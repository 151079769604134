import { forwardRef, useEffect, useMemo, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { defaultAddress } from '../../../values/location';
import { defaultLocation } from '../../../pages/Events/AddNewEventPage';
import { get } from 'lodash';
import { getCurrencyByField } from '../TextFields/Currency/utils';
import parse from 'autosuggest-highlight/parse';
import { styled } from '@mui/material';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';

const autocompleteService = { current: null, place: null, geoCoder: null };

const StyledAutocomplete = styled(Autocomplete)(({ compact = false }) => ({
  ...(compact && {
    '& .MuiFormControl-root': {
      '& .MuiInputBase-root': {
        paddingTop: '12px',
        paddingBottom: '12px',
        '& .MuiInputBase-input': {
          fontSize: '24px',
          lineHeight: '30px',
          padding: 0
        }
      }
    }
  })
}));

// eslint-disable-next-line react/display-name
export const GooglePlaceTextField = forwardRef((props, ref) => {
  const [value, setValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  // TODO: Better will be a separate wrapper component
  const [isUserLocationLoading, setIsUserLocationLoading] = useState(false);
  const { isGmapLoaded } = useSelector(({ app }) => app);

  const getUserCurrentLocation = () => {
    if (navigator.geolocation) {
      setIsUserLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        position => {
          setUserCurrentLocation(position);
          setIsUserLocationLoading(false);
        },
        error => {
          setIsUserLocationLoading(false);
          console.log('Error while fetching user location', error);
        }
      );
    } else {
      // TODO: popup?
      console.log('Geolocation is not supported by this browser.');
    }
  };

  // Fetch the predicted locations.
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    if (userCurrentLocation) {
      const latitude = get(userCurrentLocation, 'coords.latitude', null);
      const longitude = get(userCurrentLocation, 'coords.longitude', null);
      if (
        latitude !== null &&
        longitude !== null &&
        autocompleteService &&
        autocompleteService.geoCoder
      ) {
        autocompleteService.geoCoder
          .geocode({
            location: {
              lat: latitude,
              lng: longitude
            }
          })
          .then(response => {
            if (response.results[0]) {
              buildLocationObject(response.results[0], 'OK');
            }
          })
          .catch(e =>
            console.log('Error while fetching reverse geocode address', e)
          );
      }
    }
  }, [userCurrentLocation]);

  useEffect(() => {
    let value = '';
    if (get(props, 'value.address1', false)) {
      value = `${props.value.name ? `${props.value.name}, ` : ''}${
        props.value.address1
      }, ${props.value.city}, ${props.value.state}, ${props.value.country}`;
    }
    setInitialValue(value);
  }, [props.value]);

  useEffect(() => {
    if (!isGmapLoaded) return;
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      autocompleteService.place = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      autocompleteService.geoCoder = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      const { preFillCurrentLocation } = props;
      if (preFillCurrentLocation) {
        getUserCurrentLocation();
      }
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, isGmapLoaded]);

  const buildLocationObject = (place, status) => {
    if (status === 'OK') {
      const location = place.address_components.reduce(
        (prev, current) => {
          const temp = { ...prev };
          let key = '';

          for (const type of current.types) {
            switch (type) {
              case 'administrative_area_level_1':
                key = 'state';
                break;
              case 'country':
                key = 'country';
                break;
              case 'locality':
                key = 'city';
                break;
              case 'postal_town':
                key = 'city';
                break;
              case 'postal_code':
                key = 'zip';
                break;
              case 'route':
                key = 'address';
                break;
              case 'street_number':
                key = 'number';
                break;
              case 'subpremise':
                key = 'address2';
                break;
              default:
                break;
            }
          }

          if (key !== '') {
            const value = current.short_name;
            if (key === 'number' || key === 'address') {
              temp.address1[key] = value;
            } else {
              temp[key] = value;
            }
          }

          return temp;
        },
        { address1: { number: '', address: '' } }
      );
      props.setCurrency &&
        props.setCurrency(getCurrencyByField(location.country, 'countryCode'));
      props.onChange &&
        props.onChange({
          ...defaultAddress,
          ...location,
          address1: `${location.address1.number} ${location.address1.address}`,
          name: place.name,
          geo: {
            type: 'Point',
            coordinates: [
              place.geometry.location.lng(),
              place.geometry.location.lat()
            ]
          }
        });
    }
  };

  return (
    <StyledAutocomplete
      compact={props.compact}
      disabled={props.disabled}
      ref={ref}
      id={props.id ? props.id : 'google-map-autocomplete-input'}
      data-testid={props.id ? props.id : 'google-map-autocomplete-input'}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={initialValue ? initialValue : value}
      onChange={async (event, newValue, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        // Build location object if empty or new location.
        if (
          (!value && newValue) ||
          (value && newValue && value.place_id !== newValue.place_id)
        ) {
          autocompleteService.place.getDetails(
            {
              placeId: newValue.place_id,
              fields: ['address_component', 'geometry', 'name']
            },
            buildLocationObject
          );
        }

        // Send undefined if the user cleared the value.
        if (reason === 'clear') {
          props.onChange && props.onChange({ ...defaultLocation });
        }
        setInitialValue(null);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          // required
          {...params}
          data-testid={
            props.id
              ? `${props.id}-textfield`
              : 'google-autocomplete-field-basic-textfield'
          }
          error={props.error}
          label={props?.hideLabel ? '' : props.label || 'Location'}
          InputLabelProps={{ shrink: !props?.hideLabel }}
          InputProps={{
            ...params?.InputProps,
            endAdornment: isUserLocationLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={24} color="primary" />
              </InputAdornment>
            ) : (
              params?.InputProps?.endAdornment
            )
          }}
          inputProps={{
            ...props.inputProps,
            ...params.inputProps,
            'data-testid': props.id
              ? `${props.id}-input`
              : 'google-autocomplete-field-basic-input'
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item>
                <Typography variant={'h5'}>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
                {/* Location Address */}
                <Typography variant={'body2'}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
});
