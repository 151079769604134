import { Box, Grid, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { filter, get, isEmpty } from 'lodash';
import {
  useGetAvailableJudgesQuery,
  useGetSingleEventQuery,
  useUpdateEventJudgesMutation
} from '../../../../../services/apis/organizePlayApi';

import { AvailableJudge } from './AvailableJudge';
import Loading from '../../../../Loading/Loader';
import { ModalContext } from '../../../../Modal/Context/ModalContext';
import SimpleModalCard from '../../../../Modal/Cards/SimpleModalCard';
import { StyledSearchTextField } from '../../../../Shared/TextFields';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const JudgesListContainer = styled(Box)`
  width: 100%;
  padding: 16px 24px;
  max-height: 32vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;

const NoResultContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

export const ManageEventJudges = () => {
  const { eventId } = useParams();
  const { closeModal, setIsLoading } = useContext(ModalContext);
  const {
    data: availableJudges,
    isLoading,
    isSuccess
  } = useGetAvailableJudgesQuery(eventId, {
    refetchOnMountOrArgChange: true
  });

  const {
    isLoading: eventsLoading,
    isFetching: eventsFetching,
    isSuccess: eventsFetchSuccess
  } = useGetSingleEventQuery(eventId);

  const [addUpdateLoading, setAddUpdateLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [judgesAdded, setJudgesAdded] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedJudgeIds, setSelectedJudgeIds] = useState([]);

  const [
    addUpdateJudges,
    addUpdateJudgesResult
  ] = useUpdateEventJudgesMutation();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setFilteredData(availableJudges || []);
    }
  }, [availableJudges, isLoading, isSuccess]);

  useEffect(() => {
    if (searchText) {
      setFilteredData(
        filter(availableJudges, availableJudge => {
          const name = `${get(
            availableJudge,
            'user.profile.firstName',
            ''
          )} ${get(availableJudge, 'user.profile.lastName', '')}`
            .trim()
            .toLowerCase();
          const judgeId = get(availableJudge, 'judgeId', '').toLowerCase();
          return (
            (name && name.includes(searchText.toLowerCase())) ||
            (judgeId && judgeId.includes(searchText.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(availableJudges || []);
    }
  }, [searchText]);

  useEffect(() => {
    if (addUpdateJudgesResult.isSuccess) {
      setJudgesAdded(true);
      toast.success('Successfully updated judges');
    } else if (addUpdateJudgesResult.isError) {
      setAddUpdateLoading(false);
      setIsLoading(false);
      toast.error(
        addUpdateJudgesResult.error?.data?.errorMessage ||
          'Unable to add/update judges'
      );
    }
  }, [addUpdateJudgesResult.isLoading]);

  useEffect(() => {
    if (judgesAdded && !eventsFetching && eventsFetchSuccess) {
      setIsLoading(false);
      setAddUpdateLoading(false);
      closeModal();
    }
  }, [judgesAdded, eventsLoading, eventsFetching, eventsFetchSuccess]);

  const isJudgeSelected = judgeId => {
    return selectedJudgeIds.includes(judgeId);
  };

  const onCheckJudgeSelection = judgeId => {
    if (isJudgeSelected(judgeId)) {
      setSelectedJudgeIds(
        filter(selectedJudgeIds, selectedJudgeId => selectedJudgeId !== judgeId)
      );
    } else {
      setSelectedJudgeIds([...selectedJudgeIds, judgeId]);
    }
  };

  const onClickAddUpdateJudges = () => {
    setIsLoading(true);
    setAddUpdateLoading(true);
    addUpdateJudges({
      eventId,
      body: {
        addJudges: selectedJudgeIds
      }
    });
  };

  return (
    <SimpleModalCard
      title="Add Judges"
      confirmButton={{
        id: 'add-judges-confirm-button',
        color: 'success',
        title: 'Add Judges',
        disabled: addUpdateLoading || isEmpty(selectedJudgeIds),
        onClick: onClickAddUpdateJudges
      }}
      actionContainerProps={{
        justifyContent: { xs: 'center', sm: 'flex-end' }
      }}
      showClose
      onClose={closeModal}
      isCloseDisabled={addUpdateLoading}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ paddingLeft: '22px', paddingRight: '22px' }}>
          <StyledSearchTextField
            id="search-judges-input"
            label="Search"
            value={searchText}
            placeholder="Search by Name or Judge ID"
            onChange={event => setSearchText(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loading />
          ) : (
            <Grid
              id="add-judges-modal-select"
              data-testid="add-judges-modal-select"
              container
              spacing={1}
            >
              <JudgesListContainer>
                {filteredData.length ? (
                  filteredData.map(availableJudge => (
                    <Grid item xs={12} key={availableJudge._id} mb={2}>
                      <AvailableJudge
                        judgeInfo={availableJudge}
                        isSelected={isJudgeSelected(availableJudge._id)}
                        onCheck={onCheckJudgeSelection}
                      />
                    </Grid>
                  ))
                ) : (
                  <NoResultContainer>
                    <Typography variant="h4">No judges available</Typography>
                  </NoResultContainer>
                )}
              </JudgesListContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};
