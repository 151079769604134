import { Grid, Typography } from '@mui/material';

import Loading from '../../Loading/Loader';
import { MaxWidthContainer } from '../Grid/styles';
import React from 'react';

const EmptyPageLoader = ({
  mainText = 'Loading',
  subtitle = 'Please wait!'
}) => {
  return (
    <MaxWidthContainer container spacing={2}>
      <Grid item xs={12}>
        <Grid container mt={12}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2Bold">{mainText}</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="body2Light">{subtitle}</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Loading />
          </Grid>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default EmptyPageLoader;
