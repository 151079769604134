import React from 'react';
import { TubeCard } from '../../../../../components/Shared/Cards/styles';
import { Typography } from '@mui/material';
// eslint-disable-next-line no-unused-vars
const ProductStatus = ({ isSoldOut, limitedQty, limitedTime, isLive }) => {
  let title = undefined;
  let status = '';

  if (isSoldOut) {
    title = 'Sold Out';
    status = 'sold-out';
  }
  /** TODO: For now we are only showing "sold out" but this can change in the future. **/
  // else if (!isLive) {
  //   title = 'Coming Soon';
  //   status = 'limited';
  // } else if (limitedQty) {
  //   title = `Only ${limitedQty} Left!`;
  //   status = 'limited';
  // } else if (limitedTime) {
  //   title = 'Limited Time Only';
  //   status = 'limited';
  // }

  if (!title) {
    return <></>;
  }

  return (
    <TubeCard className={'product-status'} status={status}>
      <Typography variant={'subtitle2'}>{title}</Typography>
    </TubeCard>
  );
};

export default ProductStatus;
