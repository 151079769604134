import React from 'react';
import ProfileGradientImage from '../../User/ProfileGradientImage';
import { Grid, Typography } from '@mui/material';

const UserRankingProfileInfo = ({
  firstName,
  lastName,
  profileImage,
  rankName,
  teamName,
  rankNumber,
  rankIcon
}) => {
  const fullName = [firstName, lastName].filter(name => !!name).join(' ');
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <ProfileGradientImage
          imageUrl={profileImage}
          size={100}
          rankIcon={rankIcon}
          rankNumber={rankNumber}
        />
      </Grid>
      <Grid item xs={12} mt={rankIcon ? 3 : 1}>
        <Typography textAlign="center" variant="h4">
          {rankName}
        </Typography>
      </Grid>
      <Grid item mt={1} xs={12}>
        <Typography textAlign="center" variant="h2">
          {fullName}
        </Typography>
      </Grid>
      {teamName && (
        <Grid item xs={12} mt={1} justifyContent="center" display="flex">
          <Typography mb={2} mt={1} textAlign="center" variant="body1Light">
            {teamName}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default UserRankingProfileInfo;
