import { Grid } from '@mui/material';
import Loader from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import React from 'react';
import StoreHeader from './components/Header';
import StoreInfo from './components/Info';
import StoreLeaderboard from './components/Leaderboard';
import StoreUpcomingEvents from './components/UpcomingEvents';
import { get, isEmpty } from 'lodash';
import {
  useGetEntityByIdQuery,
  useGetEventsByEntityIdQuery,
  useGetRankingsQuery
} from '../../../services/apis/organizePlayApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SingleStorePage = () => {
  const { entityId } = useParams();
  const { game } = useSelector(({ app }) => app);

  const { data: activeStore, isLoading } = useGetEntityByIdQuery({
    storeId: entityId,
    gameId: game.id
  });

  const {
    data: rankingsData,
    isLoading: rankingsLoading
  } = useGetRankingsQuery(`gameId=${game.id}&entityId=${entityId}`);

  const {
    data: eventsData,
    isLoading: eventsLoading
  } = useGetEventsByEntityIdQuery({
    gameId: game.id,
    entityId,
    startDate: new Date().toISOString().slice(0, 10)
  });

  if (!isLoading) {
    return (
      <Grid container gap={6}>
        <StoreHeader
          storeDescription={get(activeStore, 'data.info.description')}
          storeName={get(activeStore, 'data.name', '')}
        />
        <MaxWidthContainer container gap={6} className="p-percent">
          <Grid xs={12} item>
            <StoreInfo store={get(activeStore, 'data')} />
            {!isEmpty(rankingsData?.rankings) && !rankingsLoading && (
              <StoreLeaderboard
                rankings={rankingsData.rankings}
                storeInfo={get(activeStore, 'data')}
              />
            )}
            {!isEmpty(eventsData?.data) && !eventsLoading && (
              <StoreUpcomingEvents events={eventsData.data} />
            )}
          </Grid>
        </MaxWidthContainer>
      </Grid>
    );
  } else {
    return <Loader />;
  }
};

export default SingleStorePage;
