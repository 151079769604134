import { Box, Button, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { compact, get } from 'lodash';

import ShippingAddressUpdateModal from '../ShippingAddressUpdateModal';
import { isUserShippingAddressValid } from '../../../utils/user';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../hooks/modal/useModalContext';

const ShippingAddressContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  flexDirection: 'column'
}));

const UserShippingAddress = () => {
  const { user } = useSelector(({ app }) => app);
  const { addModal } = useModalContext();
  const name = compact([
    get(user, 'details.info.firstName'),
    get(user, 'details.info.lastName')
  ]).join(' ');
  const addresses = compact([
    get(user, 'details.location.address1'),
    get(user, 'details.location.address2')
  ]).join(' ');
  const restPart = compact([
    get(user, 'details.location.city'),
    get(user, 'details.location.state'),
    get(user, 'details.location.zip')
  ]).join(', ');

  const country = get(user, 'details.location.country');

  const isValidAddress = isUserShippingAddressValid(user);

  const openShippingAddressModal = () => {
    addModal({
      children: <ShippingAddressUpdateModal />
    });
  };
  return (
    <ShippingAddressContainer>
      <Grid
        container
        spacing={1}
        justifyContent={{ sm: 'flex-start', xs: 'center' }}
      >
        <Grid item xs={12} sm={10} textAlign={{ sm: 'start', xs: 'center' }}>
          <Box sx={{ padding: '8px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1Bold">Shipping Address</Typography>
              </Grid>

              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1Bold">{name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1Light">
                      {addresses.toUpperCase()}
                    </Typography>
                  </Grid>
                  {restPart && (
                    <Grid item xs={12}>
                      <Typography variant="body1Light">
                        {restPart.toUpperCase()}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1Light">{country}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  onClick={openShippingAddressModal}
                  sx={{ padding: 0 }}
                >
                  <Typography data-testid="update-shipping-address-button">
                    {isValidAddress
                      ? 'Change Shipping Address'
                      : 'Add Shipping Address'}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ShippingAddressContainer>
  );
};

export default UserShippingAddress;
