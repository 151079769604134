import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  eventStatuses,
  playerStatus
} from '../../../../../values/event-values';
import {
  useGetSingleEventPairingsQuery,
  useGetSingleEventQuery,
  useGetSingleEventRosterQuery,
  usePostStartEventMutation
} from '../../../../../services/apis/organizePlayApi';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalContext } from '../../../../Modal/Context/ModalContext';
import { NA } from '../../../../../assets/strings';
import SimpleModalCard from '../../../../Modal/Cards/SimpleModalCard';
import { StyledSelectTextField } from '../../../../../components/Shared/TextFields';
import { buildRoutePath } from '../../../../../utils/routingUtils';
import { get } from 'lodash';
import { isNumberPowerOfTwo } from '../../../../../utils/number';
import { singleEventInfo } from '../../../../../utils/EventUtils';
import { tournamentFormatOptions } from '../../../../../values/event-values';
import { useSelector } from 'react-redux';

const StartingRounds = () => {
  const topCut = [0, 2, 4, 8, 16, 32, 64];
  const { gameSlug, eventId } = useParams();
  const { game } = useSelector(({ app }) => app);
  const navigate = useNavigate();

  // Context Hooks
  const { closeModal, setIsLoading } = useContext(ModalContext);

  // Redux Hooks
  const [startEvent, startEventResult] = usePostStartEventMutation();
  const [selectedTournamentFormat, setSelectedTournamentFormat] = useState(
    null
  );

  // Single Event
  const {
    data: activeEvent,
    isLoading: singleEventLoading
  } = useGetSingleEventQuery(eventId);

  // Roster
  const {
    data: roster,
    isLoading: rosterLoading
  } = useGetSingleEventRosterQuery({ eventId });

  const {
    id,
    currentRound,
    regularRounds,
    totalRounds,
    tournament,
    status: eventStatus
  } = singleEventInfo(activeEvent);
  const playType = get(tournament, 'playType', 'tournament');

  const {
    data: pairingsData,
    // eslint-disable-next-line no-unused-vars
    isLoading: pairingsLoading
  } = useGetSingleEventPairingsQuery({
    eventId,
    roundNumber: currentRound
  });

  const pairings = get(pairingsData, 'pairings', []).filter(pairing =>
    get(pairing, 'active', true)
  );

  const players = get(roster, 'data.participants', []);
  const confirmedPlayers = players.filter(
    player => get(player, 'status', '') === playerStatus.active.key
  );
  const isConfirmedPlayerCountPowerOfTwo = isNumberPowerOfTwo(
    confirmedPlayers.length
  );

  const nextRoundMessage =
    'Please confirm that all reported results are correct.';
  const getTitle = () => {
    if (activeEvent) {
      switch (currentRound) {
        case regularRounds:
          return 'Start Top Cut?';
        case totalRounds:
          return 'Complete Event?';
        case totalRounds - 1:
          return 'Start Finals?';
        case totalRounds - 2:
          return 'Start Semi Finals?';
      }

      switch (eventStatus) {
        case eventStatuses.registration.key:
          return 'Start Event?';
        case eventStatuses.started.key:
          return 'Start Next Round?';
      }
    }
    return NA;
  };

  const startRound = () => {
    if (
      (eventStatus === eventStatuses.registration.key ||
        eventStatus === eventStatuses.started.key) &&
      (playType === 'tournament'
        ? confirmedPlayers.length >= 2
        : confirmedPlayers.length >= 1)
    ) {
      const startEventReq = {
        eventId: id,
        type: eventStatus === eventStatuses.registration.key ? 'start' : 'next',
        playOffCount: topCutValue
      };
      if (
        startEventReq.type === 'start' &&
        isConfirmedPlayerCountPowerOfTwo &&
        playType === 'tournament'
      ) {
        startEventReq.format = selectedTournamentFormat;
        startEventReq.updateEvent = 1;
      }
      startEvent(startEventReq);
    }
  };

  const [topCutValue, setTopCutValue] = useState(
    get(activeEvent, 'tournament.cut.limit', null)
  );

  useEffect(() => {
    setIsLoading(startEventResult.isLoading);
    if (startEventResult.isSuccess && playType === 'tournament') {
      closeModal();
      navigate(buildRoutePath(gameSlug, `/events/${eventId}/pairings`));
    } else if (startEventResult.isSuccess) {
      closeModal();
    }
  }, [startEventResult.isLoading]);

  useEffect(() => {
    if (activeEvent) {
      const format = get(activeEvent, 'tournament.details.format', 'swiss');
      if (!selectedTournamentFormat) {
        setSelectedTournamentFormat(format);
      }
    }
  }, [activeEvent, selectedTournamentFormat]);

  return (
    <SimpleModalCard
      title={getTitle()}
      confirmButton={{
        id: 'event-start-modal-confirm-button',
        color: 'success',
        disabled:
          (playType === 'tournament'
            ? confirmedPlayers.length <= 1
            : confirmedPlayers.length === 0) ||
          singleEventLoading ||
          rosterLoading ||
          pairingsLoading ||
          pairings.length > 0,
        onClick: startRound
      }}
    >
      <Grid container>
        <Grid container justifyContent={'center'} spacing={2}>
          <Grid item mb={4} mt={2} xs={12}>
            <Typography textAlign={'center'}>
              {eventStatus === eventStatuses.registration.key
                ? `${confirmedPlayers.length} ${
                    confirmedPlayers.length <= 1
                      ? get(game, 'terminology.player')
                      : get(game, 'terminology.players')
                  } Checked In`
                : nextRoundMessage}
            </Typography>
          </Grid>
          {eventStatus === eventStatuses.registration.key &&
          isConfirmedPlayerCountPowerOfTwo &&
          playType === 'tournament' ? (
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                >
                  <Grid item xs={12} sm={6}>
                    <StyledSelectTextField
                      id="event-start-modal-format-select"
                      label="Tournament Format"
                      value={selectedTournamentFormat}
                      onChange={event => {
                        setSelectedTournamentFormat(event.target.value);
                      }}
                      menuItems={tournamentFormatOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          {currentRound === regularRounds && currentRound !== totalRounds && (
            <Grid item xs={12}>
              <Typography mb={4} textAlign={'center'} variant="body2">
                How many rounds of top cut would you like to run? If you&apos;d
                like to have no top cut, please select 0.
              </Typography>
              <TextField
                label={'Top Cut Players'}
                select
                onChange={e => setTopCutValue(e.target.value)}
                value={topCutValue}
              >
                {topCut
                  .filter(num => num < confirmedPlayers.length)
                  .map(num => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};

export default StartingRounds;
