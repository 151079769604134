import StatusPage, { statusTypes } from '../Status';

import FeaturedRoute from '../../components/Route/FeaturedRoute';
import MyManagedEventsPage from './MyManagedEventsPage';
import MyStoresPage from './MyStoresPage';
import ProfilePage from './ProfilePage';
import ProtectedRoute from '../../components/Route/ProtectedRoute';
import React from 'react';
import RewardsPage from './RewardsPage';
import { Route } from 'react-router-dom';
import UserInfo from '../../components/User/UserInfo';
import UserMainPage from './index';

const UserRouter = () => {
  return (
    <Route
      path={'user/*'}
      element={
        <ProtectedRoute>
          <UserMainPage />
        </ProtectedRoute>
      }
      errorElement={<StatusPage type={statusTypes.error} />}
    >
      <Route path={'profile'} element={<ProfilePage />}>
        <Route index element={<UserInfo />} />
        <Route
          path={'my-rewards'}
          element={
            <FeaturedRoute feature="rewardStore">
              <RewardsPage />
            </FeaturedRoute>
          }
        />
        <Route path={'my-stores'} element={<MyStoresPage />} />
      </Route>
      <Route
        exact
        path={'events'}
        element={
          <ProtectedRoute checkEventAccess={true}>
            <MyManagedEventsPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default UserRouter;
