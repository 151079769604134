import { get } from 'lodash';
import { useSelector } from 'react-redux';

export function useDefinitionName(attributeId) {
  const { game } = useSelector(({ app }) => app);

  const cardAttribute = game?.cardAttributes?.find(card => {
    return card._id === attributeId;
  });

  const definitionId = get(cardAttribute, 'definitionId');

  const genericDefinition = game?.genericDefinitions.find(definition => {
    return definition._id === definitionId;
  });

  const name = get(genericDefinition, 'name');

  return name;
}
