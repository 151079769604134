import React, { forwardRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { useTheme } from '@mui/material/styles';
import { EditorContainer } from './styles';
import Toolbar from './plugins/Toolbar/toolbar';
import { HeadingNode } from '@lexical/rich-text';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import AutoLinkPlugin from './plugins/Toolbar/AutoLink';
import { $generateHtmlFromNodes } from '@lexical/html';
// eslint-disable-next-line react/display-name
const Editor = forwardRef((props, ref) => {
  const theme = useTheme();
  //  Editor
  const initialConfig = {
    namespace: 'Description',
    theme: {
      ...theme,
      text: {
        bold: 'font-bold',
        italic: 'italic',
        underline: 'underline',
        strikethrough: 'line-through'
      },
      link: 'editor-link'
    },
    onError: () => {},
    nodes: [HeadingNode, LinkNode, AutoLinkNode],
    editable: props.readOnly && false,
    editorState: props.initState || undefined
  };

  return (
    <>
      {props.readOnly ? (
        <LexicalComposer initialConfig={initialConfig}>
          <RichTextPlugin
            contentEditable={<ContentEditable />}
            placeholder={<p className="editor-placeholder"></p>}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </LexicalComposer>
      ) : (
        <EditorContainer ref={ref} elevation={0}>
          <LexicalComposer initialConfig={initialConfig}>
            <Toolbar />
            <div className={'inner-editor-container'}>
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={
                  <p className="editor-placeholder">Start Typing...</p>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnChangePlugin
                onChange={(state, editor) => {
                  state.read(() => {
                    const htmlString = $generateHtmlFromNodes(editor, null);
                    props.onChange &&
                      props.onChange({
                        html: htmlString,
                        json: JSON.stringify(editor.getEditorState())
                      });
                  });
                }}
              />
              <LinkPlugin />
              <AutoLinkPlugin />
            </div>
          </LexicalComposer>
        </EditorContainer>
      )}
    </>
  );
});

export default Editor;
