import React from 'react';
import { Box, Typography } from '@mui/material';

const OverflowTextfield = ({
  title,
  variant = 'body1',
  style = {},
  ...props
}) => {
  const typographyStyle = {
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...style
  };
  return (
    <Box
      sx={{
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        borderCollapse: 'collapse'
      }}
    >
      <Typography variant={variant} sx={typographyStyle} {...props}>
        {title}
      </Typography>
    </Box>
  );
};

export default OverflowTextfield;
