export const APP_INIT_REQUEST = 'app/APP_INIT_REQUEST';
export const APP_INIT_SUCCESS = 'app/APP_INIT_SUCCESS';
export const APP_INIT_FAILURE = 'app/APP_INIT_FAILURE';

export const AUTH_START = 'app/AUTH_START';
export const AUTH_COMPLETE = 'app/AUTH_COMPLETE';
export const AUTH_FAILURE = 'app/AUTH_FAILURE';

export const SET_USER = 'app/SET_USER';

export const UPDATE_LOCAL_USER_IMAGE = 'app/UPDATE_LOCAL_USER_IMAGE';

export const GET_GAME_PROFILE_PICS = 'app/GET_GAME_PROFILE_PICS';

export const UPDATE_USER_PROFILE_PIC = 'app/UPDATE_USER_PROFILE_PIC';

export const UPDATE_USER_REQUEST = 'app/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'app/UPDATE_USER_FAILURE';

export const UPDATE_GAME_USER_REQUEST = 'app/UPDATE_GAME_USER_REQUEST';
export const UPDATE_GAME_USER_SUCCESS = 'app/UPDATE_GAME_USER_SUCCESS';
export const UPDATE_GAME_USER_FAILURE = 'app/UPDATE_GAME_USER_FAILURE';

export const SET_GMAP_LOADED = 'app/SET_GMAP_LOADED';

export const GET_ENTITY_APPLICATIONS_REQUEST =
  'app/GET_ENTITY_APPLICATIONS_REQUEST';
export const GET_ENTITY_APPLICATIONS_SUCCESS =
  'app/GET_ENTITY_APPLICATIONS_SUCCESS';
export const GET_ENTITY_APPLICATIONS_FAILURE =
  'app/GET_ENTITY_APPLICATIONS_FAILURE';

export const SET_STORE_CURRENCY = 'app/SET_STORE_CURRENCY';
export const UPDATE_USER_WALLET_REQUEST = 'app/UPDATE_USER_WALLET_REQUEST';
export const UPDATE_USER_WALLET_FAILURE = 'app/UPDATE_USER_WALLET_FAILURE';
export const UPDATE_USER_WALLET_SUCCESS = 'app/UPDATE_USER_WALLET_SUCCESS';
