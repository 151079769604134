export const orderGridStyles = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    sm: 'repeat(auto-fill, minmax(250px, 1fr))',
    lg: 'repeat(auto-fill, 300px)'
  },
  justifyContent: 'space-evenly',
  gap: theme => theme.spacing(4)
};
