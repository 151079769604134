import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { Typography } from '@mui/material';

const TrendTube = () => {
  return (
    <TubeCard status={'active'} style={{ width: 'fit-content' }}>
      <Typography
        sx={{
          typography: { xs: 'caption', sm: 'h6' },
          fontWeight: '600 !important'
        }}
      >
        Trending
      </Typography>
    </TubeCard>
  );
};

export default TrendTube;
