import {
  DesktopDateTimePicker,
  MobileDateTimePicker
} from '@mui/x-date-pickers';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import React, { useState } from 'react';

import SimpleModalCard from '../../Modal/Cards/SimpleModalCard';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash';

const startLaterOption = {
  displayText: 'Start later',
  value: 'start_later'
};
const startNowOption = {
  displayText: 'Start now',
  value: 'start_now'
};

const startTimerOptions = [startNowOption, startLaterOption];

export const EventRoundTimerModal = ({ onConfirm }) => {
  const [selectedTimerType, setSelectedTimerType] = useState(
    startNowOption.value
  );
  const [startTime, setStartTime] = useState(dayjs(new Date()));

  const now = dayjs(new Date());
  const maxDateTime = dayjs(now).add(90, 'minutes');

  const onClickSubmit = () => {
    if (selectedTimerType === startLaterOption.value && startTime.isValid()) {
      onConfirm(startTime.toISOString());
    } else {
      onConfirm();
    }
  };

  return (
    <SimpleModalCard
      title={'Set Start Timer'}
      cancelButton={{ id: 'event-pairings-start-timer-modal-cancel-button' }}
      confirmButton={{
        id: 'event-pairings-start-timer-modal-confirm-button',
        title: 'Submit',
        onClick: onClickSubmit,
        disabled:
          !selectedTimerType ||
          (selectedTimerType === startLaterOption.value && !startTime)
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Grid container>
              <Grid item xs={12}>
                <FormLabel
                  id="select-start-timer-type"
                  data-testid="select-start-timer-type"
                >
                  Choose how to start timer?
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  id="event-parings-start-timer-select"
                  data-testid="event-parings-start-timer-select"
                  aria-labelledby="select-start-timer-type"
                  value={selectedTimerType}
                  name="select-timer-type-group"
                  onChange={event => setSelectedTimerType(event.target.value)}
                  sx={{ width: '100%' }}
                >
                  <Grid container>
                    {startTimerOptions.map(({ displayText, value }) => (
                      <Grid item xs={6} key={value}>
                        <FormControlLabel
                          value={value}
                          control={
                            <Radio
                              id={`event-pairings-start-timer-${kebabCase(
                                displayText
                              )}-radio`}
                              data-testid={`event-pairings-start-timer-${kebabCase(
                                displayText
                              )}-radio`}
                            />
                          }
                          label={displayText}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </Grid>
              {selectedTimerType === startLaterOption.value && (
                <Grid item xs={12} pt="1rem">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormLabel
                        id="select-start-timer-type"
                        data-testid="select-start-timer-type"
                      >
                        Select when to start timer?
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} display={{ xs: 'none', sm: 'unset' }}>
                      <DesktopDateTimePicker
                        inputFormat={'MM/DD/YYYY hh:mm A'}
                        value={startTime}
                        onChange={date => setStartTime(date)}
                        renderInput={params => (
                          <TextField
                            id="event-pairings-start-timer-date-picker-input"
                            data-testid="event-pairings-start-timer-date-picker-input"
                            {...params}
                          />
                        )}
                        minDateTime={now}
                        maxDateTime={maxDateTime}
                      />
                    </Grid>
                    <Grid item xs={12} display={{ xs: 'unset', sm: 'none' }}>
                      <MobileDateTimePicker
                        inputFormat={'MM/DD/YYYY hh:mm A'}
                        value={startTime}
                        componentsProps={{
                          actionBar: {
                            actions: ['accept', 'clear']
                          }
                        }}
                        onChange={date => setStartTime(date)}
                        renderInput={params => (
                          <TextField
                            id="event-pairings-start-timer-date-picker-input"
                            data-testid="event-pairings-start-timer-date-picker-input"
                            {...params}
                          />
                        )}
                        minDateTime={now}
                        maxDateTime={maxDateTime}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};
