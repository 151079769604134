/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SideButton, StyledVariantDisplayWrapper } from './styles';
import {
  getAvailableImageFromProduct,
  getVariantsArrayFromInventory
} from '../../../utils/cart';
import {
  usePostAddToCartMutation,
  usePostRemoveFromCartMutation
} from '../../../services/apis/organizePlayApi';

import CartItemQuantitySelector from './QuantitySelector';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RewardDetailsModal from '../../Rewards/components/RewardDetailsModal';
import SimpleModalCard from '../../../components/Modal/Cards/SimpleModalCard';
import StoreCurrency from '../../../components/Shared/StoreCurrency';
import { get, kebabCase } from 'lodash';
import { getMaxQty } from '../../../utils/products/getMaxQty';
import { getProductLimit } from '../../../utils/products/getProductLimit';
import { isProductSoldOut } from '../../../utils/products/isProductSoldOut';
import testImage from '../../../assets/test.png';
import { toast } from 'react-toastify';
import { useModalContext } from '../../../hooks/modal/useModalContext';
import { useSelector } from 'react-redux';

const CartItem = ({ item }) => {
  const { addModal, closeModal, setIsLoading } = useModalContext();
  const [showShare, setShowShare] = useState(true);
  const [isQuantityChangeLoading, setIsQuantityChangeLoading] = useState(false);
  const { selectedStoreCurrency } = useSelector(({ app }) => app);
  const { purchases, limit, currentLimit } = getProductLimit(
    item?.product,
    selectedStoreCurrency
  );

  const soldOut = isProductSoldOut([get(item, 'product.inventory')]);

  const [removeButton, setRemoveButton] = useState(false);

  const maxQty = getMaxQty(currentLimit, item?.product?.inventory);

  const variants = getVariantsArrayFromInventory(
    get(item, 'product.inventory')
  );

  const getTotalPrice = () => {
    const price = get(item, 'product.inventory.productPrice.price', 0);
    const quantity = get(item, 'quantity', 1);
    const fiatPrice = Object.assign(
      {},
      get(item, 'product.inventory.productPrice.fiatPrice', null)
    );
    if (fiatPrice && fiatPrice.amount) {
      fiatPrice.amount = fiatPrice.amount * quantity;
    }
    const totalPrice = price * quantity;
    return {
      amount: totalPrice,
      symbol: get(item, 'currency.symbol'),
      fiatPrice
    };
  };

  const [
    removeFromCart,
    removeFromCartResult
  ] = usePostRemoveFromCartMutation();

  const [addToCart, addToCartResult] = usePostAddToCartMutation();

  useEffect(() => {
    setIsLoading(removeFromCartResult.isLoading);
    if (isQuantityChangeLoading) {
      setIsQuantityChangeLoading(addToCartResult.isLoading);
    }
    if (removeFromCartResult.isSuccess) {
      if (isQuantityChangeLoading) {
        setIsQuantityChangeLoading(false);
        toast.success(`${item?.product?.title} quantity updated`, {
          toastId: item?.product?.id + 'add'
        });
      } else {
        toast.success(`${item?.product?.title} removed from cart`, {
          toastId: item?.product?.id + 'remove'
        });
      }
    }
    if (removeButton) {
      closeModal();
      setRemoveButton(true);
    }
  }, [removeFromCartResult]);

  useEffect(() => {
    if (isQuantityChangeLoading) {
      setIsQuantityChangeLoading(addToCartResult.isLoading);
      if (addToCartResult.isSuccess) {
        setIsQuantityChangeLoading(false);
        toast.success('Product quantity updated');
      }
    }
  }, [addToCartResult]);

  const onQuantityChange = newQuantity => {
    if (newQuantity !== item.quantity) {
      const diff = newQuantity - item.quantity;
      setIsQuantityChangeLoading(true);
      const requestData = {
        body: {
          productId: item.productId,
          inventoryId: item.inventoryId,
          productPriceId: item.productPriceId,
          quantity: Math.abs(diff)
        }
      };
      if (diff < 0) {
        removeFromCart(requestData);
      } else {
        addToCart(requestData);
      }
    }
  };

  const onDeleteConfirmClick = () => {
    setIsLoading(true);
    removeFromCart({
      body: {
        productId: item.productId,
        inventoryId: item.inventoryId,
        productPriceId: item.productPriceId,
        quantity: item.quantity
      }
    });
  };

  const getProductImage = useCallback(() => {
    return getAvailableImageFromProduct(item.product) || testImage;
  }, [item]);

  const handleDelete = () => {
    setRemoveButton(true);
    addModal({
      children: (
        <SimpleModalCard
          title={'Remove Product from cart'}
          cancelButton={{ id: 'delete-deck-cancel-button' }}
          confirmButton={{
            id: 'delete-deck-confirm-button',
            onClick: () => onDeleteConfirmClick(),
            title: 'Delete',
            color: 'error'
          }}
          onClose={() => setRemoveButton(false)}
        >
          <Typography textAlign={'center'} typography={'h4'}>
            Are you sure?
          </Typography>
        </SimpleModalCard>
      ),
      style: { maxWidth: '500px' }
    });
  };
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        padding: { xs: '20px 0', sm: '40px 0' },
        borderBottom: `1px solid ${theme.border.borderColor}`
      })}
    >
      <Grid container spacing={{ xs: 2, sm: 5 }}>
        <Grid item xs={3}>
          <Box
            sx={{
              height: { xs: '50%', sm: '100%' },
              borderRadius: '10px',
              overflow: 'hidden',
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }
            }}
          >
            <img src={getProductImage()} />
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            padding="10px 0"
            order={{ xs: 2, sm: 1 }}
            // alignSelf={{ xs: 'flex-end', sm: 'flex-start' }}
          >
            <div>
              <Typography
                variant="h3"
                data-testid={`cart-item-product-name-${kebabCase(
                  item.product.name
                )
                  .replaceAll(' ', '')
                  .toLowerCase()}`}
              >
                {item.product.name}
              </Typography>
              {limit && (
                <Typography variant="body2" sx={{ paddingTop: 0.5 }}>
                  {`Limit purchase: ${purchases} of ${limit}`.toUpperCase()}
                </Typography>
              )}
              <CartItemQuantitySelector
                itemName={item.product.name}
                currentQuantity={item.quantity}
                limit={maxQty}
                onChange={onQuantityChange}
                isLoading={isQuantityChangeLoading}
              />
            </div>

            <StyledVariantDisplayWrapper>
              {variants && variants.length
                ? variants.map(variant => (
                    <Chip
                      key={`variant_${variant.variantName}_${variant.variantValue}`}
                      label={`${variant.variantName}: ${variant.variantValue}`}
                      color="primary"
                    />
                  ))
                : null}
            </StyledVariantDisplayWrapper>

            <Link
              component={'button'}
              color={'inherit'}
              sx={{ width: 'fit-content' }}
              variant={'body2'}
              data-testid={`view-more-details-${kebabCase(item.product.name)
                .replaceAll(' ', '')
                .toLowerCase()}`}
              onClick={() =>
                addModal({
                  children: (
                    <RewardDetailsModal
                      productId={item?.product?.id}
                      currencyId={item?.currency?.id}
                      inventoryId={item?.inventoryId}
                    />
                  )
                })
              }
            >
              View More Details
            </Link>
            <Stack
              direction="row"
              divider={
                <Divider
                  sx={theme => ({
                    borderColor: theme.border.borderColor
                  })}
                  orientation="vertical"
                  flexItem
                />
              }
              spacing={{ xs: 1, sm: 2 }}
            >
              <SideButton
                sx={theme => ({
                  '&:hover,&:active': {
                    color: theme.palette.error.main
                  },
                  '&:active': {
                    backgroundColor: theme.palette.error.main
                  }
                })}
                onClick={handleDelete}
              >
                <Typography
                  variant="body2"
                  data-testid={`cart-delete-item-${kebabCase(item.product.name)
                    .replaceAll(' ', '')
                    .toLowerCase()}`}
                >
                  Delete
                </Typography>
                <DeleteOutlineOutlinedIcon />
              </SideButton>
            </Stack>
            {soldOut && <Typography color={'error'}>Sold Out!</Typography>}
          </Box>
          <Box
            order={{ xs: 1, sm: 2 }}
            minWidth={{ xs: 'initial', sm: '200px' }}
            padding={{ xs: 0, sm: '10px 0' }}
          >
            <StoreCurrency
              {...getTotalPrice()}
              color="primary"
              variant="h3"
              marginBottom="9px"
              imageSize="32px"
              data-testid={`cart-item-price-${kebabCase(item.product.name)
                .replaceAll(' ', '')
                .toLowerCase()}`}
              containerProps={{
                justifyContent: 'flex-end'
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CartItem);
