/* eslint-disable no-unused-vars */
import { Avatar, Box, Checkbox, Grid, Paper, styled } from '@mui/material';

import React from 'react';
import { Typography } from '@mui/material';
import { get } from 'lodash';
import { getUserFullName } from '../../../../../../../../utils/user';

const StyledUserInfoContainer = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)} 0}`
  }
}));

const StyledUserAvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));
const StyledUseTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'
}));

const AvailableUserItem = ({ user, onSelect, isSelected }) => {
  const onCheckClicked = () => {
    onSelect(user);
  };
  return (
    <StyledUserInfoContainer>
      <Grid container>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Checkbox onChange={onCheckClicked} checked={isSelected} />
        </Grid>
        <Grid item xs={10}>
          <StyledUserAvatarContainer>
            <Avatar
              src={get(user, 'gameUser.imageUrl')}
              sx={{
                height: '64px',
                mr: '16px',
                width: '64px'
              }}
            />
            <StyledUseTextContainer>
              <Typography variant="body1">{getUserFullName(user)}</Typography>
              <Typography variant="body2Light">{user.email}</Typography>
            </StyledUseTextContainer>
          </StyledUserAvatarContainer>
        </Grid>
      </Grid>
    </StyledUserInfoContainer>
  );
};

export default AvailableUserItem;
