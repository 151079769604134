import { Box, styled } from '@mui/material';

const gapValue = 2;
export const RewardContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  gap: theme.spacing(gapValue),
  padding: 0,
  overflow: 'hidden',

  ['.responsive-img-container']: {
    borderRadius: 'inherit',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  ['.responsive-img']: {
    height: '250px'
  },
  ['.reward-info-container']: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: theme.spacing(gapValue),
    ['.reward-listing-product-name']: {
      width: '100%'
    }
  }
}));
