import React from 'react';
import { Box, useTheme } from '@mui/material';
import { NumberCircle, CardIcon1, CardIcon2 } from './styles';

const CardDetails = ({ icon1, icon2, icon3, number1, number2, gameSlug }) => {
  const theme = useTheme();
  return (
    <Box
      gap={{ xs: 0.4, sm: 0.5 }}
      sx={{
        position: 'absolute',
        display: 'flex',
        zIndex: 1,
        top: { xs: '-14px', sm: '-22px' },
        paddingY: '2px',
        paddingX: { xs: '0.2rem', sm: '4px' },
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'auto',
        maxWidth: { sm: '136px' },
        height: { xs: '20px', sm: '32px' },
        borderRadius: '16px',
        border: `1px solid ${theme.palette.grey['700']}`,
        backgroundColor: theme.palette.background.default
      }}
    >
      <NumberCircle
        gameSlug={gameSlug}
        color={theme.palette.primary.main}
        number={number1}
      />
      {icon1 && <CardIcon1 iconImage={icon1} />}
      {icon2 && <CardIcon1 iconImage={icon2} />}
      {icon3 && <CardIcon2 iconImage={icon3} />}
      <NumberCircle gameSlug={gameSlug} color={'#02BAAA'} number={number2} />
    </Box>
  );
};

export default CardDetails;
