import { Box, Typography, styled } from '@mui/material';

export const AuraImage = styled('img')`
  width: 20px;
  height: 20px;
`;

export const SocialInfo = styled(Typography)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: 1
}));

export const DeckCardMainContainer = styled(Box)(({ cards = 0, theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),

  '.deck-name-info': {
    gridColumn: cards > 0 ? '1 / 2' : '1 / -1',
    gridRow: 2
  },
  '.deck-cards': {
    gridColumn: '2 / 3',
    gridRow: '1 / 4'
  },
  '.deck-stats': {
    gridColumn: '1 / 2',
    gridRow: '3'
  },

  [theme.breakpoints.up('sm')]: {
    '.deck-cards': {
      gridRow: '1 / 3'
    },
    '.deck-stats': {
      gridColumn: '1 / 3',
      gridRow: '3'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.deck-cards': {
      display: 'none'
    },
    '*': {
      gridColumn: '1 / -1 !important'
    }
  }
}));

export const DeckValidityStatusContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '4px',
  left: '4px',
  zIndex: '9',
  transition: 'transform 0.25s ease-in-out',
  '&:hover': {
    transform: 'scale(1.04)'
  }
}));
