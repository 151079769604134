import { Avatar, Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { getReadableErrorMessage } from '../../../../../../utils/StringUtil';
import { inputTextSxMobile } from '../styles';
import { useLazyGetUserByEmailQuery } from '../../../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const AddByUserEmail = ({
  setFoundUserByEmail,
  participants,
  setParticipants,
  addPlayerResult,
  setIsLoading
}) => {
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState(null);
  const [trigger, result] = useLazyGetUserByEmailQuery();
  const { game } = useSelector(({ app }) => app);

  const handleFindUser = async () => {
    trigger({ userEmail, query: `?gameId=${game.id}` });
  };

  useEffect(() => {
    if (addPlayerResult?.data?.errorMessage) {
      setError(addPlayerResult?.data?.errorMessage);
    } else {
      setError(null);
    }
  }, [addPlayerResult]);

  useEffect(() => {
    if (participants[0]?.userEmail !== userEmail) {
      setParticipants([]);
      setError(null);
    }
  }, [userEmail]);

  useEffect(() => {
    setIsLoading(result.isLoading);
    if (result.isError) {
      setFoundUserByEmail(false);
      setError('User not found');
    } else if (result.isSuccess && !result.isFetching) {
      const {
        data: { data }
      } = result;
      const { id } = data;
      setError(null);
      setParticipants([
        {
          userId: id,
          userEmail
        }
      ]);
      setFoundUserByEmail(true);
    }
  }, [result]);

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap="1rem"
          >
            <TextField
              id="add-participant-type-email-search-input"
              data-testid="add-participant-type-email-search-input"
              label="User Email"
              onChange={event => setUserEmail(event.target.value)}
              value={userEmail}
              inputProps={{
                sx: inputTextSxMobile
              }}
              InputLabelProps={{
                sx: inputTextSxMobile
              }}
            />
            <LoadingButton
              id="add-participant-type-email-search-button"
              data-testid="add-participant-type-email-search-button"
              loading={result.isFetching}
              onClick={handleFindUser}
              variant="contained"
            >
              Find
            </LoadingButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {error ? (
            <Typography
              textAlign={{ xs: 'center', sm: 'left' }}
              color="error"
              variant="body2"
              pl={{ xs: 0, sm: 1 }}
            >
              {getReadableErrorMessage(error)}
            </Typography>
          ) : null}
        </Grid>
        {!error && result?.data?.data && (
          <Grid item xs={12}>
            <Grid container>
              <Grid container alignItems="center" pl={1} spacing={2}>
                <Grid item>
                  <Avatar src={result.data.data.imageUrl || null} />
                </Grid>
                <Grid item>
                  <Box flexDirection="column">
                    <Typography variant="body2">
                      {`${result.data.data.firstName} ${result.data.data.lastName}`}
                    </Typography>
                    <Typography variant="body2">
                      {result.data.data.teamName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AddByUserEmail;
