import React from 'react';
import { Avatar, Grid } from '@mui/material';
import { createCrown } from '../../Shared/Overlay';

const ProfileImage = ({ avatarImageUrl, size = 72, rankIcon, rankNumber }) => {
  return (
    <Grid position="relative">
      <Avatar
        src={avatarImageUrl}
        sx={{ height: `${size}px`, width: `${size}px` }}
      />
      {rankNumber > 0 && rankNumber < 4 && createCrown(rankNumber)}

      {rankIcon && (
        <Grid
          sx={{
            position: 'absolute',
            bottom: `-${size / 3}px`,
            right: `-${size / 24}px`,
            width: `${size / 2}px`
          }}
        >
          <img src={rankIcon} width={'100%'} height={'auto'} />
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileImage;
