import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { Typography } from '@mui/material';
import { deckTubeStyle } from './styles';
import { isEmpty } from 'lodash';

const DeckTagsTube = ({ tags = [] }) => {
  const tagsText = tags.map(tag => tag.name).join(' ');
  if (isEmpty(tagsText)) {
    return null;
  }
  return (
    <TubeCard className={'deck-tube'} sx={deckTubeStyle}>
      <Typography className={'deck-tube-typography'} textAlign={'center'}>
        {tagsText}
      </Typography>
    </TubeCard>
  );
};

export default DeckTagsTube;
