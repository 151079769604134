export const drawerStyle = ({
  isDrawer,
  theme,
  offsetTop,
  deckDetailRef,
  height,
  defaultHeight
}) => ({
  position: 'fixed',
  zIndex: 1000,
  ...(!isDrawer && {
    position: 'sticky',
    top: offsetTop.xs,
    minHeight: deckDetailRef?.current ? 'unset' : defaultHeight,
    maxHeight: height,
    overflow: 'scroll',
    borderRadius: '12px',
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `${theme.palette.primary.main} 0px 0px 8px 1px`
  }),
  ['& .MuiDrawer-paper']: {
    top: offsetTop,
    position: isDrawer ? 'fixed' : 'relative',
    width: { xs: isDrawer ? '85vw' : 'unset', sm: '250px' },
    maxWidth: '100%',
    paddingBottom: offsetTop
  }
});
