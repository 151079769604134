import { Box, MenuItem, Typography } from '@mui/material';

import React from 'react';
import { ViewSelector } from './styles';

const DeckViewSelector = ({ view, setView }) => {
  return (
    <Box display="flex" width="100%" justifyContent="flex-end" mb={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" sx={{ marginRight: 2 }}>
          View
        </Typography>
        <ViewSelector
          id="deck-preview-view-select"
          data-testid="deck-preview-view-select"
          value={view}
          onChange={e => setView(e.target.value)}
        >
          <MenuItem
            id="deck-preview-text-view-option"
            data-testid="deck-preview-text-view-option"
            value="text"
          >
            Text
          </MenuItem>
          <MenuItem
            id="deck-preview-stacks-view-option"
            data-testid="deck-preview-stacks-view-option"
            value="stacks"
          >
            Stacks
          </MenuItem>
        </ViewSelector>
      </Box>
    </Box>
  );
};

export default DeckViewSelector;
