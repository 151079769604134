import Community from './Community';
import Explore from './Explore';
import Featured from './Featured';
import { Grid } from '@mui/material';
import Header from './Header';
import React from 'react';

export const LandingPage = () => {
  return (
    <Grid
      id="landing-page-container"
      data-testid="landing-page-container"
      container
      flexDirection={'column'}
    >
      <Header />
      <Featured />
      <Explore />
      <Community />
    </Grid>
  );
};

export default LandingPage;
