import React, { useContext, useEffect, useState } from 'react';
import { debounce, get } from 'lodash';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import FormatsSwiperComponent from '../../../components/Rankings/Swiper/FormatsSwiperComponent';
import GeneralContainer from '../../../components/Shared/Cards/GeneralCard';
import HowRankingsWorkModal from '../ModalPages/HowRankingsWorkModal';
import HowToGetSeasonPointsModal from '../ModalPages/HowToGetSeasonPointsModal';
import InfiniteScroll from '../../../components/InfiniteScroll';
import Loader from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import { ModalContext } from '../../../components/Modal/Context/ModalContext';
import RankingCard from './RankingCard';
import RewardCard from './RewardCard';
import { StyledSearchTextField } from '../../../components/Shared/TextFields';
import UserRankDetailsPage from '../ModalPages/UserRankDetailsPage';
import { rankingListContainerStyle } from './style';
import { useGetRankingsQuery } from '../../../services/apis/organizePlayApi';
import { usePrevious } from '../../../utils/usePrevious';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  limit: 25
};

const RankingsMainPage = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const { game } = useSelector(({ app }) => app);
  const [formatId, setFormatId] = useState('');
  const [seasonId, setSeasonId] = useState('');
  const [rankingType, setRankingType] = useState('all');
  const [seasonRankingSortType, setSeasonRankingSortType] = useState('rank');
  const theme = useTheme();
  const [query, setQuery] = useState('');
  const [currentPaginationData, setCurrentPaginationData] = useState(
    DEFAULT_PAGINATION_OPTIONS
  );
  const [rankings, setRankings] = useState([]);
  const previousSearchInput = usePrevious(searchInput);
  const playerTerm = get(game, 'terminology.player', 'Player');

  const { addModal } = useContext(ModalContext);
  const { rankingsExplanationModal } = useFlags();

  const resetPagination = () => {
    setCurrentPaginationData(DEFAULT_PAGINATION_OPTIONS);
  };

  useEffect(() => {
    const { page, limit } = currentPaginationData;
    const queryParams = {
      gameId: game.id,
      seasonId,
      formatId,
      searchText,
      seasonSortBy: seasonRankingSortType
    };
    const paramKeys = Object.keys(queryParams);
    const updatedQuery = [];
    paramKeys.forEach(paramKey => {
      if (queryParams[paramKey]) {
        updatedQuery.push(`${paramKey}=${queryParams[paramKey]}`);
      }
    });
    updatedQuery.push(`page=${page}`);
    updatedQuery.push(`limit=${limit}`);
    setQuery(updatedQuery.join('&'));
  }, [
    seasonId,
    game,
    formatId,
    currentPaginationData,
    searchText,
    seasonRankingSortType
  ]);

  const formats = [
    { name: 'All' },
    ...get(game, 'formats', []).filter(
      format => get(format, 'gameplay.type', '') === 'competitive'
    )
  ];

  const seasonRankingSortOptions = [
    {
      _id: 'rank',
      name: 'Rank'
    },
    {
      _id: 'elo',
      name: 'ELO Score'
    },
    {
      _id: 'seasonPoints',
      name: 'Season Points'
    }
  ];

  const { data, isLoading, isFetching } = useGetRankingsQuery(query, {
    skip: !query
  });

  // eslint-disable-next-line no-unused-vars
  const { activePage, totalPages, totalResults } = get(data, 'pagination', {});

  useEffect(() => {
    const { activePage } = get(data, 'pagination', 1);
    if (data) {
      if (activePage === 1) {
        setRankings(data.rankings);
      } else {
        setRankings(prevState => [...prevState, ...data.rankings]);
      }
    }
  }, [data]);

  const handleRankingChange = (format, rankingTypeSelected) => {
    if (
      (rankingType !== 'seasons' && !rankingTypeSelected) ||
      rankingTypeSelected === 'all'
    ) {
      resetPagination();
      setSeasonId(null);
      setFormatId(get(format, '_id', ''));
    } else {
      resetPagination();
      setFormatId(null);
      setSeasonId(get(format, '_id', ''));
    }
  };

  const searchByText = searchQuery => {
    resetPagination();
    setSearchText(searchQuery);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => searchByText(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput]);

  const handleSearch = e => {
    setSearchInput(get(e, 'target.value', ''));
  };

  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(data, 'pagination', {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    });
    if (!isLoading && !isFetching && activePage < totalPages) {
      setCurrentPaginationData(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  const handleSeasonSortTypeChange = newSeasonSortType => {
    const lastSeasonSortType = get(query, 'seasonSortType', null);
    if (!lastSeasonSortType && newSeasonSortType !== lastSeasonSortType) {
      resetPagination();
    }
    setSeasonRankingSortType(newSeasonSortType);
  };

  return (
    <Grid
      id="rankings-container"
      data-testid="rankings-container"
      className={'p-top'}
      container
    >
      <Grid container spacing={4}>
        <Grid xs={12} item>
          <MaxWidthContainer
            container
            className={'p-percent p-top'}
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <Typography variant={'h1'} data-testid="rankings-title">
              Rankings
            </Typography>
            {get(game, 'seasons', []).length > 0 && (
              <FormControl
                sx={{
                  m: 1,
                  ml: { xs: 0, sm: 4 },
                  mt: { xs: 2, sm: 1 },
                  minWidth: 120
                }}
                size="small"
              >
                <Select
                  id="rankings-time-format-select"
                  data-testid="rankings-time-format-select"
                  value={rankingType}
                  onChange={event => {
                    setRankingType(event.target.value);
                    if (event.target.value === 'all') {
                      handleRankingChange(null, event.target.value);
                    } else {
                      handleRankingChange(
                        get(game, 'seasons', [])[0],
                        event.target.value
                      );
                    }
                  }}
                  sx={{
                    '#rankings-time-format-select': {
                      display: 'flex',
                      width: '100px',
                      alignItems: 'center',
                      padding: '0 24px'
                    }
                  }}
                >
                  <MenuItem
                    id="rankings-time-format-all-time-option"
                    data-testid="rankings-time-format-all-time-option"
                    value="all"
                  >
                    <Typography>All Time</Typography>
                  </MenuItem>
                  <MenuItem
                    id="rankings-time-format-seasons-option"
                    data-testid="rankings-time-format-seasons-option"
                    value="seasons"
                  >
                    <Typography>Seasons</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </MaxWidthContainer>
        </Grid>

        <Grid
          id="rankings-formats-select-section"
          data-testid="rankings-formats-select-section"
          item
          xs={12}
        >
          <FormatsSwiperComponent
            activeRankType={rankingType}
            formats={formats}
            onClick={handleRankingChange}
            seasons={get(game, 'seasons', [])}
          />
        </Grid>

        {rankingType === 'seasons' ? (
          <Grid container mb={4} mt={4} xs={12} pl={4}>
            <MaxWidthContainer className={'p-percent'} container>
              <Typography mb={4} mr={1} variant="h2">
                Season Rewards
              </Typography>
              <Grid container mt={2} justifyContent="space-between">
                {get(data, 'rewards.placing') &&
                  data.rewards.placing.map(reward => (
                    <RewardCard
                      key={reward.id}
                      reward={reward}
                      totalRewards={get(data, 'rewards.placing', []).length}
                    />
                  ))}
              </Grid>

              <Grid container>
                <Grid xs={12} sm={6}>
                  <Button
                    id="rankings-season-points-help-button"
                    data-testid="rankings-season-points-help-button"
                    onClick={() =>
                      addModal({
                        children: (
                          <HowToGetSeasonPointsModal seasonId={seasonId} />
                        ),
                        style: { maxWidth: '700px !important' }
                      })
                    }
                    sx={{
                      color: theme.palette.text.primary,
                      padding: '4px'
                    }}
                  >
                    <Typography mr={1} variant="body2">
                      {`How does ${get(
                        game,
                        'terminology.points.seasonTotal',
                        'Season Points'
                      )} work`}
                    </Typography>
                    <HelpIcon />
                  </Button>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    alignItems="center"
                  >
                    <FormControl
                      sx={{
                        m: 1,
                        ml: { xs: 0, sm: 4 },
                        mt: { xs: 2, sm: 1 },
                        minWidth: 120
                      }}
                      size="small"
                    >
                      <Select
                        id="season-ranking-sort-by-select"
                        data-testid="season-ranking-sort-by-select"
                        value={seasonRankingSortType}
                        onChange={event =>
                          handleSeasonSortTypeChange(event.target.value)
                        }
                        sx={{
                          '#season-ranking-sort-by-select': {
                            display: 'flex',
                            width: '140px',
                            alignItems: 'center',
                            padding: '0 24px'
                          }
                        }}
                      >
                        {seasonRankingSortOptions.map(({ _id, name }) => {
                          return (
                            <MenuItem
                              id={`season-ranking-sort-by-${_id}-option`}
                              data-testid={`season-ranking-sort-by-${_id}-option`}
                              value={_id}
                              key={_id}
                            >
                              <Typography>{name}</Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </MaxWidthContainer>
          </Grid>
        ) : (
          <Grid container mb={4} mt={4} xs={12} pl={4}>
            <MaxWidthContainer className={'p-percent'} container>
              {rankingsExplanationModal ? (
                <Grid container>
                  <Button
                    onClick={() =>
                      addModal({
                        children: <HowRankingsWorkModal seasonId={seasonId} />,
                        style: { maxWidth: '700px !important' }
                      })
                    }
                    sx={{
                      color: theme.palette.text.primary,
                      padding: '4px'
                    }}
                  >
                    <Typography mr={1} variant="body2">
                      How do rankings work
                    </Typography>
                    <HelpIcon />
                  </Button>
                </Grid>
              ) : (
                <Grid container justifyContent="space-between">
                  {game.ranks.map(rank => {
                    if (rank.iconUrl) {
                      return (
                        <Grid
                          container
                          key={rank.name}
                          justifyContent="center"
                          sx={{
                            width: {
                              xs: '48%',
                              md: `calc(100% / ${game.ranks.length})`
                            }
                          }}
                        >
                          <Box
                            sx={{
                              backgroundImage: `url(${rank.iconUrl})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'contain',
                              height: '80px',
                              width: '80px'
                            }}
                          />
                          <Grid
                            justifyContent="center"
                            container
                            flexDirection="column"
                            sx={{
                              mb: { xs: 4, md: 0 },
                              mt: 2,
                              textAlign: 'center'
                            }}
                          >
                            <Typography>{rank.name}</Typography>
                            {!rank.minThreshold && !rank.maxThreshold && (
                              <Typography variant="body2">{`#1 ${playerTerm}`}</Typography>
                            )}
                            {rank.minThreshold && rank.maxThreshold && (
                              <Typography variant="body2">{`${rank.minThreshold} to ${rank.maxThreshold}`}</Typography>
                            )}
                            {rank.minThreshold && !rank.maxThreshold && (
                              <Typography variant="body2">{`${rank.minThreshold}+`}</Typography>
                            )}
                            {!rank.minThreshold && rank.maxThreshold && (
                              <Typography variant="body2">{`Below ${rank.maxThreshold}`}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                    return (
                      <Grid
                        container
                        key={rank.name}
                        mb={2}
                        justifyContent="center"
                        sx={{ width: { xs: '100%', md: '48%' } }}
                      >
                        <Grid
                          justifyContent="center"
                          container
                          flexDirection="column"
                          sx={{ mb: 4, mt: 2, textAlign: 'center' }}
                        >
                          <Typography>{rank.name}</Typography>
                          {!rank.minThreshold && !rank.maxThreshold && (
                            <Typography variant="body2">{`#1 ${playerTerm}`}</Typography>
                          )}
                          {rank.minThreshold && rank.maxThreshold && (
                            <Typography variant="body2">{`${rank.minThreshold} to ${rank.maxThreshold}`}</Typography>
                          )}
                          {rank.minThreshold && !rank.maxThreshold && (
                            <Typography variant="body2">{`${rank.minThreshold}+`}</Typography>
                          )}
                          {!rank.minThreshold && rank.maxThreshold && (
                            <Typography variant="body2">{`Below ${rank.maxThreshold}`}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </MaxWidthContainer>
          </Grid>
        )}

        <Grid item xs={12}>
          <MaxWidthContainer className={'p-percent'} container>
            <StyledSearchTextField
              id="rankings-search-input"
              onChange={handleSearch}
              value={searchInput}
            />
          </MaxWidthContainer>
        </Grid>

        <Grid item xs={12}>
          <MaxWidthContainer
            className={'p-percent'}
            container
            sx={rankingListContainerStyle}
          >
            <InfiniteScroll
              done={activePage >= totalPages || !data}
              emptyMessage={
                searchText
                  ? 'No rankings found for the search'
                  : 'No Rankings Yet...'
              }
              isLoading={isLoading || isFetching}
              loader={isLoading || isFetching ? <Loader /> : null}
              loaderAction={loadAction}
              shouldScrollToTop={false}
              emptyResultContainerStyle={{
                position: 'relative',
                minHeight: '50vh'
              }}
            >
              {rankings && rankings.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 3,
                    justifyContent: 'space-around'
                  }}
                >
                  {rankings.map(ranking => (
                    <GeneralContainer
                      buttonStyle={{ padding: '24px' }}
                      onClick={() =>
                        addModal({
                          children: (
                            <UserRankDetailsPage
                              userId={ranking.userId}
                              formatId={formatId}
                              seasonId={seasonId}
                              rankIcon={ranking.rankIcon}
                              rankName={ranking.rankName}
                              rankNumber={ranking.rankNumber}
                              teamName={get(ranking, 'teamName')}
                            />
                          ),
                          style: { maxWidth: '700px !important' },
                          compactOnSmallDevice: true
                        })
                      }
                      key={ranking.userId}
                    >
                      <RankingCard
                        activeRankingType={rankingType}
                        user={ranking}
                        textAlign={'md'}
                      />
                    </GeneralContainer>
                  ))}
                </Box>
              )}
            </InfiniteScroll>
          </MaxWidthContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RankingsMainPage;
