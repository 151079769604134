import { refetchUserData } from '../../store/app/actions';
import { useDispatch } from 'react-redux';

export function useRefetchUserInfo() {
  const dispatch = useDispatch();

  const update = () => {
    dispatch(refetchUserData());
  };

  return {
    update
  };
}
