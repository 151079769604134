import { Grid, Menu, MenuItem } from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MoreOptions } from './styles';
import React from 'react';
import { kebabCase } from 'lodash';
import { useState } from 'react';
const MoreOptionsMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCallback = callback => {
    callback();
    handleClose();
  };
  const isValidChildren = children.some(child => !!child);
  return (
    <>
      {isValidChildren && (
        <Grid item>
          <MoreOptions
            id="more-options-menu-button"
            data-testid="more-options-menu-button"
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </MoreOptions>
          <Menu
            id="more-options-menu"
            data-testid="more-options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                transform: 'translateY(5px)'
              }
            }}
          >
            {!children.length && (
              <MenuItem
                id={`${kebabCase(
                  child.props.children.props.children
                )}-menu-option`}
                data-testid={`${kebabCase(
                  child.props.children.props.children
                )}-menu-option`}
                onClick={() => handleCallback(children.props.onClick)}
              >
                {children.props.children.props.children}
              </MenuItem>
            )}
            {children.length &&
              children.map((child, index) => {
                if (child) {
                  return (
                    <MenuItem
                      id={`${kebabCase(
                        child.props.children.props.children
                      )}-menu-option`}
                      data-testid={`${kebabCase(
                        child.props.children.props.children
                      )}-menu-option`}
                      key={index}
                      onClick={() => handleCallback(child.props.onClick)}
                      sx={{ padding: 2 }}
                    >
                      {child.props.children.props.children}
                    </MenuItem>
                  );
                }
              })}
          </Menu>
        </Grid>
      )}
    </>
  );
};

export default MoreOptionsMenu;
