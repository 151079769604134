import { useEffect, useState } from 'react';

import { get } from 'lodash';

export function usePagination({
  data,
  dataKey = 'data',
  setCurrentPage,
  isLoading = false,
  isFetching = false
}) {
  const [treatedData, setTreatedData] = useState();
  const [isSettingUp, setIsSettingUp] = useState(true);

  const { activePage, nextPage, resultsThisPage, totalResults } = get(
    data,
    'pagination',
    {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    }
  );

  const loadAction = () => {
    if (!isLoading && !isFetching && nextPage > 0) {
      setCurrentPage && setCurrentPage(activePage + 1);
    }
  };

  useEffect(() => {
    const extractedData = get(data, dataKey, []);
    if (data) {
      if (activePage === 1) {
        setTreatedData(extractedData);
      } else {
        setTreatedData(prevState => [...prevState, ...extractedData]);
      }
      if (isSettingUp) {
        setIsSettingUp(false);
      }
    }
  }, [data]);

  const resetPagination = () => {
    setCurrentPage(1);
    setIsSettingUp(true);
  };

  return {
    loadAction,
    treatedData,
    nextPage,
    resultsThisPage,
    totalResults,
    resetPagination,
    isSettingUp,
    setIsSettingUp
  };
}
