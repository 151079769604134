export const playerStatus = {
  active: { title: 'Checked In', key: 'active' },
  registered: { title: 'Registered', key: 'registered' },
  unregistered: { title: 'Unregistered', key: 'unregistered' },
  dropped: { title: 'Dropped', key: 'dropped' },
  eliminated: { title: 'Eliminated', key: 'eliminated' }
};

export const eventStatuses = {
  registration: { title: 'Start Event', key: 'registration' },
  started: { title: 'Event Started', key: 'started' },
  complete: { title: 'Event Complete', key: 'complete' }
};

export const eventStatusDisplayConfig = {
  [eventStatuses.registration.key]: {
    displayText: 'Upcoming',
    color: '#FEB705'
  },
  [eventStatuses.started.key]: {
    displayText: 'In Progress',
    color: 'success'
  },
  [eventStatuses.complete.key]: {
    displayText: 'Complete',
    color: 'primary'
  }
};
export const tournamentFormatOptions = [
  {
    _id: 'swiss',
    name: 'Swiss'
  },
  {
    _id: 'single-elimination',
    name: 'Single elimination'
  }
];
