import { UpdateUserTypes } from '../../hooks/user/useUpdateUserInfo';
import { get, unset } from 'lodash';
import { userAddress } from '../../values/location';

export const createDefaultUserInfo = ({
  updateUserType = UpdateUserTypes.Default,
  user
}) => {
  const userInfo = {
    profile: {
      firstName: get(user, 'details.info.firstName', ''),
      lastName: get(user, 'details.info.lastName', ''),
      nickname: get(user, 'details.info.nickname', ''),
      email: get(user, 'details.info.email', '')
    },
    location: {
      ...get(user, 'details.location', userAddress)
    },
    teamName: get(user, 'details.gameUser.teamName', '')
  };

  switch (updateUserType) {
    case UpdateUserTypes.ShippingAddress:
      unset(userInfo, 'profile.nickname');
      unset(userInfo, 'profile.email');
      unset(userInfo, 'teamName');
      break;
    default:
      break;
  }

  return userInfo;
};
