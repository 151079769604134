import { Box, Grid, Typography, alpha, styled } from '@mui/material';
import {
  CustomerBehaviorIcon,
  IncreaseRevenueIcon,
  StreamLineIcon
} from '../../../../../../components/Icon/vectors';
import React, { useEffect } from 'react';
import {
  useGetPayoutAccountQuery,
  usePostCreatePayoutAccountAndLinkMutation,
  usePostCreatePayoutAccountLinkMutation
} from '../../../../../../services/apis/organizePlayApi';

import DoneIcon from '@mui/icons-material/Done';
import Loading from '../../../../../../components/Loading/Loader';
import { LoadingButton } from '@mui/lab';
import StripeText from '../../../../../../assets/images/stripe_text.png';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useCustomNavigation } from '../../../../../../hooks/navigation/useCustomNavigation';

const StyledConnectedContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center'
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledConnectedStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.6)}`,
  borderRadius: '16px',
  padding: '8px 16px'
}));

const StyledPaymentActionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.3),
  padding: '16px',
  borderRadius: '12px',
  border: `1px solid ${alpha(theme.palette.background.default, 0.6)}`
}));

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.3),
  padding: '32px',
  borderRadius: '12px',
  border: `1px solid ${alpha(theme.palette.background.default, 0.6)}`,
  height: '100%'
}));

const StorePayout = ({ storeData, gameId }) => {
  const organizationId = get(storeData, 'pgData.organization.id', null);
  const { buildUrlPath } = useCustomNavigation();
  const { data: paymentData, isLoading, isFetching } = useGetPayoutAccountQuery(
    {
      organizationId,
      gameId
    },
    {
      skip: !gameId || !organizationId
    }
  );
  const [
    createAndLinkPayoutAccount,
    createAndLinkPayoutAccountResult
  ] = usePostCreatePayoutAccountAndLinkMutation();
  const [
    createAccountLink,
    createAccountLinkResult
  ] = usePostCreatePayoutAccountLinkMutation();
  const isStorePayoutLoading = isLoading || isFetching;
  const isAccountOnboardingComplete = get(
    paymentData,
    'data.payoutEnabled',
    false
  );
  const ifPayoutAvailable = get(paymentData, 'data.metaData.account.id', null);

  const sections = [
    {
      key: 'streamline',
      Icon: StreamLineIcon,
      title: 'Streamlined Management',
      description:
        'Simplify event payments by connecting your payment account during event creation. Say goodbye to manual invoicing and cash handling'
    },
    {
      key: 'increasedRevenue',
      Icon: IncreaseRevenueIcon,
      title: 'Increased Revenue',
      description:
        'Convenient online payments attract more participants, boosting registrations and revenue for your events.'
    },
    {
      key: 'enhancedExperience',
      Icon: CustomerBehaviorIcon,
      title: 'Enhanced Experience',
      description:
        'Provide a seamless payment process for attendees, improving their overall event experience and satisfaction'
    }
  ];

  const getReqBody = () => {
    return {
      gameId,
      organizationId,
      body: {
        refreshUrl: buildUrlPath(
          `/payouts/refresh?entity=${storeData._id}&organization=${organizationId}`
        ),
        returnUrl: buildUrlPath(
          `/payouts/callback?entity=${storeData._id}&organization=${organizationId}`
        )
      }
    };
  };

  const onClickConnect = () => {
    createAndLinkPayoutAccount(getReqBody());
  };

  const onClickCompleteOnboarding = () => {
    createAccountLink(getReqBody());
  };

  useEffect(() => {
    if (createAccountLinkResult.isSuccess) {
      const redirectUrl = get(
        createAccountLinkResult,
        'data.data.metaData.accountLink.url',
        ''
      );
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        toast.error(
          get(
            createAccountLinkResult,
            'error.data.errorMessage',
            'Error while creating account onboarding link'
          )
        );
      }
    }
  }, [createAccountLinkResult]);

  useEffect(() => {
    if (createAndLinkPayoutAccountResult.isSuccess) {
      const redirectUrl = get(
        createAndLinkPayoutAccountResult,
        'data.data.metaData.accountLink.url',
        ''
      );
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        toast.error(
          get(
            createAndLinkPayoutAccountResult,
            'error.data.errorMessage',
            'Error while creating account onboarding link'
          )
        );
      }
    }
  }, [createAndLinkPayoutAccountResult]);

  const renderAction = () => {
    if (ifPayoutAvailable && !isAccountOnboardingComplete) {
      return (
        <LoadingButton
          onClick={onClickCompleteOnboarding}
          loading={createAccountLinkResult?.isLoading}
          disabled={createAccountLinkResult?.isLoading}
          variant="outlined"
          sx={{ padding: '8px 32px' }}
        >
          Complete Onboarding
        </LoadingButton>
      );
    }
    if (ifPayoutAvailable && isAccountOnboardingComplete) {
      return (
        <StyledConnectedContainer>
          <StyledConnectedStatus>
            <DoneIcon color="primary" sx={{ marginRight: '16px' }} />
            <Typography variant="body1Bold" color="primary">
              Connected
            </Typography>
          </StyledConnectedStatus>
        </StyledConnectedContainer>
      );
    }
    return (
      <LoadingButton
        loading={createAndLinkPayoutAccountResult?.isLoading}
        disabled={createAndLinkPayoutAccountResult?.isLoading}
        variant="contained"
        sx={{ padding: '8px 32px' }}
        onClick={onClickConnect}
      >
        Connect
      </LoadingButton>
    );
  };
  if (isStorePayoutLoading) {
    return <Loading />;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledPaymentActionContainer container mt={2}>
          <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'start' }}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              justifyContent={{
                xs: 'center',
                sm: 'start'
              }}
            >
              <img src={StripeText} alt="stripe" width="110px" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'end' }}>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              justifyContent={{
                xs: 'center',
                sm: 'flex-end'
              }}
            >
              {renderAction()}
            </Box>
          </Grid>
        </StyledPaymentActionContainer>
      </Grid>
      <Grid item xs={12} mt={4} textAlign="center">
        <Typography variant="h4">Why connect your payment account?</Typography>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container alignItems="stretch" spacing={2}>
          {sections.map(section => (
            <Grid item sm={12} md={4} key={section.key}>
              <StyledSectionContainer className="payout_info_sections">
                {<section.Icon />}
                <Typography variant="h4" mt={2} mb={2}>
                  {section.title}
                </Typography>
                <Typography variant="body2Light">
                  {section.description}
                </Typography>
              </StyledSectionContainer>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StorePayout;
