import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import Lottie from 'react-lottie';
import { Box, Grid, Typography } from '@mui/material';

import * as judgeBadgeAnimation from '../../../assets/animations/judge/judge.json';
import * as metazooJudgeBadgeAnimation from '../../../assets/animations/judge/metazoo.json';

import { formatDate } from '../../../utils/date';

const JudgeSection = () => {
  const { game, user } = useSelector(({ app }) => app);

  const lottieSize = { xs: 200, sm: 300 };

  const judgeAnimations = {
    metazoo: metazooJudgeBadgeAnimation
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: judgeAnimations[game.slug] || judgeBadgeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <Grid alignItems="center" justifyContent="center" container>
        <Grid>
          <Box sx={{ height: lottieSize, width: lottieSize }}>
            <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
          </Box>
        </Grid>
        <Grid ml={{ xs: 0, md: 4 }}>
          <Typography
            mb={2}
            variant="h3"
            sx={{ textAlign: { xs: 'center', sm: 'start' } }}
          >
            Certified {get(user, 'permissions.judge.levelName', '')}
          </Typography>
          <Grid
            alignItems="center"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            container
            mb={1}
          >
            <Typography variant="h5">Judge ID:</Typography>
            <Typography ml={1} mt={0.25} variant="body1">
              {get(user, 'permissions.judge.judgeId', '')}
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            container
          >
            <Typography variant="h6" textAlign={'center'}>
              Certification Date:
            </Typography>
            <Typography ml={1} mt={0.25} variant="body2" textAlign={'center'}>
              {formatDate(get(user, 'permissions.judge.certificationDate'))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JudgeSection;
