import { Avatar, Box, IconButton, Typography, styled } from '@mui/material';
import React, { useContext } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { JudgeRemoveConfirm } from './JudgeRemoveConfirm';
import { ModalContext } from '../../Modal/Context/ModalContext';
import { get } from 'lodash';
import { getJudgeFullName } from '../../../utils/judge-user';

const JudgeInfoContainer = styled(Box)`
  background: ${({ theme }) => theme.events.cardPrimaryColor};
  padding: 12px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
`;

const JudgeImageAndNameContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EventJudgeSingleInfo = ({
  eventId,
  judgeInfo = {},
  isOrganizer = false
}) => {
  const { addModal } = useContext(ModalContext);
  const judgeName = getJudgeFullName(judgeInfo);

  const openRemoveConfirmDialog = () => {
    addModal({
      children: <JudgeRemoveConfirm eventId={eventId} judgeInfo={judgeInfo} />,
      isOpen: true
    });
  };

  return (
    <JudgeInfoContainer>
      <JudgeImageAndNameContainer>
        <Avatar
          src={get(judgeInfo, 'gameUser.imageUrl', '')}
          sx={{ height: '64px', width: '64px' }}
        />
        <Box ml={2}>
          <Typography variant="h5">{judgeName}</Typography>
        </Box>
      </JudgeImageAndNameContainer>
      <Box>
        {isOrganizer && (
          <IconButton
            id="event-page-remove-judge-button"
            data-testid="event-page-remove-judge-button"
            onClick={openRemoveConfirmDialog}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Box>
    </JudgeInfoContainer>
  );
};
