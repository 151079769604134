import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getSelectedNode } from '../utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { StyledEditIcon, LinkWrapper, LinkEditor } from './styles';
import { Link } from '@mui/material';

const LowPriority = 1;
const FloatingLinkEditor = ({ editor }) => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  function positionEditorElement(editor, rect, nativeSelection) {
    if (rect === null) {
      editor.style.opacity = '0';
      editor.style.top = '-1000px';
      editor.style.left = '-1000px';
    } else {
      editor.style.opacity = '1';
      editor.style.top = `${rect.top +
        rect.height +
        window.pageYOffset +
        10}px`;
      editor.style.left = `${window.pageXOffset +
        (nativeSelection.focusNode.length / 2) * 10}px`;
    }
  }

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;
    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      rootElement !== null &&
      nativeSelection.focusOffset !== nativeSelection.focusNode.length &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect, nativeSelection);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  const registerLink = event => {
    const linkObj = {
      url: linkUrl,
      target: '_blank',
      rel: 'noopener noreferrer'
    };
    if (event.key === 'Enter') {
      event.preventDefault();
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkObj);
      setEditMode(false);
    } else if (event.type === 'mouseleave') {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkObj);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setEditMode(false);
    }
  };

  return (
    <LinkEditor ref={editorRef}>
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={event => {
            setLinkUrl(event.target.value);
          }}
          onMouseLeave={event => {
            if (lastSelection !== null && linkUrl !== '') {
              registerLink(event);
            }
          }}
          onKeyDown={event => {
            if (lastSelection !== null && linkUrl !== '') {
              registerLink(event);
            }
          }}
        />
      ) : (
        <>
          <LinkWrapper>
            <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </Link>
            <StyledEditIcon
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </LinkWrapper>
        </>
      )}
    </LinkEditor>
  );
};

export default FloatingLinkEditor;
