import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { Grid, TextField } from '@mui/material';

import { GooglePlaceTextField } from '../../../../components/Shared/GooglePlaceTextField';
import React from 'react';
import { StyledSelectTextField } from '../../../../components/Shared/TextFields';
import { kebabCase } from 'lodash';
import { useSelector } from 'react-redux';

export const AllEventsSearch = ({ dateChange, setUserInput, userInput }) => {
  const { game } = useSelector(({ app }) => app);
  const formats = [{ _id: '', name: 'Show All' }, ...game.formats];
  const dateInfo = [
    { title: 'Start Date', key: 'startDate', value: userInput.startDate },
    { title: 'End Date', key: 'endDate', value: userInput.endDate }
  ];
  const radiusPredefinedMiles = [25, 50, 100, 250, 500];
  const radiusOptions = [
    ...radiusPredefinedMiles.map(predefinedMile => {
      return {
        _id: predefinedMile,
        name: `${predefinedMile} miles`
      };
    })
  ];
  const locationTypeOptions = [
    {
      _id: 'anywhere',
      name: 'Anywhere'
    },
    {
      _id: 'in-person',
      name: 'In Person'
    },
    {
      _id: 'online',
      name: 'Online'
    }
  ];

  const locationTypeChange = event => {
    const updateObj = {
      locationType: event.target.value
    };
    if (updateObj.locationType !== 'in-person') {
      updateObj.location = null;
      updateObj.radius = 50;
    }
    setUserInput({
      ...userInput,
      ...updateObj
    });
  };

  return (
    <Grid
      id="events-search-section"
      data-testid="events-search-section"
      item
      xs={12}
    >
      <Grid container>
        {/* Event Search Input */}
        <Grid item xs={12}>
          <TextField
            id="events-name-search-input"
            data-testid="events-name-search-input"
            inputProps={{
              'data-testid': 'events-name-search-input-text',
              id: 'events-name-search-input-text'
            }}
            label={'Event Name'}
            property={'eventName'}
            onChange={e => setUserInput({ ...userInput, name: e.target.value })}
          />
        </Grid>

        {/* Start and End Dates Inputs */}
        <Grid item xs={12} mt={'49px'}>
          <Grid container rowSpacing={'49px'} columnSpacing={'36px'}>
            {dateInfo.map(obj => (
              <Grid item sm={6} xs={12} key={obj.key}>
                <Grid item xs={12} display={{ xs: 'none', sm: 'unset' }}>
                  <DesktopDatePicker
                    id={`events-${kebabCase(obj.title)}-date-picker-select`}
                    data-testid={`events-${kebabCase(
                      obj.title
                    )}-date-picker-select`}
                    label={obj.title}
                    inputFormat={'MM/DD/YYYY'}
                    value={obj.value}
                    onChange={event => dateChange(event, obj.key)}
                    renderInput={params => (
                      <TextField
                        id={`events-${kebabCase(
                          obj.title
                        )}-date-picker-select-textfield`}
                        data-testid={`events-${kebabCase(
                          obj.title
                        )}-date-picker-select-textfield`}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': `events-${kebabCase(
                            obj.title
                          )}-date-picker-select-input`,
                          id: `events-${kebabCase(
                            obj.title
                          )}-date-picker-select-input`
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} display={{ xs: 'unset', sm: 'none' }}>
                  <MobileDatePicker
                    id={`events-${kebabCase(obj.title)}-date-picker-select`}
                    data-testid={`events-${kebabCase(
                      obj.title
                    )}-date-picker-select`}
                    label={obj.title}
                    inputFormat={'MM/DD/YYYY'}
                    value={obj.value}
                    componentsProps={{
                      actionBar: {
                        actions: ['accept', 'clear']
                      }
                    }}
                    onChange={event => dateChange(event, obj.key)}
                    renderInput={params => (
                      <TextField
                        id={`events-${kebabCase(
                          obj.title
                        )}-date-picker-textfield`}
                        data-testid={`events-${kebabCase(
                          obj.title
                        )}-date-picker-textfield-mobile`}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': `events-${kebabCase(
                            obj.title
                          )}-date-picker-select-input-mobile`,
                          id: `events-${kebabCase(
                            obj.title
                          )}-date-picker-select-input-mobile`
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item sm={6} xs={12}>
              <StyledSelectTextField
                id="events-format-select"
                idPrefix="events-format-"
                label="Format"
                value={userInput.formatId}
                onChange={event => {
                  setUserInput({ ...userInput, formatId: event.target.value });
                }}
                menuItems={formats}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledSelectTextField
                id="events-location-type-select"
                idPrefix="events-location-type"
                label="Location Type"
                value={userInput.locationType}
                onChange={locationTypeChange}
                menuItems={locationTypeOptions}
              />
            </Grid>
            {userInput.locationType === 'in-person' ? (
              <>
                <Grid item sm={6} xs={12}>
                  <GooglePlaceTextField
                    id="events-location-search-input"
                    value={userInput.location}
                    disabled={false}
                    property={'place'}
                    onChange={data =>
                      setUserInput({ ...userInput, location: data })
                    }
                    preFillCurrentLocation
                  />
                </Grid>
                {userInput.location && (
                  <Grid item sm={6} xs={12}>
                    <StyledSelectTextField
                      id="events-location-radius-select"
                      idPrefix="events-location-radius"
                      label="Radius"
                      value={userInput.radius}
                      onChange={event => {
                        setUserInput({
                          ...userInput,
                          radius: event.target.value
                        });
                      }}
                      menuItems={radiusOptions}
                    />
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllEventsSearch;
