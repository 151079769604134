import { Box, styled } from '@mui/material';

export const GameCardImage = styled(Box)(({ imageurl, theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: `url(${imageurl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: 'inherit',
  width: '100%',
  paddingTop: '135%',
  ['&.deck-card-preview']: {
    borderRadius: '16px',
    borderColor: `${theme.palette.common.white}`
  }
}));
