import { NA } from '../assets/strings';
import { get } from 'lodash';

export const formatLocation = (location, type = 'address') => {
  if (Object.keys(location).length <= 0) {
    return NA;
  }

  switch (type) {
    case 'link':
      return `https://google.com/maps/place/${get(location, 'address1')} ${get(
        location,
        'address2'
      )} ${get(location, 'city')} ${get(location, 'zip')} ${get(
        location,
        'state'
      )} ${get(location, 'country')}`;
    default:
      return get(location, 'isOnline', false)
        ? 'Online'
        : `${get(location, 'city')}, ${get(location, 'state')} ${get(
            location,
            'country'
          )}`;
  }
};

// Location for the images used for each game.
export const buildBannerUrl = (slug, num) =>
  `https://storage.googleapis.com/cardeio-images/defaults/${slug}/events/${num}.webp`;

export const getReadableErrorMessage = error => {
  if (!error) return '';
  const indexOfColon = error.indexOf(':');
  return indexOfColon >= 0 ? error.slice(indexOfColon + 1).trim() : error;
};

export const getStoreAddress = location => {
  const addressParts = [
    get(location, 'address1'),
    get(location, 'address2'),
    get(location, 'city'),
    get(location, 'state'),
    get(location, 'country'),
    get(location, 'zip')
  ].filter(ap => !!ap);
  return addressParts.join(', ');
};

export const isValidEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
