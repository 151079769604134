import { Box, Paper, styled } from '@mui/material';

export const CardListMainContainer = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: '#0008',
  borderRadius: 'inherit',
  opacity: 0,
  transition: 'opacity 0.3s',
  [':hover']: {
    opacity: 1
  }
}));

export const SelectionContainer = styled(Paper)(() => ({
  width: '100%',
  height: '80%',
  borderRadius: 'inherit',
  overflow: 'scroll'
}));
