import React, { useEffect, useState } from 'react';

import { AddDeckToEventDeckDetailsView } from './DeckDetailsView';
import { AddDeckToEventDeckListingView } from './DeckListView';

const VIEW_MODE = {
  LIST: 'listing',
  DETAILS: 'details'
};

export const AddDeckToEventModal = ({ user, activeEvent }) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.LIST);
  const [selectedDeckId, setSelectedDeckId] = useState(null);

  useEffect(() => {
    setViewMode(VIEW_MODE.LIST);
    setSelectedDeckId(null);
  }, []);

  const onSelectDetailsMode = deckId => {
    setSelectedDeckId(deckId);
    setViewMode(VIEW_MODE.DETAILS);
  };

  const onSelectListingMode = () => {
    setViewMode(VIEW_MODE.LIST);
  };

  if (viewMode === VIEW_MODE.DETAILS) {
    return (
      <AddDeckToEventDeckDetailsView
        onClickBack={onSelectListingMode}
        deckId={selectedDeckId}
      />
    );
  }
  return (
    <AddDeckToEventDeckListingView
      user={user}
      activeEvent={activeEvent}
      onSelectViewDetails={onSelectDetailsMode}
      fromDetailsDeckId={selectedDeckId}
    />
  );
};
