import {
  Box,
  Button,
  Grid,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import {
  DistanceIcon,
  LocationIcon
} from '../../../../../components/Icon/vectors';

import Loading from '../../../../../components/Loading/Loader';
import React from 'react';
import { getFixedPositionNumber } from '../../../../../utils/number';
import { getStoreAddress } from '../../../../../utils/StringUtil';
import { useNavigate, useParams } from 'react-router-dom';
import { buildRoutePath } from '../../../../../utils/routingUtils';

const StyledStoreListContainer = styled(Box)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.6),
  borderRadius: '8px',
  maxHeight: '80vh',
  height: '80vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px 12px',
  '& .store-list-details-single:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.neutral[400]}`
  },
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    maxHeight: '40vh'
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledStoreSingleInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg': {
    width: '20px'
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledStoreContainer = styled(Box)(({ isSelected, theme }) => ({
  width: '100%',
  marginBottom: '16px',
  cursor: 'pointer',
  padding: '0 4px 16px 4px',
  ...(isSelected && {
    background: alpha(theme.palette.background.paper, 0.7)
  }),
  '&:hover': {
    background: alpha(theme.palette.background.paper, 0.7)
  }
}));

const StoreList = ({
  data = [],
  isLoading = false,
  onClickStore = () => {},
  selectedStoreId = null
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { gameSlug } = useParams();
  const textColor = alpha(
    theme.palette.getContrastText(theme.palette.background.paper),
    0.7
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledStoreListContainer>
          {data.length ? (
            data.map(store => {
              return (
                <StyledStoreContainer
                  key={store._id}
                  className="store-list-details-single"
                  onClick={() => onClickStore(store._id)}
                  isSelected={selectedStoreId && selectedStoreId === store._id}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{store.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledStoreSingleInfoContainer>
                        <LocationIcon color={theme.palette.text.primary} />
                        <Typography
                          variant="body3Light"
                          ml={1}
                          color={textColor}
                        >
                          {getStoreAddress(store.location)}
                        </Typography>
                      </StyledStoreSingleInfoContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledStoreSingleInfoContainer>
                        <DistanceIcon color={theme.palette.text.primary} />
                        <Typography
                          variant="body3Light"
                          ml={1}
                          color={textColor}
                        >
                          {getFixedPositionNumber(store.distanceInMiles, 2)} mi
                        </Typography>
                      </StyledStoreSingleInfoContainer>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Button
                        sx={{
                          padding: '12px 20px'
                        }}
                        variant="contained"
                        onClick={() => {
                          navigate(
                            buildRoutePath(gameSlug, `/stores/${store._id}`)
                          );
                        }}
                      >
                        <Typography variant="body2Light">Visit Page</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </StyledStoreContainer>
              );
            })
          ) : (
            <Box>
              <Typography padding="16px" textAlign="center">
                No stores available in this location!
              </Typography>
            </Box>
          )}
        </StyledStoreListContainer>
      </Grid>
    </Grid>
  );
};

export default StoreList;
