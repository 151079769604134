import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
// import RewardButton from './RewardButton';

const SeasonHelp = () => {
  const GeneralHelpCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.layout.backgrounds.block,
    padding: '47px 37px',
    borderRadius: '30px',
    height: '100%',
    gap: '70px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  }));
  return (
    <Grid container item xs={12} spacing={7}>
      <Grid item xs={12} sm>
        <GeneralHelpCard>
          <Typography variant="h3">How do I get Season Points?</Typography>
          <Typography variant="body1">
            Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text, and a search for lorem ipsum will
            uncover many web sites still in their infancy. Various versions have
            evolved over the years, sometimes by accident, sometimes on purpose
          </Typography>
          {/* <RewardButton /> */}
        </GeneralHelpCard>
      </Grid>
      <Grid item xs={12} sm>
        <GeneralHelpCard>
          <Typography variant="h3">How do Seasons Work?</Typography>
          <Typography variant="body1">
            Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text, and a search for lorem ipsum will
            uncover many web sites still in their infancy. Various versions have
            evolved over the years, sometimes by accident, sometimes on purpose.
            <br />
            <br />
            All the Lorem Ipsum generators on the Internet tend to repeat
            predefined chunks as necessary, making this the first true generator
            on the Internet. It uses a dictionary of over 200 Latin words.
          </Typography>
        </GeneralHelpCard>
      </Grid>
    </Grid>
  );
};

export default SeasonHelp;
