import { Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import CustomTable from '../../../components/Shared/CustomTable';
import { useSelector } from 'react-redux';
import { useGetRankingsQuery } from '../../../services/apis/organizePlayApi';
import { get } from 'lodash';
import UserRankDetailsPage from '../../Rankings/ModalPages/UserRankDetailsPage';
import RankingCard from '../../Rankings/All/RankingCard';
import { createCrown } from '../../../components/Shared/Overlay';
import { ModalContext } from '../../../components/Modal/Context/ModalContext';
import MiniSearch from 'minisearch';
import GeneralContainer from '../../../components/Shared/Cards/GeneralCard';

const SeasonRank = ({ seasonId }) => {
  const { game } = useSelector(({ app }) => app);
  const { addModal } = useContext(ModalContext);
  const [searchResults, setSearchResults] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const formatId = null;

  const { data, isLoading, isFetching } = useGetRankingsQuery(
    `gameId=${game.id}${formatId ? `&formatId=${formatId}` : ''}${
      seasonId ? `&seasonId=${seasonId}` : ''
    }`
  );
  const miniSearch = new MiniSearch({
    fields: ['firstName', 'lastName'],
    storeFields: [
      'userId',
      'firstName',
      'lastName',
      'userImage',
      'history',
      'rankNumber',
      'rankInfo',
      'eloRank'
    ],
    idField: ['userId'],
    searchOptions: {
      boost: { firstName: 5 },
      fuzzy: 0.2
    }
  });

  const handleSearch = e => {
    const hash = get(data, 'rankings', []).map((user, index) => ({
      ...user,
      place: index + 1
    }));
    const newHash = hash;
    const eventTarget = get(e, 'target.value', searchInput);
    miniSearch.addAll(newHash);
    setSearchInput(eventTarget);
    const results = miniSearch.search(eventTarget, { prefix: true });
    if (eventTarget) {
      setSearchResults(results);
    } else if (eventTarget === '') {
      setSearchResults(newHash);
    }
  };

  return (
    <Grid container item xs={12} gap={7}>
      <Typography variant="h1">Rankings</Typography>
      <CustomTable
        state={(isLoading || isFetching) && 'loading'}
        items={searchResults || get(data, 'rankings', [])}
        emptyMessage="No Rankings Yet..."
        onChange={e => handleSearch(e)}
        component={(user, index) => {
          return (
            <td>
              <GeneralContainer
                buttonStyle={{ padding: '16px 24px' }}
                onClick={() =>
                  addModal({
                    children: (
                      <UserRankDetailsPage
                        userId={user.userId}
                        formatId={formatId}
                        seasonId={seasonId}
                        rankName={user.rankName}
                      />
                    ),
                    style: { maxWidth: '700px !important' }
                  })
                }
              >
                <RankingCard
                  activeRankingType="seasons"
                  user={user}
                  textAlign={'md'}
                  overlay={createCrown(user.rankNumber, index + 1)}
                />
              </GeneralContainer>
            </td>
          );
        }}
      />
    </Grid>
  );
};

export default SeasonRank;
