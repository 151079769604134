import { Grid, Icon, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import Editor from '../../../../components/Editor';
import { EventJudgeSingleInfo } from '../../../../components/Events/EventJudgeSingleInfo';
import { GeneralEventCard } from './style';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import Loading from '../../../../components/Loading/Loader';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { MaxWidthContainer } from '../../../../components/Shared/Grid/styles';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import PrizeInformation from '../../../../components/Events/Cards/PrizeInformation';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SavingsIcon from '@mui/icons-material/Savings';
import TimerIcon from '@mui/icons-material/Timer';
import { formatEventTime } from '../../../../utils/date';
import { get } from 'lodash';
import { useEventFormat } from '../../../../hooks/events/useEventFormat';
import { useGetSingleEventQuery } from '../../../../services/apis/organizePlayApi';
import { useParams } from 'react-router-dom';

const EventOverviewPage = () => {
  const { eventId } = useParams();
  const desktopOnly = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [show, setShow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const { data: activeEvent, error, isLoading } = useGetSingleEventQuery(
    eventId
  );

  const {
    details,
    description,
    startDate,
    tournament,
    judges,
    isOrganizer
  } = activeEvent;
  const playType = get(tournament, 'playType', 'tournament');
  let parsedDescription = null;

  const eventFormatName = useEventFormat(activeEvent);
  try {
    parsedDescription = JSON.parse(description);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const noInfo = 'N/A';

  const registration = {
    total: get(details, 'capacity'),
    confirmed: 0,
    registered: get(details, 'participantCount'),
    available: get(details, 'capacity') - get(details, 'participantCount')
  };

  const createPrizingTotal = () => {
    if (!get(details, 'prizing')) {
      return noInfo;
    }

    const value = Object.keys(get(details, 'prizing')).reduce(
      (prev, prizingKey) => {
        const currentPrizing = get(details, `prizing[${prizingKey}].cash`, 0);

        if (prizingKey === 'top8' && currentPrizing > 0) {
          return prev + currentPrizing * 5;
        }

        if (prizingKey === 'top16' && currentPrizing > 0) {
          return prev + currentPrizing * 8;
        }

        return prev + currentPrizing;
      },
      0
    );

    return value + get(details, 'totalPrizingValue', 0);
  };

  const registrationInfo = [
    { title: 'Total', value: registration.total },
    { title: 'Registered', value: registration.registered },
    { title: 'Available', value: registration.available }
  ];

  const prizesInfo = [
    {
      title: 'First',
      value: get(details, 'prizing.first.cash', 0),
      productValue: get(details, 'prizing.first.product'),
      limit: 0
    },
    {
      title: 'Second',
      value: get(details, 'prizing.second.cash', 0),
      productValue: get(details, 'prizing.second.product'),
      limit: 0
    },
    {
      title: 'Third',
      value: get(details, 'prizing.third.cash', 0),
      productValue: get(details, 'prizing.third.product'),
      limit: 0
    },
    {
      title: 'Top 8',
      value: get(details, 'prizing.top8.cash', 0),
      productValue: get(details, 'prizing.top8.product'),
      limit: 8
    },
    {
      title: 'Top 16',
      value: get(details, 'prizing.top16.cash', 0),
      productValue: get(details, 'prizing.top16.product'),
      limit: 16
    }
  ];

  const formatInfo = [
    {
      icon: LocalFireDepartmentIcon,
      title: `${playType === 'tournament' ? 'GAMEPLAY ' : ''}FORMAT`,
      value: eventFormatName,
      show: true
    },
    {
      icon: BatchPredictionIcon,
      title: 'TOTAL ROUNDS',
      value: `${get(tournament, 'rounds')} Rounds`,
      show: playType === 'tournament'
    },
    {
      icon: LineWeightIcon,
      title: 'TOURNAMENT FORMAT',
      value:
        get(tournament, 'format') !== 'single-elimination'
          ? 'Swiss'
          : 'Single Elimination',
      show: playType === 'tournament'
    },
    {
      icon: QrCodeIcon,
      title: 'ENTRY TIME',
      value: formatEventTime(startDate, {
        type: 'checkIn',
        minutesToSubtract: get(details, 'checkinPeriod', 0)
      }),
      show: true
    },
    {
      icon: TimerIcon,
      title: 'START TIME',
      value: formatEventTime(startDate),
      show: true
    },
    {
      icon: TimerIcon,
      title: 'END TIME',
      value:
        formatEventTime(startDate, {
          type: 'endTime',
          rounds: get(tournament, 'rounds', 0),
          roundLength: get(tournament, 'roundLength', 0)
        }) || noInfo,
      show: true
    },
    {
      icon: MilitaryTechIcon,
      title: 'PARTICIPATION',
      value: `${get(details, 'prizing.participation.product') || 'No Rewards'}`,
      show: true
    },
    {
      icon: SavingsIcon,
      title: 'PRIZING VALUE',
      value: `$${createPrizingTotal()}`,
      show: playType === 'tournament' && createPrizingTotal() > 0
    }
  ];

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    });
  }, [activeEvent]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MaxWidthContainer container gap={2}>
      <Grid xs={12} item>
        <Typography variant="h1" gutterBottom>
          Details
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={4}>
        {/* Left Section */}
        <Grid
          container
          xs={12}
          md={9}
          item
          alignContent={'flex-start'}
          justifyContent={'flex-start'}
          gap={4}
        >
          {/* Format Section*/}
          <Grid container item xs={12} spacing={4} justifyContent={'center'}>
            {formatInfo
              .filter(format => format.show)
              .map(obj => {
                return (
                  <Grid item xs={12} sm={6} key={obj.title}>
                    <GeneralEventCard style={{ textAlign: 'center' }}>
                      <Icon component={obj.icon} fontSize="large" />
                      <Typography sx={{ mt: 0.5 }} variant="h4">
                        {obj.title.toString().toUpperCase()}
                      </Typography>
                      <Typography sx={{ mt: 1 }} variant="body1">
                        {obj.value}
                      </Typography>
                    </GeneralEventCard>
                  </Grid>
                );
              })}
          </Grid>
          {/* Events Information Desktop */}
          {desktopOnly && (
            <>
              {(get(activeEvent, 'location.address1') ||
                get(activeEvent, 'location.isOnline', false)) && (
                <Grid item xs={12}>
                  <Typography variant="h1" mb={4}>
                    Location
                  </Typography>
                  {get(activeEvent, 'location.isOnline', false) ? (
                    <>
                      <Typography gutterBottom variant="h4">
                        Online
                      </Typography>
                      <Typography variant="body1">
                        {get(activeEvent, 'location.name')}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography gutterBottom variant="h4">
                        {get(activeEvent, 'location.name')}
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        {`${get(activeEvent, 'location.address1')} ${get(
                          activeEvent,
                          'location.address2'
                        )}`}
                      </Typography>
                      <Typography variant="body1">
                        {`${get(activeEvent, 'location.city')}, ${get(
                          activeEvent,
                          'location.state'
                        )} ${get(activeEvent, 'location.zip')}`}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
              {description && description.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h1" gutterBottom>
                    More Info
                  </Typography>
                  {parsedDescription ? (
                    show && <Editor initState={description} readOnly />
                  ) : (
                    <div
                      style={{ overflow: 'hidden' }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  )}
                </Grid>
              )}
              {judges && judges.length ? (
                <Grid item xs={12}>
                  <Typography variant="h1" gutterBottom>
                    Event Judges
                  </Typography>
                  <Grid container spacing={2}>
                    {judges.map(judge => (
                      <Grid key={judge._id} item xs={12} sm={6} md={6} lg={4}>
                        <EventJudgeSingleInfo
                          judgeInfo={judge}
                          eventId={eventId}
                          isOrganizer={isOrganizer}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
        </Grid>

        {/* Right Section */}
        <Grid container item xs={12} md={3} alignContent={'flex-start'} gap={2}>
          <Grid xs={12} item>
            <GeneralEventCard item container gap={4} xs={12}>
              {registrationInfo.map((obj, index) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'space-between'}
                    key={index}
                  >
                    <Typography variant="h6">{obj.title.toString()}</Typography>
                    <Typography variant="h6">{obj.value}</Typography>
                  </Grid>
                );
              })}
            </GeneralEventCard>
          </Grid>
          <Grid item xs={12}>
            {playType === 'tournament' && (
              <Grid container gap={2} item>
                <Grid item xs={12}>
                  <Typography variant="h3">Prizes</Typography>
                </Grid>
                {desktopOnly &&
                  prizesInfo.map((obj, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <PrizeInformation prize={obj} />
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Events Information Mobile */}
        {!desktopOnly && playType === 'tournament' && (
          <>
            <Grid item xs={12}>
              <Swiper
                observer={true}
                loop={true}
                centeredSlides={true}
                slideToClickedSlide={true}
                autoHeight={false}
                spaceBetween={40}
                slidesPerView={1.5}
              >
                {prizesInfo.map((obj, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PrizeInformation prize={obj} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Grid>
            {get(activeEvent, 'location.address1') && (
              <Grid item xs={12}>
                <Typography variant="h1" mb={4}>
                  Location
                </Typography>
                <Typography gutterBottom variant="h4">
                  {get(activeEvent, 'location.name')}
                </Typography>
                <Typography gutterBottom variant="body1">
                  {`${get(activeEvent, 'location.address1')} ${get(
                    activeEvent,
                    'location.address2'
                  )}`}
                </Typography>
                <Typography variant="body1">
                  {`${get(activeEvent, 'location.city')}, ${get(
                    activeEvent,
                    'location.state'
                  )} ${get(activeEvent, 'location.zip')}`}
                </Typography>
              </Grid>
            )}
            {description && description.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h1" gutterBottom>
                  More Info
                </Typography>
                {parsedDescription ? (
                  show && <Editor initState={description} readOnly />
                ) : (
                  <div
                    style={{ overflow: 'hidden' }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </Grid>
            )}
            {judges && judges.length ? (
              <Grid item xs={12}>
                <Typography variant="h1" gutterBottom>
                  Event Judges
                </Typography>
                <Grid container spacing={2}>
                  {judges.map(judge => (
                    <Grid key={judge._id} item xs={12} sm={6}>
                      <EventJudgeSingleInfo
                        judgeInfo={judge}
                        eventId={eventId}
                        isOrganizer={isOrganizer}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </MaxWidthContainer>
  );
};

export default EventOverviewPage;
