import { get, min } from 'lodash';

export const getMaxQty = (limit, inventory) => {
  const qtyAvailable = get(inventory, 'qtyAvailable');
  if (limit && inventory) {
    return min([limit, qtyAvailable]);
  } else if (limit) {
    return limit;
  } else if (qtyAvailable) {
    return qtyAvailable;
  }
  return 0;
};
