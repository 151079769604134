import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { createContext } from 'react';
import DeckCardCounter from './DeckCardCounter';
import ManaCurveChart from './ManaCurveChart';
import CardTypeChart from './CardTypeChart';
// import FactionsChart from './FactionsChart';
import TraitsChart from './TraitsChart';
import { getMetric, getRandomColors } from './utils';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { useDefinitionName } from '../hooks/useDefinitionName';

export const MetricsContext = createContext();

const DeckMetricsPage = ({ edit }) => {
  const theme = useTheme();
  const { deck } = useOutletContext();
  const metrics = getMetric(deck, '', true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    game: {
      theme: { palette }
    }
  } = useSelector(({ app }) => app);
  const getFillArray = length => {
    return getRandomColors(
      palette.primary.light,
      palette.secondary.main,
      length
    );
  };
  const getChartTitle = metrics => {
    const key = Object.keys(metrics).filter(
      item => item !== 'visualizations'
    )[0];
    return useDefinitionName(key);
  };
  const getChartTitleFromField = metrics => {
    if (metrics['field'] === undefined) return null;
    const fieldName = String(metrics.field).split('.');
    const len = fieldName.length - 1;
    return fieldName[len].charAt(0).toUpperCase() + fieldName[len].slice(1);
  };

  const contextValue = {
    isMobile,
    edit,
    getFillArray,
    getChartTitle,
    getChartTitleFromField,
    primaryColor: palette.primary.main,
    secondaryColor: palette.secondary.main,
    backgroundPaper: palette.background.default,
    barSize: 30,
    defaultMargin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  };

  return (
    <MetricsContext.Provider value={contextValue}>
      <Box padding={{ xs: 0, sm: 6 }}>
        <Grid container spacing={2}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <DeckCardCounter />
          </Grid>

          {metrics?.totalCost && (
            <Grid item xs={12} md={6}>
              <ManaCurveChart />
            </Grid>
          )}

          {metrics?.cardTypes && (
            <Grid item xs={12} md={6}>
              <CardTypeChart />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6}>
            <FactionsChart />
          </Grid> */}

          {metrics?.traits && (
            <Grid item xs={12} md={6}>
              <TraitsChart />
            </Grid>
          )}
        </Grid>
      </Box>
    </MetricsContext.Provider>
  );
};

export default DeckMetricsPage;
