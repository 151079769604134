import axios from 'axios';
import { findIndex, get } from 'lodash';
import { toast } from 'react-toastify';

import {
  CREATE_NEW_EVENT_REQUEST,
  CREATE_NEW_EVENT_SUCCESS,
  CREATE_NEW_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENT_PAIRINGS_REQUEST,
  GET_EVENT_PAIRINGS_SUCCESS,
  GET_EVENT_PAIRINGS_FAILURE,
  GET_EVENT_ROSTER_REQUEST,
  GET_EVENT_ROSTER_SUCCESS,
  GET_EVENT_ROSTER_FAILURE,
  GET_EVENT_STANDINGS_REQUEST,
  GET_EVENT_STANDINGS_SUCCESS,
  GET_EVENT_STANDINGS_FAILURE,
  ADD_PARTICIPANTS_REQUEST,
  ADD_PARTICIPANTS_SUCCESS,
  ADD_PARTICIPANTS_FAILURE,
  UPDATE_PARTICIPANT_REQUEST,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_FAILURE,
  START_EVENT_REQUEST,
  START_EVENT_SUCCESS,
  START_EVENT_FAILURE,
  NEXT_ROUND_REQUEST,
  NEXT_ROUND_SUCCESS,
  NEXT_ROUND_FAILURE,
  REPORT_RESULTS_REQUEST,
  REPORT_RESULTS_SUCCESS,
  REPORT_RESULTS_FAILURE,
  START_TIME_IN_ROUND_REQUEST,
  START_TIME_IN_ROUND_SUCCESS,
  START_TIME_IN_ROUND_FAILURE,
  RESET_ACTIVE_EVENT,
  SET_ACTIVE_ROUND,
  FINALIZE_EVENT_SUCCESS
} from './types';

export const createNewEvent = (
  eventData,
  navigate,
  gameSlug
) => async dispatch => {
  dispatch({
    type: CREATE_NEW_EVENT_REQUEST
  });

  const newEventData = eventData;
  const hasActiveGame = Boolean(process.env.REACT_APP_ACTIVE_GAME);

  const res = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events`,
    data: newEventData
  }).catch(() => null);

  if (res) {
    dispatch({
      type: CREATE_NEW_EVENT_SUCCESS,
      activeEvent: res.data.data
    });
    toast.success('Successfully created new event!');
    navigate(
      `${hasActiveGame ? '' : `/${gameSlug}`}/events/${get(
        res.data,
        'data._id'
      )}`
    );
  } else {
    dispatch({
      type: CREATE_NEW_EVENT_FAILURE
    });

    toast.error('Could not create a new event!');
  }
};

export const updateEvent = (
  body,
  gameSlug,
  navigate,
  eventId
) => async dispatch => {
  dispatch({
    type: UPDATE_EVENT_REQUEST
  });

  const hasActiveGame = Boolean(process.env.REACT_APP_ACTIVE_GAME);

  const res = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}`,
    data: body
  }).catch(() => null);
  if (res) {
    dispatch({
      type: UPDATE_EVENT_SUCCESS,
      activeEvent: res.data.data
    });
    toast.success('Successfully updated event!');
    navigate(`${hasActiveGame ? '' : `/${gameSlug}`}/events/${eventId}/about`);
  } else {
    dispatch({
      type: UPDATE_EVENT_FAILURE
    });
  }
};

export const getEventById = id => async dispatch => {
  dispatch({
    type: GET_EVENT_REQUEST
  });

  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/single/${id}`
  });
  if (!res) {
    dispatch({
      type: GET_EVENT_FAILURE
    });
  }

  dispatch({
    type: GET_EVENT_SUCCESS,
    activeEvent: res.data
  });
};

export const getEvents = () => async (dispatch, getState) => {
  dispatch({
    type: GET_EVENTS_REQUEST
  });

  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${get(
      getState(),
      'app.game.id'
    )}?organizerId=${get(getState(), 'app.user.details.id')}`
  });
  if (!res) {
    dispatch({
      type: GET_EVENTS_FAILURE
    });
  }

  dispatch({
    type: GET_EVENTS_SUCCESS,
    managedEvents: res.data.data
  });
};

export const getEventRoster = id => async dispatch => {
  dispatch({
    type: GET_EVENT_ROSTER_REQUEST
  });

  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/single/${id}/roster`
  });
  if (!res) {
    dispatch({
      type: GET_EVENT_ROSTER_FAILURE
    });
  }

  dispatch({
    type: GET_EVENT_ROSTER_SUCCESS,
    roster: res.data.participants
  });
};

export const getEventPairings = (id, roundNumber) => async dispatch => {
  dispatch({
    type: GET_EVENT_PAIRINGS_REQUEST
  });

  const res = await axios({
    method: 'get',
    url: `${
      process.env.REACT_APP_MIDDLEWARE_URL
    }/v1/events/single/${id}/pairings${
      roundNumber ? `?roundNumber=${roundNumber}` : ''
    }`
  });
  if (!res) {
    dispatch({
      type: GET_EVENT_PAIRINGS_FAILURE
    });
  }

  dispatch({
    type: SET_ACTIVE_ROUND,
    activeRound: get(res.data, 'roundDetails.roundNumber')
  });

  dispatch({
    type: GET_EVENT_PAIRINGS_SUCCESS,
    pairings: res.data.pairings,
    roundDetails: res.data.roundDetails
  });
};

export const getEventStandings = id => async dispatch => {
  dispatch({
    type: GET_EVENT_STANDINGS_REQUEST
  });

  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/single/${id}/standings`
  });
  if (!res) {
    dispatch({
      type: GET_EVENT_STANDINGS_FAILURE
    });
  }

  dispatch({
    type: GET_EVENT_STANDINGS_SUCCESS,
    standings: res.data
  });
};

export const addParticipantsToEvent = (
  eventId,
  participants
) => async dispatch => {
  dispatch({
    type: ADD_PARTICIPANTS_REQUEST
  });

  const res = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}/participants`,
    data: participants
  });

  if (res) {
    dispatch({
      type: ADD_PARTICIPANTS_SUCCESS,
      newRoster: res.data.participants
    });
  } else {
    dispatch({
      type: ADD_PARTICIPANTS_FAILURE
    });
  }
};

export const updateEventParticipant = (
  eventId,
  participantId,
  body,
  successAction
) => async dispatch => {
  dispatch({
    type: UPDATE_PARTICIPANT_REQUEST
  });
  const res = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}/participants/${participantId}`,
    data: body
  }).catch(() => {
    return {};
  });
  if (res) {
    dispatch({
      type: UPDATE_PARTICIPANT_SUCCESS,
      newRoster: res.data.participants
    });
    successAction();
    toast.success('Participant updated', { toastId: 'participantSuccess' });
  } else {
    dispatch({
      type: UPDATE_PARTICIPANT_FAILURE
    });
    toast.error('Could not update Participant');
  }
};

export const startEvent = eventId => async dispatch => {
  dispatch({
    type: START_EVENT_REQUEST
  });

  const res = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}/start`,
    data: {}
  });

  if (res) {
    dispatch({
      type: START_EVENT_SUCCESS,
      pairings: res.data.pairings
    });
  } else {
    dispatch({
      type: START_EVENT_FAILURE
    });
  }
};

export const nextRound = eventId => async dispatch => {
  dispatch({
    type: NEXT_ROUND_REQUEST
  });

  const res = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}/next`,
    data: {}
  });

  if (res) {
    if (res.data.pairings.length > 0 && res.data.roundDetails.roundNumber) {
      dispatch({
        type: NEXT_ROUND_SUCCESS,
        pairings: res.data.pairings,
        roundDetails: res.data.roundDetails
      });
    } else {
      dispatch({
        type: FINALIZE_EVENT_SUCCESS
      });
    }
  } else {
    dispatch({
      type: NEXT_ROUND_FAILURE
    });
  }
};

export const reportResults = (eventId, matchId, results) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: REPORT_RESULTS_REQUEST
  });

  const res = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/${eventId}/results`,
    data: {
      ...results,
      matchId
    }
  });

  if (res) {
    const currentPairings = getState().events.activeEvent.pairings;
    const pairingIndex = findIndex(
      currentPairings,
      pairing => pairing._id === matchId,
      0
    );

    dispatch({
      type: REPORT_RESULTS_SUCCESS,
      updatedPairing: res.data,
      pairingIndex
    });
  } else {
    dispatch({
      type: REPORT_RESULTS_FAILURE
    });
  }
};

export const startTimeInRound = eventId => async dispatch => {
  dispatch({
    type: START_TIME_IN_ROUND_REQUEST
  });

  const res = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/events/single/${eventId}/start-timer`,
    data: {}
  });

  if (res) {
    dispatch({
      type: START_TIME_IN_ROUND_SUCCESS,
      endTime: res.data
    });
  } else {
    dispatch({
      type: START_TIME_IN_ROUND_FAILURE
    });
  }
};

export const resetActiveEvent = () => async dispatch => {
  dispatch({
    type: RESET_ACTIVE_EVENT
  });
};
