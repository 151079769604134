import Joi from 'joi';
import { userNameSchema } from './updateUserSchema';
import { addressSchema } from '../address/addressSchema';

export const updateUserAddressSchema = Joi.object({
  profile: Joi.object({
    ...userNameSchema
  }),
  location: addressSchema
}).required();
