import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { get } from 'lodash';
import { updateUserWallets } from '../../store/app/actions';
import { useIsLoggedIn } from '../auth0/useLoggedIn';

export function useUserWallets() {
  const isLoggedIn = useIsLoggedIn();
  const { game, user, isUpdatingUserWallet } = useSelector(({ app }) => app);
  const { token } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [wallets, setWallets] = useState([]);

  const update = () => {
    if (isLoggedIn) {
      dispatch(updateUserWallets(token, get(game, 'pgGame.id')));
    }
  };

  useEffect(() => {
    setWallets(get(user, 'details.wallets', []));
  }, [user]);

  return {
    isUpdating: isUpdatingUserWallet,
    update,
    wallets
  };
}
