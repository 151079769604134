import { Box, Button, Grid, ImageList, ImageListItem } from '@mui/material';

import React from 'react';

// eslint-disable-next-line react/display-name,no-unused-vars
const ImageSelector = React.forwardRef(({ images, value, onChange }, ref) => {
  return (
    <Grid
      id="create-deck-image-button-select"
      data-testid="create-deck-image-button-select"
      container
      spacing={0.5}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            position: 'relative',
            paddingTop: '50%',
            width: '100%'
          }}
        >
          <img
            src={value}
            width={'100%'}
            height={'auto'}
            style={{ position: 'absolute', top: 0 }}
          />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <ImageList
          cols={3}
          gap={12}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: 'repeat(2, 1fr) !important',
              gap: '8px !important'
            }
          })}
        >
          {images.map((obj, i) => (
            <ImageListItem key={obj}>
              <Button
                id={`create-deck-image-select-${i}-button`}
                data-testid={`create-deck-image-select-${i}-button`}
                className={`toggle ${value === obj ? 'active' : ''}`}
                disableRipple={true}
                variant={'outlined'}
                onClick={() => onChange(obj)}
                sx={{
                  position: 'relative',
                  padding: 0,
                  paddingTop: '49%'
                }}
              >
                <img
                  src={obj}
                  width={'100%'}
                  height={'auto'}
                  style={{ position: 'absolute', top: 0 }}
                />
              </Button>
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  );
});

export default ImageSelector;
