import { Box, Grid, Typography, styled } from '@mui/material';
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { find, get, isEmpty } from 'lodash';

import AddToCartButton from '../AddToCartButton';
import DescriptionText from '../../../../components/Shared/DescriptionText';
import Loader from '../../../../components/Loading/Loader';
import ResponsiveImage from '../../../../components/Shared/Images/ResponsiveImage/index';
import VariantSelector from './VariantSelector';
import testImage from '../../../../assets/test.png';
import { useGetCartDetailsQuery } from '../../../../services/apis/organizePlayApi';
import { useGetProductByIdQuery } from '../../../../services/apis/organizePlayApi';
import { useIsLoggedIn } from '../../../../hooks/auth0/useLoggedIn';
import { useSelector } from 'react-redux';

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '60%'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: '100%'
    }
  }
}));

const StyledAddToCartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const RewardDetails = ({ productId, inventoryId, currencyId }) => {
  const {
    data: productDetails,
    isLoading,
    isFetching
  } = useGetProductByIdQuery(productId, {
    skip: !productId
  });
  const { game } = useSelector(({ app }) => app);
  const [hasVariants, setHasVariants] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const isProductLoading = isLoading || isFetching;
  const [selectedInventoryId, setSelectedInventoryId] = useState(
    inventoryId || null
  );
  const [shouldDisableAddButton, setShouldDisableAddButton] = useState(false);
  const {
    data: cartDetails,
    isLoading: isCartLoading,
    isFetching: isCartFetching
  } = useGetCartDetailsQuery(
    {},
    {
      skip: !isLoggedIn
    }
  );
  const currencies = get(game, 'pgGame.currencies', []);

  const getProductImage = useCallback(() => {
    if (productDetails) {
      const inventories = get(productDetails, 'data.inventory', []);
      if (isEmpty(inventories)) {
        return testImage;
      }
      if (!selectedInventoryId) {
        return get(inventories, '[0].resourceImages[0].image.url');
      }
      return get(
        find(inventories, inv => inv.id === selectedInventoryId),
        'resourceImages[0].image.url',
        testImage
      );
    }
    return testImage;
  }, [productDetails, selectedInventoryId]);

  useEffect(() => {
    if (get(productDetails, 'data.inventory', []).length > 1) {
      if (!selectedInventoryId) {
        const existingSelection = get(
          cartDetails,
          `cart.items.${productDetails.data.id}`
        );
        if (!isEmpty(existingSelection)) {
          const inventoryIds = Object.keys(existingSelection);
          if (!isEmpty(inventoryIds)) {
            setSelectedInventoryId(inventoryIds[0]);
            setShouldDisableAddButton(false);
          }
        }
      }
      setHasVariants(true);
    } else {
      setHasVariants(false);
    }
  }, [productDetails, cartDetails]);

  const onInventoryIdChanged = newInvId => {
    setSelectedInventoryId(newInvId);
    setShouldDisableAddButton(false);
  };
  const onInvalidVariantSelection = () => {
    setShouldDisableAddButton(true);
  };

  if (isProductLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          mb={2}
          sx={{ textAlign: 'center' }}
          variant="h3"
          data-testid="rewards-reward-name"
        >
          {productDetails.data.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledImageContainer>
          <img src={getProductImage()} alt={productDetails.data.name} />
        </StyledImageContainer>
      </Grid>
      <Grid item xs={12} data-testid="rewards-reward-description">
        <DescriptionText center description={productDetails.data.description} />
      </Grid>
      <Grid item xs={12}>
        {hasVariants && (
          <Grid container>
            <Grid item xs={12}>
              <VariantSelector
                cartDetails={cartDetails}
                isCartFetching={isCartFetching}
                isCartLoading={isCartLoading}
                currencyId={currencyId}
                currencies={currencies}
                product={productDetails.data}
                selectedInventoryId={selectedInventoryId}
                onChange={onInventoryIdChanged}
                onInvalidSelection={onInvalidVariantSelection}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} mt={hasVariants ? 2 : 0}>
          <StyledAddToCartContainer>
            <AddToCartButton
              product={productDetails.data}
              cartDetails={cartDetails}
              isCartFetching={isCartFetching}
              isCartLoading={isCartLoading}
              currencyId={currencyId}
              currencies={currencies}
              isForSingleVariant={!hasVariants}
              inventoryId={selectedInventoryId}
              shouldDisable={shouldDisableAddButton}
            />
          </StyledAddToCartContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RewardDetails;
