import { Outlet, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Navigate } from 'react-router-dom';
import { buildRoutePath } from '../../utils/routingUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PayoutRedirectPage = () => {
  const { gameSlug } = useParams();
  const { ticketing } = useFlags();
  const [isFlagSet, setIsFlagSet] = React.useState(false);

  useEffect(() => {
    if (ticketing !== undefined) {
      setIsFlagSet(true);
    }
  }, [ticketing]);

  if (isFlagSet && !ticketing) {
    return <Navigate to={buildRoutePath(gameSlug, '/')} />;
  }
  return <Outlet />;
};

export default PayoutRedirectPage;
