// eslint-disable-next-line no-unused-vars
import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';

import { CTable } from './style';
import Loading from '../../Loading/Loader';
import { StyledSearchTextField } from '../TextFields';
import { useTheme } from '@mui/material/styles';

export const tableStates = {
  loading: 'loading',
  ready: 'ready',
  error: 'error'
};

const CustomTable = ({
  searchId = 'search-input',
  emptyMessage = 'No Results',
  items = undefined,
  onChange,
  search = true,
  state = tableStates.loading,
  value,
  ...restProps
}) => {
  // Create Array to be displayed on the table
  const createList = useMemo(() => {
    const compItems = Array.isArray(items)
      ? items.map((obj, index) => {
          return <tr key={index}>{restProps.component(obj, index)}</tr>;
        })
      : [];

    return compItems;
  }, [items]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const emptyOrLoading =
    state === tableStates.loading || !items || items.length === 0;
  const minHeight = isSmall ? '150px' : '200px';

  return (
    <Grid container style={{ minHeight: minHeight }}>
      {search && (
        <Grid item xs={12}>
          <StyledSearchTextField
            id={searchId}
            onChange={onChange && onChange}
            value={value && value}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          container
          alignItems={emptyOrLoading && 'center'}
          justifyContent={emptyOrLoading && 'center'}
          height={emptyOrLoading && '100%'}
        >
          {/* eslint-disable-next-line no-constant-condition */}
          {state === tableStates.loading && <Loading />}

          {state !== tableStates.loading &&
            (items && items.length === 0 ? (
              <Typography variant={'h3'} textAlign={'center'}>
                {emptyMessage}
              </Typography>
            ) : (
              <CTable>
                <tbody style={{ position: 'relative' }}>{createList}</tbody>
              </CTable>
            ))}
          {/*<Pagination*/}
          {/*  count={20}*/}
          {/*  size={'large'}*/}
          {/*  color="primary"*/}
          {/*  sx={{*/}
          {/*    background: 'red',*/}
          {/*    '& .MuiPaginationItem-root': {*/}
          {/*      color: '#fff'*/}
          {/*    },*/}
          {/*    '& .Mui-selected': {*/}
          {/*      backgroundColor: 'red',*/}
          {/*      color: 'white'*/}
          {/*    }*/}
          {/*  }}*/}
          {/*/>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomTable;
