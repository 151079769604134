export const address = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

export const defaultAddress = {
  name: '',
  ...address
};

export const userAddress = {
  ...address,
  isOptedOut: false
};
