import React from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import Cart from '../Cart';
import { HeaderNavButton } from '../styles';
const HeaderAuth = ({ drawer, close }) => {
  // Auth0
  // eslint-disable-next-line no-unused-vars
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const theme = useTheme();
  // Redux
  const { game, user } = useSelector(({ app }) => app);

  // Feature Flags
  const { deckBuilder, showLgsApplication } = useFlags();
  // React Router Dom
  const { path } = useLoaderData();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const testIdPrefix = drawer ? 'drawer' : 'regular';
  const picSize = drawer ? 'currentValue' : '56px';
  const userMenuKeys = {
    lgs: 'lgs',
    preOrders: get(game, 'layout.profile.preOrderLink', ''),
    events: 'myEvents',
    decks: 'myDecks',
    cart: 'myCart',
    rewards: 'rewards',
    profile: 'profile',
    support: 'support',
    logout: 'logout'
  };

  const userMenuOptions = [
    {
      title: 'LGS Application',
      key: userMenuKeys.lgs,
      show:
        get(user, 'details.gameUser.lgsApplications', []).length > 0
          ? false
          : showLgsApplication
    },
    {
      title: 'Pre Orders',
      key: userMenuKeys.preOrders,
      show:
        get(user, 'permissions.roleName', '') === 'Entity' &&
        get(game, 'layout.profile.preOrderLink', '').length > 0
    },
    {
      title: 'My Events',
      key: userMenuKeys.events,
      show: get(user, 'permissions.events.hasAccess', false)
    },
    {
      title: 'My Decks',
      key: userMenuKeys.decks,
      show: deckBuilder
    },
    {
      title: 'Profile',
      key: userMenuKeys.profile,
      show: true
    },
    {
      title: 'Support',
      key: userMenuKeys.support,
      show: true
    },
    {
      title: 'Logout',
      key: userMenuKeys.logout,
      show: true
    }
  ].filter(obj => obj.show);

  const userSelectedAction = (popupstate, key) => {
    switch (key) {
      case userMenuKeys.decks:
        navigate(`${path}/decks/my-decks`);
        break;
      case userMenuKeys.rewards:
        navigate(`${path}/rewards`);
        break;
      case userMenuKeys.lgs:
        navigate(`${path}/lgs-application`);
        break;
      case userMenuKeys.events:
        navigate(`${path}/user/events`);
        break;
      case userMenuKeys.profile:
        navigate(`${path}/user/profile`);
        break;
      case userMenuKeys.cart:
        navigate(`${path}/cart`);
        break;
      case userMenuKeys.support:
        window.open('mailto:support@carde.io', '_blank');
        break;
      case userMenuKeys.logout:
        logout({ returnTo: `${window.location.origin}${path}` });
        break;
      default:
        break;
    }

    popupstate && popupstate.close();
    close && close();
  };

  const firstName = get(user, 'details.info.firstName', '');

  const typographyStyle = drawer
    ? {
        maxWidth: '150px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    : {
        color:
          pathname.toString().match(new RegExp('user')) &&
          !pathname.toString().match(new RegExp('events'))
            ? get(theme, 'colors.header.textActive')
            : get(theme, 'colors.header.textColor')
      };

  return (
    <>
      {isAuthenticated ? (
        <>
          <PopupState
            variant={'popover'}
            popupId={'userOptions'}
            disableAutoFocus={true}
          >
            {popupState => (
              <>
                {/* Nav button used in the drawer and also in the header */}
                <Box display="flex" alignItems="center">
                  <HeaderNavButton
                    id={`${testIdPrefix}-header-auth-select-button`}
                    data-testid={`${testIdPrefix}-header-auth-select-button`}
                    fullWidth={drawer && true}
                    color={drawer ? 'inherit' : 'primary'}
                    sx={{
                      flexDirection: drawer ? 'row-reverse' : 'row',
                      justifyContent: 'space-between'
                    }}
                    className={'user-info'}
                    {...bindTrigger(popupState)}
                    {...{
                      ...(drawer && {
                        onClick: event => {
                          popupState.setOpen(!popupState.isOpen, event);
                        }
                      })
                    }}
                    disableRipple={true}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                  >
                    {drawer &&
                      (popupState.isOpen ? <ExpandLess /> : <ExpandMore />)}

                    <Box
                      sx={{
                        gap: 'inherit',
                        display: 'inherit',
                        flexDirection: 'inherit',
                        alignItems: 'inherit'
                      }}
                    >
                      {firstName && (
                        <Typography
                          mr={1}
                          variant="h5"
                          sx={typographyStyle}
                          data-testid={`${testIdPrefix}-user-name`}
                        >
                          {`${firstName} ${get(
                            user,
                            'details.info.lastName',
                            ''
                          )}`}
                        </Typography>
                      )}

                      <Avatar
                        src={get(user, 'details.gameUser.imageUrl')}
                        sx={{
                          ml: 2,
                          width: picSize,
                          height: picSize
                        }}
                      />
                    </Box>
                  </HeaderNavButton>
                  {!drawer && <Cart />}
                </Box>
                {/* List used in the drawer */}
                {drawer ? (
                  <Collapse in={popupState.isOpen} sx={{ width: '100%' }}>
                    <List>
                      {userMenuOptions.map(obj => (
                        <ListItemButton
                          id={`${testIdPrefix}-header-auth-select-${obj.key.toLowerCase()}-option`}
                          data-testid={`${testIdPrefix}-header-auth-select-${obj.key.toLowerCase()}-option`}
                          key={obj.key}
                          onClick={() => {
                            if (obj.key.includes('https')) {
                              window.open(obj.key, '_blank');
                            } else {
                              userSelectedAction(null, obj.key);
                            }
                          }}
                        >
                          <ListItemText>{obj.title}</ListItemText>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                ) : (
                  <Menu
                    {...bindMenu(popupState)}
                    PaperProps={{
                      style: {
                        width: '200px'
                      }
                    }}
                  >
                    {userMenuOptions
                      .filter(obj => obj.show)
                      .map(obj => (
                        <MenuItem
                          id={`${testIdPrefix}-header-auth-select-${obj.key.toLowerCase()}-option`}
                          data-testid={`${testIdPrefix}-header-auth-select-${obj.key.toLowerCase()}-option`}
                          key={obj.key}
                          sx={{ p: 2 }}
                          onClick={() => {
                            if (obj.key.includes('https')) {
                              window.open(obj.key, '_blank');
                            } else {
                              userSelectedAction(popupState, obj.key);
                            }
                          }}
                        >
                          {obj.title}
                        </MenuItem>
                      ))}
                  </Menu>
                )}
              </>
            )}
          </PopupState>
        </>
      ) : (
        // Login Button
        <Button
          id={`${testIdPrefix}-header-login-button`}
          data-testid={`${testIdPrefix}-header-login-button`}
          fullWidth={drawer && true}
          onClick={() =>
            loginWithRedirect({
              redirectUri: `${window.location.origin}${path}`
            })
          }
          variant="contained"
        >
          <Typography variant="h5">Login</Typography>
        </Button>
      )}
    </>
  );
};

export default HeaderAuth;
