import { Route } from 'react-router-dom';
import React from 'react';
import StatusPage, { statusTypes } from '../Status';
import CartMainPage from '.';
import FeaturedRoute from '../../components/Route/FeaturedRoute';

const CartRouter = () => {
  return (
    <Route
      path={'cart/'}
      element={
        <FeaturedRoute feature="rewardStore">
          <CartMainPage />
        </FeaturedRoute>
      }
      errorElement={<StatusPage type={statusTypes.error} />}
    ></Route>
  );
};

export default CartRouter;
