import { Box } from '@mui/material';
import React from 'react';

const ResponsiveImage = ({ src, paddingTop = '70%', onClick = () => {} }) => {
  return (
    <Box
      className={'responsive-img-container'}
      sx={{ paddingTop, width: '100%' }}
      onClick={onClick}
    >
      <Box
        className={'responsive-img'}
        component={'img'}
        src={src}
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover'
        }}
      />
    </Box>
  );
};

export default ResponsiveImage;
