import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

const BasicUserInfo = ({ boxStyle, avatar = '', name = '' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        ...boxStyle
      }}
    >
      <Avatar
        className={'avatar-icon'}
        sx={{ width: '35px', height: '35px' }}
        src={avatar}
      >
        NA
      </Avatar>
      <Typography className={'username'} variant={'subtitle1'}>
        {name}
      </Typography>
    </Box>
  );
};

export default BasicUserInfo;
