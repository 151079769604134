import { filter, get } from 'lodash';

import { Box } from '@mui/material';
import Icon from '../../../../../../components/Icon';
import { Link } from 'react-router-dom';
import React from 'react';

const socialMediaSuffixes = {
  instagram: 'https://instagram.com/',
  twitter: 'https://twitter.com/',
  discord: 'https://discordapp.com/users/',
  linkedin: 'https://linkedin.com/in/',
  youtube: 'https://youtube.com/'
};

const SocialLinks = ({ store }) => {
  const availableSocialLinks = filter(get(store, 'info.socials'), 'url');
  return (
    <Box display="flex" gap={3}>
      {availableSocialLinks.map(social => (
        <Link
          to={socialMediaSuffixes[social.name] + social.url}
          target="_blank"
          key={social.name}
        >
          <Box
            sx={{
              '&:hover': {
                svg: {
                  transition: 'transform 0.3s ease',
                  transform: 'scale(1.2)'
                }
              }
            }}
          >
            <Icon name={social.name} />
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default SocialLinks;
