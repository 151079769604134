import React from 'react';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { singleEventInfo } from '../../../../utils/EventUtils';
import EventInfo from '../../Shared/EventInfo';
import { formatLocation } from '../../../../utils/StringUtil';
import GeneralContainer from '../../../Shared/Cards/GeneralCard';
import { getRandomProfileImage } from '../../../../utils/images';

const FeaturedEventCard = ({ event, active = true }) => {
  const { game } = useSelector(({ app }) => app);
  const hidden = useMediaQuery(theme => theme.breakpoints.down('md'));
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const eventNameHeader = !hidden ? 'h3' : small ? 'h5' : 'h4';
  const {
    capacity,
    date,
    entryFee,
    currency,
    format,
    id,
    imageUrl,
    location,
    name,
    organization,
    participants
  } = singleEventInfo(event);

  const navigate = useNavigate();
  return (
    <GeneralContainer
      hover={false}
      onClick={() => navigate(`events/${id}/about`)}
      active={active}
      paperStyle={{
        height: hidden ? '100%' : 'unset',
        width: hidden ? '100%' : '900px',
        overflow: hidden ? 'hidden' : 'unset'
      }}
    >
      <Grid container height={'100%'}>
        {/* Mobile Event Image */}
        {hidden && (
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
                paddingBottom: small ? '100%' : '50%',
                width: '100%'
              }}
            ></Box>
          </Grid>
        )}
        <Grid item xs={12} sx={{ padding: '6%' }}>
          <Grid container>
            {/* Event Image */}
            <Grid item xs={12} md={6}>
              {!hidden && (
                <Box
                  sx={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '24px',
                    bottom: '48px',
                    height: '500px',
                    left: '48px',
                    position: 'absolute',
                    width: '360px'
                  }}
                />
              )}
            </Grid>

            {/* Right side Information */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={small ? 2 : 4}>
                {/* Event Name */}
                <Grid item xs={12}>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant={eventNameHeader}
                      textAlign={'left'}
                    >
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      <Typography variant={small ? 'h6' : 'h5'}>
                        {format}
                      </Typography>
                      <Typography variant={small ? 'h6' : 'h5'}>
                        {`${currency} ${entryFee}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item>
                  <Grid container spacing={2}>
                    <EventInfo
                      fontSize={!hidden ? 'body1' : 'body3'}
                      date={date}
                      location={formatLocation(location)}
                      organizationName={organization}
                      locationLink={formatLocation(location, 'link')}
                      playersInfo={`${participants} / ${capacity} Capacity`}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {small ? (
                    <Grid container justifyContent={'center'}>
                      <Button
                        variant="contained"
                        onClick={() => navigate(`events/${id}/about`)}
                      >
                        <Typography variant={'body2'}>More Info</Typography>
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Typography gutterBottom variant={'h5'}>
                        {`Meet the ${get(
                          game,
                          'terminology.players',
                          'Players'
                        )}`}
                      </Typography>
                      <AvatarGroup
                        total={
                          participants > 4 ? participants - 3 : participants
                        }
                        sx={{
                          justifyContent: 'flex-end'
                        }}
                      >
                        {participants > 4 ? (
                          <>
                            <Avatar
                              src={`https://storage.googleapis.com/cardeio-images/defaults/${
                                game.slug
                              }/profile/${getRandomProfileImage(game)}.webp`}
                              sx={{
                                border: 'unset !important',
                                height: '48px',
                                width: '48px'
                              }}
                            />
                            <Avatar
                              src={`https://storage.googleapis.com/cardeio-images/defaults/${
                                game.slug
                              }/profile/${getRandomProfileImage(game)}.webp`}
                              sx={{
                                border: 'unset !important',
                                height: '48px',
                                width: '48px'
                              }}
                            />
                            <Avatar
                              src={`https://storage.googleapis.com/cardeio-images/defaults/${
                                game.slug
                              }/profile/${getRandomProfileImage(game)}.webp`}
                              sx={{
                                border: 'unset !important',
                                height: '48px',
                                width: '48px'
                              }}
                            />
                            <Avatar
                              src={`https://storage.googleapis.com/cardeio-images/defaults/${
                                game.slug
                              }/profile/${getRandomProfileImage(game)}.webp`}
                              sx={{
                                border: 'unset !important',
                                height: '48px',
                                width: '48px'
                              }}
                            />
                          </>
                        ) : (
                          <Avatar
                            src={`https://storage.googleapis.com/cardeio-images/defaults/${
                              game.slug
                            }/profile/${getRandomProfileImage(game)}.webp`}
                            sx={{
                              border: 'unset !important',
                              height: '48px',
                              width: '48px'
                            }}
                          />
                        )}
                      </AvatarGroup>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralContainer>
  );
};

export default FeaturedEventCard;
