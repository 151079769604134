import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Avatar, Grid, Rating, Typography } from '@mui/material';
import Lottie from 'react-lottie';

import * as akoraMobileAppAnimation from '../../../assets/animations/mobile/akora.json';
import * as alphaClashMobileAppAnimation from '../../../assets/animations/mobile/alpha-clash.json';
import * as defaultMobileAppAnimation from '../../../assets/animations/mobile/cardeio.json';
import * as elestralsMobileAppAnimation from '../../../assets/animations/mobile/elestrals.json';
import * as fabledSagasMobileAppAnimation from '../../../assets/animations/mobile/fabled-sagas.json';
import * as interstellarMobileAppAnimation from '../../../assets/animations/mobile/interstellar.json';
import * as kryptikMobileAppAnimation from '../../../assets/animations/mobile/kryptik.json';
import * as metazooMobileAppAnimation from '../../../assets/animations/mobile/metazoo.json';
import * as nostalgixMobileAppAnimation from '../../../assets/animations/mobile/nostalgix.json';
import * as systemGateMobileAppAnimation from '../../../assets/animations/mobile/system-gate.json';

import googlePlay from '../../../assets/googlePlay.svg';
import appStore from '../../../assets/appStore.svg';
import appStoreMobile from '../../../assets/appStoreBadge.svg';
import googlePlayMobile from '../../../assets/googlePlayBadge.svg';
import {
  DownloadMobileAppContainer,
  MaxWidthContainer,
  QRCodeBorder,
  SocialLinksContainer,
  AppRatingWhiteBackground,
  DownloadMobileAppBackground
} from '../styles';

export const FooterApp = () => {
  const theme = useTheme();
  const { game } = useSelector(({ app }) => app);

  const animationData = {
    akora: akoraMobileAppAnimation,
    'alpha-clash': alphaClashMobileAppAnimation,
    elestrals: elestralsMobileAppAnimation,
    'fabled-sagas': fabledSagasMobileAppAnimation,
    interstellar: interstellarMobileAppAnimation,
    kryptik: kryptikMobileAppAnimation,
    metazoo: metazooMobileAppAnimation,
    nostalgix: nostalgixMobileAppAnimation,
    'system-gate': systemGateMobileAppAnimation
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData[game.slug] || defaultMobileAppAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    speed: 0.5
  };

  const availableApps = [
    {
      url: googlePlay,
      mobileUrl: googlePlayMobile,
      rating: 4.5
    },
    {
      url: appStore,
      mobileUrl: appStoreMobile,
      rating: 4.5
    }
  ];

  return (
    <DownloadMobileAppContainer container item xs={12}>
      <DownloadMobileAppBackground />
      <MaxWidthContainer
        container
        item
        wrap={'nowrap'}
        gap={2}
        alignItems={'end'}
        p={'0 25px'}
      >
        {/*QR Code Section */}
        <Grid
          item
          container
          xs={4}
          justifyContent={'flex-end'}
          display={{ xs: 'none', sm: 'flex' }}
        >
          <QRCodeBorder>
            <Lottie
              options={defaultOptions}
              height={160}
              width={160}
              style={{ marginLeft: '16px' }}
            />
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              Coming Soon
            </Typography>
          </QRCodeBorder>
        </Grid>

        {/* Mobile App Download Section */}
        <Grid
          container
          item
          xs={12}
          sm={8}
          gap={2}
          alignItems={{ xs: 'center', sm: 'unset' }}
          justifyContent={{ xs: 'center', sm: 'space-around' }}
          direction={{ xs: 'column', sm: 'unset' }}
        >
          {/* App User's Section */}
          <Grid item>
            <Grid item mb={'24px'}>
              <Typography gutterBottom variant="h3">
                {get(game, 'layout.footer.app.name', 'Mobile App')}
              </Typography>
              <Typography variant="body1">
                {get(game, 'layout.footer.app.isLive')
                  ? 'Download Now'
                  : 'Coming 2023'}
              </Typography>
            </Grid>
            <SocialLinksContainer>
              <div>
                <Avatar
                  src={`https://storage.googleapis.com/cardeio-images/defaults/${game.slug}/profile/1.webp`}
                  sx={{
                    height: '48px',
                    width: '48px'
                  }}
                />
              </div>
              <div>
                <Avatar
                  src={`https://storage.googleapis.com/cardeio-images/defaults/${game.slug}/profile/2.webp`}
                  sx={{
                    height: '48px',
                    width: '48px'
                  }}
                />
              </div>
              <div>
                <Avatar
                  src={`https://storage.googleapis.com/cardeio-images/defaults/${game.slug}/profile/3.webp`}
                  sx={{
                    height: '48px',
                    width: '48px'
                  }}
                />
              </div>
              <div>
                <Avatar
                  src={`https://storage.googleapis.com/cardeio-images/defaults/${game.slug}/profile/4.webp`}
                  sx={{
                    height: '48px',
                    width: '48px'
                  }}
                />
              </div>
            </SocialLinksContainer>
          </Grid>

          {/*Mobile App Link Section*/}
          {get(game, 'layout.footer.app.isLive') && (
            <Grid item>
              <div style={{ display: 'flex', gap: '20px' }}>
                {availableApps.map((obj, index) => {
                  return (
                    <div key={index}>
                      <AppRatingWhiteBackground
                        item
                        container
                        alignItems={'center'}
                      >
                        <Grid
                          item
                          container
                          justifyContent={'center'}
                          alignItems={'center'}
                          gap={2}
                        >
                          <Grid item>
                            <img src={obj.url} />
                          </Grid>
                          <Grid
                            item
                            container
                            justifyContent={'center'}
                            xs={12}
                          >
                            <Rating
                              value={obj.rating}
                              precision={0.5}
                              readOnly
                              size={'small'}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              sx={{ color: theme.palette.neutral[600] }}
                            >
                              Soon
                            </Typography>
                          </Grid>
                        </Grid>
                      </AppRatingWhiteBackground>
                    </div>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>
      </MaxWidthContainer>
    </DownloadMobileAppContainer>
  );
};

export default FooterApp;
