import { Paper, styled } from '@mui/material';

export const ProfileTabContainer = styled(Paper)(({ theme }) => ({
  borderRadius: `${theme?.border.borderRadius * 2}px ${theme?.border
    .borderRadius * 2}px 0px 0px`,
  overflow: 'hidden',
  display: 'flex'
}));

export const profileTabButtonStyle = {
  flexDirection: 'column',
  borderRadius: 0,
  width: { xs: '100%', sm: 'fit-content' }
};
