import PayoutRedirectPage from './index';
import PayoutRefreshPage from './refresh';
import PayoutReturnPage from './return';
import React from 'react';
import { Route } from 'react-router-dom';

const PaymentsRoutes = () => {
  return (
    <Route path={'payouts/*'} element={<PayoutRedirectPage />}>
      <Route exact path="refresh" element={<PayoutRefreshPage />} />
      <Route exact path="callback" element={<PayoutReturnPage />} />
    </Route>
  );
};

export default PaymentsRoutes;
