import { Autocomplete, FormControl, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const DeckFormat = ({ selectedFormat, setSelectedFormat }) => {
  const { game } = useSelector(({ app }) => app);

  const gameFormats = game.formats;

  return (
    <FormControl fullWidth>
      <Autocomplete
        getOptionLabel={option =>
          gameFormats.find(format => format._id === option)?.name || ''
        }
        value={selectedFormat}
        options={gameFormats.map(format => format._id)}
        onChange={(event, value) => setSelectedFormat(value || '')}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="decks-format-filter"
            label="Format"
          />
        )}
      />
    </FormControl>
  );
};

export default DeckFormat;
