import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Loader from '../../../../../components/Loading/Loader';
import DeckSection from './DeckSection';
import DeckViewSelector from './DeckViewSelector';
import { useCardsCategory } from '../../../hooks/useCardsCategory';

const DeckPreviewPage = () => {
  const formContext = useFormContext();
  const { control } = formContext || {};
  const [view, setView] = useState('stacks');
  const data = useCardsCategory();

  return (
    <Box sx={{ padding: { xs: 0, sm: 4 } }}>
      <DeckViewSelector view={view} setView={setView} />
      {formContext ? (
        <Controller
          name={'sections'}
          control={control}
          render={({ field }) => {
            if (!field.value) {
              return <Loader />;
            }
            return field?.value?.map((section, index) => (
              <Controller
                key={section.deckSectionId}
                control={control}
                name={`sections.${index}`}
                render={({ field: sectionField }) => (
                  <DeckSection
                    view={view}
                    index={index}
                    section={sectionField.value}
                    data={data}
                  />
                )}
              />
            ));
          }}
        />
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default DeckPreviewPage;
