import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  StyledSearchTextField,
  StyledSelectTextField
} from '../../../components/Shared/TextFields';
import { debounce, get } from 'lodash';

import InfiniteScroll from '../../../components/InfiniteScroll/index';
import Loader from '../../../components/Loading/Loader';
import { PaperContainer } from '../../../components/Shared/Paper/index';
import StoreItem from './StoreItem';
import { buildRoutePath } from '../../../utils/routingUtils';
import { useGetUserEntitiesQuery } from '../../../services/apis/organizePlayApi';
import { usePagination } from '../../../hooks/pagination/usePagination';
import { usePrevious } from '../../../utils/usePrevious';
import { useSelector } from 'react-redux';

const StyledStoreSortBySelectTextField = styled(StyledSelectTextField)(
  // eslint-disable-next-line no-unused-vars
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      '& .MuiSelect-select': {
        padding: '12px 10px',
        fontSize: '24px',
        lineHeight: '30px'
      }
    }
  })
);

const StyledStoreSearchTextSearchTextField = styled(StyledSearchTextField)(
  // eslint-disable-next-line no-unused-vars
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        lineHeight: '30px',
        padding: '12px 10px',
        fontSize: '24px'
      }
    }
  })
);

const NoStoreContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  width: '100%'
}));

const MyStoresPage = () => {
  const [searchText, setSearchText] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const { gameSlug } = useParams();
  const { game } = useSelector(({ app }) => app);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({});
  const previousSearchInput = usePrevious(searchInput);
  const sortByOptions = [
    { _id: 'createdAt', name: 'Recently Added' },
    { _id: 'name', name: 'Name' }
  ];

  useEffect(() => {
    if (game && game.id) {
      const queryObj = {
        gameId: game.id,
        page: currentPage,
        limit: 10
      };
      if (sortBy) {
        queryObj.sortBy = sortBy;
      }
      if (searchText) {
        queryObj.searchText = searchText;
      }
      setQuery(queryObj);
    }
  }, [game, sortBy, currentPage, searchText]);

  const searchByText = searchQuery => {
    resetPagination();
    setSearchText(searchQuery);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => searchByText(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput]);

  const { data: entities, isLoading, isFetching } = useGetUserEntitiesQuery(
    query,
    {
      skip: !query.gameId
    }
  );
  const {
    loadAction,
    treatedData,
    nextPage,
    totalResults,
    resetPagination
  } = usePagination({
    data: entities,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });
  const handleSearch = e => {
    setSearchInput(get(e, 'target.value', ''));
  };
  return (
    <Grid item xs={12}>
      <PaperContainer className={'my-stores'} elevation={0}>
        <Grid container spacing={4} className={'container'}>
          <Grid item xs={12}>
            <Typography>My Stores</Typography>
          </Grid>
          {/* search options */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-start'
                  }}
                >
                  <Box width="70%" maxWidth="400px">
                    <StyledStoreSearchTextSearchTextField
                      id="my-stores-search-input"
                      label=""
                      value={searchInput}
                      placeholder="Search store"
                      onChange={handleSearch}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} mt={{ xs: 2, sm: 0 }}>
                <Box
                  sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                  justifyContent={{
                    xs: 'center',
                    sm: 'flex-end'
                  }}
                  flexDirection={{
                    xs: 'column',
                    sm: 'row'
                  }}
                >
                  <Typography
                    variant="body3Light"
                    mr={2}
                    display={{ xs: 'none', sm: 'initial' }}
                  >
                    Sort By
                  </Typography>
                  <Box width="70%" maxWidth="400px">
                    <StyledStoreSortBySelectTextField
                      id="my-stores-sort-select"
                      idPrefix="my-stores-sort-"
                      value={sortBy}
                      onChange={event => {
                        setSortBy(event.target.value);
                      }}
                      menuItems={sortByOptions}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* search options ends */}
          {/* store listing */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <InfiniteScroll
                  isLoading={isLoading}
                  done={nextPage === 0 || totalResults === treatedData?.length}
                  loaderAction={loadAction}
                  emptyMessage="No stores found!"
                  loader={<Loader />}
                >
                  {treatedData?.map((store, index) => (
                    <StoreItem
                      key={store._id}
                      store={store}
                      itemIndex={index}
                      gameId={game.id}
                    />
                  ))}
                </InfiniteScroll>
              </Grid>
              {!treatedData || !treatedData.length ? (
                <Grid item xs={12}>
                  <NoStoreContainer>
                    <Typography variant="h2" textTransform="uppercase">
                      You don&#39;t have any stores yet
                    </Typography>
                    <Button
                      LinkComponent={Link}
                      variant="contained"
                      sx={{ marginTop: '16px' }}
                      to={buildRoutePath(gameSlug, '/lgs-application')}
                    >
                      Submit application
                    </Button>
                  </NoStoreContainer>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {/* store listing ends */}
        </Grid>
      </PaperContainer>
    </Grid>
  );
};

export default MyStoresPage;
