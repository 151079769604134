import { Box, Grid, ListItem, Typography, styled } from '@mui/material';
import React from 'react';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
// import RewardCard from './RewardCard';
import { defaultBackgroundSetup } from './styles';
import SeasonRewardsSwiper from './SeasonRewardsSwiper';
import firstPlaceIcon from '../../../assets/rewards/first.svg';
import secondPlaceIcon from '../../../assets/rewards/second.svg';
import thirdPlaceIcon from '../../../assets/rewards/third.svg';
import top8Icon from '../../../assets/rewards/top8.svg';
import top16Icon from '../../../assets/rewards/top16.svg';

const SeasonRewards = () => {
  const prizing = {
    1: firstPlaceIcon,
    2: secondPlaceIcon,
    3: thirdPlaceIcon,
    4: top8Icon,
    5: top16Icon,
    6: top16Icon
  };

  const placingName = {
    1: 'Champion',
    2: '2nd Place',
    3: '3rd Place',
    4: 'Top 4',
    5: 'Top 5',
    6: 'Top 6',
    default: 'Top 16'
  };

  const testRewardArray = [
    { prize: prizing[1], name: placingName[1] },
    { prize: prizing[2], name: placingName[2] },
    { prize: prizing[3], name: placingName[3] },
    { prize: prizing[4], name: placingName[4] },
    { prize: prizing[5], name: placingName[5] },
    { prize: prizing[6], name: placingName[6] }
  ];
  const SwiperBox = styled(Box)(({ theme }) => ({
    '* .swiper-pagination-bullet-active': {
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }));
  return (
    <Grid
      item
      xs={12}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      gap={{ xs: 2 }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="h2">Season Rewards</Typography>
          {/* {!isSmallScreen && (
            <Link underline="none" display="flex" alignItems="center" href="#">
              Reward Store
              <ArrowForwardIosIcon />
            </Link>
          )} */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            sx={defaultBackgroundSetup()}
            justifyContent="space-between"
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            display="flex"
            flexDirection="column"
            gap={5}
            height="100%"
          >
            <Typography variant="h3">Participation</Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', sm: 'flex-start' }}
            >
              <EmojiEventsOutlinedIcon style={{ fontSize: '54px' }} />
              <ListItem sx={{ display: 'list-item' }}>
                1x KS Booster Deck
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                1x KS Booster Pack
              </ListItem>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={8} item>
          <Box
            sx={defaultBackgroundSetup('47px 37px 0')}
            display="flex"
            flexDirection="column"
            bgcolor="red"
            height="100%"
          >
            <Typography
              textAlign={{ xs: 'center', sm: 'left' }}
              sx={{ typography: { sm: 'h3', xs: 'h4' } }}
            >
              Season Rewards for Winning
            </Typography>
            <SwiperBox>
              <SeasonRewardsSwiper rewards={testRewardArray} />
            </SwiperBox>
          </Box>
        </Grid>
      </Grid>
      {/* {isSmallScreen && (
        <Link underline="none" display="flex" alignItems="center" href="#">
          Reward Store
          <ArrowForwardIosIcon />
        </Link>
      )} */}
    </Grid>
  );
};

export default SeasonRewards;
