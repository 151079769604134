import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  defaultSocialLinks,
  defaultStoreTimings,
  getMergedSocials,
  getMergedTimings
} from '../../../../utils/entityStore';

import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GooglePlaceTextField } from '../../../../components/Shared/GooglePlaceTextField/index';
import { LoadingButton } from '@mui/lab';
import StoreHoursEdit from './components/StoreHours';
import StoreOrganizers from './components/StoreOrganizers';
import StorePayout from './components/StorePayout/index';
import StoreSocialsEdit from './components/StoreSocials';
import { TextField } from '@mui/material';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useForm } from 'react-hook-form';
import { usePostUpdateEntityByIdMutation } from '../../../../services/apis/organizePlayApi';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root.MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2)
  },
  '& .MuiAccordionDetails-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`
    }
  },
  marginBottom: theme.spacing(2)
}));

const StyledStoreUpdateGridContainer = styled(Grid)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: '1.75rem',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}}`
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0
  },
  '& .MuiTextField-root.store-socials-username-input': {
    '& .MuiInputBase-input': {
      fontSize: '1.25rem'
    }
  }
}));

export const defaultLocation = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

const StoreEdit = ({ storeData, gameId, onStoreUpdateSuccess }) => {
  const errorToastId = 'updateStoreToastError';
  const initValues = {
    name: '',
    description: '',
    location: { ...defaultLocation },
    storeHours: defaultStoreTimings,
    socialLinks: defaultSocialLinks
  };
  const { ticketing } = useFlags();
  const [isEntityUpdating, setIsEntityUpdating] = useState(false);
  const [updateEntity, updateEntityResult] = usePostUpdateEntityByIdMutation();
  const defaultValues = { ...initValues };
  if (storeData) {
    defaultValues.name = get(storeData, 'name', '');
    defaultValues.description = get(storeData, 'info.description', '');
    defaultValues.location = get(storeData, 'location', defaultLocation);
    defaultValues.storeHours = getMergedTimings(
      get(storeData, 'info.openingHours', [])
    );
    defaultValues.socialLinks = getMergedSocials(
      get(storeData, 'info.socials', [])
    );
  }
  const onSubmit = async data => {
    const { socialLinks, ...restData } = data;
    const updatedSocialLinks = socialLinks.map((socialLink, index) => {
      return {
        ...socialLink,
        order: index + 1
      };
    });
    updateEntity({
      storeId: storeData._id,
      body: {
        socialLinks: updatedSocialLinks,
        ...restData
      },
      gameId
    });
  };
  useEffect(() => {
    setIsEntityUpdating(updateEntityResult.isLoading);
    if (updateEntityResult.isSuccess) {
      toast.success('Store Updated Successfully');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      onStoreUpdateSuccess();
    }
    if (updateEntityResult.isError) {
      toast.error('Store Update Failed', { toastId: errorToastId });
    }
  }, [updateEntityResult]);
  const onError = () => {
    toast.error('Missing Information', { toastId: errorToastId });
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues
  });
  return (
    <form
      id="store-update-form"
      data-testid="store-update-form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <StyledStoreUpdateGridContainer container spacing={4}>
        <Grid item xs={12}>
          {/* store payout starts */}
          {ticketing && (
            <StyledAccordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    justifyContent: 'center'
                  }
                }}
              >
                <Typography variant="h3">Payment Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <StorePayout storeData={storeData} gameId={gameId} />
              </AccordionDetails>
            </StyledAccordion>
          )}
          {/* store payout ends */}
          {/* store information starts */}
          <StyledAccordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Store Information
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="body1Light" mb={1} display="flex">
                    Store Name
                  </Typography>
                  <Controller
                    name={'name'}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        id="store-update-name-input"
                        data-testid="store-update-name-input"
                        {...field}
                        error={'name' in errors}
                        placeholder={'Store Name'}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1Light" mb={1} display="flex">
                    Store Location
                  </Typography>
                  <Controller
                    name={'location'}
                    control={control}
                    rules={{
                      validate: value => {
                        return (
                          value.name !== '' &&
                          value.city !== '' &&
                          value.address1 !== ''
                        );
                      }
                    }}
                    render={({ field }) => (
                      <GooglePlaceTextField
                        id="store-update-location-input"
                        data-testid="store-update-location-input"
                        {...field}
                        error={'location' in errors}
                        property={'location'}
                        hideLabel
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1Light" mb={1} display="flex">
                    Store Description
                  </Typography>
                  <Controller
                    name={'description'}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="store-update-description-input"
                        data-testid="store-update-description-input"
                        {...field}
                        error={'description' in errors}
                        placeholder={'Store Description'}
                        rows={4}
                        multiline
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </StyledAccordion>
          {/* store information ends */}
          {/* store timings starts */}
          <StyledAccordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Store Hours
            </AccordionSummary>
            <AccordionDetails>
              <StoreHoursEdit control={control} name="storeHours" />
            </AccordionDetails>
          </StyledAccordion>
          {/* store timings ends */}
          {/* store organizers starts */}
          <StyledAccordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              My Organizers
            </AccordionSummary>
            <AccordionDetails>
              <StoreOrganizers storeData={storeData} gameId={gameId} />
            </AccordionDetails>
          </StyledAccordion>
          {/* store organizers ends */}
          {/* store socials starts */}
          <StyledAccordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Social Media/Links
            </AccordionSummary>
            <AccordionDetails>
              <StoreSocialsEdit control={control} name="socialLinks" />
            </AccordionDetails>
          </StyledAccordion>
          {/* store socials ends */}
        </Grid>
        <Grid item xs={12} mt={3}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              textAlign={{ xs: 'center', sm: 'end' }}
              order={{ xs: 2, sm: 1 }}
            >
              <Button
                type="reset"
                sx={{ minWidth: '200px' }}
                variant="contained"
                disabled={!isDirty || isEntityUpdating}
                onClick={() => reset(defaultValues)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              textAlign={{ xs: 'center', sm: 'start' }}
              order={{ xs: 1, sm: 2 }}
            >
              <LoadingButton
                type="submit"
                sx={{ minWidth: '200px' }}
                variant="contained"
                disabled={!isDirty || isEntityUpdating}
                loading={isEntityUpdating}
              >
                Save changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledStoreUpdateGridContainer>
    </form>
  );
};

export default StoreEdit;
