import { styled } from '@mui/material';
import { get } from 'lodash';
import { ToggleButton } from '../../components/Shared/Buttons';

export const DeckButtons = styled(ToggleButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  '&.deck-selection': {},
  '&.active-button': {
    color: get(theme, 'colors.components.button.primary.text', '#FFFFFF')
  }
}));

export const commonDeckStyle = {
  border: '1px solid #FFF3'
};
