import {
  capitalize,
  compact,
  each,
  find,
  forEach,
  get,
  isArray,
  isEmpty,
  isEqual,
  sortBy
} from 'lodash';

export const getCartItemCount = cart => {
  let count = 0;
  each(cart, product => {
    each(product, inventory => {
      each(inventory, quantity => {
        count += quantity;
      });
    });
  });
  return count;
};

export const getCartTotalFiatPrice = cartDetails => {
  const total = {};
  if (isEmpty(get(cartDetails, 'cart.items'))) {
    return total;
  }
  forEach(cartDetails.cart.items, (product, productId) => {
    const findProduct = find(
      get(cartDetails, 'products'),
      p => p.id === productId
    );
    forEach(product, (inventory, inventoryId) => {
      const findInventory = find(
        get(findProduct, 'inventory'),
        inv => inv.id === inventoryId
      );
      forEach(inventory, (quantity, productPriceId) => {
        const findPrice = find(
          get(findInventory, 'productPrices'),
          pp => pp.id === productPriceId
        );
        const currentTotal = quantity * get(findPrice, 'fiatPrice.amount', 0);
        const currentSymbol = get(findPrice, 'fiatPrice.symbol', '');
        if (total[currentSymbol]) {
          total[currentSymbol] += currentTotal;
        } else {
          total[currentSymbol] = currentTotal;
        }
      });
    });
  });
  return total;
};

// eslint-disable-next-line no-unused-vars
export const getCartListingFromDetails = (cartDetails, currencies) => {
  const cartData = [];
  const total = {};
  const totalPrices = [];
  if (isEmpty(get(cartDetails, 'cart.items')) || isEmpty(currencies)) {
    return { cartData, totalPrices };
  }
  forEach(cartDetails.cart.items, (product, productId) => {
    const findProduct = find(
      get(cartDetails, 'products'),
      p => p.id === productId
    );
    forEach(product, (inventory, inventoryId) => {
      const findInventory = find(
        get(findProduct, 'inventory'),
        inv => inv.id === inventoryId
      );
      forEach(inventory, (quantity, productPriceId) => {
        const findPrice = find(
          get(findInventory, 'productPrices'),
          pp => pp.id === productPriceId
        );
        const currency = find(currencies, cr => cr.id === findPrice.currencyId);
        cartData.push({
          productId,
          inventoryId,
          productPriceId,
          quantity,
          product: {
            ...findProduct,
            inventory: {
              ...findInventory,
              productPrice: {
                ...findPrice
              }
            }
          },
          currency
        });
        if (!total[findPrice.currencyId]) {
          total[findPrice.currencyId] = quantity * findPrice.price;
        } else {
          total[findPrice.currencyId] =
            total[findPrice.currencyId] + quantity * findPrice.price;
        }
      });
    });
  });
  forEach(total, (amount, currencyId) => {
    const currency = find(currencies, cr => cr.id === currencyId);
    totalPrices.push({ amount, currency });
  });
  return { cartData, totalPrices };
};

const getSelectedPrice = (prices, currency) => {
  const selectedPrice = find(prices, price => price.currencyId === currency);

  return selectedPrice;
};

export const getVariantMapping = (productDetails, selectedCurrency) => {
  if (isEmpty(get(productDetails, 'inventory', []))) {
    return { variants: [], availabilityMap: {} };
  }
  const inventories = get(productDetails, 'inventory', []);
  const availableVariantsMap = [];
  const availableOrderMap = [];
  for (const inventory of inventories) {
    const iVariants = inventory.variant;
    const price = get(
      getSelectedPrice(inventory.productPrices, selectedCurrency),
      'price',
      0
    );
    const fiatPrice = get(
      getSelectedPrice(inventory.productPrices, selectedCurrency),
      'fiatPrice',
      null
    );
    const variantKeys = Object.keys(iVariants);
    forEach(variantKeys, vk => {
      availableVariantsMap[vk] = [
        ...(availableVariantsMap[vk] || []),
        {
          variant: iVariants[vk],
          price,
          fiatPrice
        }
      ];
      availableOrderMap[vk] = {};
    });
  }
  for (const inventory of inventories) {
    const iVariants = inventory.variant;
    const iOrder = inventory.order;
    const orderKeys = Object.keys(iOrder);
    forEach(orderKeys, ok => {
      availableOrderMap[ok][iVariants[ok]] = iOrder[ok];
    });
  }
  const availableVariants = Object.keys(availableVariantsMap); // [size, color]
  const availableVariantsWithValue = availableVariants.map(av => ({
    variant: av,
    values: availableVariantsMap[av]
  }));
  const variantsAvailabilityMap = {};

  forEach(availableVariantsWithValue, avv => {
    const key = avv.variant;
    const vOrder = availableOrderMap[key];
    avv.values = sortBy(avv.values, v => {
      return vOrder[v.variant];
    });
  });

  for (const inventory of inventories) {
    variantsAvailabilityMap[inventory.id] = inventory.variant;
  }
  return {
    variants: availableVariantsWithValue,
    availabilityMap: variantsAvailabilityMap
  };
};

export const getSelectedVariantInventoryId = (
  variantAvailabilityMap,
  selectedVariant
) => {
  if (!variantAvailabilityMap || !selectedVariant) {
    return null;
  }
  const invIds = Object.keys(variantAvailabilityMap);
  for (const invId of invIds) {
    if (isEqual(variantAvailabilityMap[invId], selectedVariant)) {
      return invId;
    }
  }
  return null;
};

export const getVariantsArrayFromInventory = inventory => {
  if (isEmpty(inventory) || isEmpty(get(inventory, 'variant'))) {
    return [];
  }
  const variant = get(inventory, 'variant');
  const variantArr = [];
  forEach(variant, (variantValue, variantName) => {
    variantArr.push({ variantName: capitalize(variantName), variantValue });
  });
  return variantArr;
};

export const getAvailableImageFromProduct = (product, inventoryId) => {
  if (!product) return null;
  let inventories = get(product, 'inventory', null);
  if (isEmpty(inventories)) return null;
  if (!isArray(inventories)) {
    inventories = [inventories];
  }
  const productImagesMap = {};
  forEach(inventories, inventory => {
    const images = [];
    forEach(get(inventory, 'resourceImages', []), img => {
      images.push(get(img, 'image.url'));
    });
    const compactImages = compact(images);
    if (compactImages.length) {
      productImagesMap[inventory.id] = compact(images);
    }
  });
  const keys = Object.keys(productImagesMap);
  const firstImage = get(productImagesMap, `${keys[0]}[0]`, null);
  if (inventoryId) {
    return get(productImagesMap, `${inventoryId}.[0]`, firstImage);
  }
  return firstImage;
};

export const getTotalPricesFromOrder = (
  order,
  currencies,
  returnArray = false
) => {
  if (!order || !currencies) return [];
  const total = get(order, 'total', {});
  const totalArr = [];
  forEach(total, (amount, currencyId) => {
    if (currencyId !== 'fiatTotal' && currencyId !== 'totalAmount') {
      const foundCurrency = find(currencies, cr => cr.id === currencyId);
      totalArr.push({ amount, symbol: get(foundCurrency, 'symbol', 'points') });
    }
  });
  if (returnArray) return totalArr;
  return totalArr.map(ta => `${ta.amount} ${ta.symbol}`).join(', ');
};

export const hasPhysicalProductInCart = cart => {
  if (!cart || !cart.products) return false;
  const products = get(cart, 'products', []);
  for (const product of products) {
    if (!get(product, 'isDigital', false)) {
      return true;
    }
  }
  return false;
};
