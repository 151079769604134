import React, { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Grid } from '@mui/material';

import StatusPage, { statusTypes } from '../../Status';
import DeckDetailHeader from '../../../components/Decks/DeckDetailHeader';
import DeckDetailView from '../../../components/Decks/Sections/DeckDetailView';
import Loader from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import { buildValues } from '../../../utils/deck';
import { useDeck } from '../hooks/useDeck';

const DeckDetailPage = () => {
  const { deckMetrics } = useFlags();
  const { game } = useSelector(({ app }) => app);
  const navigate = useNavigate();
  const { deckId, ...params } = useParams();
  const { singleDeckProps, defaultDeckType, deckFormats } = useDeck({ deckId });
  const { data: singleDeckData } = singleDeckProps;

  const { deck, gameUser, user } = singleDeckData?.data || {};

  const gameFormatsById =
    deckFormats?.reduce((prev, current) => {
      return { ...prev, [current._id]: current };
    }, {}) || {};

  // React hook form hook
  const methods = useForm({
    defaultValues: {},
    values: buildValues(singleDeckData, defaultDeckType, gameFormatsById)
  });

  const pageRoutes = [
    { title: 'Deck', key: 'deck', path: '', show: true },
    {
      title: 'Metrics',
      key: 'metrics',
      path: 'metrics',
      show: deckMetrics
    }
  ].filter(section => section.show);

  const [sectionSelected, setSectionSelected] = useState(
    pageRoutes.findIndex(section =>
      `${deckId}/${section.path}`.includes(params['*'].toString())
    )
  );

  const selectionAction = index => {
    setSectionSelected(index);
    if (sectionSelected !== index) {
      navigate(pageRoutes[index].path, {
        preventScrollReset: true
      });
    }
  };

  if (singleDeckProps.isLoading) {
    return <Loader entireScreen />;
  }

  if (!singleDeckProps.data || deck?.gameId !== game?.id) {
    return <StatusPage type={statusTypes.deckNotFound} />;
  }

  return (
    <FormProvider {...methods}>
      <MaxWidthContainer container className={'p-percent p-top-5'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DeckDetailHeader deck={deck} gameUser={gameUser} user={user} />
          </Grid>
          <Grid item xs={12}>
            <DeckDetailView
              sectionSelected={sectionSelected}
              onSelectionChange={selectionAction}
              sections={pageRoutes}
            >
              <Outlet context={{ deck: singleDeckData }} />
            </DeckDetailView>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </FormProvider>
  );
};

export default DeckDetailPage;
