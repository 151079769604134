import { Box, Button, styled } from '@mui/material';

export const SideButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  padding: '10px',
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  [theme.breakpoints.down('md')]: {
    padding: '5px 0'
  }
}));

export const StyledVariantDisplayWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  padding: `${theme.spacing(2)} 0`
}));
