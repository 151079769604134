import { Grid, styled } from '@mui/material';

export const MaxWidthContainer = styled(Grid)`
  max-width: 1500px;
  margin: 0 auto;

  &.p-percent {
    padding: 0 5%;

    &.landing-header {
      padding-right: 0;
    }
  }
  &.p-top {
    padding-top: 10%;
  }
  &.p-top-5 {
    padding-top: 5%;
  }

  &.carousel {
  }
`;

export const MaxWidthPadded = styled(Grid)`
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 10%;
  padding-right: 10%;
`;
