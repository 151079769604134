import React, { Fragment } from 'react';

const HamburgerIcon = ({ color }) => (
  <Fragment>
    <path
      d="M2 2H24"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2 18H24"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2 10H31"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Fragment>
);

export default HamburgerIcon;
