import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import StripePaymentForm from './form';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from '@mui/material';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

const StripePayment = ({
  clientSecret,
  clientEmail = '',
  showCancel = false,
  paymentBreakdown = null,
  onCancel = () => {}
}) => {
  const theme = useTheme();
  const stripeCustomization = {
    colorPrimary: theme.palette.primary.main,
    colorBackground: theme.palette.background.default,
    colorText: theme.palette.text.primary,
    colorDanger: theme.palette.error.main
  };
  return (
    <Elements
      options={{
        clientSecret: clientSecret,
        appearance: { theme: 'stripe', variables: stripeCustomization }
      }}
      stripe={stripePromise}
    >
      <StripePaymentForm
        clientSecret={clientSecret}
        clientEmail={clientEmail}
        showCancel={showCancel}
        onCancel={onCancel}
        paymentBreakdown={paymentBreakdown}
      />
    </Elements>
  );
};

export default StripePayment;
