import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { allHaveSomeValue, hasSomeValue } from '../../../utils/common-utils';
import { useDispatch, useSelector } from 'react-redux';

import { EventCard } from '../../../components/Events/Cards/EventCard';
import GameStats from '../../../components/GameInfo/Stats';
import InfiniteScroll from '../../../components/InfiniteScroll';
import Loader from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import Search from './Search';
import { get } from 'lodash';
import { getISODateString } from '../../../utils/date';
import { resetActiveEvent } from '../../../store/events/actions';
import { useGetEventsByGameQuery } from '../../../services/apis/organizePlayApi';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  limit: 20
};

export const EventListingsPage = () => {
  const dispatch = useDispatch();
  const { game } = useSelector(({ app }) => app);
  const [query, setQuery] = useState('');
  const offset = new Date().getTimezoneOffset();

  // Game id used to search for events
  const { id } = useSelector(state => state.app.game);
  const [events, setEvents] = useState([]);
  const [currentPaginationData, setCurrentPaginationData] = useState(
    DEFAULT_PAGINATION_OPTIONS
  );
  const [shouldResetEvents, setShouldResetEvents] = useState(false);
  // // eslint-disable-next-line no-unused-vars
  const [userInput, setUserInput] = useState({
    startDate: new Date(),
    endDate: null,
    name: '',
    formatId: '',
    location: null,
    radius: 50,
    locationType: 'anywhere'
  });

  useEffect(() => {
    const { page, limit } = currentPaginationData;
    const queryParams = [];
    queryParams.push(`offset=${offset}`);
    const excludedParams = ['location', 'locationType', 'startDate', 'endDate'];
    let shouldReset = false;
    for (const [k, v] of Object.entries(userInput)) {
      if (allHaveSomeValue(v, k) && !excludedParams.includes(k)) {
        queryParams.push(`${k}=${v}`);
      }
    }

    const dateParams = ['startDate', 'endDate'];
    for (const dateKey of dateParams) {
      if (userInput[dateKey]) {
        const isoDate = getISODateString(userInput[dateKey]);
        if (isoDate) queryParams.push(`${dateKey}=${isoDate}`);
      }
    }

    const { locationType, location } = userInput;

    if (hasSomeValue(locationType)) {
      switch (locationType) {
        case 'in-person': {
          shouldReset = true;
          if (location) {
            const lng = get(location, 'geo.coordinates[0]', null);
            const lat = get(location, 'geo.coordinates[1]', null);
            if (lng !== null && lat !== null) {
              queryParams.push(`lat=${lat}`);
              queryParams.push(`lng=${lng}`);
              shouldReset = false;
            }
          }
          break;
        }
        case 'online': {
          queryParams.push('isOnline=1');
        }
      }
    }

    if (shouldReset) {
      setShouldResetEvents(true);
    } else {
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
      setQuery(queryParams.join('&'));
    }
  }, [currentPaginationData, userInput]);

  const { data, isLoading, isFetching } = useGetEventsByGameQuery({
    gameId: id,
    query
  });

  const { activePage, totalPages, totalResults } = get(data, 'pagination', {});

  // eslint-disable-next-line no-unused-vars
  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(data, 'pagination', {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    });
    if (!isLoading && !isFetching && activePage < totalPages) {
      setCurrentPaginationData(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  useEffect(() => {
    const { activePage } = get(data, 'pagination', 1);
    if (data) {
      if (activePage === 1) {
        setEvents(data.data);
      } else {
        setEvents(prevState => [...prevState, ...data.data]);
      }
    }
  }, [data]);

  // If data is not cached, remove prev data and show loader.
  useEffect(() => {
    const page = get(currentPaginationData, 'page', 0);
    if (isFetching && page === 1) {
      setEvents([]);
    }
  }, [isFetching]);

  const resetCurrentPaginatedData = () => {
    setEvents([]);
    dispatch(resetActiveEvent());
    setCurrentPaginationData(DEFAULT_PAGINATION_OPTIONS);
  };

  useEffect(() => {
    if (shouldResetEvents) {
      setShouldResetEvents(false);
      resetCurrentPaginatedData();
    }
  }, [shouldResetEvents]);

  const dateChange = (date, key) => {
    let newDate = date;
    if (!date || !date.isValid()) {
      newDate = null;
    }
    resetCurrentPaginatedData();
    setUserInput(prevState => {
      const newState = { ...prevState };
      newState[key] = newDate;
      return newState;
    });
  };

  useEffect(() => {
    setEvents([]);
    dispatch(resetActiveEvent());
    setCurrentPaginationData({ ...DEFAULT_PAGINATION_OPTIONS });
  }, []);

  const handleJoinClick = eventId => {
    const allEvents = [];

    events.forEach(event => {
      if (event._id === eventId) {
        allEvents.push({
          ...event,
          details: {
            ...event.details,
            participantCount: event.details.participantCount + 1
          },
          canJoin: false
        });
      } else {
        allEvents.push(event);
      }
    });

    setEvents(allEvents);
  };

  const handleUserInputChange = newUserInputData => {
    resetCurrentPaginatedData();
    setUserInput(prevState => {
      return {
        ...prevState,
        ...newUserInputData
      };
    });
  };

  const getTotalEventsCount = () => {
    return events && events.length ? totalResults : 0;
  };

  return (
    <MaxWidthContainer className={'p-percent p-top'} container>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={8}>
            {/* Intro Section */}
            <Grid item xs={12}>
              <Grid item xs={12} mb={{ xs: 2, md: 4 }}>
                <Typography variant="h1" data-testid="event-title">
                  Event Listings
                </Typography>
              </Grid>
              <Grid item xs={12} mb={{ xs: 2, md: 4 }}>
                <Typography variant="subtitle1">
                  {`Discover Upcoming Tournaments and Compete with ${get(
                    game,
                    'terminology.players',
                    'Players'
                  )} in Your Region!`}
                </Typography>
              </Grid>

              <GameStats
                totalEvents={get(game, 'stats.events')}
                totalPlayers={get(game, 'stats.players')}
              />
            </Grid>

            <Search
              idPrefix="events"
              dateChange={dateChange}
              setUserInput={handleUserInputChange}
              userInput={userInput}
            />
          </Grid>
        </Grid>

        <Grid xs={12} item>
          <Grid container spacing={4} pt={8} pb={6}>
            <Grid alignItems="flex-end" container item xs={12}>
              <Typography variant="h1">Event Results</Typography>
              {totalResults !== undefined && !isLoading && (
                <Typography
                  mb="6px"
                  ml={1.5}
                  variant="body"
                >{`${getTotalEventsCount()} Total Events`}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          id="events-search-results-section"
          data-testid="events-search-results-section"
          xs={12}
          item
        >
          <InfiniteScroll
            done={activePage >= totalPages || !data}
            emptyMessage={'No events Found'}
            isLoading={isLoading || isFetching}
            loader={isLoading || isFetching ? <Loader /> : null}
            loaderAction={loadAction}
            shouldScrollToTop={false}
            emptyResultContainerStyle={{
              position: 'relative',
              minHeight: '50vh'
            }}
          >
            {events && events.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 3,
                  justifyContent: 'space-around'
                }}
              >
                {events.map(event => (
                  <EventCard
                    key={event._id}
                    id={`all-event-list-page-${event._id}`}
                    event={event}
                    handleJoinClick={handleJoinClick}
                  />
                ))}
              </Box>
            )}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default EventListingsPage;
