import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeList, areEqual } from 'react-window';
import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import {
  createDeckNamePath,
  getDeckCardCount,
  getDeckErrors
} from '../../../../../utils/deck';

import AutoSizer from 'react-virtualized-auto-sizer';
import CloseIcon from '@mui/icons-material/Close';
import DeckAccordion from '../../../../../components/Decks/DeckAccordion';
import SingleCard from './SingleCard';
import { drawerStyle } from './styles';
import { useTheme } from '@mui/material/styles';

const DeckPage = ({ checkDeckValidation, deckDetailRef, deckProps }) => {
  const formContext = useFormContext();
  const { control, getValues } = formContext || {};
  const theme = useTheme();
  const isDrawer = useMediaQuery(theme.breakpoints.down('md'));
  const { deckOpenProps, offsetTop } = deckProps || {};
  const [deckOpen, setDeckOpen] = deckOpenProps;
  const defaultHeight = 500;
  const [height, setHeight] = useState(defaultHeight);

  const errors = useWatch({ control: control, name: 'errors' });

  useEffect(() => {
    if (deckDetailRef?.current) {
      const resizeObserver = new ResizeObserver(e => {
        setHeight(e[0].contentRect.height);
      });
      resizeObserver.observe(deckDetailRef?.current);
      return () => resizeObserver.disconnect(); // clean up
    } else if (height !== defaultHeight) {
      setHeight(defaultHeight);
    }
  }, [deckDetailRef?.current]);

  useEffect(() => {
    if (!isDrawer) setDeckOpen(false);
  }, [isDrawer]);

  return (
    <Drawer
      id="create-deck-side-view-drawer-section"
      data-testid="create-deck-side-view-drawer-section"
      open={deckOpen}
      onClose={() => setDeckOpen(false)}
      anchor={'right'}
      sx={theme =>
        drawerStyle({
          deckDetailRef,
          defaultHeight: defaultHeight,
          height,
          isDrawer,
          theme,
          offsetTop
        })
      }
      variant={isDrawer ? 'temporary' : 'permanent'}
    >
      <Box>
        {isDrawer && (
          <Box sx={{ padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              id="create-deck-side-view-drawer-close-button"
              data-testid="create-deck-side-view-drawer-close-button"
              onClick={() => setDeckOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Controller
          name={'sections'}
          control={control}
          render={({ field }) => {
            return field?.value?.map((section, sectionIndex) => (
              <Controller
                key={section?.deckSectionId}
                name={`sections.${sectionIndex}`}
                control={control}
                render={({ field: sectionField }) => {
                  useWatch({
                    control,
                    name: `sections.${sectionIndex}.cards`
                  });

                  // const sectionName = section.name;
                  const rowHeight = 60;
                  const gutter_Size = 8;
                  const cardLength = sectionField?.value?.cards?.length;

                  // eslint-disable-next-line react/display-name
                  const Row = memo(
                    ({ index, style }) => (
                      <div
                        style={{
                          ...style,
                          top: style.top + gutter_Size,
                          height: style.height - gutter_Size
                        }}
                      >
                        <SingleCard
                          type={
                            sectionIndex == 0
                              ? 'main'
                              : sectionIndex == 1
                              ? 'side'
                              : sectionIndex
                          }
                          sectionIndex={sectionIndex}
                          cardIndex={index}
                          checkDeckValidation={checkDeckValidation}
                          context={formContext}
                        />
                      </div>
                    ),
                    areEqual
                  );

                  // eslint-disable-next-line react/display-name
                  const innerElementType = forwardRef(
                    ({ style, ...rest }, ref) => (
                      <div
                        ref={ref}
                        style={{
                          ...style,
                          height: `${parseFloat(style.height) + gutter_Size}px`
                        }}
                        {...rest}
                      />
                    )
                  );

                  // eslint-disable-next-line react/display-name
                  const list = useMemo(() => {
                    return (
                      <Box
                        sx={{
                          paddingX: `${gutter_Size}px`
                        }}
                      >
                        <AutoSizer disableHeight>
                          {({ width }) => (
                            <FixedSizeList
                              className={'remove-scrollbar'}
                              innerElementType={innerElementType}
                              height={
                                rowHeight * cardLength +
                                (cardLength + 1) * gutter_Size
                              }
                              width={width}
                              itemSize={rowHeight + gutter_Size}
                              itemCount={cardLength}
                            >
                              {Row}
                            </FixedSizeList>
                          )}
                        </AutoSizer>
                      </Box>
                    );
                  }, [sectionField?.value?.cards]);

                  return (
                    <DeckAccordion
                      type="create-deck-side-view"
                      error={getDeckErrors({
                        errors,
                        id: section?.deckSectionId
                      })}
                      name={getValues(
                        createDeckNamePath(section?.deckSectionId)
                      )}
                      count={getDeckCardCount(
                        getValues(`sections.${sectionIndex}.cards`)
                      )}
                    >
                      {list}
                    </DeckAccordion>
                  );
                }}
              />
            ));
          }}
        />
      </Box>
    </Drawer>
  );
};

export default DeckPage;
