import { Box } from '@mui/material';
import React from 'react';

function StoreIcon({ color = 'currentColor', size = 37 }) {
  return (
    <Box
      padding="5px"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 39 37"
      >
        <path
          fill={color}
          fillRule="evenodd"
          d="M1.773 15.261a1.331 1.331 0 012.662 0v15.084a3.992 3.992 0 003.993 3.993h21.295a3.992 3.992 0 003.993-3.993V15.261a1.331 1.331 0 012.662 0v15.084A6.655 6.655 0 0129.723 37H8.428a6.655 6.655 0 01-6.655-6.655V15.261z"
          clipRule="evenodd"
        ></path>
        <path
          fill={color}
          fillRule="evenodd"
          d="M19.077 21.028c1.765 0 3.456.701 4.706 1.949a6.659 6.659 0 011.948 4.706v7.986a1.331 1.331 0 01-2.662 0v-7.986a3.992 3.992 0 00-6.816-2.823 3.991 3.991 0 00-1.17 2.823v7.986a1.331 1.331 0 01-2.661 0v-7.986c0-1.766.7-3.457 1.948-4.706a6.659 6.659 0 014.707-1.949zM13.654 1.33v9.983a6.79 6.79 0 01-6.79 6.788 6.869 6.869 0 01-5.41-2.639 6.867 6.867 0 01-1.25-5.888l1.411-5.642A5.191 5.191 0 016.651 0h5.672c.735 0 1.33.596 1.33 1.33zm-2.662 1.332h-4.34c-1.161 0-2.173.79-2.453 1.915L2.788 10.22a4.204 4.204 0 00.763 3.604 4.208 4.208 0 003.313 1.615 4.128 4.128 0 004.128-4.126V2.662zM25.83 0h5.673a5.191 5.191 0 015.036 3.932l1.41 5.642a6.867 6.867 0 01-1.249 5.888 6.869 6.869 0 01-5.41 2.639 6.79 6.79 0 01-6.79-6.788V1.331C24.5.596 25.096 0 25.83 0zm1.332 2.662v8.651a4.128 4.128 0 004.128 4.126 4.208 4.208 0 003.313-1.615 4.204 4.204 0 00.763-3.604l-1.411-5.643a2.527 2.527 0 00-2.452-1.915h-4.341z"
          clipRule="evenodd"
        ></path>
        <path
          fill={color}
          fillRule="evenodd"
          d="M12.307 0H25.83a1.333 1.333 0 011.33 1.333l-.008 9.653a7.12 7.12 0 01-7.12 7.115h-1.934a7.12 7.12 0 01-7.121-7.12v-9.65c0-.735.596-1.331 1.33-1.331zm1.331 2.662v8.32a4.459 4.459 0 004.46 4.457h1.934a4.457 4.457 0 004.458-4.454l.009-8.323h-10.86z"
          clipRule="evenodd"
        ></path>
      </svg>
    </Box>
  );
}

export default StoreIcon;
