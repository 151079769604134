import { useAuth0 } from '@auth0/auth0-react';
import { useCustomNavigation } from '../navigation/useCustomNavigation';

export function useLogout() {
  const { logout } = useAuth0();
  const { buildUrlPath } = useCustomNavigation();

  const logoutAction = (path = '') => {
    logout({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || undefined,
      returnTo: buildUrlPath(path)
    });
  };

  return { logoutAction, logout };
}
