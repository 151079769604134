import React from 'react';

const LocationIcon = ({ color = '#fff', width = 14, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 18"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M7 1.286a5.143 5.143 0 00-5.143 5.143c0 .65.307 1.623.845 2.788.527 1.141 1.235 2.383 1.952 3.537A65.262 65.262 0 007 16.264a67.068 67.068 0 002.347-3.51c.716-1.154 1.424-2.396 1.951-3.537.538-1.165.845-2.139.845-2.788A5.143 5.143 0 007 1.286zm0 16.071l-.515.385-.004-.006-.013-.018a66.443 66.443 0 01-2.907-4.286c-.73-1.177-1.468-2.468-2.026-3.676C.988 8.573.572 7.38.572 6.43a6.429 6.429 0 0112.857 0c0 .95-.417 2.144-.964 3.327-.557 1.208-1.296 2.5-2.026 3.676a66.473 66.473 0 01-2.857 4.22l-.035.048-.014.018-.014.018-.004.006L7 17.357zm0 0l.515.385a.643.643 0 01-1.03 0L7 17.357z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M7 5.143a1.286 1.286 0 100 2.571 1.286 1.286 0 000-2.571zM4.43 6.429a2.571 2.571 0 115.143 0 2.571 2.571 0 01-5.143 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default React.memo(LocationIcon);
