import { useSelector } from 'react-redux';
import { get } from 'lodash';

export function useCardsCategory(filterName = 'Card Type') {
  const { game } = useSelector(({ app }) => app);
  let defName = filterName.charAt(0).toUpperCase() + filterName.slice(1);
  const genericDefinitionCardType = game?.genericDefinitions.find(
    genericDefinition => genericDefinition.name === defName
  );
  const definitionId = get(genericDefinitionCardType, '_id');
  const cardAttributes = filterName
    ? game?.cardAttributes.filter(
        cardAttribute => cardAttribute.definitionId === definitionId
      )
    : game?.cardAttributes;
  const cardCategories = cardAttributes.reduce((acc, cardType) => {
    const { name, slug, _id } = cardType;
    acc[_id] = { name, slug };
    return acc;
  }, {});
  return cardCategories;
}
