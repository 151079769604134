import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Accordion from '../../Shared/Accordion';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '../../Shared/Tooltip';
import { deckSectionStyle } from './styles';
import { kebabCase } from 'lodash';

const DeckAccordion = ({
  type = 'default',
  name = 'NA',
  error = undefined,
  count = 0,
  ...props
}) => {
  const [open, setOpen] = useState(count > 0);
  useEffect(() => {
    setOpen(count > 0);
  }, [count]);

  const slug = kebabCase(name);

  return (
    <Accordion
      id={type && slug ? `${type}-${slug}-accordion-button` : null}
      data-testid={type && slug ? `${type}-${slug}-accordion-button` : null}
      className={'deck-accordion'}
      expanded={open}
      onChange={() => setOpen(prev => !prev)}
      sx={deckSectionStyle}
      detailsStyle={{
        padding: 0
      }}
      childrenSummary={
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ minHeight: '36px' }}
        >
          <Grid item>
            <Grid container alignItems={'center'}>
              <Grid item>
                <Typography sx={{ ...props.mainTextStyle }}>{name}</Typography>
              </Grid>
              {error && (
                <Grid
                  id={`create-deck-side-view-accordion-${slug}-error-tooltip`}
                  data-testid={`create-deck-side-view-accordion-${slug}-error-tooltip`}
                  item
                >
                  <Tooltip
                    icon={
                      <ErrorIcon
                        className={'error-icon'}
                        fontSize={'small'}
                        color={'error'}
                      />
                    }
                    info={error}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography>({count})</Typography>
          </Grid>
        </Grid>
      }
    >
      {props?.children}
    </Accordion>
  );
};

export default DeckAccordion;
