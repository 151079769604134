import { Box, Typography, styled } from '@mui/material';
import React, { useContext } from 'react';
import {
  getFormattedCurrency,
  getUserReadableCurrencyAmount
} from '../../../../utils/number';

import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import OrderDetailsModal from '../OrderDetailsModal';
import OrderStatus from '../OrderStatus';
import ResponsiveImage from '../../../../components/Shared/Images/ResponsiveImage';
import StoreCurrency from '../../../../components/Shared/StoreCurrency';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { getTotalPricesFromOrder } from '../../../../utils/cart';
import testImage from '../../../../assets/test.png';
import { useSelector } from 'react-redux';

const OrderStatusContainer = styled(Box)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const OrderImageContainer = styled(Box)`
  cursor: pointer;
`;

const OrderCard = ({ order }) => {
  const { game } = useSelector(({ app }) => app);
  const currencies = get(game, 'pgGame.currencies', []);
  const orderDate = dayjs(new Date(order.createdAt)).format('MMMM D, YYYY');
  const orderAmount = getTotalPricesFromOrder(order, currencies, true);
  const { addModal } = useContext(ModalContext);
  const openOrderDetails = () => {
    addModal({
      children: <OrderDetailsModal orderId={order.id} />
    });
  };
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      gap={2}
      position="relative"
    >
      <OrderImageContainer onClick={openOrderDetails}>
        <ResponsiveImage
          src={get(order, 'firstImage.url', testImage)}
          paddingTop="0"
        />
      </OrderImageContainer>
      <Typography variant="body1">{orderDate}</Typography>
      {orderAmount.map(orderCurrencyTotal => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          key={orderCurrencyTotal.symbol}
        >
          <StoreCurrency
            {...orderCurrencyTotal}
            color="primary"
            variant="body1"
            containerProps={{
              marginRight: '8px'
            }}
          />
        </Box>
      ))}
      {order?.total?.totalAmount > 0 && (
        <Typography color="primary" variant="body1">
          {getFormattedCurrency(
            getUserReadableCurrencyAmount(order.total.totalAmount)
          )}
        </Typography>
      )}
      <OrderStatusContainer>
        <OrderStatus status={get(order, 'status')} />
      </OrderStatusContainer>
    </Box>
  );
};

export default OrderCard;
