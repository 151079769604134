import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { capitalize, get, isEmpty, lowerCase } from 'lodash';

import { Box, Typography } from '@mui/material';

import { GameCardImage } from '../../Shared/Images';
import { BannerProfileContainer, headerImageStyle } from './styles';
import SelectProfileImage from '../ProfileImageSelection/ModalPages/SelectProfileImage';
import { ModalContext } from '../../Modal/Context/ModalContext';
import ProfileImageNew from '../ProfileImageNew';

/** TODO: Add ranking name and image. **/
const UserHeader = () => {
  const { game, user } = useSelector(({ app }) => app);
  const rankName = get(user, 'details.gameUser.rankName', '');
  const name = `${get(user, 'details.info.firstName', '')} ${get(
    user,
    'details.info.lastName',
    ''
  )}`;
  const { addModal } = useContext(ModalContext);
  const dummyHeader = `https://storage.googleapis.com/cardeio-images/defaults/${game?.slug}/events/1.webp`;
  const userImage = get(user, 'details.gameUser.imageUrl');

  const handleSelect = () => {
    addModal({
      children: <SelectProfileImage image={userImage} setImage={() => {}} />,
      isOpen: true,
      scrollVisible: true
    });
  };

  return (
    <Box>
      <BannerProfileContainer>
        <GameCardImage imageurl={dummyHeader} sx={headerImageStyle} />
        <ProfileImageNew onClick={handleSelect} />
      </BannerProfileContainer>
      <Box sx={{ paddingTop: 2 }}>
        {!isEmpty(rankName) && (
          <Typography textAlign={'center'}>
            {capitalize(lowerCase(rankName))}
          </Typography>
        )}
        <Typography textAlign={'center'} variant={'h2'}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserHeader;
