import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { getCurrencyObj } from './utils';

// eslint-disable-next-line react/display-name
const Currency = forwardRef(({ hideAdornment, ...props }, ref) => {
  const [position, setPosition] = useState(0);
  const allCurrencies = Object.values(getCurrencyObj());
  const inputRef = useRef();
  const adornment = hideAdornment
    ? {}
    : {
        startAdornment: (
          <InputAdornment position={'start'}>
            <Select
              sx={{
                position: 'relative',
                top: '3px',
                border: 'none',
                backgroundColor: 'transparent',
                width: 'max-content',
                padding: '0 4px',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 }
              }}
              disableUnderline
              variant="standard"
              MenuProps={{
                style: {
                  maxHeight: 200
                }
              }}
              value={props.currency.currencyCode}
              onChange={event =>
                props.setCurrency(getCurrencyObj()[event.target.value])
              }
            >
              {allCurrencies.map((currency, index) => (
                <MenuItem key={index} value={currency.currencyCode}>
                  {currency.symbol}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        )
      };

  useEffect(() => {
    inputRef.current.setSelectionRange(position, position);
  }, [position, props.value]);

  return (
    <Grid item>
      <TextField
        ref={ref}
        {...props}
        inputRef={inputRef}
        fullWidth
        type={'text'}
        inputProps={{
          min: 0,
          max: 100000
        }}
        InputProps={adornment}
        onChange={event => {
          setPosition(event.target.selectionStart);
          let value = event.target.value;
          if (isNaN(Number(value)) || event.target.value.match(/-+|\++/g)) {
            return;
          }

          if (value.length >= 24) {
            value = value.slice(0, 24);
          } else {
            const price = value.split('.');
            if (price.length == 2) {
              const change = price[1].slice(0, 2);
              value = `${
                price[0].charAt(0) === '0' ? '0' : price[0]
              }.${change}`;
            } else if (value.charAt(0) === '0') {
              value = '0';
            }
          }
          props.onChange && props.onChange(value);
        }}
      />
    </Grid>
  );
});

export default Currency;
