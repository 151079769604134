import { Box, Grid, IconButton, Tab, alpha, styled } from '@mui/material';
import React, { useContext, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import Loading from '../../../../components/Loading/Loader';
import UserRankEventHistory from './UserRankEventHistory';
import UserRankMatchupHistory from './UserRankMatchupHistory';
import UserRankScoreHistory from './UserRankScoreHistory';
import UserRankingProfileInfo from '../../../../components/Rankings/UserRankingProfileInfo';
import UserRankingScoreStatCard from '../../../../components/Rankings/UserRankingScoreStatCard';
import { get } from 'lodash';
import { useGetUserRankInfoQuery } from '../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { ModalContext } from '../../../../components/Modal/Context/ModalContext';

const USER_RANKING_DETAILS_TABS = {
  eventHistory: {
    title: 'Event History',
    id: 'event-history'
  },
  matchupHistory: {
    title: 'Matchup History',
    id: 'matchup-history'
  }
};

const TabsContainer = styled(Box)(({ theme }) => ({
  padding: '15px 30px',
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  border: `1px solid ${alpha(theme.palette.neutral[100], 0.2)}`,
  borderRadius: '40px'
}));

const StyledTabPanel = styled(TabPanel)`
  padding: 32px 0;
`;

const UserRankDetailsPage = ({
  formatId,
  rankIcon,
  rankName,
  rankNumber,
  seasonId,
  userId,
  teamName
}) => {
  const { game } = useSelector(({ app }) => app);
  const { closeModal } = useContext(ModalContext);
  // eslint-disable-next-line no-unused-vars
  const { data: userData, isLoading } = useGetUserRankInfoQuery({
    userId,
    query: `?gameId=${game.id}${formatId ? `&formatId=${formatId}` : ''}${
      seasonId ? `&seasonId=${seasonId}` : ''
    }`
  });

  const [currentTab, setCurrentTab] = useState(
    USER_RANKING_DETAILS_TABS.eventHistory.id
  );

  const tabOnChange = (e, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid
      container
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      position="relative"
    >
      {isLoading ? (
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          height={'100%'}
        >
          <Loading />
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <IconButton
            size={'large'}
            onClick={() => closeModal()}
            sx={{
              position: 'fixed',
              right: '1%',
              top: '1%',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme => theme.palette.primary.main
              }
            }}
          >
            <CloseIcon fontSize={'large'} />
          </IconButton>
          {/* profile pic, name, and other info view */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <UserRankingProfileInfo
                  firstName={get(userData, 'user.firstName')}
                  lastName={get(userData, 'user.lastName')}
                  profileImage={get(userData, 'user.imageUrl', '')}
                  rankName={rankName}
                  teamName={teamName}
                  rankIcon={rankIcon}
                  rankNumber={rankNumber}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <UserRankingScoreStatCard
              eloRank={userData.eloRank}
              gameplayHistory={get(userData, 'gameplayHistory')}
            />
          </Grid>

          <Grid item xs={12}>
            <UserRankScoreHistory
              userId={userId}
              formatId={formatId}
              seasonId={seasonId}
            />
          </Grid>

          {/* Games and opponent history view */}
          <Grid item xs={12}>
            <TabsContainer>
              <TabContext value={currentTab}>
                <TabList onChange={tabOnChange} variant={'scrollable'}>
                  <Tab
                    id="ranking-user-details-event-history-tab-button"
                    data-testid="ranking-user-details-event-history-tab-button"
                    value={USER_RANKING_DETAILS_TABS.eventHistory.id}
                    label={USER_RANKING_DETAILS_TABS.eventHistory.title}
                  />
                  <Tab
                    id="ranking-user-details-matchup-history-tab-button"
                    data-testid="ranking-user-details-matchup-history-tab-button"
                    value={USER_RANKING_DETAILS_TABS.matchupHistory.id}
                    label={USER_RANKING_DETAILS_TABS.matchupHistory.title}
                  />
                </TabList>

                <StyledTabPanel
                  value={USER_RANKING_DETAILS_TABS.eventHistory.id}
                >
                  <UserRankEventHistory
                    userId={userId}
                    currentTab={currentTab}
                    formatId={formatId}
                    seasonId={seasonId}
                  />
                </StyledTabPanel>
                <StyledTabPanel
                  value={USER_RANKING_DETAILS_TABS.matchupHistory.id}
                >
                  <UserRankMatchupHistory rankingData={userData} />
                </StyledTabPanel>
              </TabContext>
            </TabsContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserRankDetailsPage;
