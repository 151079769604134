import * as kryptikWinnerAnimation from '../../../../../../assets/animations/winner/kryptik.json';
import * as metazooWinnerAnimation from '../../../../../../assets/animations/winner/metazoo.json';

import { Avatar, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { Counter } from '../../../../../../components/Shared/Buttons/Counter';
import Lottie from 'react-lottie';
import { ModalContext } from '../../../../../../components/Modal/Context/ModalContext';
import SimpleModalCard from '../../../../../../components/Modal/Cards/SimpleModalCard';
import { get } from 'lodash';
import { usePostUpdatePairingResultMutation } from '../../../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const styles = {
  avatar: {
    height: {
      xs: '64px',
      md: '96px'
    },
    width: {
      xs: '64px',
      md: '96px'
    }
  },
  section: {
    mb: { xs: 2, sm: 4 }
  },
  textField: {
    '.MuiTextField-root': {
      width: '100px'
    }
  }
};

const ReportScoreModal = ({
  activeScoreCard,
  eventId,
  isPlayoffs,
  bestOf = 3
}) => {
  // Context Hooks
  const { closeModal, setIsLoading } = useContext(ModalContext);
  const { game } = useSelector(({ app }) => app);

  const winnerAnimations = {
    metazoo: metazooWinnerAnimation
  };

  const animationSize = {
    metazoo: {
      desktop: {
        height: '60px',
        width: '60px'
      },
      mobile: {
        height: '50px',
        width: '50px'
      },
      bottom: {
        desktop: '24px',
        mobile: '16px'
      },
      left: '0'
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: winnerAnimations[game.slug] || kryptikWinnerAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [
    // eslint-disable-next-line no-unused-vars
    submitPairingResult,
    pairingResult
  ] = usePostUpdatePairingResultMutation();
  const mobileQuery = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [playerOneWins, setPlayerOneWins] = useState(0);
  const [playerTwoWins, setPlayerTwoWins] = useState(0);

  useEffect(() => {
    if (!get(activeScoreCard, 'active')) {
      setPlayerOneWins(get(activeScoreCard, 'results.playerOneWins', 0));
      setPlayerTwoWins(get(activeScoreCard, 'results.playerTwoWins', 0));
    }
  }, [activeScoreCard]);

  useEffect(() => {
    setIsLoading(pairingResult.isLoading);
    if (pairingResult.isSuccess) {
      closeModal();
    }
  }, [pairingResult.isLoading]);

  const submitResult = () => {
    submitPairingResult({
      eventId,
      body: {
        playerOneWins: Number(playerOneWins),
        playerTwoWins: Number(playerTwoWins),
        matchId: activeScoreCard._id
      }
    });
  };

  const bestOfMax = Math.ceil(bestOf / 2);

  const calcMaxRounds = opponentWins => {
    const playerMax = bestOf - opponentWins;
    if (playerMax >= bestOfMax) {
      return bestOfMax;
    }
    return bestOfMax - 1;
  };

  // eslint-disable-next-line no-unused-vars
  const players = [
    {
      scoreCardKey: 'playerOne',
      max: bestOfMax,
      win: playerOneWins,
      opponentWins: playerTwoWins,
      setCount: setPlayerOneWins
    },
    {
      scoreCardKey: 'playerTwo',
      max: bestOfMax,
      win: playerTwoWins,
      opponentWins: playerOneWins,
      setCount: setPlayerTwoWins
    }
  ];

  return (
    <SimpleModalCard
      title={'Report Result'}
      confirmButton={{
        id: 'report-score-modal-confirm-button',
        title: 'Submit',
        onClick: submitResult,
        disabled:
          (isPlayoffs && Number(playerOneWins) === Number(playerTwoWins)) ||
          playerOneWins === '' ||
          playerTwoWins === ''
      }}
    >
      <Grid container mt={2}>
        {/* Title */}
        <Grid container>
          {players.map((player, index) => (
            <Grid key={player.scoreCardKey} item xs={6}>
              <Grid
                container
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                rowSpacing={2}
              >
                {/* Player Avatar */}
                <Grid item>
                  <div
                    style={{
                      height: mobileQuery ? '64px' : '96px',
                      width: mobileQuery ? '64px' : '96px'
                    }}
                  >
                    <Avatar
                      src={get(
                        activeScoreCard,
                        `${player.scoreCardKey}.imageUrl`
                      )}
                      sx={styles.avatar}
                    />
                    {player.win > player.opponentWins && (
                      <Lottie
                        options={defaultOptions}
                        height={
                          mobileQuery
                            ? get(
                                animationSize[game.slug],
                                'mobile.height',
                                130
                              )
                            : get(
                                animationSize[game.slug],
                                'desktop.height',
                                200
                              )
                        }
                        width={
                          mobileQuery
                            ? get(animationSize[game.slug], 'mobile.width', 130)
                            : get(
                                animationSize[game.slug],
                                'desktop.width',
                                200
                              )
                        }
                        style={{
                          position: 'relative',
                          bottom: mobileQuery
                            ? get(
                                animationSize[game.slug],
                                'bottom.mobile',
                                '105px'
                              )
                            : get(
                                animationSize[game.slug],
                                'bottom.desktop',
                                '164px'
                              ),
                          left: get(animationSize[game.slug], 'left', '-50%')
                        }}
                      />
                    )}
                  </div>
                </Grid>

                {/* Player Name */}
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      alignItems="center"
                      container
                      spacing={1}
                      flexDirection={{ xs: 'column' }}
                      mt={{ xs: 4, md: 4 }}
                    >
                      <Grid>
                        <Typography variant={mobileQuery ? 'h4' : 'h3'}>
                          {get(
                            activeScoreCard,
                            `${player.scoreCardKey}.firstName`
                          )}
                        </Typography>
                      </Grid>
                      <Typography variant={mobileQuery ? 'h4' : 'h3'}>
                        {get(
                          activeScoreCard,
                          `${player.scoreCardKey}.lastName`
                        )}
                      </Typography>
                      <Grid></Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item>*/}
                {/*  <Grid*/}
                {/*    container*/}
                {/*    spacing={1}*/}
                {/*    flexDirection={{ xs: 'column', md: 'row' }}*/}
                {/*  >*/}
                {/*    <Grid container alignItems="center">*/}
                {/*      <Typography variant={mobileQuery ? 'h4' : 'h3'}>*/}
                {/*        {get(*/}
                {/*          activeScoreCard,*/}
                {/*          `${player.scoreCardKey}.firstName`*/}
                {/*        )}*/}
                {/*      </Typography>*/}
                {/*    </Grid>*/}
                {/*    <Grid item>*/}
                {/*      <Typography variant={mobileQuery ? 'h4' : 'h3'}>*/}
                {/*        {get(*/}
                {/*          activeScoreCard,*/}
                {/*          `${player.scoreCardKey}.lastName`*/}
                {/*        )}*/}
                {/*      </Typography>*/}
                {/*    </Grid>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

                {/* Player Counter*/}
                <Grid item>
                  <Counter
                    index={index}
                    disableIncrement={
                      calcMaxRounds(player.opponentWins) === player.win
                    }
                    orientation={mobileQuery ? 'vertical' : 'horizontal'}
                    title={'Wins'}
                    maxValue={player.max}
                    setCount={player.setCount}
                    count={player.win}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {isPlayoffs && (
          <Grid xs={12} item sx={{ paddingTop: 3 }}>
            <Typography
              noWrap
              align={'center'}
              sx={{ typography: { sm: 'body1', xs: 'body2' } }}
            >
              * Winner must be declared
            </Typography>
          </Grid>
        )}
      </Grid>
    </SimpleModalCard>
  );
};

export default ReportScoreModal;
