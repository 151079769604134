import { Box, IconButton, Popover } from '@mui/material';

import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { get } from 'lodash';

const CardPopover = ({ card, fontSize = 'medium', sx }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleOnClick = e => {
    e.stopPropagation();
    if (!anchorEl) {
      handlePopoverOpen(e);
    } else {
      handlePopoverClose();
    }
  };

  return (
    <>
      <IconButton
        id={`card-${card.slug}-popover-button`}
        data-testid={`card-${card.slug}-popover-button`}
        onClick={handleOnClick}
        onMouseLeave={handlePopoverClose}
      >
        <VisibilityIcon
          fontSize={fontSize}
          sx={theme => ({
            background: theme.palette.primary.main,
            borderRadius: '30px',
            paddingX: 0.1,
            color: 'white',
            ...sx
          })}
        />
      </IconButton>
      <Popover
        className="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
        disableRestoreFocus
      >
        <Box
          className="popover-card-image"
          sx={{
            backgroundColor: 'transparent',
            backgroundImage: `url(${get(
              card,
              'imageUrl',
              get(card, 'images.small', '')
            )})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: {
              xs: '12px',
              sm: '24px'
            },
            height: {
              xs: 293,
              sm: 531
            },
            width: {
              xs: 213,
              sm: 385
            }
          }}
        />
      </Popover>
    </>
  );
};

export default CardPopover;
