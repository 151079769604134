import React from 'react';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import RewardsHeader from '../../components/Rewards/RewardsHeader';

const RewardsMainPage = () => {
  return (
    <MaxWidthContainer container className={'p-percent p-top-5'}>
      <Grid container spacing={4}>
        <RewardsHeader />
        <Outlet />
      </Grid>
    </MaxWidthContainer>
  );
};

export default RewardsMainPage;
