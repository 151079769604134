import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer
} from 'recharts';

import React from 'react';
import { isEmpty, isUndefined } from 'lodash';

const PercentageRadialChart = ({
  amountValue,
  value = 100,
  labelText = '',
  fillColor
}) => {
  const data = [{ value }];
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const hasLabel = !isEmpty(labelText);
  return (
    <Box>
      <ResponsiveContainer width="100%" height={180}>
        <RadialBarChart
          innerRadius="80%"
          outerRadius="100%"
          barSize={30}
          data={data}
          startAngle={90}
          endAngle={450}
        >
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar
            background={{ fill: theme.palette.neutral[400] }}
            clockWise
            dataKey="value"
            cornerRadius="50%"
            fill={fillColor || theme.palette.primary.main}
          />
          <text
            x="50%"
            y={hasLabel ? '45%' : '50%'}
            data-testid="available-balance"
            textAnchor="middle"
            dominantBaseline="middle"
            className="progress-label"
            fill={fillColor || theme.palette.primary.main}
            fontSize={smallScreen ? '1em' : mediumScreen ? '1.2em' : '1.3em'}
          >
            {!isUndefined(amountValue) ? amountValue : `${value}%`}
          </text>
          {labelText && (
            <text
              x="50%"
              y="57%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={fillColor || theme.palette.primary.main}
              fontSize={smallScreen ? '0.8em' : mediumScreen ? '0.9em' : '1em'}
              fontWeight={500}
            >
              {labelText}
            </text>
          )}
        </RadialBarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PercentageRadialChart;
