import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import React from 'react';
import { get } from 'lodash';

const CustomDot = props => {
  const {
    data,
    dataKey,
    cx,
    cy,
    index,
    payload,
    hideDottedLines,
    mobileHideText
  } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const hasPrevValue = get(data, `[${index - 1}][${dataKey}]`, null) !== null;
  const hasNextValue = get(data, `[${index + 1}][${dataKey}]`, null) !== null;
  const shouldPlaceLower = (hasPrevValue || hasNextValue) && index % 2 === 0;
  const textY = shouldPlaceLower ? 40 : 50;
  const circleRadius = data.length > 30 ? (isSmall ? 2 : 4) : isSmall ? 6 : 8;
  const shouldHideText =
    mobileHideText ||
    (data.length > 20
      ? isSmall
        ? index % 2 === 0 || index % 5 === 0
        : index % 3 === 0 || index % 5 === 0
      : false);
  const getTextAnchor = () => {
    if (index === 0) {
      return 'start';
    } else if (index + 1 === data.length) {
      return 'end';
    }
    return 'middle';
  };

  if (!payload[dataKey]) {
    return null;
  }
  if (data[index].isPlaceholder) {
    return null;
  }

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={circleRadius}
        fill={theme.palette.background.default}
        stroke={theme.palette.primary.main}
        fillOpacity={1}
        strokeWidth={2}
      />
      {!shouldHideText && (
        <text
          x={cx}
          y={cy - textY}
          textAnchor={getTextAnchor()}
          fill={theme.palette.text.primary}
          fontSize={isSmall ? '10px' : '12px'}
          fontWeight={600}
        >
          {payload[dataKey]}
        </text>
      )}
      {!hideDottedLines && (
        <line
          x1={cx}
          y1={cy + 10}
          x2={cx}
          y2={210}
          stroke={theme.palette.text.primary}
          strokeDasharray="3 3"
        />
      )}
      {!hideDottedLines && (
        <line
          x1={cx}
          y1={cy - textY + 5}
          x2={cx}
          y2={cy - 10}
          stroke={theme.palette.text.primary}
          strokeDasharray="3 3"
        />
      )}
    </g>
  );
};

const CustomXTick = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    x,
    y,
    payload,
    index,
    visibleTicksCount,
    data,
    dataKey,
    mobileHideText,
    showXFirstAndLast = false
  } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const hasSomeValue = data[index] && data[index][dataKey] !== undefined;
  const isFirstOrLast = index === 0 || index === data.length - 1;
  // eslint-disable-next-line no-unused-vars
  const getTextAnchor = () => {
    if (index === 0) {
      return 'start';
    } else if (index + 1 === visibleTicksCount) {
      return 'end';
    }
    return 'middle';
  };
  const getTextProperties = () => {
    return {
      fontSize: hasSomeValue
        ? isSmall
          ? '0.8rem'
          : '1rem'
        : isSmall
        ? '0.6rem'
        : '0.8rem',
      fontWeight: hasSomeValue ? '600' : '500'
    };
  };

  if (!hasSomeValue) {
    return null;
  }
  if (!isFirstOrLast && showXFirstAndLast && index % 2 !== 0) {
    return null;
  }
  if (
    !hasSomeValue &&
    (get(data, `[${index - 1}][${dataKey}]`, null) ||
      get(data, `[${index + 1}][${dataKey}]`, null))
  ) {
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  const properties = getTextProperties();
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={getTextAnchor()}
        fill={theme.palette.text.primary}
        fontSize={properties.fontSize}
        fontWeight={properties.fontWeight}
      >
        {mobileHideText && !isSmall ? payload.value : ''}
      </text>
    </g>
  );
};

const CustomLineChart = ({
  data,
  dataKey = 'score',
  domain = [1000, 2500],
  height = 250,
  removeOverflow = false,
  xInterval = 0,
  mobileHideText = false,
  hideDottedLines = false,
  showXFirstAndLast = false,
  tooltipContent = null
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        ['.recharts-surface']: {
          overflow: removeOverflow ? 'unset' : 'hidden'
        }
      }}
    >
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.7}
              />
              <stop
                offset="90%"
                stopColor={theme.palette.neutral[500]}
                stopOpacity={0.5}
              />
            </linearGradient>
          </defs>
          <XAxis
            interval={xInterval}
            dataKey="xLabel"
            tick={
              <CustomXTick
                data={data}
                dataKey={dataKey}
                mobileHideText={mobileHideText}
                showXFirstAndLast={showXFirstAndLast}
              />
            }
          />
          <YAxis hide domain={domain} />
          <Area
            connectNulls
            type="monotone"
            dataKey={dataKey}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            fill="url(#colorScore)"
            dot={
              <CustomDot
                data={data}
                dataKey={dataKey}
                hideDottedLines={hideDottedLines}
                mobileHideText={mobileHideText}
              />
            }
          />
          {tooltipContent ? <Tooltip content={tooltipContent} /> : <Tooltip />}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default CustomLineChart;
