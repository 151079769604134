import { Grid, MenuItem, Select, useMediaQuery } from '@mui/material';
import { StyledTab, StyledTabs } from '../../../Shared/Tabs';

import { GeneralCard } from '../../../Shared/Cards/styles';
import React from 'react';
import { deckDetailViewContainerStyle } from './styles';
import { isNumber } from 'lodash/lang';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line no-unused-vars
const deckDetailViewType = {
  detail: 'detail',
  deckBuilder: 'deckBuilder'
};

const DeckDetailView = ({
  sections = [],
  sectionSelected = 0,
  onSelectionChange,
  createCustomHeader,
  ...props
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const selectionAction = e => {
    if (isNumber(e)) {
      onSelectionChange && onSelectionChange(e);
    } else {
      onSelectionChange && onSelectionChange(e.target.value);
    }
  };

  const buildHeader = () => {
    const selections = sections.map((section, index) =>
      isSmall ? (
        <MenuItem
          id={`create-deck-select-${section.title.toLowerCase()}-option`}
          data-testid={`create-deck-select-${section.title.toLowerCase()}-option`}
          key={section.title}
          value={index}
        >
          {section.title}
        </MenuItem>
      ) : (
        <StyledTab
          id={`create-deck-tab-${section.title.toLowerCase()}-button`}
          data-testid={`create-deck-tab-${section.title.toLowerCase()}-button`}
          key={section.key}
          label={section.title}
          value={index}
          onClick={() => selectionAction(index)}
        />
      )
    );

    const selectionHeader = isSmall ? (
      <Select
        id="create-deck-section-menu-select"
        data-testid="create-deck-section-menu-select"
        fullWidth
        value={sectionSelected}
        onChange={selectionAction}
      >
        {selections}
      </Select>
    ) : (
      <StyledTabs
        id="create-deck-section-tab-select"
        data-testid="create-deck-section-tab-select"
        value={sectionSelected}
      >
        {selections}
      </StyledTabs>
    );

    return createCustomHeader
      ? createCustomHeader(selectionHeader, isSmall)
      : selectionHeader;
  };

  return (
    <GeneralCard
      id="decks-create-deck-detail-view-section"
      data-testid="decks-create-deck-detail-view-section"
      sx={deckDetailViewContainerStyle}
      className={'border-16'}
      container
    >
      <Grid container spacing={{ xs: 1, sm: 0 }}>
        <Grid
          item
          xs={12}
          sx={theme => ({
            ...(isSmall && {
              position: 'sticky',
              top: '79px',
              zIndex: 10,
              background: theme.palette.background.default,
              paddingBottom: 1,
              paddingTop: 1
            })
          })}
        >
          {buildHeader()}
        </Grid>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </GeneralCard>
  );
};

export default DeckDetailView;
