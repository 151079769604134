import { Box, Grid, Typography } from '@mui/material';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { RewardsBox, rewardsGridStyle } from './styles';
import { debounce, get, isEmpty } from 'lodash';
import { useGetProductsQuery } from '../../../../services/apis/organizePlayApi';

import FilterOptions from '../FilterOptions';
import InfiniteScroll from '../../../../components/InfiniteScroll/index';
import Loader from '../../../../components/Loading/Loader';
import RewardCard from '../RewardCard';
import RewardSearch from '../RewardSearch';
import { usePagination } from '../../../../hooks/pagination/usePagination';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../../hooks/modal/useModalContext';

export const FilterContext = createContext(null);

const Rewards = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    name: '',
    rewardType: '',
    exclusivityType: '',
    min: 0,
    max: 1000,
    maxValue: 1000,
    tags: []
  });
  const updateFilter = updatedObj => {
    setFilter({ ...filter, ...updatedObj });
  };
  const [filterQuery, setFilterQuery] = useState({ ...filter });

  const { data, isLoading, isFetching } = useGetProductsQuery({
    page: currentPage,
    limit: 24,
    ...filterQuery
  });

  const {
    loadAction,
    treatedData,
    nextPage,
    totalResults,
    resetPagination
  } = usePagination({
    data,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });

  useEffect(() => {
    const updateFilterQuery = debounce(() => {
      resetPagination();
      setFilterQuery(filter);
    }, 250);
    updateFilterQuery();
    return () => updateFilterQuery.cancel();
  }, [filter]);

  const { game } = useSelector(({ app }) => app);
  const currencies = get(game, 'pgGame.currencies', []);

  const { addModal } = useModalContext();

  const showSearchEmptyMessage = useCallback(() => {
    return (
      isEmpty(treatedData) &&
      (!isEmpty(filter.name) || !isEmpty(filter.tags)) &&
      (!isLoading || !isFetching)
    );
  }, [filter, treatedData, isLoading, isFetching]);

  const [showFilter, setShowFilter] = useState(false);

  const rewardCards = useMemo(() => {
    return (
      <Box sx={rewardsGridStyle}>
        {!isLoading &&
          treatedData &&
          treatedData.length > 0 &&
          treatedData.map(item => (
            <RewardCard
              currencies={currencies}
              product={item}
              key={item.id}
              addModal={addModal}
            />
          ))}
      </Box>
    );
  }, [isLoading, treatedData, currencies]);

  return (
    <FilterContext.Provider value={{ filter, updateFilter }}>
      <Grid item xs={12}>
        <RewardsBox>
          <Grid container spacing={2} mb={2}>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems="flex-start"
              justifyContent="space-between"
              gap={2}
            ></Grid>
            <RewardSearch
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
            <FilterOptions show={showFilter} />
          </Grid>
          <InfiniteScroll
            isLoading={isLoading}
            done={nextPage === 0 || totalResults === treatedData?.length}
            loaderAction={loadAction}
            loader={<Loader />}
          >
            {rewardCards}
          </InfiniteScroll>
          {showSearchEmptyMessage() && (
            <Grid item xs={12}>
              <Typography textAlign="center">
                Oops! No rewards were found for your current search filters.
                Please try adjusting your filters for more results!
              </Typography>
            </Grid>
          )}
        </RewardsBox>
      </Grid>
    </FilterContext.Provider>
  );
};

export default Rewards;
