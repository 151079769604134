import '@fontsource/roboto';
import 'react-toastify/dist/ReactToastify.css';

import {
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useLocation
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { initApp, startAuthentication } from '../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AuthManager from '../components/Auth/AuthManager';
import { CssBaseline } from '@mui/material';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
import { LocalizationProvider } from '@mui/x-date-pickers';
import MapLoader from '../components/Shared/MapLoader';
import ModalProvider from '../components/Modal/Context/ModalContext';
import Notification from '../components/Notification';
import PageLoading from '../components/Loading/Page';
import ReactGA from 'react-ga4';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

let gaInitialized = false;
if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
  gaInitialized = true;
}

const App = () => {
  const { gameSlug } = useLoaderData();
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const { isAuthorizing, isInitializingApp, game, user } = useSelector(
    ({ app }) => app
  );
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);

  let theme;

  if (get(game, 'layout.generic.headerBgImage')) {
    theme = createTheme({
      ...game.theme,
      ...{
        overrides: {
          ...game.theme.overrides,
          MuiCssBaseline: {
            '@global': {
              body: {
                backgroundImage: `url(${get(
                  game,
                  'layout.generic.headerBgImage'
                )})`,
                backgroundSize: 'contain'
              }
            }
          }
        }
      }
    });
  } else {
    theme = createTheme(game.theme);
  }

  useEffect(() => {
    dispatch(initApp(gameSlug));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(startAuthentication());
    }
  }, [isLoading]);

  useEffect(() => {
    const documentClickListener = event => {
      const eventSource = event.target || event.srcElement;
      if (gaInitialized && eventSource && eventSource.nodeName === 'BUTTON') {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: eventSource.textContent || eventSource.outerText
        });
      }
    };
    document.addEventListener('click', documentClickListener);

    return () => {
      document.removeEventListener('click', documentClickListener);
    };
  }, []);

  useEffect(() => {
    if (!gaInitialized) {
      return;
    }
    ReactGA.set({ game: gameSlug });
  }, [gameSlug]);

  useEffect(() => {
    if (!gaInitialized) {
      return;
    }
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname
    });
  }, [location]);

  useEffect(() => {
    if (!gaInitialized) {
      return;
    }
    if (user && user.details) {
      ReactGA.set({
        userId: user.details.info.username
      });
    } else {
      ReactGA.set({
        userId: null
      });
    }
  }, [user]);

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.href = get(game, 'images.favicon', '');
  }, [game]);

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsNotificationOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{get(game, 'titles.op')}</title>
          </Helmet>
          <CssBaseline />
          <AuthManager />
          <MapLoader />
          {isLoading || isInitializingApp || isAuthorizing ? (
            <PageLoading slug={gameSlug} />
          ) : (
            <>
              <Header />
              {get(game, 'notification.banner.showWhileLoggedOutOnly') &&
                !isAuthenticated &&
                get(game, 'notification.banner.message') && (
                  <Notification
                    handleClose={handleNotificationClose}
                    isOpen={isNotificationOpen}
                    message={get(game, 'notification.banner.message')}
                  />
                )}
              <ScrollRestoration />
              <Outlet />
              <Footer />
            </>
          )}
        </ModalProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID,
  context: {
    kind: 'game',
    key:
      process.env.REACT_APP_ACTIVE_GAME ||
      window.location.pathname.split('/')[1]
  }
})(App);
