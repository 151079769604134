import { capitalize, get } from 'lodash';

export function useEventFormat(eventDetails = {}) {
  const formatName = get(eventDetails, 'format.name', 'Format not found');
  const subFormatName = get(eventDetails, 'subFormat.name', null);
  if (!subFormatName) {
    return formatName;
  }
  return `${capitalize(subFormatName)} ${formatName}`;
}
