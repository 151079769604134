import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ResponsiveContainer } from 'recharts';
import { MetricsContext } from '..';
import { darkenColor } from '../utils';

const ChartWrapper = props => {
  const { backgroundPaper } = useContext(MetricsContext);

  return (
    <Box
      bgcolor={`${backgroundPaper}CC`}
      padding={{ xs: 2, sm: 4 }}
      display="flex"
      flexDirection="column"
      gap={{ xs: 3, sm: 6 }}
      borderRadius={3}
      sx={{
        border: `1px solid ${darkenColor(backgroundPaper, -10)}`,
        overflow: 'hidden',
        '& .custom-tooltip': {
          backgroundColor: darkenColor(backgroundPaper, 5),
          border: 'none',
          padding: '10px',
          borderRadius: '3px'
        }
      }}
    >
      <Typography variant="h4">{props.title}</Typography>
      <ResponsiveContainer width="100%" aspect={4 / 3}>
        {props.children}
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartWrapper;
