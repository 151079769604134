import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import {
  boardTypeToSection,
  getFirstFormatOption
} from '../../../../../utils/deck';
import { capitalize, entries, first, get, isEmpty, kebabCase } from 'lodash';

import ConditionalWrapper from '../../../../../components/Shared/ConditionalWrapper';
import DropDownMenuOptions from '../../../../../components/DropDownMenuOptions';
import ImageSelector from '../../../../../components/ImageSelector';
import Loader from '../../../../../components/Loading/Loader';
import { LoadingButton } from '@mui/lab';
import { buildBannerUrl } from '../../../../../utils/StringUtil';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DeckSettingsPage = ({ deck }) => {
  const {
    isLoading,
    gameFormatsById,
    deckFormats,
    deleteModalAction,
    checkDeckValidationAction
  } = useOutletContext();
  const { control, getValues, setValue } = useFormContext();
  const { game } = useSelector(({ app }) => app);
  const totalEventBanners = get(game, 'stats.totalEventBanners');
  const images = [...Array(totalEventBanners).keys()].map(obj =>
    buildBannerUrl(game.slug, obj + 1)
  );

  const deckVisibility = ['private', 'public'];
  const [tags, setTags] = useState([]);
  const deckTags = game?.deckTags;

  useEffect(() => {
    if (deckTags) {
      setTags(
        deckTags.map(deckTag => ({
          value: deckTag._id,
          name: deckTag.name
        }))
      );
    }
  }, [deckTags]);

  const changeFormat = formatId => {
    const oldSections = getValues('sections');
    const helper = getValues('sectionHelper.boardTypeMap');
    const newHelper = boardTypeToSection(
      gameFormatsById[formatId]?.deckType?.deckSections
    );

    const newSections = gameFormatsById[formatId]?.deckType?.deckSections.map(
      (section, index) => {
        const boardTypeToSection = newHelper.boardTypeToSection[section?.type];
        if (boardTypeToSection) {
          boardTypeToSection.index = index;
        }
        const oldSectionMap = helper?.boardTypeToSection[section?.type];
        if (
          !isEmpty(oldSectionMap) &&
          oldSectionMap?.index >= 0 &&
          oldSectionMap?.index < oldSections?.length
        ) {
          return {
            ...oldSections[oldSectionMap?.index],
            deckSectionId: section._id
          };
        }
        return {
          deckSectionId: section._id,
          cards: [],
          cardTracker: new Set()
        };
      }
    );
    setValue('sections', newSections);
    setValue('sectionHelper.boardTypeMap', newHelper);
    setValue('formatOptions', {});
    checkDeckValidationAction();
  };

  if (isEmpty(getValues())) {
    return <Loader />;
  }
  const deckName = get(game, 'terminology.deck', 'Deck').toLowerCase();

  return (
    <Grid container spacing={4} sx={{ padding: { xs: 0, sm: 8 } }}>
      {/* Deck Name */}
      <Grid item xs={12}>
        <Controller
          name={'name'}
          control={control}
          render={({ field }) => (
            <TextField
              id={`create-deck-settings-${deckName}-name-input`}
              data-testid={`create-deck-settings-${deckName}-name-input`}
              label={`${get(game, 'terminology.deck', 'Deck')} Name`}
              value={deck?.name}
              {...field}
            />
          )}
        />
      </Grid>

      {/* Deck Tags */}
      <Grid item xs={12}>
        <Controller
          name={'tags'}
          control={control}
          render={({ field }) => (
            <Autocomplete
              id="create-deck-settings-tags-select-input"
              data-testid="create-deck-settings-tags-select-input"
              disableCloseOnSelect
              options={tags}
              value={field.value}
              onChange={(e, data) => {
                field.onChange(data);
              }}
              getOptionLabel={option => option.name}
              multiple
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              renderInput={param => <TextField {...param} label={'Tags'} />}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <Checkbox
                    id={`create-deck-tags-${kebabCase(option.name)}-checkbox`}
                    data-testid={`create-deck-tags-${kebabCase(
                      option.name
                    )}-checkbox`}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
          )}
        />
      </Grid>

      {/* Deck Notes */}
      <Grid item xs={12}>
        <Controller
          name={'notes'}
          control={control}
          render={({ field }) => (
            <TextField
              id="create-deck-settings-notes-input"
              data-testid="create-deck-settings-notes-input"
              {...field}
              label={'Notes'}
              multiline
              rows={4}
            />
          )}
        />
      </Grid>

      {/* Deck Format*/}
      <Grid item xs={12}>
        <Box sx={{ paddingBottom: 1 }}>
          <FormLabel>{get(game, 'terminology.deck', 'Deck')} Format</FormLabel>
        </Box>
        <Grid
          id="create-deck-settings-format-button-select"
          data-testid="create-deck-settings-format-button-select"
          container
          spacing={2}
        >
          {deckFormats
            .filter(format => !isEmpty(get(format, 'deckType', {})))
            .map(format => (
              <Grid item key={format.name}>
                <Controller
                  name={'formatId'}
                  control={control}
                  render={({ field }) => {
                    const formatOptions = format?.formatOptions;
                    const auras =
                      entries(first(entries(formatOptions))?.[1]) || [];
                    const currentFormatOption = useWatch({
                      control,
                      name: 'formatOptions'
                    });
                    const [title, id] = getFirstFormatOption(
                      currentFormatOption
                    );
                    return (
                      <ConditionalWrapper
                        condition={format?.formatOptions}
                        wrapper={children => {
                          return (
                            <DropDownMenuOptions
                              options={auras.map(aura => ({
                                title: aura[0],
                                key: aura[1],
                                action: () => {
                                  if (aura[1] !== id) {
                                    setValue('formatOptions.aura', aura);
                                    checkDeckValidationAction();
                                  }
                                },
                                selected: id === aura[1]
                              }))}
                            >
                              {children}
                            </DropDownMenuOptions>
                          );
                        }}
                      >
                        <Button
                          id={`create-deck-settings-format-${kebabCase(
                            format.name
                          )}-button`}
                          data-testid={`create-deck-settings-format-${kebabCase(
                            format.name
                          )}-button`}
                          className={`toggle ${
                            field.value === format._id ? 'active' : ''
                          }`}
                          variant={'outlined'}
                          onClick={() => {
                            if (field.value !== format._id) {
                              field.onChange(format._id);
                              changeFormat(format._id);
                            }
                          }}
                        >
                          {formatOptions && title + ' '}
                          {format.name}
                        </Button>
                      </ConditionalWrapper>
                    );
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>

      {/* Deck Image */}
      <Grid item xs={12}>
        <Box sx={{ paddingBottom: 1 }}>
          <FormLabel
            id="deck-image-options-label"
            data-testid="deck-image-options-label"
          >
            {get(game, 'terminology.deck', 'Deck')} Image
          </FormLabel>
        </Box>
        <Controller
          name={'deckImageUrl'}
          control={control}
          render={({ field }) => <ImageSelector images={images} {...field} />}
        />
      </Grid>

      {/* Deck Visibility */}
      <Grid item xs={12}>
        <FormControl>
          <FormLabel
            id="deck-visibility-options-label"
            data-testid="deck-visibility-options-label"
          >
            {get(game, 'terminology.deck', 'Deck')} Visibility
          </FormLabel>

          <Controller
            name={'sharing.status'}
            control={control}
            render={({ field }) => (
              <RadioGroup
                id="create-deck-settings-visibility-radio-select"
                data-testid="create-deck-settings-visibility-radio-select"
                aria-labelledby={'deck-visibility-options'}
                {...field}
                row
              >
                {deckVisibility.map(option => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={
                      <Radio
                        id={`create-deck-settings-visibility-${option}-radio`}
                        data-testid={`create-deck-settings-visibility-${option}-radio`}
                        checked={field.value === option}
                      />
                    }
                    label={capitalize(option)}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      {/* Deck Save and Delete Buttons */}
      <Grid
        id="create-deck-save-delete-section"
        data-testid="create-deck-save-delete-section"
        item
        xs={12}
      >
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <LoadingButton
              id="create-deck-settings-delete-button"
              data-testid="create-deck-settings-delete-button"
              loading={isLoading}
              variant={'outlined'}
              color={'error'}
              onClick={() => {
                deleteModalAction(getValues('_id'), true);
              }}
            >
              Delete
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              id="create-deck-settings-save-button"
              data-testid="create-deck-settings-save-button"
              loading={isLoading}
              type={'submit'}
              variant={'contained'}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeckSettingsPage;
