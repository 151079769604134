import { Select, styled } from '@mui/material';

export const ViewSelector = styled(Select)(({ theme }) => ({
  minWidth: '252px',
  maxHeight: '60px',
  '& .MuiSelect-select': {
    fontSize: '18px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.text.primary}33 !important`
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.primary.main} !important`
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '152px'
  }
}));
