import { pickBy } from 'lodash';

export const hasSomeValue = value => {
  return value !== null && value !== undefined && value !== '';
};

export const allHaveSomeValue = (...values) => {
  return values.reduce((pv, cv) => pv && hasSomeValue(cv), true);
};

export const removeEmptyOrUndefined = value => {
  return pickBy(value, v => v !== undefined && v !== null);
};

export const getOptionArrayFromObject = obj => {
  const result = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const subResult = getOptionArrayFromObject(value);
      result.push(...subResult);
    } else {
      result.push({ _id: value, name: key });
    }
  });
  return result;
};
