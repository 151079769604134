import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';

const CustomDateTimePicker = ({ disabled, field, label = '', error }) => {
  return (
    <DateTimePicker
      {...field}
      disabled={disabled}
      minDateTime={dayjs()}
      minutesStep={5}
      componentsProps={{
        actionBar: {
          actions: ['accept', 'clear']
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, readOnly: true }}
          error={error}
          label={label}
        />
      )}
    />
  );
};

export default CustomDateTimePicker;
