import { Box, Grid, Typography, alpha, styled, useTheme } from '@mui/material';

import PercentageRadialChart from '../../Shared/Charts/PercentageRadialChart';
import React from 'react';
import { get } from 'lodash';

const RankingScoreContainer = styled(Box)(({ theme }) => ({
  padding: '30px',
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  border: `1px solid ${alpha(theme.palette.neutral[100], 0.2)}`,
  borderRadius: '40px'
}));

const EloScoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main
}));

const UserRankingScoreStatCard = ({ eloRank, gameplayHistory }) => {
  const theme = useTheme();
  const getPercentage = (value, total) => {
    if (!value || !total) {
      return 0;
    }
    return Math.ceil((value / total) * 100);
  };

  const gameWinPercentage = getPercentage(
    get(gameplayHistory, 'games.won', 0),
    get(gameplayHistory, 'games.played')
  );
  const matchesWinPercentage = getPercentage(
    get(gameplayHistory, 'matches.won', 0),
    get(gameplayHistory, 'matches.played')
  );
  return (
    <RankingScoreContainer padding={{ xs: '30px 8px', sm: '30px' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sm={4}
          justifyContent="center"
          alignItems={{
            xs: 'center',
            sm: 'flex-start'
          }}
          display="flex"
          flexDirection="column"
          borderRight={{
            xs: 'none',
            sm: `1px solid ${theme.palette.neutral[400]}`
          }}
          paddingLeft={{
            xs: 0,
            sm: '30px'
          }}
          mb={{
            xs: 2,
            sm: 0
          }}
        >
          <Typography variant="body2Light">ELO Score:</Typography>
          <EloScoreText variant="h3" color="success">
            {eloRank}
          </EloScoreText>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          borderRight={`1px solid ${theme.palette.neutral[400]}`}
        >
          <PercentageRadialChart
            value={gameWinPercentage}
            fillColor={theme.palette.info.main}
            labelText="Game Win"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <PercentageRadialChart
            value={matchesWinPercentage}
            fillColor={theme.palette.primary.main}
            labelText="Match Win"
          />
        </Grid>
      </Grid>
    </RankingScoreContainer>
  );
};

export default UserRankingScoreStatCard;
