import { Grid, styled, Tab, Typography } from '@mui/material';

export const GrayBannerBackground = styled(Grid)`
  overflow: hidden;
  background: ${({ theme }) => theme.events.cardPrimaryColor};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  ${props => props.theme.breakpoints.down('sm')} {
  }
`;

export const GrayPillBackground = styled(Grid)`
  gap: 8px;
  padding: 8px 12px;
  background: ${({ theme }) => theme.events.cardSecondaryColor};
  border-radius: 10px;
  align-items: center;
`;

export const EventTitle = styled(Typography)``;

export const EventTab = styled(Tab)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${({ theme }) => theme.palette.text.primary};
  min-width: fit-content;
  text-transform: uppercase;
  padding: 8px 32px 24px;
`;

export const EventImage = styled('div')`
  width: 100%;
  height: 280px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({ theme }) => theme.events.singleBannerPosition};
  ${props => props.theme.breakpoints.down('md')} {
    height: 300px;
    object-fit: cover;
  }
`;

export const EventInformationContainer = styled(Grid)`
  padding: 0 5%;

  ${props => props.theme.breakpoints.down('sm')} {
    padding-bottom: 2.5%;
  }
`;
