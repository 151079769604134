import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { drawerContainerStyle, filterContainerStyle } from './styles';
import { get, isEmpty, unset } from 'lodash';

import Accordion from '../../Shared/Accordion';
import CloseIcon from '@mui/icons-material/Close';
import ConditionalWrapper from '../../Shared/ConditionalWrapper';
import TuneIcon from '@mui/icons-material/Tune';
import { kebabCase } from 'lodash';
import { useGetCardsFiltersQuery } from '../../../services/apis/organizePlayApi';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const CardFilter = ({
  type = 'default',
  onChange,
  children,
  drawerStyleOverrides
}) => {
  const { filterProps } = useOutletContext() || {};
  const drawerWidth = '300px';
  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const isSmall = useMediaQuery(
    theme.breakpoints.down(filterProps?.breakpoint || 'sm')
  );
  const logoPaddingTop = get(game, 'layout.header.logoMarginTop');
  const [open, isOpen] = filterProps?.filterOpenProps || useState(!isSmall);
  const [currentFilters, setCurrentFilters] = useState(undefined);
  const mainContainerRef = useRef();
  const boxRef = useRef();

  const offsetTop =
    filterProps?.offsetTop ||
    `${get(mainContainerRef, 'current.offsetTop', 0)}px`;

  const { data: filters, isLoading } = useGetCardsFiltersQuery(
    get(game, 'id', '')
  );

  const checkFieldType = fieldType => {
    switch (fieldType) {
      case 'text':
      case 'multiselect':
        return true;
      default:
        return false;
    }
  };

  const createTransition = props => {
    return {
      transition: theme.transitions.create(props, {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      ...(open && {
        transition: theme.transitions.create(props, {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      })
    };
  };

  // Styles
  const drawerStyle = {
    gridRow: 1,
    gridColumn: 1,
    position: isSmall ? 'fixed' : 'sticky',
    top: offsetTop,
    left: 0,
    zIndex: 1000,
    width: { xs: '85vw', sm: open ? drawerWidth : 0 },
    ...(!isSmall && {
      height: '100vh'
    }),
    ...drawerStyleOverrides,
    ...createTransition('width'),
    ['& .MuiDrawer-paper']: {
      position: 'absolute',
      width: { xs: '100%', sm: drawerWidth },
      ...(!isSmall && {
        paddingBottom: offsetTop
      })
    }
  };

  const childrenContainerStyle = {
    gridRow: 1,
    gridColumn: 1,
    ...createTransition('margin-left'),
    marginLeft:
      open && !isSmall
        ? drawerWidth
        : `${get(boxRef, 'current.clientWidth', 0)}px`,
    ...(isSmall && {
      marginLeft: 0,
      marginTop: `${get(boxRef, 'current.clientHeight', 0)}px`
    })
  };

  const filterTypes = {};
  const defaultFilters = filters
    ? filters.reduce((prev, current) => {
        const searchField = get(current, 'searchField', '');
        if (searchField) {
          filterTypes[searchField] = current.fieldType;
          let defaultValue = '';
          switch (current.fieldType) {
            case 'text':
              break;
            case 'multiselect':
              defaultValue = current.options.reduce((prev, current) => {
                return { ...prev, [current.searchTerm]: false };
              }, {});
          }
          return { ...prev, [searchField]: defaultValue };
        }

        return { ...prev };
      }, {})
    : {};

  // Setup currentFilters
  useEffect(() => {
    if (filters && !currentFilters) {
      setCurrentFilters({ ...defaultFilters });
    }
  }, [filters]);

  // currentFilter has change send onChange event
  useEffect(() => {
    if (currentFilters) {
      const modifiedFilter = { ...currentFilters };
      Object.keys(modifiedFilter).forEach(searchField => {
        let shouldUnset = false;
        switch (get(filterTypes, `${searchField}`, '')) {
          case 'text':
            if (modifiedFilter[searchField] === '') {
              shouldUnset = true;
            }
            break;
          case 'multiselect':
            const values = Object.entries(modifiedFilter[searchField])
              .filter(values => values[1])
              .map(values => values[0]);
            if (values.length > 0) {
              modifiedFilter[searchField] = values.join(',');
            } else {
              shouldUnset = true;
            }
            break;
          default:
            shouldUnset = true;
        }
        if (shouldUnset) {
          unset(modifiedFilter, searchField);
        }
      });
      onChange && onChange(modifiedFilter);
    }
  }, [currentFilters]);

  // Open or close filter depending if it's in mobile.
  useEffect(() => {
    if (isSmall) {
      isOpen(false);
    } else {
      isOpen(true);
    }
  }, [isSmall]);

  // Builds the component used by fieldType.
  const createFieldType = field => {
    const accordionCondition = field.options.length > 12;
    const slug = kebabCase(field.name);
    switch (field.fieldType) {
      case 'text':
        return (
          <TextField
            id={
              type == 'allCards'
                ? `cards-search-${field?.options?.displayText}-input`
                : `cards-filter-${slug}-input`
            }
            data-testid={
              type == 'allCards'
                ? `cards-search-${field?.options?.displayText}-input`
                : `cards-filter-${slug}-input`
            }
            fullWidth
            label={field.name}
            InputLabelProps={{ shrink: true }}
            value={currentFilters && currentFilters[field.searchField]}
            onChange={e =>
              setCurrentFilters(prev => {
                return { ...prev, [field.searchField]: e.target.value };
              })
            }
          />
        );
      case 'multiselect':
        return (
          <ConditionalWrapper
            condition={accordionCondition}
            wrapper={children => (
              <Accordion
                id={
                  type == 'deckBuilder'
                    ? `cards-select-${field?.searchField.toLowerCase()}-accordion`
                    : `cards-filter-${slug}-accordion-button`
                }
                data-testid={
                  type == 'deckBuilder'
                    ? `cards-select-${field?.searchField.toLowerCase()}-accordion`
                    : `cards-filter-${slug}-accordion-button`
                }
                sx={{
                  border: 'none',
                  boxShadow: 'none'
                }}
                summaryStyle={{
                  color: theme.palette.primary.main,
                  padding: 0
                }}
                detailsStyle={{ padding: 0 }}
                variant="h4"
                title={field.name}
              >
                {children}
              </Accordion>
            )}
          >
            <FormControl
              id={
                type == 'deckBuilder'
                  ? `cards-select-${field.searchField}-container`
                  : `cards-filter-${slug}-select-section`
              }
              data-testid={
                type == 'deckBuilder'
                  ? `cards-select-${field.searchField}-container`
                  : `cards-filter-${slug}-select-section`
              }
              fullWidth
              component={'fieldset'}
              sx={{ marginBottom: '16px' }}
              variant={'standard'}
            >
              <FormLabel component={'legend'}>
                {!accordionCondition && (
                  <Typography
                    className={'field-name'}
                    mb={2}
                    mt={2}
                    variant="h4"
                  >
                    {field.name}
                  </Typography>
                )}
              </FormLabel>
              <FormGroup sx={{ display: 'unset' }}>
                {field.options.map(option => (
                  <FormControlLabel
                    key={`${option.displayText}`}
                    sx={{
                      m: 0,
                      minWidth: get(option, 'displayIcon') ? '33%' : '50%',
                      width: get(option, 'displayIcon') ? '33%' : 'auto'
                    }}
                    control={
                      <Checkbox
                        id={
                          type == 'deckBuilder'
                            ? `cards-select-${field.searchField.toLowerCase()}-${option.displayText.toLowerCase()}-checkbox`
                            : `cards-filter-${slug}-select-${kebabCase(
                                option.displayText
                              )}-checkbox`
                        }
                        data-testid={
                          type == 'deckBuilder'
                            ? `cards-select-${field.searchField.toLowerCase()}-${option.displayText.toLowerCase()}-checkbox`
                            : `cards-filter-${slug}-select-${kebabCase(
                                option.displayText
                              )}-checkbox`
                        }
                        checked={
                          get(
                            currentFilters,
                            `${field.searchField}.${option.searchTerm}`,
                            false
                          ) || false
                        }
                        onChange={e => {
                          setCurrentFilters(prev => {
                            return {
                              ...prev,
                              [field.searchField]: {
                                ...prev[field.searchField],
                                [option.searchTerm]: e.target.checked
                              }
                            };
                          });
                        }}
                      />
                    }
                    label={
                      <Grid container>
                        {get(option, 'displayIcon', '') ? (
                          <img
                            height={'32px'}
                            width={'32px'}
                            src={get(option, 'displayIcon', '')}
                          />
                        ) : (
                          <Typography
                            className="option-text"
                            sx={{ whiteSpace: 'nowrap' }}
                            variant="body3"
                          >
                            {get(option, 'displayText', '')}
                          </Typography>
                        )}
                      </Grid>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </ConditionalWrapper>
        );
      default:
        return <Typography className={'field-name'}>{field.name}</Typography>;
    }
  };

  return (
    <Box
      id="cards-filter-container"
      data-testid="cards-filter-container"
      ref={mainContainerRef}
      sx={{
        display: 'grid',
        position: 'relative'
      }}
    >
      {/* Open filter icon */}
      {!filterProps?.hideButton && (
        <Box
          ref={boxRef}
          sx={theme =>
            filterContainerStyle({
              offsetTop,
              breakpointOn: isSmall,
              logoPaddingTop,
              theme
            })
          }
        >
          <IconButton
            id="cards-filter-open-button"
            data-testid="cards-filter-open-button"
            size={'large'}
            onClick={() => isOpen(true)}
            disabled={open}
          >
            <TuneIcon fontSize={'large'} />
          </IconButton>
        </Box>
      )}
      <Drawer
        id="cards-filter-drawer-section"
        data-testid="cards-filter-drawer-section"
        variant={isSmall ? 'temporary' : 'persistent'}
        onClose={() => {
          isOpen(false);
        }}
        open={open}
        sx={drawerStyle}
      >
        <Grid
          container
          sx={drawerContainerStyle({
            breakpointOn: isSmall,
            filterProps,
            logoPaddingTop
          })}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              height={'100%'}
              alignItems={'flex-start'}
            >
              {/* Title and close section */}
              <Grid item mb={4} xs={12}>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <Button
                      id="cards-filter-reset-button"
                      data-testid="cards-filter-reset-button"
                      onClick={() => setCurrentFilters(defaultFilters)}
                      sx={{
                        fontSize: '14px',
                        padding: '8px 16px'
                      }}
                    >
                      Reset Filters
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton
                      id="cards-filter-close-button"
                      data-testid="cards-filter-close-button"
                      onClick={() => isOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* Loader and Filter Sections */}
              {isLoading || !currentFilters || isEmpty(currentFilters) ? (
                // Loading Section
                <Grid
                  item
                  xs={12}
                  height={'100%'}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: -1
                  }}
                >
                  <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    {!isLoading && isEmpty(filters) ? (
                      <Typography variant={'h4'} textAlign={'center'}>
                        No Filters
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                </Grid>
              ) : (
                // Filter Content Section
                <>
                  {filters &&
                    filters
                      .filter(type =>
                        checkFieldType(get(type, 'fieldType', ''))
                      )
                      .map(type => (
                        <Grid
                          item
                          xs={12}
                          key={type.searchField}
                          height={'fit-content'}
                        >
                          {createFieldType(type)}
                        </Grid>
                      ))}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
      <Box sx={childrenContainerStyle}>{children}</Box>
    </Box>
  );
};

export default CardFilter;
