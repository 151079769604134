import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { SocialInfo } from '../../../Decks/Cards/DeckCard/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const SocialTube = ({ tubeStyle, socialStyle }) => {
  const defaultSocialStyle = {
    typography: { xs: 'caption', sm: 'body2' },
    ...socialStyle
  };

  const defaultTubeStyle = {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: 'transparent',
    border: '1px solid #FFF2',
    gap: 2,
    minHeight: '60px',
    ...tubeStyle
  };

  return (
    <TubeCard sx={defaultTubeStyle}>
      <SocialInfo sx={defaultSocialStyle}>
        <VisibilityIcon fontSize={'inherit'} /> 1
      </SocialInfo>
      <SocialInfo sx={defaultSocialStyle}>
        <ChatBubbleOutlineIcon fontSize={'inherit'} /> 156
      </SocialInfo>
      <SocialInfo sx={defaultSocialStyle}>
        <FavoriteBorderIcon fontSize={'inherit'} /> 156
      </SocialInfo>
    </TubeCard>
  );
};

export default SocialTube;
