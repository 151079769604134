import { flatten, get, isInteger, isArray, isUndefined } from 'lodash';

const timeframeMap = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Yearly',
  lifetime: 'Lifetime'
};

export const getProductLimit = (product, currencyId) => {
  const currentValues = {
    timeframe: undefined,
    purchases: 0,
    limit: undefined,
    currentLimit: undefined
  };
  // eslint-disable-next-line no-unused-vars
  let inventories = get(product, 'inventory', []);
  if (!isArray(inventories)) inventories = [inventories];
  const productPrices = flatten(
    inventories?.map(inventory => get(inventory, 'productPrices', []))
  ).filter(productPrice => productPrice?.currencyId === currencyId);

  setLimit(inventories, currentValues);
  setLimit(productPrices, currentValues);
  setLimit([product], currentValues);

  if (isInteger(currentValues.limit))
    currentValues['currentLimit'] =
      currentValues.limit - currentValues.purchases;

  return currentValues;
};

const setLimit = (items, values) => {
  items?.forEach(item => {
    const purchases = get(item, 'purchases');
    get(item, 'limits', []).forEach(limit => {
      const qty = get(limit, 'qty');
      if (!isUndefined(qty)) {
        if (isUndefined(values?.limit) || qty < values?.limit) {
          values.timeframe = limit?.timeframe
            ? timeframeMap[limit.timeframe]
            : undefined;
          values.limit = qty;
          values.purchases = get(purchases, limit?.timeframe, 0);
        }
      }
    });
  });
};
