import React from 'react';

const DistanceIcon = ({ color = '#fff', width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={color}
        d="M3.167 5.875a.626.626 0 00.479-.225c.425-.508 1.812-2.254 1.812-3.317A2.296 2.296 0 003.167.042 2.296 2.296 0 00.875 2.333c0 1.063 1.388 2.809 1.813 3.317a.626.626 0 00.479.225zm0-4.583c.575 0 1.041.466 1.041 1.041 0 .325-.479 1.142-1.041 1.905-.563-.763-1.042-1.58-1.042-1.905 0-.575.467-1.041 1.042-1.041zM14.833 12.125a2.296 2.296 0 00-2.291 2.292c0 1.062 1.387 2.808 1.812 3.316a.626.626 0 00.958 0c.426-.508 1.813-2.254 1.813-3.316a2.296 2.296 0 00-2.292-2.292zm0 4.196c-.562-.763-1.041-1.58-1.041-1.904a1.042 1.042 0 012.083 0c0 .325-.48 1.141-1.042 1.904z"
      ></path>
      <path
        fill={color}
        d="M12.333 16.709H4a1.878 1.878 0 01-1.875-1.875c0-1.034.842-1.875 1.875-1.875h5a3.126 3.126 0 000-6.25H4.417a.624.624 0 100 1.25H9c1.033 0 1.875.841 1.875 1.875A1.878 1.878 0 019 11.709H4a3.126 3.126 0 000 6.25h8.333a.624.624 0 100-1.25z"
      ></path>
    </svg>
  );
};

export default React.memo(DistanceIcon);
