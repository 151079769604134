import { Button, Grid, Typography } from '@mui/material';
import { PastButton, UpComingButton } from '../../../components/Shared/Buttons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from '../../../components/Shared/CustomTable';
import { EventCard } from '../../../components/Events/Cards/EventCard';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import MiniSearch from 'minisearch';
import { StyledSearchTextField } from '../../../components/Shared/TextFields';
import { getEvents } from '../../../store/events/actions';
import { isDateInTheFuture } from '../../../utils/date';
import { useNavigate } from 'react-router-dom';

export const MyManagedEventsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasActiveGame = Boolean(process.env.REACT_APP_ACTIVE_GAME);

  const { game, user } = useSelector(({ app }) => app);
  const { isFetchingManagedEvents, managedEvents } = useSelector(
    ({ events }) => events
  );
  const [searchResults, setSearchResults] = useState();
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [pastEvents, setPastEvents] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [activeButton, setActiveButton] = useState('upcoming');

  // const handleChange = (event, value) => {
  //   setPage(value);
  //   // TODO: fetch next page of events to map on frontend
  // };

  const miniSearch = new MiniSearch({
    fields: ['name'],
    storeFields: [
      'canJoin',
      'details',
      'entityName',
      'imageUrl',
      'isOrganizer',
      'location',
      'metaData',
      'name',
      'startDate',
      'tournament',
      '_id'
    ],
    idField: ['_id'],
    searchOptions: {
      boost: { name: 2 },
      fuzzy: 0.2
    }
  });

  const handleSearch = e => {
    setPastEvents(null);
    setUpcomingEvents(null);
    const eventTarget = e.target.value;
    miniSearch.addAll(managedEvents);
    setSearchInput(eventTarget);
    const results = miniSearch.search(eventTarget, { prefix: true });
    if (eventTarget) {
      setSearchResults(results);
    } else if (eventTarget === '') {
      setSearchResults(managedEvents);
    }
  };

  const getEventList = () => {
    if (upcomingEvents) {
      return upcomingEvents;
    } else if (pastEvents) {
      return pastEvents;
    } else if (searchResults) {
      return searchResults;
    }
    return managedEvents;
  };

  useEffect(() => {
    if (game.id && user.details) {
      dispatch(getEvents());
    }
  }, [getEvents]);

  const handleUpcomingEvents = () => {
    const newUpcomingEvents = [];
    if (managedEvents) {
      managedEvents.forEach(event => {
        if (isDateInTheFuture(event.startDate)) {
          newUpcomingEvents.push(event);
        }
      });
      setActiveButton('upcoming');
      setPastEvents(null);
      setUpcomingEvents(newUpcomingEvents);
    }
  };

  useEffect(() => {
    handleUpcomingEvents();
  }, [managedEvents]);

  const handlePastEvents = () => {
    const newPastEvents = [];
    managedEvents.forEach(event => {
      if (!isDateInTheFuture(event.startDate)) {
        newPastEvents.push(event);
      }
    });
    setActiveButton('past');
    setUpcomingEvents(null);
    setPastEvents(newPastEvents.reverse());
  };

  return (
    <MaxWidthContainer
      id="my-events-container"
      data-testid="my-events-container"
      container
      className={'p-percent'}
    >
      <Grid container mt="96px">
        <Grid
          sx={{
            width: '100%'
          }}
          flexWrap="wrap"
          justifyContent="space-between"
          container
        >
          <Typography mb={4} variant="h1" data-testid="my-events-title">
            My Events
          </Typography>

          <Button
            onClick={() =>
              navigate(`${hasActiveGame ? '' : `/${game.slug}`}/events/new`)
            }
            sx={{ p: 4, height: '24px' }}
            variant="contained"
            id="create-event-button"
            data-testid="create-event-button"
          >
            Create Event
          </Button>
        </Grid>
      </Grid>
      <Grid
        id="filter-events-container"
        data-testid="filter-events-container"
        container
        mt="32px"
        columnSpacing={'16px'}
        mb="64px"
        rowSpacing={'16px'}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <StyledSearchTextField
            onChange={e => handleSearch(e)}
            value={searchInput}
            id="search-events-input"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid
            container
            columnSpacing={'16px'}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <UpComingButton
                className={activeButton === 'upcoming' ? 'active-button' : ''}
                onClick={handleUpcomingEvents}
                id="upcoming-events-button"
                data-testid="upcoming-events-button"
              >
                Upcoming
              </UpComingButton>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <PastButton
                className={activeButton === 'past' ? 'active-button' : ''}
                onClick={handlePastEvents}
                id="past-events-button"
                data-testid="past-events-button"
              >
                Past
              </PastButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <CustomTable
          state={isFetchingManagedEvents ? 'loading' : ''}
          search={false}
          items={getEventList()}
          component={(event, idx) => (
            <td>
              <EventCard
                key={idx + event}
                event={event}
                id={'my-events-card-' + idx}
              />
            </td>
          )}
        />
      </Grid>
      {/* <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
          mt={'60px'}
        >
          <Pagination
            count={10}
            page={page}
            size={'large'}
            onChange={handleChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#fff'
              },
              '& .Mui-selected': {
                backgroundColor: theme.primaryColor,
                color: theme.white
              }
            }}
          />
        </Grid>
      </Grid> */}
    </MaxWidthContainer>
  );
};

export default MyManagedEventsPage;
