/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import RewardCard from './RewardCard';
import { styled, useTheme } from '@mui/material';
import NavigationButton from '../../../components/Swiper/Navigation/Buttons/NavigationButton';

const SeasonRewardsSwiper = ({ rewards }) => {
  const theme = useTheme();
  const [swiperStatus, setSwiperStatus] = useState({
    isBeginning: true,
    isEnd: false
  });

  const breakpoints = {
    [theme.breakpoints.values.md]: {
      slidesPerView: 3
    },
    [theme.breakpoints.values.sm]: {
      slidesPerView: 2
    },
    [theme.breakpoints.values.xs]: {
      slidesPerView: 1
    }
  };

  return (
    <Swiper
      style={{
        paddingBottom: '40px',
        paddingTop: '16px',
        paddingLeft: '5%',
        paddingRight: '5%'
      }}
      modules={[Pagination]}
      pagination={{
        clickable: true
      }}
      spaceBetween={20}
      slideToClickedSlide={true}
      breakpoints={breakpoints}
      onSlideChange={swiper => {
        setSwiperStatus({
          isBeginning: swiper.isBeginning,
          isEnd: swiper.isEnd
        });
      }}
    >
      {!swiperStatus.isBeginning && <NavigationButton prev />}
      {!swiperStatus.isEnd && <NavigationButton />}
      {rewards.map((reward, index) => (
        <SwiperSlide key={index}>
          <RewardCard reward={reward} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SeasonRewardsSwiper;
