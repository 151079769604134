import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep, debounce, entries, get, isEmpty, unset } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  buildValues,
  getDeckErrors,
  getDeckTerminologies
} from '../../../utils/deck';

import { CreateDeckContainer } from './styles';
import DeckCreateSectionButtons from '../../../components/Decks/DeckCreateSectionButtons';
import DeckDetailView from '../../../components/Decks/Sections/DeckDetailView';
import DeckPage from './SinglePages/DeckPage';
import Loader from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import Tooltip from '../../../components/Shared/Tooltip';
import { buildRoutePath } from '../../../utils/routingUtils';
import { useDeck } from '../hooks/useDeck';

const CreateEventPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { deckMetrics } = useFlags();
  const { user, game } = useSelector(({ app }) => app);
  const { gameSlug, deckId, ...params } = useParams();
  const ref = useRef();
  const deckDetailRef = useRef();
  const offsetTop = { xs: `${get(ref, 'current.offsetTop', 0)}px`, md: 0 };
  const deckTerminologies = getDeckTerminologies(game);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    singleDeckProps,
    updateDeckProps,
    deckFormats,
    deckFormatsById: gameFormatsById,
    defaultDeckType,
    deleteModalAction,
    checkDeckValidation
  } = useDeck({ deckId });
  const [updateDeck, updateDeckResult] = updateDeckProps;
  const { data: singleDeckData } = singleDeckProps;
  const [checkDeck, checkDeckResult] = checkDeckValidation;
  const [deckData, setDeckData] = useState(null);

  const deck = singleDeckData?.data?.deck;
  // React hook form hook
  const methods = useForm({
    defaultValues: {},
    values: buildValues(singleDeckData, defaultDeckType, gameFormatsById)
  });

  // Update deck info
  const createDeckRequestBody = data => {
    const body = cloneDeep(data);
    unset(body, 'errors');
    unset(body, 'userId');
    unset(body, 'gameId');
    unset(body, '_id');
    unset(body, 'cardInfo');
    unset(body, 'sectionHelper');
    unset(body, 'formatOption');

    body?.sections?.forEach(section => {
      unset(section, 'cardTracker');
      section?.cards?.forEach(card => {
        unset(card, 'cardInfo');
      });
    });

    if (!isEmpty(body.formatOptions)) {
      body.formatOptions = entries(body?.formatOptions)?.reduce(
        (prev, current) => ({
          ...prev,
          [current[0]]: get(current, '1.1', '')
        }),
        {}
      );
    }

    if (body.tags) {
      body.deckTagIds = body.tags.map(tag => tag.value);
    }

    unset(body, 'tags');

    return { body, deckId: data._id };
  };

  const fetchDeckValidation = data => {
    const userId = data.userId;
    const gameId = data.gameId;
    const { body, deckId: _id } = createDeckRequestBody(data);
    checkDeck({
      body: {
        ...body,
        userId,
        gameId,
        _id
      }
    });
  };

  const debouncedFetchCheckDeckValidation = debounce(data => {
    fetchDeckValidation(data);
  }, 300);

  const checkDeckValidationAction = () => {
    debouncedFetchCheckDeckValidation?.cancel();
    debouncedFetchCheckDeckValidation(methods.getValues());
  };

  // Update deck info
  const onSubmit = data => {
    updateDeck(createDeckRequestBody(data));
  };

  // Sections in create deck
  const sections = [
    {
      title: deckTerminologies.deck,
      key: 'deck',
      path: '',
      show: true
    },
    {
      title: 'Preview',
      key: 'preview',
      path: 'preview',
      show: true
    },
    {
      title: 'Metrics',
      key: 'metrics',
      path: 'metrics',
      show: deckMetrics
    },
    {
      title: 'Settings',
      key: 'settings',
      path: 'settings',
      show: true
    }
  ].filter(section => section.show);

  // eslint-disable-next-line no-unused-vars
  const [filterOpen, setFilterOpen] = useState(false);
  const [deckOpen, setDeckOpen] = useState(false);

  const [sectionSelected, setSectionSelected] = useState(
    sections.findIndex(section =>
      `${deckId}/edit/${section.path}`.includes(params['*'].toString())
    )
  );

  const selectionAction = index => {
    setSectionSelected(index);
    if (sectionSelected !== index) {
      navigate(sections[index].path, {
        ...(index !== 0 || (index === 0 && isSmall)
          ? {
              preventScrollReset: true
            }
          : {})
      });
    }
  };

  const deckProps = {
    deckOpenProps: [deckOpen, setDeckOpen],
    offsetTop: offsetTop
  };

  const filterProps = {
    breakpoint: 'md',
    filterOpenProps: [filterOpen, setFilterOpen],
    offsetTop,
    hideButton: true
  };

  const contextValues = {
    isLoading: updateDeckResult?.isLoading,
    gameFormatsById,
    deleteModalAction,
    deckFormats,
    filterProps,
    deckProps,
    checkDeckValidationAction,
    deck: deckData
  };

  useEffect(() => {
    if (!updateDeckResult?.isLoading && updateDeckResult?.isSuccess) {
      toast.success('Deck Updated!');
    }
  }, [updateDeckResult]);

  useEffect(() => {
    if (!checkDeckResult.isLoading && checkDeckResult.isSuccess) {
      const updatedErrors = get(checkDeckResult, 'data.data.errors', {});
      methods.setValue('errors', updatedErrors);
      setDeckData(prevDeckData => ({
        data: {
          ...prevDeckData.data,
          deck: {
            ...prevDeckData.data.deck,
            metrics: get(checkDeckResult, 'data.data.metrics', {})
          }
        }
      }));
    }
  }, [checkDeckResult]);

  useEffect(() => {
    if (singleDeckData) {
      setDeckData(singleDeckData);
    }
  }, [singleDeckData]);

  // Show loader when loading screen
  if (singleDeckProps.isLoading) {
    return <Loader entireScreen />;
  }

  // If they don't have permissions, navigate back to decks.
  if (
    singleDeckData?.data?.deck?.userId !== user?.details?.id ||
    deck?.gameId !== game?.id
  ) {
    return <Navigate to={buildRoutePath(gameSlug, '/decks')} replace={true} />;
  }

  return (
    <MaxWidthContainer
      id="decks-create-deck-section"
      data-testid="decks-create-deck-section"
      className={'p-percent p-top-5'}
      ref={ref}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            justifyContent={'space-between'}
            mb="24px"
          >
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent={'space-between'}>
                <Grid item>
                  <Grid container alignItems={'flex-end'}>
                    <Grid item>
                      <Typography
                        className={'deck-term'}
                        variant={'h1'}
                        data-testid="deck-builder-title"
                      >
                        {get(game, 'terminology.deck', 'Deck')} Builder
                      </Typography>
                    </Grid>
                    <Controller
                      control={methods.control}
                      name={'errors'}
                      render={({ field }) => {
                        const errors = field?.value;
                        return !isEmpty(errors) ? (
                          <Grid item>
                            <Tooltip
                              icon={
                                <ErrorIcon
                                  className={'error-icon'}
                                  color={'error'}
                                  fontSize={'medium'}
                                />
                              }
                              info={getDeckErrors({ errors, id: '$' })}
                            />
                          </Grid>
                        ) : (
                          <></>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={updateDeckResult?.isLoading}
                    type={'submit'}
                    variant={'contained'}
                    id="create-deck-save-deck-button"
                    data-testid="create-deck-save-deck-button"
                  >
                    Save {get(game, 'terminology.deck', 'Deck')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                sx={{
                  opacity: checkDeckResult?.isLoading ? 1.0 : 0
                }}
              />
            </Grid>
          </Grid>
          <CreateDeckContainer
            id="decks-create-deck-container"
            data-testid="decks-create-deck-container"
          >
            <Box ref={deckDetailRef} width={'100%'} height={'fit-content'}>
              <DeckDetailView
                id="create-deck-detail-view-section"
                data-testid="create-deck-detail-view-section"
                sections={sections}
                sectionSelected={sectionSelected}
                onSelectionChange={selectionAction}
                createCustomHeader={(section, isSmall) => {
                  return (
                    <DeckCreateSectionButtons
                      isSmall={isSmall}
                      section={section}
                      deckProps={deckProps}
                      filterProps={filterProps}
                      hideFilter={sectionSelected === 0}
                    />
                  );
                }}
              >
                <Outlet context={contextValues} />
              </DeckDetailView>
            </Box>
            <DeckPage
              deckDetailRef={sectionSelected === 0 && deckDetailRef}
              deckProps={deckProps}
              checkDeckValidation={checkDeckValidationAction}
            />
          </CreateDeckContainer>
        </form>
      </FormProvider>
    </MaxWidthContainer>
  );
};

export default CreateEventPage;
