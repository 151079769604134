export const OrderStatuses = {
  processing: {
    text: 'Processing',
    color: 'info'
  },
  confirmed: {
    text: 'Confirmed',
    color: 'primary'
  },
  shipped: {
    text: 'Shipped',
    color: 'primary'
  },
  delivered: {
    text: 'Delivered',
    color: 'primary'
  },
  completed: {
    text: 'Completed',
    color: 'primary'
  },
  canceled: {
    text: 'Canceled',
    color: 'error'
  },
  error: {
    text: 'Error',
    color: 'error'
  },
  returned: {
    text: 'Returned',
    color: 'error'
  },
  refunded: {
    text: 'Refunded',
    color: 'info'
  }
};
