import React, { useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
  Link
} from '@mui/icons-material';
import { Menu, MenuItem, ToggleButtonGroup, Typography } from '@mui/material';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isLeafNode,
  $isRangeSelection,
  $isRootNode,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { ToolbarContainer, ToolbarToggleButton } from './styles';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { createPortal } from 'react-dom';
import { getSelectedNode } from './utils';
import FloatingLinkEditor from './FloatingLinkEditor';

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [isLink, setIsLink] = useState(false);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const formatTypes = {
    bold: 'bold',
    italic: 'italic',
    underline: 'underline',
    strikethrough: 'strikethrough',
    left: { type: 'left', format: 1 },
    center: { type: 'center', format: 2 },
    right: { type: 'right', format: 3 },
    paragraphs: {
      paragraph: { type: 'paragraph', title: 'Normal' },
      h1: { type: 'h1', title: 'Header 1' },
      h2: { type: 'h2', title: 'Header 2' },
      h3: { type: 'h3', title: 'Header 3' },
      h4: { type: 'h4', title: 'Header 4' },
      h5: { type: 'h5', title: 'Header 5' },
      h6: { type: 'h6', title: 'Header 6' },
      multiple: { type: 'multiple', title: 'Multiple' }
    }
  };

  const paragraphList = [
    formatTypes.paragraphs.paragraph,
    formatTypes.paragraphs.h1,
    formatTypes.paragraphs.h2,
    formatTypes.paragraphs.h3,
    formatTypes.paragraphs.h4,
    formatTypes.paragraphs.h5,
    formatTypes.paragraphs.h6
  ];

  const alignmentList = [
    { value: formatTypes.left.type, icon: <FormatAlignLeft /> },
    { value: formatTypes.center.type, icon: <FormatAlignCenter /> },
    { value: formatTypes.right.type, icon: <FormatAlignRight /> }
  ];

  const [formats, setFormats] = useState([]);
  const [alignment, setAlignment] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [paragraph, setParagraph] = useState(paragraphList[0]);

  const formatList = [
    { value: formatTypes.bold, icon: <FormatBold /> },
    { value: formatTypes.italic, icon: <FormatItalic /> },
    { value: formatTypes.underline, icon: <FormatUnderlined /> },
    { value: formatTypes.strikethrough, icon: <FormatStrikethrough /> }
  ];
  const updateParagraphType = newParagraph => {
    const createNode =
      newParagraph.type === formatTypes.paragraphs.paragraph.type
        ? $createParagraphNode
        : $createHeadingNode;
    if (newParagraph.type !== paragraph.type) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => createNode(newParagraph.type));
        }
      });
    }
  };
  // Creates the buttons used on the toolbar
  const createToggleButtons = (list, commandType = FORMAT_TEXT_COMMAND) =>
    list.map(obj => (
      <ToolbarToggleButton
        key={obj.value}
        value={obj.value}
        onClick={() => {
          editor.dispatchCommand(commandType, obj.value);
        }}
      >
        {obj.icon}
      </ToolbarToggleButton>
    ));

  const getElementNode = node => {
    if (!node || $isRootNode(node)) {
      return null;
    } else if ($isLeafNode(node)) {
      return getElementNode($getNodeByKey(node.__parent));
    } else if ($isElementNode(node)) {
      return node;
    }
    return null;
  };

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      // Setup current Alignment
      let currentAlignment = 0;
      let currentParagraph = formatTypes.paragraphs.paragraph;
      let elementFound = false;

      const node = $getNodeByKey(selection.anchor.key);
      if (selection._cachedNodes) {
        for (const node of selection._cachedNodes) {
          if ($isElementNode(node)) {
            elementFound = true;
            currentAlignment = node.__format;
            currentParagraph = formatTypes.paragraphs.multiple;
            break;
          }
        }
      }

      if (!elementFound) {
        const parent = getElementNode(node);
        if (parent) {
          currentAlignment = parent.__format;
          if (parent.__tag) {
            const foundParagraph = paragraphList.find(
              obj => obj.type === parent.__tag
            );

            currentParagraph = foundParagraph
              ? foundParagraph
              : currentParagraph;
          }
        }
      }

      switch (currentAlignment) {
        case formatTypes.left.format:
          currentAlignment = formatTypes.left.type;
          break;
        case formatTypes.center.format:
          currentAlignment = formatTypes.center.type;
          break;
        case formatTypes.right.format:
          currentAlignment = formatTypes.right.type;
          break;
        default:
          currentAlignment = '';
      }

      setParagraph(currentParagraph);
      setAlignment(currentAlignment);

      // Setup current format.
      const builtFormats = formatList
        .filter(obj => selection.hasFormat(obj.value))
        .map(obj => obj.value);

      setFormats(builtFormats);

      const testNode = getSelectedNode(selection);
      const parent = testNode.getParent();
      if ($isLinkNode(parent) || $isLinkNode(testNode)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [updateToolbar, editor]);

  return (
    <>
      <ToolbarContainer>
        <PopupState
          variant={'popover'}
          popupId={'paragraphMenu'}
          disableAutoFocus={true}
        >
          {popupState => (
            <>
              <ToolbarToggleButton
                {...bindTrigger(popupState)}
                value={''}
                sx={{ minWidth: '150px', textAlign: 'center' }}
              >
                <Typography variant={'body1'}>{paragraph.title}</Typography>
              </ToolbarToggleButton>
              <Menu {...bindMenu(popupState)}>
                {paragraphList.map(obj => (
                  <MenuItem
                    key={obj.type}
                    onClick={() => {
                      updateParagraphType(obj);
                      popupState.close();
                    }}
                  >
                    {obj.title}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
        <ToggleButtonGroup value={alignment} exclusive>
          {createToggleButtons(alignmentList, FORMAT_ELEMENT_COMMAND)}
        </ToggleButtonGroup>
        <ToggleButtonGroup value={formats}>
          {createToggleButtons(formatList)}
        </ToggleButtonGroup>
        <ToolbarToggleButton onClick={insertLink}>
          <Link />
        </ToolbarToggleButton>
      </ToolbarContainer>
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </>
  );
};

export default Toolbar;
