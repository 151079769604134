import 'swiper/css';
import 'swiper/css/pagination';
import '../../index';

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Carousel from 'react-spring-3d-carousel';
import FeaturedEventCard from '../../components/Events/Cards/Featured';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { config } from 'react-spring';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

export const LandingFeatured = () => {
  const { game } = useSelector(({ app }) => app);

  const [featuredEvents, setFeaturedEvents] = useState(
    get(game, 'events.featured', [])
  );

  useEffect(() => {
    if (featuredEvents.length == 2) {
      setFeaturedEvents([...featuredEvents, ...featuredEvents]);
    }
  });

  const [activeEvent, setActiveEvent] = useState(0);

  const hidden = useMediaQuery(theme => theme.breakpoints.down('md'));

  const slides = featuredEvents.map((event, index) => {
    return {
      key: index,
      content: (
        <FeaturedEventCard
          id={`featured-event-${event._id}-card-button`}
          data-testid={`featured-event-${event._id}-card-button`}
          event={event}
          active={index === activeEvent}
        />
      ),
      onClick: () => setActiveEvent(index)
    };
  });

  if (featuredEvents.length <= 0) {
    return <></>;
  }

  return (
    <>
      <MaxWidthContainer
        id="landing-page-featured-container"
        data-testid="landing-page-featured-container"
        container
        className={'p-percent'}
      >
        <Grid item mt={{ xs: 2, md: 8 }} xs={12}>
          <Typography variant="h2">Featured Events</Typography>
        </Grid>
        {slides.length <= 0 && (
          <Grid item xs={12} paddingY={10}>
            <Typography variant={'h3'} textAlign={'center'}>
              No Featured Events
            </Typography>
          </Grid>
        )}
      </MaxWidthContainer>
      {slides.length > 0 &&
        (hidden ? (
          <>
            <Grid
              container
              sx={{
                ['.swiper-wrapper']: {},
                padding: '2em 0'
              }}
            >
              <Swiper
                observer={true}
                loop={true}
                centeredSlides={true}
                slideToClickedSlide={true}
                autoHeight={false}
                spaceBetween={40}
                slidesPerView={1.5}
              >
                {featuredEvents.map((obj, index) => (
                  <SwiperSlide key={index}>
                    {({ isActive }) => (
                      <FeaturedEventCard
                        id={`featured-event-${event._id}-card-button`}
                        data-testid={`featured-event-${event._id}-card-button`}
                        event={obj}
                        active={isActive}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </>
        ) : (
          <>
            <MaxWidthContainer
              id="landing-page-featured-container"
              data-testid="landing-page-featured-container"
              container
              className={'carousel'}
              height={'700px'}
              overflow={'hidden'}
            >
              <Carousel
                slides={slides}
                goToSlide={activeEvent}
                offsetRadius={2}
                animationConfig={config.default}
              />
            </MaxWidthContainer>
          </>
        ))}
    </>
  );
};

export default LandingFeatured;
