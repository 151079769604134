import { Box, ListItem, styled } from '@mui/material';

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(5),
  width: '100%',
  padding: theme.spacing(1)
}));

export const CardList = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.primary}33`,
  padding: theme.spacing(1)
}));
