import React, { useEffect, useState } from 'react';

import AllCards from '../../../../Cards/Pages/AllCards';
import { debounce } from 'lodash';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DeckCardsSelectorPage = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const heightDivisor = 1.8;
  const getHeight = () => {
    return window.innerHeight / heightDivisor;
  };
  const [currentHeight, setCurrentHeight] = useState(getHeight);

  const setHeightDebounce = debounce(() => {
    setCurrentHeight(getHeight());
  }, 1000);

  useEffect(() => {
    if (!isSmall) {
      window.scrollTo(0, 0);
    }
    document.body.style.overflow = isSmall ? 'auto' : 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSmall]);

  useEffect(() => {
    const handleResize = () => {
      setHeightDebounce?.cancel();
      setHeightDebounce();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      setHeightDebounce?.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  return (
    <AllCards
      id="create-deck-card-view-section"
      data-testid="create-deck-card-view-section"
      type={'deckBuilder'}
      height={currentHeight}
    />
  );
};

export default DeckCardsSelectorPage;
