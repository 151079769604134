import React from 'react';
import { get } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Typography } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { buildRoutePath } from '../../../utils/routingUtils';
import { getCartItemCount } from '../../../utils/cart';
import { useGetCartDetailsQuery } from '../../../services/apis/organizePlayApi';
import { useIsLoggedIn } from '../../../hooks/auth0/useLoggedIn';

import { CustomIcon, ItemCount } from './styles';

const Cart = () => {
  const { rewardStore } = useFlags();
  const { game } = useSelector(({ app }) => app);
  const isLoggedIn = useIsLoggedIn();
  const { gameSlug } = useParams();
  const { data: cartDetails, isLoading, isFetching } = useGetCartDetailsQuery(
    {},
    {
      skip: !game || !rewardStore || !isLoggedIn
    }
  );
  const isCartDetailsLoading = isLoading || isFetching;
  const count = getCartItemCount(get(cartDetails, 'cart.items', {}));

  if (rewardStore && isLoggedIn) {
    return (
      <Link to={buildRoutePath(gameSlug, '/cart')}>
        <CustomIcon data-testid="regular-cart-nav-button">
          <ShoppingCartOutlinedIcon
            sx={{ color: theme => theme.colors.header.textColor }}
          />
          {!isCartDetailsLoading && count > 0 ? (
            <ItemCount>
              <Typography sx={{ color: '#000000' }} variant="body3">
                {count}
              </Typography>
            </ItemCount>
          ) : null}
        </CustomIcon>
      </Link>
    );
  }
};

export default Cart;
