import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

export function useFormError(formState) {
  useEffect(() => {
    if (!isEmpty(formState.errors)) {
      toast.error('Errors found', { toastId: 'Errors found' });
    }
  }, [formState.errors]);

  return {};
}
