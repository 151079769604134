import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTheme } from '@mui/material/styles';

import {
  EventPrizeAmountContainer,
  EventTooltip,
  GeneralEventCard
} from '../../../../pages/Events/Single/EventOverviewPage/style';

import firstPlaceIcon from '../../../../assets/rewards/first.svg';
import secondPlaceIcon from '../../../../assets/rewards/second.svg';
import thirdPlaceIcon from '../../../../assets/rewards/third.svg';
import top8Icon from '../../../../assets/rewards/top8.svg';
import top16Icon from '../../../../assets/rewards/top16.svg';

const PrizeInformation = ({ prize }) => {
  const theme = useTheme();
  const { activeEvent } = useSelector(({ events }) => events);

  const [open, setOpen] = useState(false);

  const setOpenFalse = () => setOpen(false);
  const topCutAmount = get(activeEvent, 'tournament.cut.limit', 0);

  const prizing = {
    first: firstPlaceIcon,
    second: secondPlaceIcon,
    third: thirdPlaceIcon,
    top8: top8Icon,
    top16: top16Icon,
    default: top16Icon
  };

  if (prize.limit > topCutAmount && !prize.value && !prize.productValue) {
    return null;
  }

  return (
    <GeneralEventCard>
      <Grid container justifyContent="center">
        {prize.title.includes('Top') && (
          <Typography
            sx={{ mb: 2, textAlign: 'center', width: '100%' }}
            variant="h3"
          >
            {prize.title}
          </Typography>
        )}
        <img
          src={prizing[prize.title.replace(' ', '').toLowerCase()]}
          height={'auto'}
          width={prize.title.includes('Top') ? '50%' : '70%'}
        />
      </Grid>
      {prize.value > 0 && (
        <EventPrizeAmountContainer>
          <Typography variant="h6">${prize.value}</Typography>
        </EventPrizeAmountContainer>
      )}
      {prize.productValue && (
        <ClickAwayListener onClickAway={setOpenFalse}>
          <EventTooltip
            open={open}
            onClose={setOpenFalse}
            title={
              <>
                <Typography color="inherit">{prize.productValue}</Typography>
              </>
            }
          >
            <Box
              sx={{
                padding: '0 !important',
                alignSelf: 'flex-end',
                backgroundColor: `${theme.palette.primary.light} !important`
              }}
            >
              <IconButton
                onMouseLeave={setOpenFalse}
                onMouseEnter={() => setOpen(true)}
                onClick={() => setOpen(!open)}
                sx={{ background: 'transparent' }}
              >
                <EmojiEventsIcon />
              </IconButton>
            </Box>
          </EventTooltip>
        </ClickAwayListener>
      )}
      {!prize.value && !prize.productValue && (
        <Typography>
          <Typography
            sx={{ mt: 2, textAlign: 'center', width: '100%' }}
            variant="h6"
          >
            No Rewards
          </Typography>
        </Typography>
      )}
    </GeneralEventCard>
  );
};

export default PrizeInformation;
