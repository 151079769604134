/* eslint-disable no-unused-vars */
import { Box, Checkbox, Grid, Typography, styled } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../../../../../components/Icon';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import SocialLinkUpdate from './SocialLinkUpdate/index';
import { StyledSelectTextField } from '../../../../../../components/Shared/TextFields/index';

const StyledGrid = styled(Grid)(({ theme }) => ({
  minHeight: '360px',
  '& .store-socials-grid-row': {
    '&:not(:last-child)': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.neutral[400]}`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const StoreSocialsEdit = ({ control, name }) => {
  const { fields, move } = useFieldArray({
    control,
    name
  });
  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <StyledGrid container spacing={2}>
        <Droppable droppableId="store-social-links">
          {(provided, snapshot) => (
            <Box
              sx={{ width: '100%' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fields.map((item, index) => {
                return (
                  <Draggable
                    draggableId={`item-${index}`}
                    index={index}
                    key={item.name}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        item
                        xs={12}
                        sx={{ minHeight: '60px' }}
                        ref={provided.innerRef}
                        className="store-socials-grid-row"
                        display="flex"
                        alignItems="center"
                        {...provided.draggableProps}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={1}
                            display="flex"
                            alignItems="center"
                            {...provided.dragHandleProps}
                          >
                            <MenuIcon />
                          </Grid>
                          <Grid item xs={5} display="flex" alignItems="center">
                            <Grid container spacing={1}>
                              <Grid item xs={3} sm={2}>
                                <Icon name={item.name} />
                              </Grid>
                              <Grid item xs={9} sm={10} textAlign="start">
                                <Typography
                                  variant="body1Light"
                                  textTransform="capitalize"
                                >
                                  {item.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} textAlign="end">
                            <Controller
                              name={`${name}.${index}.url`}
                              control={control}
                              render={({ field }) => (
                                <SocialLinkUpdate {...field} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            </Box>
          )}
        </Droppable>
      </StyledGrid>
    </DragDropContext>
  );
};

export default StoreSocialsEdit;
