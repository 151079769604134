export const CREATE_NEW_EVENT_REQUEST = 'events/CREATE_NEW_EVENT_REQUEST';
export const CREATE_NEW_EVENT_SUCCESS = 'events/CREATE_NEW_EVENT_SUCCESS';
export const CREATE_NEW_EVENT_FAILURE = 'events/CREATE_NEW_EVENT_FAILURE';

export const UPDATE_EVENT_REQUEST = 'events/UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'events/UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'events/UPDATE_EVENT_FAILURE';

export const GET_ALL_EVENTS_REQUEST = 'events/GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'events/GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILURE = 'events/GET_ALL_EVENTS_FAILURE';

export const GET_EVENT_REQUEST = 'events/GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS = 'events/GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'events/GET_EVENT_FAILURE';

export const GET_EVENTS_REQUEST = 'events/GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'events/GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'events/GET_EVENTS_FAILURE';

export const GET_EVENT_ROSTER_REQUEST = 'events/GET_EVENT_ROSTER_REQUEST';
export const GET_EVENT_ROSTER_SUCCESS = 'events/GET_EVENT_ROSTER_SUCCESS';
export const GET_EVENT_ROSTER_FAILURE = 'events/GET_EVENT_ROSTER_FAILURE';

export const GET_EVENT_PAIRINGS_REQUEST = 'events/GET_EVENT_PAIRINGS_REQUEST';
export const GET_EVENT_PAIRINGS_SUCCESS = 'events/GET_EVENT_PAIRINGS_SUCCESS';
export const GET_EVENT_PAIRINGS_FAILURE = 'events/GET_EVENT_PAIRINGS_FAILURE';

export const GET_EVENT_STANDINGS_REQUEST = 'events/GET_EVENT_STANDINGS_REQUEST';
export const GET_EVENT_STANDINGS_SUCCESS = 'events/GET_EVENT_STANDINGS_SUCCESS';
export const GET_EVENT_STANDINGS_FAILURE = 'events/GET_EVENT_STANDINGS_FAILURE';

export const ADD_PARTICIPANTS_REQUEST = 'events/ADD_PARTICIPANTS_REQUEST';
export const ADD_PARTICIPANTS_SUCCESS = 'events/ADD_PARTICIPANTS_SUCCESS';
export const ADD_PARTICIPANTS_FAILURE = 'events/ADD_PARTICIPANTS_FAILURE';

export const UPDATE_PARTICIPANT_REQUEST = 'events/UPDATE_PARTICIPANT_REQUEST';
export const UPDATE_PARTICIPANT_SUCCESS = 'events/UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_FAILURE = 'events/UPDATE_PARTICIPANT_FAILURE';

export const START_EVENT_REQUEST = 'events/START_EVENT_REQUEST';
export const START_EVENT_SUCCESS = 'events/START_EVENT_SUCCESS';
export const START_EVENT_FAILURE = 'events/START_EVENT_FAILURE';

export const NEXT_ROUND_REQUEST = 'events/NEXT_ROUND_REQUEST';
export const NEXT_ROUND_SUCCESS = 'events/NEXT_ROUND_SUCCESS';
export const NEXT_ROUND_FAILURE = 'events/NEXT_ROUND_FAILURE';

export const REPORT_RESULTS_REQUEST = 'events/REPORT_RESULTS_REQUEST';
export const REPORT_RESULTS_SUCCESS = 'events/REPORT_RESULTS_SUCCESS';
export const REPORT_RESULTS_FAILURE = 'events/REPORT_RESULTS_FAILURE';

export const START_TIME_IN_ROUND_REQUEST = 'events/START_TIME_IN_ROUND_REQUEST';
export const START_TIME_IN_ROUND_SUCCESS = 'events/START_TIME_IN_ROUND_SUCCESS';
export const START_TIME_IN_ROUND_FAILURE = 'events/START_TIME_IN_ROUND_FAILURE';

export const RESET_ACTIVE_EVENT = 'events/RESET_ACTIVE_EVENT';
export const SET_ACTIVE_ROUND = 'events/SET_ACTIVE_ROUND';
export const FINALIZE_EVENT_SUCCESS = 'events/FINALIZE_EVENT_SUCCESS';
