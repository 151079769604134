import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  StyledSearchTextField,
  StyledSelectTextField
} from '../../../../components/Shared/TextFields';
import { debounce, get, isEmpty } from 'lodash';
import { getRoundDetails, singleEventInfo } from '../../../../utils/EventUtils';
import {
  useGetCurrentUserPairingQuery,
  useGetSingleEventPairingsQuery,
  useGetSingleEventQuery,
  usePostStartEventTimeMutation
} from '../../../../services/apis/organizePlayApi';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EventDetailsCard } from '../EventOverviewPage/style';
import { EventRoundTimer } from '../../../../components/Events/EventRoundTimer';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import Loader from '../../../../components/Loading/Loader';
import Loading from '../../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../../components/Shared/Grid/styles';
import PairingCard from './PairingCard';
import { removeEmptyOrUndefined } from '../../../../utils/common-utils';
import { useParams } from 'react-router-dom';
import { usePrevious } from '../../../../utils/usePrevious';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  limit: 25
};

export const pairingsResultStatusOptions = [
  {
    _id: 'all',
    name: 'All'
  },
  {
    _id: 'pending',
    name: 'In Progress'
  },
  {
    _id: 'completed',
    name: 'Submitted'
  }
];

const EventPairingsPage = () => {
  const { eventId } = useParams();
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const [pairings, setPairings] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const { user } = useSelector(({ app }) => app);
  const loggedInUserId = get(user, 'details.id', '');
  const [currentPaginationData, setCurrentPaginationData] = useState(
    DEFAULT_PAGINATION_OPTIONS
  );
  const previousSearchInput = usePrevious(searchInput);
  const [
    selectedPairingResultStatus,
    setSelectedPairingResultStatus
  ] = useState('all');

  const { data: activeEvent } = useGetSingleEventQuery(eventId);
  const previousResultStatus = usePrevious(selectedPairingResultStatus);

  const { currentRound, regularRounds, totalRounds, status } = singleEventInfo(
    activeEvent
  );
  const [goToRound, setGoToRound] = useState(currentRound);

  const resetPagination = () => {
    setCurrentPaginationData(DEFAULT_PAGINATION_OPTIONS);
  };

  useEffect(() => {
    if (
      eventId &&
      goToRound &&
      currentPaginationData.page &&
      currentPaginationData.limit
    ) {
      const newSearchQuery = {
        ...currentPaginationData,
        searchText: searchText ? searchText : undefined,
        eventId,
        roundNumber: goToRound,
        resultStatus: selectedPairingResultStatus
      };
      if (loggedInUserId) {
        newSearchQuery.hideCurrentUserTable = 1;
      }
      setSearchQuery(removeEmptyOrUndefined(newSearchQuery));
    }
  }, [
    currentPaginationData,
    searchText,
    eventId,
    goToRound,
    selectedPairingResultStatus
  ]);

  const {
    data: pairingsData,
    isLoading,
    isFetching
  } = useGetSingleEventPairingsQuery(searchQuery, {
    refetchOnMountOrArgChange: true,
    skip:
      isEmpty(searchQuery) ||
      !searchQuery.page ||
      !searchQuery.limit ||
      !goToRound
  });

  const {
    data: currentUserPairingData,
    isLoading: isCurrentUserPairingLoading,
    isFetching: isCurrentUserPairingFetching
  } = useGetCurrentUserPairingQuery(
    { roundNumber: goToRound, eventId },
    {
      refetchOnMountOrArgChange: true,
      skip: !goToRound || !loggedInUserId || !eventId
    }
  );

  // eslint-disable-next-line no-unused-vars
  const { activePage, totalPages, totalResults } = get(
    pairingsData,
    'pagination',
    {}
  );

  const searchByText = searchTextValue => {
    resetPagination();
    setSearchText(searchTextValue);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => searchByText(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput]);

  useEffect(() => {
    const { activePage } = get(pairingsData, 'pagination', 1);
    const data = get(pairingsData, 'pairings', []);
    if (data) {
      if (activePage === 1) {
        setPairings(data);
      } else {
        setPairings(prevState => [...prevState, ...data]);
      }
    }
  }, [pairingsData]);

  const [startTimer, startTimerResult] = usePostStartEventTimeMutation();

  const isInPlayoffs = goToRound > regularRounds;
  const { totalPairings } = getRoundDetails(
    get(pairingsData, 'roundDetails', [])
  );

  const handleSearch = e => {
    const eventTarget = e.target.value;
    setSearchInput(eventTarget);
  };

  const getTopCutRoundName = () => {
    if (totalPairings === 2) {
      if (goToRound === totalRounds) {
        return 'Finals';
      } else {
        return 'Semi Finals';
      }
    }

    if (totalPairings === 4) {
      return 'Quarter Finals';
    }

    return 'Playoffs';
  };

  const goToRoundAction = (increment = true) => {
    // eslint-disable-next-line no-unused-vars
    const nextRound = goToRound + (increment ? 1 : -1);
    resetPagination();
    setGoToRound(prev => prev + (increment ? 1 : -1));
  };

  useEffect(() => {
    setGoToRound(currentRound);
  }, [activeEvent]);

  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(
      pairingsData,
      'pagination',
      {
        activePage: 0,
        nextPage: 0,
        totalPages: -1
      }
    );
    if (!isLoading && !isFetching && activePage < totalPages) {
      setCurrentPaginationData(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  const onConfirmStartTimer = (startTime = null) => {
    startTimer({ eventId, startTime });
  };

  const onChangeResultStatus = e => {
    const newResultStatus = get(e, 'target.value');
    if (newResultStatus !== previousResultStatus) {
      resetPagination();
    }
    setSelectedPairingResultStatus(newResultStatus);
  };

  const getTotalPairingsCount = () => {
    if (!totalResults) {
      return 0;
    }
    if (loggedInUserId && currentUserPairingData) {
      return totalResults + 1;
    }
    return totalResults;
  };

  return (
    <MaxWidthContainer>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {' '}
          <Grid container item justifyContent="space-between" mb={4} xs={12}>
            <Grid xs={12} mb={{ xs: 4, md: 0 }} md={2.7}>
              <EventDetailsCard style={{ textAlign: 'center' }}>
                {activeEvent && (
                  <EventRoundTimer
                    eventStatus={status}
                    currentRound={currentRound}
                    goToRound={goToRound}
                    pairingsData={pairingsData}
                    activeEvent={activeEvent}
                    onConfirmStartTimer={onConfirmStartTimer}
                    startTimerResult={startTimerResult}
                  />
                )}
              </EventDetailsCard>
            </Grid>

            <Grid xs={12} mb={{ xs: 4, md: 0 }} md={2.7}>
              <EventDetailsCard style={{ textAlign: 'center' }}>
                <Typography variant="h6">TOTAL MATCHES</Typography>
                <Typography mt={1} variant="h4">
                  {totalPairings} Matches
                </Typography>
              </EventDetailsCard>
            </Grid>

            <Grid xs={12} mb={{ xs: 4, md: 0 }} md={2.7}>
              <EventDetailsCard style={{ textAlign: 'center' }}>
                <Typography variant="h6">CURRENT ROUND</Typography>
                <Typography mt={1} variant="h4">
                  Round {get(pairingsData, 'roundDetails.roundNumber')}
                </Typography>
              </EventDetailsCard>
            </Grid>

            <Grid xs={12} mb={{ xs: 4, md: 0 }} md={2.7}>
              <EventDetailsCard style={{ textAlign: 'center' }}>
                <Typography variant="h6">
                  {isInPlayoffs ? 'TOP CUT' : 'ROUNDS REMAINING'}
                </Typography>
                <Typography mt={1} variant="h4">
                  {isInPlayoffs
                    ? getTopCutRoundName()
                    : `${get(
                        pairingsData,
                        'roundDetails.roundsRemaining'
                      )} Rounds`}
                </Typography>
              </EventDetailsCard>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" mb={4} xs={12}>
            {/* Back Button */}
            <Button
              disabled={goToRound - 1 < 1}
              onClick={() => {
                goToRoundAction(false);
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '2rem'
                }}
              />
            </Button>
            {/* Forward Button */}
            <Button
              disabled={goToRound + 1 > currentRound}
              onClick={() => {
                goToRoundAction();
              }}
            >
              <ArrowForwardIcon
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '2rem'
                }}
              />
            </Button>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={8} pr={{ xs: 0, sm: 1 }}>
                  <Box>
                    <StyledSearchTextField
                      id="event-pairings-search-input"
                      onChange={handleSearch}
                      value={searchInput}
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  pl={{ xs: 0, sm: 1 }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Box>
                    <StyledSelectTextField
                      label="Result Status"
                      value={selectedPairingResultStatus}
                      onChange={onChangeResultStatus}
                      menuItems={pairingsResultStatusOptions}
                      id="event-pairing-result-status"
                      sx={{
                        '& .MuiInputBase-root': {
                          '& .MuiInputBase-input': {
                            minHeight: '32px',
                            padding: '12px 14px'
                          }
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {loggedInUserId &&
            currentUserPairingData &&
            !isCurrentUserPairingLoading &&
            !isCurrentUserPairingFetching ? (
              <Box mt={3}>
                <PairingCard
                  pairing={currentUserPairingData}
                  goToRound={goToRound}
                  totalPairings={getTotalPairingsCount()}
                />
              </Box>
            ) : null}
            <Grid item xs={12}>
              <InfiniteScroll
                done={activePage >= totalPages || !pairings}
                emptyMessage={
                  searchText
                    ? 'No Pairings found for the search term'
                    : 'No Pairings Found'
                }
                isLoading={isLoading || isFetching}
                loader={isLoading || isFetching ? <Loader /> : null}
                loaderAction={loadAction}
                shouldScrollToTop={false}
                emptyResultContainerStyle={{
                  position: 'relative',
                  minHeight: '50vh'
                }}
              >
                {pairings && pairings.length
                  ? pairings.map(pairing => {
                      return (
                        <Box key={pairing._id} mt={3}>
                          <PairingCard
                            pairing={pairing}
                            goToRound={goToRound}
                            totalPairings={getTotalPairingsCount()}
                          />
                        </Box>
                      );
                    })
                  : null}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </>
      )}
    </MaxWidthContainer>
  );
};

export default EventPairingsPage;
