/* eslint-disable no-unused-vars */
import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import CardFilter from '../../../../components/Cards/Filter';
import CardOverview from '../../CardOverview';
import FullCard from '../../../../components/Cards/FullCard';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import Loader from '../../../../components/Loading/Loader';
import { cardContainer } from './styles';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useGetCardsQuery } from '../../../../services/apis/organizePlayApi';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export const allCardsTypes = {
  deckBuilder: 'deckBuilder'
};

const AllCards = ({ cardsOnly = false, type = 'allCards', height }) => {
  const { game } = useSelector(({ app }) => app);
  const formContext = useFormContext();
  const outletContext = useOutletContext();
  const [query, setQuery] = useState({});
  const [cards, setCards] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isLoading, isFetching } = useGetCardsQuery({
    gameId: get(game, 'id', ''),
    query: { ...query }
  });

  const { activePage, totalPages } = get(data, 'pagination', {});

  // eslint-disable-next-line no-unused-vars
  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(data, 'pagination', {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    });
    if (!isLoading && !isFetching && activePage < totalPages) {
      setQuery(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  const filterAction = newQuery => {
    setQuery(newQuery);
  };

  useEffect(() => {
    const { activePage } = get(data, 'pagination', 1);
    if (data) {
      if (activePage === 1) {
        setCards(data.data);
      } else {
        setCards(prevState => [...prevState, ...data.data]);
      }
    }
  }, [data]);

  // If data is not cached, remove prev data and show loader.
  useEffect(() => {
    const page = get(query, 'page', 1);
    if (isFetching && page === 1) {
      setCards([]);
    }
  }, [isFetching]);

  const builtCards = useMemo(
    () => (
      <>
        {cards && cards.length > 0 && (
          <Box sx={cardContainer(type)}>
            {cards.map(card => {
              if (type === allCardsTypes.deckBuilder) {
                return (
                  <FullCard
                    key={card.id}
                    card={card}
                    formContext={formContext}
                    outletContext={outletContext}
                    type="deckBuilder"
                  />
                );
              }
              return <CardOverview key={card.id} card={card} />;
            })}
          </Box>
        )}
      </>
    ),
    [cards]
  );

  const createCards = (builder = false) => (
    <InfiniteScroll
      done={activePage >= totalPages || !data}
      emptyMessage={'No Cards Found'}
      isLoading={isLoading || isFetching}
      loader={<Loader />}
      loaderAction={loadAction}
      useWindow={!builder}
    >
      {builtCards}
    </InfiniteScroll>
  );

  if (cardsOnly) {
    return createCards();
  }

  if (type === allCardsTypes.deckBuilder) {
    const builderStyle = {
      height: { xs: 'unset', sm: height },
      overflow: 'scroll'
    };
    return (
      <CardFilter
        type={type}
        onChange={filterAction}
        drawerStyleOverrides={{
          height: {
            xs: 'unset',
            md: height,
            overflow: { xs: 'unset', sm: 'scroll' }
          }
        }}
      >
        <Box
          id={
            type == 'deckBuilder'
              ? 'create-deck-all-cards-container'
              : 'cards-all-cards-container'
          }
          data-testid={
            type == 'deckBuilder'
              ? 'create-deck-all-cards-container'
              : 'cards-all-cards-container'
          }
          sx={builderStyle}
        >
          {createCards(!isSmall)}
        </Box>
      </CardFilter>
    );
  }

  return (
    <CardFilter onChange={filterAction} type={type}>
      {createCards()}
    </CardFilter>
  );
};

export default AllCards;
