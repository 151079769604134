/* eslint-disable no-unused-vars */
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import PointSlider from './Slider';
import { FilterBox, Text } from './styles';
import { FilterContext } from '../Rewards';
import { useSelector } from 'react-redux';
import { productItens, tagDict } from './utils';
import CancelIcon from '@mui/icons-material/Cancel';
import { without } from 'lodash';

const FilterOptions = ({ show }) => {
  const { updateFilter, filter } = useContext(FilterContext);
  const { game } = useSelector(({ app }) => app);
  const { productTags } = game;
  const products = useMemo(() => productItens(productTags, filter), [filter]);
  const productDict = useMemo(() => tagDict(productTags), [productTags]);

  return (
    <Grid item xs={12}>
      <Collapse in={show}>
        <FilterBox>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={{ xs: 'column' }}
              gap={2}
            >
              <FormControl>
                <InputLabel htmlFor="select-tags">Tags</InputLabel>
                <Select
                  id="select-tags"
                  MenuProps={{
                    style: {
                      maxHeight: 300
                    }
                  }}
                  label="Tags"
                  value={filter.tags}
                  onChange={e => updateFilter({ tags: e.target.value })}
                  multiple
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={productDict[value].name}
                          clickable
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={event => event.stopPropagation()}
                            />
                          }
                          onDelete={e => {
                            e.preventDefault();
                            updateFilter({
                              tags: without(filter.tags, value)
                            });
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {/* {productTags.map(groupTag => (
                  <div key={groupTag.id}>
                    <ListSubheader muiSkipListHighlight>
                      {groupTag.name}
                    </ListSubheader>
                    {groupTag.tags.map(tag => (
                      <MenuItem key={tag.id} value={tag.id}>
                        <Checkbox checked={filter.tags.indexOf(tag.id) > -1} />
                        <ListItemText primary={tag.name} />
                      </MenuItem>
                    ))}
                  </div>
                ))} */}
                  {products}
                </Select>
              </FormControl>
              {/* <Text
                select
                label="Reward Type"
                value={filter.rewardType}
                name="rewardType"
                onChange={e =>
                  updateFilter({ [e.target.name]: e.target.value })
                }
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Electronics">Electronics</MenuItem>
                <MenuItem value="Appliances">Appliances</MenuItem>
              </Text>
              <Text
                label="Exclusivity Type"
                select
                value={filter.exclusivityType}
                name="exclusivityType"
                onChange={e =>
                  updateFilter({ [e.target.name]: e.target.value })
                }
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Electronics">Electronics</MenuItem>
                <MenuItem value="Appliances">Appliances</MenuItem>
              </Text> */}
            </Grid>

            <Grid item xs={12}>
              <PointSlider />
            </Grid>
          </Grid>
        </FilterBox>
      </Collapse>
    </Grid>
  );
};

export default FilterOptions;
