import { Button, Switch, styled } from '@mui/material';

export const OutlinedButton = styled(Button)`
  width: ${props => props.width};
  height: 56px;
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 78px;
  &:hover {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
  }
  &:active {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
  }
  font-family: ${props => props.theme.fontFamily1};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.primaryColor};
  text-transform: none;
  background: ${props => props.isactive && props.theme.primaryColor};
  color: ${props => props.isactive && props.theme.white};
`;

export const CoverImageUploadButton = styled(Button)`
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 353px;
  background-color: ${({ theme }) =>
    theme.colors.components.input.background}80;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.layout.text.primary};
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.components.input.background};
  }
`;

export const FooterSocialLinkButton = styled(Button)`
  max-width: 75px;
  max-height: 75px;
  min-width: unset;
  width: 100%;
  padding: 8px;
  background-color: ${props => props.theme.opacity6};
  border-radius: 20px;
  &:hover {
    background: ${props => props.theme.opacity4};
  }
`;

export const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const ToggleButton = styled(Button)`
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 8px 24px;
  gap: 10px;
  width: 100%;
  height: 64px;
  background: ${({ theme }) => theme.palette.primary.main}1A;
  border-radius: 16px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main}CC;
  }

  &.active-button {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
`;

export const UpComingButton = styled(Button)`
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 8px 24px;
  gap: 10px;
  width: 100%;
  height: 64px;
  background: ${({ theme }) => theme.palette.primary.main}1A;
  border-radius: 16px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main}CC;
  }

  &.active-button {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
`;

export const PastButton = styled(Button)`
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 8px 24px;
  gap: 10px;
  width: 100%;
  height: 64px;
  background: ${({ theme }) => theme.palette.primary.main}1A;
  border-radius: 16px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main}CC;
  }

  &.active-button {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
`;

export const PrimaryButton = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 126.21px;
  height: 45px;
  background: ${props => props.theme.palette.primary.main};
  border-radius: 50px;

  &:hover {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
  }

  &:active {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.white};
  }

  font-family: ${props => props.theme.fontFamily1};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${props => props.theme.palette.text.primary};
  text-transform: none;
`;

export const OtherButton = styled(Button)`
  padding: 8px 31px 8px 30px;
  gap: 10px;
  width: 166px;
  height: 36px;
  background: ${props =>
    props.ischeckedin ? props.theme.green : props.theme.red};
  border-radius: 60px;

  &:hover {
    background: ${props =>
      props.ischeckedin ? props.theme.green : props.theme.red};
    color: ${props => props.theme.white};
  }

  &:active {
    background: ${props =>
      props.ischeckedin ? props.theme.green : props.theme.red};
    color: ${props => props.theme.white};
  }

  font-family: ${props => props.theme.fontFamily1};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${props => props.theme.white};
  text-transform: none;
`;
