import React from 'react';
import { get } from 'lodash';
import { Navigate, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { buildRoutePath } from '../../../utils/routingUtils';

const FeaturedRoute = ({ feature, children }) => {
  const featureFlags = useFlags();
  const { gameSlug } = useParams();

  if (!get(featureFlags, feature)) {
    return <Navigate to={buildRoutePath(gameSlug, '/')} />;
  }

  return children;
};

export default FeaturedRoute;
