import { Tab, Tabs, styled } from '@mui/material';

/* eslint-disable no-unused-vars */
import React from 'react';

export const StyledTabsRoot = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    height: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: 'transparent',
    '& > span': {
      backgroundColor: theme.palette.primary.main,
      width: '70%'
    }
  }
}));

export const StyledTabRoot = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '16px 32px',
  overflow: 'visible',
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&:before': {
      content: "''",
      top: '-8px',
      left: '-24px',
      position: 'absolute',
      height: 'calc(2*16px)',
      width: '16px',
      backgroundColor: theme.palette.background.paper,
      borderTopLeftRadius: '16px',
      boxShadow: `${theme.palette.background.default} 0px -12px 0px 0px`,
      transform: 'rotate(90deg)'
    },
    '&:after': {
      content: "''",
      top: '0',
      right: '-16px',
      position: 'absolute',
      height: 'calc(2*16px)',
      width: '16px',
      backgroundColor: theme.palette.background.paper,
      borderBottomRightRadius: '16px',
      boxShadow: `${theme.palette.background.default} 0px 12px 0px 0px`,
      transform: 'rotate(180deg)'
    }
  }
}));

export const StyledTabs = props => {
  const { children, ...restProps } = props;
  return (
    <StyledTabsRoot
      {...restProps}
      TabIndicatorProps={{
        children: <span />
      }}
    >
      {children}
    </StyledTabsRoot>
  );
};

export const StyledTab = props => {
  return <StyledTabRoot {...props} />;
};
