import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import SimpleModalCard from '../../../components/Modal/Cards/SimpleModalCard';
import {
  UpdateUserTypes,
  useUpdateUserInfo
} from '../../../hooks/user/useUpdateUserInfo';
import FormTextfield from '../../../components/Form/FormTextfield';
import FormAddressInfo from '../../../components/Form/FormAddressInfo';
import { useModalContext } from '../../../hooks/modal/useModalContext';

const ShippingAddressUpdateModal = ({ onCloseClick, message }) => {
  const { methods, handleSave, updateUserRes } = useUpdateUserInfo({
    omitGameUser: true,
    updateUserType: UpdateUserTypes.ShippingAddress
  });
  const { isLoading, isSuccess } = updateUserRes;
  const { closeModal, setIsLoading } = useModalContext();

  useEffect(() => {
    setIsLoading(isLoading);
    if (!isLoading && isSuccess) {
      closeModal();
    }
  }, [updateUserRes]);

  return (
    <form onSubmit={methods.handleSubmit(handleSave)}>
      <SimpleModalCard
        title="Update Shipping Address"
        onClose={onCloseClick}
        confirmButton={{
          title: 'Continue',
          type: 'submit'
        }}
      >
        <Grid container flexDirection="column" gap={4}>
          {message ? <Typography>{message}</Typography> : null}

          <FormTextfield
            data-testid="update-shipping-address-modal-first-name"
            name={'profile.firstName'}
            formMethods={methods}
            label={'First Name*'}
          />

          <FormTextfield
            data-testid="update-shipping-address-modal-last-name"
            name={'profile.lastName'}
            formMethods={methods}
            label={'Last Name'}
          />

          <FormAddressInfo name={'location'} formMethods={methods} />
        </Grid>
      </SimpleModalCard>
    </form>
  );
};

export default ShippingAddressUpdateModal;
