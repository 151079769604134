import React from 'react';
import { get } from 'lodash';
import { Box } from '@mui/material';
import { GameCardImage } from '../../../Shared/Images';

const DeckStackOfCards = ({ cards = [], paddingValue = 4 }) => {
  if (cards.length <= 0) {
    return <></>;
  }
  return (
    <Box
      className={'deck-cards'}
      sx={{
        paddingTop: `${cards?.length * paddingValue}%`,
        paddingLeft: `${cards?.length * paddingValue}%`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          '*': { gridRow: 1, gridColumn: 1 }
        }}
      >
        {cards?.map((card, index) => (
          <Box
            key={card._id}
            style={{
              marginLeft: `${index * -paddingValue}%`,
              marginTop: `${index * -paddingValue}%`,
              marginRight: `${index * paddingValue}%`
            }}
          >
            <GameCardImage
              className={'deck-card-preview'}
              imageurl={get(card, 'images.small')}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DeckStackOfCards;
