import { Box, Typography, useTheme } from '@mui/material';
import { get, isEmpty } from 'lodash';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '../../Shared/Tooltip';

const iconStyle = {
  backgroundColor: '#000000',
  borderRadius: '50%',
  boxShadow: '0 0 6px 0px #a5a5a5'
};

export const DeckValidationStatus = ({ deck, iconOnly = false }) => {
  const isValid = isEmpty(get(deck, 'errors', {}));
  const theme = useTheme();

  if (isValid) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        my="6px"
      >
        {iconOnly ? (
          <Tooltip title="Valid">
            <CheckCircleIcon color="success" fontSize="medium" sx={iconStyle} />
          </Tooltip>
        ) : (
          <CheckCircleOutlineIcon color="success" fontSize="small" />
        )}
        {!iconOnly && (
          <Typography
            className={'deck-status'}
            ml="4px"
            color={theme.palette.success.main}
            variant="subtitle3"
          >
            Valid
          </Typography>
        )}
      </Box>
    );
  }
  if (iconOnly) {
    return (
      <Tooltip
        info={'Invalid'}
        icon={
          <ErrorIcon
            className={'error-icon'}
            color={'error'}
            fontSize={'small'}
          />
        }
      />
    );
  }
  return (
    <Typography
      className={'deck-status'}
      my="6px"
      display="inline-block"
      color={theme.palette.primary.main}
      variant="subtitle3"
    >
      Invalid
    </Typography>
  );
};
