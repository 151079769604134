import React from 'react';
import { IconButton, Slide, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

const Notification = ({ handleClose, isOpen, message }) => {
  const theme = useTheme();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      message={message}
      TransitionComponent={props => <Slide {...props} direction="up" />}
      sx={{
        borderRadius: 4,
        pointerEvents: isOpen ? 'initial' : 'none',
        '.MuiSnackbarContent-root': {
          backgroundColor: theme.palette.primary.main,
          display: 'flex',
          flexDirection: 'row'
        },
        '.MuiSnackbarContent-message': {
          width: '90%'
        }
      }}
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default Notification;
