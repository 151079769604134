import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { debounce, find, get, isUndefined } from 'lodash';
import {
  usePostAddToCartMutation,
  usePostRemoveFromCartMutation
} from '../../../../services/apis/organizePlayApi';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SimpleModalCard from '../../../../components/Modal/Cards/SimpleModalCard';
import { toast } from 'react-toastify';
import { useIsLoggedIn } from '../../../../hooks/auth0/useLoggedIn';
import { useLogin } from '../../../../hooks/auth0/useLogin';
import { useSelector } from 'react-redux';
import { getProductLimit } from '../../../../utils/products/getProductLimit';
import { useModalContext } from '../../../../hooks/modal/useModalContext';
import { AddItemButton, StyledQuantity, StyledQuantityButton } from './styles';
import { getPrice } from '../../../../utils/products/getPrice';
import { getMaxQty } from '../../../../utils/products/getMaxQty';

const AddToCartButton = ({
  product,
  cartDetails,
  isForSingleVariant = true,
  inventoryId = null,
  currencyId,
  shouldDisable = false
}) => {
  // eslint-disable-next-line no-unused-vars
  const [qty, setQty] = useState(0);
  const [isCartUpdating, setCartIsUpdating] = useState(false);
  const { addModal } = useModalContext();
  const { login } = useLogin();
  const isUserLoggedIn = useIsLoggedIn();
  const { selectedStoreCurrency, game } = useSelector(({ app }) => app);
  const usedCurrencyId = currencyId || selectedStoreCurrency;
  // eslint-disable-next-line no-unused-vars
  const currencies = get(game, 'pgGame.currencies');

  const [addToCart, addToCartResult] = usePostAddToCartMutation();

  const [
    removeFromCart,
    removeFromCartResult
  ] = usePostRemoveFromCartMutation();

  const getInventory = () => {
    if (isForSingleVariant && !inventoryId) {
      return get(product, 'inventory[0]');
    } else {
      const foundInventory = find(
        get(product, 'inventory', []),
        inv => inv.id === inventoryId
      );
      return foundInventory;
    }
  };

  const inventory = getInventory();
  const prices = get(inventory, 'productPrices', []);
  const { productPrice } = getPrice(prices, currencies, usedCurrencyId);

  // eslint-disable-next-line no-unused-vars
  const { purchases, limit, currentLimit, timeframe } = getProductLimit(
    {
      ...product,
      ...(inventoryId
        ? {
            inventory: product?.inventory?.find(
              item => item?.id === inventoryId
            )
          }
        : {})
    },
    usedCurrencyId
  );

  const isDisabled =
    !isUserLoggedIn ||
    isUndefined(
      get(
        cartDetails,
        `cart.items.${product?.id}.${inventory?.id}.${productPrice?.id}`
      )
    );

  const showLimit = (limit && isForSingleVariant) || (limit && inventoryId);

  const maxQty = getMaxQty(currentLimit, inventory);

  const navigateToLogin = () => {
    login();
  };

  const openLoginPrompt = () => {
    addModal({
      children: (
        <SimpleModalCard
          title={'Please Login/Register'}
          cancelButton={{ id: 'delete-deck-cancel-button' }}
          confirmButton={{
            id: 'delete-deck-confirm-button',
            onClick: () => navigateToLogin(),
            title: 'Login/Register',
            color: 'error'
          }}
        >
          <Typography textAlign={'center'} typography={'h4'}>
            Please login or Register to checkout products
          </Typography>
        </SimpleModalCard>
      )
    });
  };

  const updateItemCart = (add = true) => {
    if (!isCartUpdating) {
      if (isUserLoggedIn) {
        setCartIsUpdating(true);
        const body = {
          productId: product.id,
          inventoryId: getInventory().id,
          productPriceId: productPrice?.id,
          quantity: 1
        };
        if (add) {
          addToCart({ body });
        } else {
          removeFromCart({ body });
        }
      } else {
        openLoginPrompt();
      }
    }
  };

  const removeItemFromCart = () => updateItemCart(false);

  // Setting qty
  useEffect(() => {
    if (cartDetails) {
      const newQty = get(
        cartDetails,
        `cart.items.${product?.id}.${inventory?.id}.${productPrice?.id}`,
        0
      );
      if (newQty !== qty) {
        setQty(newQty);
      }
    }
  }, [cartDetails, inventory, productPrice, product]);

  // Success Adding item from cart message.
  useEffect(() => {
    if (addToCartResult.isSuccess) {
      toast.success(`${product?.name} added to cart`, {
        toastId: product?.id + 'add',
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, [addToCartResult]);

  // Success Removing item from cart message.
  useEffect(() => {
    if (removeFromCartResult.isSuccess) {
      toast.success(`${product?.name} removed from cart`, {
        toastId: product?.id + 'remove',
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }, [removeFromCartResult]);

  const setNotUpdating = debounce(() => {
    setCartIsUpdating(false);
  }, 300);

  useEffect(() => {
    setNotUpdating.cancel();
    const cartNotUpdating =
      !removeFromCartResult?.isLoading && !addToCartResult?.isLoading;
    if (cartNotUpdating) {
      setNotUpdating.cancel();
      setNotUpdating();
    }
  }, [addToCartResult, removeFromCartResult]);

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'center', sm: 'start' }}
        justifyContent={'center'}
        spacing={2}
        mt={2}
      >
        {/* Quantity changer */}
        {qty > 0 && (
          <Grid item>
            <StyledQuantityButton>
              <Button
                data-testid="remove-from-cart-btn"
                variant="contained"
                disabled={isDisabled}
                className="cart-quantity-minus"
                onClick={removeItemFromCart}
              >
                <RemoveIcon />
              </Button>
              <StyledQuantity
                className={isDisabled ? 'disabled' : ''}
                data-testid="item-quantity"
              >
                {qty}
              </StyledQuantity>
              <Button
                data-testid="rewards-add-to-cart-button"
                variant="contained"
                disabled={isDisabled || qty >= maxQty}
                className="cart-quantity-plus"
                onClick={updateItemCart}
              >
                <AddIcon />
              </Button>
            </StyledQuantityButton>
          </Grid>
        )}

        {/* Add to cart button */}
        {qty === 0 && (
          <Grid item>
            <AddItemButton
              data-testid="rewards-add-to-cart-button"
              variant="contained"
              disabled={shouldDisable || qty >= maxQty || purchases >= limit}
              onClick={updateItemCart}
            >
              Add to Cart
            </AddItemButton>
          </Grid>
        )}
      </Grid>
      {showLimit && (
        <Typography
          textAlign={'center'}
          sx={{ my: 2, textTransform: 'capitalize' }}
          variant="body2"
        >
          {`${timeframe} Limit of ${limit}`}
        </Typography>
      )}
    </Grid>
  );
};

export default AddToCartButton;
