import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  useGetEntityOrganizersQuery,
  usePostUpdateEntityUsersMutation
} from '../../../../../../services/apis/organizePlayApi';

import AddOrganizerModal from './AddOrganizerModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../../../components/Loading/Loader';
import { LoadingButton } from '@mui/lab';
import { ModalContext } from '../../../../../../components/Modal/Context/ModalContext';
import OrganizerListItem from './OrganizerListItem';
import SimpleModalCard from '../../../../../../components/Modal/Cards/SimpleModalCard';
import { toast } from 'react-toastify';
import { usePagination } from '../../../../../../hooks/pagination/usePagination';

const StoreOrganizers = ({ storeData, gameId }) => {
  const { addModal, closeModal, setIsLoading } = useContext(ModalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeletingOrganizer, setIsDeletingOrganizer] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const {
    data: entityOrganizers,
    isLoading,
    isFetching
  } = useGetEntityOrganizersQuery(
    { storeId: storeData?._id, gameId, page: currentPage, limit: 5 },
    { skip: !gameId || !storeData?._id }
  );
  const { treatedData, loadAction, nextPage, totalResults } = usePagination({
    data: entityOrganizers,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });
  const [
    deleteOrganizers,
    deleteOrganizersResult
  ] = usePostUpdateEntityUsersMutation();

  useEffect(() => {
    setIsLoading(deleteOrganizersResult.isLoading);
    setIsDeletingOrganizer(deleteOrganizersResult.isLoading);
    if (deleteOrganizersResult?.isSuccess) {
      closeModal();
      setSelectedUserIds([]);
      toast.success('Organizers removed successfully!');
    }
    if (deleteOrganizersResult?.isError) {
      toast.error('Failed to remove organizers!');
    }
  }, [deleteOrganizersResult]);

  const openAddOrganizersModal = () => {
    addModal({
      children: <AddOrganizerModal storeData={storeData} gameId={gameId} />
    });
  };
  const onOrganizerUserIdSelect = userId => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };
  const isOrganizerUserIdSelected = userId => {
    return selectedUserIds.includes(userId);
  };
  const onOrganizerUserIdDelete = userId => {
    deleteOrganizers({
      storeId: storeData._id,
      gameId,
      body: {
        userIds: [userId],
        operation: 'remove'
      }
    });
  };
  const onDeleteConfirmClick = () => {
    setIsLoading(true);
    deleteOrganizers({
      storeId: storeData._id,
      gameId,
      body: {
        userIds: selectedUserIds,
        operation: 'remove'
      }
    });
  };
  const openDeleteConfirm = () => {
    addModal({
      children: (
        <SimpleModalCard
          title={'Remove organizers?'}
          cancelButton={{ id: 'delete-organizer-cancel-button' }}
          confirmButton={{
            id: 'delete-organizer-confirm-button',
            onClick: () => onDeleteConfirmClick(),
            title: 'Remove',
            color: 'error'
          }}
        >
          <Typography textAlign={'center'} typography={'h4'}>
            Are you sure?
          </Typography>
        </SimpleModalCard>
      ),
      style: { maxWidth: '500px' }
    });
  };
  return (
    <Grid container spacing={1}>
      {treatedData?.length ? (
        <Grid item xs={12}>
          <Grid container>
            {/* header starts */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8} sm={5}>
                  <Typography variant="body3Light">
                    Organizer&apos;s name
                  </Typography>
                </Grid>
                <Grid item sm={2} display={{ xs: 'none', sm: 'initial' }}>
                  <Typography variant="body3Light">Role</Typography>
                </Grid>
                <Grid item xs={3} sm={2}>
                  <Typography variant="body3Light">Added On</Typography>
                </Grid>
                <Grid item sm={2} display={{ xs: 'none', sm: 'initial' }}>
                  <Typography variant="body3Light">Last Updated</Typography>
                </Grid>
                <Grid item xs={1} sm={1}>
                  &nbsp;
                </Grid>
              </Grid>
            </Grid>
            {/* header ends */}
            {/* data starts */}
            <Grid
              item
              xs={12}
              sx={{ minHeight: '30vh', maxHeight: '30vh', overflowY: 'scroll' }}
              id="organizerListScrollableTarget"
            >
              <InfiniteScroll
                dataLength={totalResults}
                next={loadAction}
                hasMore={nextPage !== 0 && totalResults !== treatedData?.length}
                loader={<Loading />}
                scrollableTarget="organizerListScrollableTarget"
                scrollThreshold={0.9}
              >
                {treatedData.map((organizer, index) => (
                  <OrganizerListItem
                    key={organizer._id}
                    organizer={organizer}
                    itemIndex={index}
                    onSelect={onOrganizerUserIdSelect}
                    isSelected={isOrganizerUserIdSelected(organizer.user._id)}
                    onDelete={onOrganizerUserIdDelete}
                    isDeleting={isDeletingOrganizer}
                  />
                ))}
              </InfiniteScroll>
            </Grid>
            {/* data ends */}
          </Grid>
        </Grid>
      ) : null}
      {!treatedData || !treatedData.length ? (
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1">No Organizers added yet!</Typography>
        </Grid>
      ) : null}
      <Grid item xs={12} textAlign="end" mt={4}>
        {selectedUserIds && selectedUserIds.length > 0 ? (
          <LoadingButton
            variant="text"
            color="error"
            disabled={isDeletingOrganizer}
            onClick={openDeleteConfirm}
            loading={isDeletingOrganizer}
          >
            Remove Organizers
          </LoadingButton>
        ) : null}
        <Button variant="outlined" onClick={openAddOrganizersModal}>
          Add Organizers
        </Button>
      </Grid>
    </Grid>
  );
};

export default StoreOrganizers;
