import { Backdrop, Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import { modalContainerStyle, modalPaperStyle } from './styles';

import MuiModal from '@mui/material/Modal';
import React from 'react';

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const Modal = ({
  children,
  isOpen,
  handleClose,
  handleReset,
  style,
  scrollVisible = true,
  compactOnSmallDevice = false
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MuiModal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100
      }}
    >
      <Fade in={isOpen} onExited={handleReset}>
        <Paper
          sx={{
            ...modalPaperStyle,
            ...style,
            ...(isSmall && compactOnSmallDevice && { padding: 0 })
          }}
        >
          <Box sx={theme => modalContainerStyle({ theme, scrollVisible })}>
            {children}
          </Box>
        </Paper>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
