import { Box, TextField, styled } from '@mui/material';

export const Text = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('sm')]: {
      top: '15%'
    },
    [theme.breakpoints.up('sm')]: {
      top: 0
    }
  },

  '& .MuiInputLabel-root.Mui-focused': {
    top: 0
  }
}));

export const FilterBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  border: `${theme.border.borderWidth}px solid ${theme.border.borderColor}`,
  borderRadius: `${theme.border.borderRadius}px`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  }
}));
