import {
  Grid,
  Paper,
  Popover,
  Typography,
  styled,
  useMediaQuery
} from '@mui/material';

import React from 'react';
import firstPlaceIcon from '../../../assets/rewards/first.svg';
import { get } from 'lodash';
import secondPlaceIcon from '../../../assets/rewards/second.svg';
import thirdPlaceIcon from '../../../assets/rewards/third.svg';
import top16Icon from '../../../assets/rewards/top16.svg';
import top8Icon from '../../../assets/rewards/top8.svg';

export const GeneralEventCard = styled(Grid)`
  background: ${({ theme }) => theme.layout.backgrounds.block};
  padding: 24px;
  border-radius: 16px;
  position: relative;
  width: 22%;

  &.eventSectionRight {
    background: ${({ theme }) => theme.palette.neutral['200']}1A;
  }
`;

const RewardCard = ({ reward, totalRewards }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const desktopOnly = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const prizing = {
    1: firstPlaceIcon,
    2: secondPlaceIcon,
    3: thirdPlaceIcon,
    8: top8Icon,
    16: top16Icon,
    32: top16Icon,
    default: top16Icon
  };

  const placingName = {
    1: 'Champion',
    2: '2nd Place',
    3: '3rd Place',
    8: 'Top 8',
    16: 'Top 16',
    32: 'Top 32',
    default: 'Top 16'
  };

  return (
    <GeneralEventCard
      mb={2}
      sx={{
        width: {
          xs: '100%',
          sm: '48%',
          md: '30%',
          lg: `${100 / totalRewards - 2}%`
        }
      }}
    >
      <Grid container justifyContent="center" sx={{ height: '100%' }}>
        <Grid container justifyContent="center">
          <Typography
            sx={{
              mb: get(reward, 'imageUrl') ? 2 : 0,
              textAlign: 'center',
              width: '100%'
            }}
            variant="h4"
          >
            {placingName[get(reward, 'standingsInfo.placing', 'default')]}
          </Typography>
          <img
            src={prizing[get(reward, 'standingsInfo.placing', 'default')]}
            height={'80px'}
          />
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          {get(reward, 'imageUrl') ? (
            <>
              <img
                src={get(reward, 'imageUrl', '')}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                width={'80%'}
              />
              <Popover
                id="rankings-reward-mouse-over-popover"
                data-testid="rankings-reward-mouse-over-popover"
                sx={{
                  pointerEvents: 'none'
                }}
                open={open && desktopOnly}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                PaperProps={{
                  sx: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                  }
                }}
                disableRestoreFocus
              >
                <Paper
                  sx={{
                    borderRadius: '24px',
                    boxShadow: '0 0 24px 0 #FFFFFF20',
                    height: '100%',
                    m: 4,
                    p: 2,
                    width: {
                      sx: '80vw',
                      md: '500px'
                    }
                  }}
                >
                  <Grid alignItems="center" justifyContent="center" container>
                    <Typography
                      sx={{
                        mb: get(reward, 'imageUrl') ? 2 : 0,
                        textAlign: 'center',
                        width: '100%'
                      }}
                      variant="h4"
                    >
                      {
                        placingName[
                          get(reward, 'standingsInfo.placing', 'default')
                        ]
                      }
                    </Typography>
                    <img
                      src={
                        prizing[get(reward, 'standingsInfo.placing', 'default')]
                      }
                      height={'100px'}
                    />
                    <Typography
                      sx={{ mb: 2, mt: 2, textAlign: 'center', width: '100%' }}
                      variant="body2"
                    >
                      {reward.name}
                    </Typography>
                    <img src={get(reward, 'imageUrl', '')} width={'60%'} />
                  </Grid>
                </Paper>
              </Popover>
            </>
          ) : (
            <Typography
              sx={{ mb: 2, mt: 2, textAlign: 'center', width: '100%' }}
              variant="body2"
            >
              {reward.name}
            </Typography>
          )}
        </Grid>
      </Grid>
    </GeneralEventCard>
  );
};

export default RewardCard;
