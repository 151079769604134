import { Grid } from '@mui/material';
import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const SingleStoreHeader = ({ imageSrc = '' }) => {
  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const dummyHeader =
    imageSrc ||
    `https://storage.googleapis.com/cardeio-images/defaults/${game?.slug}/events/1.webp`;

  return (
    <Grid
      xs={12}
      alignItems="flex-end"
      justifyContent="center"
      container
      sx={{
        height: '400px',
        position: 'absolute',
        zIndex: -1,
        width: '100vw'
      }}
    >
      <Grid
        sx={{
          backgroundImage: `url(${dummyHeader})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          height: '400px',
          opacity: 0.7,
          position: 'absolute',
          top: 0,
          width: '100vw',
          zIndex: 0
        }}
      />
      <Grid
        sx={{
          backgroundImage: `linear-gradient(180deg, rgba(29, 29, 29, 0), ${get(
            theme,
            'palette.background.default'
          )})`,
          height: '400px',
          top: 0,
          position: 'absolute',
          width: '100vw',
          zIndex: 0
        }}
      />
    </Grid>
  );
};

export default SingleStoreHeader;
