import { Box, Button, Grid, Typography } from '@mui/material';
import { Navigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { emailIconStyle, verifyEmailStyle } from './styles';

import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useCustomNavigation } from '../../hooks/navigation/useCustomNavigation';
import { useLogout } from '../../hooks/auth0/useLogout';
import { usePostResendVerifyEmailMutation } from '../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const VerifyEmail = () => {
  const { game } = useSelector(({ app }) => app);
  const [resendEmail, resendEmailResult] = usePostResendVerifyEmailMutation();
  const [searchParams] = useSearchParams();
  const { buildSubPath } = useCustomNavigation();
  const userId = searchParams.get('user_id');
  const email = searchParams.get('email');
  const state = searchParams.get('state');

  const { logoutAction } = useLogout();

  const sendEmailVerification = () => {
    userId &&
      resendEmail({
        body: {
          user_id: userId,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
        }
      });
  };

  useEffect(() => {
    if (
      !resendEmailResult.isLoading &&
      resendEmailResult.isSuccess &&
      !resendEmailResult?.data?.errorMessage
    ) {
      toast.success('Email Sent!');
    }
  }, [resendEmailResult]);

  if (!userId || !email || !state) {
    return <Navigate to={buildSubPath()} />;
  }

  return (
    <MaxWidthContainer className={'p-top'} container justifyContent={'center'}>
      <Grid item>
        <Box sx={verifyEmailStyle}>
          <EmailIcon sx={emailIconStyle} />

          <Typography variant={'h2'} textAlign={'center'} gutterBottom>
            Verify Your Email
          </Typography>

          <Box className={'verify-email-text-container'}>
            <Typography>
              Welcome to {get(game, 'titles.op', '')}! To ensure your account
              security and grant full access to our platform, we need you to
              verify your email address.
            </Typography>
            <Typography>
              The verification email has been sent to the following email
              address: <span className={'user-email'}>{email || ''}</span>
            </Typography>
            <Typography>
              Please check your inbox (including spam folder) for an email from
              us containing a verification link. Clicking on the link will
              complete the verification process.
            </Typography>

            <Typography variant={'body2'} fontWeight={'bold'} gutterBottom>
              * Please note that the verification link or instructions are
              time-sensitive and will expire after a certain period. If the
              verification link expires, you may need to request another email
              verification.
            </Typography>
          </Box>

          <LoadingButton
            loading={resendEmailResult.isLoading}
            id="verify-email-resend-email-button"
            data-testid="verify-email-resend-email-button"
            onClick={sendEmailVerification}
            variant={'contained'}
          >
            Resend Email
          </LoadingButton>
          <Button
            id="verify-email-logout-button"
            data-testid="verify-email-logout-button"
            variant={'text'}
            onClick={() => logoutAction()}
          >
            If not user, logout
          </Button>
        </Box>
      </Grid>
    </MaxWidthContainer>
  );
};

export default VerifyEmail;
