import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { buildRoutePath } from '../../utils/routingUtils';

const DecksMainPage = () => {
  const { deckBuilder } = useFlags();
  const { gameSlug } = useParams();

  if (!deckBuilder) {
    return <Navigate to={buildRoutePath(gameSlug, '/')} />;
  }

  return <Outlet />;
};

export default DecksMainPage;
