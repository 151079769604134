import { Grid, styled } from '@mui/material';

export const GeneralCard = styled(Grid)`
  background: ${({ theme }) => theme.layout.backgrounds.block};
  border-radius: 8px;
  position: relative;

  &.border-16 {
    border-radius: 16px;
  }
`;

export const EventRowCard = styled(GeneralCard)`
  padding: 12px;
`;

export const EventPlayersScoreCard = styled(GeneralCard)`
  border-radius: 32px;
  background: ${props => props.theme.palette.grey['800']};
  padding: 4px;
  width: fit-content;
`;

export const EventRecordCard = styled(GeneralCard)`
  background: ${({ theme }) => theme.events.cardSecondaryColor};
  border-radius: 4px;
  padding: 4px 16px;
`;

export const TubeCard = styled(GeneralCard)`
  background: ${({ status, theme }) =>
    status === 'active'
      ? theme.palette.success.main
      : status === 'dropped'
      ? theme.palette.error.main
      : theme.events.cardSecondaryColor};
  border-radius: 24px;
  display: flex;
  padding: 8px 24px;
  text-align: center;

  &.product-status {
    pointer-events: none;
    position: absolute;
    top: ${({ theme }) => theme.spacing(1)};
    right: ${({ theme }) => theme.spacing(1)};
    background: ${({ status, theme }) => {
      switch (status) {
        case 'sold-out':
          return theme.palette.error.main;
        case 'limited':
          return theme.palette.secondary.main;
      }
    }};
    color: ${({ status }) => {
      switch (status) {
        case 'sold-out':
          return 'white';
      }
    }};
  }
`;
