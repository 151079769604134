import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { getDeckCardCount } from '../../../../utils/deck';
import { useOutletContext } from 'react-router-dom';
import { get } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { MetricsContext } from '..';
import { darkenColor } from '../utils';

const getMainDeckSectionIndex = (deck, deckFormats) => {
  const sections = get(deck, 'data.deck.sections', []);
  const mainSectionFormat = deckFormats
    .map(format => ({
      format,
      mainSection: format.deckType?.deckSections.find(
        section => section.type === 'mainboard'
      )
    }))
    .find(item => item.mainSection);
  const mainSection = mainSectionFormat?.mainSection;
  return mainSection
    ? sections.findIndex(sect => sect.deckSectionId === mainSection._id) || 0
    : 0;
};

const DeckCardCounter = () => {
  const { deck, deckFormats } = useOutletContext();
  const { edit, backgroundPaper } = useContext(MetricsContext);
  let cards, sections;
  let cardCount = 0;

  if (edit) {
    const mainIdx = getMainDeckSectionIndex(deck, deckFormats);
    const cardsPath = 'sections.' + mainIdx + '.cards';
    const formContext = useFormContext();
    const { control } = formContext || {};
    cards = useWatch({
      control,
      name: cardsPath
    });
    cardCount = getDeckCardCount(cards);
  } else {
    sections = get(deck, 'data.deck.sections', []);
    sections.forEach(section => {
      cardCount =
        cardCount < section?.cardCount ? section.cardCount : cardCount;
    });
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `1px solid ${darkenColor(backgroundPaper, -10)}`,
        padding: 2,
        borderRadius: '20px',
        backgroundColor: darkenColor(backgroundPaper, 5)
      }}
    >
      <Typography variant="h3">{cardCount}</Typography>
      <Typography variant="h4">Cards</Typography>
    </Box>
  );
};

export default DeckCardCounter;
