import { Box, Grid, Stack, ToggleButton, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  StyledToggleButtonGroup,
  ToggleButtonContainer,
  UserBox
} from './styles';
import { get, isEmpty } from 'lodash';
import {
  useGetUserWalletsQuery,
  useLazyGetUserWalletTransactionsGraphQuery
} from '../../../services/apis/organizePlayApi';

import CustomLineChart from '../../Shared/Charts/CustomLineChart';
import PercentageRadialChart from '../../Shared/Charts/PercentageRadialChart';
import ProfileImageNew from '../../User/ProfileImageNew';
import WalletBalanceTooltip from './WalletBalanceTooltip/index';
import dayjs from 'dayjs';
import { useIsLoggedIn } from '../../../hooks/auth0/useLoggedIn';
import { useSelector } from 'react-redux';

const RewardsUserInfo = () => {
  const { game, selectedStoreCurrency, user } = useSelector(({ app }) => app);
  const [selectedChartType, setSelectedChartType] = useState('last30days');
  const isLoggedIn = useIsLoggedIn();
  const { data: userWalletsData } = useGetUserWalletsQuery('', {
    skip: !isLoggedIn
  });
  const endDate = new Date().toISOString();
  const startDate = dayjs(new Date())
    .subtract(90, 'days')
    .startOf('day')
    .toDate()
    .toISOString();
  const [
    getTransactions,
    transactionsData
  ] = useLazyGetUserWalletTransactionsGraphQuery();
  const firstName = get(user, 'details.info.firstName', '');
  const lastName = get(user, 'details.info.lastName', '');
  const rankName = get(user, 'details.gameUser.rankName', '');
  const teamName = get(user, 'details.gameUser.teamName', '');

  const gameCurrencies = get(game, 'pgGame.currencies', []);
  const activeCurrency = gameCurrencies.find(
    currency => currency.id === selectedStoreCurrency
  );
  const getWallet = () =>
    get(userWalletsData, `currencyIdToWallet.${selectedStoreCurrency}`, {});
  const [activeWallet, setActiveWallet] = useState(getWallet());

  const balance = activeWallet?.balance || 0;
  const lifetimeBalance =
    (balance / (transactionsData?.data?.totals?.eventAwards || 1)) * 100;
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const wallet = getWallet();
    if (!isEmpty(wallet) && activeWallet.id !== wallet.id) {
      setActiveWallet(getWallet());
    }
  }, [selectedStoreCurrency, userWalletsData]);

  useEffect(() => {
    if (!isEmpty(activeWallet)) {
      getTransactions({ id: activeWallet.id, startDate, endDate });
    }
  }, [activeWallet]);

  useLayoutEffect(() => {
    setHeight(get(ref, 'current.offsetHeight'));
  }, []);

  const handleChartTypeChange = value => {
    if (value) {
      setSelectedChartType(value);
    }
  };

  const transactions = get(
    transactionsData,
    `data.transactionGraphInfo.${selectedChartType}`,
    []
  );

  if (user.details) {
    return (
      <Grid container spacing={4} item xs={12}>
        <Grid item xs={12} md={3}>
          <UserBox
            sx={{
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
              height: '100%',
              paddingTop: 4
            }}
          >
            <ProfileImageNew />
            <Stack alignItems="center">
              {rankName && <Typography variant="body1">{rankName}</Typography>}
              {firstName && (
                <Typography
                  variant="h4"
                  data-testid="rewards-user-name"
                >{`${firstName} ${lastName}`}</Typography>
              )}
              {teamName && <Typography variant="body1">{teamName}</Typography>}
            </Stack>
            <Typography variant="body2" data-testid="lifetime-coin-balance">
              {`Lifetime ${get(activeCurrency, 'symbol')}: ${transactionsData
                ?.data?.totals?.eventAwards || 0}`}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <PercentageRadialChart
                  amountValue={balance}
                  value={
                    transactionsData?.data?.totals?.eventAwards
                      ? lifetimeBalance
                      : 100
                  }
                  labelText={get(activeCurrency, 'symbol')}
                />
              </Grid>
            </Grid>
          </UserBox>
        </Grid>
        <Grid item xs={12} md={9}>
          <UserBox
            sx={{
              height: '100%',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: `${
                transactions.length > 0 ? 'space-between' : 'flex-start'
              }`,
              minHeight: { xs: '200px' }
            }}
            ref={ref}
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography
                  color="text.secondary"
                  variant="h3"
                  textAlign={{ xs: 'center', sm: 'start' }}
                >
                  {get(activeCurrency, 'name')} Balance
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} mt={{ xs: 2, sm: 0 }}>
                <ToggleButtonContainer
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                >
                  <StyledToggleButtonGroup
                    value={selectedChartType}
                    exclusive
                    onChange={(_, value) => handleChartTypeChange(value)}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="last90days"
                      aria-label="Last 90 days"
                      data-testid="balance-history-last-90-days"
                    >
                      Last 90 Days
                    </ToggleButton>
                    <ToggleButton
                      value="last30days"
                      aria-label="Last 30 days"
                      data-testid="balance-history-last-30-days"
                    >
                      Last 30 Days
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </ToggleButtonContainer>
              </Grid>
            </Grid>
            {transactions.length ? (
              <CustomLineChart
                dataKey={'balance'}
                data={transactions}
                isLoading={true}
                domain={get(
                  transactionsData,
                  'data.transactionGraphInfo.minMax',
                  [0, 70]
                )}
                height={height * 0.75}
                removeOverflow
                xInterval={
                  transactions.length > 20
                    ? Math.max(1, Math.floor(transactions.length / 10))
                    : 0
                }
                mobileHideText
                hideDottedLines
                showXFirstAndLast
                tooltipContent={<WalletBalanceTooltip />}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="h3"
                  color="primary"
                  data-testid="balance-history-unavailable"
                >
                  No Balance History Yet
                </Typography>
              </Box>
            )}
          </UserBox>
        </Grid>
      </Grid>
    );
  }
};

export default RewardsUserInfo;
