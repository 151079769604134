import { Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import DecksFilter from './DecksFilter';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import { Outlet } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const DecksPage = () => {
  const { game } = useSelector(({ app }) => app);
  const ref = useRef();
  const offsetTop = { xs: `${get(ref, 'current.offsetTop', 80)}px`, sm: 0 };

  const INITIAL_QUERY = {
    name: '',
    deckTagIds: [],
    cardIds: [],
    formatId: ''
  };

  const [deckQuery, setDeckQuery] = useState(INITIAL_QUERY);

  return (
    <MaxWidthContainer
      id="decks-page-container"
      data-testid="decks-page-container"
      container
      className={'p-percent p-top-5'}
      ref={ref}
    >
      <Grid container spacing={8}>
        <Grid item>
          <Typography
            data-testid="deck-title"
            className={'deck-term'}
            variant={'h1'}
          >
            {`${get(game, 'terminology.deck', 'Deck')}s`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DecksFilter
            offsetTop={offsetTop}
            setDeckQuery={setDeckQuery}
            INITIAL_QUERY={INITIAL_QUERY}
          />
        </Grid>
        <Grid item xs={12}>
          <Outlet context={{ query: deckQuery }} />
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default DecksPage;
