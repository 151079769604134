import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

const HowRankingsWorkModal = () => {
  const { game } = useSelector(({ app }) => app);
  const playerTerm = get(game, 'terminology.player', 'Player');

  return (
    <Grid container flexDirection="column">
      <Typography sx={{ mb: 2 }} variant="h4">
        What are the different ranks?
      </Typography>
      <Typography sx={{ mb: 4 }} variant="body2">
        {`We have ${game.ranks.length} different ranks that are all broken out by different ELO thresholds. You can find the various ranking brackets and thresholds below.`}
      </Typography>

      <Grid container justifyContent="space-between" sx={{ flexWrap: 'wrap' }}>
        {game.ranks.map(rank => {
          if (rank.iconUrl) {
            return (
              <Grid
                container
                key={rank.name}
                mb={2}
                justifyContent="center"
                sx={{ width: { xs: '100%', md: '48%' } }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${rank.iconUrl})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    height: '80px',
                    width: '80px'
                  }}
                />
                <Grid
                  justifyContent="center"
                  container
                  flexDirection="column"
                  sx={{ mb: 4, mt: 2, textAlign: 'center' }}
                >
                  <Typography>{rank.name}</Typography>
                  {!rank.minThreshold && !rank.maxThreshold && (
                    <Typography variant="body2">{`#1 ${playerTerm}`}</Typography>
                  )}
                  {rank.minThreshold && rank.maxThreshold && (
                    <Typography variant="body2">{`${rank.minThreshold} to ${rank.maxThreshold}`}</Typography>
                  )}
                  {rank.minThreshold && !rank.maxThreshold && (
                    <Typography variant="body2">{`${rank.minThreshold}+`}</Typography>
                  )}
                  {!rank.minThreshold && rank.maxThreshold && (
                    <Typography variant="body2">{`Below ${rank.maxThreshold}`}</Typography>
                  )}
                </Grid>
              </Grid>
            );
          }
          return (
            <Grid
              container
              key={rank.name}
              mb={2}
              justifyContent="center"
              sx={{ width: { xs: '100%', md: '48%' } }}
            >
              <Grid
                justifyContent="center"
                container
                flexDirection="column"
                sx={{ mb: 4, mt: 2, textAlign: 'center' }}
              >
                <Typography>{rank.name}</Typography>
                {!rank.minThreshold && !rank.maxThreshold && (
                  <Typography variant="body2">{`#1 ${playerTerm}`}</Typography>
                )}
                {rank.minThreshold && rank.maxThreshold && (
                  <Typography variant="body2">{`${rank.minThreshold} to ${rank.maxThreshold}`}</Typography>
                )}
                {rank.minThreshold && !rank.maxThreshold && (
                  <Typography variant="body2">{`${rank.minThreshold}+`}</Typography>
                )}
                {!rank.minThreshold && rank.maxThreshold && (
                  <Typography variant="body2">{`Below ${rank.maxThreshold}`}</Typography>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default HowRankingsWorkModal;
