import moment from 'moment';
import { NA } from '../assets/strings';
import dayjs from 'dayjs';
import { get } from 'lodash';

export const isDateInTheFuture = date => {
  const today = new Date();
  const dateToCheck = new Date(date);

  return dateToCheck > today;
};

export const displayableDate = date => {
  return moment(date).format('MMM D, YYYY');
};

export const formatDate = date => {
  const currentDate = dayjs(date);

  if (!currentDate.isValid()) {
    return NA;
  }

  return currentDate.format('ddd. MMM DD, YYYY');
};

// Used to format the times displayed for the event
export const formatEventTime = (startDate, to) => {
  const startTime = dayjs(startDate);

  if (startDate && startTime.isValid()) {
    let endTime = startTime;
    switch (get(to, 'type', '')) {
      case 'endTime':
        const minutesToAdd = to.rounds * (to.roundLength + 20);
        endTime = startTime.add(minutesToAdd, 'm');
        break;
      case 'checkIn':
        endTime = startTime.subtract(to.minutesToSubtract, 'm');
        break;
      default:
        break;
    }

    return endTime.format('h:mm A');
  }
  return '00:00';
};

export const getISODateString = date => {
  if (!date) {
    return null;
  }
  return dayjs(date).toISOString();
};
