import React, { useContext, useState } from 'react';
import {
  useDeleteDeckMutation,
  useGetDecksQuery,
  useGetSingleDeckQuery,
  usePostCheckDeckValidationMutation,
  usePostCreateDeckMutation,
  usePutUpdateDeckMutation
} from '../../../services/apis/organizePlayApi';

import DeleteDeck from '../../../components/Decks/ModalPages/DeleteDeck';
import { ModalContext } from '../../../components/Modal/Context/ModalContext';
import { createFormatByIdMap } from '../../../utils/game';
import { getDeckTerminologies } from '../../../utils/deck';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export function useDeck(
  props = { deckId: '', getMyDecks: false, getMyTags: false }
) {
  const { auth } = useSelector(({ auth }) => ({
    auth
  }));
  const reactHookFormContext = useFormContext();
  const { addModal } = useContext(ModalContext);
  const { game } = useSelector(({ app }) => app);
  const [deckLastUpdated, setDeckLastUpdated] = useState(new Date().getTime());
  const deckTerminologies = getDeckTerminologies(game);
  const decksProps = useGetDecksQuery(
    {
      gameId: game?.id,
      getMyDecks: props?.getMyDecks,
      ...props?.query
    },
    {
      skip:
        !game?.id ||
        (props?.getMyDecks && !auth?.token) ||
        !props?.query?.page ||
        !props?.query?.limit
    }
  );

  const deckFormatsById = createFormatByIdMap(game?.formats);

  const deckTags = game?.deckTags;
  const singleDeckProps = useGetSingleDeckQuery(props.deckId, {
    skip: !props.deckId
  });
  const defaultDeckTypeData = game?.defaultDeckType;

  let buildDeckSections = undefined;

  const updateDeckProps = usePutUpdateDeckMutation();
  const createDeckProps = usePostCreateDeckMutation();
  const deleteDeckProps = useDeleteDeckMutation();
  const checkDeckValidation = usePostCheckDeckValidationMutation();

  if (reactHookFormContext) {
    buildDeckSections = {
      reactHookFormContext
    };
  }

  const deleteModalAction = (deckId, redirectToDecks) => {
    addModal({
      children: (
        <DeleteDeck
          id={deckId}
          data-testid={deckId}
          redirectToDecks={redirectToDecks}
        />
      ),
      style: { maxWidth: '500px' }
    });
  };

  const registerDeckUpdate = () => {
    setDeckLastUpdated(new Date().getTime());
  };

  return {
    buildDeckSections,
    createDeckProps,
    deckFormats: game.formats,
    deckFormatsById,
    decksProps,
    deckTags,
    deckTerminologies,
    defaultDeckType: defaultDeckTypeData,
    deleteDeckProps,
    deleteModalAction,
    singleDeckProps,
    updateDeckProps,
    deckLastUpdated,
    registerDeckUpdate,
    checkDeckValidation
  };
}
