import React, { useContext, useMemo } from 'react';
import ChartWrapper from '../ChartWrapper';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip,
  YAxis
} from 'recharts';
import { MetricsContext } from '..';
import { useOutletContext } from 'react-router-dom';
import { getMetric } from '../utils';
import { useCardsCategory } from '../../hooks/useCardsCategory';
import { useTheme } from '@mui/material';
import CustomTooltip from '../CustomTooltip';

const TraitsChart = () => {
  const {
    isMobile,
    getFillArray,
    barSize,
    defaultMargin,
    getChartTitleFromField
  } = useContext(MetricsContext);
  const { deck } = useOutletContext();
  const traits = getMetric(deck, 'traits');
  let curveName = traits.field ? getChartTitleFromField(traits) : 'Traits';
  let traitsDictionary = traits.field
    ? useCardsCategory(traits.field)
    : useCardsCategory('Traits');
  const theme = useTheme();
  const defaultData = [{ name: '', value: 0 }];

  const fillArray = getFillArray(Object.keys(traits).length);
  const data = useMemo(
    () =>
      Object.keys(traits)
        .filter(key => typeof traits[key] === 'number')
        .sort((a, b) => traits[b] - traits[a])
        .map((key, i) => ({
          name: traitsDictionary[key]?.name,
          value: traits[key],
          fill: fillArray[i],
          index: i
        })),
    [traits]
  );

  const CustomLabel = props => {
    const { x, y, name, width } = props;
    const xOffset = 5;
    return (
      <text
        x={x + xOffset}
        y={y + width / 2}
        textAnchor="start"
        fill={theme.palette.text.primary}
        transform={`rotate(-90, ${x}, ${y})`}
        fontSize={15}
        width={width}
        dominantBaseline="middle"
      >
        {name}
      </text>
    );
  };

  const CustomBar = props => {
    const { x, y, width, height, value } = props;
    const verticalMargin = isMobile ? 2 : 5;
    // const blockHeight = 35;
    // const numberOfBlocks = Math.ceil(height / blockHeight);
    const maxOpacity = 99;
    const opacityDecrease = 5;

    return (
      <svg>
        {/* {Array.from({ length: numberOfBlocks }, (_, i) => {
          const opacity = Math.floor(maxOpacity / (numberOfBlocks - i));

          return (
            <rect
              key={i}
              fill={`${fill}${opacity < 10 ? `0${opacity}` : opacity}`}
              x={x}
              y={y + (height / numberOfBlocks) * i + verticalMargin}
              width={width}
              height={height / numberOfBlocks - verticalMargin}
            />
          );
        })} */}
        {Array.from({ length: value }, (_, i) => {
          let opacity, fill;
          if (i === value - 1) {
            fill = props.fill;
          } else {
            opacity = Math.max(
              Math.floor(maxOpacity - (value - i) * opacityDecrease),
              10
            );
            fill = `${props.fill}${opacity}`;
          }

          const rectHeight = Math.max(height / value - verticalMargin, 0);

          return (
            <rect
              key={i}
              fill={fill}
              x={x}
              y={y + (height / value) * i}
              width={width}
              height={rectHeight}
            />
          );
        })}
      </svg>
    );
  };

  return (
    <ChartWrapper title={`${curveName} Graph`}>
      <BarChart data={data.length ? data : defaultData} margin={defaultMargin}>
        <CartesianGrid stroke={`${theme.palette.text.primary}20`} />
        <YAxis
          width={25}
          allowDecimals={false}
          padding={{ top: 70 }}
          interval="preserveStart"
        />
        <Tooltip
          cursor={false}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Bar dataKey="value" barSize={barSize} shape={<CustomBar />}>
          <LabelList content={<CustomLabel />} />
        </Bar>
      </BarChart>
    </ChartWrapper>
  );
};

export default TraitsChart;
