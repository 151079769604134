export const deckSectionStyle = theme => ({
  margin: 0,
  border: 'unset',
  borderRadius: 'unset !important',
  boxShadow: 'unset !important',
  background: 'transparent',
  ['&.Mui-expanded']: {
    margin: 0
  },
  ['.MuiAccordionSummary-root']: {
    background: `${theme.palette.background.paper}`,
    padding: 1,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    ['.MuiAccordionSummary-content']: {
      margin: '10px 0',
      ['&.Mui-expanded']: {
        margin: '10px 0'
      }
    }
  }
});
