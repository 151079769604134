import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleEventQuery } from '../../../../services/apis/organizePlayApi';
import AddNewEventPage from '../../AddNewEventPage';
import Loading from '../../../../components/Loading/Loader';

export const EditEventPage = () => {
  const { eventId } = useParams();

  const { data: activeEvent, isLoading } = useGetSingleEventQuery(eventId);

  if (isLoading) {
    return <Loading />;
  }
  return <AddNewEventPage activeEvent={activeEvent} />;
};

export default EditEventPage;
