import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { capitalize, get } from 'lodash';

import { PlayerTeamName } from '../../../components/Events/PlayerTeamName';
import ProfileImage from '../../../components/User/ProfileImage';
import RankTypography from '../../../components/Shared/Typography/RankTypography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const commonFlexDirection = { xs: 'column', md: 'row' };

const RankingCard = ({
  activeRankingType = 'all',
  user = {
    user: {
      firstName: 'test'
    }
  },
  showAvatar = true,
  showUserInfo = true,
  flip = false,
  textAlign = 'sm',
  constantTextAlign = '',
  alignBottomItems = null
}) => {
  const alignItems = flip ? 'flex-end' : 'flex-start';
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(textAlign));
  const { game } = useSelector(({ app }) => app);

  const alignText = () => {
    if (constantTextAlign) {
      return constantTextAlign;
    }
    return isSmallScreen ? 'center' : flip ? 'end' : 'unset';
  };
  return (
    <>
      <Grid
        id={`rankings-user-${user.userId}-card-button`}
        data-testid={`rankings-user-${user.userId}-card-button`}
        container
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        alignItems={'center'}
        flexDirection={commonFlexDirection}
        wrap={'nowrap'}
        gap={4}
      >
        <Grid item>
          <Grid
            container
            item
            alignItems={'center'}
            flexDirection={commonFlexDirection}
            wrap={'nowrap'}
            gap={2}
          >
            <Grid item>
              <RankTypography
                variant={'h3'}
                hash={user.rankNumber}
                position={user.rankNumber}
              />
            </Grid>
            {/* Avatar Picture */}
            {showAvatar && (
              <Grid item style={{ position: 'relative' }}>
                <ProfileImage
                  avatarImageUrl={get(user, 'userImage')}
                  rankIcon={get(user, 'rankIcon')}
                  rankNumber={get(user, 'rankNumber')}
                />
              </Grid>
            )}
            {showUserInfo && (
              <Grid item>
                <Grid
                  container
                  item
                  direction={'column'}
                  alignItems={alignBottomItems || alignItems}
                  justifyContent={{ xs: 'center', sm: 'unset' }}
                  gap={0.5}
                >
                  {/* Name */}
                  <Grid item>
                    <Box
                      sx={{
                        display: 'table',
                        tableLayout: 'fixed',
                        width: '100%',
                        borderCollapse: 'collapse'
                      }}
                    >
                      <Typography
                        variant={'h3'}
                        sx={{
                          display: 'table-cell',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          textAlign: alignText()
                        }}
                      >
                        {`${capitalize(
                          get(user, 'firstName', '')
                        )} ${capitalize(get(user, 'lastName', ''))}`}
                      </Typography>
                    </Box>
                    <PlayerTeamName
                      teamName={get(user, 'teamName')}
                      shouldCenter={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              alignItems={{
                xs: activeRankingType === 'seasons' ? 'center' : 'inherit',
                sm: 'inherit'
              }}
              flexDirection={{
                xs: activeRankingType === 'seasons' ? 'column' : 'row',
                sm: 'row'
              }}
              flexWrap="nowrap"
              gap={{
                xs: activeRankingType === 'seasons' ? 2 : 0,
                sm: 0
              }}
              justifyContent={{ xs: 'center' }}
            >
              {activeRankingType === 'seasons' && (
                <Grid
                  container
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                  flexDirection="column"
                  sx={{ width: '150px' }}
                >
                  <Typography
                    mb={1}
                    sx={{ textTransform: 'uppercase' }}
                    variant="body3"
                  >
                    {get(
                      game,
                      'terminology.points.seasonTotal',
                      'Season Points'
                    )}
                  </Typography>
                  <Typography variant="body1">
                    {get(user, 'points.totalSeasonPoints')}
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                flexWrap="nowrap"
                justifyContent={{ xs: 'center' }}
                sx={{ width: 'auto' }}
              >
                <Grid
                  container
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                  flexDirection="column"
                  sx={{ width: '110px' }}
                >
                  <Typography mb={1} variant="body3">
                    ELO SCORE
                  </Typography>
                  <Typography variant="body1">
                    {get(user, 'eloRank')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems={{ xs: 'center', md: 'flex-end' }}
                  flexDirection="column"
                  sx={{ width: '100px' }}
                >
                  <Typography mb={1} variant="body3">
                    MATCHES
                  </Typography>
                  <Typography variant="body1">
                    {get(user, 'history.matches.played')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RankingCard;
