import { Box, Grid, Typography, alpha, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { compact, find, forEach, get, isEmpty, kebabCase } from 'lodash';
import {
  getCartListingFromDetails,
  getTotalPricesFromOrder
} from '../../../../utils/cart';
import {
  getFormattedCurrency,
  getUserReadableCurrencyAmount
} from '../../../../utils/number';

import Loading from '../../../../components/Loading/Loader';
import OrderItemSingle from '../OrderItemSingle/index';
import StoreCurrency from '../../../../components/Shared/StoreCurrency';
import dayjs from 'dayjs';
import { useGetOrderByIdQuery } from '../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const StyledOrderItemsContainer = styled(Grid)(({ theme }) => ({
  '& .order-details-single-item:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.neutral[600], 0.2)}`
  }
}));

const StyledAddressContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  borderRadius: '8px'
}));

// eslint-disable-next-line no-unused-vars
const StyledFlexBox = styled(Box)(({ theme, justifyContent }) => ({
  display: 'flex',
  justifyContent: justifyContent || 'flex-start',
  alignItems: 'center'
}));

const OrderDetails = ({ orderId }) => {
  const { game, user } = useSelector(({ app }) => app);
  const name = compact([
    get(user, 'details.info.firstName'),
    get(user, 'details.info.lastName')
  ]).join(' ');
  const currencies = get(game, 'pgGame.currencies', []);
  const { data: orderDetails, isLoading, isFetching } = useGetOrderByIdQuery(
    orderId,
    {
      skip: !orderId
    }
  );
  const getOrderDate = useCallback(() => {
    if (get(orderDetails, 'data.order', null)) {
      return dayjs(new Date(get(orderDetails, 'data.order.createdAt'))).format(
        'MMM D, YYYY'
      );
    }
    return '';
  }, [orderDetails]);
  const getOrderTotalPrice = useCallback(() => {
    if (get(orderDetails, 'data.order', null)) {
      return getTotalPricesFromOrder(orderDetails.data.order, currencies, true);
    }
    return '';
  }, [orderDetails, currencies]);
  const orderFiatTotal = get(orderDetails, 'data.order.total.totalAmount', 0);
  const cartItems = getCartListingFromDetails(
    {
      cart: get(orderDetails, 'data.order.cart', null),
      products: get(orderDetails, 'data.products')
    },
    currencies
  );
  const getTransactionsBalances = () => {
    const transactions = get(orderDetails, 'data.order.transactions', []);
    if (isEmpty(transactions)) return [];
    const result = [];
    forEach(transactions, transaction => {
      const findCurrency = find(
        currencies,
        cr => cr.id === transaction.currencyId
      );
      result.push({
        beforeBalance: transaction.sourceBalance + transaction.amount,
        afterBalance: transaction.sourceBalance,
        currency: get(findCurrency, 'symbol', 'points')
      });
    });
    return result;
  };
  const renderAddress = (label, address) => {
    if (!address) return null;
    const addresses = compact([
      get(address, 'address1'),
      get(address, 'address2'),
      get(address, 'city')
    ]);
    const restPart = compact([
      get(address, 'state'),
      get(address, 'zip'),
      get(address, 'country')
    ]).join(', ');
    return (
      <StyledAddressContainer container spacing={1}>
        <Grid item xs={12}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={12} mt="4px">
          <Typography variant="body1Light">{name}</Typography>
        </Grid>
        {addresses.map(addr => (
          <Grid item xs={12} key={`address_${kebabCase(addr)}`} mt="4px">
            <Typography variant="body1Light">{addr}</Typography>
          </Grid>
        ))}
        {restPart && (
          <Grid item xs={12} mt="4px">
            <Typography variant="body1Light">{restPart}</Typography>
          </Grid>
        )}
      </StyledAddressContainer>
    );
  };
  if (isLoading || isFetching) {
    return <Loading />;
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        Order Date: {getOrderDate()}
      </Grid>
      <Grid item xs={6} textAlign="end">
        <StyledFlexBox justifyContent="flex-end">
          <Typography mr={1}>Order Amount:</Typography>
          <StyledFlexBox>
            {getOrderTotalPrice().map(orderTotal => (
              <StoreCurrency key={orderTotal.symbol} {...orderTotal} />
            ))}
            {orderFiatTotal > 0 && (
              <Typography ml={1} variant="body1">
                {getFormattedCurrency(
                  getUserReadableCurrencyAmount(orderFiatTotal)
                )}
              </Typography>
            )}
          </StyledFlexBox>
        </StyledFlexBox>
      </Grid>
      <Grid item xs={12} marginY={1}>
        Included Items
      </Grid>
      <Grid item xs={12}>
        <StyledOrderItemsContainer container spacing={1}>
          {cartItems && cartItems.cartData && cartItems.cartData.length
            ? cartItems.cartData.map(cartItemData => (
                <OrderItemSingle
                  key={cartItemData.productId}
                  orderItem={cartItemData}
                />
              ))
            : null}
        </StyledOrderItemsContainer>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} padding="0 16px">
            {renderAddress(
              'Shipping Address',
              get(orderDetails, 'data.order.shippingAddress', null)
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Wallet balance</Typography>
          </Grid>
          <Grid item xs={12}>
            {getTransactionsBalances().map(tb => (
              <Box key={tb.currency} sx={{ display: 'flex' }}>
                <Typography>{tb.beforeBalance}</Typography>
                <Typography ml={1} color="primary">
                  ({tb.afterBalance})
                </Typography>
                <StoreCurrency
                  symbol={tb.currency}
                  amount={null}
                  containerProps={{ marginLeft: '8px' }}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
