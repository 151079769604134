import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { StyledTable, WalletBalanceContainer } from './styles';
import { get, isEmpty, kebabCase, toPairs } from 'lodash';
import {
  getFormattedCurrency,
  getUserReadableCurrencyAmount
} from '../../../utils/number';

import Loading from '../../../components/Loading/Loader';

const CartUserWalletBalance = ({
  totalPrices,
  currencies,
  wallets,
  isLoading,
  totalFiat,
  onBalanceUpdate = () => {}
}) => {
  const [tableData, setTableData] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const fiatArray = toPairs(totalFiat);
  const createCell = (cell, key) => ({
    id: cell.currency.id,
    value: cell[key]
  });

  useEffect(() => {
    if (totalPrices && wallets && currencies && !isLoading) {
      const walletToCurrency = wallets.reduce(
        (prev, current) => ({ ...prev, [current.currencyId]: current }),
        {}
      );

      const currentTableData = totalPrices
        .filter(price => walletToCurrency[price.currency.id])
        .map(price => {
          const walletBalance = get(
            walletToCurrency,
            `${price?.currency?.id}.balance`,
            0
          );
          return {
            ...price,
            walletBalance,
            walletBalanceAfterOrder: walletBalance - (price?.amount || 0)
          };
        });

      const hasNegativeBalance = !!currentTableData.find(
        aob => aob.walletBalanceAfterOrder < 0
      );

      setTableData([
        ['', ...currentTableData.map(tp => tp.currency.symbol)],
        [
          'Current Balance',
          ...currentTableData.map(wcb => createCell(wcb, 'walletBalance'))
        ],
        [
          'Cart Total',
          ...currentTableData.map(wcb => createCell(wcb, 'amount'))
        ],
        [
          'After Checkout Balance',
          ...currentTableData.map(wcb =>
            createCell(wcb, 'walletBalanceAfterOrder')
          )
        ]
      ]);

      setIsInvalid(hasNegativeBalance);
      if (hasNegativeBalance) {
        onBalanceUpdate(true, 'Insufficient wallet balance');
      } else {
        onBalanceUpdate(false);
      }
    }
  }, [totalPrices, wallets, currencies, isLoading]);

  if (isLoading || isEmpty(tableData)) {
    return <Loading />;
  }

  return (
    <WalletBalanceContainer>
      <Grid
        container
        spacing={1}
        justifyContent={{ sm: 'flex-end', xs: 'center' }}
      >
        <Grid item xs={12} sm={10} textAlign={{ sm: 'end', xs: 'center' }}>
          <TableContainer>
            <StyledTable size="small">
              <TableHead>
                <TableRow>
                  {tableData[0].map(row => (
                    <TableCell key={`header_${row}`} align="right">
                      <Typography>{row}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(1).map(row => (
                  <TableRow key={`body_${kebabCase(row[0])}`}>
                    <TableCell>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        {row[0]}
                      </Typography>
                    </TableCell>
                    {row.slice(1).map(col => (
                      <TableCell key={`cell_${col.id}_${col}`} align="right">
                        <Typography
                          color={'primary'}
                          data-testid={`shipping-value-${kebabCase(row[0])
                            .replace(' ', '')
                            .toLowerCase()}`}
                        >
                          {col.value}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {fiatArray.map(([currency, amount]) => (
                  <TableRow key={`body_${kebabCase(currency)}`}>
                    <TableCell variant={'body2'} fontWeight={'bold'}>
                      <Typography variant={'body2'} fontWeight={'bold'}>
                        Cart Total ({currency})
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        color={'primary'}
                        data-testid="currency-cart-total"
                      >
                        {getFormattedCurrency(
                          getUserReadableCurrencyAmount(amount)
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Grid>
        <Grid item mt={2}>
          <Typography sx={{ fontStyle: 'italic' }} variant="body3Light">
            Note: All Reward Store Purchases are Final.
          </Typography>
        </Grid>
      </Grid>
    </WalletBalanceContainer>
  );
};

export default CartUserWalletBalance;
