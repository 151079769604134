import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import StatusPage, { statusTypes } from '../../pages/Status';

import App from '../../pages';
import CardRoutes from '../../pages/Cards/routes';
import CartRouter from '../../pages/Cart/routes';
import DeckRouter from '../../pages/Decks/routes';
import EventsRoutes from '../../pages/Events/routes';
import FeaturedRoute from '../../components/Route/FeaturedRoute';
import LandingPage from '../../pages/Landing';
import LgsApplicationPage from '../../pages/LgsApplication';
import Login from '../../pages/Login';
import PaymentsRoutes from '../../pages/Payments/routes';
import PayoutsRoutes from '../../pages/Payout/routes';
import ProtectedRoute from '../../components/Route/ProtectedRoute';
import RankingsRoutes from '../../pages/Rankings/routes';
import React from 'react';
import RewardsMainPage from '../../pages/Rewards';
import RewardsRouter from '../../pages/Rewards/routes';
import SeasonsMainPage from '../../pages/Seasons';
import StoreRoutes from '../../pages/Store/routes';
import UserRouter from '../../pages/User/routes';
import VerifyEmail from '../../pages/VerifyEmail';

const BrowserRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={process.env.REACT_APP_ACTIVE_GAME ? '/*' : '/:gameSlug/*'}
        element={<App />}
        errorElement={<StatusPage type={statusTypes.error} />}
        loader={({ params }) => {
          return {
            isSingleGame: Boolean(process.env.REACT_APP_ACTIVE_GAME),
            gameSlug: process.env.REACT_APP_ACTIVE_GAME || params.gameSlug,
            path: process.env.REACT_APP_ACTIVE_GAME ? '' : `/${params.gameSlug}`
          };
        }}
      >
        <Route
          index
          element={<LandingPage />}
          errorElement={<StatusPage type={statusTypes.error} />}
        />
        <Route exact path={'login'} element={<Login />} />
        <Route exact path={'verify-email'} element={<VerifyEmail />} />
        {CardRoutes()}
        {DeckRouter()}
        {EventsRoutes()}
        {UserRouter()}
        {StoreRoutes()}
        {RewardsRouter()}
        {CartRouter()}
        {PaymentsRoutes()}
        {PayoutsRoutes()}
        <Route
          exact
          path={'seasons'}
          element={
            <FeaturedRoute feature="seasons">
              <SeasonsMainPage />
            </FeaturedRoute>
          }
          errorElement={<StatusPage type={statusTypes.error} />}
        />
        {RankingsRoutes()}
        <Route
          exact
          path={'rewards'}
          element={<RewardsMainPage />}
          errorElement={<StatusPage type={statusTypes.error} />}
        />
        <Route
          exact
          path={'lgs-application'}
          element={
            <ProtectedRoute>
              <LgsApplicationPage />
            </ProtectedRoute>
          }
          errorElement={<StatusPage type={statusTypes.error} />}
        />
        <Route
          path={'*'}
          element={<StatusPage type={statusTypes.notFound} />}
          errorElement={<StatusPage type={statusTypes.error} />}
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default BrowserRouter;
