import { get } from 'lodash';

export const getRandomProfileImage = game => {
  const totalProfileImages = get(game, 'stats.totalPFPs', 10);
  return Math.floor(Math.random() * (totalProfileImages - 1 + 1) + 1);
};

export const getProfileImages = game => {
  const totalProfileImages = get(game, 'stats.totalPFPs', 10);

  const images = [];

  const imageCount = Array(totalProfileImages).fill('');
  imageCount.forEach((_, index) => {
    const imageIndex = index + 1;
    images.push(
      `https://storage.googleapis.com/cardeio-images/defaults/${game.slug}/profile/${imageIndex}.webp`
    );
  });

  return images;
};
