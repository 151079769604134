import { useSelector } from 'react-redux';
import { get, unset } from 'lodash';
import {
  usePutUpdateUserInfoByGameMutation,
  usePutUpdateUserInfoMutation
} from '../../services/apis/organizePlayApi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { updateUserSchema } from '../../schema/user/updateUserSchema';
import { useFormError } from '../form/useFormError';
import { updateUserAddressSchema } from '../../schema/user/updateUserAddressSchema';
import { createDefaultUserInfo } from '../../utils/user/createDefaultUserInfo';

export const UpdateUserTypes = {
  Default: 'default',
  ShippingAddress: 'ShippingAddress'
};

export function useUpdateUserInfo({
  omitGameUser = false,
  updateUserType = UpdateUserTypes.Default
}) {
  const { user, game } = useSelector(({ app }) => app);
  const userId = get(user, 'details.id', null);
  const gameId = get(game, 'id', null);

  const [updateUser, updateUserRes] = usePutUpdateUserInfoMutation();
  const [
    updateGameUser,
    updateGameUserRes
  ] = usePutUpdateUserInfoByGameMutation();

  const getUpdateUserSchema = () => {
    switch (updateUserType) {
      case UpdateUserTypes.ShippingAddress:
        return updateUserAddressSchema;
      default:
        return updateUserSchema;
    }
  };

  const handleSave = data => {
    const localData = {
      location: { ...data.location },
      profile: { ...data.profile }
    };
    unset(localData, 'profile.email');
    unset(localData, 'teamName');
    if (userId) {
      localData.location.isOptedOut =
        localData.location.address1 === '' && localData.location.city === '';
      unset(localData, 'location.geo');
      unset(localData, 'location.name');
      unset(localData, 'location.isOnline');
      updateUser({ id: userId, body: localData });
      if (!omitGameUser) {
        updateGameUser({
          id: userId,
          gameId,
          body: {
            imageUrl: get(user, 'details.gameUser.imageUrl', ''),
            teamName: get(data, 'teamName', '')
          }
        });
      }
    }
  };

  const methods = useForm({
    defaultValues: createDefaultUserInfo({ updateUserType, user }),
    resolver: joiResolver(getUpdateUserSchema())
  });

  useFormError(methods.formState);

  return { methods, handleSave, updateUserRes, updateGameUserRes };
}
