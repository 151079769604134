import { Box, Grid, Typography, styled } from '@mui/material';

import React from 'react';

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  maxWidth: '350px',
  boxShadow: theme.shadows[2]
}));

const WalletBalanceTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { balance, description } = payload[0].payload;
    return (
      <StyledContainer>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary">Balance: {balance}</Typography>
          </Grid>
        </Grid>
      </StyledContainer>
    );
  }
  return null;
};

export default WalletBalanceTooltip;
