import { isFulfilled } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { get, has } from 'lodash';
import { successMessages } from '../../values/messages';
import { rtkEndPointNames } from '../../services/apis/organizePlayApi';
import { updateGameUserAction } from '../app/actions/updateGameUserAction';
import { updateUserAction } from '../app/actions/updateUserAction';
import { updateUserProfilePicAction } from '../app/actions/updateUserProfilePicAction';

export const successLogger = store => next => action => {
  if (isFulfilled(action) && !has(action, 'payload.errorMessage')) {
    // Get the action name
    const actionType = get(
      action,
      'meta.arg.fixedCacheKey',
      get(action, 'meta.arg.endpointName', 'default')
    );

    switch (actionType) {
      case 'updateUserProfilePic':
        store.dispatch(
          updateUserProfilePicAction(action?.payload?.data?.imageUrl)
        );
        break;
      case rtkEndPointNames.putUpdateUserInfo:
        store.dispatch(updateUserAction(action?.payload?.data));
        break;
      case rtkEndPointNames.putUpdateUserInfoByGame:
        store.dispatch(updateGameUserAction(action?.payload?.data));
        break;
    }

    if (toast.isActive(actionType)) {
      toast.update(actionType);
    } else {
      const message = successMessages[actionType];
      if (message) {
        toast.success(successMessages[actionType], {
          toastId: actionType
        });
      }
    }
  }

  return next(action);
};
