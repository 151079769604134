import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CustomLineChart from '../../../../../components/Shared/Charts/CustomLineChart';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { isEmpty } from 'lodash';
import { useGetUserRankingScoreHistoryQuery } from '../../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const RankScoreHistoryContainer = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  border: `1px solid ${alpha(theme.palette.neutral[100], 0.2)}`,
  borderRadius: '40px'
}));

const RankingTitleContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
`;

const StyledNavIconWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ToggleButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0
  },
  '& .MuiToggleButton-root': {
    border: 0,
    padding: '6px 10px 8px',
    fontSize: '1rem',
    lineHeight: '1rem',
    textTransform: 'lower',
    fontWeight: '500',
    '&.Mui-selected': {
      borderRadius: '16px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    '&:hover': {
      backgroundColor: 'initial'
    }
  }
}));

function UserRankScoreHistory({ userId, seasonId, formatId }) {
  const theme = useTheme();
  const { game } = useSelector(({ app }) => app);
  const gameId = get(game, 'id');
  const [selectedChartType, setSelectedChartType] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [query, setQuery] = useState({});
  const [formattedData, setFormattedData] = useState([]);
  const [yAxisDomain, setYAxisDomain] = useState([1000, 2500]);
  const [isNextVisible, setIsNextVisible] = useState(false);
  const [isPrevVisible, setIsPrevVisible] = useState(false);

  useEffect(() => {
    const queryObj = {
      userId,
      gameId,
      date: currentDate.toISOString(),
      scoreTypes: [selectedChartType]
    };
    if (seasonId) {
      queryObj.seasonId = seasonId;
    }
    if (formatId) {
      queryObj.formatId = formatId;
    }
    setQuery(queryObj);
  }, [userId, currentDate, selectedChartType, seasonId, formatId, gameId]);

  const handleChartTypeChange = value => {
    if (value) {
      setCurrentDate(new Date());
      setSelectedChartType(value);
    }
  };

  const {
    data: scoreHistoryData,
    isLoading,
    isFetching
  } = useGetUserRankingScoreHistoryQuery(query, {
    refetchOnMountOrArgChange: true,
    skip: isEmpty(query) || !userId
  });

  const isChartDataLoading = isLoading || isFetching;

  useEffect(() => {
    const minDateAvailable = dayjs(
      get(scoreHistoryData, 'data.limit.minDate', new Date())
    ).startOf(selectedChartType);
    const maxDateAvailable = dayjs(new Date()).endOf(selectedChartType);

    const prevRangeDate = dayjs(currentDate).subtract(1, selectedChartType);
    const nextRangeDate = dayjs(currentDate).add(1, selectedChartType);

    if (prevRangeDate.isBefore(minDateAvailable)) {
      setIsPrevVisible(false);
    } else {
      setIsPrevVisible(true);
    }

    if (nextRangeDate.isAfter(maxDateAvailable)) {
      setIsNextVisible(false);
    } else {
      setIsNextVisible(true);
    }
  }, [scoreHistoryData, selectedChartType, currentDate]);

  useEffect(() => {
    if (
      !isEmpty(scoreHistoryData) &&
      selectedChartType &&
      !isChartDataLoading
    ) {
      const requiredData = get(
        scoreHistoryData,
        `data.${selectedChartType}`,
        []
      );
      const dateFormat = selectedChartType === 'year' ? 'M/YY' : 'D';

      const result = requiredData.map(scoreData => {
        const date = new Date(scoreData.timestamp);
        return {
          score: Math.round(parseFloat(scoreData.eloScore)),
          date,
          xLabel: dayjs(date).format(dateFormat),
          isPlaceholder: scoreData.isPlaceholder
        };
      });

      const sortedData = requiredData
        .map(d => d.eloScore)
        .filter(d => !!d)
        .sort();
      let minNumber = get(sortedData, '[0]', 1400) - 400;
      if (minNumber < 0) {
        minNumber = 0;
      }
      const maxNumber =
        get(sortedData, `[${sortedData.length - 1}]`, 2500) + 200;
      setYAxisDomain([minNumber, maxNumber]);
      setFormattedData(result);
    } else {
      setFormattedData([]);
    }
  }, [scoreHistoryData, selectedChartType, isChartDataLoading]);

  const onNextClick = () => {
    setCurrentDate(dayjs(currentDate).add(1, selectedChartType));
  };
  const onPrevClick = () => {
    setCurrentDate(dayjs(currentDate).subtract(1, selectedChartType));
  };

  const getTitleDateText = () => {
    if (selectedChartType === 'year') {
      return dayjs(currentDate).format('YYYY');
    }
    if (selectedChartType === 'week') {
      const startDate = dayjs(currentDate).startOf('week');
      const endDate = dayjs(currentDate).endOf('week');
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;
    }
    return dayjs(currentDate).format('MMM YYYY');
  };

  return (
    <RankScoreHistoryContainer>
      <Grid container>
        <Grid item xs={12} padding="30px">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <RankingTitleContainer
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
              >
                <Typography variant="h4" color={theme.palette.primary.main}>
                  ELO ranking
                </Typography>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.main}
                  ml={1}
                >
                  ({getTitleDateText()})
                </Typography>
              </RankingTitleContainer>
            </Grid>
            <Grid item xs={12} sm={6} mt={{ xs: 2, sm: 0 }}>
              <ToggleButtonContainer
                justifyContent={{ xs: 'center', sm: 'flex-end' }}
              >
                <StyledToggleButtonGroup
                  value={selectedChartType}
                  exclusive
                  onChange={(_, value) => handleChartTypeChange(value)}
                  aria-label="text alignment"
                >
                  <ToggleButton value="week" aria-label="week view">
                    week
                  </ToggleButton>
                  <ToggleButton value="month" aria-label="month view">
                    month
                  </ToggleButton>
                  <ToggleButton value="year" aria-label="year view">
                    year
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </ToggleButtonContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1} mb={3} position="relative">
          <CustomLineChart
            data={formattedData}
            isLoading={isChartDataLoading}
            domain={yAxisDomain}
          />
          <StyledNavIconWrapper>
            {isPrevVisible ? (
              <IconButton onClick={onPrevClick}>
                {isFetching ? <CircularProgress /> : <KeyboardArrowLeftIcon />}
              </IconButton>
            ) : (
              <Box />
            )}
            {isNextVisible ? (
              <IconButton onClick={onNextClick} disabled={isChartDataLoading}>
                {isFetching ? <CircularProgress /> : <KeyboardArrowRightIcon />}
              </IconButton>
            ) : (
              <Box />
            )}
          </StyledNavIconWrapper>
        </Grid>
      </Grid>
    </RankScoreHistoryContainer>
  );
}

export default UserRankScoreHistory;
