import React, { useState } from 'react';
import { Box, ClickAwayListener, IconButton } from '@mui/material';
import { EventTooltip } from './styles';

const Tooltip = ({ info, icon, autoHover = true, buttonSize }) => {
  const [open, setOpen] = useState(false);
  const setOpenFalse = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={setOpenFalse}>
      <EventTooltip
        leaveDelay={0}
        disableTouchListener
        open={open}
        onClose={setOpenFalse}
        title={<>{info}</>}
      >
        <Box>
          <IconButton
            size={buttonSize}
            onMouseEnter={() => setOpen(autoHover)}
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {icon}
          </IconButton>
        </Box>
      </EventTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
