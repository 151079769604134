import { Grid, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CardDetails from './CardDetails';
import CardListSelections from './CardListSelections';
import { GameCardImage } from '../../Shared/Images';
import GeneralContainer from '../../Shared/Cards/GeneralCard';
import OverflowTextfield from '../../Shared/TextFields/Overflow';
import { cardInfoStyle } from './styles';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const cardLayoutPath = 'layout.card.cardOverview';

const FullCard = ({
  card,
  cardValues,
  cardDetails = false,
  formContext,
  outletContext,
  type = 'default'
}) => {
  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  // Used to get the information from
  const getSplitKeys = fieldKey => {
    const fieldValue = get(game, `${cardLayoutPath}.${fieldKey}`, '');
    if (fieldValue.includes(',')) {
      const keys = fieldValue.split(',');
      let fieldData = '';

      keys.forEach(fValue => {
        const keyData = get(card, fValue, '');
        if (fieldData.length === 0) {
          fieldData = keyData;
        } else {
          fieldData = fieldData + ' ' + keyData;
        }
      });

      return fieldData;
    }
    return get(card, fieldValue, '');
  };

  const fieldData = {
    image: get(card, get(game, `${cardLayoutPath}.image.fieldKey`, ''), ''),
    title: getSplitKeys('title.fieldKey'),
    subtitle: getSplitKeys('subtitle.fieldKey'),
    backgroundImage: get(
      card,
      get(game, `${cardLayoutPath}.bgIconImage.fieldKey`, ''),
      ''
    ),
    shadowColor:
      get(game, `${cardLayoutPath}.shadowColor.from`, '') === 'theme'
        ? get(theme, get(game, `${cardLayoutPath}.shadowColor.fieldKey`, ''))
        : 'transparent'
  };

  const getCardDimensions = slug => {
    switch (slug) {
      case 'nostalgix':
        return {
          borderRadius: {
            xs: '8px',
            sm: '12px'
          },
          height: { xs: 171, sm: 348 },
          width: { xs: 123, sm: 250 }
        };
      default:
        return {
          borderRadius: {
            xs: '8px',
            sm: '12px'
          },
          height: { xs: 165, sm: 339 },
          width: { xs: 122, sm: 250 }
        };
    }
  };

  const cardDimensions = getCardDimensions(game.slug);

  useEffect(() => {
    setOpen(false);
  }, [isMediumDown]);

  return (
    <GeneralContainer
      id={
        type == 'deckBuilder'
          ? `create-deck-card-${card.slug}-card-button`
          : `cards-${card.slug}-full-card`
      }
      paperStyle={{
        boxShadow: `0 0 12px 4px ${fieldData.shadowColor}80`,
        maxWidth: get(cardDimensions, 'width', '20%'),
        width: '100%',
        borderRadius: cardDimensions.borderRadius
      }}
      buttonStyle={{
        flexDirection: 'column',
        width: '100%',
        ['.card-list-main-container']: {
          pointerEvents: open || !isMediumDown ? 'unset' : 'none',
          ...(open && {
            opacity: 1
          })
        }
      }}
      {...(isMediumDown && {
        onClick: () => {
          setOpen(true);
        },
        onMouseLeave: () => {
          setOpen(false);
        }
      })}
    >
      {cardDetails && (
        <CardDetails
          gameSlug={game.slug}
          icon1={cardValues.iconType1.white}
          icon2={cardValues.iconType1.green}
          icon3={cardValues.iconType2.blue}
          number1={cardValues.number1}
          number2={cardValues.number2}
        />
      )}
      <GameCardImage imageurl={fieldData.image} />

      <Grid container sx={cardInfoStyle}>
        <Grid item xs={12}>
          <OverflowTextfield
            className={'card-title'}
            title={fieldData?.title}
            style={{
              lineHeight: { xs: '.8rem', sm: '2rem' },
              fontSize: { xs: '.7rem', sm: '1.2rem' }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <OverflowTextfield
            className={'card-subtitle'}
            title={fieldData?.subtitle}
            style={{
              lineHeight: { xs: '.8rem', sm: '1rem' },
              fontSize: { xs: '.6rem', sm: '.9rem' }
            }}
          />
        </Grid>
      </Grid>
      <CardListSelections
        type={type}
        card={card}
        formContext={formContext}
        outletContext={outletContext}
      />
    </GeneralContainer>
  );
};

export default React.memo(FullCard);
