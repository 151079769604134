import React, { useContext } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import RankingCard from '../../../../Rankings/All/RankingCard';
import GeneralContainer from '../../../../../components/Shared/Cards/GeneralCard';
import { ModalContext } from '../../../../../components/Modal/Context/ModalContext';
import UserRankDetailsPage from '../../../../Rankings/ModalPages/UserRankDetailsPage';

const StoreLeaderboard = ({ rankings, storeInfo }) => {
  const { game } = useSelector(({ app }) => app);
  const { addModal } = useContext(ModalContext);

  return (
    <Grid container justifyContent="center" mt={16} rowSpacing={2}>
      <Grid item xs={12} display="flex" justifyContent="center" mb={10}>
        <Typography variant="h2">
          {storeInfo.name} Top 25 {get(game, 'terminology.players', 'Players')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={2}>
          {rankings.map(user => (
            <Grid key={user.userId} item xs={12}>
              <GeneralContainer
                buttonStyle={{ padding: '24px' }}
                onClick={() =>
                  addModal({
                    children: (
                      <UserRankDetailsPage
                        userId={user.userId}
                        formatId={''}
                        seasonId={''}
                        rankIcon={user.rankIcon}
                        rankName={user.rankName}
                        rankNumber={user.rankNumber}
                        teamName={get(user, 'teamName')}
                      />
                    ),
                    style: { maxWidth: '700px !important' },
                    compactOnSmallDevice: true
                  })
                }
              >
                <RankingCard user={user} />
              </GeneralContainer>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreLeaderboard;
