import {
  DiscordIcon,
  FacebookIcon,
  HamburgerIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon
} from './vectors';

import React from 'react';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

const Icon = ({ color, name, height, width }) => {
  const theme = useTheme();
  const defaultColor = get(theme, 'palette.text.primary', '#FFFFFF');
  const iconMap = {
    discord: <DiscordIcon color={color || defaultColor} />,
    facebook: <FacebookIcon color={color || defaultColor} />,
    hamburger: <HamburgerIcon color={color || defaultColor} />,
    instagram: <InstagramIcon color={color || defaultColor} />,
    tikTok: <TikTokIcon color={color || defaultColor} />,
    twitter: <TwitterIcon color={color || defaultColor} />,
    twitch: <TwitchIcon color={color || defaultColor} />,
    youtube: <YoutubeIcon color={color || defaultColor} />,
    linkedin: <LinkedinIcon color={color || defaultColor} />
  };

  const iconViewBox = {
    discord: '0 0 24 24',
    facebook: '0 0 24 24',
    hamburger: '0 0 33 20',
    instagram: '0 0 24 24',
    tikTok: '0 0 2859 3333',
    twitch: '0 0 24 24',
    twitter: '0 0 24 24',
    youtube: '0 0 24 24',
    linkedin: '0 0 24 24'
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24'}
      height={height || '24'}
      viewBox={iconViewBox[name]}
      fillRule={name === 'checkCircle' ? 'evenodd' : 'inherit'}
    >
      {iconMap[name]}
    </svg>
  );
};

export default Icon;
