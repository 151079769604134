import React from 'react';
import { Route } from 'react-router-dom';
import DecksMainPage from './index';
import StatusPage, { statusTypes } from '../Status';
import CreateEventPage from './CreateDecksPage';
import DecksPage from './DecksPage';
import DeckDetailPage from './DeckDetailPage';
import DeckPreviewPage from './CreateDecksPage/SinglePages/DeckPreviewPage ';
import ProtectedRoute from '../../components/Route/ProtectedRoute';
import DecksView from '../../components/Decks/Sections/DecksView';
import DeckCardsSelectorPage from './CreateDecksPage/SinglePages/DeckCardsSelectorPage';
import DeckSettingsPage from './CreateDecksPage/SinglePages/DeckSettingsPage';
import DeckMetricsPage from './DeckMetricsPage';
import FeaturedRoute from '../../components/Route/FeaturedRoute';

const DeckRouter = () => {
  const deckMetrics = 'deckMetrics';
  return (
    <Route
      path={'decks/*'}
      element={<DecksMainPage />}
      errorElement={<StatusPage type={statusTypes.error} />}
    >
      <Route path={''} element={<DecksPage />}>
        <Route index element={<DecksView />} />
        <Route path={'my-decks'} element={<DecksView />} />
      </Route>
      <Route
        path={':deckId/edit'}
        element={
          <ProtectedRoute>
            <CreateEventPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<DeckCardsSelectorPage />} />
        <Route path={'preview'} element={<DeckPreviewPage />} />
        <Route
          path={'metrics'}
          element={
            <FeaturedRoute feature={deckMetrics}>
              <DeckMetricsPage edit />
            </FeaturedRoute>
          }
        />
        <Route path={'settings'} element={<DeckSettingsPage />} />
      </Route>
      <Route path={':deckId/'} element={<DeckDetailPage />}>
        <Route index element={<DeckPreviewPage />} />
        <Route
          path={'metrics'}
          element={
            <FeaturedRoute feature={deckMetrics}>
              <DeckMetricsPage />
            </FeaturedRoute>
          }
        />
      </Route>
    </Route>
  );
};

export default DeckRouter;
