import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { Typography } from '@mui/material';
import { deckTubeStyle } from './styles';
import { isEmpty } from 'lodash';

const DeckFormatTube = ({ format = '' }) => {
  if (isEmpty(format)) {
    return null;
  }
  return (
    <TubeCard className={'deck-tube'} sx={deckTubeStyle}>
      <Typography className={'deck-tube-typography'} textAlign={'center'}>
        {format}
      </Typography>
    </TubeCard>
  );
};

export default DeckFormatTube;
