import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ImageList,
  ImageListItem,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { find, get, isEmpty, isString, unset } from 'lodash';
import {
  usePostCreateEventMutation,
  usePostUpdateEventMutation
} from '../../../services/apis/organizePlayApi';

import AccordionMenu from '../../../components/Shared/Accordion';
import Currency from '../../../components/Shared/TextFields/Currency';
import CustomDateTimePicker from '../../../components/Shared/DateTimePickers';
import Editor from '../../../components/Editor';
import { GooglePlaceTextField } from '../../../components/Shared/GooglePlaceTextField';
import { LoadingButton } from '@mui/lab';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import { ModalContext } from '../../../components/Modal/Context/ModalContext';
import SimpleModalCard from '../../../components/Modal/Cards/SimpleModalCard';
import TournamentOverwrites from '../../../components/Events/Create/TournamentOverwrites';
import { buildBannerUrl } from '../../../utils/StringUtil';
import { buildRoutePath } from '../../../utils/routingUtils';
import dayjs from 'dayjs';
import { eventStatuses } from '../../../values/event-values';
import { getOptionArrayFromObject } from '../../../utils/common-utils';
import { mainCurrencies } from '../../../components/Shared/TextFields/Currency/utils';
import { toast } from 'react-toastify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

export const defaultLocation = {
  isOnline: false,
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

export const AddNewEventPage = ({ activeEvent = null }) => {
  // Redux
  const { gameSlug } = useParams();
  const { ticketing } = useFlags();
  const { addModal, closeModal } = useContext(ModalContext);

  if (activeEvent && activeEvent.status === eventStatuses.complete.key) {
    return (
      <Navigate
        to={buildRoutePath(gameSlug, `/events/${activeEvent._id}/about`)}
      />
    );
  }

  const { slug, formats, id } = useSelector(({ app }) => app.game) || {
    formats: [],
    id: ''
  };

  const { game, user } = useSelector(({ app }) => app);
  // React router
  const navigate = useNavigate();
  const errorToastId = 'addEventToastError';
  const halfItem = { xs: 12, md: 6 };
  const totalEventBanners = get(game, 'stats.totalEventBanners');

  // eslint-disable-next-line no-unused-vars
  const [createEvent, createEventResult] = usePostCreateEventMutation();
  // eslint-disable-next-line no-unused-vars
  const [updateEvent, updateEventResult] = usePostUpdateEventMutation();
  const [nestedFormats, setNestedFormats] = useState([]);

  const prizingInfo = [
    {
      title: '1st Place',
      key: 'first'
    },
    { title: '2nd Place', key: 'second' },
    { title: '3rd Place', key: 'third' },
    { title: 'Top 8', key: 'top8' },
    { title: 'Top 16', key: 'top16' },
    { title: 'Participation', key: 'participation' }
  ].map(obj => {
    return {
      title: obj.title,
      cashKey: `details.prizing.${obj.key}.cash`,
      productKey: `details.prizing.${obj.key}.product`
    };
  });

  // List of entities that this organizer has.
  const orgs = [];

  get(user, 'permissions.events.entities', []).forEach(entity => {
    orgs.push({
      key: entity.entity.id,
      title: entity.entity.name
    });
  });

  const checkinPeriodChoices = [
    { title: '3 hours prior', key: 180 },
    { title: '2.5 hours prior', key: 150 },
    { title: '2 hours prior', key: 120 },
    { title: '1.5 hours prior', key: 90 },
    { title: '1 hour prior', key: 60 },
    { title: '45 minutes prior', key: 45 },
    { title: '30 minutes prior', key: 30 },
    { title: '15 minutes prior', key: 15 }
  ];

  const hasStarted =
    get(activeEvent, 'status', '') === eventStatuses.started.key;
  const isTopCut = get(activeEvent, 'tournament.status', '') === 'stage-two';
  const hasTournamentOverwrite = true;

  const images = [...Array(totalEventBanners).keys()].map(obj =>
    buildBannerUrl(slug, obj + 1)
  );

  const initValues = {
    description: '',
    name: '',
    gameId: id,
    entityId: '',
    startDate: null,
    registrationStartDate: '',
    organizerId: get(user, 'details.id'),
    imageUrl: images[0],
    formatId: formats.length !== 0 ? formats[0]._id : '',
    location: { ...defaultLocation },
    details: {
      capacity: 32,
      checkinPeriod: checkinPeriodChoices[4].key,
      entryFee: 0.0,
      currency: mainCurrencies[0],
      prizing: {
        totalCashValue: 0,
        first: {
          cash: 0,
          product: ''
        },
        second: {
          cash: 0,
          product: ''
        },
        third: {
          cash: 0,
          product: ''
        },
        top8: {
          cash: 0,
          product: ''
        },
        top16: {
          cash: 0,
          product: ''
        },
        participation: {
          cash: 0,
          product: ''
        }
      }
    },
    tournament: {}
  };

  const defaultValues = { ...initValues };
  if (activeEvent) {
    const details = {};
    Object.keys(defaultValues.details).map(key => {
      details[key] = activeEvent.details[key];
    });

    const location = { ...defaultValues.location, ...activeEvent.location };
    Object.keys(defaultValues).map(key => {
      defaultValues[key] = activeEvent[key];
    });

    defaultValues.location = location;
    defaultValues.details = details;

    if (defaultValues.location.isOnline) {
      defaultValues.onlineLocation = defaultValues.location.name;
    }
    defaultValues.tournament = {
      rounds: defaultValues.tournament.rounds
    };
  }
  const [currency, setCurrency] = useState(
    get(defaultValues, 'details.currency', mainCurrencies[0])
  );

  const isLoading = createEventResult.isLoading || updateEventResult.isLoading;

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isSubmitting },
    setValue
  } = useForm({
    defaultValues
  });

  const selectedFormat = watch('formatId');
  const selectedFormatDetails = find(
    formats,
    format => format._id === selectedFormat
  );

  const playType = get(
    selectedFormatDetails,
    'gameplay.playType',
    'tournament'
  );

  useEffect(() => {
    if (selectedFormat) {
      setNestedFormats([]);
      const foundFormat = find(
        formats,
        format => format._id === selectedFormat
      );
      if (!foundFormat) {
        setValue('subFormatId', null);
        return;
      }
      const subFormats = get(foundFormat, 'formatOptions', null);
      if (!subFormats || isEmpty(subFormats)) {
        setValue('subFormatId', null);
        return;
      }
      const subFormatOptions = getOptionArrayFromObject(subFormats);
      if (!subFormatOptions || isEmpty(subFormatOptions)) {
        setValue('subFormatId', null);
        return;
      }
      setValue(
        'subFormatId',
        activeEvent ? get(activeEvent, 'subFormatId') : null
      );
      setNestedFormats(subFormatOptions);
    }
  }, [selectedFormat]);

  const getSelectedEntity = entityId => {
    const availableEntities = get(user, 'permissions.events.entities', []);
    const selectedEntity = find(
      availableEntities,
      entity => entity.entity.id === entityId
    );
    return selectedEntity;
  };

  const doesEntityHavePayoutEnabled = entityId => {
    if (!ticketing) {
      return true;
    }
    const selectedEntity = getSelectedEntity(entityId);
    if (!selectedEntity) {
      return true;
    }
    return get(selectedEntity, 'entity.payoutEnabled', false);
  };

  const onClickNavigateToPayout = entityId => {
    closeModal();
    navigate(buildRoutePath(gameSlug, `/stores/${entityId}/manage`));
  };

  const showEnablePayoutModal = entityId => {
    const selectedOrg = getSelectedEntity(entityId);
    const isOrgAdmin = get(selectedOrg, 'role', '') === 'owner';
    const getText = () => {
      if (isOrgAdmin) {
        return 'Please add a payout method to your account to run events with entry fees. Click the button below to manage your organization.';
      }
      return "To run events with entry fees, your store's admin must add a payout method to the organization's account. Please inform them about this requirement.";
    };
    addModal({
      children: (
        <SimpleModalCard
          title={'Payout not enabled'}
          cancelButton={{
            id: 'cancel-payout-add-suggestion-modal-button',
            title: isOrgAdmin ? 'Cancel' : 'Close'
          }}
          hideConfirmButton={!isOrgAdmin}
          confirmButton={{
            id: 'confirm-payout-add-suggestion-modal-button',
            onClick: () => onClickNavigateToPayout(entityId),
            title: 'Add Payout'
          }}
          showClose
          onClose={closeModal}
        >
          <Typography textAlign={'center'} typography={'h5'}>
            {getText()}
          </Typography>
        </SimpleModalCard>
      ),
      style: { maxWidth: '500px' }
    });
  };

  const onSubmit = async data => {
    toast.isActive(errorToastId) && toast.dismiss(errorToastId);
    const body = { ...data };
    body.details.currency = currency;
    body.details.entryFee = Number(body.details.entryFee).valueOf();
    body.details.capacity = Number(body.details.capacity).valueOf();
    body.description = data.description.json;
    body.descriptionHtml = data.description.html;
    if (!isString(body.startDate)) {
      body.startDate = body.startDate.toISOString();
    }
    if (body.location.isOnline) {
      body.location = {
        ...initValues.location,
        isOnline: true,
        name: body.location.name
      };
    }
    if (body?.tournament?.rounds === '') {
      unset(body, 'tournament');
    }
    unset(body, 'onlineLocation');

    // check for payout if ticketing is enabled
    // and if the event has an entry fee
    if (
      ticketing &&
      !doesEntityHavePayoutEnabled(body.entityId) &&
      body.details.entryFee
    ) {
      showEnablePayoutModal(body.entityId);
      return;
    }

    if (activeEvent) {
      updateEvent({ body, eventId: activeEvent._id });
    } else {
      createEvent({ body });
    }
  };

  const onError = () => {
    toast.error('Missing Information', { toastId: errorToastId });
  };

  useEffect(() => {
    if (createEventResult.isSuccess || updateEventResult.isSuccess) {
      toast.success(`Event ${activeEvent ? 'Updated' : 'Created'}!`);
      navigate(
        buildRoutePath(
          gameSlug,
          `/events/${
            activeEvent
              ? activeEvent._id
              : get(createEventResult.data, 'data._id')
          }`
        )
      );
    }
  }, [createEventResult.isLoading, updateEventResult.isLoading]);

  const idType = activeEvent ? 'edit' : 'create';

  return (
    <form
      id={idType + '-event-form'}
      data-testid={idType + '-event-form'}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <MaxWidthContainer className={'p-percent p-top'}>
        <Grid container columnSpacing={3} rowSpacing={4}>
          <Grid item xs={12}>
            <Typography variant="h1">
              {activeEvent ? 'Edit' : 'Create'} Event
            </Typography>
          </Grid>
          {/* Event Name */}
          <Grid item {...halfItem}>
            <Controller
              name={'name'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id={idType + '-event-name-input'}
                  data-testid={idType + '-event-name-input'}
                  {...field}
                  error={'name' in errors}
                  label={'Event Name'}
                />
              )}
            />
          </Grid>

          {/* Organization */}
          <Grid item {...halfItem}>
            <Controller
              name={'entityId'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id={idType + '-event-org-select'}
                  data-testid={idType + '-event-org-select'}
                  {...field}
                  disabled={hasStarted}
                  error={'entityId' in errors}
                  label={'Organization'}
                  select
                  fullWidth
                >
                  {orgs.map(item => (
                    <MenuItem key={item.title} value={item.key}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Location */}
          <Grid
            id={idType + '-event-location-section'}
            data-testid={idType + '-event-location-section'}
            item
            xs={12}
          >
            <Controller
              name={'location.isOnline'}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  id="event-online-checkbox"
                  data-testid="event-online-checkbox"
                  {...field}
                  disabled={hasStarted}
                  checked={field.value}
                  control={<Checkbox />}
                  label="Is this an Online Event?"
                  sx={{ mb: 2 }}
                />
              )}
            />
            {watch('location.isOnline') && (
              <Controller
                name={'location.name'}
                control={control}
                rules={{
                  validate: value => {
                    return value !== '';
                  }
                }}
                render={({ field }) => {
                  return (
                    <TextField
                      id={idType + '-event-online-location-name'}
                      data-testid={idType + '-event-online-location-name'}
                      {...field}
                      disabled={hasStarted}
                      error={'location' in errors}
                      fullWidth
                      label={'Location Name'}
                    />
                  );
                }}
              />
            )}
            {!watch('location.isOnline') && (
              <Controller
                name={'location'}
                control={control}
                rules={{
                  validate: value => {
                    return (
                      value.name !== '' &&
                      value.city !== '' &&
                      value.address1 !== ''
                    );
                  }
                }}
                render={({ field }) => (
                  <GooglePlaceTextField
                    id={idType + '-event-location-input'}
                    data-testid={idType + '-event-location-input'}
                    {...field}
                    disabled={hasStarted}
                    error={'location' in errors}
                    property={'place'}
                    setCurrency={setCurrency}
                  />
                )}
              />
            )}
          </Grid>

          {/* Start Date & Time for event */}
          <Grid item {...halfItem}>
            <Controller
              name={'startDate'}
              control={control}
              rules={{ required: true, valueAsDate: true }}
              render={({ field }) => (
                <CustomDateTimePicker
                  id={idType + '-event-start-date-select'}
                  data-testid={idType + '-event-start-date-select'}
                  field={field}
                  disabled={hasStarted}
                  error={'startDate' in errors}
                  label={'Start Event Date & Time'}
                />
              )}
            />
          </Grid>

          {/* Sign-up Date & Time (Optional) */}
          <Grid item {...halfItem}>
            <Controller
              name={'registrationStartDate'}
              control={control}
              rules={{
                valueAsDate: true,
                validate: value => {
                  if (value === '' || !value) {
                    return true;
                  }
                  const startDate = dayjs(getValues('startDate'));
                  return dayjs(value).isBefore(startDate, 'minute');
                }
              }}
              render={({ field }) => (
                <CustomDateTimePicker
                  id={idType + '-event-signup-select'}
                  data-testid={idType + '-event-signup-select'}
                  field={field}
                  disabled={hasStarted}
                  error={'registrationStartDate' in errors}
                  label={'Sign-Up Date & Time'}
                />
              )}
            />
            <Typography variant={'body2'} sx={{ marginTop: 1 }}>
              * Optional, Must be before start date.
            </Typography>
          </Grid>

          {/* Format Type */}
          <Grid item {...halfItem}>
            <Controller
              name={'formatId'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id={idType + '-event-format-select'}
                  data-testid={idType + '-event-format-select'}
                  {...field}
                  disabled={hasStarted}
                  error={'formatId' in errors}
                  label={'Format Type'}
                  select
                  fullWidth
                >
                  {formats.map(format => (
                    <MenuItem key={format._id} value={format._id}>
                      {format.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* format sub type */}
          {nestedFormats && nestedFormats.length ? (
            <Grid item {...halfItem}>
              <Controller
                name={'subFormatId'}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    id={idType + '-event-sub-format-select'}
                    data-testid={idType + '-event-sub-format-select'}
                    {...field}
                    disabled={hasStarted}
                    error={'subFormatId' in errors}
                    label={'Format Subtype'}
                    select
                    fullWidth
                  >
                    {nestedFormats.map(format => (
                      <MenuItem
                        key={format._id}
                        value={format._id}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {format.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          ) : null}

          {/* Checkin Period */}
          <Grid item {...halfItem}>
            <Controller
              name={'details.checkinPeriod'}
              control={control}
              render={({ field }) => (
                <TextField
                  id={idType + '-event-checkin-input'}
                  data-testid={idType + '-event-checkin-input'}
                  {...field}
                  disabled={hasStarted}
                  label={'Check-in Period'}
                  select
                  fullWidth
                >
                  {checkinPeriodChoices.map(item => (
                    <MenuItem key={item.title} value={item.key}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Entry Fee*/}
          <Grid item {...halfItem}>
            <Controller
              name={'details.entryFee'}
              control={control}
              render={({ field }) => (
                <Currency
                  id={idType + '-event-entry-fee-input'}
                  data-testid={idType + '-event-entry-fee-input'}
                  {...field}
                  disabled={hasStarted}
                  label={'Entry Fee'}
                  currency={currency}
                  setCurrency={setCurrency}
                />
              )}
            />
          </Grid>

          {/* Max Participants */}
          <Grid item {...halfItem}>
            <Controller
              name={'details.capacity'}
              control={control}
              render={({ field }) => (
                <TextField
                  id={idType + '-event-capacity-input'}
                  data-testid={idType + '-event-capacity-input'}
                  {...field}
                  disabled={hasStarted}
                  fullWidth
                  onChange={event => {
                    if (
                      isNaN(Number(event.target.value)) ||
                      event.target.value.match(/\.+|-+|\++/g)
                    ) {
                      return;
                    }
                    field.onChange(
                      event.target.value === ''
                        ? event
                        : Number(event.target.value).valueOf()
                    );
                  }}
                  InputProps={{
                    inputProps: {
                      min: 2
                    }
                  }}
                  label={`Max ${get(game, 'terminology.players', 'Players')}`}
                />
              )}
            />
          </Grid>

          {/* Tournament Info Section */}
          {hasTournamentOverwrite && playType === 'tournament' && (
            <Grid
              id="tournament-section"
              data-testid="tournament-section"
              item
              xs={12}
            >
              <AccordionMenu
                title={'Tournament Format'}
                disabled={isTopCut}
                elevation={0}
              >
                <Controller
                  name={'tournament'}
                  control={control}
                  render={({ field }) => (
                    <TournamentOverwrites
                      id={idType + '-event-tournament-overwrite-section'}
                      data-testid={
                        idType + '-event-tournament-overwrite-section'
                      }
                      {...field}
                      activeEvent={activeEvent}
                    />
                  )}
                />
              </AccordionMenu>
            </Grid>
          )}

          {/* Background Images */}
          <Grid item xs={12}>
            <AccordionMenu
              id={idType + '-event-image-section'}
              data-testid={idType + '-event-image-section'}
              title={'Event Image'}
              disabled={hasStarted}
              elevation={0}
            >
              <Controller
                control={control}
                name={'imageUrl'}
                render={({ field }) => (
                  <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                      <img src={field.value} width={'100%'} />
                    </Grid>
                    <Grid xs={12} item>
                      <ImageList
                        cols={3}
                        gap={12}
                        sx={theme => ({
                          [theme.breakpoints.down('sm')]: {
                            gridTemplateColumns: 'repeat(2, 1fr) !important',
                            gap: '8px !important'
                          }
                        })}
                      >
                        {images.map(obj => (
                          <ImageListItem key={obj}>
                            <Button
                              id={idType + '-event-select-image-button'}
                              data-testid={
                                idType + '-event-select-image-button'
                              }
                              disableRipple={true}
                              variant={'outlined'}
                              onClick={() => {
                                field.onChange(obj);
                              }}
                              sx={{
                                borderRadius: 0,
                                padding: 0,
                                ...(field.value === obj && {
                                  borderColor: 'inherit'
                                })
                              }}
                            >
                              <img src={obj} width={'100%'} />
                            </Button>
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  </Grid>
                )}
              />
            </AccordionMenu>
          </Grid>

          {/* Prize Information */}
          {playType === 'tournament' && (
            <Grid item xs={12}>
              <AccordionMenu
                id={idType + '-event-prizing-section'}
                data-testid={idType + '-event-prizing-section'}
                title={'Prizing'}
                elevation={0}
              >
                <Grid container spacing={3}>
                  {prizingInfo.map(obj => (
                    <Grid item key={obj.title} xs={12}>
                      <Grid container spacing={3}>
                        {/* Title */}
                        <Grid item xs={12}>
                          <Typography>{obj.title}</Typography>
                        </Grid>

                        {/* Cash */}
                        <Grid item xs={12}>
                          <Controller
                            name={obj.cashKey}
                            control={control}
                            render={({ field }) => (
                              <Currency
                                id={idType + '-event-cash-prize-input'}
                                data-testid={idType + '-event-cash-prize-input'}
                                {...field}
                                label={'Cash Prize'}
                                currency={currency}
                                setCurrency={setCurrency}
                              />
                            )}
                          />
                        </Grid>

                        {/* Product */}
                        <Grid item xs={12}>
                          <Controller
                            name={obj.productKey}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                id={idType + '-event-product-prize-input'}
                                data-testid={
                                  idType + '-event-product-prize-input'
                                }
                                {...field}
                                fullWidth
                                label={'Product Prize'}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionMenu>
            </Grid>
          )}

          {/* Rich Text Editor */}
          <Grid item xs={12}>
            <AccordionMenu
              id={idType + '-event-description-section'}
              data-testid={idType + '-event-description-section'}
              elevation={0}
              defaultExpanded={true}
              title={'Event Details'}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <Editor
                    id={idType + '-event-description-input'}
                    data-testid={idType + '-event-description-input'}
                    {...field}
                    initState={field.value}
                  />
                )}
                name={'description'}
              />
            </AccordionMenu>
          </Grid>

          {/* Submit Button */}
          <Grid item container xs={12} justifyContent={'center'}>
            <Grid item {...halfItem}>
              <LoadingButton
                id={idType + '-event-submit-button'}
                data-testid={idType + '-event-submit-button'}
                variant={'contained'}
                type={'submit'}
                loading={isSubmitting || isLoading}
                fullWidth
              >
                {activeEvent ? 'Update' : 'Create'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </form>
  );
};

export default AddNewEventPage;
