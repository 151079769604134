import { Grid, styled } from '@mui/material';
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { GooglePlaceTextField } from '../../../../../components/Shared/GooglePlaceTextField/index';
import { LoadingButton } from '@mui/lab';
import { StyledSelectTextField } from '../../../../../components/Shared/TextFields/index';

const StyledStoreDistanceSelectTextField = styled(StyledSelectTextField)(
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      '& .MuiSelect-select': {
        padding: '12px 10px',
        fontSize: '24px',
        lineHeight: '30px'
      }
    }
  })
);

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 64px'
}));

const StoreSearchFilters = ({ onSearch = () => {}, isLoading = false }) => {
  const [searchLocation, setSearchLocation] = useState(null);
  const [searchRadius, setSearchRadius] = useState(100);
  const [emitInitial, setEmitInitial] = useState(false);
  const radiusPredefinedMiles = [10, 25, 50, 100, 250, 500];
  const radiusOptions = [
    ...radiusPredefinedMiles.map(predefinedMile => {
      return {
        _id: predefinedMile,
        name: `${predefinedMile} miles`
      };
    })
  ];
  const onClickSearch = () => {
    onSearch({
      location: searchLocation,
      radius: searchRadius
    });
  };

  useEffect(() => {
    if (!emitInitial && searchLocation && searchRadius) {
      setEmitInitial(false);
      onClickSearch();
    }
  }, [emitInitial, setEmitInitial, searchLocation, searchRadius]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <GooglePlaceTextField
          id="store-location-search-input"
          data-testid="store-location-search-input"
          value={searchLocation}
          disabled={false}
          property={'place'}
          onChange={data => setSearchLocation(data)}
          preFillCurrentLocation
          compact
        />
      </Grid>
      <Grid item xs={12} sm={4} mt={{ xs: 1, sm: 0 }}>
        <StyledStoreDistanceSelectTextField
          id="events-location-radius-select"
          data-testid="events-location-radius-select"
          idPrefix="events-location-radius"
          label="Distance"
          value={searchRadius}
          onChange={event => setSearchRadius(event.target.value)}
          menuItems={radiusOptions}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        mt={{ xs: 1, sm: 0 }}
        textAlign={{ xs: 'center', sm: 'initial' }}
      >
        <StyledLoadingButton
          disabled={!searchLocation || !searchRadius}
          onClick={onClickSearch}
          loading={isLoading}
          variant="contained"
        >
          Search
        </StyledLoadingButton>
      </Grid>
    </Grid>
  );
};

export default StoreSearchFilters;
