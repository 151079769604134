export const modalPaperStyle = {
  position: 'absolute',
  overflow: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90vw',
    md: '60vw'
  },
  maxWidth: '800px',
  borderRadius: 4,
  padding: 3
};

export const closeButtonStyle = {
  position: 'absolute',
  top: '2px',
  right: '2px'
};

export const modalContainerStyle = ({ theme, scrollVisible }) => ({
  maxHeight: '80vh',
  overflow: 'auto',
  ['&::-webkit-scrollbar']: {
    display: !scrollVisible && 'none',
    height: '100%',
    width: { xs: '24px', sm: '28px' }
  },
  ['&::-webkit-scrollbar-track']: {
    background: 'transparent'
  },
  ['&::-webkit-scrollbar-thumb']: {
    borderRadius: { xs: '12px', sm: '14px' },
    border: '8px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box',
    backgroundColor: theme.palette.primary.main
  },
  ['&::-webkit-scrollbar-thumb:hover']: {
    backgroundColor: theme.palette.primary.dark
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  padding: 0
});
