import React, { useState } from 'react';
import { IconButton, useMediaQuery } from '@mui/material';
import { useSwiper } from 'swiper/react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';

const NavigationButton = ({ prev }) => {
  const swiper = useSwiper();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  // eslint-disable-next-line no-unused-vars
  const [disable, setDisable] = useState(false);
  const pConst = isSmall ? 0 : '2%';

  const style = {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '35%',
    left: prev ? pConst : 'unset',
    right: prev ? 'unset' : pConst,
    zIndex: 10
  };

  return (
    <IconButton
      sx={style}
      disabled={disable}
      onClick={() => {
        prev ? swiper.slidePrev() : swiper.slideNext();
      }}
    >
      {prev ? (
        <ArrowBackIosNewIcon fontSize={'large'} />
      ) : (
        <ArrowForwardIosIcon fontSize={'large'} />
      )}
    </IconButton>
  );
};

export default NavigationButton;
