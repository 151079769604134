/* eslint-disable no-unused-vars */
import { Avatar, Box, Grid, Paper, Typography, styled } from '@mui/material';
import { get, isEmpty, kebabCase } from 'lodash';

import React from 'react';
import { getJudgeFullName } from '../../../../../../utils/judge-user';

const JudgeInfoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding-left: 16px;
`;

const JudgeContainer = styled(Paper)(({ isSelected, theme }) => ({
  marginBottom: '12px',
  backgroundColor: theme.palette.neutral[400],
  cursor: 'pointer',
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '16px',
  outline: 0,
  boxShadow: `${
    isSelected ? theme.palette.primary.main : 'rgba(0,0,0,0.2)'
  } 0px 0px 16px 4px`,
  transition: 'transform 0.5s ease-in-out',
  '&:hover': {
    transform: 'scale(1.04)'
  }
}));

export const AvailableJudge = ({
  judgeInfo = {},
  isSelected = false,
  onCheck = () => {}
}) => {
  const judgeName = getJudgeFullName(judgeInfo);
  const judgeId = get(judgeInfo, 'judgeId', '');
  if (isEmpty(judgeInfo)) {
    return null;
  }
  return (
    <JudgeContainer
      id={`available-judge-${kebabCase(judgeName)}-button-option`}
      data-testid={`available-judge-${kebabCase(judgeName)}-button-option`}
      onClick={() => onCheck(judgeInfo._id)}
      isSelected={isSelected}
    >
      <Avatar
        src={get(judgeInfo, 'gameUser.imageUrl', '')}
        sx={{ height: '64px', width: '64px', mr: '8px' }}
      />
      <JudgeInfoContainer>
        <Typography variant="h5">{judgeName}</Typography>
        {judgeId && <Typography variant="subtitle3">{judgeId}</Typography>}
      </JudgeInfoContainer>
    </JudgeContainer>
  );
};
