import { Box, Grid, IconButton, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { filter, find, get, isEmpty, kebabCase } from 'lodash';
import {
  useGetDecksQuery,
  usePostRegisterDeckToEventMutation,
  useUnregisterDeckFromEventMutation
} from '../../../../../../services/apis/organizePlayApi';

import CloseIcon from '@mui/icons-material/Close';
import InfiniteScroll from '../../../../../InfiniteScroll';
import Loading from '../../../../../Loading/Loader';
import { LoadingButton } from '@mui/lab';
import { ModalContext } from '../../../../../Modal/Context/ModalContext';
import { SelectableDeckPreview } from '../SelectableDeckPreview';
import { StyledSearchTextField } from '../../../../../Shared/TextFields';
import { toast } from 'react-toastify';
import { useEventSubTypeQueryParams } from '../../../../../../hooks/events/useEventSubTypeQueryParams';
import { usePagination } from '../../../../../../hooks/pagination/usePagination';
import { useSelector } from 'react-redux';

export const CloseContainer = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const AddDeckToEventDeckListingView = ({
  activeEvent,
  onSelectViewDetails,
  fromDetailsDeckId = null
}) => {
  const { closeModal, setIsLoading } = useContext(ModalContext);

  const { game } = useSelector(({ app }) => app);
  const gameId = game?.id;
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const [decks, setDecks] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const [focusDeckId, setFocusDeckId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({});
  const subTypeQuery = useEventSubTypeQueryParams(activeEvent);

  useEffect(() => {
    const queryObj = {
      gameId,
      getMyDecks: true,
      formatId: activeEvent.formatId,
      page: currentPage,
      limit: 9
    };
    if (subTypeQuery) {
      queryObj.formatOptionsName = subTypeQuery.name;
      queryObj.formatOptionsValue = subTypeQuery.value;
    }
    setQuery(queryObj);
  }, [currentPage, gameId, activeEvent, subTypeQuery]);

  const {
    data: allDecksData,
    isLoading,
    isFetching,
    isSuccess
  } = useGetDecksQuery(query, {
    skip: isEmpty(query) || !game?.id
  });

  const { loadAction, treatedData, nextPage, totalResults } = usePagination({
    data: allDecksData,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });

  const [
    registerDeck,
    registerDeckResult
  ] = usePostRegisterDeckToEventMutation();

  const [
    unregisterDeck,
    unregisterDeckResult
  ] = useUnregisterDeckFromEventMutation();

  const getRegisteredDeck = () => {
    const isDeckRegistered = get(activeEvent, 'isDeckRegistered', false);
    const deck = get(activeEvent, 'registeredDeck', null);
    return isDeckRegistered && !isEmpty(deck) ? deck : null;
  };

  useEffect(() => {
    const deck = getRegisteredDeck();
    if (!isEmpty(deck)) {
      const deckId = get(deck, 'deck._id', null);
      setSelectedDeckId(deckId);
      if (!fromDetailsDeckId) {
        setFocusDeckId(deckId);
      }
    }
  }, [activeEvent, decks, fromDetailsDeckId]);

  const getDeckSectionId = deckId => {
    return `deck_list_item_${deckId}`;
  };

  useEffect(() => {
    if (focusDeckId && decks.length) {
      const id = getDeckSectionId(focusDeckId);
      const element = document.querySelector(`#${id}`);
      if (element) {
        element.scrollIntoView({
          block: 'end',
          inline: 'nearest'
        });
      }
      setFocusDeckId(null);
    }
  }, [focusDeckId, decks, fromDetailsDeckId]);

  useEffect(() => {
    if (fromDetailsDeckId) {
      setFocusDeckId(fromDetailsDeckId);
    }
  }, [fromDetailsDeckId]);

  useEffect(() => {
    if (!isLoading && isSuccess && !isEmpty(get(allDecksData, 'data', []))) {
      setDecks(allDecksData.data);
    } else {
      setDecks([]);
    }
  }, [allDecksData, isLoading, isSuccess]);

  useEffect(() => {
    if (searchText) {
      setDecks(
        filter(get(allDecksData, 'data', []), ({ deck }) => {
          return deck.name.toLowerCase().includes(searchText.toLowerCase());
        })
      );
    } else {
      setDecks(get(allDecksData, 'data', []));
    }
  }, [searchText]);

  const getSelectedDeckName = () => {
    if (!selectedDeckId) {
      return '';
    }
    const findDeck = find(
      allDecksData.data,
      deck => get(deck, 'deck._id') === selectedDeckId
    );
    return get(findDeck, 'deck.name', '');
  };

  useEffect(() => {
    setIsActionDisabled(registerDeckResult.isLoading);
    setIsLoading(registerDeckResult.isLoading);
    if (registerDeckResult.isSuccess) {
      toast.success(`Deck "${getSelectedDeckName()}" successfully added`);
      closeModal();
    } else if (registerDeckResult.isError) {
      toast.error(
        registerDeckResult.error?.data?.errorMessage || 'Unable to update deck'
      );
    }
  }, [registerDeckResult]);

  useEffect(() => {
    setIsActionDisabled(unregisterDeckResult.isLoading);
    setIsLoading(unregisterDeckResult.isLoading);
    if (unregisterDeckResult.isSuccess) {
      toast.success(`Deck "${getSelectedDeckName()}" successfully removed`);
      closeModal();
    } else if (unregisterDeckResult.isError) {
      toast.error(
        unregisterDeckResult.error?.data?.errorMessage ||
          'Unable to remove deck'
      );
    }
  }, [unregisterDeckResult]);

  const onClose = () => {
    closeModal();
  };

  const isDeckSelected = deckId => {
    if (!selectedDeckId || !deckId) {
      return false;
    }
    return deckId === selectedDeckId;
  };

  const onDeckSelect = deckId => {
    if (!deckId) {
      return;
    }
    if (isDeckSelected(deckId)) {
      setSelectedDeckId(null);
    } else {
      setSelectedDeckId(deckId);
    }
  };

  const onClickActionButton = () => {
    const deck = getRegisteredDeck();
    if (deck && selectedDeckId === get(deck, 'deck._id', null)) {
      unregisterDeck({ eventId: activeEvent._id });
    } else {
      registerDeck({ eventId: activeEvent._id, deckId: selectedDeckId });
    }
  };

  const getActionButtonColor = () => {
    const deck = getRegisteredDeck();
    if (!deck) {
      return 'primary';
    }
    if (selectedDeckId && selectedDeckId === get(deck, 'deck._id')) {
      return 'error';
    }
    return 'info';
  };

  const getActionButtonText = () => {
    const deck = getRegisteredDeck();
    if (!deck) {
      return 'Import';
    }
    if (selectedDeckId && selectedDeckId === get(deck, 'deck._id')) {
      return 'Remove';
    }
    return 'Change';
  };

  const renderHeaderSection = () => {
    return (
      <Grid item xs={12}>
        <Grid container alignItems="center" mt="16px">
          <CloseContainer>
            <IconButton
              id="add-deck-list-modal-close-button"
              data-testid="add-deck-list-modal-close-button"
              aria-label="close"
              onClick={onClose}
              disabled={isActionDisabled}
            >
              <CloseIcon />
            </IconButton>
          </CloseContainer>
          <Grid xs={10} item>
            <Typography
              noWrap
              sx={{ typography: { xs: 'h5', sm: 'h3' } }}
              align="left"
              xs={12}
            >
              {get(game, 'terminology.deck', 'Deck')} List
            </Typography>
          </Grid>
          <Grid xs={2} item>
            <Typography noWrap variant="subtitle1" align="right" xs={2}>
              ({totalResults ? totalResults : 0})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSearchAndFilterSection = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledSearchTextField
              id="add-deck-list-search-deck-input"
              label=""
              value={searchText}
              placeholder={`Search ${get(game, 'terminology.deck', 'Deck')}`}
              onChange={event => setSearchText(event.target.value)}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Button
              variant="outlined"
              sx={{
                minWidth: '200px',
                justifyContent: 'space-between',
                borderRadius: '12px'
              }}
              endIcon={<FilterIcon color={theme.palette.primary.main} />}
            >
              Filter
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} sx={{ padding: { xs: '16px', sm: '32px' } }}>
      {renderHeaderSection()}
      {renderSearchAndFilterSection()}
      {/* main deck list starts */}
      <Grid item xs={12} my="24px">
        <Grid
          container
          spacing={0}
          py="8px"
          minHeight="25vh"
          maxHeight="40vh"
          overflow="scroll"
        >
          <InfiniteScroll
            isLoading={isLoading}
            done={nextPage === 0 || totalResults === treatedData?.length}
            loaderAction={loadAction}
            loader={<Loading />}
            useWindow={false}
            mainContainerStyleOverride={{ width: '100%' }}
          >
            {treatedData && treatedData.length ? (
              <Grid item xs={12}>
                <Grid container>
                  {treatedData.map(deck => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={deck.deck._id}
                      id={getDeckSectionId(deck.deck._id)}
                      data-testid={getDeckSectionId(deck.deck._id)}
                    >
                      <SelectableDeckPreview
                        deck={deck}
                        isSelected={isDeckSelected(deck.deck._id)}
                        onChangeSelect={() => onDeckSelect(deck.deck._id)}
                        onSelectViewDetails={() =>
                          onSelectViewDetails(deck.deck._id)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <Grid xs={12} item>
                <Typography textAlign="center">No decks available</Typography>
              </Grid>
            )}
          </InfiniteScroll>
        </Grid>
      </Grid>
      {/* main deck list ends */}
      {/* action section starts */}
      <Grid item xs={12} textAlign="right">
        <LoadingButton
          id={`add-deck-modal-${kebabCase(getActionButtonText())}-button`}
          data-testid={`add-deck-modal-${kebabCase(
            getActionButtonText()
          )}-button`}
          color={getActionButtonColor()}
          variant="contained"
          disabled={!selectedDeckId}
          loading={isActionDisabled}
          onClick={onClickActionButton}
        >
          {getActionButtonText()}
        </LoadingButton>
      </Grid>
      {/* action section ends */}
    </Grid>
  );
};
