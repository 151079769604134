export const deckTubeStyle = theme => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 1.5,
  background: '#FFF1',
  border: `1px solid ${theme?.border?.borderColor}`,
  height: '100%',
  ['.deck-tube-typography']: {
    typography: { xs: 'caption', sm: 'body2' }
  }
});
