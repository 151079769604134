import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { LocationIcon } from '../../../../components/Icon/vectors';
import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SimpleModalCard from '../../../../components/Modal/Cards/SimpleModalCard';
import { buildRoutePath } from '../../../../utils/routingUtils';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { usePostDeleteEntityMutation } from '../../../../services/apis/organizePlayApi';

const StyledStoreItemContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '12px 20px',
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  position: 'relative',
  borderRadius: '12px'
}));

const StyledStoreInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiAvatar-root': {
    fontSize: '36px',
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    flex: 1
  }
}));

const StyledStoreActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '8px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    position: 'absolute',
    top: '0px',
    right: '12px'
  }
}));

const StyledStoreInfoTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  flexDirection: 'column',
  marginLeft: '24px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledStoreLocationTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));

// eslint-disable-next-line no-unused-vars
const StyledStoreMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiList-root': {
      padding: '12px 0',
      '& .MuiButtonBase-root': {
        fontSize: '16px',
        '&:not(:last-child)': {
          marginBottom: '8px'
        },
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }
  }
}));

const StoreItem = ({ store, itemIndex = 0, gameId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const { addModal, closeModal, setIsLoading } = useContext(ModalContext);
  const [deleteEntity, deleteEntityRes] = usePostDeleteEntityMutation();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onManageStoreClick = () => {
    handleClose();
    navigate(buildRoutePath(gameSlug, `/stores/${store._id}/manage`));
  };
  const onDeleteConfirmClick = () => {
    setIsLoading(true);
    deleteEntity({
      storeId: store._id,
      gameId
    });
  };
  useEffect(() => {
    setIsLoading(deleteEntityRes.isLoading);
    if (deleteEntityRes.isSuccess) {
      closeModal();
      toast.success('Store removed successfully');
    }
    if (deleteEntityRes.isError) {
      toast.error('Error removing store');
    }
  }, [deleteEntityRes]);
  const onDeleteStoreClick = () => {
    handleClose();
    addModal({
      children: (
        <SimpleModalCard
          title={'Remove store?'}
          cancelButton={{ id: 'delete-store-cancel-button' }}
          confirmButton={{
            id: 'delete-store-confirm-button',
            onClick: () => onDeleteConfirmClick(),
            title: 'Remove',
            color: 'error'
          }}
        >
          <Typography textAlign={'center'} typography={'h4'}>
            Are you sure?
          </Typography>
        </SimpleModalCard>
      ),
      style: { maxWidth: '500px' }
    });
  };
  const theme = useTheme();
  const getStoreAbbreviation = () => {
    const name = get(store, 'name', '');
    const abbreviation = name
      .split(' ')
      .map(word => word[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
    return abbreviation;
  };
  return (
    <Grid container mb={2}>
      <Grid item xs={12}>
        <StyledStoreItemContainer
          data-testid={`store-listing-item-${itemIndex}`}
        >
          <StyledStoreInfoContainer>
            <Avatar
              src={get(store, 'imageUrl')}
              variant="rounded"
              sx={{ width: '140px', height: '100px', borderRadius: '12px' }}
            >
              {getStoreAbbreviation()}
            </Avatar>
            <StyledStoreInfoTextContainer>
              <Link
                to={buildRoutePath(gameSlug, `/stores/${store._id}`)}
                component={RouterLink}
                underline="none"
              >
                <Typography variant="h3" mb={1}>
                  {get(store, 'name')}
                </Typography>
              </Link>
              <StyledStoreLocationTextContainer>
                <LocationIcon color="currentColor" />
                <Typography
                  ml={1}
                  variant="body2Light"
                  color={theme.palette.text.primary}
                >
                  {get(store, 'location.primary.name')}
                </Typography>
              </StyledStoreLocationTextContainer>
            </StyledStoreInfoTextContainer>
          </StyledStoreInfoContainer>
          <StyledStoreActionContainer
            data-testid={`store-listing-item-action-${itemIndex}`}
          >
            <IconButton
              id={`store-list-action-button-${itemIndex}`}
              data-testid={`store-list-action-button-${itemIndex}`}
              aria-controls={open ? 'store-list-action-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledStoreMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem
                data-testid={`store-list-action-button-${itemIndex}-manage-store`}
                onClick={onManageStoreClick}
              >
                Manage Store
              </MenuItem>
              <MenuItem
                data-testid={`store-list-action-button-${itemIndex}-remove-store`}
                onClick={onDeleteStoreClick}
              >
                Remove Store
              </MenuItem>
            </StyledStoreMenu>
          </StyledStoreActionContainer>
        </StyledStoreItemContainer>
      </Grid>
    </Grid>
  );
};

export default StoreItem;
