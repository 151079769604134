import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  useGetSingleEventQuery,
  useUpdateEventJudgesMutation
} from '../../../../services/apis/organizePlayApi';

import { ModalContext } from '../../../Modal/Context/ModalContext';
import SimpleModalCard from '../../../Modal/Cards/SimpleModalCard';
import { getJudgeFullName } from '../../../../utils/judge-user';

export const JudgeRemoveConfirm = ({ eventId, judgeInfo }) => {
  const { closeModal, setIsLoading } = useContext(ModalContext);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [judgeRemoved, setJudgeRemoved] = useState(false);

  const [
    addUpdateJudges,
    addUpdateJudgesResult
  ] = useUpdateEventJudgesMutation();

  const { isLoading, isFetching, isSuccess } = useGetSingleEventQuery(eventId);

  const judgeName = getJudgeFullName(judgeInfo);

  useEffect(() => {
    if (addUpdateJudgesResult.isSuccess) {
      setJudgeRemoved(true);
    }
  }, [addUpdateJudgesResult.isLoading]);

  useEffect(() => {
    if (judgeRemoved && !isFetching && isSuccess) {
      setRemoveLoading(false);
      setIsLoading(false);
      closeModal();
    }
  }, [judgeRemoved, isLoading, isFetching, isSuccess]);

  const onClickRemoveJudge = () => {
    setRemoveLoading(true);
    setIsLoading(true);
    addUpdateJudges({
      eventId,
      body: {
        removeJudges: [judgeInfo._id]
      }
    });
  };

  return (
    <SimpleModalCard
      title="Remove Judge?"
      cancelButton={{ id: 'remove-judge-cancel-button' }}
      confirmButton={{
        id: 'remove-judge-confirm-button',
        color: 'error',
        title: 'Remove',
        disabled: removeLoading,
        onClick: onClickRemoveJudge
      }}
      isCloseDisabled={removeLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            Remove judge {judgeName} from this event?
          </Typography>
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};
