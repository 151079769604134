import { Avatar, styled } from '@mui/material';
import { GeneralCard } from '../../../../components/Shared/Cards/styles';

export const PlayerScoreCard = styled(GeneralCard)`
  background: ${({ theme }) => theme.palette.neutral['200']}1A;
  padding: 4px 8px;

  text-align: center;
  .unchecked {
    background: ${({ theme }) => theme.palette.error.main};
  }

  &.win {
    background: ${props => props.theme.palette.success.main};
  }

  &.loss {
    background: ${({ theme }) => theme.palette.error.main};
  }

  &.draw {
    background: ${({ theme }) => theme.palette.info.main};
  }
`;

export const PlayerResult = styled(Avatar)`
  background: ${({ theme }) => theme.palette.neutral['200']}1A;
  border: 2px solid ${({ theme }) => theme.palette.neutral['200']}1A;
  height: 56px;
  width: 56px;

  color: white;
  &.win {
    background: ${props => props.theme.palette.success.main};
  }

  &.loss {
    background: ${({ theme }) => theme.palette.error.main};
  }

  &.draw {
    background: ${({ theme }) => theme.palette.info.main};
  }

  &.bye {
    height: 82px;
    width: 82px;
  }
`;
