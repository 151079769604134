import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { get } from 'lodash';
import qs from 'qs';

const currentRoutePath = '/v1';
const currentRoutePathV2 = '/rewards';
const currentRoutePathManage = '/manage';
const currentRoutePathCore = '/core';
const currentRoutePathPlay = '/play';

// eslint-disable-next-line no-unused-vars
const paths = {
  games: 'game',
  users: 'users'
};

export const rtkEndPointNames = {
  deleteDeck: 'deleteDeck',
  getAvailableJudges: 'getAvailableJudges',
  getCards: 'getCards',
  getCardsFilters: 'getCardsFilters',
  getCurrentUserPairing: 'getCurrentUserPairing',
  getDecks: 'getDecks',
  getEventsByGame: 'getEventsByGame',
  getRankings: 'getRankings',
  getSingleDeck: 'getSingleDeck',
  getSingleEvent: 'getSingleEvent',
  getSingleEventPairings: 'getSingleEventPairings',
  getSingleEventRoster: 'getSingleEventRoster',
  getSingleEventStandings: 'getSingleEventStandings',
  getStoreSearch: 'getStoreSearch',
  getUserByEmail: 'getUserByEmail',
  getUserRankInfo: 'getUserRankInfo',
  getUserRankingEventHistory: 'getUserRankingEventHistory',
  getUserRankingScoreHistory: 'getUserRankingScoreHistory',
  postAddParticipantToEvent: 'postAddParticipantToEvent',
  postCheckDeckValidation: 'postCheckDeckValidation',
  postResendVerifyEmail: 'postResendVerifyEmail',
  postCreateDeck: 'postCreateDeck',
  postCreateEvent: 'postCreateEvent',
  postStartEvent: 'postStartEvent',
  postStartEventTime: 'postStartEventTime',
  postUpdateEvent: 'postUpdateEvent',
  postUpdateEventParticipant: 'postUpdateEventParticipant',
  postUpdatePairingResult: 'postUpdatePairingResult',
  postRegisterDeckToEvent: 'postRegisterDeckToEvent',
  putUpdateDeck: 'putUpdateDeck',
  putUpdateUserInfo: 'putUpdateUserInfo',
  putUpdateUserInfoByGame: 'putUpdateUserInfoByGame',
  updateEventJudges: 'updateEventJudges',
  unregisterDeckFromEvent: 'unregisterDeckFromEvent'
};

export const organizePlayApi = createApi({
  reducerPath: 'organizePlayApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MIDDLEWARE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth, app } = getState();
      const gameId = get(app, 'game.pgGame.id', '');
      if (gameId) {
        headers.set('game-id', gameId);
      }
      const token = get(auth, 'token', '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Rankings', 'Roster', 'Event', 'Pairings', 'Standings', 'Wallets'],
  endpoints: builder => ({
    getAvailableJudges: builder.query({
      query: eventId => `/v1/events/${eventId}/available-judges`,
      providesTags: ['Judges']
    }),
    getCards: builder.query({
      query: ({ gameId, query }) => ({
        url: `${currentRoutePath}/cards/${gameId}`,
        method: 'GET',
        params: query
      })
    }),
    getCardsFilters: builder.query({
      query: gameId => ({
        url: `${currentRoutePath}/cards/${gameId}/filters`,
        method: 'GET'
      })
    }),
    getCurrentUserPairing: builder.query({
      query: ({ eventId, roundNumber, ...restQuery }) => ({
        url: `${currentRoutePath}/events/single/${eventId}/pairings/current-user`,
        method: 'GET',
        params: {
          roundNumber,
          ...restQuery
        }
      }),
      providesTags: ['CurrentUserPairings']
    }),
    getDecks: builder.query({
      query: ({ gameId, getMyDecks, ...restParams }) => ({
        url: `${currentRoutePath}/game/${gameId}/${
          getMyDecks ? 'my-decks' : 'decks'
        }`,
        params: {
          ...restParams
        },
        paramsSerializer: params => qs.stringify(params)
      }),
      providesTags: ['Decks']
    }),
    getEventsByGame: builder.query({
      query: ({ gameId, query }) => ({
        url: `${currentRoutePath}/events/${gameId}${query ? `?${query}` : ''}`,
        method: 'GET'
      })
    }),
    getEventsByEntityId: builder.query({
      query: ({ gameId, entityId, startDate }) => ({
        url: `${currentRoutePath}/events/${gameId}`,
        method: 'GET',
        params: {
          entityId,
          startDate
        }
      })
    }),
    getRankings: builder.query({
      query: searchQuery => ({
        url: `${currentRoutePath}/rankings${
          searchQuery ? `?${searchQuery}` : ''
        }`,
        method: 'GET'
      }),
      providesTags: ['Rankings']
    }),
    getSingleDeck: builder.query({
      query: deckId => ({
        url: `${currentRoutePath}/decks/${deckId}`
      }),
      providesTags: ['SingleDeck']
    }),
    getSingleEvent: builder.query({
      query: eventId => `${currentRoutePath}/events/single/${eventId}`,
      providesTags: ['Event']
    }),
    getSingleEventPairings: builder.query({
      query: ({ eventId, roundNumber, ...restQuery }) => ({
        url: `${currentRoutePath}/events/single/${eventId}/pairings`,
        method: 'GET',
        params: {
          roundNumber,
          ...restQuery
        }
      }),
      providesTags: ['Pairings']
    }),
    getSingleEventRoster: builder.query({
      query: ({ eventId, page, limit, searchText }) => ({
        url: `${currentRoutePath}/events/single/${eventId}/roster`,
        method: 'GET',
        params: { page, limit, searchText }
      }),
      providesTags: ['Roster']
    }),
    getSingleEventStandings: builder.query({
      query: ({ eventId, page, limit, searchText }) => ({
        url: `${currentRoutePath}/events/single/${eventId}/standings`,
        method: 'GET',
        params: { page, limit, searchText }
      }),
      providesTags: ['Standings']
    }),
    getUserWallets: builder.query({
      query: () => ({
        url: `${currentRoutePathV2}/wallets`,
        method: 'GET'
      }),
      providesTags: ['Wallets']
    }),
    getUserWalletTransactions: builder.query({
      query: id => ({
        url: `${currentRoutePathV2}/wallets/${id}/transactions`,
        method: 'GET'
      }),
      providesTags: ['Wallets']
    }),
    getUserWalletTransactionsGraph: builder.query({
      query: ({ id, ...restQuery }) => ({
        url: `${currentRoutePathV2}/wallets/${id}/transactions-graph`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['TransactionGraph']
    }),
    getUserByEmail: builder.query({
      query: ({ userEmail, query = '' }) => ({
        url: `v1/users/email/${userEmail}${query}`,
        method: 'GET'
      })
    }),
    getUserRankInfo: builder.query({
      query: ({ userId, query = '' }) => ({
        url: `${currentRoutePath}/rankings/user/${userId}${query}`,
        method: 'GET'
      })
    }),
    deleteDeck: builder.mutation({
      query: deckId => ({
        url: `${currentRoutePath}/decks/${deckId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Decks', 'SingleDeck']
    }),
    postAddParticipantToEvent: builder.mutation({
      query: ({ body, eventId }) => ({
        url: `${currentRoutePath}/events/${eventId}/participants`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Roster', 'Event']
    }),
    postCheckDeckValidation: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePath}/decks/validate`,
        method: 'POST',
        body
      })
    }),
    postResendVerifyEmail: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePath}/${paths.users}/resend-verification`,
        method: 'POST',
        body
      })
    }),
    postCreateDeck: builder.mutation({
      query: ({ body, gameId }) => ({
        url: `${currentRoutePath}/game/${gameId}/decks`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Decks']
    }),
    postCreateEvent: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePath}/events`,
        method: 'POST',
        body
      })
    }),
    postStartEvent: builder.mutation({
      query: ({
        eventId,
        type = 'start',
        playOffCount = null,
        format = 'swiss',
        updateEvent = 0
      }) => ({
        url: `/v1/events/${eventId}/${type}`,
        method: 'POST',
        body: {
          playOffCount,
          format,
          updateEvent
        }
      }),
      invalidatesTags: ['Event', 'Pairings', 'CurrentUserPairings']
    }),
    postStartEventTime: builder.mutation({
      query: ({ eventId, startTime }) => ({
        url: `/v1/events/single/${eventId}/start-timer`,
        method: 'POST',
        body: {
          startTime
        }
      }),
      invalidatesTags: ['Pairings', 'CurrentUserPairings']
    }),
    postUpdateEvent: builder.mutation({
      query: ({ body, eventId }) => ({
        url: `${currentRoutePath}/events/${eventId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Event', 'Pairings']
    }),
    postUpdateEventParticipant: builder.mutation({
      query: ({ body, eventId, userId }) => ({
        url: `${currentRoutePath}/events/${eventId}/participants/${userId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Roster', 'Event']
    }),
    postUpdatePairingResult: builder.mutation({
      query: ({ body, eventId }) => ({
        url: `${currentRoutePath}/events/${eventId}/results`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Pairings', 'Standings', 'CurrentUserPairings']
    }),
    postRegisterDeckToEvent: builder.mutation({
      query: ({ body, eventId, deckId }) => ({
        url: `${currentRoutePath}/events/${eventId}/register-deck/${deckId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Roster', 'Event']
    }),
    updateEventJudges: builder.mutation({
      query: ({ body, eventId }) => ({
        url: `/v1/events/${eventId}/update-event-judges`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Event', 'Judges']
    }),
    putUpdateDeck: builder.mutation({
      query: ({ body, deckId }) => ({
        url: `${currentRoutePath}/decks/${deckId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Decks', 'SingleDeck']
    }),
    putUpdateUserInfo: builder.mutation({
      query: ({ body, id }) => ({
        url: `${currentRoutePath}/${paths.users}/${id}`,
        method: 'PUT',
        body
      })
    }),
    putUpdateUserInfoByGame: builder.mutation({
      query: ({ body, id, gameId }) => ({
        url: `${currentRoutePath}/${paths.users}/${id}/${gameId}`,
        method: 'PUT',
        body
      })
    }),
    unregisterDeckFromEvent: builder.mutation({
      query: ({ body, eventId }) => ({
        url: `${currentRoutePath}/events/${eventId}/unregister-deck`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['Roster', 'Event']
    }),
    getUserRankingEventHistory: builder.query({
      query: ({ userId, ...restQuery }) => ({
        url: `${currentRoutePath}/rankings/event-history/user/${userId}`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['UserRankingEventHistory']
    }),
    getUserRankingScoreHistory: builder.query({
      query: ({ userId, ...restQuery }) => ({
        url: `${currentRoutePath}/rankings/score-history/user/${userId}`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['UserRankingScoreHistory']
    }),
    getStoreSearch: builder.query({
      query: query => ({
        url: `${currentRoutePath}/entity/search`,
        method: 'GET',
        params: query
      }),
      providesTags: ['Stores']
    }),
    getProducts: builder.query({
      query: query => {
        return {
          url: `${currentRoutePathV2}/products?${qs.stringify(query, {
            arrayFormat: 'repeat'
          })}`,
          method: 'GET'
        };
      },
      providesTags: ['Products']
    }),
    getProductById: builder.query({
      query: productId => ({
        url: `${currentRoutePathV2}/products/${productId}`,
        method: 'GET'
      }),
      providesTags: ['SingleProduct']
    }),
    getCartDetails: builder.query({
      query: query => ({
        url: `${currentRoutePathV2}/cart`,
        method: 'GET',
        params: query
      }),
      providesTags: ['Cart']
    }),
    postAddToCart: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePathV2}/cart/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Cart', 'Orders', 'ShippingRates']
    }),
    postRemoveFromCart: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePathV2}/cart/remove`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Cart']
    }),
    putUpdateCart: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePathV2}/cart`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Cart', 'ShippingRates']
    }),
    deleteClearCart: builder.mutation({
      query: () => ({
        url: `${currentRoutePathV2}/cart`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Cart', 'ShippingRates']
    }),
    postCreateOrder: builder.mutation({
      query: ({ body }) => ({
        url: `${currentRoutePathV2}/orders`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Cart', 'Wallets', 'Products', 'SingleProduct']
    }),
    getOrders: builder.query({
      query: query => ({
        url: `${currentRoutePathV2}/orders`,
        method: 'GET',
        params: query
      }),
      providesTags: ['Orders']
    }),
    getOrderById: builder.query({
      query: orderId => ({
        url: `${currentRoutePathV2}/orders/${orderId}`,
        method: 'GET'
      })
    }),
    getUserEntities: builder.query({
      query: query => ({
        url: `${currentRoutePath}/entity/user`,
        method: 'GET',
        params: query
      }),
      providesTags: ['UserEntities']
    }),
    getEntityById: builder.query({
      query: ({ storeId, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['EntityDetails']
    }),
    postUpdateEntityById: builder.mutation({
      query: ({ storeId, body, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}`,
        method: 'PUT',
        params: restQuery,
        body
      }),
      invalidatesTags: ['UserEntities']
    }),
    getEntityOrganizers: builder.query({
      query: ({ storeId, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}/organizers`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['EntityOrganizers']
    }),
    getEntityAvailableUsers: builder.query({
      query: ({ storeId, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}/available-users`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['EntityAvailableUsers']
    }),
    postUpdateEntityUsers: builder.mutation({
      query: ({ storeId, body, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}/users`,
        method: 'PUT',
        params: restQuery,
        body
      }),
      invalidatesTags: ['EntityOrganizers', 'EntityAvailableUsers']
    }),
    postDeleteEntity: builder.mutation({
      query: ({ storeId, ...restQuery }) => ({
        url: `${currentRoutePath}/entity/${storeId}`,
        method: 'DELETE',
        params: restQuery,
        body: {}
      }),
      invalidatesTags: ['UserEntities', 'EntityAvailableUsers']
    }),
    getShippingRates: builder.query({
      query: query => ({
        url: `${currentRoutePathV2}/cart/shipping-rates`,
        method: 'GET',
        params: query
      }),
      providesTags: ['ShippingRates']
    }),
    putUpdateCartShippingMethod: builder.mutation({
      query: shipping => ({
        url: `${currentRoutePathV2}/cart/shipping-method`,
        method: 'PUT',
        body: shipping
      }),
      invalidatesTags: ['Cart', 'ShippingRates']
    }),
    deleteCartShippingMethod: builder.mutation({
      query: () => ({
        url: `${currentRoutePathV2}/cart/shipping-method`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Cart', 'ShippingRates']
    }),
    postCreatePaymentIntent: builder.mutation({
      query: data => ({
        url: `${currentRoutePathV2}/payments/create-intent`,
        method: 'POST',
        body: data
      })
    }),
    postVerifyPaymentAndOrder: builder.mutation({
      query: data => ({
        url: `${currentRoutePathV2}/payments/verify-and-order`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [
        'Cart',
        'Wallets',
        'Products',
        'SingleProduct',
        'Orders'
      ]
    }),
    getPayoutAccount: builder.query({
      query: ({ organizationId, ...restQuery }) => ({
        url: `${currentRoutePathManage}/entity-payout/${organizationId}`,
        method: 'GET',
        params: restQuery
      }),
      providesTags: ['PayoutAccount']
    }),
    postCreatePayoutAccountAndLink: builder.mutation({
      query: ({ organizationId, body, ...restQuery }) => ({
        url: `${currentRoutePathManage}/entity-payout/${organizationId}/create-link`,
        method: 'POST',
        params: restQuery,
        body
      }),
      invalidatesTags: ['PayoutAccount']
    }),
    postCreatePayoutAccount: builder.mutation({
      query: ({ organizationId, body, ...restQuery }) => ({
        url: `${currentRoutePathManage}/entity-payout/${organizationId}`,
        method: 'POST',
        params: restQuery,
        body
      }),
      invalidatesTags: ['PayoutAccount']
    }),
    postCreatePayoutAccountLink: builder.mutation({
      query: ({ organizationId, body, ...restQuery }) => ({
        url: `${currentRoutePathManage}/entity-payout/${organizationId}/link`,
        method: 'POST',
        params: restQuery,
        body
      }),
      invalidatesTags: ['PayoutAccount']
    }),
    postUpdatePayoutAccount: builder.mutation({
      query: ({ organizationId, body, ...restQuery }) => ({
        url: `${currentRoutePathManage}/entity-payout/${organizationId}/`,
        method: 'PUT',
        params: restQuery,
        body
      }),
      invalidatesTags: ['PayoutAccount']
    }),
    getPaymentIntentDetails: builder.query({
      query: paymentIntentId => ({
        url: `${currentRoutePathCore}/payments/paymentIntents/${paymentIntentId}`,
        method: 'GET'
      })
    }),
    postFetchTicketRegistration: builder.mutation({
      query: ({ body, ...restQuery }) => ({
        url: `${currentRoutePathPlay}/registration`,
        method: 'POST',
        params: restQuery,
        body
      })
    }),
    postVerifyPaymentAndJoinEvent: builder.mutation({
      query: ({ body, ...restQuery }) => ({
        url: `${currentRoutePathPlay}/registration/verify-and-join`,
        method: 'POST',
        params: restQuery,
        body
      }),
      invalidatesTags: ['Roster', 'Event']
    })
  })
});

export const {
  useGetAvailableJudgesQuery,
  useGetCardsQuery,
  useLazyGetCardsQuery,
  useGetCardsFiltersQuery,
  useGetDecksQuery,
  useGetRankingsQuery,
  useGetEventsByGameQuery,
  useGetEventsByEntityIdQuery,
  useGetSingleDeckQuery,
  useGetSingleEventQuery,
  useGetSingleEventPairingsQuery,
  useGetSingleEventRosterQuery,
  useGetSingleEventStandingsQuery,
  useGetCurrentUserPairingQuery,
  useGetUserRankInfoQuery,
  useLazyGetUserByEmailQuery,
  useGetUserWalletsQuery,
  useLazyGetUserWalletTransactionsQuery,
  useDeleteDeckMutation,
  usePostAddParticipantToEventMutation,
  usePostCreateDeckMutation,
  usePostCreateEventMutation,
  usePostStartEventMutation,
  usePostStartEventTimeMutation,
  usePostResendVerifyEmailMutation,
  usePostUpdateEventMutation,
  usePostUpdateEventParticipantMutation,
  usePostUpdatePairingResultMutation,
  usePutUpdateDeckMutation,
  usePutUpdateUserInfoMutation,
  usePutUpdateUserInfoByGameMutation,
  useUpdateEventJudgesMutation,
  usePostRegisterDeckToEventMutation,
  useUnregisterDeckFromEventMutation,
  usePostCheckDeckValidationMutation,
  useGetUserRankingEventHistoryQuery,
  useGetUserRankingScoreHistoryQuery,
  useGetStoreSearchQuery,
  useGetProductsQuery,
  useGetProductByIdQuery,
  useGetCartDetailsQuery,
  usePostAddToCartMutation,
  usePutUpdateCartMutation,
  useDeleteClearCartMutation,
  usePostRemoveFromCartMutation,
  usePostCreateOrderMutation,
  useLazyGetUserWalletTransactionsGraphQuery,
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useGetUserEntitiesQuery,
  useGetEntityByIdQuery,
  usePostUpdateEntityByIdMutation,
  useGetEntityOrganizersQuery,
  useGetEntityAvailableUsersQuery,
  usePostUpdateEntityUsersMutation,
  usePostDeleteEntityMutation,
  useLazyGetShippingRatesQuery,
  usePutUpdateCartShippingMethodMutation,
  useDeleteCartShippingMethodMutation,
  usePostCreatePaymentIntentMutation,
  usePostVerifyPaymentAndOrderMutation,
  useGetPayoutAccountQuery,
  usePostCreatePayoutAccountAndLinkMutation,
  usePostCreatePayoutAccountMutation,
  usePostCreatePayoutAccountLinkMutation,
  usePostUpdatePayoutAccountMutation,
  useLazyGetPaymentIntentDetailsQuery,
  usePostFetchTicketRegistrationMutation,
  usePostVerifyPaymentAndJoinEventMutation
} = organizePlayApi;
