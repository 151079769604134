import { Button, Typography } from '@mui/material';

import GeneralContainer from '../../../Shared/Cards/GeneralCard';
import Loader from '../../../Loading/Loader';
import React from 'react';
import StyleIcon from '@mui/icons-material/Style';
import { capitalize } from 'lodash';
import { useDeck } from '../../../../pages/Decks/hooks/useDeck';
import { useSelector } from 'react-redux';

export const deckInfoType = {
  buildDeck: { key: 'buildDeck', title: 'Build a Deck' },
  userEmptyDecks: {
    key: 'userEmptyDecks',
    title: "YOU DON'T HAVE ANY DECKS YET"
  },
  communityEmptyDecks: {
    key: 'communityEmptyDecks',
    title: 'No Decks At This Time'
  },
  emptySearchDecks: { key: 'emptySearchDecks', title: 'Deck(s) not Found' }
};

const DeckInfoCard = ({ type = '', error, createDeckProps }) => {
  const {
    defaultDeckType,
    decksProps,
    deckFormats,
    deckTerminologies
  } = useDeck({
    getMyDecks: type === deckInfoType.buildDeck.key
  });

  const defaultFormat = deckFormats.find(
    format => format?.isDeckBuildingDefault
  );

  const [createDeck, createDeckResult] = createDeckProps || [];
  const { user, game } = useSelector(({ app }) => app);
  const newDeckNavigation = () => {
    if (!defaultDeckType) {
      return;
    }

    const body = {
      name: `${user?.details?.info?.firstName || ''} ${user?.details?.info
        ?.lastName || ''} ${deckTerminologies.deck} ${
        decksProps?.data?.data ? decksProps?.data?.data?.length + 1 : ''
      }`,
      notes: '',
      deckImageUrl: `https://storage.googleapis.com/cardeio-images/defaults/${game?.slug}/events/1.webp`,
      sections: (defaultDeckType?.deckSectionIds || []).map(id => ({
        deckSectionId: id,
        cards: []
      })),
      sharing: {
        status: 'private'
      },
      tags: [],
      ...(defaultFormat ? { formatId: defaultFormat._id } : {})
    };

    createDeck && createDeck({ gameId: defaultDeckType?.gameId, body });
  };

  const clickCard = type === deckInfoType.buildDeck.key;
  const showButton = type === deckInfoType.userEmptyDecks.key;

  const createTitleString = type => {
    switch (type) {
      case deckInfoType.buildDeck.key:
        return `Build a ${capitalize(deckTerminologies.deck.toLowerCase())}`;
      case deckInfoType.communityEmptyDecks.key:
        return `No ${capitalize(
          deckTerminologies.deck.toLowerCase()
        )}s At This Time`;
      case deckInfoType.userEmptyDecks.key:
        return `YOU DON'T HAVE ANY ${deckTerminologies.deck.toUpperCase()}S YET`;
      case deckInfoType.emptySearchDecks.key:
        return `${capitalize(
          deckTerminologies.deck.toLowerCase()
        )}(s) not Found`;
      default:
        return '';
    }
  };
  return (
    <GeneralContainer
      id={clickCard ? 'decks-build-deck-card-button' : 'decks-info-card'}
      data-testid="decks-build-deck-card-button"
      paperStyle={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #FFF3'
      }}
      buttonStyle={{
        minHeight: '300px',
        padding: 4,
        flexDirection: 'column',
        gap: 1
      }}
      onClick={clickCard ? newDeckNavigation : null}
    >
      {!createDeckResult?.isLoading ? (
        <>
          <StyleIcon style={{ fontSize: 100 }} />
          <Typography
            className="decks-info-card-title"
            variant={'h3'}
            textTransform={'uppercase'}
            textAlign={'center'}
            gutterBottom
          >
            {createTitleString(type)}
          </Typography>

          {showButton && !error && (
            <Button
              id="decks-build-deck-button"
              data-testid="decks-build-deck-card-button"
              variant={'contained'}
              onClick={newDeckNavigation}
            >
              Build a {deckTerminologies.deck}
            </Button>
          )}
        </>
      ) : (
        <Loader />
      )}
    </GeneralContainer>
  );
};

export default DeckInfoCard;
