import { styled, TableCell, TableContainer, TableRow } from '@mui/material';

export const RankingTableContainer = styled(TableContainer)`
  background: ${({ theme }) => theme.palette.neutral['500']};
  border-radius: 12px;
`;

export const RankingInfoRow = styled(TableRow)`
  &.odd {
    background: ${({ theme }) => theme.palette.neutral['600']};
  }
`;

export const RankingTableCell = styled(TableCell)`
  border-bottom: none;
`;

export const rankingListContainerStyle = {
  ['.infinite-scroll-main-container']: { width: '100%' }
};
