import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Zoom
} from '@mui/material';

import { GooglePlaceTextField } from '../Shared/GooglePlaceTextField';
import Modal from '../Modal';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { address } from '../../values/location';
import { updateUser } from '../../store/app/actions';
import { useLogout } from '../../hooks/auth0/useLogout';

const UserDetailsModal = ({ isOpen, handleClose }) => {
  const userAddress = {
    isOptedOut: false,
    ...address
  };
  const dispatch = useDispatch();
  const { logoutAction } = useLogout();
  const { game, user } = useSelector(({ app }) => app);
  const theme = useTheme();

  const [firstName, setFirstName] = useState(
    get(user, 'details.info.firstName', '')
  );
  const [lastName, setLastName] = useState(
    get(user, 'details.info.lastName', '')
  );

  // eslint-disable-next-line no-unused-vars
  const [shippingAddress, setShippingAddress] = useState({ ...userAddress });

  const [isOptedOut, setIsOptedOut] = useState(
    get(user, 'details.location.isOptedOut', false)
  );

  useEffect(() => {
    setShippingAddress(
      createAddress(get(user, 'details.location', userAddress))
    );
    setIsOptedOut(get(user, 'details.location.isOptedOut', false));
    setFirstName(get(user, 'details.info.firstName', ''));
    setLastName(get(user, 'details.info.lastName', ''));
  }, [user]);

  const createAddress = address => {
    const tempAddress = { ...userAddress };

    Object.keys(tempAddress).forEach(key => {
      tempAddress[key] = get(address, key, tempAddress[key]);
    });

    return tempAddress;
  };

  const disableContinueButton =
    !firstName ||
    !lastName ||
    (isOptedOut
      ? !isOptedOut
      : !shippingAddress.address1 ||
        !shippingAddress.city ||
        !shippingAddress.state ||
        !shippingAddress.zip ||
        !shippingAddress.country);

  const handleSaveUser = () => {
    let locationData = {};

    if (isOptedOut) {
      locationData = { ...userAddress, isOptedOut: true };
    } else {
      locationData = {
        ...shippingAddress,
        isOptedOut: false
      };
    }
    dispatch(
      updateUser(get(user, 'details.id'), {
        profile: {
          firstName,
          lastName
        },
        location: locationData
      })
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <Grid container flexDirection="column" gap={4}>
        <Typography variant="h2" data-testid="welcome-text-label">
          {`Welcome${
            get(game, 'terminology.player') ? ` ${game.terminology.player}` : ''
          }!`}
        </Typography>
        <Typography variant="body2">
          In order to continue with {get(game, 'titles.op')}, we need to
          register some minor details about you. Please fill out this form to
          continue!
        </Typography>
        <TextField
          label="First Name*"
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          data-testid="first-name"
        />
        <TextField
          label="Last Name*"
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          data-testid="last-name"
        />

        <Grid mt={4} container alignItems="center">
          <Typography mb={0.25} variant="h4">
            Shipping Information
          </Typography>
          <Tooltip
            TransitionComponent={Zoom}
            title="We collect your shipping address in order to fulfill rewards. If you choose not to provide this information we may be unable to fulfill rewards for you."
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                ml: 1,
                width: '24px',
                height: '24px'
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: `${get(theme, 'palette.neutral[700]')}40`,
                  borderRadius: '16px',
                  width: '24px',
                  height: '24px'
                }}
              >
                <QuestionMarkIcon sx={{ height: '16px', width: '16px' }} />
              </IconButton>
            </Grid>
          </Tooltip>
        </Grid>

        {/* Shipping Address */}
        <GooglePlaceTextField
          value={shippingAddress}
          label={'Shipping Address'}
          onChange={e => {
            setShippingAddress(createAddress(e));
          }}
          data-testid="shipping-address-1"
        />

        {/* Address 2 */}
        <TextField
          label="Address 2"
          value={shippingAddress.address2}
          onChange={e =>
            setShippingAddress(prevState => {
              return { ...prevState, address2: e.target.value };
            })
          }
          data-testid="shipping-address-2"
        />

        <Grid container alignItems="center">
          <Checkbox
            checked={isOptedOut}
            onChange={() => {
              setIsOptedOut(!isOptedOut);
            }}
            data-testid="opt-out-chkbx"
          />
          <Typography sx={{ ml: 0.5, width: '75%' }} variant="body3">
            I would like to opt out of sharing my shipping address.
          </Typography>
        </Grid>
        <Grid container flexDirection={{ xs: 'column', md: 'row' }}>
          <Button
            disabled={disableContinueButton}
            onClick={() => {
              handleSaveUser();
              handleClose();
            }}
            sx={{
              mb: { xs: 2, md: 0 }
            }}
            variant="contained"
            data-testid="continue-button"
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              logoutAction();
              handleClose();
            }}
            data-testid="logout-button"
            sx={{
              fontSize: 12,
              m: { xs: 0, md: 'initial' },
              p: { xs: '16px 8px', md: '16px 32px' }
            }}
          >
            Nevermind, Log Me Out
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UserDetailsModal;
