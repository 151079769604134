import { Divider, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  getFixedPositionNumber,
  getFormattedCurrency
} from '../../../../../utils/number';

import Loading from '../../../../Loading/Loader';
import { ModalContext } from '../../../../Modal/Context/ModalContext';
import SimpleModalCard from '../../../../Modal/Cards/SimpleModalCard';
import StripePayment from '../../../../Payment/StripePayment';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { usePostFetchTicketRegistrationMutation } from '../../../../../services/apis/organizePlayApi';

const StyledPriceDetailsContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const BuyEventTicket = ({ user, activeEvent }) => {
  const { closeModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [
    fetchRegistration,
    fetchRegistrationRes
  ] = usePostFetchTicketRegistrationMutation();
  const ticketPrice = getFixedPositionNumber(
    activeEvent?.ticketPricing.ticketFee / 100,
    2
  );
  const taxes = getFixedPositionNumber(
    activeEvent?.ticketPricing.taxes / 100,
    2
  );
  const total = ticketPrice + taxes;
  useEffect(() => {
    if (activeEvent._id) {
      setIsLoading(true);
      fetchRegistration({ body: { eventId: activeEvent._id } });
    }
  }, [activeEvent]);

  useEffect(() => {
    setIsLoading(fetchRegistrationRes.isLoading);
    if (fetchRegistrationRes.isSuccess) {
      const cs = get(
        fetchRegistrationRes,
        'data.data.ticketInfo.paymentIntent.client_secret',
        null
      );
      if (!cs) {
        toast.error('Failed to fetch payment related information.');
        return;
      }
      setClientSecret(cs);
    }
  }, [fetchRegistrationRes]);

  const renderPricingRow = (label, price, highlight = false) => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography
              variant={highlight ? 'body1' : 'body1Light'}
              color={highlight ? 'primary' : 'initial'}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Typography
              variant="body1"
              color={highlight ? 'primary' : 'initial'}
            >
              {getFormattedCurrency(price)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <SimpleModalCard title={'Buy Ticket'} hideActionButtons={true}>
      <StyledPriceDetailsContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Ticket Pricing Details</Typography>
          </Grid>
          {renderPricingRow('Ticket Price', ticketPrice)}
          {renderPricingRow('Taxes', taxes)}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {renderPricingRow('Total', total, true)}
        </Grid>
      </StyledPriceDetailsContainer>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          {isLoading || !clientSecret ? (
            <Loading />
          ) : (
            <StripePayment
              clientSecret={clientSecret}
              clientEmail={get(user, 'details.info.email', '')}
              showCancel={true}
              onCancel={closeModal}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Typography sx={{ fontStyle: 'italic' }} variant="body4Light">
            *Note: All ticket sales are final, except in the case that this
            event is cancelled.
          </Typography>
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};

export default BuyEventTicket;
