import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { get, kebabCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { EventStatusChip } from '../../EventDetailStatus';
import GeneralContainer from '../../../Shared/Cards/GeneralCard';
import { GeneralEventCard } from '../../../../pages/Events/Single/EventOverviewPage/style';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LoadingButton from '@mui/lab/LoadingButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import OverflowTextfield from '../../../Shared/TextFields/Overflow';
import { TubeCard } from '../../../Shared/Cards/styles';
import { addParticipantsToEvent } from '../../../../store/events/actions';
import { buildRoutePath } from '../../../../utils/routingUtils';
import dateFormat from 'dateformat';
import { isDateInTheFuture } from '../../../../utils/date';
import { useCustomNavigation } from '../../../../hooks/navigation/useCustomNavigation';
import { useEventFormat } from '../../../../hooks/events/useEventFormat';
import { useFlags } from 'launchdarkly-react-client-sdk';

const formatDate = dateToFormat => {
  const date = new Date(dateToFormat);

  return isNaN(date.getDay()) ? 'N/A' : dateFormat(date, 'ddd. mmm dd, yyyy');
};

const formatStartTime = dateToFormat => {
  const date = new Date(dateToFormat);

  return isNaN(date.getDay()) ? 'N/A' : dateFormat(date, 'h:MM TT');
};

export const EventCard = ({ id, event, handleJoinClick }) => {
  const {
    _id,
    details,
    entityName,
    imageUrl,
    location,
    // eslint-disable-next-line no-unused-vars
    name,
    startDate,
    tournament,
    status,
    canJoin,
    isOrganizer,
    payoutEnabled,
    previouslyJoined
  } = event;
  const { ticketing } = useFlags();
  const hasActiveGame = Boolean(process.env.REACT_APP_ACTIVE_GAME);
  const dispatch = useDispatch();
  const { user } = useSelector(({ app }) => app);
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { buildSubPath } = useCustomNavigation();
  const canManage =
    isOrganizer && isDateInTheFuture(startDate) && status === 'registration';

  const eventFormatName = useEventFormat(event);

  const eventInfo = [
    {
      icon: <CalendarTodayIcon />,
      value: formatDate(startDate)
    },
    {
      icon: <AccessTimeIcon />,
      value: `Starts at ${formatStartTime(startDate)}`
    },
    {
      icon: <BusinessIcon />,
      value: entityName
    },
    {
      icon: <LocationOnIcon />,
      value: get(location, 'isOnline', false)
        ? 'Online'
        : `${location.city}, ${location.state}`
    }
  ];

  let matchStructureDetails = [];

  if (get(tournament, 'playType', 'tournament') === 'tournament') {
    matchStructureDetails = [
      {
        title: 'Match Structure',
        value: (
          <>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
            >
              Best of {tournament.bestOf} Game{tournament.bestOf > 1 ? 's' : ''}
            </Typography>
            <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
              {tournament.roundLength} min rounds
            </Typography>
          </>
        )
      },
      {
        title: 'Round Structure',
        value: (
          <>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
            >
              {`${get(tournament, 'format', '').replace('-', ' ')}`}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
            >
              {`${tournament.rounds}${
                get(tournament, 'format', '') === 'swiss' ? ' Swiss' : ''
              } rounds`}
            </Typography>
            {tournament.playOffRounds > 0 && (
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {`${tournament.playOffRounds} Playoff rounds`}
              </Typography>
            )}
          </>
        )
      }
    ];
  }

  if (get(tournament, 'playType', 'tournament') === 'meetup') {
    matchStructureDetails = [
      {
        title: 'Gameplay Type',
        value: (
          <>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
            >
              Casual
            </Typography>
          </>
        )
      }
    ];
  }

  const handleJoinEvent = () => {
    if (ticketing && details.entryFee && payoutEnabled && !previouslyJoined) {
      navigate(buildSubPath(`/events/${_id}`));
    } else {
      dispatch(
        addParticipantsToEvent(_id, {
          userId: user.details.id
        })
      );
      handleJoinClick(_id);
    }
  };

  return (
    <GeneralContainer
      id={id ? id : `event-card-${kebabCase(name)}-${_id}-button`}
      paperStyle={{
        width: '100%'
      }}
      buttonStyle={{ p: 2 }}
      onClick={() => navigate(buildRoutePath(gameSlug, `/events/${_id}`))}
    >
      <Grid container spacing={2}>
        {/*  Image */}
        <Grid xs={12} sm={6} md={3} item>
          <Grid
            sx={{
              paddingBottom: '68%',
              position: 'relative',
              height: '100%',
              borderRadius: '16px',
              overflow: 'hidden'
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${imageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            ></div>
            <EventStatusChip eventStatus={status} />
          </Grid>
        </Grid>

        {/* Middle & Right sections */}
        <Grid item xs={12} sm={6} md={9}>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            {/* Middle Section */}
            <Grid item>
              <Grid
                container
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                flexDirection={'column'}
                spacing={2}
                paddingTop={{ xs: 0, sm: 2 }}
              >
                <Grid item>
                  {/*<Typography variant={'h3'} textAlign={'left'}>*/}
                  {/*  {name}*/}
                  {/*</Typography>*/}
                  <OverflowTextfield title={name} variant={'h3'} />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  spacing={2}
                >
                  <Grid xs={12} md={6} item container spacing={1}>
                    <Grid xs={12} mb={1} item>
                      <Typography variant={'h5'}>{eventFormatName}</Typography>
                    </Grid>
                    {eventInfo.map((obj, index) => {
                      return (
                        <Grid
                          xs={12}
                          item
                          container
                          gap={0.5}
                          key={index}
                          alignItems="center"
                        >
                          <Grid item>{obj.icon}</Grid>
                          <Grid item>
                            <Typography
                              mt={-0.75}
                              variant={'body2'}
                              sx={{
                                textWrap: 'wrap'
                              }}
                            >
                              {obj.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* Player Registration Info */}
                  <Grid
                    xs={12}
                    md={6}
                    item
                    container
                    justifyContent={{ xs: 'center', lg: 'flex-end' }}
                  >
                    <Grid item>
                      <TubeCard
                        alignItems="center"
                        gap={1}
                        item
                        className={'event'}
                      >
                        <Grid item sx={{ height: '24px' }}>
                          <GroupAddIcon />
                        </Grid>
                        <Typography variant="body3">{`${details.participantCount ||
                          0} / ${details.capacity} Capacity`}</Typography>
                      </TubeCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              {/* Right Section */}
              <GeneralEventCard
                item
                container
                className={'eventSectionRight'}
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'flex'
                  },
                  height: '100%'
                }}
                width={'225px'}
              >
                <Grid
                  container
                  alignContent="space-between"
                  sx={{ height: '100%' }}
                  gap={3}
                >
                  {matchStructureDetails.map((obj, index) => {
                    return (
                      <Grid item container key={index} spacing={1}>
                        <Grid xs={12} item>
                          <Typography variant={'body2'}>{obj.title}</Typography>
                        </Grid>
                        <Grid xs={12} item>
                          <Typography
                            variant={'h5'}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {obj.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    item
                    container
                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  >
                    <LoadingButton
                      id={
                        canManage
                          ? `edit-event-${kebabCase(name)}-button`
                          : canJoin
                          ? `join-event-${kebabCase(name)}-button`
                          : `join-event-${kebabCase(name)}-button`
                      }
                      data-testid={
                        canManage
                          ? `edit-event-${kebabCase(name)}-button`
                          : canJoin
                          ? `join-event-${kebabCase(name)}-button`
                          : `join-event-${kebabCase(name)}-button`
                      }
                      variant={'contained'}
                      loading={loading}
                      onClick={event => {
                        event.stopPropagation();
                        setLoading(true);

                        if (canManage) {
                          navigate(
                            `${
                              hasActiveGame ? '' : `/${gameSlug}`
                            }/events/${_id}/edit`
                          );
                        } else if (canJoin) {
                          handleJoinEvent();
                          setLoading(false);
                        } else {
                          navigate(
                            `${
                              hasActiveGame ? '' : `/${gameSlug}`
                            }/events/${_id}`
                          );
                        }
                      }}
                    >
                      <Typography variant={'h6'}>
                        {canManage
                          ? 'Manage'
                          : canJoin
                          ? 'Join Now'
                          : 'More Info'}
                      </Typography>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </GeneralEventCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GeneralContainer>
  );
};
