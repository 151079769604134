import { Button, Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { get, isEmpty, toPairs } from 'lodash';
import {
  getCartListingFromDetails,
  getCartTotalFiatPrice,
  hasPhysicalProductInCart
} from '../../utils/cart';
import {
  useGetCartDetailsQuery,
  usePostCreateOrderMutation,
  usePostCreatePaymentIntentMutation,
  usePutUpdateCartShippingMethodMutation
} from '../../services/apis/organizePlayApi';

import CartItem from './CartItem';
import CartUserWalletBalance from './CartUserWalletBalance';
import CheckoutButton from './CheckoutButton';
import CheckoutButtonWithPayment from './CheckoutButtonWithPayment/index';
import CheckoutButtonWithShipping from './CheckoutButtonWithShipping/index';
import Loading from '../../components/Loading/Loader';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { ModalContext } from '../../components/Modal/Context/ModalContext';
import PaymentBreakdown from '../../components/Payment/PaymentBreakdown';
import { PrizesBox } from './styles';
import ShippingAddressUpdateModal from './ShippingAddressUpdateModal/index';
import StripePayment from '../../components/Payment/StripePayment';
import UserShippingAddress from './UserShippingAddress';
import { buildRoutePath } from '../../utils/routingUtils';
import { isUserShippingAddressValid } from '../../utils/user';
import { toast } from 'react-toastify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsLoggedIn } from '../../hooks/auth0/useLoggedIn';
import { useSelector } from 'react-redux';
import { useUserWallets } from '../../hooks/user/useUserWallets';

const CartMainPage = () => {
  const { game, user } = useSelector(({ app }) => app);
  const isLoggedIn = useIsLoggedIn();
  const {
    data: cartDetails,
    isLoading,
    isFetching,
    refetch
  } = useGetCartDetailsQuery({}, { skip: !isLoggedIn });
  const { rewardFulfillment } = useFlags();
  const currencies = get(game, 'pgGame.currencies', []);
  const isCartLoading = isLoading || isFetching;
  const [isOrderCreating, setIsOrderCreating] = useState(false);
  const [walletBalanceError, setWalletBalanceError] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [paymentClientSecret, setPaymentClientSecret] = useState(null);
  const [isPaymentIntentLoading, setIsPaymentIntentLoading] = useState(false);
  const [paymentPriceBreakdown, setPaymentPriceBreakdown] = useState(null);
  const [shouldShowPaymentBreakdown, setShouldShowPaymentBreakdown] = useState(
    false
  );
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const { cartData, totalPrices } = getCartListingFromDetails(
    cartDetails,
    currencies
  );
  const totalFiat = getCartTotalFiatPrice(cartDetails);
  const hasFiatValue =
    toPairs(totalFiat).reduce((acc, fiatObj) => {
      return acc + fiatObj[1];
    }, 0) > 0;
  const [createOrder, createOrderResult] = usePostCreateOrderMutation();
  const userWallets = useUserWallets();
  useEffect(() => {
    userWallets.update();
    refetch();
  }, []);
  const [
    updateShippingMethod,
    updateShippingMethodRes
  ] = usePutUpdateCartShippingMethodMutation();
  const [
    createPaymentIntent,
    createPaymentIntentResult
  ] = usePostCreatePaymentIntentMutation();
  const { addModal, closeModal } = useContext(ModalContext);

  const isUserWalletLoading = userWallets.isUpdating;

  const hasPhysicalProduct = hasPhysicalProductInCart(cartDetails);

  const createPaymentIntentRequest = () => {
    createPaymentIntent({
      getAmountFromCart: true
    });
  };

  const updatePriceBreakdown = breakdown => {
    if (!breakdown || isEmpty(breakdown)) {
      setPaymentPriceBreakdown(null);
      setShouldShowPaymentBreakdown(false);
    } else {
      const currentBreakdown = {
        taxes: get(breakdown, 'taxes', 0),
        shipping: get(breakdown, 'shipping', 0),
        cartTotal: get(breakdown, 'cartTotal', 0),
        serviceFee: get(breakdown, 'serviceFee', 0),
        total: get(breakdown, 'total', 0)
      };
      setPaymentPriceBreakdown(currentBreakdown);
      setShouldShowPaymentBreakdown(
        toPairs(currentBreakdown).reduce((acc, curr) => {
          return acc + curr[1];
        }, 0) > 0
      );
    }
  };

  useEffect(() => {
    setPaymentClientSecret(
      get(cartDetails, 'cart.paymentIntent.clientSecret', null)
    );
    updatePriceBreakdown(
      get(cartDetails, 'cart.paymentIntent.paymentBreakdown', null)
    );
  }, [cartDetails]);

  useEffect(() => {
    if (updateShippingMethodRes.isSuccess) {
      createPaymentIntentRequest();
    }
  }, [updateShippingMethodRes]);

  useEffect(() => {
    setIsPaymentIntentLoading(
      createPaymentIntentResult.isLoading ||
        createPaymentIntentResult.isFetching
    );
    if (createPaymentIntentResult.isSuccess && createPaymentIntentResult.data) {
      setPaymentClientSecret(
        get(createPaymentIntentResult, 'data.data.clientSecret', null)
      );
      updatePriceBreakdown(get(createPaymentIntentResult, 'data.data', null));
    }
  }, [createPaymentIntentResult]);

  useEffect(() => {
    const selectedShippingMethod = get(cartDetails, 'cart.shipping', null);
    setShippingMethod(selectedShippingMethod);
  }, [cartDetails, setShippingMethod]);

  useEffect(() => {
    setIsOrderCreating(createOrderResult.isLoading);
    if (createOrderResult.isSuccess) {
      toast.success('Order placed!');
      navigate(buildRoutePath(gameSlug, '/user/profile/my-rewards'));
    } else if (createOrderResult.isError) {
      toast.error(
        get(
          createOrderResult,
          'error.data.errorMessage',
          'Error while placing order'
        )
      );
    }
  }, [createOrderResult]);

  const onShippingMethodSelect = selectedMethod => {
    setShippingMethod(selectedMethod);
    updateShippingMethod(selectedMethod);
    setPaymentClientSecret(null);
    closeModal();
  };

  const createNewOrder = () => {
    createOrder({
      body: {}
    });
  };

  const onClickCheckoutNoFulfillment = () => {
    if (!isUserShippingAddressValid(user)) {
      openShippingAddressModal();
    } else {
      createNewOrder();
    }
  };

  const openShippingAddressModal = () => {
    addModal({
      children: (
        <ShippingAddressUpdateModal
          onCloseClick={closeModal}
          message="We need your shipping address in order to fulfill rewards. If you choose not to provide this information we may be unable to fulfill rewards for you."
        />
      )
    });
  };

  const onBalanceUpdate = (
    isError = true,
    message = 'Insufficient wallet balance'
  ) => {
    setWalletBalanceError(isError ? message : null);
  };

  const onClickPayment = () => {
    setPaymentClientSecret(null);
    createPaymentIntentRequest();
  };

  const shouldShowStripePayment = () => {
    if (rewardFulfillment) {
      return (
        paymentClientSecret &&
        shippingMethod &&
        !isPaymentIntentLoading &&
        isEmpty(walletBalanceError)
      );
    }
    return (
      paymentClientSecret &&
      hasFiatValue &&
      !isPaymentIntentLoading &&
      isEmpty(walletBalanceError)
    );
  };

  return (
    <MaxWidthContainer
      container
      className={'p-percent p-top-5'}
      sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <Typography
        marginBottom={6}
        variant="h1"
        width="100%"
        textAlign="left"
        data-testid="cart-page-title"
      >
        Cart
      </Typography>

      <PrizesBox width="100%">
        {isCartLoading ? (
          <Loading />
        ) : (
          <>
            {cartData.length ? (
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  {cartData.map(item => (
                    <CartItem
                      key={`cart_${item.productId}_${item.inventoryId}_${item.productPriceId}`}
                      item={item}
                    />
                  ))}
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <UserShippingAddress />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CartUserWalletBalance
                        wallets={userWallets.wallets}
                        isLoading={isUserWalletLoading}
                        currencies={currencies}
                        totalPrices={totalPrices}
                        onBalanceUpdate={onBalanceUpdate}
                        totalFiat={totalFiat}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    justifyContent={{ xs: 'center', sm: 'space-between' }}
                    alignItems={{ xs: 'center', sm: 'flex-start' }}
                    spacing={2}
                  >
                    <Grid item>
                      <MuiLink
                        component={Link}
                        to={buildRoutePath(gameSlug, '/rewards')}
                        color={'inherit'}
                        data-testid="shop-more-link"
                      >
                        Shop More
                      </MuiLink>
                    </Grid>
                    <Grid item>
                      {rewardFulfillment ? (
                        <CheckoutButtonWithShipping
                          loading={isOrderCreating}
                          disabled={!isEmpty(walletBalanceError)}
                          hasPhysicalProduct={hasPhysicalProduct}
                          shippingMethod={shippingMethod}
                          isShippingAddressValid={isUserShippingAddressValid(
                            user
                          )}
                          openShippingAddressModal={openShippingAddressModal}
                          onShippingMethodSelect={onShippingMethodSelect}
                        />
                      ) : hasFiatValue ? (
                        <CheckoutButtonWithPayment
                          loading={isPaymentIntentLoading}
                          onClickPayment={onClickPayment}
                          disabled={
                            !isEmpty(walletBalanceError) ||
                            isPaymentIntentLoading
                          }
                        />
                      ) : (
                        <CheckoutButton
                          loading={isOrderCreating}
                          onCreateOrder={onClickCheckoutNoFulfillment}
                          disabled={!isEmpty(walletBalanceError)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {walletBalanceError ? (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  >
                    <Typography color="error">{walletBalanceError}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {isPaymentIntentLoading && isEmpty(walletBalanceError) ? (
                    <Loading />
                  ) : null}
                  {shouldShowStripePayment() ? (
                    <StripePayment
                      clientSecret={paymentClientSecret}
                      clientEmail={get(user, 'details.info.email', '')}
                      paymentBreakdown={
                        shouldShowPaymentBreakdown ? (
                          <PaymentBreakdown breakdown={paymentPriceBreakdown} />
                        ) : null
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={6} width="100%">
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    data-testid="cart-empty-text"
                  >
                    Cart is Empty
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    LinkComponent={Link}
                    sx={{
                      padding: '12px 24px'
                    }}
                    variant="contained"
                    to={buildRoutePath(gameSlug, '/rewards')}
                    data-testid="see-rewards-btn"
                  >
                    See Rewards
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </PrizesBox>
    </MaxWidthContainer>
  );
};

export default CartMainPage;
