import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';

import { get } from 'lodash';

const prevValues = { scrollTop: 0 };

const InfiniteScroll = ({
  children,
  done = false,
  emptyMessage = 'No Information',
  isLoading,
  loader,
  loaderAction,
  threshold = 0,
  shouldScrollToTop = true,
  emptyResultContainerStyle = {},
  // eslint-disable-next-line no-unused-vars
  useWindow = true,
  mainContainerStyleOverride = {}
}) => {
  const mainContainerRef = useRef();
  const dataContainerRef = useRef();
  const loaderContainerRef = useRef();

  const showEmptyMessage = !isLoading && !children && done;

  const handleScroll = e => {
    const parentOffsetTop = Math.ceil(
      useWindow
        ? get(mainContainerRef, 'current.offsetParent.offsetTop', 0)
        : get(mainContainerRef, 'current.offsetTop', 0)
    );

    const scrollY = Math.ceil(
      useWindow ? get(window, 'scrollY', 0) : get(e, 'target.scrollTop', 0)
    );
    const innerHeight = Math.ceil(
      useWindow
        ? get(window, 'innerHeight', 0)
        : get(e, 'target.clientHeight', 0)
    );

    const dataScrollHeight = Math.ceil(
      get(dataContainerRef, 'current.scrollHeight', 0) / 1.2
    );
    const offsetTop = Math.ceil(get(dataContainerRef, 'current.offsetTop', 0));
    const scrollPosition = scrollY + innerHeight - parentOffsetTop + threshold;
    const scrollHeight = dataScrollHeight + offsetTop;

    if (
      prevValues.scrollTop < scrollY &&
      scrollPosition > scrollHeight &&
      !isLoading &&
      !done
    ) {
      loaderAction && loaderAction();
    }

    prevValues.scrollTop = scrollY;
  };

  const mainContainerStyle = {
    position: 'relative',
    ...(children &&
      !done && {
        paddingBottom:
          loader && `${get(loaderContainerRef, 'current.clientHeight', 0)}px`
      })
  };

  const loaderContainerStyle = {
    opacity: isLoading ? 1.0 : 0.0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: !children ? '100vh' : 'unset',
    ...(children && {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      pointerEvents: 'none'
    })
  };

  useEffect(() => {
    let container = window;
    if (!useWindow && mainContainerRef) {
      container = mainContainerRef.current.parentElement;
    }
    if (!children && shouldScrollToTop) {
      container.scrollTo(0, 0);
    }
    container?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, children, shouldScrollToTop, mainContainerRef, useWindow]);

  return (
    <Box
      ref={mainContainerRef}
      sx={{ ...mainContainerStyle, ...mainContainerStyleOverride }}
      className={'infinite-scroll-main-container'}
    >
      {/* Content */}
      <Box ref={dataContainerRef}>{children}</Box>

      {/* Loader*/}
      {loader && (
        <Box ref={loaderContainerRef} sx={loaderContainerStyle}>
          {loader}
        </Box>
      )}
      {showEmptyMessage && (
        <Box
          sx={{
            ...loaderContainerStyle,
            opacity: 1.0,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
            ...emptyResultContainerStyle
          }}
        >
          <Typography typography={'h2'} textAlign={'center'}>
            {emptyMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default InfiniteScroll;
