import { allCardsTypes } from './index';

export const cardContainer = type => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: { xs: '32px 8px', sm: 4 },
  gap: 2,
  justifyContent: 'space-around',
  ...(type === allCardsTypes.deckBuilder && {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(auto-fill, 100px)',
      sm: 'repeat(auto-fill, 150px)'
    },
    justifyItems: 'center',
    rowGap: 6,
    columnGap: 4
  })
});
