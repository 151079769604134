import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { Box } from '@mui/material';
import { EnergyImage } from './styles';
const DeckEnergyTube = () => {
  const auraImage =
    'https://storage.googleapis.com/cardeio-images/kryptik/icons/soul/provided/G.webp';
  return (
    <TubeCard
      sx={{
        gap: 1,
        justifyContent: 'space-evenly',
        padding: '8px 12px',
        width: '100%',
        background: 'transparent',
        border: '1px solid #FFF2'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: 'fit-content',
          maxWidth: '30px'
        }}
      >
        <EnergyImage src={auraImage} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: 'fit-content',
          maxWidth: '30px'
        }}
      >
        <EnergyImage src={auraImage} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: 'fit-content',
          maxWidth: '30px'
        }}
      >
        <EnergyImage src={auraImage} />
      </Box>
    </TubeCard>
  );
};

export default DeckEnergyTube;
