import { Box, Grid, Paper, Popover, Typography } from '@mui/material';

import React from 'react';
import { get } from 'lodash';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const StyledPaper = styled(Paper)({
  height: 120,
  margin: '32px 8px',
  padding: '8px 16px',
  position: 'relative',
  transform: 'scale(1)',
  transition: 'transform 0.5s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04)',
    transition: 'transform 0.5s ease-in-out'
  }
});

const cardLayoutPath = 'layout.card.cardOverview';
const CardOverview = ({ card }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const getSplitKeys = fieldKey => {
    const fieldValue = get(game, `${cardLayoutPath}.${fieldKey}`, '');
    if (fieldValue.includes(',')) {
      const keys = fieldValue.split(',');
      let fieldData = '';

      keys.forEach(fValue => {
        const keyData = get(card, fValue, '');
        if (fieldData.length === 0) {
          fieldData = keyData;
        } else {
          fieldData = fieldData + ' ' + keyData;
        }
      });

      return fieldData;
    }
    return get(card, fieldValue, '');
  };

  const fieldData = {
    image: get(card, get(game, `${cardLayoutPath}.image.fieldKey`, ''), ''),
    title: getSplitKeys('title.fieldKey'),
    subtitle: getSplitKeys('subtitle.fieldKey'),
    backgroundImage: get(
      card,
      get(game, `${cardLayoutPath}.bgIconImage.fieldKey`, ''),
      ''
    ),
    shadowColor:
      get(game, `${cardLayoutPath}.shadowColor.from`, '') === 'theme'
        ? get(theme, get(game, `${cardLayoutPath}.shadowColor.fieldKey`, ''))
        : get(
            card,
            `${get(game, `${cardLayoutPath}.shadowColor.from`, '')}.${get(
              game,
              `${cardLayoutPath}.shadowColor.fieldKey`,
              ''
            )}`,
            'transparent'
          )
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledPaper
      id={`cards-overview-${card.slug}-display-card`}
      data-testid={`cards-overview-${card.slug}-display-card`}
      sx={{
        boxShadow: `0 0 16px 4px ${fieldData.shadowColor}80`,
        maxWidth: 400,
        width: {
          xs: '90vw',
          sm: '320px',
          md: '350px'
        }
      }}
    >
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        sx={{
          backgroundImage: `url(${get(card, 'imageUrl', '')})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '8px',
          boxShadow: '0 0 24px 2px #1a1a1a',
          bottom: '16px',
          height: '140px',
          position: 'absolute',
          width: '101px'
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id={`card-${card.slug}-mouse-over-popover`}
        data-testid={`card-${card.slug}-mouse-over-popover`}
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
        disableRestoreFocus
      >
        <Box
          sx={{
            backgroundColor: 'transparent',
            backgroundImage: `url(${fieldData.image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: {
              xs: '12px',
              sm: '24px'
            },
            height: {
              xs: 293,
              sm: 531
            },
            width: {
              xs: 213,
              sm: 385
            }
          }}
        />
      </Popover>
      <Grid
        container
        sx={{
          flexWrap: 'nowrap',
          marginLeft: '120px',
          width: 'calc(100% - 120px)',
          height: '100%'
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          flexDirection="column"
          item
          sx={{
            height: 'calc(100% - 8px)',
            overflow: 'hidden',
            width: '180px'
          }}
        >
          <Grid>
            <Typography
              className={'card-title'}
              sx={{
                fontWeight: 700,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              variant="subtitle2"
            >
              {fieldData.title}
            </Typography>
            <Typography
              className={'card-subtitle'}
              sx={{ fontWeight: 400 }}
              variant="body3"
            >
              {fieldData.subtitle}
            </Typography>
          </Grid>
          <Box
            sx={{
              background: 'transparent',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '30px',
              marginLeft: '-8px',
              width: '30px'
            }}
          />
        </Grid>

        <Box
          sx={{
            backgroundImage: `url(${fieldData.backgroundImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            bottom: '5px',
            height: '110px',
            opacity: 0.1,
            position: 'absolute',
            right: '0',
            width: '110px'
          }}
        />
      </Grid>
    </StyledPaper>
  );
};

export default CardOverview;
