import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Checkbox, Chip, TextField } from '@mui/material';
import { useLazyGetCardsQuery } from '../../../../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';
import { debounce, get } from 'lodash';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const DeckCards = ({ selectedCards, setSelectedCards }) => {
  const { game } = useSelector(({ app }) => app);
  const [cardName, setCardName] = useState('');
  const [getCards, data] = useLazyGetCardsQuery();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    if (cardName.length === 0) setLoading(false);
    else setLoading(true);
    const debounceCards = debounce(() => {
      if (cardName) {
        getCards({
          gameId: get(game, 'id', ''),
          query: { name: cardName }
        });
      }
    }, 1000);
    debounceCards();

    return () => debounceCards.cancel();
  }, [cardName]);

  useEffect(() => {
    if (data.isSuccess && !data.isFetching) {
      const fechtedCards = get(data, 'data.data', []);

      const treatedSelectedCards = selectedCards.map(card => ({
        ...card,
        checked: true
      }));

      const treatedFetchedCards = fechtedCards.filter(
        card => !selectedCards.find(selectedCard => selectedCard.id === card.id)
      );

      setCards([...treatedSelectedCards, ...treatedFetchedCards]);
      setLoading(false);
    }
  }, [data, selectedCards]);
  return (
    <Autocomplete
      options={cards}
      value={selectedCards}
      onChange={(event, newValue) => {
        if (newValue.length > 1) {
          const lastCard = newValue[newValue.length - 1];
          if (selectedCards.some(card => card.id === lastCard.id)) {
            const newSelectedCards = newValue.filter(
              card => card.id !== lastCard.id
            );
            setSelectedCards(newSelectedCards);
          } else {
            setSelectedCards(newValue);
          }
        } else {
          setSelectedCards(newValue);
        }
      }}
      multiple
      getOptionLabel={option => option.name}
      loading={loading}
      loadingText="Loading cards..."
      noOptionsText={
        cardName.length > 0 ? 'No cards found' : 'Start typing to search cards'
      }
      renderInput={params => (
        <TextField
          {...params}
          onChange={e => setCardName(e.target.value)}
          data-testid="decks-includes-card-filter"
          label="Includes Card"
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.checked}
            />
            <img loading="lazy" width="30" src={option.imageUrl} />
            {option.name}
          </Box>
        );
      }}
      filterOptions={options => options}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        const limitTags = 1;

        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.id}
                label={option.name}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
    />
  );
};

export default DeckCards;
