import { Route } from 'react-router-dom';
import React from 'react';
import RewardsMainPage from '.';
import StatusPage, { statusTypes } from '../Status';
import Rewards from './components/Rewards';
import FeaturedRoute from '../../components/Route/FeaturedRoute';
import RewardsUserInfo from '../../components/Rewards/RewardsUserInfo';

const RewardsRouter = () => {
  return (
    <Route
      path={'rewards/*'}
      element={
        <FeaturedRoute feature="rewardStore">
          <RewardsMainPage />
        </FeaturedRoute>
      }
      errorElement={<StatusPage type={statusTypes.error} />}
    >
      <Route
        index
        element={
          <>
            <RewardsUserInfo />
            <Rewards />
          </>
        }
      />
      <Route path={'store'} element={<Rewards store />} />
    </Route>
  );
};

export default RewardsRouter;
