import { find, get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';

export function useEventSubTypeQueryParams(eventDetails = {}) {
  const { formats } = useSelector(({ app }) => app.game) || { formats: [] };
  const subFormatId = get(eventDetails, 'subFormatId', null);
  const formatId = get(eventDetails, 'formatId', null);

  if (!subFormatId || !formatId) {
    return null;
  }
  const foundFormat = find(formats, format => format._id === formatId);
  const formatOptions = get(foundFormat, 'formatOptions', {});
  if (isEmpty(formatOptions)) {
    return null;
  }
  const keys = Object.keys(formatOptions);
  for (let i = 0; i < keys.length; i++) {
    const values = Object.values(formatOptions[keys[i]]);
    if (values && values.indexOf(subFormatId) >= 0) {
      return { name: [keys[i]], value: subFormatId };
    }
  }
  return null;
}
