import { Grid, Tooltip, styled } from '@mui/material';

export const GeneralEventCard = styled(Grid)`
  background: ${({ theme }) => theme.events.cardPrimaryColor};
  padding: 24px;
  border-radius: 16px;
  position: relative;

  &.eventSectionRight {
    background: ${({ theme }) => theme.events.cardSecondaryColor};
  }
`;

export const EventDetailsCard = styled(Grid)`
  background: ${({ theme }) => theme.events.cardPrimaryColor};
  padding: 48px 24px;
  border-radius: 16px;
  position: relative;

  &.eventSectionRight {
    background: ${({ theme }) => theme.events.cardSecondaryColor};
  }
`;

export const EventCardHover = styled(GeneralEventCard)`
  cursor: pointer;
  transform: scale(1);
  margin-bottom: 3rem;
  transition: transform 0.5s ease-in-out;
  &:hover {
    transform: scale(1.04);
    transition: transform 0.25s ease-in-out;
  }
`;

export const EventVideoContainer = styled(Grid)`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 16px;
`;

export const EventPrizeAmountContainer = styled('div')`
  background: ${props => props.theme.palette.success.main};
  border-radius: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px;
`;

export const EventTooltip = styled(Tooltip)`
  background: ${props => props.theme.palette.success.main};
  border-radius: 16px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  padding: 8px;
`;
