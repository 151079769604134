import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { buildRoutePath } from '../../utils/routingUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CardsMainPage = () => {
  const { gameSlug } = useParams();
  const { cardDatabase } = useFlags();

  if (!cardDatabase) {
    return <Navigate to={buildRoutePath(gameSlug, '/')} />;
  }
  return <Outlet />;
};

export default CardsMainPage;
