import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import { Grid, Typography } from '@mui/material';

const GameStats = ({ totalEvents, totalPlayers }) => {
  const { game } = useSelector(({ app }) => app);

  return (
    <Grid item>
      <Grid container>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">
                <CountUp
                  duration={3}
                  end={totalEvents}
                  suffix={totalEvents > 100 ? '+' : ''}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Events</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">
                <CountUp
                  delay={0.5}
                  duration={2.5}
                  end={totalPlayers}
                  suffix="+"
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {get(game, 'terminology.players', 'Players')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GameStats;
