import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import StatusPage, { statusTypes } from '../Status';
import { capitalize, get, unset } from 'lodash';
import { lgsDefault, lgsSchema } from '../../schema/lgs/lgs';
import { useDispatch, useSelector } from 'react-redux';

import { GooglePlaceTextField } from '../../components/Shared/GooglePlaceTextField';
import { LoadingButton } from '@mui/lab';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { createApplication } from '../../services/entity';
import { findFormError } from '../../utils/react-hook-form-utils';
import { getEntityApplications } from '../../store/app/actions';
import { joiResolver } from '@hookform/resolvers/joi';
import { toast } from 'react-toastify';

const LgsApplicationPage = () => {
  // Redux
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const { game, user } = useSelector(({ app }) => app);
  const { slug } = game || { slug: '' };
  const { op } = game.titles || { op: '' };
  const toastId = 'lgsApplicationPage';

  // Hook States
  const [legalAge, setLegalAge] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(
    get(user, 'details.gameUser.lgsApplications', []).length > 0 ? true : false
  );

  const defaultValues = {
    ...lgsDefault,
    email: get(user, 'details.info.email', '')
  };
  unset(defaultValues, 'location.isOnline');

  // React Hook form
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm({
    defaultValues,
    resolver: joiResolver(lgsSchema)
  });

  const description = [
    // eslint-disable-next-line react/jsx-key
    <Typography>
      Thank you for considering using the {op} Event Organizer to plan and
      manage your events!
    </Typography>,
    // eslint-disable-next-line react/jsx-key
    <Typography>
      We understand that organizing events can be a challenging and
      time-consuming task, but we are here to help make the process as smooth
      and stress-free as possible. With the {op} Event Organizer, you can easily
      create and customize your events, invite organizers to your organization,
      track player stats, and manage all of your events in one convenient place.
    </Typography>,
    // eslint-disable-next-line react/jsx-key
    <Typography>
      The {op} Event Organizer is built on top of Carde.io Play Software and is
      managed inside of the Carde.io Admin Panel. If your Gaming Organization
      already has an account with Carde.io we recommend applying through the
      Carde.io Admin Panel. If not, please apply through this form if you would
      like to organize sanctioned {capitalize(slug)} Events through the {op}.
      Before proceeding, please make sure you meet the following criteria:
    </Typography>,
    // eslint-disable-next-line react/jsx-key
    <Typography component={'div'}>
      <ol>
        <li>Are at least 18+ years of age.</li>
        <li>Agree to the Carde.io Terms of Use and Privacy Policy.</li>
      </ol>
    </Typography>,
    // eslint-disable-next-line react/jsx-key
    <Typography>
      In addition, the business you are organizing events for must meet the
      following criteria:
    </Typography>,
    // eslint-disable-next-line react/jsx-key
    <Typography component={'div'}>
      <ol>
        <li>Must have a legal entity.</li>
        <li>Must have a physical location/storefront to run events at.</li>
        <li>
          Must be able to comfortably and safely seat at least 8 players at the
          location above.
        </li>
        <li>
          Must be able to comfortably and safely seat at least 8 players at a
          rented venue.
        </li>
      </ol>
    </Typography>
  ];

  const businessRelationshipChoices = [
    { title: 'Owner or Co-Owner', key: 'owner' },
    { title: 'Employee', key: 'employee' },
    {
      title: 'Non-Employee Affiliate (i.e., trusted individual)',
      key: 'affiliate'
    }
  ];

  const commonRules = { required: true };

  const onSubmit = async data => {
    const sendData = { ...data, location: { ...data.location } };
    const address2 = get(sendData, 'address2', '');
    if (address2 !== '') {
      sendData.location.address2 = address2;
    }
    unset(sendData, 'address2');
    const res = await createApplication(game.id, sendData);

    if (res) {
      dispatch(getEntityApplications(game.id, get(user, 'details.id')));
      setSuccess(true);
    } else {
      toast('Unable to Create Application.', {
        toastId
      });
    }
  };

  if (success) {
    return (
      <StatusPage
        type={statusTypes.successful}
        customTitle={'Application Sent!'}
        body={`Thank you for wanting to use ${op}.We have received your application and will be sending you a status update by email within 24-48 hours.`}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MaxWidthContainer container className={'p-percent p-top-5'}>
        {/* Title */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'h1'} gutterBottom>
              {op} Organizer Application
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {description.map((obj, index) => (
                <Grid item xs={12} key={index}>
                  {obj}
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Age requirement */}
          <Grid item xs={12}>
            <Grid item>
              <Typography fontWeight={'bold'}>
                Are you 18+ years old?
              </Typography>
            </Grid>

            {/* Legal Age Checkbox */}
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    id="lgs-legal-age-checkbox"
                    data-testid="lgs-legal-age-checkbox"
                    checked={legalAge}
                    onChange={() => setLegalAge(prev => !prev)}
                  />
                }
                label={'Yes I am'}
              />
            </Grid>
          </Grid>

          {/* Business Relationship */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography fontWeight={'bold'}>
                  What is your relationship to the business?
                </Typography>
              </Grid>
              <Grid item>
                <FormControl error={findFormError(errors, 'relationship')}>
                  <Controller
                    control={control}
                    name={'relationship'}
                    rules={commonRules}
                    render={({ field }) => (
                      <RadioGroup
                        id="lgs-business-relationship-radio-select"
                        data-testid="lgs-business-relationship-radio-select"
                        {...field}
                      >
                        {businessRelationshipChoices.map(obj => (
                          <Grid container key={obj.key} alignItems={'center'}>
                            <Grid item>
                              <FormControlLabel
                                value={obj.key}
                                control={
                                  <Radio
                                    id={`lgs-business-relationship-${obj.key}-radio-option`}
                                    data-testid={`lgs-business-relationship-${obj.key}-radio-option`}
                                  />
                                }
                                label={obj.title}
                              />
                            </Grid>
                            {/*{obj.key === 'other' && (*/}
                            {/*  <Grid item>*/}
                            {/*    <Controller*/}
                            {/*      control={control}*/}
                            {/*      name={'relationshipDescription'}*/}
                            {/*      render={({ field }) => (*/}
                            {/*        <TextField*/}
                            {/*          {...field}*/}
                            {/*          error={*/}
                            {/*            findFormError(*/}
                            {/*              errors,*/}
                            {/*              'relationshipDescription'*/}
                            {/*            ) &&*/}
                            {/*            getValues('relationship') === 'other'*/}
                            {/*          }*/}
                            {/*          onClick={() =>*/}
                            {/*            setValue('relationship', 'other')*/}
                            {/*          }*/}
                            {/*          size={'small'}*/}
                            {/*          inputProps={{*/}
                            {/*            sx: { fontSize: 'inherit' }*/}
                            {/*          }}*/}
                            {/*        />*/}
                            {/*      )}*/}
                            {/*    />*/}
                            {/*  </Grid>*/}
                            {/*)}*/}
                          </Grid>
                        ))}
                      </RadioGroup>
                    )}
                  />
                  {findFormError(errors, 'relationship') && (
                    <FormLabel component="legend">* Please pick One</FormLabel>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Business Info */}

          <Grid item xs={12}>
            <Typography variant={'h2'} gutterBottom>
              Business Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {/* Business Name */}
              <Grid item xs={12}>
                <Controller
                  name={'name'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="lgs-business-name-input"
                      data-testid="lgs-business-name-input"
                      error={findFormError(errors, 'name')}
                      label={'Organization Name'}
                    />
                  )}
                />
              </Grid>

              {/* Legal Business Name */}
              <Grid item xs={12}>
                <Controller
                  name={'legalName'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="lgs-legal-business-name-input"
                      data-testid="lgs-legal-business-name-input"
                      {...field}
                      error={findFormError(errors, 'legalName')}
                      label={'Legal Business Entity Name'}
                      helperText="LLC, Inc, etc."
                    />
                  )}
                />
              </Grid>

              {/* Location*/}
              <Grid item xs={12}>
                <Controller
                  name={'location'}
                  control={control}
                  render={({ field }) => (
                    <GooglePlaceTextField
                      id="lgs-business-location-input"
                      data-testid="lgs-business-location-input"
                      {...field}
                      error={findFormError(errors, 'location')}
                    />
                  )}
                />
              </Grid>

              {/* Address2 */}
              <Grid item xs={12}>
                <Controller
                  name={'address2'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="lgs-business-location-2-input"
                      data-testid="lgs-business-location-2-input"
                      {...field}
                      label="Address 2"
                      helperText="Apt, suite, ect. (optional)"
                    />
                  )}
                />
              </Grid>

              {/* Business Phone Number */}
              <Grid item xs={12}>
                <Controller
                  name={'phoneNumber'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="lgs-business-phone-number-input"
                      data-testid="lgs-business-phone-number-input"
                      {...field}
                      error={findFormError(errors, 'phoneNumber')}
                      label={'Business Phone Number'}
                      helperText="ex. 123-456-7890"
                      type={'tel'}
                    />
                  )}
                />
              </Grid>

              {/* Business Website */}
              <Grid item xs={12}>
                <Controller
                  name={'website'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="lgs-business-website-input"
                      data-testid="lgs-business-website-input"
                      {...field}
                      error={findFormError(errors, 'website')}
                      label={'Business Website'}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Final Confirmation */}
          <Grid item xs={12}>
            <Grid container paddingTop={4} spacing={8}>
              <Grid item xs={12}>
                <Grid item>
                  <Typography fontWeight={'bold'} gutterBottom>
                    Please confirm the following:
                  </Typography>
                </Grid>

                {/* Confirm Checkbox */}
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="lgs-business-application-confirm-checkbox"
                        data-testid="lgs-business-application-confirm-checkbox"
                        checked={confirm}
                        onChange={() => setConfirm(prev => !prev)}
                      />
                    }
                    label={
                      'I confirm that the venue where I am organizing events is able to safely and comfortably seat at least 8 players for all event types.' +
                      ' I confirm that the venue is compliant with all local building/fire regulations.' +
                      ' I confirm all of the information I am about to submit is accurate and true.'
                    }
                  />
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                  <LoadingButton
                    id="lgs-business-application-submit-button"
                    data-testid="lgs-business-application-submit-button"
                    disabled={!legalAge || !confirm}
                    loading={isSubmitting}
                    fullWidth
                    type={'submit'}
                    variant={'contained'}
                    sx={{ maxWidth: '300px' }}
                  >
                    Sign Up
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </form>
  );
};

export default LgsApplicationPage;
