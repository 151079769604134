import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { hasSomeValue } from '../../../utils/common-utils';
import { useFormContext } from 'react-hook-form';

export const StyledTextField = props => {
  const {
    property,
    type,
    inputProps,
    id,
    label,
    value,
    onChange,
    ...restProps
  } = props;
  const formContext = useFormContext(); // retrieve all hook methods
  const register =
    formContext !== null && formContext.register
      ? formContext.register(property, { required: false })
      : {};

  return (
    <TextField
      {...register}
      type={type}
      // required
      inputProps={inputProps}
      id={id ? id : 'outlined-basic'}
      data-testid={id ? id : 'outlined-basic'}
      variant="outlined"
      label={label}
      onChange={onChange}
      onWheel={event => event.currentTarget.blur()}
      value={value}
      fullWidth
      {...restProps}
    />
  );
};

export const StyledSearchTextField = props => {
  const { id, label, value, placeholder, onChange, sx, ...restProps } = props;
  return (
    <TextField
      size={'small'}
      id={id ? id : 'search-text-basic'}
      data-testid={id ? id : 'search-text-basic'}
      variant="outlined"
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder || 'Search'}
      fullWidth
      sx={sx}
      {...restProps}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export const StyledSelectTextField = props => {
  const { label, value, onChange, menuItems, ...respProps } = props;
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      placeholder={'Search'}
      fullWidth
      select
      id={props.id ? props.id : 'select-field-basic'}
      data-testid={props.id ? props.id : 'select-field-basic'}
      InputLabelProps={{
        shrink: true
      }}
      SelectProps={{
        displayEmpty: true,
        id: props.id
          ? `${props.id}-select-field`
          : 'select-field-basic-select-field',
        'data-testid': props.id
          ? `${props.id}-select-field`
          : 'select-field-basic-select-field'
      }}
      {...respProps}
      inputProps={{
        ...respProps.inputProps,
        'data-testid': props.id
          ? `${props.id}-input`
          : 'select-field-basic-input'
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="clear selected value"
            onClick={() => console.log('click adornment')}
            onMouseDown={() => console.log('mouse down adornment')}
            edge="end"
          >
            {hasSomeValue(value) && <CloseIcon />}
          </IconButton>
        </InputAdornment>
      }
    >
      {menuItems.length
        ? menuItems.map(obj => {
            return (
              <MenuItem
                id={`${props.idPrefix || 'menu-item'}-${obj._id ||
                  'default'}-option`}
                data-testid={`${props.idPrefix || 'menu-item'}-${obj._id ||
                  'default'}-option`}
                value={obj._id || ''}
                key={`menu_item_${obj._id || 'default'}`}
              >
                {obj.name}
              </MenuItem>
            );
          })
        : null}
    </TextField>
  );
};

// Reference on how to style the textfields
// sx={{
//   borderRadius: '12px',
//     backgroundColor: theme.secondaryColor,
//     color: theme.white,
//     '.MuiInputBase-root': {
//     backgroundColor: theme.opacity6,
//       borderRadius: '12px',
//       '& fieldset': {
//       borderColor: theme.opacity4
//     },
//     '&:hover fieldset': {
//       borderColor: theme.opacity4,
//         backgroundColor: theme.opacity6
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: theme.primaryColor,
//         backgroundColor: 'unset'
//     }
//   }
