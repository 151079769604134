import { Button, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import Countdown from 'react-countdown';
import { EventRoundTimerModal } from '../EventRoundTimerModal';
import { ModalContext } from '../../Modal/Context/ModalContext';
import dayjs from 'dayjs';
import { eventStatuses } from '../../../values/event-values';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

export const EventRoundTimer = ({
  eventStatus = eventStatuses.started.key,
  currentRound = 1,
  goToRound = null,
  pairingsData = {},
  activeEvent = {},
  onConfirmStartTimer = () => {},
  startTimerResult = {}
}) => {
  const theme = useTheme();
  const { addModal, closeModal, setIsLoading } = useContext(ModalContext);
  const [futureTimerEnds, setFutureTimerEnds] = useState(false);

  useEffect(() => {
    setIsLoading(startTimerResult.isLoading);
  }, [startTimerResult.isLoading]);

  useEffect(() => {
    if (
      startTimerResult &&
      (startTimerResult.isSuccess || startTimerResult.isError)
    ) {
      closeModal();
    }
  }, [startTimerResult]);

  const onClickSetStartTime = () => {
    addModal({
      children: (
        <EventRoundTimerModal
          sx={{ maxWidth: '300px' }}
          onConfirm={onConfirmStartTimer}
        />
      )
    });
  };

  const isRoundCompleted = () => {
    return (
      eventStatus === eventStatuses.complete.key || goToRound !== currentRound
    );
  };

  const isStartTimeInFuture = () => {
    const startTime = get(pairingsData, 'roundDetails.startTime');
    const now = dayjs();
    if (startTime) {
      return now.isBefore(startTime);
    }
    return null;
  };

  const isEndTimeAvailable = () => {
    const endTime = get(pairingsData, 'roundDetails.endTime');
    return endTime ? true : false;
  };

  const renderEndTimeCountdown = () => {
    return (
      <Countdown
        key="end_timer"
        date={get(pairingsData, 'roundDetails.endTime')}
        daysInHours
        autoStart
        renderer={({ hours, minutes, seconds, completed }) => {
          if (completed) {
            // Render a completed state
            return (
              <Typography mt={1} variant="h4">
                Round Finished
              </Typography>
            );
          } else {
            // Render a countdown
            return (
              <Typography mt={1} variant="h4">
                {hours > 0 && `${hours} hr${hours === 1 ? '' : 's'} `}
                {minutes} min {seconds} sec
              </Typography>
            );
          }
        }}
      />
    );
  };

  const renderFutureStartTimeCountdown = () => {
    const startDate = get(pairingsData, 'roundDetails.startTime');
    return (
      <Countdown
        key="start_timer"
        date={startDate}
        daysInHours
        onComplete={() => setFutureTimerEnds(true)}
        renderer={({ completed }) => {
          if (completed) {
            // Render a completed state
            return renderEndTimeCountdown();
          } else {
            // Render a countdown
            return (
              <Typography mt={1} variant="h4">
                Starts at {dayjs(startDate).format('hh:mm A')}
              </Typography>
            );
          }
        }}
      />
    );
  };

  const renderButtonOrText = () => {
    return (
      <>
        {get(activeEvent, 'isOrganizer') || get(activeEvent, 'isJudge') ? (
          <Button
            id="event-pairings-set-start-timer-button"
            data-testid="event-pairings-set-start-timer-button"
            variant="outlined"
            onClick={onClickSetStartTime}
            sx={{
              color: theme.palette.text.primary,
              marginBottom: '-20px'
            }}
          >
            Set Start Timer
          </Button>
        ) : (
          <Typography mt={1} variant="h4">
            Starting Soon
          </Typography>
        )}
      </>
    );
  };

  const renderConditionalCountdown = () => {
    if (isRoundCompleted()) {
      return (
        <Typography mt={1} variant="h4">
          Round Finished
        </Typography>
      );
    }
    if (isStartTimeInFuture() && !futureTimerEnds) {
      return renderFutureStartTimeCountdown();
    }
    if (isEndTimeAvailable() || futureTimerEnds) {
      return renderEndTimeCountdown();
    }
    return renderButtonOrText();
  };

  const getHeadingText = () => {
    if (!isStartTimeInFuture() || futureTimerEnds) {
      return 'TIME REMAINING';
    }
    return 'TIME TO START';
  };

  const startTime = get(pairingsData, 'roundDetails.startTime');

  return (
    <>
      <Typography
        sx={!startTime && { marginTop: '-20px', marginBottom: '10px' }}
        variant="h6"
      >
        {getHeadingText()}
      </Typography>
      {renderConditionalCountdown()}
    </>
  );
};
