/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { find, get, isEmpty } from 'lodash';
import {
  useGetCardsFiltersQuery,
  useGetSingleDeckQuery
} from '../../../../../../services/apis/organizePlayApi';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import DatasetIcon from '@mui/icons-material/Dataset';
import DeckDetailHeader from '../../../../../Decks/DeckDetailHeader';
import ErrorIcon from '@mui/icons-material/Error';
import Loading from '../../../../../Loading/Loader';
import React from 'react';
import Tooltip from '../../../../../Shared/Tooltip';
import { getDeckErrors } from '../../../../../../utils/deck';
import { useSelector } from 'react-redux';

const HeaderActionContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackActionContainer = styled(Box)(({ imageUrl, theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& .MuiButtonBase-root': {
    color: theme.palette.action.active
  }
}));

const BackButton = styled(Button)`
  color: #ffffff;
  padding: 12px;
`;

export const AddDeckToEventDeckDetailsView = ({ onClickBack, deckId }) => {
  const { game } = useSelector(({ app }) => app);
  const {
    data: filters,
    isLoading: isFiltersLoading
  } = useGetCardsFiltersQuery(get(game, 'id', ''));
  const theme = useTheme();
  const defaultDeckTypeData = game?.defaultDeckType;
  const {
    data: singleDeck,
    isLoading,
    isFetching,
    isSuccess
  } = useGetSingleDeckQuery(deckId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  /** TODO: Hard coded for now, delete when metrics is more advance **/
  const auras =
    filters
      ?.find(filter => filter.name === 'Auras')
      ?.options?.reduce((prev, current) => {
        return { ...prev, [current.searchTerm]: current };
      }, {}) || {};

  const getSectionName = deckSectionId => {
    return get(
      find(
        defaultDeckTypeData.deckSections,
        section => section._id === deckSectionId
      ),
      'name'
    );
  };

  const getCardName = cardId => {
    return get(
      find(singleDeck.data.cards, card => card._id === cardId),
      'name',
      'card'
    );
  };
  const renderHeaderSection = () => {
    return (
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <HeaderActionContainer>
              <BackActionContainer>
                <BackButton
                  id="back-button"
                  data-testid="back-button"
                  aria-label="close"
                  onClick={onClickBack}
                  disabled={false}
                  variant="text"
                  color="secondary"
                  startIcon={<ArrowBackIosNewIcon />}
                >
                  Back
                </BackButton>
              </BackActionContainer>
              <IconButton
                id="close-button"
                data-testid="close-button"
                aria-label="close"
                onClick={onClickBack}
                disabled={false}
              >
                <CloseIcon />
              </IconButton>
            </HeaderActionContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDeckHeader = () => {
    const { deck, gameUser, user } = singleDeck.data;
    return (
      <Grid item xs={12}>
        <DeckDetailHeader
          deck={deck}
          gameUser={gameUser}
          user={user}
          showOptions={false}
        />
      </Grid>
    );
  };
  return (
    <Grid container spacing={1}>
      {renderHeaderSection()}
      {isLoading || isFetching || isFiltersLoading ? (
        <Loading />
      ) : (
        <>
          {renderDeckHeader()}
          <Grid item xs={12} mt="16px">
            <Grid container spacing={2}>
              {get(singleDeck.data, 'deck.sections', []).map(deckSection => {
                const auraData = Object.entries(
                  get(
                    singleDeck,
                    `data.metrics.metrics.auraTypes.${deckSection?.deckSectionId}`,
                    {}
                  )
                );
                const sectionError = getDeckErrors({
                  errors: singleDeck?.data?.deck?.errors,
                  id: deckSection?.deckSectionId
                });
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={deckSection.deckSectionId}
                    sx={{ margin: { xs: '8px', sm: '0' } }}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ background: theme.palette.neutral[400] }}
                      padding="8px 4px"
                    >
                      <Grid item xs={12}>
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <Typography variant="h6">
                              {getSectionName(deckSection.deckSectionId)}
                            </Typography>
                          </Grid>
                          {sectionError && (
                            <Grid item>
                              <Tooltip
                                icon={
                                  <ErrorIcon
                                    className={'error-icon'}
                                    color={'error'}
                                    fontSize={'small'}
                                  />
                                }
                                info={sectionError}
                              />
                            </Grid>
                          )}
                          {!isEmpty(auraData) && (
                            <Grid item>
                              <Tooltip
                                icon={<DatasetIcon fontSize={'small'} />}
                                info={auraData.map(aura => {
                                  const name = aura[0]
                                    .toString()
                                    .includes('total')
                                    ? 'Total'
                                    : get(
                                        auras,
                                        `${aura[0]}.displayText`,
                                        'NA'
                                      );
                                  return (
                                    <Box
                                      key={aura[0]}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5,
                                        paddingLeft: name === 'Total' ? 0 : 1,
                                        paddingTop: name === 'Total' ? 0 : 0.5
                                      }}
                                    >
                                      {name !== 'Total' && (
                                        <img
                                          src={get(
                                            auras,
                                            `${aura[0]}.displayIcon`
                                          )}
                                          height={'20px'}
                                          width={'20px'}
                                        />
                                      )}
                                      <Typography>
                                        {name}: {aura[1]}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {get(deckSection, 'cards', []).map(deckCard => {
                        const cardError = getDeckErrors({
                          errors: singleDeck?.data?.deck?.errors,
                          id: deckCard.cardId
                        });
                        return (
                          <Grid
                            container
                            key={deckCard.cardId}
                            alignItems={'center'}
                          >
                            <Grid item>
                              <Typography>
                                {`${get(deckCard, 'count', 1)}x ${getCardName(
                                  get(deckCard, 'cardId')
                                )}`}
                              </Typography>
                            </Grid>
                            {cardError && (
                              <Grid item>
                                <Tooltip
                                  icon={
                                    <ErrorIcon
                                      className={'error-icon'}
                                      color={'error'}
                                      fontSize={'small'}
                                    />
                                  }
                                  info={cardError}
                                />
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
