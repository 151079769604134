import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useContext, useState } from 'react';

import { ModalContext } from '../../../../../../../components/Modal/Context/ModalContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SimpleModalCard from '../../../../../../../components/Modal/Cards/SimpleModalCard';
import { dayjsWithPlugins as dayjs } from '../../../../../../../utils/dayjs';
import { get } from 'lodash';
import { getUserFullName } from '../../../../../../../utils/user';

const StyledOrganizerListItemContainer = styled(Grid)(({ theme }) => ({
  background: alpha(theme.palette.background.default, 0.3),
  padding: '12px 16px',
  marginBottom: '8px',
  marginTop: '8px',
  borderRadius: '8px'
}));

// eslint-disable-next-line no-unused-vars
const StyledUserInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}));

const StyledStoreMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiList-root': {
      padding: '12px 0',
      '& .MuiButtonBase-root': {
        fontSize: '16px',
        '&:not(:last-child)': {
          marginBottom: '8px'
        },
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }
  }
}));

const OrganizerListItem = ({
  organizer,
  itemIndex = 0,
  onSelect,
  isSelected,
  onDelete,
  isDeleting = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { addModal } = useContext(ModalContext);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDeleteConfirmClick = () => {
    onDelete(organizer.user._id);
  };
  const onDeleteOrganizerClick = () => {
    handleClose();
    addModal({
      children: (
        <SimpleModalCard
          title={'Remove organizer?'}
          cancelButton={{ id: 'delete-organizer-cancel-button' }}
          confirmButton={{
            id: 'delete-organizer-confirm-button',
            onClick: () => onDeleteConfirmClick(),
            title: 'Remove',
            color: 'error'
          }}
        >
          <Typography textAlign={'center'} typography={'h4'}>
            Are you sure?
          </Typography>
        </SimpleModalCard>
      ),
      style: { maxWidth: '500px' }
    });
  };
  const getLastUpdatedDateString = () => {
    const updatedAt = get(organizer, 'metaData.updatedAt');
    if (!updatedAt) {
      const createdAt = get(organizer, 'metaData.createdAt');
      if (!createdAt) {
        return new Date();
      }
      return new Date(createdAt);
    }
    return new Date(updatedAt);
  };
  const lastUpdateTime = dayjs(getLastUpdatedDateString());
  const addedOn = dayjs(
    get(
      organizer,
      'metaData.createdAt',
      get(organizer, 'user.metaData.createdAt'),
      new Date()
    )
  ).format('MMMM, DD YYYY');
  return (
    <StyledOrganizerListItemContainer container key={organizer._id}>
      <Grid item xs={8} sm={5}>
        <StyledUserInfoContainer>
          <Checkbox
            sx={{ marginRight: '8px' }}
            value={isSelected}
            onChange={() => onSelect(organizer.user._id)}
            disabled={isDeleting}
          />
          <Avatar
            src={get(organizer, 'gameUser.imageUrl')}
            sx={{
              height: '32px',
              mr: '16px',
              width: '32px'
            }}
          />
          {isXs ? (
            <Box display="flex" flexDirection="column">
              <Typography variant="body3Light">
                {getUserFullName(organizer?.user)}
              </Typography>
              <Typography variant="body4Light">({organizer?.role})</Typography>
            </Box>
          ) : (
            <Typography variant="body3Light">
              {getUserFullName(organizer?.user)}
            </Typography>
          )}
        </StyledUserInfoContainer>
      </Grid>
      <Grid item sm={2} display={{ xs: 'none', sm: 'initial' }}>
        <Typography variant="body3Light" textTransform="capitalize">
          {organizer?.role}
        </Typography>
      </Grid>
      <Grid item xs={3} sm={2}>
        <Typography variant="body3Light">{addedOn}</Typography>
      </Grid>
      <Grid item sm={2} display={{ xs: 'none', sm: 'initial' }}>
        <Typography variant="body3Light">{lastUpdateTime.fromNow()}</Typography>
      </Grid>
      <Grid item xs={1} sm={1} textAlign="end">
        <IconButton
          id={`organizer-list-action-button-${itemIndex}`}
          data-testid={`organizer-list-action-button-${itemIndex}`}
          aria-controls={open ? 'organizer-list-action-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={isDeleting}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledStoreMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem
            data-testid={`organizer-list-action-button-${itemIndex}-remove`}
            onClick={onDeleteOrganizerClick}
          >
            Remove Organizer
          </MenuItem>
        </StyledStoreMenu>
      </Grid>
    </StyledOrganizerListItemContainer>
  );
};

export default OrganizerListItem;
