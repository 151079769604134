import React, { Fragment } from 'react';

const LinkedinIcon = ({ color }) => (
  <Fragment>
    <path
      d="M5.37214 23.6252H0.396429V7.85235H5.37214V23.6252ZM2.88161 5.70079C1.29054 5.70079 0 4.40353 0 2.83731C1.13882e-08 2.08501 0.303597 1.36351 0.844003 0.831548C1.38441 0.299586 2.11736 0.000732422 2.88161 0.000732422C3.64586 0.000732422 4.3788 0.299586 4.91921 0.831548C5.45962 1.36351 5.76321 2.08501 5.76321 2.83731C5.76321 4.40353 4.47214 5.70079 2.88161 5.70079ZM23.9946 23.6252H19.0296V15.9471C19.0296 14.1172 18.9921 11.7705 16.4427 11.7705C13.8557 11.7705 13.4593 13.7586 13.4593 15.8152V23.6252H8.48893V7.85235H13.2611V10.0039H13.3307C13.995 8.76466 15.6177 7.45685 18.0386 7.45685C23.0743 7.45685 24 10.7211 24 14.9609V23.6252H23.9946Z"
      fill={color}
    />
  </Fragment>
);

export default LinkedinIcon;
