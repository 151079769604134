export const verifyEmailStyle = theme => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: { xs: 2, md: 0 },
  ['.verify-email-text-container']: {
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    ['.user-email']: {
      color: theme.palette.primary.main
    },
    textAlign: 'center'
  },
  ['.MuiButtonBase-root']: {
    marginTop: 2
  }
});

export const emailIconStyle = () => ({
  fontSize: { xs: '100px', sm: '125px' }
});
