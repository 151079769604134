import {
  CREATE_NEW_EVENT_REQUEST,
  CREATE_NEW_EVENT_SUCCESS,
  CREATE_NEW_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENT_PAIRINGS_REQUEST,
  GET_EVENT_PAIRINGS_SUCCESS,
  GET_EVENT_PAIRINGS_FAILURE,
  GET_EVENT_ROSTER_REQUEST,
  GET_EVENT_ROSTER_SUCCESS,
  GET_EVENT_ROSTER_FAILURE,
  GET_EVENT_STANDINGS_REQUEST,
  GET_EVENT_STANDINGS_SUCCESS,
  GET_EVENT_STANDINGS_FAILURE,
  ADD_PARTICIPANTS_REQUEST,
  ADD_PARTICIPANTS_SUCCESS,
  ADD_PARTICIPANTS_FAILURE,
  UPDATE_PARTICIPANT_REQUEST,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_FAILURE,
  START_EVENT_REQUEST,
  START_EVENT_SUCCESS,
  START_EVENT_FAILURE,
  NEXT_ROUND_REQUEST,
  NEXT_ROUND_SUCCESS,
  NEXT_ROUND_FAILURE,
  REPORT_RESULTS_REQUEST,
  REPORT_RESULTS_SUCCESS,
  REPORT_RESULTS_FAILURE,
  START_TIME_IN_ROUND_REQUEST,
  START_TIME_IN_ROUND_SUCCESS,
  START_TIME_IN_ROUND_FAILURE,
  RESET_ACTIVE_EVENT,
  SET_ACTIVE_ROUND,
  FINALIZE_EVENT_SUCCESS
} from './types';
import { isArray } from 'lodash';

const INITIAL_STATE = {
  activeEvent: {},
  allEvents: [],
  managedEvents: [],
  isCreatingNewEvent: false,
  isUpdatingEvent: false,
  isFetchingManagedEvents: false,
  isAddingParticipants: false,
  isUpdatingParticipant: false,
  isFetchingAllEvents: false,
  isFetchingEvent: false,
  isFetchingPairings: false,
  isFetchingRoster: false,
  isFetchingStandings: false,
  isStartingNextRound: false,
  isStartingTime: false,
  isUpdatingResults: false
};

const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_NEW_EVENT_REQUEST:
      return {
        ...state,
        isCreatingNewEvent: true
      };

    case CREATE_NEW_EVENT_SUCCESS:
      return {
        ...state,
        isCreatingNewEvent: false,
        activeEvent: action.activeEvent,
        allEvents: [
          ...(isArray(state.allEvents) ? state.allEvents : []),
          action.activeEvent
        ]
      };

    case CREATE_NEW_EVENT_FAILURE:
      return {
        ...state,
        isCreatingNewEvent: false
      };

    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        isUpdatingEvent: true
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          ...action.activeEvent,
          details: {
            ...state.activeEvent.details
          }
        },
        isUpdatingEvent: false
      };

    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        isUpdatingEvent: false
      };

    case GET_EVENT_REQUEST:
      return {
        ...state,
        isFetchingEvent: true
      };

    case GET_EVENT_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          ...action.activeEvent
        },
        isFetchingEvent: false
      };

    case GET_EVENT_FAILURE:
      return {
        ...state,
        isFetchingEvent: false
      };

    case GET_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingManagedEvents: true
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        managedEvents: action.managedEvents,
        isFetchingManagedEvents: false
      };

    case GET_EVENTS_FAILURE:
      return {
        ...state,
        isFetchingManagedEvents: false
      };

    case GET_EVENT_ROSTER_REQUEST:
      return {
        ...state,
        isFetchingRoster: true
      };

    case GET_EVENT_ROSTER_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          roster: action.roster
        },
        isFetchingRoster: false
      };

    case GET_EVENT_ROSTER_FAILURE:
      return {
        ...state,
        isFetchingRoster: false
      };

    case GET_EVENT_PAIRINGS_REQUEST:
      return {
        ...state,
        isFetchingPairings: true
      };

    case GET_EVENT_PAIRINGS_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          pairings: action.pairings,
          roundDetails: action.roundDetails
        },
        isFetchingPairings: false
      };

    case GET_EVENT_PAIRINGS_FAILURE:
      return {
        ...state,
        isFetchingPairings: false
      };

    case GET_EVENT_STANDINGS_REQUEST:
      return {
        ...state,
        isFetchingStandings: true
      };

    case GET_EVENT_STANDINGS_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          standings: action.standings
        },
        isFetchingStandings: false
      };

    case GET_EVENT_STANDINGS_FAILURE:
      return {
        ...state,
        isFetchingStandings: false
      };

    case ADD_PARTICIPANTS_REQUEST:
      return {
        ...state,
        isAddingParticipants: true
      };

    case ADD_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          canJoin: false,
          roster: action.newRoster,
          details: {
            ...state.activeEvent.details,
            participantCount: action.newRoster.length
          }
        },
        isAddingParticipants: false
      };

    case ADD_PARTICIPANTS_FAILURE:
      return {
        ...state,
        isAddingParticipants: false
      };

    case UPDATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        isUpdatingParticipant: true
      };

    case UPDATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          roster: action.newRoster
        },
        isUpdatingParticipant: false
      };

    case UPDATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        isUpdatingParticipant: false
      };

    case START_EVENT_REQUEST:
      return {
        ...state,
        isStartingNextRound: true
      };

    case START_EVENT_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          currentRound: 1,
          pairings: action.pairings,
          status: 'active'
        },
        isStartingNextRound: false
      };

    case START_EVENT_FAILURE:
      return {
        ...state,
        isStartingNextRound: false
      };

    case NEXT_ROUND_REQUEST:
      return {
        ...state,
        isStartingNextRound: true
      };

    case NEXT_ROUND_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          activeRound: state.activeEvent.activeRound + 1,
          currentRound: state.activeEvent.currentRound + 1,
          pairings: action.pairings,
          roundDetails: action.roundDetails
        },
        isStartingNextRound: false
      };

    case NEXT_ROUND_FAILURE:
      return {
        ...state,
        isStartingNextRound: false
      };

    case REPORT_RESULTS_REQUEST:
      return {
        ...state,
        isUpdatingResults: true
      };

    case REPORT_RESULTS_SUCCESS:
      const newPairings = [];
      state.activeEvent.pairings.forEach((pairing, index) => {
        if (index === action.pairingIndex) {
          newPairings.push(action.updatedPairing);
        } else {
          newPairings.push(pairing);
        }
      });
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          pairings: newPairings
        },
        isUpdatingResults: false
      };

    case REPORT_RESULTS_FAILURE:
      return {
        ...state,
        isUpdatingResults: false
      };

    case START_TIME_IN_ROUND_REQUEST:
      return {
        ...state,
        isStartingTime: true
      };

    case START_TIME_IN_ROUND_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          roundDetails: {
            ...state.activeEvent.roundDetails,
            endTime: action.endTime
          }
        },
        isStartingTime: false
      };

    case START_TIME_IN_ROUND_FAILURE:
      return {
        ...state,
        isStartingTime: false
      };

    case RESET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: INITIAL_STATE.activeEvent
      };

    case SET_ACTIVE_ROUND:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          activeRound: action.activeRound
        }
      };

    case FINALIZE_EVENT_SUCCESS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          status: 'complete',
          tournament: {
            ...state.activeEvent.tournament,
            status: 'complete'
          }
        },
        isStartingNextRound: false
      };

    default:
      return state;
  }
};

export default eventsReducer;
