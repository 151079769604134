/* eslint-disable no-unused-vars */
import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { StyledTextField } from '../../../../../../../components/Shared/TextFields';

const StyledSocialInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const SocialLinkUpdate = props => {
  const { value, onChange, name, ref } = props;
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');

  if (!value && !showInput) {
    return (
      <Button
        variant="contained"
        sx={{ padding: '4px 8px' }}
        size="small"
        onClick={() => setShowInput(true)}
      >
        <Typography variant="body3Light">Link</Typography>
      </Button>
    );
  }
  if (!value && showInput) {
    return (
      <StyledSocialInputContainer>
        <StyledTextField
          placeholder="Enter username"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          className="store-socials-username-input"
          sx={{
            maxWidth: '200px'
          }}
        />
        <Button
          size="small"
          sx={{ padding: '4px 8px' }}
          disabled={!inputValue}
          onClick={() => {
            onChange(inputValue);
            setShowInput(false);
          }}
        >
          Submit
        </Button>
        <IconButton
          sx={{ padding: 0, marginLeft: '8px' }}
          onClick={() => setShowInput(false)}
        >
          <CloseIcon />
        </IconButton>
      </StyledSocialInputContainer>
    );
  }
  return (
    <StyledSocialInputContainer>
      <Typography>{value}</Typography>
      <IconButton
        sx={{ padding: 0, marginLeft: '8px' }}
        onClick={() => {
          onChange('');
          setInputValue('');
          setShowInput(false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </StyledSocialInputContainer>
  );
};

export default SocialLinkUpdate;
