import React from 'react';
import { Grid, Typography } from '@mui/material';
import { EventCard } from '../../../../components/Events/Cards/EventCard';

const StoreUpcomingEvents = ({ events }) => {
  return (
    <Grid container width="100%" mt={16} gap={6}>
      <Grid item display="flex" justifyContent="center" xs={12}>
        <Typography variant="h2">Upcoming Events</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="center">
          {events.map(event => {
            return (
              <Grid key={event['_id']} item xs={12}>
                <EventCard
                  key={event._id}
                  id={`all-event-list-page-${event._id}`}
                  event={event}
                  halfSize
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreUpcomingEvents;
