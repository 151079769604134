import React, { useContext, useMemo, useState } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { MetricsContext } from '..';
import ChartWrapper from '../ChartWrapper';
import { useOutletContext } from 'react-router-dom';
import { getMetric } from '../utils';
import { useTheme } from '@mui/material';
import CustomTooltip from '../CustomTooltip';

const RenderDot = props => {
  const { r, fill, cx, cy } = props;
  const padding = 5;
  const multiplicatorFactor = 1.5;
  const circleRadius = r * multiplicatorFactor;
  return (
    <svg>
      <circle cx={cx} cy={cy} r={circleRadius - 0.5} fill={fill}>
        <animate
          attributeName="fill"
          values={`${fill}00;${fill};`}
          dur="0.5s"
          repeatCount="0"
        />
      </circle>
      <circle
        cx={cx}
        cy={cy}
        r={circleRadius + padding}
        fill="transparent"
        stroke={`${fill}90`}
        strokeWidth="3"
      >
        <animate
          attributeName="stroke"
          values={`${fill}00;${fill}90;`}
          dur="0.5s"
          repeatCount="0"
        />
      </circle>
    </svg>
  );
};

const ManaCurveChart = () => {
  const [renderLine, setRenderLine] = useState(false);
  const {
    isMobile,
    primaryColor,
    barSize,
    defaultMargin,
    getChartTitle,
    getChartTitleFromField,
    getFillArray
  } = useContext(MetricsContext);
  const theme = useTheme();
  const { deck } = useOutletContext();
  const totalCost = getMetric(deck, 'totalCost');
  const symbols = getMetric(deck, 'symbolsPresence');
  const defaultData = [{ cost: 0, quantity: 0 }];
  const data = useMemo(
    () =>
      Object.keys(totalCost)
        .map(key => ({
          cost: parseInt(key),
          quantity: totalCost[key].total
        }))
        .filter(({ cost }) => cost || cost === 0),
    [totalCost]
  );
  const curveName = getChartTitle(symbols)
    ? getChartTitle(symbols)
    : getChartTitleFromField(totalCost);

  return (
    <ChartWrapper title={`${curveName || 'Cost'} Curve`}>
      <ComposedChart
        data={data.length ? data : defaultData}
        margin={defaultMargin}
      >
        <CartesianGrid stroke={`${theme.palette.text.primary}20`} />
        <XAxis dataKey="cost" tick={data.length} />
        <YAxis width={25} allowDecimals={false} padding={{ top: 25 }} />
        <Tooltip
          cursor={false}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Bar
          onAnimationStart={() => {
            setRenderLine(false);
          }}
          onAnimationEnd={() => {
            setRenderLine(true);
          }}
          animationDuration={500}
          dataKey="quantity"
          barSize={barSize}
          fill={primaryColor}
        />
        {data.length && renderLine && (
          <Line
            type="monotone"
            dataKey="quantity"
            stroke={getFillArray(5)[4]}
            activeDot={false}
            animationDuration={740}
            animationEasing="ease"
            dot={<RenderDot fill={getFillArray(5)[4]} isMobile={isMobile} />}
          />
        )}
      </ComposedChart>
    </ChartWrapper>
  );
};

export default ManaCurveChart;
