import { Box, Button, Typography, alpha, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line no-unused-vars
const StyledDescriptionButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '& .MuiButtonBase-root': {
    marginTop: '4px',
    padding: '2px 6px',
    fontSize: '12px',
    textTransformation: 'lowercase',
    zIndex: 101,
    backgroundColor: theme.palette.neutral[100],
    '&::hover': {
      backgroundColor: theme.palette.neutral[200]
    }
  },
  '& .show-more-bar': {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    borderTop: `1px solid ${alpha(theme.palette.neutral[700], 0.2)}`,
    width: '100%',
    zIndex: 100
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledDescriptionCollapsed = styled(Box)(({ theme }) => ({
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '50%',
    right: '0',
    width: '100%',
    height: '1.2em',
    background:
      'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%)'
  }
}));

const DescriptionText = ({ center, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldDisplayButton, setShouldDisplayButton] = useState(false);
  const descElement = useRef(null);

  // This function will be called when the 'Show More' or 'Show Less' button is clicked
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // The number of lines to display in the collapsed state
  const collapsedNumLines = 2;

  useEffect(() => {
    if (descElement.current) {
      const { scrollHeight, clientHeight } = descElement.current;
      setShouldDisplayButton(scrollHeight > clientHeight);
    }
  }, [description, isExpanded]);

  // The styles to apply to the description
  const style = isExpanded
    ? {}
    : {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: String(collapsedNumLines),
        WebkitBoxOrient: 'vertical',
        position: 'relative'
      };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography
        as="div"
        ref={descElement}
        style={{ ...{ textAlign: center ? 'center' : 'initial' }, ...style }}
        variant="body2Light"
      >
        {description}
      </Typography>
      {(shouldDisplayButton || isExpanded) && (
        <StyledDescriptionButtonContainer>
          <Button color="primary" variant="outlined" onClick={handleToggle}>
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
          {shouldDisplayButton && !isExpanded && (
            <StyledDescriptionCollapsed className="description-text-collapsed"></StyledDescriptionCollapsed>
          )}
          <div className="show-more-bar" />
        </StyledDescriptionButtonContainer>
      )}
    </Box>
  );
};

export default DescriptionText;
