import { Paper, styled, ToggleButton } from '@mui/material';

export const ToolbarContainer = styled(Paper)`
  display: flex;
  overflow: scroll;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const ToolbarToggleButton = styled(ToggleButton)`
  border: unset;
  border-radius: 0;
`;
