import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';

import React from 'react';
import { get } from 'lodash';
const SeasonsHeader = ({ game, seasonId, setSeasonId }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h1">Seasons</Typography>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center" gap="18px">
          <Typography variant="body2">Past seasons</Typography>
          <FormControl
            sx={{
              minWidth: 120
            }}
            size="small"
          >
            <Select
              id="select-season"
              data-testid="select-season"
              value={seasonId}
              onChange={event => setSeasonId(event.target.value)}
              sx={{
                '#select-season': {
                  backgroundColor: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 24px'
                }
              }}
            >
              {get(game, 'seasons', []).map(season => (
                <MenuItem key={season._id} value={season._id}>
                  <Typography>{season.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SeasonsHeader;
