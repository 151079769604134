import React, { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { EventStatusChipStyled } from './style';

import {
  eventStatusDisplayConfig,
  eventStatuses
} from '../../../values/event-values';

export const EventStatusChip = ({
  eventStatus = eventStatuses.registration.key
}) => {
  const theme = useTheme();

  const getColorConfig = useCallback(() => {
    switch (eventStatus) {
      case eventStatuses.registration.key:
        return {
          bgColor: eventStatusDisplayConfig[eventStatus].color,
          textColor: theme.palette.getContrastText(
            eventStatusDisplayConfig[eventStatus].color
          )
        };
      case eventStatuses.started.key:
        return {
          bgColor: theme.palette.success.main,
          textColor: theme.palette.success.contrastText
        };
      default:
        return {
          bgColor: theme.palette.primary.main,
          textColor: theme.palette.primary.contrastText
        };
    }
  }, [eventStatus]);

  return (
    <EventStatusChipStyled
      size="medium"
      label={eventStatusDisplayConfig[eventStatus].displayText}
      {...getColorConfig()}
    />
  );
};
