import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Typography } from '@mui/material';

const AccordionMenu = ({
  detailsStyle,
  summaryStyle,
  variant = 'h3',
  title,
  children,
  childrenSummary,
  sx,
  ...props
}) => {
  return (
    <Accordion sx={sx} {...props}>
      <AccordionSummary
        sx={{ ...summaryStyle }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-testid="panel1a-header"
      >
        {childrenSummary ? (
          childrenSummary
        ) : (
          <Typography variant={variant}>{title}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ ...detailsStyle }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionMenu;
