/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { capitalize, get } from 'lodash';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React from 'react';
import { formatHour } from '../../../../../../utils/formatHour';

const typographyStyle = {
  typography: {
    xs: 'body3Light',
    sm: 'body2Light'
  },
  whiteSpace: 'nowrap'
};

const StoreHours = ({ store }) => {
  const theme = useTheme();
  const textColor = alpha(
    theme.palette.getContrastText(theme.palette.background.paper),
    0.7
  );

  return (
    <Box display="flex" alignItems="flex-start">
      <AccessTimeIcon color={theme.palette.text.primary} />
      <Box
        display="flex"
        width={{ xs: '80%', sm: '60%' }}
        justifyContent="space-between"
      >
        <Box height="100%" display="flex" gap={1} flexDirection="column">
          {get(store, 'info.openingHours', []).map(item => (
            <Typography
              key={item.day}
              ml={1}
              color={textColor}
              sx={{
                ...typographyStyle
              }}
            >
              {capitalize(item.day)}
            </Typography>
          ))}
        </Box>
        <Box display="flex" gap={1} flexDirection="column" alignItems="center">
          {get(store, 'info.openingHours', []).map(item =>
            item.isOpen ? (
              <Typography
                key={item.day}
                ml={1}
                color={textColor}
                sx={{
                  ...typographyStyle
                }}
              >
                {formatHour(item.openAt)} - {formatHour(item.closeAt)}
              </Typography>
            ) : (
              <Typography
                key={item.day}
                ml={1}
                color={textColor}
                sx={{
                  ...typographyStyle
                }}
              >
                Closed
              </Typography>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StoreHours;
