import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import StackView from './StackView';
import TextView from './TextView';

const DeckCardsPreview = ({ cards, isTextView, section }) => {
  const formContext = useFormContext();

  const { control, getValues } = formContext || {};

  const cardInfo = getValues('cardInfo') || {};

  const memoCards = useMemo(() => {
    return cards?.map(card => {
      return (
        <Controller
          key={card.cardId}
          control={control}
          name={`sections.${card.sectionIndex}.cards.${card.cardIndex}`}
          render={({ field }) => {
            const cardValue = field.value;
            return isTextView ? (
              <TextView card={cardValue} cardInfo={cardInfo} />
            ) : (
              <StackView cardValue={cardValue} cardInfo={cardInfo} />
            );
          }}
        />
      );
    });
  }, [section?.cards, isTextView]);

  return memoCards;
};
export default DeckCardsPreview;
