import { find } from 'lodash';

export const getPrice = (prices, currencies, selectedCurrency) => {
  const selectedPrice = find(
    prices,
    price => price.currencyId === selectedCurrency
  );
  const currency = currencies
    ? find(currencies, cr => cr.id === selectedCurrency)
    : null;
  if (selectedPrice) {
    return {
      amount: selectedPrice.price,
      symbol: currency ? currency.symbol : 'points',
      productPrice: selectedPrice
    };
  }

  return { amount: undefined, symbol: undefined, productPrice: undefined };
};
