export const dropDownContainerStyle = {
  padding: 1,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  background: 'transparent',
  [':not(.disable-hover)']: {
    opacity: { xs: 1, sm: 0 },
    ['.more-options']: {
      visibility: 'hidden'
    },
    [':hover, &.open']: {
      background: '#0006',
      transition: 'opacity 0.3s',
      opacity: 1,
      ['.more-options']: {
        visibility: 'visible'
      }
    }
  }
};
