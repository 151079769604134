import { Box, Chip, Grid, Typography, styled } from '@mui/material';

import React from 'react';
import StoreCurrency from '../../../../components/Shared/StoreCurrency';
import { StyledVariantDisplayWrapper } from '../../../Cart/CartItem/styles';
import { get } from 'lodash';
import { getVariantsArrayFromInventory } from '../../../../utils/cart';
import testImage from '../../../../assets/test.png';

// eslint-disable-next-line no-unused-vars
const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '70%'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: '60%',
      margin: '0 auto'
    }
  }
}));

const OrderItemSingle = ({ orderItem }) => {
  const variants = getVariantsArrayFromInventory(
    get(orderItem, 'product.inventory')
  );
  const getTotalPrice = () => {
    const price = get(orderItem, 'product.inventory.productPrice.price', 0);
    const quantity = get(orderItem, 'quantity', 1);
    const totalPrice = price * quantity;

    const fiatPrice = get(
      orderItem,
      'product.inventory.productPrice.fiatPrice',
      {}
    );
    const totalResponse = {
      amount: totalPrice,
      symbol: get(orderItem, 'currency.symbol')
    };
    if (fiatPrice && fiatPrice.amount) {
      totalResponse.fiatPrice = {
        amount: fiatPrice.amount * quantity,
        symbol: '$',
        currency: 'usd'
      };
    }
    return totalResponse;
  };
  return (
    <Grid item xs={12} padding="8px 0" className="order-details-single-item">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <StyledImageContainer>
            <img
              src={get(
                orderItem,
                'product.inventory.resourceImages.0.image.url',
                testImage
              )}
            />
          </StyledImageContainer>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1Bold">
                {get(orderItem, 'product.name')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6} display="flex" alignItems="center">
                  <Typography>
                    Quantity: {get(orderItem, 'quantity')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <StyledVariantDisplayWrapper>
                    {variants && variants.length
                      ? variants.map(variant => (
                          <Chip
                            key={`variant_${variant.variantName}_${variant.variantValue}`}
                            label={`${variant.variantName}: ${variant.variantValue}`}
                            color="primary"
                          />
                        ))
                      : null}
                  </StyledVariantDisplayWrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                padding={{ xs: 0, sm: '10px 0' }}
                order={{ xs: 1, sm: 2 }}
                minWidth={{ xs: 'initial', sm: '200px' }}
              >
                <StoreCurrency
                  variant="h4"
                  color="primary"
                  marginBottom="8px"
                  showInSingleLine
                  {...getTotalPrice()}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderItemSingle;
