import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

export function useIsLoggedIn() {
  const { isAuthenticated } = useAuth0();
  const { user } = useSelector(({ app }) => app);
  const isLoggedIn = isAuthenticated && !!get(user, 'details', null);

  return isLoggedIn;
}
