import { Box, IconButton, styled } from '@mui/material';

export const ItemCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '-7.5px',
  right: 0,
  height: '25px',
  width: '25px',
  backgroundColor: theme.colors.header.textColor,
  borderRadius: theme.border.borderRadius
}));

export const CustomIcon = styled(IconButton)(({ theme }) => ({
  border: `2px solid ${theme.colors.header.textColor}`,
  padding: theme.spacing(2),
  position: 'relative'
}));
