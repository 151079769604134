import React, { useContext, useEffect, useState } from 'react';

import AddOrganizer from '../AddOrganizer/index';
import { ModalContext } from '../../../../../../../components/Modal/Context/ModalContext';
import SimpleModalCard from '../../../../../../../components/Modal/Cards/SimpleModalCard';
import { toast } from 'react-toastify';
import { usePostUpdateEntityUsersMutation } from '../../../../../../../services/apis/organizePlayApi';

const AddOrganizerModal = ({ storeData, gameId }) => {
  const { closeModal } = useContext(ModalContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAddingUsers, setIsAddingUsers] = useState(false);
  const [
    addUsersToEntity,
    addUsersToEntityResult
  ] = usePostUpdateEntityUsersMutation();

  useEffect(() => {
    setIsAddingUsers(addUsersToEntityResult.isLoading);
    if (addUsersToEntityResult.isSuccess) {
      toast.success('Organizers added successfully!');
      closeModal();
      setSelectedUsers([]);
    }
    if (addUsersToEntityResult.isError) {
      toast.error('Failed to add organizers!');
    }
  }, [addUsersToEntityResult]);

  const onCloseClick = () => {
    closeModal();
  };
  const onAddClick = () => {
    addUsersToEntity({
      storeId: storeData._id,
      gameId,
      body: {
        userIds: selectedUsers,
        operation: 'add'
      }
    });
  };
  const onUserSelect = userId => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };
  return (
    <SimpleModalCard
      title="Add Organizers"
      showClose
      onClose={onCloseClick}
      confirmButton={{
        id: 'add-organizer-confirm-button',
        title: 'Add',
        disabled: isAddingUsers || !selectedUsers.length,
        onClick: onAddClick
      }}
      cancelButton={{
        id: 'add-organizer-cancel-button',
        title: 'Close',
        disabled: isAddingUsers
      }}
    >
      <AddOrganizer
        onSelect={onUserSelect}
        storeData={storeData}
        gameId={gameId}
      />
    </SimpleModalCard>
  );
};

export default AddOrganizerModal;
