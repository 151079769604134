import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { get, kebabCase } from 'lodash';

import GeneralContainer from '../../../Shared/Cards/GeneralCard';
import React from 'react';
import { buildBannerUrl } from '../../../../utils/StringUtil';
import { displayableDate } from '../../../../utils/date';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const RankingsFormatCard = ({ format, index, onClick, selected = false }) => {
  const { game } = useSelector(({ app }) => app);
  const totalEventBanners = get(game, 'stats.totalEventBanners');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      id={`rankings-format-${kebabCase(format.name)}-card-button`}
      data-testid={`rankings-format-${kebabCase(format.name)}-card-button`}
      container
      justifyContent={'center'}
      sx={{
        padding: '10px 10px'
      }}
    >
      <Grid item xs={12}>
        <GeneralContainer
          paperStyle={{
            overflow: 'hidden',
            boxShadow: `${
              selected ? theme.palette.primary.main : 'transparent'
            } 0px 0px 16px 4px`
          }}
          onClick={onClick && onClick}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ position: 'relative', paddingBottom: '48%' }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%'
                }}
              >
                <img
                  src={get(
                    format,
                    'bannerImageUrl',
                    buildBannerUrl(game.slug, (index % totalEventBanners) + 1)
                  )}
                  width={'100%'}
                  height={'100%'}
                  style={{ objectFit: 'cover' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ padding: '16px 20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Grid>
                  <Typography
                    variant={format.startDate || isSmall ? 'h4' : 'h3'}
                  >
                    {format.name}
                  </Typography>
                  {format.startDate && (
                    <Typography variant="body3">
                      {displayableDate(format.startDate)}
                      {'  '}to{'  '}
                      {displayableDate(format.endDate)}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </GeneralContainer>
      </Grid>
    </Grid>
  );
};

export default RankingsFormatCard;
