import { Chip, Grid, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { find, findIndex } from 'lodash';

import AvailableUserItem from './AvailableUserItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../../../../components/Loading/Loader';
import { LoadingButton } from '@mui/lab';
import { StyledTextField } from '../../../../../../../components/Shared/TextFields/index';
import { getUserFullName } from '../../../../../../../utils/user';
import { isValidEmail } from '../../../../../../../utils/StringUtil';
import { useGetEntityAvailableUsersQuery } from '../../../../../../../services/apis/organizePlayApi';
import { usePagination } from '../../../../../../../hooks/pagination/usePagination';

const StyledSearchUserTextField = styled(StyledTextField)(() => ({
  '& .MuiInputBase-input': {
    padding: '10px 16px',
    fontSize: '1.75rem'
  }
}));

const StyledUserSearchListGrid = styled(Grid)(() => ({
  height: '200px',
  maxHeight: '200px',
  overflowY: 'auto'
}));

const StyledSelectedUsersGrid = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  minHeight: '80px'
}));

const AddOrganizer = ({ onSelect, storeData, gameId }) => {
  const theme = useTheme();
  const isXs = theme.breakpoints.down('xs');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [query, setQuery] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setQuery({
      gameId,
      storeId: storeData?._id,
      page: currentPage,
      limit: 10,
      searchText
    });
  }, [currentPage, searchText]);

  const {
    data: availableUsers,
    isLoading,
    isFetching
  } = useGetEntityAvailableUsersQuery(query, {
    skip: !query.gameId || !query.storeId || !query.searchText
  });
  const isSearching = isLoading || isFetching;
  const {
    loadAction,
    treatedData,
    nextPage,
    resetPagination,
    totalResults
  } = usePagination({
    data: availableUsers,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });
  const onUserSelect = user => {
    if (find(selectedUsers, ({ _id }) => _id === user._id)) {
      setSelectedUsers(selectedUsers.filter(({ _id }) => _id !== user._id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    onSelect(user._id);
  };
  const onSearchClicked = () => {
    resetPagination();
    setSearchText(inputText);
  };
  const isValidInput = () => {
    return inputText && inputText !== searchText && isValidEmail(inputText);
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter' && isValidInput()) {
      onSearchClicked();
    }
  };
  const isUserSelected = userId => {
    return findIndex(selectedUsers, ({ _id }) => _id === userId) >= 0;
  };
  return (
    <Grid container>
      <Grid item xs={12} mt={1} padding="16px 8px">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <StyledSearchUserTextField
              placeholder="Search by email"
              name="email_or_name"
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </Grid>
          <Grid item xs={12} sm={3} textAlign={{ xs: 'center' }}>
            <LoadingButton
              fullWidth={!isXs}
              loading={isSearching}
              disabled={isSearching || !isValidInput()}
              onClick={onSearchClicked}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      {treatedData?.length > 0 ? (
        <StyledUserSearchListGrid
          item
          xs={12}
          padding="16px 8px"
          id="userAvailableListScrollableTarget"
        >
          <InfiniteScroll
            dataLength={totalResults}
            next={loadAction}
            hasMore={nextPage !== 0 && totalResults !== treatedData?.length}
            loader={<Loading />}
            scrollableTarget="userAvailableListScrollableTarget"
            scrollThreshold={0.9}
          >
            {treatedData?.map(user => (
              <AvailableUserItem
                user={user}
                onSelect={onUserSelect}
                isSelected={isUserSelected(user._id)}
                key={user._id}
              />
            ))}
          </InfiniteScroll>
        </StyledUserSearchListGrid>
      ) : null}
      {!treatedData ||
        (!treatedData.length && searchText && (
          <Grid item xs={12} textAlign="center">
            <Typography>No users found!</Typography>
          </Grid>
        ))}

      {selectedUsers.length > 0 ? (
        <StyledSelectedUsersGrid item xs={12} padding="8px">
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="start">
              <Typography variant="body3Light">Selected Users</Typography>
            </Grid>
            <Grid item xs={12}>
              {selectedUsers.map(user => (
                <Chip
                  sx={{ marginRight: '8px', marginBottom: '8px' }}
                  key={user._id}
                  label={getUserFullName(user)}
                  onDelete={() => onUserSelect(user)}
                />
              ))}
            </Grid>
          </Grid>
        </StyledSelectedUsersGrid>
      ) : null}
    </Grid>
  );
};

export default AddOrganizer;
