import Joi from 'joi';

export const userNameSchema = {
  firstName: Joi.string()
    .trim()
    .min(1)
    .required(),
  lastName: Joi.string()
    .trim()
    .allow('')
};

export const updateUserSchema = Joi.object({
  profile: Joi.object({
    ...userNameSchema,
    nickname: Joi.string().allow(''),
    email: Joi.string()
  }),
  location: Joi.object(),
  teamName: Joi.string().allow('')
}).required();

export const updateUserSchemaFirstTime = Joi.object({
  profile: Joi.object({
    ...userNameSchema
  }).required(),
  location: Joi.alternatives().conditional('isOptedOut', {
    is: false,
    then: Joi.object({
      address1: Joi.string().required(),
      address2: Joi.string().allow(''),
      city: Joi.string().required(),
      state: Joi.string().required(),
      zip: Joi.string().required(),
      country: Joi.string().required(),
      isOptedOut: Joi.boolean().allow()
    }).required(),
    otherwise: Joi.object()
  }),
  isOptedOut: Joi.boolean()
});
