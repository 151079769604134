import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import { CardListMainContainer, SelectionContainer } from './styles';
import { capitalize, isEmpty, kebabCase, toLower } from 'lodash';

import CardPopover from '../../CardPopover';
import { Controller } from 'react-hook-form';
import React from 'react';
import { createDeckNamePath } from '../../../../utils/deck';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';

const CardListSelections = ({
  type = null,
  card,
  formContext = {},
  outletContext = {}
}) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  if (isEmpty(formContext)) {
    return <></>;
  }

  const { control, getValues, setValue } = formContext;

  const addCardAction = (sectionIndex, sectionName) => {
    const deck = getValues(`sections.${sectionIndex}`);
    if (!deck?.cardTracker?.has(card?.id)) {
      deck?.cards?.push({
        cardId: card?.id,
        count: 1
      });
      const cardInfo = getValues('cardInfo');
      cardInfo[card?.id] = card;
      deck?.cardTracker?.add(card?.id);

      setValue(`sections.${sectionIndex}`, deck);
    } else {
      const index = deck?.cards?.findIndex(
        currentCard => currentCard?.cardId === card?.id
      );
      if (index >= 0) {
        deck.cards[index].count += 1;
      }
      setValue(`sections.${sectionIndex}.cards.${index}`, {
        ...deck.cards[index]
      });
    }
    if (isMedium) {
      toast.dismiss();
      toast.success(`${card?.name} added to ${sectionName}`);
    }

    outletContext?.checkDeckValidationAction();
  };

  return (
    <CardListMainContainer className={'card-list-main-container'}>
      <SelectionContainer className={'remove-scrollbar'}>
        <List>
          <Controller
            name={'sections'}
            control={control}
            render={({ field }) =>
              field?.value?.map((section, sectionIndex) => {
                const sectionName = toLower(
                  getValues(createDeckNamePath(section?.deckSectionId))
                )
                  .split(' ')
                  .map(word => capitalize(word))
                  .join(' ');

                return (
                  <ListItemButton
                    id={
                      type == 'deckBuilder'
                        ? `create-deck-full-card-${kebabCase(
                            sectionName
                          )}-add-button`
                        : null
                    }
                    data-testid={
                      type == 'deckBuilder'
                        ? `create-deck-full-card-${kebabCase(
                            sectionName
                          )}-add-button`
                        : null
                    }
                    key={section?.deckSectionId}
                    {...field}
                    onClick={() => addCardAction(sectionIndex, sectionName)}
                  >
                    <ListItemText
                      sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem' } }}
                      disableTypography
                    >
                      Add to {sectionName}
                    </ListItemText>
                  </ListItemButton>
                );
              })
            }
          />

          {/** TODO: Add preview to this card. **/}
          {/*<Divider sx={{ marginX: 1, background: 'white' }} />*/}
          {/*<ListItemButton>*/}
          {/*  <ListItemText>Preview</ListItemText>*/}
          {/*</ListItemButton>*/}
        </List>
      </SelectionContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: { xs: 0.5, md: 1 }
        }}
      >
        <CardPopover
          type={type ? type : null}
          card={card}
          sx={{ fontSize: { xs: '1.0rem', sm: '1.5rem' } }}
        />
      </Box>
    </CardListMainContainer>
  );
};

export default CardListSelections;
