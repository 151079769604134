import { Button, Grid, Typography } from '@mui/material';

import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const Community = () => {
  const { game } = useSelector(({ app }) => app);

  const backgroundImage = get(game, 'layout.homepage.community.bgImage', '');
  const subTitle =
    get(game, 'layout.homepage.community.subtitle', '') ||
    get(game, 'layout.homepage.header.subtitle', '');
  return (
    <MaxWidthContainer
      id="landing-page-community-container"
      data-testid="landing-page-community-container"
      container
      justifyContent={'center'}
      className={'p-percent'}
      sx={{ mb: 12, mt: 12 }}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        mt={{ xs: 2, md: 8 }}
        sx={{
          backgroundImage: backgroundImage && `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: backgroundImage ? '800px' : '500px',
          maxWidth: backgroundImage ? '800px' : '500px'
        }}
      >
        <Grid item>
          <Grid
            container
            justifyContent={'center'}
            spacing={2}
            sx={{ maxWidth: '350px' }}
          >
            <Grid xs={12} item>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id="join-community-button"
                data-testid="join-community-button"
                onClick={() =>
                  window.open(
                    get(game, 'layout.footer.socials.discord'),
                    '_blank'
                  )
                }
                variant="contained"
              >
                Join Our Community
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default Community;
