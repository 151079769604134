import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { fetchUserData, startAuthentication } from '../../store/app/actions';
import UserDetailsModal from './UserDetailsModal';
import { setAuthToken } from '../../store/auth/dispatch';
import { useLogout } from '../../hooks/auth0/useLogout';
import { useUserWallets } from '../../hooks/user/useUserWallets';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;

const AuthManager = () => {
  const dispatch = useDispatch();
  const [accessToken, setAccessToken] = useState('');
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { game, user, isAuthorizing } = useSelector(({ app }) => app);
  const { logoutAction } = useLogout();
  const [isWalletInitialized, setIsWalletInitialized] = useState(false);
  const userWallets = useUserWallets();
  const { rewardStore } = useFlags();

  useEffect(() => {
    if (isAuthenticated && game.id && !user.details) {
      dispatch(startAuthentication());
      getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: 'read:current_user'
      }).then(token => {
        setAccessToken(token);
        dispatch(setAuthToken(token));
        dispatch(fetchUserData(token, game.id, logoutAction));
      });
    }
  }, [isAuthenticated, game]);

  useEffect(() => {
    if (!isAuthorizing && !isWalletInitialized && user && game && rewardStore) {
      setIsWalletInitialized(true);
      userWallets.update();
    }
  }, [isAuthorizing, isWalletInitialized, setIsWalletInitialized, user, game]);

  useEffect(() => {
    if (
      (isAuthenticated &&
        game?.id &&
        user?.details &&
        !get(user, 'details.info.firstName') &&
        !get(user, 'details.info.lastName')) ||
      (isAuthenticated &&
        game?.id &&
        user?.details &&
        !get(user, 'details.location.isOptedOut') &&
        !get(user, 'details.location.address1'))
    ) {
      setIsUserDetailsModalOpen(true);
    }
  }, [isAuthenticated, game, user]);

  axios.interceptors.request.use(
    config => {
      if (isAuthenticated) {
        if (!accessToken) {
          return getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: 'read:current_user'
          }).then(token => {
            return {
              ...config,
              headers: {
                ...config.headers,
                common: {
                  ...config.headers.common,
                  Authorization: `Bearer ${token}`
                }
              }
            };
          });
        } else {
          return {
            ...config,
            headers: {
              ...config.headers,
              common: {
                ...config.headers.common,
                Authorization: `Bearer ${accessToken}`
              }
            }
          };
        }
      } else {
        return config;
      }
    },
    error => {
      console.log(error);
    }
  );

  return (
    <>
      <UserDetailsModal
        isOpen={isUserDetailsModalOpen}
        handleClose={() => setIsUserDetailsModalOpen(false)}
      />
    </>
  );
};

export default AuthManager;
