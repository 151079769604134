import { Box, Button, useMediaQuery } from '@mui/material';
import {
  desktopHeaderContainerStyle,
  mobileHeaderContainerStyle
} from './styles';

import ConditionalWrapper from '../../Shared/ConditionalWrapper';
import React from 'react';
import StyleIcon from '@mui/icons-material/Style';
import TuneIcon from '@mui/icons-material/Tune';
import { useTheme } from '@mui/material/styles';

const DeckCreateSectionButtons = ({
  deckProps,
  filterProps,
  isSmall,
  section,
  hideFilter
}) => {
  const theme = useTheme();
  const betweenMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      id="create-deck-header-button-container"
      data-testid="create-deck-header-button-container"
      sx={isSmall ? mobileHeaderContainerStyle : desktopHeaderContainerStyle}
    >
      <div className={'deck-page-selector'}>{section}</div>
      <ConditionalWrapper
        condition={!isSmall}
        wrapper={children => <div className={'filter-buttons'}>{children}</div>}
      >
        {hideFilter && (
          <Button
            id="create-deck-filter-button"
            data-testid="create-deck-filter-button"
            className={'textfield filter-button'}
            onClick={() =>
              filterProps?.filterOpenProps[1](prevState => !prevState)
            }
            variant={'outlined'}
          >
            {isSmall && 'Filters'}
            <TuneIcon fontSize={'inherit'} color={'primary'} />
          </Button>
        )}
        {(isSmall || betweenMedium) && (
          <Button
            id="create-deck-side-view-button"
            data-testid="create-deck-side-view-button"
            className={'textfield deck-button'}
            variant={'outlined'}
            onClick={() => deckProps?.deckOpenProps[1](prevState => !prevState)}
          >
            <StyleIcon fontSize={'inherit'} />
          </Button>
        )}
      </ConditionalWrapper>
    </Box>
  );
};

export default DeckCreateSectionButtons;
