import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { GooglePlaceTextField } from '../../Shared/GooglePlaceTextField';
import { get } from 'lodash';
import { defaultUserInfo } from '../../../values/user-values';
import { useSelector } from 'react-redux';
import { buttonStylesContainer } from './styles';
import { useOutletContext } from 'react-router-dom';
import JudgeSection from '../../../pages/User/ProfilePage/Judge';
import { PaperContainer } from '../../Shared/Paper';

const UserInfo = () => {
  const { user } = useSelector(({ app }) => app);
  const { control, reset } = useFormContext() || {};
  const context = useOutletContext();
  const [isEditing, setIsEditing] = context?.editUseState || [];

  const userInfo = {
    profile: {
      firstName: get(user, 'details.info.firstName', ''),
      lastName: get(user, 'details.info.lastName', ''),
      nickname: get(user, 'details.info.nickname', ''),
      email: get(user, 'details.info.email', '')
    },
    location: {
      ...get(user, 'details.location', defaultUserInfo.location),
      name: ''
    },
    teamName: get(user, 'details.gameUser.teamName', '')
  };

  const LabelTypography = props => (
    <Typography gutterBottom paddingLeft={1}>
      {props.children}
    </Typography>
  );

  const handleKeys = key => {
    switch (key) {
      case 'firstName':
        return 'First Name';
      case 'lastName':
        return 'Last Name';
      case 'email':
        return 'Email';
      case 'nickname':
        return 'Nickname';
      // case 'username':
      //   return 'User Name';
      default:
        return 'N/A';
    }
  };

  const inputOptions = {
    email: { disabled: true }
  };

  // eslint-disable-next-line no-unused-vars
  const gridBreakpoints = {
    xs: 12,
    md: 6
  };

  const handleEdit = e => {
    setIsEditing(true);
    e.preventDefault();
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset({ ...context.userInfo });
  };

  return (
    <>
      <Grid item xs={12}>
        <PaperContainer className={'user-profile'} elevation={0}>
          <Grid container spacing={4} className={'container'}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {Object.keys(userInfo.profile).map(key => {
                  return (
                    <Grid item key={key} {...gridBreakpoints}>
                      <Controller
                        name={`profile.${key}`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <LabelTypography>{handleKeys(key)}</LabelTypography>
                            <TextField
                              {...field}
                              disabled={
                                !isEditing ||
                                get(inputOptions, `${key}.disabled`, false)
                              }
                              InputLabelProps={{ shrink: false }}
                              inputProps={
                                isEditing && key !== 'email'
                                  ? {
                                      readOnly: false
                                    }
                                  : {
                                      readOnly: true
                                    }
                              }
                            />
                          </>
                        )}
                      />
                    </Grid>
                  );
                })}
                <Grid item {...gridBreakpoints}>
                  <Controller
                    name="teamName"
                    control={control}
                    render={({ field }) => (
                      <>
                        <LabelTypography>Team Name</LabelTypography>
                        <TextField
                          {...field}
                          disabled={!isEditing}
                          inputProps={
                            isEditing
                              ? {
                                  readOnly: false
                                }
                              : {
                                  readOnly: true
                                }
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item {...gridBreakpoints}>
                  <Controller
                    name={'location'}
                    control={control}
                    render={({ field }) => (
                      <>
                        <LabelTypography>Address</LabelTypography>
                        <GooglePlaceTextField
                          {...field}
                          hideLabel
                          disabled={!isEditing}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item {...gridBreakpoints}>
                  <Controller
                    name={'location'}
                    control={control}
                    render={({ field }) => (
                      <>
                        <LabelTypography>Address 2</LabelTypography>
                        <TextField
                          {...field}
                          value={field.value.address2}
                          onChange={e => {
                            field.onChange({
                              ...field.value,
                              address2: e.target.value
                            });
                          }}
                          disabled={!isEditing}
                        />
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Buttons */}
            <Grid item xs={12}>
              <Box sx={buttonStylesContainer}>
                <Button
                  type={isEditing ? 'submit' : 'button'}
                  fullWidth
                  variant={'contained'}
                  onClick={!isEditing ? handleEdit : null}
                >
                  {isEditing ? 'Save' : 'Edit'}
                </Button>
                <Button
                  disabled={!isEditing}
                  fullWidth
                  variant={'contained'}
                  onClick={() => handleCancel()}
                  data-testid="my-profile-cancel-button"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </PaperContainer>
      </Grid>

      {get(user, 'permissions.judge.hasAccess', false) && (
        <Grid item xs={12}>
          <PaperContainer className={'user-profile'} elevation={0}>
            <JudgeSection />
          </PaperContainer>
        </Grid>
      )}
    </>
  );
};

export default UserInfo;
