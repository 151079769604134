import { merge, sortBy } from 'lodash';

export const defaultStoreTimings = [
  {
    day: 'monday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'tuesday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'wednesday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'thursday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'friday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'saturday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  },
  {
    day: 'sunday',
    openAt: 9,
    closeAt: 17,
    isOpen: false
  }
];

export const defaultSocialLinks = [
  {
    name: 'instagram',
    url: '',
    order: 1
  },
  {
    name: 'twitter',
    url: '',
    order: 2
  },
  {
    name: 'discord',
    url: '',
    order: 3
  },
  {
    name: 'youtube',
    url: '',
    order: 4
  },
  {
    name: 'linkedin',
    url: '',
    order: 5
  }
];

const openCloseHours = new Array(24).fill(1).map((_, i) => {
  return i;
});

export const getOpenCloseHoursOptions = () => {
  return openCloseHours.map(hour => {
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'am' : 'pm';
    return {
      _id: hour,
      name: `${h}${ampm}`
    };
  });
};

export const getMergedTimings = storeTimings => {
  return merge(defaultStoreTimings, storeTimings || []);
};

export const getMergedSocials = storeSocials => {
  return sortBy(merge(defaultSocialLinks, storeSocials || []), 'order');
};
