import { Button, Grid, Tabs, Typography, useMediaQuery } from '@mui/material';
import {
  EventImage,
  EventInformationContainer,
  EventTab,
  GrayBannerBackground,
  GrayPillBackground
} from './style';
import { Link, useNavigate, useParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { eventStatuses, playerStatus } from '../../../values/event-values';
import {
  useGetSingleEventPairingsQuery,
  useGetSingleEventQuery,
  useGetSingleEventRosterQuery,
  usePostAddParticipantToEventMutation
} from '../../../services/apis/organizePlayApi';

import { AddDeckToEventModal } from './ModalPages/AddDeckToEvent';
import AddParticipants from './ModalPages/AddParticipants';
import BusinessIcon from '@mui/icons-material/Business';
import BuyEventTicket from './ModalPages/BuyEventTicket/index';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { EventStatusChip } from '../EventDetailStatus/index';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { LoadingButton } from '@mui/lab';
import { ManageEventJudges } from './ModalPages/ManageEventJudges';
import { ModalContext } from '../../Modal/Context/ModalContext';
import MoreOptionsMenu from '../Shared/MoreOptionsMenu';
import OverflowTextfield from '../../Shared/TextFields/Overflow';
import StartingRounds from './ModalPages/StartingRounds';
import UpdateRegisteredPlayer from '../../../components/Events/Shared/ModalPages/UpdateRegisteredPlayer';
import { buildRoutePath } from '../../../utils/routingUtils';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { singleEventInfo } from '../../../utils/EventUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const SingleEventHeader = () => {
  const { addModal } = useContext(ModalContext);
  const { user } = useSelector(({ app }) => app);
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { eventId } = params;

  const {
    data: activeEvent,
    error,
    isLoading,
    isFetching
  } = useGetSingleEventQuery(eventId);

  const [addPlayer, addPlayerResult] = usePostAddParticipantToEventMutation();

  const {
    capacity,
    participants,
    currentRound,
    name,
    imageUrl,
    entryFee,
    currency,
    date,
    canJoin,
    startDate,
    signupDate,
    isOrganizer,
    organization,
    regularRounds,
    totalRounds,
    status,
    tournament,
    isDeckRegistered,
    canModifyDeck,
    entityId,
    ticketPricing,
    payoutEnabled,
    previouslyJoined
  } = singleEventInfo(activeEvent);
  const playType = get(tournament, 'playType', 'tournament');
  const { ticketing } = useFlags();

  const { data: rosterData } = useGetSingleEventRosterQuery({ eventId });

  const players = get(rosterData, 'data.participants', []);

  const confirmedPlayers = players.filter(
    player => get(player, 'status', '') === playerStatus.active.key
  );

  const { data: pairingsData } = useGetSingleEventPairingsQuery({
    eventId,
    roundNumber: currentRound
  });

  // Open the modal and set the selected participant
  const handleOpen = () => {
    addModal({
      children: (
        <UpdateRegisteredPlayer
          title={'Are you sure you want to Unregister?'}
          type={'selfDrop'}
          user={user}
        />
      )
    });
  };

  const handleAddDeckOpen = () => {
    addModal({
      children: <AddDeckToEventModal user={user} activeEvent={activeEvent} />
    });
  };

  const pairingsActive = get(pairingsData, 'pairings', []).filter(pairing =>
    get(pairing, 'active', true)
  );

  const startedAndNotComplete = status === 'active' || status === 'playoffs';

  const [currentTab, setCurrentTab] = useState('About');
  const hasStarted = status !== 'registration';
  const canManage =
    isOrganizer &&
    status !== eventStatuses.complete.key &&
    currentRound <= regularRounds &&
    totalRounds > currentRound;
  const disableParticipantsButton =
    participants >= capacity ||
    (hasStarted &&
      (currentRound >= regularRounds ||
        tournament.format === 'single-elimination'));

  const tabs = [
    { title: 'About', show: true },
    { title: 'Roster', show: true },
    { title: 'Placings', show: playType === 'tournament' && hasStarted },
    { title: 'Pairings', show: playType === 'tournament' && hasStarted },
    { title: 'Edit', show: canManage }
  ].filter(obj => obj.show);

  useEffect(() => {
    let tab = tabs.find(obj => obj.title.toLowerCase() === params['*']);
    tab = tab ? tab.title : tabs[0].title;
    setCurrentTab(tab);
  }, [params['*'], tabs]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const doesNeedToBuyTicket = () => {
    return (
      ticketing &&
      ticketPricing &&
      ticketPricing.ticketFee > 0 &&
      payoutEnabled &&
      !previouslyJoined
    );
  };
  // used to join event if you are not the organizer
  const handleJoinNowButton = () => {
    if (doesNeedToBuyTicket()) {
      addModal({
        children: <BuyEventTicket user={user} activeEvent={activeEvent} />
      });
    } else {
      const body = {
        userId: user.details.id
      };
      addPlayer({ body, eventId });
    }
  };

  const handleMainClick = () => {
    addModal({
      children: <StartingRounds />,
      isOpen: true
    });
  };

  const handleManageJudgesClick = () => {
    addModal({
      children: <ManageEventJudges />,
      isOpen: true
    });
  };

  const getMainText = () => {
    if (!hasStarted) {
      return 'Start Event';
    } else {
      if (
        currentRound === totalRounds ||
        (playType === 'meetup' && hasStarted)
      ) {
        return 'Complete Event';
      }

      if (currentRound === totalRounds - 1) {
        return 'Advance to Final Round';
      }

      if (currentRound === get(activeEvent, 'tournament.rounds', 0)) {
        return 'Advance to Top Cut';
      }

      if (hasStarted) {
        return 'Next Round';
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getStatusText = () => {
    // Return current event status
    switch (status) {
      case eventStatuses.complete.key:
        return eventStatuses.complete.title;
      case eventStatuses.started.key:
        return 'Event Started';
      default:
        break;
    }

    const currentTime = dayjs();
    // Return delayed registration message
    if (startDate && signupDate && dayjs(signupDate).isAfter(currentTime)) {
      return (
        <span>
          Registration Open <br />
          {dayjs(signupDate).format('MMM D YYYY h:mm a')}
        </span>
      );
    }

    // Display registered if authenticated user found in the roster.
    const player = players.find(obj => obj.userId === get(user, 'details.id'));
    if (isAuthenticated && player) {
      return playerStatus.registered.title;
    } else if (participants >= capacity) {
      return 'Event Full';
    }

    return '';
  };

  const noInfo = 'N/A';

  const getDeckActionItemText = () => {
    if (isDeckRegistered) {
      return 'Manage Deck List';
    }
    return 'Add Deck List';
  };

  const getJoinEventText = () => {
    if (doesNeedToBuyTicket()) {
      return 'Buy Ticket';
    }
    return 'Join Now';
  };

  return (
    <GrayBannerBackground
      id="event-page-container"
      data-testid="event-page-container"
      container
      item
      xs={12}
      gap={2}
      mt={'10%'}
    >
      {/* Tournament Image Section */}
      <Grid item container xs={12} position="relative">
        <EventImage
          style={{
            backgroundImage: `url(${imageUrl})`
          }}
        />
        <EventStatusChip eventStatus={status} />
      </Grid>

      {/* Information Section */}
      <EventInformationContainer container gap={{ xs: 2 }}>
        <Grid
          container
          item
          xs={12}
          gap={{ xs: 4, md: 2 }}
          wrap={'nowrap'}
          direction={{ xs: 'column', md: 'row' }}
        >
          {/* Left Section */}
          <Grid container item gap={2} xs={12} md={8}>
            {/* Events Title */}
            <Grid item xs={12}>
              <OverflowTextfield
                title={name || noInfo}
                variant={isSmall ? 'h3' : 'h1'}
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'unset'
                }}
              />
              {/*<EventTitle variant={isSmall ? 'h3' : 'h1'}>*/}
              {/*  {name || noInfo}*/}
              {/*</EventTitle>*/}
            </Grid>
            <Grid item xs={12} mt={'20px'}>
              {/* Events Date */}
              <Grid container spacing={'10px'}>
                <Grid item>
                  <GrayPillBackground container>
                    <Grid item>
                      <CalendarTodayIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{date}</Typography>
                    </Grid>
                  </GrayPillBackground>
                </Grid>
                {/* Events Location */}
                <Grid item>
                  <GrayPillBackground
                    container
                    sx={{
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    }}
                  >
                    <Grid item>
                      <BusinessIcon />
                    </Grid>
                    <Grid item>
                      <Link
                        target="_blank"
                        to={buildRoutePath(
                          params.gameSlug,
                          `/stores/${entityId}`
                        )}
                        style={{ textDecoration: 'none', color: 'unset' }}
                      >
                        <Typography variant="body1">{organization}</Typography>
                      </Link>
                    </Grid>
                  </GrayPillBackground>
                </Grid>
                {/* Participants */}
                <Grid item>
                  <GrayPillBackground container>
                    <Grid item>
                      <GroupAddIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {participants} / {capacity} Capacity
                      </Typography>
                    </Grid>
                  </GrayPillBackground>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={4}>
            <Grid
              container
              justifyContent={{ md: 'flex-start', xs: 'center' }}
              alignItems={'center'}
              height="100%"
              gap={2}
            >
              <Grid
                sx={{
                  display: 'flex'
                }}
                item
                alignItems={'center'}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                xs={12}
                alignSelf="flex-start"
              >
                {/* Events Pricing */}
                <Grid xs={12} item md={'auto'}>
                  <Typography variant="h1" textAlign={'center'}>
                    {`${currency} ${entryFee.toUpperCase()}`}
                  </Typography>
                </Grid>
                {/* Add Participants Button*/}
                {isOrganizer && status !== eventStatuses.complete.key && (
                  <Grid item>
                    <Button
                      id="event-add-participants-button"
                      data-testid="event-add-participants-button"
                      disabled={disableParticipantsButton}
                      style={{ width: '45px' }}
                      variant={'contained'}
                      onClick={() => {
                        addModal({ children: <AddParticipants /> });
                      }}
                    >
                      <GroupAddIcon />
                    </Button>
                    {/*<AddParticipants />*/}
                  </Grid>
                )}
              </Grid>

              {/* Join Now or Start & add users Button */}
              <Grid
                sx={{
                  display: 'flex'
                }}
                item
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                xs={12}
                alignSelf="flex-end"
              >
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  sx={{ width: 'auto' }}
                  // justifyContent={{ xs: 'center', md: 'flex-end' }}
                  spacing={1}
                >
                  {isOrganizer && status !== 'complete' && (
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                      item
                    >
                      <Button
                        id="event-start-button"
                        data-testid="event-start-button"
                        disabled={
                          pairingsActive.length > 0 ||
                          (playType === 'tournament'
                            ? confirmedPlayers.length < 2
                            : confirmedPlayers.length < 1)
                        }
                        sx={{ maxWidth: '250px' }}
                        onClick={handleMainClick}
                        variant={'contained'}
                        item
                      >
                        <Typography variant={'h5'}>{getMainText()}</Typography>
                      </Button>
                    </Grid>
                  )}

                  {canJoin && !isOrganizer && (
                    <Grid item>
                      <LoadingButton
                        id="event-join-now-button"
                        data-testid="event-join-now-button"
                        disabled={
                          !canJoin ||
                          currentRound > 1 ||
                          addPlayerResult.isLoading ||
                          isLoading ||
                          isFetching
                        }
                        onClick={handleJoinNowButton}
                        sx={{
                          opacity: canJoin ? 1 : 0.4,
                          maxWidth: '250px'
                        }}
                        variant={'contained'}
                        loading={
                          addPlayerResult.isLoading || isLoading || isFetching
                        }
                      >
                        <Typography variant={'h5'}>
                          {getJoinEventText()}
                        </Typography>
                      </LoadingButton>
                    </Grid>
                  )}

                  {!canJoin && !isOrganizer && (
                    <Grid item>
                      <Typography item pr={1} variant={'h6'}>
                        {getStatusText()}
                      </Typography>
                    </Grid>
                  )}

                  {/* More options button */}
                  <MoreOptionsMenu>
                    {isOrganizer &&
                    status === eventStatuses.registration.key &&
                    playType === 'tournament' ? (
                      <Button
                        id="event-add-judges-button"
                        data-testid="event-add-judges-button"
                        style={{ maxWidth: '250px' }}
                        onClick={handleManageJudgesClick}
                        variant={'contained'}
                        color="success"
                      >
                        <Typography variant={'h5'}>Add Judges</Typography>
                      </Button>
                    ) : null}
                    {canJoin && isOrganizer && (
                      <Button
                        id="event-join-now-button"
                        data-testid="event-join-now-button"
                        disabled={!canJoin || currentRound > 1}
                        onClick={handleJoinNowButton}
                        sx={{
                          opacity: canJoin ? 1 : 0.4,
                          maxWidth: '250px'
                        }}
                        variant={'contained'}
                      >
                        <Typography variant={'h5'}>Join Now</Typography>
                      </Button>
                    )}
                    {getStatusText() === 'Registered' &&
                    status === eventStatuses.registration.key &&
                    playType === 'tournament' &&
                    canModifyDeck ? (
                      <Button
                        onClick={() => {
                          handleAddDeckOpen(user);
                        }}
                        item
                      >
                        <Typography variant={'h6'}>
                          {getDeckActionItemText()}
                        </Typography>
                      </Button>
                    ) : null}
                    {getStatusText() === 'Registered' &&
                      !startedAndNotComplete && (
                        <Button
                          id="event-unregister-button"
                          data-testid="event-unregister-button"
                          onClick={() => {
                            handleOpen(user);
                          }}
                          item
                        >
                          <Typography variant={'h6'}>Unregister</Typography>
                        </Button>
                      )}
                  </MoreOptionsMenu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Tabs Section */}
        <Grid xs={12} item>
          <Tabs
            variant={'scrollable'}
            allowScrollButtonsMobile
            value={currentTab}
            onChange={handleChange}
            sx={{
              '& .MuiTabs-indicator': {
                height: 6
              }
            }}
          >
            {tabs.map(obj => (
              <EventTab
                id={`event-${obj.title.toLowerCase()}-tab-button`}
                data-testid={`event-${obj.title.toLowerCase()}-tab-button`}
                key={obj.title}
                value={obj.title}
                label={obj.title}
                onClick={() => {
                  navigate(obj.title.toLowerCase(), {
                    preventScrollReset: true
                  });
                  setCurrentTab(obj.title);
                }}
              />
            ))}
          </Tabs>
        </Grid>
      </EventInformationContainer>
    </GrayBannerBackground>
  );
};

export default SingleEventHeader;
