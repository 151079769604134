import { Box, ToggleButtonGroup, styled } from '@mui/material';

export const UserBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.border.borderRadius}px`
}));

export const ToggleButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: 0
    },
    '& .MuiToggleButton-root': {
      border: 0,
      padding: '6px 10px 8px',
      fontSize: '1rem',
      lineHeight: '1rem',
      textTransform: 'lower',
      fontWeight: '500',
      '&.Mui-selected': {
        borderRadius: '16px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
      },
      '&:hover': {
        backgroundColor: 'initial'
      }
    }
  })
);
