import { Navigate, Route } from 'react-router-dom';
import StatusPage, { statusTypes } from '../Status';

import ProtectedRoute from '../../components/Route/ProtectedRoute';
import React from 'react';
import SingleStorePage from './Single';
import StoreLocatorPage from './Locator';
import StoreMainPage from './index';
import StoreManagePage from './StoreManage';

const StoreRoutes = () => {
  return (
    <Route
      path={'stores/*'}
      element={<StoreMainPage />}
      errorElement={<StatusPage type={statusTypes.error} />}
    >
      <Route index element={<Navigate to="search" replace />} />
      <Route exact path="search" element={<StoreLocatorPage />} />
      <Route
        exact
        path=":storeId/manage"
        element={
          <ProtectedRoute>
            <StoreManagePage />
          </ProtectedRoute>
        }
      />
      <Route path=":entityId" element={<SingleStorePage />} />
    </Route>
  );
};

export default StoreRoutes;
