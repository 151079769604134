import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EmptyPageLoader from '../../../components/Shared/EmptyPageLoader';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useCustomNavigation } from '../../../hooks/navigation/useCustomNavigation';
import { usePostCreatePayoutAccountLinkMutation } from '../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const PayoutRefreshPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { game } = useSelector(({ app }) => app);
  const queryParams = new URLSearchParams(location.search);
  const entityId = queryParams.get('entity');
  const organizationId = queryParams.get('organization');
  const { buildUrlPath, buildSubPath } = useCustomNavigation();
  const [
    createAccountLink,
    createAccountLinkResult
  ] = usePostCreatePayoutAccountLinkMutation();

  useEffect(() => {
    if (entityId && game) {
      createAccountLink({
        gameId: game._id,
        organization: entityId,
        body: {
          refreshUrl: buildUrlPath(
            `/payouts/refresh?entity=${entityId}&organization=${organizationId}`
          ),
          returnUrl: buildUrlPath(
            `/payouts/callback?entity=${entityId}&organization=${organizationId}`
          )
        }
      });
    }
  }, [entityId, game]);

  useEffect(() => {
    if (!createAccountLinkResult.isLoading) {
      if (createAccountLinkResult.isSuccess) {
        const redirectUrl = get(
          createAccountLinkResult,
          'data.data.metaData.accountLink.url',
          ''
        );
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          toast.error('Something went wrong, please try again later');
        }
      } else if (createAccountLinkResult.isError) {
        toast.error(
          get(
            createAccountLinkResult,
            'error.data.errorMessage',
            'Error while verifying account'
          )
        );
        navigate(buildSubPath(`/stores/${entityId}/manage`));
      }
    }
  }, [createAccountLinkResult]);

  return (
    <EmptyPageLoader
      mainText="Please wait while we generate onboarding link"
      subtitle="Please do not refresh of press back!"
    />
  );
};

export default PayoutRefreshPage;
