/* eslint-disable no-unused-vars */
import { Box, Grid, useMediaQuery } from '@mui/material';
import DeckInfoCard, { deckInfoType } from '../../Cards/DeckInfoCard';
import React, { useEffect, useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import DeckCard from '../../Cards/DeckCard';
import InfiniteScroll from '../../../InfiniteScroll';
import Loader from '../../../Loading/Loader';
import { buildRoutePath } from '../../../../utils/routingUtils';
import { useDeck } from '../../../../pages/Decks/hooks/useDeck';
import { usePagination } from '../../../../hooks/pagination/usePagination';
import { useTheme } from '@emotion/react';

const DecksView = () => {
  const params = useParams();
  const { gameSlug } = params || {};
  const currentPath = params['*'];
  const navigate = useNavigate();
  const { query } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { decksProps, createDeckProps } = useDeck({
    getMyDecks: currentPath === 'my-decks',
    query: { ...query, page: currentPage, limit: isMobile ? 5 : 10 }
  });

  const createDeckResult = createDeckProps[1];

  const {
    data: decksDataWithPagination,
    isLoading: decksLoading,
    isFetching,
    isError
  } = decksProps;

  useEffect(() => {
    if (currentPage !== 1) {
      resetPagination();
    }
  }, [currentPath, query]);

  const {
    loadAction,
    treatedData,
    nextPage,
    resetPagination,
    isSettingUp,
    totalResults
  } = usePagination({
    data: decksDataWithPagination,
    dataKey: 'data',
    setCurrentPage,
    isLoading: decksLoading,
    isFetching: isFetching
  });
  const isDecksEmpty = isEmpty(treatedData);
  const isLoading = isFetching || isSettingUp;

  const createDeckInfoCard = ({ key, type, error }) => {
    return (
      <DeckInfoCard
        key={key}
        type={type}
        error={error}
        createDeckProps={createDeckProps}
      />
    );
  };

  const createDeckViews = useMemo(() => {
    return (
      <>
        {currentPath === 'my-decks' &&
          !isDecksEmpty &&
          createDeckInfoCard({
            key: 'myDecks',
            type: deckInfoType.buildDeck.key
          })}
        {treatedData?.map(info => (
          <DeckCard
            key={info?.deck?._id}
            info={info}
            isMyDecks={currentPath === 'my-decks'}
          />
        ))}
        {isDecksEmpty && (
          <Box sx={{ gridRow: '1', gridColumn: '1 / -1' }}>
            {createDeckInfoCard({
              type:
                currentPath === 'my-decks'
                  ? deckInfoType.userEmptyDecks.key
                  : deckInfoType.communityEmptyDecks.key,
              error: isError
            })}
          </Box>
        )}
      </>
    );
  }, [treatedData]);

  useEffect(() => {
    if (!createDeckResult.isLoading && createDeckResult.isSuccess) {
      const id = createDeckResult?.data?.data?._id;
      id && navigate(buildRoutePath(gameSlug, `/decks/${id}/edit`));
    }
  }, [createDeckResult]);

  return (
    <InfiniteScroll
      isLoading={isLoading}
      done={nextPage === 0 || totalResults === treatedData?.length}
      loaderAction={loadAction}
      loader={<Loader />}
    >
      {!isSettingUp && (
        <>
          <Box
            id="decks-view-section"
            data-testid="decks-view-section"
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
              gridAutoRows: '1fr',
              gap: 4
            }}
          >
            {createDeckViews}
          </Box>
        </>
      )}
    </InfiniteScroll>
  );
};
export default DecksView;
