import { Paper, styled } from '@mui/material';

export const EditorContainer = styled(Paper)`
  background: ${({ theme }) =>
    theme.components.MuiInputBase.styleOverrides.root.backgroundColor};
  border: ${({ theme }) =>
    theme.components.MuiAccordion.styleOverrides.root.border};

  border-radius: 4px;
  position: relative;
  overflow: hidden;
  border-color: transparent;

  .inner-editor-container {
    position: relative;
    margin: 8px;
  }

  .editor-input {
    height: 500px;
    background: transparent;
    overflow: scroll;
    outline: 0;
    :focus-visible {
      border: none;
    }
    ${props => props.theme.breakpoints.down('sm')} {
      height: 300px;
    }
  }

  .editor-placeholder {
    position: absolute;
    top: 0;
    z-index: 10;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
  }

  :focus-within {
    border-color: ${({ theme }) => theme.palette.neutral['200']}1A;
  }

  transition: border-color 0.15s ease-in;
`;
