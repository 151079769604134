import { Avatar, Box, styled } from '@mui/material';

import React from 'react';
import { createCrown } from '../../Shared/Overlay';

const ProfileGradientImageContainer = styled(Box)(({ size, theme }) => {
  return {
    padding: '12px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'relative',
    maxWidth: `${size + 44}px`
  };
});

// eslint-disable-next-line no-unused-vars
const ProfileGradientContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.background.default} 35%, ${theme.palette.primary.main})`,
  padding: '8px'
}));

const ProfileImageAvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  padding: '2px',
  backgroundColor: theme.palette.background.default
}));

const ProfileGradientImage = ({
  imageUrl,
  size = 72,
  rankIcon,
  rankNumber
}) => {
  return (
    <ProfileGradientImageContainer size={size}>
      <ProfileGradientContainer>
        <ProfileImageAvatarContainer>
          <Avatar
            src={imageUrl}
            sx={{ height: `${size}px`, width: `${size}px` }}
          />
        </ProfileImageAvatarContainer>
      </ProfileGradientContainer>
      {rankNumber > 0 &&
        rankNumber < 4 &&
        createCrown(false, rankNumber, {
          top: '-12px',
          left: '-2px'
        })}
      {rankIcon && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: `${size / 2}px`
          }}
        >
          <img src={rankIcon} width={'100%'} height={'auto'} />
        </Box>
      )}
    </ProfileGradientImageContainer>
  );
};

export default ProfileGradientImage;
