import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Grid, Typography } from '@mui/material';

import { CopyrightSectionContainer } from './styles';
import Links from './Links';
import MobileApp from './MobileApp';

export const Footer = () => {
  const { game } = useSelector(({ app }) => app);
  const { mobileAppSoonFooter } = useFlags();
  return (
    <Grid
      id="footer-container"
      data-testid="footer-container"
      container
      pt={'150px'}
    >
      {}
      {mobileAppSoonFooter && <MobileApp />}
      <Links />
      {/* Copyright Section*/}
      <CopyrightSectionContainer item xs={12}>
        <Typography align="center" variant="subtitle2">
          {`© ${moment().year()} ${get(game, 'legalName')}`}
        </Typography>
      </CopyrightSectionContainer>
    </Grid>
  );
};

export default Footer;
