import { Paper, styled } from '@mui/material';

export const PaperContainer = styled(Paper)(({ theme }) => ({
  padding: '0px',
  borderRadius: theme.border.borderRadius + 'px',
  position: 'relative',

  ['&.hover']: {
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.5s ease-in-out',
    ['&:hover']: {
      transform: 'scale(1.04)',
      transition: 'transform 0.25s ease-in-out'
    }
  },

  ['&.user-profile']: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },

  ['&.my-stores']: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
}));
