import { Checkbox, Grid, Typography, styled } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import { StyledSelectTextField } from '../../../../../../components/Shared/TextFields/index';
import { getOpenCloseHoursOptions } from '../../../../../../utils/entityStore';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .store-hours-grid-row': {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const StoreHoursEdit = ({ control, name }) => {
  const { fields } = useFieldArray({
    control,
    name
  });
  return (
    <StyledGrid container spacing={2}>
      {fields.map((item, index) => {
        return (
          <Grid item xs={12} key={item.day} className="store-hours-grid-row">
            <Grid container spacing={1}>
              <Grid
                item
                xs={2}
                sm={1}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Controller
                  name={`${name}.${index}.isOpen`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={3}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography variant="body1" textTransform="capitalize">
                  {item.day}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sm={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="body1" textTransform="capitalize">
                      From
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={8}>
                    <Controller
                      name={`${name}.${index}.openAt`}
                      control={control}
                      render={({ field }) => (
                        <StyledSelectTextField
                          {...field}
                          menuItems={getOpenCloseHoursOptions()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={3}
                    sm={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="body1" textTransform="capitalize">
                      To
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={8}>
                    <Controller
                      name={`${name}.${index}.closeAt`}
                      control={control}
                      render={({ field }) => (
                        <StyledSelectTextField
                          {...field}
                          menuItems={getOpenCloseHoursOptions()}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default StoreHoursEdit;
