import './index.css';
import './fonts.css';
import './index.css';
import './fonts.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initSentry } from './utils/session';
import { store } from './store/store';
import { ToastContainer } from 'react-toastify';
import Router from './hooks/routes/Router';

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
      scope="read:current_user"
      cacheLocation="localstorage"
    >
      <>
        <ToastContainer limit={3} />
        <Router />
      </>
    </Auth0Provider>
  </Provider>
);
