import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';

import EventSingleStat from './EventSingleStat';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import Loader from '../../../../../components/Loading/Loader';
import { useGetUserRankingEventHistoryQuery } from '../../../../../services/apis/organizePlayApi';
import { usePagination } from '../../../../../hooks/pagination/usePagination';
import { useSelector } from 'react-redux';

const EventHistoryContainer = styled(Box)`
  height: 20vh;
  overflow-y: scroll;
  max-height: 20vh;
`;

const tableHeaders = [
  { header: 'Event Name', key: 'eventName', xs: 6 },
  { header: 'Event Date', key: 'eventDate', xs: 4 },
  { header: 'ELO Change', key: 'eloChange', xs: 2, textAlign: 'end' }
];

const UserRankEventHistory = ({ userId, seasonId, formatId, currentTab }) => {
  const { game } = useSelector(({ app }) => app);
  const gameId = get(game, 'id');
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({});
  const theme = useTheme();

  useEffect(() => {
    const queryObj = {
      gameId,
      userId,
      page: currentPage,
      limit: 5
    };
    if (seasonId) {
      queryObj.seasonId = seasonId;
    }
    if (formatId) {
      queryObj.formatId = formatId;
    }
    setQuery(queryObj);
  }, [currentPage, gameId, userId, seasonId, formatId]);

  useEffect(() => {
    if (currentTab !== 'event-history') {
      resetPagination();
    }
  }, [currentTab]);

  const { data, isLoading, isFetching } = useGetUserRankingEventHistoryQuery(
    query,
    {
      skip: isEmpty(query) || !gameId || !userId
    }
  );

  const {
    loadAction,
    treatedData,
    nextPage,
    isSettingUp,
    totalResults,
    resetPagination
  } = usePagination({
    data,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });

  const isEventsEmpty = isEmpty(treatedData);
  // eslint-disable-next-line no-unused-vars
  const isHistoryLoading = isFetching || isSettingUp;

  const createEventTableHeader = useMemo(() => {
    return (
      <Grid container mb={2}>
        {tableHeaders.map(({ header, key, ...restProps }) => (
          <Grid item key={key} {...restProps}>
            <Typography
              variant="body3Light"
              color={theme.palette.text.secondary}
            >
              {header}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }, [treatedData]);

  const createEventHistoryView = useMemo(() => {
    return (
      <Grid container spacing={2} pb={2} pt={1}>
        {treatedData?.map(info => (
          <Grid item xs={12} key={info.eventId}>
            <EventSingleStat eventInfo={info} />
          </Grid>
        ))}
      </Grid>
    );
  }, [treatedData]);

  if (currentTab !== 'event-history') {
    return null;
  }

  if (isEventsEmpty) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Could not find any event</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container position="relative">
      <Grid item xs={12} mr={2}>
        {createEventTableHeader}
      </Grid>
      <Grid item xs={12}>
        <EventHistoryContainer>
          <InfiniteScroll
            isLoading={isLoading}
            done={nextPage === 0 || totalResults === treatedData?.length}
            loaderAction={loadAction}
            loader={<Loader />}
            useWindow={false}
          >
            {createEventHistoryView}
          </InfiniteScroll>
        </EventHistoryContainer>
      </Grid>
    </Grid>
  );
};

export default UserRankEventHistory;
