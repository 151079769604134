import { configureStore } from '@reduxjs/toolkit';

import AppReducer from './app/reducer';
import AuthReducer from './auth/reducer';
import EventsReducer from './events/reducer';
import { organizePlayApi } from '../services/apis/organizePlayApi';
import { successLogger } from './middlewares/successLogger';

export const store = configureStore({
  reducer: {
    app: AppReducer,
    auth: AuthReducer,
    events: EventsReducer,
    [organizePlayApi.reducerPath]: organizePlayApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(organizePlayApi.middleware)
      .concat(successLogger),
  devTools:
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : x => x
});
