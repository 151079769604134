import { Box, Grid, Typography } from '@mui/material';
import { deckDetailsHeaderStyle, deckNameStyle, userInfoStyle } from './styles';

import BasicUserInfo from '../../Shared/Info/BasicUserInfo';
import DeckDropDown from '../DecksDropDown';
import DeckFormatTube from '../../Shared/Tube/DeckFormatTube';
import DeckLastUpdatedStatus from '../DeckLastUpdatedStatus';
import DeckTagsTube from '../../Shared/Tube/DeckTagsTube';
import React from 'react';
import SocialTube from '../../Shared/Tube/SocialTube';
import { get, isEmpty } from 'lodash';
import { getUserFullName } from '../../../utils/user';
import Tooltip from '../../Shared/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { getDeckErrors } from '../../../utils/deck';

const DeckDetailHeader = ({ deck, gameUser, user, showOptions = true }) => {
  return (
    <Box
      sx={theme =>
        deckDetailsHeaderStyle({
          backgroundImageUrl: deck?.deckImageUrl,
          theme
        })
      }
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          background: '#0006',
          width: '100%',
          height: '100%',
          padding: '0 3%',
          top: 0,
          gap: 2,
          ['*']: {
            textShadow: '0 0 4px black',
            color: 'white'
          }
        }}
      >
        <BasicUserInfo
          boxStyle={userInfoStyle}
          avatar={get(gameUser, 'imageUrl')}
          name={getUserFullName(user)}
        />
        <Typography sx={deckNameStyle}>{deck?.name}</Typography>
        <Typography variant={'body2'}>{deck?.notes}</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <DeckFormatTube format={get(deck, 'format.name')} />
          </Grid>
          <Grid item>
            <DeckTagsTube tags={get(deck, 'tags')} />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            justifyContent: 'flex-start',
            flexDirection: { xs: 'column', sm: 'row' }
          }}
        >
          {deck?.social && (
            <Grid item>
              <SocialTube
                tubeStyle={{
                  minHeight: 'unset',
                  background: 'rgba(255, 255, 255, 0.1)',
                  padding: '8px',
                  gap: 2
                }}
              />
            </Grid>
          )}
          <Grid item>
            <DeckLastUpdatedStatus deck={deck} />
          </Grid>
        </Grid>
      </Box>
      {showOptions && <DeckDropDown disableHover deck={deck} redirectToDecks />}
      {!isEmpty(deck.errors) && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Tooltip
            icon={
              <ErrorIcon
                className={'error-icon'}
                color={'error'}
                sx={{ fontSize: '4rem' }}
              />
            }
            info={getDeckErrors({ errors: deck?.errors, id: '$' })}
          />
        </Box>
      )}
    </Box>
  );
};

export default DeckDetailHeader;
