// eslint-disable-next-line no-unused-vars
import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../Loading/Loader';
import { Navigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ checkEventAccess = false, ...restProps }) => {
  const { gameSlug } = useParams();
  const { user } = useSelector(({ app }) => app);

  if (checkEventAccess && !get(user, 'permissions.events.hasAccess', true)) {
    return <Navigate to={`/${gameSlug ? gameSlug : ''}`} replace={true} />;
  }

  return <>{restProps.children}</>;
};

export default withAuthenticationRequired(ProtectedRoute, {
  onRedirecting: () => <Loading />
});
