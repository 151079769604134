import { useParams } from 'react-router-dom';
import { buildRoutePath } from '../../utils/routingUtils';

export function useCustomNavigation() {
  const { gameSlug } = useParams();

  const buildSubPath = (path = '') => {
    return buildRoutePath(gameSlug, path);
  };

  const buildUrlPath = (path = '') => {
    return window.location.origin + buildRoutePath(gameSlug, path);
  };

  return { buildSubPath, buildUrlPath };
}
