import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import SimpleModalCard from '../../../Modal/Cards/SimpleModalCard';
import { ModalContext } from '../../../Modal/Context/ModalContext';
import { useDeck } from '../../../../pages/Decks/hooks/useDeck';
import { buildRoutePath } from '../../../../utils/routingUtils';

const DeleteDeck = ({ id, redirectToDecks }) => {
  const { gameSlug } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { closeModal, setIsLoading } = useContext(ModalContext);
  const { deleteDeckProps } = useDeck();

  // eslint-disable-next-line no-unused-vars
  const [deleteDeck, deleteDeckResult] = deleteDeckProps;
  const navigate = useNavigate();
  const { game } = useSelector(({ app }) => app);

  useEffect(() => {
    if (!deleteDeckResult.isLoading && deleteDeckResult.isSuccess) {
      closeModal();
      if (redirectToDecks) {
        navigate(buildRoutePath(gameSlug, '/decks'));
      }
    }
  }, [deleteDeckResult]);

  useEffect(() => {
    setIsLoading(deleteDeckResult.isLoading);
  }, [deleteDeckResult.isLoading]);

  return (
    <SimpleModalCard
      title={`Delete ${get(game, 'terminology.deck', 'Deck')}`}
      cancelButton={{ id: 'delete-deck-cancel-button' }}
      confirmButton={{
        id: 'delete-deck-confirm-button',
        onClick: () => deleteDeck(id),
        title: 'Delete',
        color: 'error'
      }}
    >
      <Typography textAlign={'center'} typography={'h4'}>
        Are you sure?
      </Typography>
    </SimpleModalCard>
  );
};

export default DeleteDeck;
