import { Checkbox, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import React from 'react';

export const productItens = (tags, filter) =>
  tags.reduce((acc, tag) => {
    acc.push(
      <ListSubheader muiSkipListHighlight key={tag.id}>
        {tag.name}
      </ListSubheader>
    );
    acc.push(
      tag.tags.map(tagItem => (
        <MenuItem key={tagItem.id} value={tagItem.id}>
          <Checkbox checked={filter.tags.indexOf(tagItem.id) > -1} />
          <ListItemText primary={tagItem.name} />
        </MenuItem>
      ))
    );
    return acc;
  }, []);

export const tagDict = tags => {
  const productTags = {};
  tags.forEach(tag => {
    const tempObj = tag.tags.reduce((acc, curr) => {
      acc[curr.id] = { ...curr };
      return acc;
    }, {});

    Object.assign(productTags, tempObj);
  });

  return productTags;
};
