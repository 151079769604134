import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import detectEthereumProvider from '@metamask/detect-provider';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const WalletIntegration = ({ setImages }) => {
  const { game } = useSelector(({ app }) => app);

  const [hasProvider, setHasProvider] = useState(false);
  const initialState = { accounts: [] };
  const [wallet, setWallet] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true });
      setHasProvider(Boolean(provider)); // transform provider to true or false
    };

    getProvider();
  }, []);

  const updateWallet = async accounts => {
    setWallet({ accounts });
  };

  const handleConnect = async () => {
    setIsLoading(true);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    });
    const allNFTImages = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_MIDDLEWARE_URL}/rewards/meta-wallets/nfts`,
      data: {
        accounts,
        symbols: get(game, 'terminology.pfpSymbols', []),
        gameSlug: get(game, 'slug')
      }
    });

    setImages(allNFTImages.data.data || []);
    await updateWallet(accounts);
    setIsLoading(false);
  };

  if (hasProvider) {
    return (
      <Grid
        alignItems="center"
        container
        flexDirection="row"
        justifyContent="center"
        sx={{ minHeight: '75px' }}
      >
        {wallet.accounts.length <= 0 ? (
          <LoadingButton onClick={handleConnect} loading={isLoading}>
            Connect Wallet
          </LoadingButton>
        ) : (
          <div>Wallet Connected!</div>
        )}
      </Grid>
    );
  }
  return null;
};

export default WalletIntegration;
