import React from 'react';
import { get } from 'lodash';
import Lottie from 'react-lottie';

import { Backdrop } from '@mui/material';

import * as akoraLoader from '../../../assets/animations/loading/akora.json';
import * as alphaClashLoader from '../../../assets/animations/loading/alpha-clash.json';
import * as cardeioLoader from '../../../assets/animations/loading/cardeio.json';
import * as elestralsLoader from '../../../assets/animations/loading/elestrals.json';
import * as fabledSagasLoader from '../../../assets/animations/loading/fabled-sagas.json';
import * as grandArchiveLoader from '../../../assets/animations/loading/grand-archive.json';
import * as interstellarLoader from '../../../assets/animations/loading/interstellar.json';
import * as kryptikLoader from '../../../assets/animations/loading/kryptik.json';
import * as lorcanaLoader from '../../../assets/animations/loading/lorcana.json';
import * as metazooLoader from '../../../assets/animations/loading/metazoo.json';
import * as neopetsLoader from '../../../assets/animations/loading/neopets-battledome.json';
import * as nostalgixLoader from '../../../assets/animations/loading/nostalgix.json';
import * as onepieceLoader from '../../../assets/animations/loading/onepiece.json';
import * as riseTcgLoader from '../../../assets/animations/loading/rise-tcg.json';
import * as shadowverseLoader from '../../../assets/animations/loading/shadowverse-evolve.json';
import * as solforgeLoader from '../../../assets/animations/loading/solforge.json';
import * as sorceryLoader from '../../../assets/animations/loading/sorcery.json';
import * as starRealmsLoader from '../../../assets/animations/loading/star-realms.json';
import * as starWarsLoader from '../../../assets/animations/loading/starwars-unlimited.json';
import * as synsoulsLoader from '../../../assets/animations/loading/synsouls.json';
import * as systemGateLoader from '../../../assets/animations/loading/system-gate.json';
import * as universusLoader from '../../../assets/animations/loading/universus.json';
import * as vampireLoader from '../../../assets/animations/loading/vampire.json';

const PageLoading = ({ slug }) => {
  const animationData = {
    akora: akoraLoader,
    'alpha-clash': alphaClashLoader,
    elestrals: elestralsLoader,
    'fabled-sagas': fabledSagasLoader,
    'grand-archive': grandArchiveLoader,
    interstellar: interstellarLoader,
    kryptik: kryptikLoader,
    lorcana: lorcanaLoader,
    metazoo: metazooLoader,
    'neopets-battledome': neopetsLoader,
    nostalgix: nostalgixLoader,
    onepiece: onepieceLoader,
    'rise-tcg': riseTcgLoader,
    'shadowverse-evolve': shadowverseLoader,
    solforge: solforgeLoader,
    sorcery: sorceryLoader,
    'star-realms': starRealmsLoader,
    'starwars-unlimited': starWarsLoader,
    synsouls: synsoulsLoader,
    'system-gate': systemGateLoader,
    universus: universusLoader,
    vampire: vampireLoader
  };

  const bgColors = {
    akora: '#000000',
    'alpha-clash': '#03000C',
    elestrals: '#000000',
    'fabled-sagas': '#111111',
    'grand-archive': '#212642',
    interstellar: '#0D0D0D',
    kryptik: '#040914',
    lorcana: '#070738',
    metazoo: '#FFFFFF',
    'neopets-battledome': '#0c1020',
    nostalgix: '#050913',
    onepiece: '#f5f4f0',
    'rise-tcg': '#0a1800',
    'shadowverse-evolve': '#041821',
    solforge: '#222428',
    sorcery: '#141415',
    'star-realms': '#111111',
    'starwars-unlimited': '#131416',
    synsouls: '#000002',
    'system-gate': '#09191f',
    universus: '#021c29',
    vampire: '#141214'
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData[slug] || cardeioLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const size = {
    elestrals: {
      height: 250,
      width: 250
    },
    interstellar: {
      height: 150,
      width: 335
    }
  };

  return (
    <Backdrop open sx={{ backgroundColor: bgColors[slug] }}>
      <Lottie
        options={defaultOptions}
        height={get(size[slug], 'height', 200)}
        width={get(size[slug], 'width', 200)}
      />
    </Backdrop>
  );
};

export default PageLoading;
