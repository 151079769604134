import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const DeckTags = ({ tags, setTags }) => {
  const { game } = useSelector(({ app }) => app);
  const deckTags = game?.deckTags;

  return (
    <Autocomplete
      filterSelectedOptions
      getOptionLabel={option => {
        return (
          option.name || deckTags.find(tag => tag._id === option)?.name || ''
        );
      }}
      value={tags}
      onChange={(event, value) => {
        setTags(value);
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      options={deckTags}
      renderInput={params => (
        <TextField {...params} data-testid="decks-tag-filter" label="Tag" />
      )}
      multiple
    />
  );
};

export default DeckTags;
