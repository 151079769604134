import Joi from 'joi';

export const addressSchema = Joi.object({
  address1: Joi.string().required(),
  address2: Joi.string().allow(''),
  city: Joi.string().required(),
  state: Joi.string().required(),
  zip: Joi.string().required(),
  country: Joi.string().required(),
  isOptedOut: Joi.boolean().allow()
}).required();
