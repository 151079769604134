/* eslint-disable no-unused-vars */
import { Avatar, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { defaultBackgroundSetup } from './styles';
// import RewardButton from './RewardButton';
import { get } from 'lodash';

const SeasonRankProfile = ({ user }) => {
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">My Season Rank</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={defaultBackgroundSetup()}
          display="flex"
          width="100%"
          gap={{ xs: 4, sm: 4, md: 0 }}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              sx={{ width: '145px', height: '145px' }}
              src="https://storage.googleapis.com/cardeio-images/defaults/akora/profile/137.webp"
            />
            <Typography color="primary.main" variant="h4">
              {`${get(user, 'details.info.firstName', '')} ${get(
                user,
                'details.info.lastName',
                ''
              )}`}
            </Typography>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={theme => ({
                paddingRight: { xs: 0, sm: 8 },
                borderRight: {
                  xs: 0,
                  sm: `1px solid ${theme.palette.primary.light}`
                },
                paddingBottom: { xs: 2, sm: 0 },
                borderBottom: {
                  xs: `1px solid ${theme.palette.primary.light}`,
                  sm: 0
                }
              })}
            >
              <Typography variant="h4">16th place</Typography>
              <Typography variant="body2">Current Standing</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                paddingLeft: { xs: 0, sm: 8 },
                paddingTop: { xs: 2, sm: 0 }
              }}
            >
              <Typography variant="h2">1543</Typography>
              <Typography variant="body2">Points</Typography>
            </Box>
          </Box>
          {/* <RewardButton /> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SeasonRankProfile;
