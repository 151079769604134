import React, { useContext } from 'react';

import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import RewardDetails from '../RewardDetails';
import SimpleModalCard from '../../../../components/Modal/Cards/SimpleModalCard';

const RewardDetailsModal = ({ productId, inventoryId, currencyId }) => {
  const { closeModal } = useContext(ModalContext);
  const onCloseClick = () => {
    closeModal();
  };
  return (
    <SimpleModalCard
      hideActionButtons
      title=""
      showClose
      onClose={onCloseClick}
    >
      <RewardDetails
        productId={productId}
        inventoryId={inventoryId}
        currencyId={currencyId}
        isModal
        onClose={onCloseClick}
      />
    </SimpleModalCard>
  );
};

export default RewardDetailsModal;
