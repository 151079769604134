import { useContext } from 'react';
import { ModalContext } from '../../components/Modal/Context/ModalContext';

export function useModalContext() {
  const { addModal, closeModal, isOpen, isLoading, setIsLoading } = useContext(
    ModalContext
  );

  return { addModal, closeModal, isOpen, isLoading, setIsLoading };
}
