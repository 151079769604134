import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, useMediaQuery } from '@mui/material';
import { DeckButtons } from '../../styles';
import DeckFilterOptions from './DeckFilterOptions';
// eslint-disable-next-line no-unused-vars
import TuneIcon from '@mui/icons-material/Tune';
import { filterButtonStyle } from './styles';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/system';

/** TODO: ADD Filtering **/

// eslint-disable-next-line no-unused-vars
const DecksFilter = ({ offsetTop, setDeckQuery, INITIAL_QUERY }) => {
  const { isAuthenticated } = useAuth0();
  const params = useParams();
  const navigate = useNavigate();
  const { game } = useSelector(({ app }) => app);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const deckSelectionTypes = [
    {
      title: `Community ${get(game, 'terminology.deck', 'Deck')}s`,
      key: 'communityDecks',
      show: true,
      path: '',
      props: { xs: 7, sm: 6 },
      id: 'community-decks'
    },
    {
      title: `My ${get(game, 'terminology.deck', 'Deck')}s`,
      key: 'myDecks',
      path: 'my-decks',
      show: isAuthenticated,
      props: { xs: 5, sm: 6 },
      id: 'my-decks'
    }
  ].filter(deckSection => deckSection.show);

  const selectionIndex = deckSelectionTypes.findIndex(
    selection => selection.path === params['*']
  );

  const setDeckSelection = value => {
    selectionIndex !== value &&
      navigate(deckSelectionTypes[value].path, {
        preventScrollReset: true
      });
  };

  return (
    <Grid
      id="decks-filter-section"
      data-testid="decks-filter-section"
      container
      justifyContent={'space-between'}
      spacing={2}
    >
      <Grid item xs={12} md={'auto'}>
        <Grid container spacing={2}>
          {deckSelectionTypes.map((deck, index) => (
            <Grid item key={deck.key} {...deck.props}>
              <DeckButtons
                id={`${deck.id}-button`}
                data-testid={`${deck.id}-button`}
                onClick={() => setDeckSelection(index)}
                fullWidth
                className={`deck-selection ${
                  selectionIndex === index ? 'active-button' : ''
                }`}
                sx={filterButtonStyle}
              >
                {deck.title}
              </DeckButtons>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={'auto'}>
        {/* <DeckButtons */}
        <Button
          className="toggleButton"
          id="decks-filter-open-button"
          data-testid="decks-filter-open-button"
          component={'div'}
          variant={'contained'}
          sx={() => ({
            '&.MuiButton-contained.toggleButton': {
              minWidth: '250px'
            }
          })}
          onClick={() => {
            if (isMobile) {
              setOpenDrawer(true);
            } else setShowFilterOptions(!showFilterOptions);
          }}
        >
          Filters
          <TuneIcon />
        </Button>
        {/* </DeckButtons> */}
      </Grid>
      <Grid item xs={12}>
        <DeckFilterOptions
          showFilterOptions={showFilterOptions}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          isMobile={isMobile}
          offsetTop={offsetTop}
          setDeckQuery={setDeckQuery}
          INITIAL_QUERY={INITIAL_QUERY}
        />
      </Grid>
    </Grid>
  );
};

export default DecksFilter;
