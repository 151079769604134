import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { useState } from 'react';
import { isNaN, kebabCase, toNumber } from 'lodash';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { LoadingButton } from '@mui/lab';

const StyledQuantityWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`;

// eslint-disable-next-line no-unused-vars
const StyledQuantityInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& .MuiFormControl-root': {
    margin: '0 8px',
    width: '120px'
  },
  '& .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      fontSize: '16px',
      padding: '8px 16px'
    }
  },
  '& .MuiButtonBase-root': {
    padding: '8px 16px'
  }
}));

const CartItemQuantitySelector = ({
  itemName,
  limit,
  currentQuantity,
  onChange = () => {},
  isLoading = false
}) => {
  const [isTextMode, setIsTextMode] = useState(currentQuantity >= 10);
  const [textValue, setTextValue] = useState(currentQuantity);
  const quantityOptions = new Array(
    limit && limit > 10 ? 10 : limit === null ? 10 : limit
  )
    .fill(1)
    .map((value, index) => ({
      value: index === 9 ? '10+' : index + 1,
      text: index === 9 ? '10+' : `${index + 1}`
    }));
  const onChangeQuantity = newQuantity => {
    if (typeof newQuantity === 'number') {
      onChange(newQuantity);
    } else {
      setIsTextMode(true);
    }
  };
  const onTextInputChange = value => {
    if (!value) {
      setTextValue('');
    } else {
      const num = toNumber(value);
      if (!isNaN(num)) {
        if (limit && limit < num) {
          setTextValue(limit);
        } else {
          setTextValue(num);
        }
      } else {
        setTextValue('');
      }
    }
  };
  const isUpdateQuantityDisabled = () => {
    if (!textValue) {
      return true;
    }
    if (currentQuantity === textValue) {
      return true;
    }
    return false;
  };
  return (
    <>
      <StyledQuantityWrapper>
        <Typography variant="body2">Quantity:</Typography>
        {isTextMode ? (
          <StyledQuantityInputWrapper>
            <TextField
              value={textValue}
              InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={ev => onTextInputChange(ev.target.value)}
            />
            <LoadingButton
              disabled={isUpdateQuantityDisabled()}
              loading={isLoading}
              onClick={() => onChange(textValue)}
            >
              Update
            </LoadingButton>
          </StyledQuantityInputWrapper>
        ) : (
          <Select
            sx={{
              marginLeft: '10px',
              '& .MuiSelect-select': {
                fontSize: '20px',
                '&:focus': {
                  backgroundColor: 'transparent'
                }
              }
            }}
            data-testid={`${kebabCase(itemName)
              .replaceAll(' ', '')
              .toLowerCase()}-item-quantity-container`}
            variant="standard"
            disableUnderline
            autoWidth
            value={currentQuantity}
            IconComponent={KeyboardArrowDownOutlinedIcon}
            onChange={event => onChangeQuantity(event.target.value)}
          >
            {quantityOptions.map(({ value, text }) => (
              <MenuItem
                data-testid={`item-quantity-${text}`}
                value={value}
                key={value}
              >
                {text}
              </MenuItem>
            ))}
          </Select>
        )}
      </StyledQuantityWrapper>
      {isTextMode && <Typography>Max: {limit}</Typography>}
    </>
  );
};

export default CartItemQuantitySelector;
