import React from 'react';
import { Route } from 'react-router-dom';

import CreateNewEventPage from './AddNewEventPage';
import EventListingsPage from './All';
import EventOverviewPage from './Single/EventOverviewPage';
import EventRosterPage from './Single/EventRosterPage';
import EventPlacingsPage from './Single/EventPlacingsPage';
import EventPairingsPage from './Single/EventPairingsPage';
import EventEditPage from './Single/EventEditPage';
import EventMainPage from './index';
import EventSinglePage from './Single';
import ProtectedRoute from '../../components/Route/ProtectedRoute';
import StatusPage, { statusTypes } from '../Status';

const EventsRoutes = () => {
  return (
    <Route
      path={'events/*'}
      element={<EventMainPage />}
      errorElement={<StatusPage type={statusTypes.error} />}
    >
      <Route index element={<EventListingsPage />} />
      <Route path=":eventId/*" element={<EventSinglePage />}>
        <Route path="*" element={<EventOverviewPage />} />
        <Route exact path="roster" element={<EventRosterPage />} />
        <Route exact path="placings" element={<EventPlacingsPage />} />
        <Route exact path="pairings" element={<EventPairingsPage />} />
        <Route
          exact
          path="edit"
          element={
            <ProtectedRoute checkEventAccess={true}>
              <EventEditPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        exact
        path={'new'}
        element={
          <ProtectedRoute checkEventAccess={true}>
            <CreateNewEventPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default EventsRoutes;
