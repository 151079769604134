import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import StoreList from './components/StoreList/index';
import StoreMapViewer from './components/StoreMapView';
import StoreSearchFilters from './components/SearchFilters';
import { useGetStoreSearchQuery } from '../../../services/apis/organizePlayApi';

const StoreLocatorPage = () => {
  const { game } = useSelector(({ app }) => app);
  const [searchQuery, setSearchQuery] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [mapCenter, setMapCenter] = useState({});
  const [selectedStoreId, setSelectedStoreId] = useState(null);

  const onSearchClick = searchData => {
    const lat = get(searchData, 'location.geo.coordinates[1]', null);
    const lng = get(searchData, 'location.geo.coordinates[0]', null);
    const distance = get(searchData, 'radius', null);
    if (lat !== null && lng !== null && distance) {
      setSearchQuery({ lat, lng, distance, gameId: get(game, 'id') });
      setMapCenter({ lat, lng });
    }
  };

  const { data, isLoading, isFetching } = useGetStoreSearchQuery(searchQuery, {
    refetchOnMountOrArgChange: true,
    skip: !game.id || isEmpty(searchQuery)
  });

  const isSearchResultLoading = isFetching || isLoading;

  useEffect(() => {
    if (isSearchResultLoading) {
      setSearchResult([]);
    } else {
      setSearchResult(get(data, 'data', []));
    }
  }, [data, isSearchResultLoading]);

  const onClickStore = storeId => {
    if (storeId === selectedStoreId) {
      setSelectedStoreId(null);
    } else {
      setSelectedStoreId(storeId);
    }
  };

  return (
    <MaxWidthContainer className={'p-percent p-top'} container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" data-testid="stores-title">
            Find Our Stores
          </Typography>
        </Grid>
        {/* search section */}
        <Grid item xs={12} mt="64px">
          <StoreSearchFilters
            onSearch={onSearchClick}
            isLoading={isSearchResultLoading}
          />
        </Grid>
        <Grid item xs={12} mt="64px">
          <Typography>Stores</Typography>
        </Grid>
        <Grid item xs={12} mt="16px">
          <Grid container spacing={2}>
            <Grid item sm={4} display={{ xs: 'none', sm: 'initial' }}>
              <StoreList
                data={searchResult}
                isLoading={isSearchResultLoading}
                onClickStore={onClickStore}
                selectedStoreId={selectedStoreId}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <StoreMapViewer
                mapCenter={mapCenter}
                data={searchResult}
                gotoStoreId={selectedStoreId}
              />
            </Grid>
            <Grid item xs={12} display={{ xs: 'initial', sm: 'none' }}>
              <StoreList
                data={searchResult}
                isLoading={isSearchResultLoading}
                onClickStore={onClickStore}
                selectedStoreId={selectedStoreId}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
};

export default StoreLocatorPage;
