export const mobileHeaderContainerStyle = theme => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridAutoRows: '1fr',
  ['.deck-page-selector']: {
    gridColumn: '1 / 3'
  },
  ['.deck-button']: {
    gridColumn: '3 / -1',
    gridRow: 1
  },
  ['.filter-button']: {
    justifyContent: 'space-between',
    color: `${theme.palette.primary.main} !important`,
    gridColumn: '1 / -1'
  },
  gap: 2
});

export const desktopHeaderContainerStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  ['.textfield']: {
    padding: 1
  },
  ['.filter-buttons']: {
    padding: 1,
    display: 'flex',
    gap: 0.5
  }
});
