import { currencyObject } from './currencyArray';

export const mainCurrencies = [
  { currencyCode: 'USD', symbol: '$', language: 'en-us', countryCode: 'US' },
  { currencyCode: 'EUR', symbol: '€', language: 'en-us', countryCode: 'EU' },
  { currencyCode: 'GBP', symbol: '£', language: 'en-gb', countryCode: 'GB' },
  { currencyCode: 'JPY', symbol: '¥', language: 'ja-jp', countryCode: 'JP' },
  { currencyCode: 'CAD', symbol: 'C$', language: 'en-ca', countryCode: 'CA' },
  { currencyCode: 'AUD', symbol: 'A$', language: 'en-au', countryCode: 'AU' },
  { currencyCode: 'CHF', symbol: 'Fr.', language: 'de-ch', countryCode: 'CH' },
  { currencyCode: 'CNY', symbol: '¥', language: 'zh-cn', countryCode: 'CN' },
  { currencyCode: 'HKD', symbol: 'HK$', language: 'zh-hk', countryCode: 'HK' },
  { currencyCode: 'NZD', symbol: 'NZ$', language: 'en-nz', countryCode: 'NZ' },
  { currencyCode: 'SEK', symbol: 'kr', language: 'sv-se', countryCode: 'SE' },
  { currencyCode: 'KRW', symbol: '₩', language: 'ko-kr', countryCode: 'KR' },
  { currencyCode: 'SGD', symbol: 'S$', language: 'en-sg', countryCode: 'SG' },
  { currencyCode: 'NOK', symbol: 'kr', language: 'nb-no', countryCode: 'NO' },
  { currencyCode: 'MXN', symbol: 'Mex$', language: 'es-mx', countryCode: 'MX' },
  { currencyCode: 'INR', symbol: '₹', language: 'hi-in', countryCode: 'IN' },
  { currencyCode: 'RUB', symbol: '₽', language: 'ru-ru', countryCode: 'RU' },
  { currencyCode: 'ZAR', symbol: 'R', language: 'en-za', countryCode: 'ZA' },
  { currencyCode: 'BRL', symbol: 'R$', language: 'pt-br', countryCode: 'BR' },
  { currencyCode: 'TRY', symbol: '₺', language: 'tr-tr', countryCode: 'TR' },
  { currencyCode: 'AED', symbol: 'د.إ', language: 'ar-ae', countryCode: 'AE' },
  { currencyCode: 'THB', symbol: '฿', language: 'th-th', countryCode: 'TH' },
  { currencyCode: 'IDR', symbol: 'Rp', language: 'id-id', countryCode: 'ID' },
  { currencyCode: 'MYR', symbol: 'RM', language: 'ms-my', countryCode: 'MY' },
  { currencyCode: 'PHP', symbol: '₱', language: 'fil-ph', countryCode: 'PH' },
  { currencyCode: 'PLN', symbol: 'zł', language: 'pl-pl', countryCode: 'PL' }
];

export const getCurrencyObj = () => {
  const allCurrencies = Object.entries(currencyObject);
  const filteredCurrencies = allCurrencies.filter(
    currency =>
      !mainCurrencies.find(
        mainCurrency => mainCurrency.currencyCode === currency[0]
      )
  );
  const otherCurrencies = filteredCurrencies.reduce((acc, cur) => {
    acc[cur[0]] = { currencyCode: cur[0], symbol: cur[1] };
    return acc;
  }, {});
  const mainCurrencyObj = mainCurrencies.reduce((acc, cur) => {
    acc[cur.currencyCode] = cur;
    return acc;
  }, {});
  return { ...mainCurrencyObj, ...otherCurrencies };
};

export const getCurrencyByField = (value, field) => {
  const foundCurrency = mainCurrencies.find(
    currency => currency[field] === value
  );
  return foundCurrency || mainCurrencies[0];
};
