import React from 'react';
import { Route } from 'react-router-dom';

import AllRankingsPage from './All';
import StatusPage, { statusTypes } from '../Status';
import RankingsMainPage from './index';

const RankingsRoutes = () => {
  return (
    <Route path={'rankings/*'} element={<RankingsMainPage />}>
      <Route
        index
        element={<AllRankingsPage />}
        errorElement={<StatusPage type={statusTypes.error} />}
      />
    </Route>
  );
};

export default RankingsRoutes;
