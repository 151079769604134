import {
  Box,
  IconButton,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { get, isEmpty } from 'lodash';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DeckValidationStatus } from '../../../../../Decks/DeckValidationStatus';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

const DeckPreviewContainer = styled(Paper)(
  ({ isSelected, isValid, isSmall, theme }) => ({
    transition: 'transform 0.5s ease-in-out',
    outline: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    margin: '12px',
    cursor: isValid ? 'pointer' : 'default',
    borderRadius: '12px',
    position: 'relative',
    boxShadow: `${
      isSelected ? theme.palette.primary.main : 'rgba(0,0,0,0.2)'
    } 0px 0px 16px 4px`,
    '& .deck_view_button_container': {
      opacity: isSmall ? 1 : 0
    },
    '&:hover': {
      transform: isValid && !isSmall ? 'scale(1.04)' : 'none',
      '& .deck_view_button_container': {
        opacity: 1
      }
    }
  })
);

const DeckImageContainer = styled(Box)(({ imageUrl }) => ({
  minHeight: '200px',
  width: '100%',
  backgroundImage: `url(${imageUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '12px 12px 0px 0px',
  position: 'relative'
}));

const DeckInfoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[600],
  borderRadius: '0px 0px 12px 12px',
  padding: '10px 14px',
  '& .MuiTypography-root': {
    color: theme.palette.primary.contrastText
  }
}));

const CheckboxContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '6px',
  right: '6px'
}));

const ViewButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '4px',
  right: 'calc(50% - 20px)',
  background: theme.palette.neutral[500],
  borderRadius: '8px',
  transition: 'opacity 0.5s ease-in-out',
  boxShadow: '0px 0 10px 1px #ffffff4d',
  '& .MuiIconButton-root': {}
}));

export const SelectableDeckPreview = ({
  deck,
  isSelected = false,
  onChangeSelect,
  onSelectViewDetails
}) => {
  const { deck: currentDeck } = deck;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const onClickViewDetails = event => {
    event.stopPropagation();
    event.preventDefault();
    onSelectViewDetails();
  };

  const onClickSelectDeck = () => {
    const isValid = isEmpty(get(currentDeck, 'errors', {}));
    if (isValid) {
      onChangeSelect();
    }
  };

  const renderCheckBox = () => {
    return (
      <CheckboxContainer id="selectable-deck-preview-checkbox">
        {isSelected ? (
          <CheckCircleIcon color="success" />
        ) : (
          <RadioButtonUncheckedIcon />
        )}
      </CheckboxContainer>
    );
  };
  const renderViewButton = () => {
    return (
      <ViewButtonContainer className="deck_view_button_container">
        <IconButton
          id="selectable-deck-preview-view-button"
          data-testid="selectable-deck-preview-view-button"
          onClick={onClickViewDetails}
        >
          <VisibilityIcon color="primary" />
        </IconButton>
      </ViewButtonContainer>
    );
  };
  const isValid = isEmpty(get(currentDeck, 'errors', {}));
  return (
    <DeckPreviewContainer
      isSelected={isSelected}
      isValid={isValid}
      onClick={onClickSelectDeck}
      isSmall={isSmall}
    >
      {renderCheckBox()}
      <DeckImageContainer imageUrl={currentDeck.deckImageUrl}>
        {renderViewButton()}
      </DeckImageContainer>
      <DeckInfoContainer>
        <Typography className={'deck-name'} variant="h5">
          {currentDeck.name}
        </Typography>
        <DeckValidationStatus deck={currentDeck} />
      </DeckInfoContainer>
    </DeckPreviewContainer>
  );
};
