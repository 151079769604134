import { entries, first, get, isArray, isEmpty, isString } from 'lodash';

import React from 'react';
import { Typography } from '@mui/material';

export const boardTypeToSection = sections => {
  return sections?.reduce((prev, current) => {
    return {
      boardTypeToSection: {
        ...prev.boardTypeToSection,
        [current?.type]: { deckSectionId: current?._id }
      },
      sectionToBoardType: {
        ...prev.sectionToBoardType,
        [current?._id]: current?.type
      },
      sectionInfo: {
        ...prev.sectionInfo,
        [current?._id]: current
      }
    };
  }, {});
};

export const createDeckNamePath = id =>
  `sectionHelper.boardTypeMap.sectionInfo.${id}.name`;

// Build values used in react hook form
export const buildValues = (
  singleDeckData,
  defaultDeckType,
  gameFormatsById
) => {
  const formatId = singleDeckData?.data?.deck?.formatId;
  let boardTypeMap =
    !formatId || formatId !== gameFormatsById[formatId]?._id
      ? defaultDeckType?.deckSections
      : gameFormatsById[formatId]?.deckType?.deckSections;

  boardTypeMap = boardTypeToSection(boardTypeMap);
  const updateData = {
    deckImageUrl: '',
    name: '',
    notes: '',
    formatId: '',
    sharing: { status: '' },
    ...singleDeckData?.data?.deck,
    cardInfo: {
      ...singleDeckData?.data?.cards?.reduce((prev, current) => {
        return { ...prev, [current?._id]: current };
      }, {})
    },
    formatOptions: createFormatOptions(
      singleDeckData?.data?.deck?.formatOptions,
      gameFormatsById[formatId]?.formatOptions
    ),
    sectionHelper: {
      boardTypeMap
    },
    tags: get(singleDeckData, 'data.deck.tags', []).map(tag => ({
      value: get(tag, '_id'),
      name: get(tag, 'name')
    }))
  };

  // Create cardTracker for each deck section
  if (updateData?.sections?.length > 0) {
    updateData.sections = updateData?.sections?.map((section, index) => {
      const sectionToBoard =
        updateData.sectionHelper?.boardTypeMap?.sectionToBoardType[
          section?.deckSectionId
        ];
      const boardTypeToSection =
        updateData.sectionHelper?.boardTypeMap?.boardTypeToSection[
          sectionToBoard
        ];
      if (boardTypeToSection) {
        boardTypeToSection.index = index;
      }
      return {
        ...section,
        cardTracker: new Set(section?.cards?.map(card => card?.cardId) || []),
        cardCount: getDeckCardCount(section?.cards)
      };
    });
  }
  return updateData;
};

export const getFirstFormatOption = formatOptions =>
  first(entries(formatOptions))?.[1] || ['', ''];

export const createFormatOptions = (
  formatOptionsId = {},
  defaultFormatOptions = {}
) => {
  const formatReduced = entries(defaultFormatOptions)?.reduce(
    (prev, current) => {
      return {
        ...prev,
        [current[0]]: entries(current[1]).reduce(
          (prevSub, currentSub) => ({
            ...prevSub,
            [currentSub[1]]: currentSub
          }),
          {}
        )
      };
    },
    {}
  );
  const convertedFormatOptions = entries(formatOptionsId).reduce(
    (prev, current) => {
      return {
        ...prev,
        [current[0]]: get(formatReduced, `${current[0]}.${current[1]}`)
      };
    },
    {}
  );
  return isEmpty(convertedFormatOptions) ? {} : convertedFormatOptions;
};

export const getDeckErrors = ({ errors, id }) => {
  // eslint-disable-next-line no-unused-vars
  const errorSections = Object?.values(errors)?.reduce((prev, section) => {
    // eslint-disable-next-line no-unused-vars
    let foundValue = Object?.entries(section)?.find(([key, value]) => {
      return id === key;
    });

    const value = get(foundValue, '1', undefined);

    if (!foundValue) {
      return prev;
    } else if (!isArray(value) && !isString(value)) {
      foundValue = Object.values(foundValue[1])
        .reduce((prev, current) => [...prev, ...current], [])
        .filter(value => value);
      return [...prev, ...foundValue];
    }
    return [...prev, foundValue[1]];
  }, []);

  if (errorSections.length > 0) {
    return errorSections.map(value => (
      <Typography key={value} variant={'body2'}>
        {value}
      </Typography>
    ));
  }

  if (id === '$' && !isEmpty(errors)) {
    return <Typography variant={'body2'}>Errors found in the deck.</Typography>;
  }
  return undefined;
};

export const getDeckCardCount = cards =>
  cards?.reduce((prev, current) => prev + (current?.count || 0), 0) || 0;

export const getDeckTerminologies = game => ({
  deck: get(game, 'terminology.deck', 'Deck')
});

const getCardType = card => {
  if (typeof card.cardType === 'string') return card.cardType;
  return card.cardType._id;
};

export const groupByCardType = (cards, cardInfo, sectionIndex) => {
  if (cards.length === 0) return false;
  const cardsInfo = cards.map((card, index) => {
    return {
      ...cardInfo[card.cardId],
      count: card.count,
      cardIndex: index,
      sectionIndex,
      cardId: card.cardId
    };
  });
  const cardsByType = cardsInfo.reduce((acc, card) => {
    acc[getCardType(card)] = [...(acc[getCardType(card)] || []), card];
    return acc;
  }, {});
  return cardsByType;
};
