import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

const activeGame = process.env.REACT_APP_ACTIVE_GAME;

export function useLogin() {
  const { loginWithRedirect } = useAuth0();
  const params = useParams();
  const path = activeGame ? '' : `/${params.gameSlug}`;

  const login = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}${path}`
    });
  };

  return {
    login
  };
}
