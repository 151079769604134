import { Box, styled } from '@mui/material';

export const RewardsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '60px 30px',
  borderRadius: `${theme.border.borderRadius}px`,
  [theme.breakpoints.down('sm')]: {
    padding: '25px 10px'
  }
}));

export const rewardsGridStyle = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    sm: 'repeat(auto-fill, minmax(250px, 1fr))',
    lg: 'repeat(auto-fill, 350px)'
  },
  justifyContent: 'space-evenly',
  gap: 4
};
