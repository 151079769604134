import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Avatar, IconButton } from '@mui/material';

import { ModalContext } from '../../../../Modal/Context/ModalContext';
import SimpleModalCard from '../../../../Modal/Cards/SimpleModalCard';
import { getProfileImages } from '../../../../../utils/images';
import { usePutUpdateUserInfoByGameMutation } from '../../../../../services/apis/organizePlayApi';
import WalletIntegration from '../../../../Wallet/ProfilePictureConnection';

import { HeaderContainer, ProfileImagesContainer, avatarStyle } from './styles';

const SelectProfileImage = ({ image }) => {
  const { game, user, isUpdatingUser } = useSelector(({ app }) => app);
  const [currentImage, setCurrentImage] = useState(image);
  const profileImages = getProfileImages(game);
  const [pfpImages, setPFPImages] = useState(profileImages);
  const { setIsLoading, closeModal } = useContext(ModalContext);
  const { metaMaskIntegration } = useFlags();

  const [updateUser, updateUserRes] = usePutUpdateUserInfoByGameMutation({
    fixedCacheKey: 'updateUserProfilePic'
  });

  useEffect(() => {
    setIsLoading(updateUserRes?.isLoading);
    if (!updateUserRes?.isLoading && updateUserRes?.isSuccess) {
      closeModal();
    }
  }, [updateUserRes]);

  useEffect(() => {
    setIsLoading(isUpdatingUser);
  }, [isUpdatingUser]);

  useEffect(() => {
    return () => {
      updateUserRes.reset();
    };
  }, []);

  const handleSave = () => {
    updateUser({
      id: user?.details?.id || '',
      gameId: game?.id || '',
      body: {
        imageUrl: currentImage
      }
    });
  };

  const setImages = nftImages => {
    setPFPImages([...nftImages, ...profileImages]);
  };

  const handleImageSelect = profileImage => {
    setCurrentImage(profileImage);
  };

  return (
    <SimpleModalCard
      id="profile-pic-selection-modal"
      data-testid="profile-pic-selection-modal"
      title={'Select Profile Image'}
      confirmButton={{
        color: 'primary',
        title: 'Save',
        onClick: handleSave
      }}
      cancelButton={{
        color: 'primary',
        title: 'Cancel'
      }}
      renderHeader={title => {
        return (
          <HeaderContainer>
            {title}
            <Avatar
              className={'selected-profile-image'}
              src={currentImage}
              sx={avatarStyle}
            />
          </HeaderContainer>
        );
      }}
    >
      {metaMaskIntegration && <WalletIntegration setImages={setImages} />}

      <ProfileImagesContainer>
        {pfpImages.map(profileImage => (
          <IconButton
            key={profileImage}
            onClick={() => handleImageSelect(profileImage)}
          >
            <Avatar
              className={`selections ${
                profileImage === currentImage ? 'selected' : ''
              }`}
              sx={avatarStyle}
            >
              <img
                src={profileImage}
                loading={'lazy'}
                width={'100%'}
                height={'100%'}
              />
            </Avatar>
          </IconButton>
        ))}
      </ProfileImagesContainer>
    </SimpleModalCard>
  );
};

export default SelectProfileImage;
