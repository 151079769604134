import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  CardButtonSelector,
  SingleCardInfoContainer,
  SingleCardMainStyle,
  SingleCardNameContainer,
  singleCardSelectionStyle
} from './styles';
import { Controller, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CardPopover from '../../../../../../components/Cards/CardPopover';
import ErrorIcon from '@mui/icons-material/Error';
import OverflowTextfield from '../../../../../../components/Shared/TextFields/Overflow';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '../../../../../../components/Shared/Tooltip';
import { getDeckErrors } from '../../../../../../utils/deck';
import { useTheme } from '@mui/material/styles';

const SingleCard = ({
  type = 'default',
  cardIndex = null,
  sectionIndex,
  checkDeckValidation,
  context
}) => {
  const { control, getValues, setValue } = context;
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  useWatch({ control: context?.control, name: 'errors' });

  const addSubtractAction = (add = false, card) => {
    card.count = card?.count + (add ? 1 : -1);
    if (card.count <= 0) {
      const section = getValues(`sections.${sectionIndex}`);
      section?.cardTracker?.delete(card.cardId);

      if (section?.cards?.length - 1 <= 0) {
        section.cards = [];
      } else {
        section?.cards?.splice(cardIndex, 1);
      }
      setValue(`sections.${sectionIndex}`, section);
      return undefined;
    }

    return card;
  };

  const cardInfo = getValues('cardInfo') || {};

  const addSub = [
    {
      key: 'subtract',
      icon: <RemoveIcon sx={{ fontSize: { xs: '18px', md: '1vw' } }} />
    },
    {
      key: 'add',
      icon: <AddIcon sx={{ fontSize: { xs: '18px', md: '1vw' } }} />
    }
  ];

  useEffect(() => {
    setOpen(false);
  }, [isMediumDown]);

  return (
    <Controller
      name={`sections.${sectionIndex}.cards.${cardIndex}`}
      control={control}
      render={({ field }) => {
        const card = field?.value;
        const image =
          cardInfo[card?.cardId]?.images?.small ||
          cardInfo[card?.cardId]?.imageUrl;
        const errors = getDeckErrors({
          errors: getValues('errors'),
          id: card?.cardId
        });

        return (
          <SingleCardMainStyle
            id={`create-deck-side-view-card-${type}-${cardIndex}-section`}
            data-testid={`create-deck-side-view-card-${type}-${cardIndex}-section`}
            ismedium={isMediumDown.toString()}
            open={open}
            error={errors}
            onClick={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            {/* Card count */}
            <Typography
              variant={'subtitle2'}
              textAlign={'start'}
              sx={{
                paddingLeft: 0,
                minWidth: '50px',
                textAlign: 'center'
              }}
            >
              x{card?.count}
            </Typography>

            {/* Card Info */}

            <SingleCardInfoContainer image={image} error={errors}>
              <SingleCardNameContainer>
                <OverflowTextfield
                  title={cardInfo[card?.cardId]?.name}
                  variant={'body2Bold'}
                />
              </SingleCardNameContainer>
              <Box
                className={'single-card-selections'}
                sx={singleCardSelectionStyle}
              >
                {/*<CardButtonSelector variant={'outlined'} color={'inherit'}>*/}
                {/*  <Typography variant={'subtitle2'} noWrap>*/}
                {/*    More Info*/}
                {/*  </Typography>*/}
                {/*</CardButtonSelector>*/}
                <CardPopover
                  type={type}
                  cardIndex={cardIndex}
                  card={cardInfo[card?.cardId]}
                />

                <Box className={'add-sub-buttons-container'}>
                  {errors && (
                    <Tooltip
                      buttonSize={'small'}
                      autoHover={false}
                      icon={
                        <ErrorIcon className={'error-icon'} color={'error'} />
                      }
                      info={errors}
                    ></Tooltip>
                  )}

                  {addSub.map(buttonType => (
                    <CardButtonSelector
                      id={`create-deck-side-view-card-${type}-${cardIndex}-${buttonType.key}-button`}
                      data-testid={`create-deck-side-view-card-${type}-${cardIndex}-${buttonType.key}-button`}
                      key={buttonType.key}
                      variant={'outlined'}
                      onClick={() => {
                        const value = addSubtractAction(
                          buttonType.key === 'add',
                          field.value
                        );
                        value && field.onChange(value);
                        checkDeckValidation && checkDeckValidation();
                      }}
                    >
                      {buttonType.icon}
                    </CardButtonSelector>
                  ))}
                </Box>
              </Box>
            </SingleCardInfoContainer>
          </SingleCardMainStyle>
        );
      }}
    />
  );
};

export default React.memo(SingleCard);
