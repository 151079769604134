import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { styled, useMediaQuery } from '@mui/material';

import NavigationButton from '../../../Swiper/Navigation/Buttons/NavigationButton';
import { Pagination } from 'swiper';
import RankingsFormatCard from '../../Card/RankingsFormatCard';
import { useTheme } from '@mui/material/styles';

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  '& .swiper-pagination .swiper-pagination-bullet-active': {
    backgroundColor: `${theme.palette.primary.main} !important`
  }
}));

const FormatsSwiperComponent = ({
  activeRankType,
  formats,
  onClick,
  seasons
}) => {
  const [selectedFormat, setSelectedFormat] = useState(0);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const breakpoints = {};
  breakpoints[theme.breakpoints.values.md] = {
    slidesPerView: 3
  };
  breakpoints[theme.breakpoints.values.sm] = {
    slidesPerView: 2
  };
  breakpoints[theme.breakpoints.values.xs] = {
    slidesPerView: 1
  };

  const getNumSlides = () => {
    if (isMedium) {
      return 2;
    } else if (isSmall) {
      return 1;
    }
    return 3;
  };

  const onLocalClick = (index, format) => {
    setSelectedFormat(index);
    onClick && onClick(format);
  };

  return (
    <StyledSwiper
      id="formats-carousel-select"
      data-testid="formats-carousel-select"
      allowTouchMove={isMedium || isSmall}
      breakpoints={breakpoints}
      style={{
        paddingBottom: '40px',
        paddingTop: '16px',
        paddingLeft: '5%',
        paddingRight: '5%'
      }}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      spaceBetween={20}
      slideToClickedSlide={true}
    >
      {formats.length > getNumSlides() && (
        <>
          <NavigationButton
            id="formats-carousel-prev-button"
            data-testid="formats-carousel-prev-button"
            prev
          />
          <NavigationButton
            id="formats-carousel-next-button"
            data-testid="formats-carousel-next-button"
          />
        </>
      )}
      {activeRankType === 'all'
        ? formats.map((format, index) => (
            <SwiperSlide key={index}>
              <RankingsFormatCard
                format={format}
                index={index}
                selected={index === selectedFormat}
                onClick={() => onLocalClick(index, format)}
              />
            </SwiperSlide>
          ))
        : seasons.map((season, index) => (
            <SwiperSlide key={index}>
              <RankingsFormatCard
                format={season}
                index={index}
                selected={index === selectedFormat}
                onClick={() => onLocalClick(index, season)}
              />
            </SwiperSlide>
          ))}
      {}
    </StyledSwiper>
  );
};

export default FormatsSwiperComponent;
