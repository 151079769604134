import React, { useContext } from 'react';

import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import OrderDetails from '../OrderDetails/index';
import SimpleModalCard from '../../../../components/Modal/Cards/SimpleModalCard';

const OrderDetailsModal = ({ orderId }) => {
  const { closeModal } = useContext(ModalContext);
  const onCloseClick = () => {
    closeModal();
  };
  return (
    <SimpleModalCard
      title="Order Details"
      showClose
      onClose={onCloseClick}
      hideConfirmButton
      cancelButton={{
        id: 'order-details-cancel-button',
        title: 'Close'
      }}
    >
      <OrderDetails orderId={orderId} isModal onClose={onCloseClick} />
    </SimpleModalCard>
  );
};

export default OrderDetailsModal;
