import { Box, Grid, Typography } from '@mui/material';

import GeneralContainer from '../../../../components/Shared/Cards/GeneralCard';
import ProductStatus from './ProductStatus';
import React from 'react';
import ResponsiveImage from '../../../../components/Shared/Images/ResponsiveImage';
import { RewardContainer } from './styles';
import RewardDetailsModal from '../RewardDetailsModal';
import StoreCurrency from '../../../../components/Shared/StoreCurrency';
import { get, kebabCase } from 'lodash';
import { getPrice } from '../../../../utils/products/getPrice';
import { isProductSoldOut } from '../../../../utils/products/isProductSoldOut';
import testImage from '../../../../assets/test.png';
import { useSelector } from 'react-redux';

const RewardCard = ({ product, currencies, addModal }) => {
  const { selectedStoreCurrency } = useSelector(({ app }) => app);

  const price = getPrice(
    get(product, 'inventory[0].productPrices', []),
    currencies,
    selectedStoreCurrency
  );

  const imageUrl = get(
    product,
    'inventory[0].resourceImages[0].image.url',
    testImage
  );

  const isSoldOut = isProductSoldOut(get(product, 'inventory', []));

  const openProductDetailsModal = () => {
    addModal({
      children: <RewardDetailsModal productId={product.id} />
    });
  };

  return (
    <GeneralContainer
      paperStyle={theme => ({
        border: `${theme.border.borderWidth}px solid ${theme.border.borderColor}`,
        overflow: 'hidden'
      })}
      disabled={isSoldOut}
      onClick={openProductDetailsModal}
    >
      <RewardContainer
        disableFocusRipple={true}
        disableRipple={true}
        color={'inherit'}
        component={'div'}
      >
        <ResponsiveImage src={imageUrl} paddingTop={'0'} />
        <Box className={'reward-info-container'}>
          <Typography
            variant="h6"
            className="reward-listing-product-name"
            data-testid={`reward-product-${kebabCase(product?.name)
              .replaceAll(' ', '')
              .toLowerCase()}`}
          >
            {product?.name}
          </Typography>

          {price && (
            <Grid
              container
              justifyContent={'flex-start'}
              className={'reward-card-limit-points-container'}
            >
              <Grid
                item
                data-testid={`reward-product-${kebabCase(product?.name)
                  .replaceAll(' ', '')
                  .toLowerCase()}-price`}
              >
                <StoreCurrency
                  {...price}
                  fiatPrice={get(price, 'productPrice.fiatPrice', null)}
                  color="primary.main"
                  textAlign="right"
                  variant="body1"
                  showInSingleLine
                  containerProps={{
                    justifyContent: 'flex-end',
                    sx: { pointerEvents: 'none' }
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </RewardContainer>

      <ProductStatus isSoldOut={isSoldOut} />
    </GeneralContainer>
  );
};

export default React.memo(RewardCard);
