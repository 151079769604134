import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Loading from '../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import SingleStoreHeader from './components/StoreHeader';
import StoreEdit from './StoreEdit';
import { get } from 'lodash';
import { useGetEntityByIdQuery } from '../../../services/apis/organizePlayApi';
import { useRefetchUserInfo } from '../../../hooks/user/useRefetchUserInfo';
import { useSelector } from 'react-redux';

const StoreManagePage = () => {
  const { storeId } = useParams();
  const { game } = useSelector(({ app }) => app);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refresh = queryParams.get('refresh');
  const { update: updateUserInfo } = useRefetchUserInfo();
  const {
    data: entityDetails,
    isLoading,
    isFetching,
    refetch
  } = useGetEntityByIdQuery(
    { storeId, gameId: game?.id },
    {
      skip: !game?.id || !storeId
    }
  );

  const onStoreUpdateSuccess = () => {
    refetch();
  };

  useEffect(() => {
    if (refresh == '1') {
      updateUserInfo();
    }
  }, [refresh]);

  return (
    <Box position="relative">
      <SingleStoreHeader storeName="Store manage" />
      <MaxWidthContainer className={'p-percent p-top'} container>
        <Grid container>
          <Grid item xs={12} textAlign="center" mb={4}>
            <Typography variant="h1" data-testid="manage-store-title">
              Manage my store
            </Typography>
          </Grid>
          {isLoading || isFetching ? (
            <Loading />
          ) : (
            <Grid item xs={12}>
              <StoreEdit
                storeData={get(entityDetails, 'data')}
                gameId={get(game, 'id')}
                onStoreUpdateSuccess={onStoreUpdateSuccess}
              />
            </Grid>
          )}
        </Grid>
      </MaxWidthContainer>
    </Box>
  );
};

export default StoreManagePage;
