import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import { Box, Grid, Link, Typography } from '@mui/material';
import { NA } from '../../../../assets/strings';

const EventInfo = ({
  date,
  fontSize = 'body3',
  gap = 0.5,
  organizationName,
  location,
  locationLink = '',
  playersInfo = ''
}) => {
  const eventInfo = [
    {
      icon: <CalendarTodayIcon />,
      value: date,
      show: true
    },
    {
      icon: <BusinessIcon />,
      value: organizationName,
      show: true
    },
    {
      icon: <LocationOnIcon />,
      value: location,
      link: locationLink,
      show: true
    },
    {
      icon: <GroupIcon />,
      value: playersInfo,
      show: playersInfo !== ''
    }
  ].filter(obj => obj.show);

  const createLink = obj => (
    <Link
      target="_blank"
      rel="noreferrer"
      onClick={e => e.stopPropagation()}
      href={locationLink}
      underline={'hover'}
      color={'inherit'}
      variant={fontSize}
      sx={{ display: 'flex', alignItems: 'center', gap, width: 'fit-content' }}
    >
      {obj.icon}
      <Typography ml={1} variant={fontSize}>
        {obj.value}
      </Typography>
    </Link>
  );

  return (
    <>
      {eventInfo.map((obj, index) => {
        return (
          <Grid item key={index} xs={12}>
            {obj.link && obj.link !== NA ? (
              createLink(obj)
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap,
                  width: 'fit-content'
                }}
              >
                {obj.icon}
                <Typography ml={1} variant={fontSize}>
                  {obj.value}
                </Typography>
              </Box>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default EventInfo;
