import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useContext } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { ModalContext } from '../../Context/ModalContext';
import { StickyComponent } from './styles';
import { kebabCase } from 'lodash';

export const CloseContainer = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 99;
`;

const SimpleModalCard = ({
  title = '',
  renderHeader,
  confirmButton = {},
  cancelButton = {},
  actionContainerProps = {},
  showClose = false,
  onClose = () => {},
  isCloseDisabled = false,
  hideActionButtons = false,
  hideCancelButton = false,
  hideConfirmButton = false,
  titleStyles = {},
  ...restProps
}) => {
  const { closeModal, isLoading } = useContext(ModalContext);
  const minWidth = '150px';
  const theme = useTheme();

  const cancelAction = () => {
    if (!isLoading) {
      closeModal();
    }
  };

  const createHeader = () => {
    const defaultTitle = (
      <Typography
        data-testid={`${kebabCase(title)
          .replaceAll(' ', '')
          .toLowerCase()}-modal-title`}
        noWrap
        sx={{ typography: { xs: 'h5', sm: 'h3' }, ...titleStyles }}
        align="center"
      >
        {title}
      </Typography>
    );

    return renderHeader ? renderHeader(defaultTitle) : defaultTitle;
  };
  return (
    <Grid container>
      <Grid justifyContent={'center'} alignItems={'center'} container>
        {/* close button */}
        {showClose && (
          <CloseContainer>
            <IconButton
              id="modal-close-button"
              data-testid="modal-close-button"
              aria-label="close"
              onClick={onClose}
              disabled={isCloseDisabled}
              color={theme.palette.primary.contrastText}
            >
              <CloseIcon />
            </IconButton>
          </CloseContainer>
        )}
        {/* Title */}
        <StickyComponent className={'header'} xs={12} item>
          {createHeader()}
        </StickyComponent>

        {/* Body */}
        <Grid item xs={12} className={'body'} sx={{ paddingY: 1 }}>
          {restProps.children}
        </Grid>

        {/* Footer */}
        {!hideActionButtons && (
          <StickyComponent className={'footer'} item xs={12}>
            <Grid
              container
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
              spacing={1}
              {...actionContainerProps}
            >
              {!hideConfirmButton && (
                <Grid item>
                  <LoadingButton
                    loading={isLoading}
                    variant={'contained'}
                    {...confirmButton}
                    sx={{ minWidth }}
                    data-testid={`${kebabCase(title)
                      .replaceAll(' ', '')
                      .toLowerCase()}-continue-button`}
                  >
                    {confirmButton.title || 'Confirm'}
                  </LoadingButton>
                </Grid>
              )}
              {!hideCancelButton && (
                <Grid item>
                  <Button
                    disabled={isLoading}
                    onClick={cancelAction}
                    {...cancelButton}
                    sx={{ minWidth }}
                    data-testid={`${kebabCase(title)
                      .replaceAll(' ', '')
                      .toLowerCase()}-cancel-button`}
                  >
                    {cancelButton.title || 'Cancel'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </StickyComponent>
        )}
      </Grid>
    </Grid>
  );
};

export default SimpleModalCard;
