import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EmptyPageLoader from '../../../components/Shared/EmptyPageLoader';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useCustomNavigation } from '../../../hooks/navigation/useCustomNavigation';
import { usePostUpdatePayoutAccountMutation } from '../../../services/apis/organizePlayApi';
import { useSelector } from 'react-redux';

const PayoutReturnPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { game } = useSelector(({ app }) => app);
  const queryParams = new URLSearchParams(location.search);
  const entityId = queryParams.get('entity');
  const organizationId = queryParams.get('organization');
  const { buildSubPath } = useCustomNavigation();
  const [
    updateAccount,
    updateAccountResult
  ] = usePostUpdatePayoutAccountMutation();

  useEffect(() => {
    if (organizationId && game) {
      updateAccount({
        gameId: game._id,
        organizationId
      });
    }
  }, [organizationId, game]);

  useEffect(() => {
    if (!updateAccountResult.isLoading) {
      if (updateAccountResult.isSuccess) {
        const isPayoutEnabled = get(
          updateAccountResult,
          'data.data.payoutEnabled',
          false
        );
        const isComplete = get(
          updateAccountResult,
          'data.data.metaData.payoutAccount.details_submitted',
          false
        );
        if (isPayoutEnabled && isComplete) {
          toast.success('Account verified successfully');
        } else {
          toast.warning('Onboarding not completed yet!');
        }
        navigate(buildSubPath(`/stores/${entityId}/manage?refresh=1`));
      } else if (updateAccountResult.isError) {
        toast.error(
          get(
            updateAccountResult,
            'error.data.errorMessage',
            'Error while placing order'
          )
        );
        navigate(buildSubPath(`/stores/${entityId}/manage`));
      }
    }
  }, [updateAccountResult]);

  return (
    <EmptyPageLoader
      mainText="Please wait while we verify account details"
      subtitle="Please do not refresh of press back!"
    />
  );
};

export default PayoutReturnPage;
