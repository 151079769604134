import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import OverflowTextfield from '../../../../../../../../components/Shared/TextFields/Overflow';

const StackView = ({ cardValue, cardInfo }) => {
  return (
    <Grid
      container
      gap={1}
      sx={{
        height: '100%',
        alignItems: 'flex-start'
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          alignItems={'center'}
          spacing={0}
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
        >
          <Grid item>
            <OverflowTextfield
              className={'card-name'}
              title={cardInfo[cardValue?.cardId]?.name}
            />
          </Grid>
          <Grid item>
            <Typography color={'primary'} variant={'body2'}>
              ({cardValue?.count || 0})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} height={'100%'}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'grid' }}>
            {Array(cardValue?.count)
              .fill(0)
              .map((placeholder, index) => (
                <img
                  className={'card-image'}
                  loading={'lazy'}
                  key={index}
                  src={
                    cardInfo[cardValue?.cardId]?.images?.small ||
                    cardInfo[cardValue?.cardId]?.imageUrl
                  }
                  style={{
                    gridColumn: 1,
                    gridRow: 1,
                    aspectRatio: 2.5 / 3.5,
                    borderRadius: '16px',
                    marginTop: `${20 * index}%`
                  }}
                  width={'100%'}
                  height={'auto'}
                />
              ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StackView;
