import React from 'react';
import CardsMainPage from './index';
import { Route } from 'react-router-dom';
import AllCards from './Pages/AllCards';

const CardRoutes = () => {
  return (
    <Route path={'cards/*'} element={<CardsMainPage />}>
      <Route index element={<AllCards />} />
    </Route>
  );
};

export default CardRoutes;
