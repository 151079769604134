import { Box, Button, styled } from '@mui/material';

const userImageSize = { xs: 115, sm: 150 };

export const ProfileImageButton = styled(Button)(({ theme }) => ({
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  ['& > *']: {
    ['&:not(.MuiTouchRipple-root)']: {
      gridColumn: 1,
      gridRow: 1
    }
  },
  width: '100%',
  height: '100%',
  transform: 'rotateY(0deg) rotate(-35deg)',
  border: `2px solid ${theme.palette.background.default}`,
  ['img']: {
    borderRadius: '50%',
    width: '100%',
    height: 'auto'
  },
  ['&.disable']: {
    pointerEvents: 'none'
  }
}));

export const EditProfileContainer = styled(Box)(() => ({
  borderRadius: '50%',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  opacity: 0,
  gap: 0.1,
  transition: 'opacity 0.1s ease-in-out',
  [':hover']: {
    background: '#0009',
    opacity: 1
  },
  zIndex: 1
}));

export const editProfileImageMainContainerStyle = theme => ({
  height: userImageSize,
  width: userImageSize,
  overflow: 'hidden',
  border: '6px solid transparent',
  borderWidth: { xs: '6px' },
  boxShadow: {
    xs: `0px 0px 0px 10px ${theme.palette.background.default}`
  },
  borderRadius: '50%',
  transform: 'rotateY(0deg) rotate(35deg)',
  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.background.default} 35%, ${theme.palette.primary.main})`
});

export const HeaderRankImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: '10%',
  bottom: 0,
  pointerEvents: 'none',
  height: '50px',
  width: '50px',
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    width: '40px'
  }
}));
