import React from 'react';
import { Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { get, isEmpty, isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import {
  EditProfileContainer,
  editProfileImageMainContainerStyle,
  HeaderRankImage,
  ProfileImageButton
} from './styles';

const ProfileImageNew = ({ onClick }) => {
  const { user } = useSelector(({ app }) => app);
  const rankIcon = get(user, 'details.gameUser.rankIcon', '');
  const userImage = get(user, 'details.gameUser.imageUrl');
  return (
    <Box className={'profile-pic'} sx={{ position: 'relative' }}>
      <Box sx={editProfileImageMainContainerStyle}>
        <ProfileImageButton
          onClick={onClick}
          className={`${!isFunction(onClick) && 'disable'}`}
        >
          <img src={userImage} />
          <EditProfileContainer className={'edit-profile-container'}>
            <EditIcon />
            <Typography>Edit</Typography>
          </EditProfileContainer>
        </ProfileImageButton>
      </Box>
      {!isEmpty(rankIcon) && <HeaderRankImage src={rankIcon} />}
    </Box>
  );
};

export default ProfileImageNew;
