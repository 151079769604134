import {
  APP_INIT_FAILURE,
  APP_INIT_REQUEST,
  APP_INIT_SUCCESS,
  AUTH_COMPLETE,
  AUTH_FAILURE,
  AUTH_START,
  GET_ENTITY_APPLICATIONS_REQUEST,
  GET_ENTITY_APPLICATIONS_SUCCESS,
  GET_GAME_PROFILE_PICS,
  SET_GMAP_LOADED,
  SET_STORE_CURRENCY,
  SET_USER,
  UPDATE_GAME_USER_FAILURE,
  UPDATE_GAME_USER_REQUEST,
  UPDATE_GAME_USER_SUCCESS,
  UPDATE_LOCAL_USER_IMAGE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_WALLET_FAILURE,
  UPDATE_USER_WALLET_REQUEST,
  UPDATE_USER_WALLET_SUCCESS
} from './types';

import axios from 'axios';
import { get } from 'lodash';
import { getEntityApplicationsService } from '../../services/entity';

export const initApp = slug => async (dispatch, getState) => {
  if (getState().app.game.slug !== slug.toString().toLowerCase()) {
    dispatch({
      type: APP_INIT_REQUEST
    });
    const offfset = new Date().getTimezoneOffset();
    const res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/app/init?game=${slug}&offfset=${offfset}`
    });
    if (res) {
      dispatch({
        type: APP_INIT_SUCCESS,
        game: res.data
      });
    } else {
      dispatch({
        type: APP_INIT_FAILURE
      });
    }
  }
};

export const startAuthentication = () => async dispatch => {
  dispatch({
    type: AUTH_START
  });
};

export const fetchUserData = (authToken, gameId, logout) => async dispatch => {
  const loginErrorAction = (path = '') => {
    dispatch({
      type: AUTH_FAILURE
    });
    logout(path);
  };
  try {
    const res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/app/user?gameId=${gameId}`,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (res && !res?.data?.errorMessage) {
      dispatch({
        type: SET_USER,
        user: res.data.data
      });
      dispatch({
        type: AUTH_COMPLETE
      });
    } else {
      const errorMessage = get(res, 'data.errorMessage', '');
      if (
        errorMessage.includes(
          'Bad Request: We found an existing account with that email and have merged them. Please log in again!'
        )
      ) {
        loginErrorAction(
          `/login?error=${'We found an existing account with that email and have merged them. Please log in again!'.replace(
            / /g,
            '+'
          )}`
        );
      } else {
        loginErrorAction();
      }
    }
  } catch (e) {
    loginErrorAction();
  }
};

export const refetchUserData = () => async (dispatch, getState) => {
  const currentState = getState();
  const authToken = currentState.auth.token;
  const gameId = currentState.app.game.id;
  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/app/user?gameId=${gameId}`,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
  if (res && !res?.data?.errorMessage) {
    dispatch({
      type: SET_USER,
      user: res.data.data
    });
    dispatch({
      type: AUTH_COMPLETE
    });
  }
};

export const fetchGameProfilePics = images => async dispatch => {
  dispatch({
    type: GET_GAME_PROFILE_PICS,
    profilePics: images
  });
};

export const updateUser = (userId, body) => async dispatch => {
  dispatch({
    type: UPDATE_USER_REQUEST
  });
  const res = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/app/users/${userId}`,
    data: body
  });
  if (res) {
    dispatch({
      type: UPDATE_USER_SUCCESS,
      newUser: res.data.data
    });
  } else {
    dispatch({
      type: UPDATE_USER_FAILURE
    });
  }
};

export const updateGameUser = (userId, gameId, body) => async dispatch => {
  dispatch({
    type: UPDATE_GAME_USER_REQUEST
  });
  const res = await axios({
    method: 'put',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/app/users/${userId}/game/${gameId}`,
    data: body
  });
  if (res) {
    dispatch({
      type: UPDATE_GAME_USER_SUCCESS,
      newGameUser: res.data.data
    });
  } else {
    dispatch({
      type: UPDATE_GAME_USER_FAILURE
    });
  }
};

export const updateLocalUserImage = body => async dispatch => {
  dispatch({
    type: UPDATE_LOCAL_USER_IMAGE,
    localUserImage: body
  });
};

export const getEntityApplications = (gameId, userId) => async dispatch => {
  dispatch({
    type: GET_ENTITY_APPLICATIONS_REQUEST
  });

  const res = await getEntityApplicationsService(gameId, userId);

  if (res) {
    dispatch({
      type: GET_ENTITY_APPLICATIONS_SUCCESS,
      lgsApplications: res.data
    });
    dispatch(refetchUserData());
  } else {
    dispatch({
      type: UPDATE_USER_FAILURE
    });
  }
};

export const setGmapLoaded = body => async dispatch => {
  dispatch({
    type: SET_GMAP_LOADED,
    status: body
  });
};

export const setStoreCurrency = body => async dispatch => {
  dispatch({
    type: SET_STORE_CURRENCY,
    currency: body
  });
};

export const updateUserWallets = (authToken, gameId) => async dispatch => {
  dispatch({
    type: UPDATE_USER_WALLET_REQUEST
  });
  const res = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MIDDLEWARE_URL}/rewards/wallets/`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'game-id': gameId
    }
  });
  if (res) {
    dispatch({
      type: UPDATE_USER_WALLET_SUCCESS,
      wallets: res.data.data.data
    });
  } else {
    dispatch({
      type: UPDATE_USER_WALLET_FAILURE
    });
  }
};
