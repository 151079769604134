import { defaultAddress } from './location';

export const defaultUserInfo = {
  profile: {
    firstName: '',
    lastName: '',
    email: ''
  },
  location: { ...defaultAddress },
  teamName: ''
};
