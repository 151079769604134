import React from 'react';
import { TextField } from '@mui/material';

const DeckName = ({ name, setName }) => {
  return (
    <TextField
      data-testid="decks-deck-name-input"
      value={name}
      onChange={e => setName(e.target.value)}
      label="Deck Name"
    />
  );
};

export default DeckName;
