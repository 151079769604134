import React, { useContext, useMemo } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Legend,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  Tooltip
} from 'recharts';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from '@mui/material';

import ChartWrapper from '../ChartWrapper';
import { MetricsContext } from '..';
import { useCardsCategory } from '../../hooks/useCardsCategory';
import { getMetric } from '../utils';
import CustomTooltip from '../CustomTooltip';

const CardTypeChart = () => {
  const { game } = useSelector(({ app }) => app);
  const { deck } = useOutletContext();
  const { getFillArray, isMobile, defaultMargin } = useContext(MetricsContext);
  const theme = useTheme();
  const cardTypes = getMetric(deck, 'cardTypes');
  const cardCategories = useCardsCategory();
  const defaultData = Array.from({ length: 3 }, () => ({
    name: '',
    quantity: 0
  }));

  const fillArray = getFillArray(Object.keys(cardTypes).length);
  const cardTypeData = useMemo(
    () =>
      Object.keys(cardTypes)
        .map((key, i) => {
          if (!cardCategories[key]) {
            return null;
          }
          return {
            name: cardCategories[key]?.name,
            quantity: cardTypes[key],
            fill: fillArray[i]
          };
        })
        .filter(Boolean),
    [cardTypes]
  );
  const total = cardTypeData.reduce((acc, curr) => acc + curr.quantity, 0);

  const legendStyle = {
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: isMobile ? 'normal' : 'nowrap',
    fontSize: isMobile ? 10 : 15
  };
  return (
    <ChartWrapper title={`${get(game, 'terminology.card', 'Card')} Type`}>
      <RadialBarChart
        cx="30%"
        cy="50%"
        innerRadius="30%"
        outerRadius="90%"
        barSize={isMobile ? 5 : 10}
        data={cardTypeData.length ? cardTypeData : defaultData}
        margin={defaultMargin}
      >
        <Tooltip
          cursor={{ stroke: theme.palette.text.primary }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <PolarAngleAxis
          type="number"
          domain={[0, total]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          minAngle={15}
          background={{ fill: `${theme.palette.text.primary}20` }}
          clockWise
          dataKey="quantity"
          angleAxisId={0}
        />
        {cardTypeData.length && (
          <Legend
            iconType="circle"
            iconSize={10}
            layout="vertical"
            verticalAlign="top"
            align="right"
            wrapperStyle={legendStyle}
          />
        )}
      </RadialBarChart>
    </ChartWrapper>
  );
};

export default CardTypeChart;
