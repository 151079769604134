import { Box, styled } from '@mui/material';

const selectedProfileImage = { xs: 75, sm: 100 };

export const avatarStyle = theme => ({
  borderRadius: '50%',
  ['&.selected-profile-image']: {
    height: selectedProfileImage,
    width: selectedProfileImage
  },
  ['&.selections']: {
    height: { xs: 65, sm: 85 },
    width: { xs: 65, sm: 85 },
    transition: 'transform 0.1s ease-in-out',
    ['&.selected']: {
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.main}`
    },
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
});

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

export const ProfileImagesContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 100px)',
  gap: theme.spacing(0.2),
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, 90px)'
  }
}));
