import { get, isEmpty } from 'lodash';

import React from 'react';
import { Typography } from '@mui/material';
import { dayjsWithPlugins as dayjs } from '../../../utils/dayjs';

const DeckLastUpdatedStatus = ({ deck = {} }) => {
  const getLastUpdatedText = () => {
    const oneMonthPast = dayjs().subtract(1, 'month');
    const lastUpdateTime = dayjs(
      get(
        deck,
        'metaData.updatedAt',
        get(deck, 'metaData.createdAt'),
        new Date()
      )
    );
    if (lastUpdateTime.isBefore(oneMonthPast)) {
      return `Last updated on ${lastUpdateTime.format('MM/DD/YYYY hh:mm a')}`;
    }
    return `Last updated ${lastUpdateTime.fromNow()}`;
  };
  if (isEmpty(deck)) {
    return null;
  }
  return <Typography variant={'caption'}>{getLastUpdatedText()}</Typography>;
};
export default DeckLastUpdatedStatus;
