import { get } from 'lodash';

export const createFormatByIdMap = formats =>
  formats?.reduce((prev, current) => {
    return { ...prev, [current._id]: current };
  }, {}) || {};

export const getGameContactEmail = game => {
  const gameLink = get(game, 'layout.footer.links[0].url', null);
  if (!gameLink) {
    return '';
  }
  const gameHostname = new URL(gameLink).hostname;
  const domain = gameHostname
    .split('.')
    .slice(-2)
    .join('.');
  return `contact@${domain}`;
};
