import { Box, Checkbox, Grid, Typography, styled } from '@mui/material';

import React from 'react';
import { getFormattedCurrency } from '../../../utils/number';

// eslint-disable-next-line no-unused-vars
const ShippingOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));

// eslint-disable-next-line no-unused-vars
const ShippingOptionInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  cursor: 'pointer'
}));

const ShippingMethodOption = ({ shippingMethod, isSelected, onSelect }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ShippingOptionContainer>
          <Box>
            <Checkbox
              data-testid={`shipping-method-${shippingMethod.value}-chkbx`}
              checked={isSelected}
              onChange={() => onSelect(shippingMethod.value)}
            />
          </Box>
          <ShippingOptionInfoContainer
            onClick={() => onSelect(shippingMethod.value)}
          >
            <Typography
              variant="body2Light"
              data-testid={`shipping-method-${shippingMethod.value}-label`}
            >
              {shippingMethod.label}
            </Typography>
            <Typography
              variant="body2Bold"
              color="primary"
              data-testid={`shipping-method-${shippingMethod.value}-cost`}
            >
              {getFormattedCurrency(shippingMethod.amount)}
            </Typography>
          </ShippingOptionInfoContainer>
        </ShippingOptionContainer>
      </Grid>
    </Grid>
  );
};

export default ShippingMethodOption;
