import { get, isEmpty } from 'lodash';

export const getUserFullName = userInfo => {
  return `${get(userInfo, 'profile.firstName', '')} ${get(
    userInfo,
    'profile.lastName',
    ''
  )}`.trim();
};

export const isUserShippingAddressValid = user => {
  if (!user) return false;
  const firstName = get(user, 'details.info.firstName');
  const address1 = get(user, 'details.location.address1');
  const zip = get(user, 'details.location.zip');
  return !(isEmpty(firstName) || isEmpty(address1) || isEmpty(zip));
};
