import { Chip } from '@mui/material';
import { OrderStatuses } from '../../../../utils/order';
import React from 'react';

const OrderStatus = ({ status }) => {
  if (!status) return null;
  return (
    <Chip
      color={OrderStatuses[status].color}
      label={OrderStatuses[status].text}
    />
  );
};

export default OrderStatus;
