import Joi from 'joi';
import { phoneExp, urlExp } from '../../values/reg-exp';
import { defaultLocation } from '../../pages/Events/AddNewEventPage';

export const lgsDefault = {
  name: '',
  legalName: '',
  imageUrl: '',
  email: '',
  phoneNumber: '',
  website: '',
  location: defaultLocation,
  address2: '',
  relationship: ''
  // relationshipDescription: ''
};

export const lgsSchema = Joi.object({
  name: Joi.string()
    .required()
    .default(''),
  legalName: Joi.string()
    .required()
    .default(''),
  imageUrl: Joi.string()
    .required()
    .allow(''),
  email: Joi.string()
    .required()
    .default(''),
  phoneNumber: Joi.string()
    .required()
    .pattern(phoneExp)
    .default(''),
  website: Joi.string()
    .required()
    .pattern(urlExp)
    .default(''),
  location: Joi.object({
    name: Joi.string()
      .required()
      .default(''),
    address1: Joi.string()
      .required()
      .default(''),
    address2: Joi.string()
      .allow('')
      .default(''),
    city: Joi.string()
      .required()
      .default(''),
    state: Joi.string()
      .required()
      .default(''),
    zip: Joi.string()
      .required()
      .default(''),
    country: Joi.string()
      .required()
      .default(''),
    geo: Joi.object().allow({})
  }).required(),
  address2: Joi.string().allow(''),
  relationship: Joi.string()
    .required()
    .default('')
  // relationshipDescription: Joi.when('relationship', {
  //   is: Joi.string().valid('other'),
  //   then: Joi.string().required(),
  //   otherwise: Joi.string().allow('')
  // })
}).required();
