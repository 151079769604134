import React from 'react';
import { Typography } from '@mui/material';
import { getOrdinal } from '../../../../utils/number';

const RankTypography = ({ hash = false, position = 0, variant = 'body1' }) => {
  return (
    <Typography variant={variant}>
      {hash ? hash : position}
      {getOrdinal(hash ? hash : position)}
    </Typography>
  );
};

export default RankTypography;
