import axios from 'axios';

export const createApplication = async (gameId, data) => {
  try {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/entity/apply/${gameId}`,
      data
    });
  } catch {
    return undefined;
  }
};

export const getEntityApplicationsService = async (gameId, userId) => {
  try {
    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_MIDDLEWARE_URL}/v1/entity/${gameId}/applications/${userId}`
    });
  } catch (e) {
    return undefined;
  }
};
