import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useIsLoggedIn } from '../../hooks/auth0/useLoggedIn';

import HeaderAuth from './Auth';
import Cart from './Cart';
import NavDrawer from './NavDrawer';

import {
  HeaderGridContainer,
  HeaderLogoButton,
  HeaderMainContainer,
  HeaderNavButton
} from './styles';

export const Header = () => {
  const {
    cardDatabase,
    deckBuilder,
    rankings,
    rewardStore,
    seasons,
    storeLocatorOnHeader
  } = useFlags();

  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { game } = useSelector(({ app }) => app);
  const gapBetweenItems = 2;
  const isBetweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLoggedIn = useIsLoggedIn();
  const shouldShowCart = isDownMd && isLoggedIn && rewardStore;
  // eslint-disable-next-line no-unused-vars
  const navs = [
    { title: 'Events', path: 'events', show: true },
    {
      title: 'Rankings',
      path: 'rankings',
      show: rankings
    },
    {
      title: `${get(game, 'terminology.card', 'Card')}s`,
      path: 'cards',
      show: cardDatabase
    },
    {
      title: `${get(game, 'terminology.deck', 'Deck')}s`,
      path: 'decks',
      show: deckBuilder
    },
    {
      title: 'Seasons',
      path: 'seasons',
      show: seasons
    },
    {
      title: 'Rewards',
      path: 'rewards',
      show: rewardStore
    },
    {
      title: 'Stores',
      path: 'stores',
      show: storeLocatorOnHeader
    }
  ].filter(nav => nav.show);
  const shouldHideMenu = isBetweenMdLg && navs.length > 3;

  const desktopDisplay = {
    xs: 'none',
    md: 'flex'
  };

  // eslint-disable-next-line no-unused-vars
  const createNavButtons = classes => {
    const filteredNavs = shouldHideMenu ? navs.slice(0, 2) : navs;
    return filteredNavs.map(obj => {
      const isActive = pathname.toString().match(new RegExp(`${obj.path}`));

      return (
        <HeaderNavButton
          id={`header-${obj.path}-nav-button`}
          data-testid={`header-${obj.path}-nav-button`}
          fullWidth
          className={`${isActive && 'active'} ${classes}`}
          onClick={() => navigate(obj.path)}
          key={obj.path}
        >
          <Typography
            sx={{
              color: isActive
                ? get(theme, 'colors.header.textActive')
                : get(theme, 'colors.header.textColor')
            }}
            variant="header"
          >
            {obj.title}
          </Typography>
        </HeaderNavButton>
      );
    });
  };

  const createLogo = close => (
    <HeaderLogoButton
      id={close ? 'header-logo-button-nav-drawer' : 'header-logo-button'}
      disableRipple={true}
      onClick={() => {
        close && close();
        navigate('');
      }}
    >
      <img
        src={get(game, 'images.logo')}
        style={{
          marginTop: get(game, 'layout.header.logoMarginTop', '0')
        }}
      />
    </HeaderLogoButton>
  );

  return (
    <>
      <AppBar position="absolute">
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
            padding: 0
          }}
        >
          <HeaderMainContainer
            container
            justifyContent={'space-between'}
            gap={{ xs: 5, sm: 3 }}
            sx={{
              maxWidth: { xs: '95vw', xl: 1500 },
              padding: '0 2.5vw'
            }}
          >
            {shouldShowCart && <NavDrawer logo={createLogo} navs={navs} />}
            {/*Game Logo*/}
            <Grid item>{createLogo()}</Grid>

            {/*Search & Routes Section*/}
            <HeaderGridContainer
              item
              container
              display={desktopDisplay}
              gap={0}
            >
              {/* To do: Add search functionality */}
              {/*<Grid item>*/}
              {/*  <HeaderIconButton>*/}
              {/*    <img src={SearchIcon} />*/}
              {/*  </HeaderIconButton>*/}
              {/*</Grid>*/}

              {createNavButtons().map((obj, index) => (
                <Grid item key={index}>
                  {obj}
                </Grid>
              ))}
            </HeaderGridContainer>

            {/* Login & Dark-Mode Section*/}
            <Grid
              item
              container
              gap={gapBetweenItems}
              display={desktopDisplay}
              justifyContent={'flex-end'}
              alignItems="center"
            >
              <HeaderAuth id="header-auth-section" />
              {/* Dark Mode Button Coming Soon */}
              {/*<Grid item>*/}
              {/*  <HeaderIconButton>*/}
              {/*    <img src={LightModeIcon} />*/}
              {/*  </HeaderIconButton>*/}
              {/*</Grid>*/}
            </Grid>
            {shouldShowCart && <Cart />}
            {/* NavDrawer Component */}
            {!shouldShowCart && <NavDrawer logo={createLogo} navs={navs} />}
          </HeaderMainContainer>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ position: 'unset' }} />
    </>
  );
};

export default Header;
