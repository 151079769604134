import { Box, Menu, MenuItem, Typography } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { LoadingButton } from '@mui/lab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

const DropDownMenuOptions = ({
  children,
  options = [],
  menuAction,
  // eslint-disable-next-line no-unused-vars
  isLoading = false
}) => {
  const openAction = isOpen => {
    menuAction && menuAction(isOpen);
  };

  return (
    <PopupState variant="popover" popupId="popup-menu" disableAutoFocus={true}>
      {popupState => (
        <React.Fragment>
          <Box
            {...bindTrigger(popupState)}
            onClick={e => {
              bindTrigger(popupState).onClick(e);
              openAction(true);
              e.stopPropagation();
            }}
          >
            {children ? (
              children
            ) : (
              <LoadingButton
                data-testid="more-vert-icon"
                variant={'contained'}
                loading={isLoading}
                sx={{ minWidth: 'unset', padding: 1 }}
              >
                <MoreVertIcon fontSize={'medium'} />
              </LoadingButton>
            )}
          </Box>
          {options.length > 0 && (
            <Menu
              {...bindMenu(popupState)}
              onClick={e => {
                bindMenu(popupState).onClose();
                openAction(false);
                e.stopPropagation();
              }}
            >
              {options.map(option => (
                <MenuItem
                  id={`${option.key}-option`}
                  data-testid={`${option.key}-option`}
                  sx={{ gap: 1 }}
                  key={option.key}
                  onClick={() => {
                    option?.action && option?.action();
                    popupState.close();
                  }}
                >
                  {option?.icon && option.icon}
                  <Typography color={option?.selected && 'primary'}>
                    {option.title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          )}
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default DropDownMenuOptions;
