import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { debounce, get, isEmpty, kebabCase } from 'lodash';
import { eventStatuses, playerStatus } from '../../../../values/event-values';
import {
  useGetSingleEventQuery,
  useGetSingleEventRosterQuery
} from '../../../../services/apis/organizePlayApi';

import EditIcon from '@mui/icons-material/Edit';
import GeneralContainer from '../../../../components/Shared/Cards/GeneralCard';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import Loader from '../../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../../components/Shared/Grid/styles';
import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import { RosterStatus } from '../../../../components/Events/RosterStatus';
import { StyledSearchTextField } from '../../../../components/Shared/TextFields';
import { TubeCard } from '../../../../components/Shared/Cards/styles';
import UpdateRegisteredPlayer from '../../../../components/Events/Shared/ModalPages/UpdateRegisteredPlayer';
import { singleEventInfo } from '../../../../utils/EventUtils';
import { useParams } from 'react-router-dom';
import { usePrevious } from '../../../../utils/usePrevious';
import { useSelector } from 'react-redux';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  limit: 25
};

export const EventRosterPage = () => {
  // React Router Dom Hooks
  const { eventId } = useParams();
  const { game, user } = useSelector(({ app }) => app);
  const [searchText, setSearchText] = useState('');
  const [rosters, setRosters] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState({});
  const [currentPaginationData, setCurrentPaginationData] = useState(
    DEFAULT_PAGINATION_OPTIONS
  );
  const previousSearchInput = usePrevious(searchInput);

  const resetPagination = () => {
    setCurrentPaginationData(DEFAULT_PAGINATION_OPTIONS);
  };

  // Context Hooks
  const { addModal } = useContext(ModalContext);

  // Redux Hooks
  const {
    data: activeEvent,
    // eslint-disable-next-line no-unused-vars
    error: activeEventError,
    // eslint-disable-next-line no-unused-vars
    isLoading: activeEventIsLoading
  } = useGetSingleEventQuery(eventId);

  useEffect(() => {
    setSearchQuery({
      ...currentPaginationData,
      searchText,
      eventId
    });
  }, [currentPaginationData, searchText, eventId]);

  const { data, isLoading, isFetching } = useGetSingleEventRosterQuery(
    searchQuery,
    {
      refetchOnMountOrArgChange: true,
      skip: isEmpty(searchQuery)
    }
  );

  // eslint-disable-next-line no-unused-vars
  const { activePage, totalPages, totalResults } = get(data, 'pagination', {});

  const searchByText = searchTextValue => {
    resetPagination();
    setSearchText(searchTextValue);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => searchByText(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput]);

  useEffect(() => {
    const { activePage } = get(data, 'pagination', 1);
    const participants = get(data, 'data.participants', []);
    if (!isEmpty(data)) {
      if (activePage === 1) {
        setRosters(participants);
      } else {
        setRosters(prevState => [...prevState, ...participants]);
      }
    } else {
      setRosters([]);
    }
  }, [data]);

  const {
    status,
    isOrganizer,
    currentRound,
    regularRounds,
    isJudge
  } = singleEventInfo(activeEvent);

  const startedAndNotComplete =
    status === 'active' || status === 'started' || status === 'playoffs';

  const cancelledOrComplete = status === 'cancelled' || status === 'complete';

  // Used to check if the user is an organizer and is able to edit the roster.
  const [canEditEvent, setCanEditEvent] = useState(false);

  // Function that saves the input
  const handleSearch = e => {
    const eventTarget = e.target.value;
    setSearchInput(eventTarget);
  };

  const canViewDeckDetails = participant => {
    return (
      isOrganizer || isJudge || participant.userId === get(user, 'details.id')
    );
  };

  const canEditSelf = participant => {
    return (
      !canEditEvent &&
      !participantDropped(participant) &&
      participant.userId === get(user, 'details.id')
    );
  };

  // Open the modal and set the selected participant
  const handleOpen = participant => {
    if (canEditEvent) {
      addModal({
        children: <UpdateRegisteredPlayer user={participant} />
      });
    } else if (
      !startedAndNotComplete &&
      !canEditEvent &&
      canEditSelf(participant)
    ) {
      addModal({
        children: (
          <UpdateRegisteredPlayer
            type={'unregister'}
            title={'Unregister'}
            canEditSelf={canEditSelf(participant)}
            user={participant}
          />
        )
      });
    } else if (canEditSelf(participant) && startedAndNotComplete) {
      addModal({
        children: (
          <UpdateRegisteredPlayer
            title={'Are you sure you want to Drop?'}
            user={participant}
            canEditSelf={canEditSelf(participant)}
          />
        )
      });
    }
  };

  useEffect(() => {
    setCanEditEvent(
      (isOrganizer || isJudge) &&
        status !== eventStatuses.complete.key &&
        currentRound <= regularRounds
    );
  }, [activeEvent]);

  const participantDropped = participant => {
    return participant.status === 'dropped';
  };

  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(data, 'pagination', {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    });
    if (!isLoading && !isFetching && activePage < totalPages) {
      setCurrentPaginationData(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  return (
    <MaxWidthContainer>
      <StyledSearchTextField
        id="event-roster-search-input"
        onChange={handleSearch}
        value={searchInput}
      />
      {/* Roster CustomTable */}
      <InfiniteScroll
        done={activePage >= totalPages || !data}
        emptyMessage={
          searchText
            ? `No ${get(
                game,
                'terminology.players',
                'Players'
              )} found for the search term`
            : `No ${get(game, 'terminology.players', 'Players')} Yet`
        }
        isLoading={isLoading || isFetching}
        loader={isLoading || isFetching ? <Loader /> : null}
        loaderAction={loadAction}
        shouldScrollToTop={false}
        emptyResultContainerStyle={{
          position: 'relative',
          minHeight: '50vh'
        }}
      >
        {rosters && rosters.length
          ? rosters.map(participant => {
              return (
                <Box key={participant.id} mt={3}>
                  <GeneralContainer
                    id={`event-roster-${kebabCase(
                      participant.firstName
                    )}-${kebabCase(participant.lastName)}-card-button`}
                    onClick={
                      canEditEvent
                        ? () => handleOpen(participant)
                        : canEditSelf(participant) && !cancelledOrComplete
                        ? () => handleOpen(participant)
                        : null
                    }
                    buttonStyle={{ padding: '16px 24px' }}
                    key={participant.id}
                  >
                    <Grid
                      container
                      alignItems={'center'}
                      justifyContent={{ xs: 'center', sm: 'space-between' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      wrap={'nowrap'}
                      rowSpacing={2}
                      columnSpacing={2}
                    >
                      <RosterStatus
                        eventId={eventId}
                        isOrganizer={isOrganizer}
                        showDeckDetails={canViewDeckDetails(participant)}
                        status={status}
                        user={participant}
                        shouldCenterTeamName={true}
                        centerBreakpointTeamName="sm"
                      />
                      <Grid item>
                        <Grid
                          container
                          justifyContent={{ xs: 'center', sm: 'flex-end' }}
                        >
                          <Grid item>
                            <TubeCard
                              sx={{
                                minWidth: '150px',
                                px:
                                  canEditSelf(participant) &&
                                  !cancelledOrComplete
                                    ? '16px'
                                    : undefined,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              status={participant.status}
                            >
                              <Typography
                                variant="h5"
                                noWrap
                                sx={{
                                  textTransform: 'uppercase',
                                  width: 'auto',
                                  mx: 1
                                }}
                              >
                                {get(playerStatus[participant.status], 'title')}
                              </Typography>
                              {canEditSelf(participant) &&
                                !cancelledOrComplete && (
                                  <EditIcon
                                    sx={{
                                      backgroundColor: 'none',
                                      width: '1.2rem'
                                    }}
                                  />
                                )}
                            </TubeCard>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </GeneralContainer>
                </Box>
              );
            })
          : null}
      </InfiniteScroll>
    </MaxWidthContainer>
  );
};

export default EventRosterPage;
