import { get } from 'lodash';

export const createAddress = (address, addressFormat) => {
  const tempAddress = { ...addressFormat };

  Object.keys(tempAddress).forEach(key => {
    tempAddress[key] = get(address, key, tempAddress[key]);
  });

  return tempAddress;
};
