export const isProductSoldOut = inventory => {
  let soldoutQty = 0;
  inventory?.forEach(singleInventory => {
    if (singleInventory?.qtyAvailable <= 0) soldoutQty++;
  });

  if (soldoutQty === inventory.length) return true;

  return false;
};
