import { Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomTable from '../../components/Shared/CustomTable';
import { EventCard } from '../../components/Events/Cards/EventCard';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

export const LandingExplore = () => {
  const { game } = useSelector(({ app }) => app);

  const events = get(game, 'events.explore', []);

  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  return (
    <MaxWidthContainer
      id="landing-page-explore-container"
      data-testid="landing-page-explore-container"
      container
      className={'p-percent'}
    >
      <Grid container>
        <Grid item mt={{ xs: 2, md: 8 }} xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography sx={{ mb: 5 }} variant="h2">
                Explore Events
              </Typography>
            </Grid>

            <Grid item>
              <Button
                id="see-all-events-button"
                data-testid="see-all-events-button"
                variant={'contained'}
                onClick={() => navigate('events')}
              >
                See All
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        id="explore-events-container"
        data-testid="explore-events-container"
        container
      >
        <CustomTable
          state={''}
          search={false}
          items={events}
          component={(event, idx) => (
            <td>
              <EventCard
                id={`explore-events-card-button-${idx}`}
                key={event._id}
                event={event}
              />
            </td>
          )}
        />
      </Grid>
    </MaxWidthContainer>
  );
};

export default LandingExplore;
