/* eslint-disable no-unused-vars */
import { Box, Divider, Typography, styled } from '@mui/material';
import {
  getFormattedCurrency,
  getUserReadableCurrencyAmount
} from '../../../utils/number';

import React from 'react';
import { isEmpty, kebabCase } from 'lodash';

const StyledPaymentBreakdownContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '8px 16px',
  borderRadius: '8px',
  marginTop: '16px',
  marginBottom: '16px'
}));

const PaymentBreakdown = ({ breakdown = null }) => {
  const renderPaymentInfo = (title, value, isTitleBold = false) => {
    if (!value) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="space-between" marginY={1}>
        <Typography
          variant={isTitleBold ? 'body1' : 'body1Light'}
          color={isTitleBold ? 'primary' : 'initial'}
        >
          {title}
        </Typography>
        <Typography
          data-testid={`${kebabCase(title)
            .replace(' ', '')
            .toLowerCase()}-cost`}
          variant={isTitleBold ? 'body1' : 'body1Light'}
          color="primary"
        >
          {getFormattedCurrency(getUserReadableCurrencyAmount(value), 'USD')}
        </Typography>
      </Box>
    );
  };
  if (!breakdown || isEmpty(breakdown)) {
    return null;
  }
  return (
    <StyledPaymentBreakdownContainer>
      {renderPaymentInfo('Cart Total', breakdown.cartTotal)}
      {renderPaymentInfo('Shipping', breakdown.shipping)}
      {renderPaymentInfo('Taxes', breakdown.taxes)}
      <Divider />
      {renderPaymentInfo('Total Payable', breakdown.total, true)}
    </StyledPaymentBreakdownContainer>
  );
};

export default PaymentBreakdown;
