export const filterContainerStyle = ({
  offsetTop,
  logoPaddingTop,
  breakpointOn,
  theme
}) => ({
  gridRow: 1,
  gridColumn: 1,
  position: 'sticky',
  left: 0,
  top: offsetTop,
  height: 'fit-content',
  background: theme.palette.background.default,
  padding: 1,
  paddingTop: logoPaddingTop ? 4 : 1,
  width: 'fit-content',
  zIndex: 1,
  ...(breakpointOn && {
    width: '100%',
    ...(logoPaddingTop && {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 1
    })
  })
});

export const drawerContainerStyle = ({
  breakpointOn,
  logoPaddingTop,
  filterProps
}) => ({
  padding: breakpointOn ? 4 : 2,
  ...(((logoPaddingTop && breakpointOn) ||
    (logoPaddingTop && !filterProps)) && {
    paddingTop: 7
  })
});
