import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { has } from 'lodash';

const FormTextfield = ({ formMethods, name, ...props }) => (
  <Controller
    name={name}
    control={formMethods.control}
    render={({ field, formState }) => {
      return (
        <TextField
          {...field}
          {...props}
          error={has(formState, 'errors.' + name)}
        />
      );
    }}
  />
);

export default FormTextfield;
