import React from 'react';

function IncreaseRevenueIcon({ fill = 'currentColor' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="35"
      fill="none"
      viewBox="0 0 41 35"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M29.698 14.709c.267.883.411 1.82.411 2.791a9.576 9.576 0 01-2.814 6.795 9.576 9.576 0 01-6.795 2.814 9.576 9.576 0 01-6.795-2.814 9.61 9.61 0 01-2.403-4.003 9.62 9.62 0 01-.411-2.792c0-5.307 4.302-9.61 9.61-9.61 4.335 0 8 2.873 9.197 6.819z"
      ></path>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M16.165 15.43a1.601 1.601 0 112.264-2.265 1.601 1.601 0 01-2.264 2.264zM24.835 19.57a1.601 1.601 0 11-2.264 2.265 1.601 1.601 0 012.264-2.264zM40.2 5.809a1.601 1.601 0 11-3.203 0 1.601 1.601 0 013.202 0zM4.004 29.191a1.601 1.601 0 11-3.202.001 1.601 1.601 0 013.202 0zM3.535 28.06l12.63-12.63M18.43 15.43l4.14 4.14M24.835 19.57l12.63-12.63M20.5 1.484v6.407M10.89 17.5H4.485M9.175 28.825l4.53-4.53M31.825 28.825l-4.53-4.53"
      ></path>
      <path
        fill={fill}
        d="M9.93 6.129a.8.8 0 11-1.602 0 .8.8 0 011.602 0z"
      ></path>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M11.985 3.933A15.94 15.94 0 0120.5 1.484c6.07 0 11.351 3.377 14.067 8.355a15.934 15.934 0 011.949 7.661c0 4.423-1.792 8.427-4.691 11.325a15.962 15.962 0 01-11.325 4.69 15.962 15.962 0 01-11.325-4.69 16.052 16.052 0 01-2.742-3.664A15.933 15.933 0 014.484 17.5c0-3.13.898-6.049 2.45-8.516"
      ></path>
    </svg>
  );
}

export default IncreaseRevenueIcon;
