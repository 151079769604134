import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';

import { StoreIcon } from '../../Icon/vectors';
import { ProfileTabContainer, profileTabButtonStyle } from './styles';

const ProfileTabs = () => {
  const { rewardStore } = useFlags();
  const navigate = useNavigate();
  const params = useParams();
  const tabs = [
    {
      title: 'My Profile',
      path: '.',
      icon: <PersonIcon fontSize={'large'} />,
      show: true,
      name: 'my-profile'
    },
    {
      title: 'Rewards',
      path: 'my-rewards',
      icon: <EmojiEventsIcon fontSize={'large'} />,
      show: rewardStore
    },
    {
      title: 'My Stores',
      path: 'my-stores',
      icon: <StoreIcon size={24} />,
      show: true
    }
  ].filter(obj => obj.show);

  const [currentTab, setCurrentTab] = useState(() => {
    if (params['*'].toString() === 'profile') {
      return 0;
    }
    return tabs.findIndex(tab =>
      params['*'].toString().includes(`/${tab.path}`)
    );
  });
  const setTab = index => {
    if (currentTab !== index) {
      setCurrentTab(index);
      navigate(tabs[index].path, {
        preventScrollReset: true
      });
    }
  };

  return (
    <ProfileTabContainer elevation={0}>
      {tabs
        .filter(obj => obj.show)
        .map((tab, index) => (
          <Button
            disableElevation
            variant={index === currentTab ? 'contained' : 'text'}
            key={tab.title}
            sx={profileTabButtonStyle}
            onClick={() => setTab(index)}
            data-testid={`${tab.name}-button`}
          >
            {tab.icon}
            {tab.title}
          </Button>
        ))}
    </ProfileTabContainer>
  );
};

export default ProfileTabs;
