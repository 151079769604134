import { capitalize, get } from 'lodash';
import { convertToCurrency, getCurrency } from './number';

import { NA } from '../assets/strings';
import { formatDate } from './date';

export const singleEventInfo = event => {
  return {
    canJoin: get(event, 'canJoin', false),
    capacity: get(event, 'details.capacity', 0),
    currentRound: get(event, 'currentRound', 0),
    date: formatDate(get(event, 'startDate', NA)),
    startDate: get(event, 'startDate', ''),
    signupDate: get(event, 'registrationStartDate', ''),
    entryFee: capitalize(convertToCurrency(get(event, 'details.entryFee', NA))),
    currency: getCurrency(event),
    format: get(event, 'format.name', NA),
    gameId: get(event, 'gameId', ''),
    id: get(event, '_id', ''),
    imageUrl: get(event, 'imageUrl', ''),
    isOrganizer: get(event, 'isOrganizer', false),
    location: get(event, 'location', {}),
    name: get(event, 'name', NA),
    organization: get(event, 'entityName', NA),
    participants: get(event, 'details.participantCount', 0),
    playoffRounds: get(event, 'tournament.playOffRounds', 0),
    regularRounds: get(event, 'tournament.rounds', 0),
    totalRounds:
      get(event, 'tournament.rounds', 0) +
      get(event, 'tournament.playOffRounds', 0),
    status: get(event, 'status', ''),
    tournament: get(event, 'tournament', {}),
    judges: get(event, 'judges', []),
    isJudge: get(event, 'isJudge', false),
    isDeckRegistered: get(event, 'isDeckRegistered', false),
    registeredDeck: get(event, 'registeredDeck', null),
    canModifyDeck: get(event, 'canModifyDeck', false),
    entityId: get(event, 'entityId', ''),
    ticketPricing: get(event, 'ticketPricing', null),
    payoutEnabled: get(event, 'payoutEnabled', false),
    previouslyJoined: get(event, 'previouslyJoined', false)
  };
};

export const getRoundDetails = roundDetails => ({
  endTime: get(roundDetails, 'endTime', ''),
  roundNumber: get(roundDetails, 'roundNumber', 0),
  roundsRemaining: get(roundDetails, 'roundsRemaining', 0),
  totalPairings: get(roundDetails, 'totalPairings', 0),
  totalParticipants: get(roundDetails, 'totalParticipants', 0)
});
