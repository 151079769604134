import { Grid, Link, Typography, useMediaQuery } from '@mui/material';
import {
  LinksContainer,
  LinksMainContainer,
  MaxWidthContainer,
  SocialIconButton,
  SocialLinksContainer
} from '../styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { get, kebabCase } from 'lodash';

import { Icon } from '../../../components';
import React from 'react';
import { buildRoutePath } from '../../../utils/routingUtils';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const FooterLinks = () => {
  const theme = useTheme();
  const { game } = useSelector(({ app }) => app);
  const links = get(game, 'layout.footer.links', []);
  const policies = get(game, 'layout.footer.policies');
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { gameSlug } = useParams();
  const { storeLocator } = useFlags();

  const fontVariant = small ? 'body3' : 'body2';
  const headerVariant = small ? 'h5' : 'h3';

  const renderLinkId = link => {
    const newLink = kebabCase(link.title);
    return newLink;
  };

  return (
    <Grid
      id="footer-link-section"
      data-testid="footer-link-section"
      item
      xs={12}
      sx={{
        margin: '0 auto',
        maxWidth: '1500px',
        p: {
          sm: '96px 24px',
          xs: '64px 24px'
        }
      }}
    >
      <MaxWidthContainer
        alignItems="flex-end"
        container
        direction={{ xs: 'column-reverse', sm: 'row' }}
        gap={{ xs: 10, sm: 2 }}
        item
        wrap={'nowrap'}
      >
        {/* Social Links */}
        <Grid
          id="footer-social-links-container"
          data-testid="footer-social-links-container"
          container
          justifyContent={{ xs: 'unset', sm: 'center' }}
          xs={12}
          sm={6}
          item
        >
          <div>
            <Typography gutterBottom variant="h4" className={'footer'}>
              Give us a Follow!
            </Typography>
            <SocialLinksContainer>
              {get(game, 'layout.footer.socials.instagram') && (
                <SocialIconButton
                  id="footer-instagram-button"
                  data-testid="footer-instagram-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.instagram, '_blank')
                  }
                >
                  <Icon name="instagram" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.twitch') && (
                <SocialIconButton
                  id="footer-twitch-button"
                  data-testid="footer-twitch-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.twitch, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="twitch" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.twitter') && (
                <SocialIconButton
                  id="footer-twitter-button"
                  data-testid="footer-twitter-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.twitter, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="twitter" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.facebook') && (
                <SocialIconButton
                  id="footer-facebook-button"
                  data-testid="footer-facebook-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.facebook, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="facebook" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.tikTok') && (
                <SocialIconButton
                  id="footer-tikTok-button"
                  data-testid="footer-tikTok-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.tikTok, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="tikTok" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.discord') && (
                <SocialIconButton
                  id="footer-discord-button"
                  data-testid="footer-discord-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.discord, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="discord" height={36} width={36} />
                </SocialIconButton>
              )}

              {get(game, 'layout.footer.socials.youtube') && (
                <SocialIconButton
                  id="footer-youtube-button"
                  data-testid="footer-youtube-button"
                  onClick={() =>
                    window.open(game.layout.footer.socials.youtube, '_blank')
                  }
                  sx={{ p: 0 }}
                >
                  <Icon name="youtube" height={36} width={36} />
                </SocialIconButton>
              )}
            </SocialLinksContainer>
          </div>
        </Grid>

        {/* Home & Policies Section */}
        <LinksMainContainer
          container
          gap={{ xs: 2, sm: 0 }}
          item
          sm={6}
          wrap={'nowrap'}
          xs={12}
        >
          <Grid container item justifyContent="center" xs={6}>
            <Grid
              container
              flexDirection="column"
              alignItems="flex-start"
              sx={{ width: 'max-content' }}
            >
              <Typography
                gutterBottom
                variant={headerVariant}
                sx={{
                  mb: 4
                }}
              >
                Links
              </Typography>
              <LinksContainer>
                {links.map(link => (
                  <Link
                    id={`footer-${renderLinkId(link)}-link`}
                    data-testid={`footer-${renderLinkId(link)}-link`}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(link.url, '_blank');
                    }}
                    key={link.url}
                    sx={{
                      color: theme.palette.text.primary,
                      width: 'max-content'
                    }}
                    underline="none"
                  >
                    <Typography variant={fontVariant}>{link.title}</Typography>
                  </Link>
                ))}
                {storeLocator && (
                  <Link
                    id="footer-store-locator-link"
                    data-testid="footer-store-locator-link"
                    component={RouterLink}
                    variant="body2"
                    to={buildRoutePath(gameSlug, '/stores/search')}
                    sx={{
                      color: theme.palette.text.primary,
                      width: 'max-content'
                    }}
                    underline="none"
                  >
                    <Typography variant={fontVariant}>Store Locator</Typography>
                  </Link>
                )}
              </LinksContainer>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center" xs={6}>
            <Grid
              container
              flexDirection="column"
              alignItems="flex-start"
              sx={{ width: 'max-content' }}
            >
              <Typography
                gutterBottom
                variant={headerVariant}
                className={'footer linksHeader'}
                sx={{
                  mb: 4
                }}
              >
                Policies
              </Typography>
              <LinksContainer>
                <Link
                  id="footer-terms-of-use-link"
                  data-testid="footer-terms-of-use-link"
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(get(policies, 'termsOfUse'), '_blank');
                  }}
                  sx={{
                    color: theme.palette.text.primary,
                    width: 'max-content'
                  }}
                  underline="none"
                >
                  <Typography variant={fontVariant}>Terms of Use</Typography>
                </Link>

                <Link
                  id="footer-privacy-policy-link"
                  data-testid="footer-privacy-policy-link"
                  component="button"
                  onClick={() => {
                    window.open(get(policies, 'privacy'), '_blank');
                  }}
                  sx={{
                    color: theme.palette.text.primary,
                    width: 'max-content'
                  }}
                  underline="none"
                >
                  <Typography variant={fontVariant}>Privacy Policy</Typography>
                </Link>

                <Link
                  id="footer-cookie-policy-link"
                  data-testid="footer-cookie-policy-link"
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(get(policies, 'cookie'), '_blank');
                  }}
                  sx={{
                    color: theme.palette.text.primary,
                    width: 'max-content'
                  }}
                  underline="none"
                >
                  <Typography variant={fontVariant}>Cookie Policy</Typography>
                </Link>
              </LinksContainer>
            </Grid>
          </Grid>
        </LinksMainContainer>
      </MaxWidthContainer>
    </Grid>
  );
};

export default FooterLinks;
