import { Button, ButtonGroup, Typography } from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';
import { kebabCase } from 'lodash';

export const Counter = ({
  index = null,
  title,
  setCount,
  count,
  maxValue = null,
  disableIncrement = false,
  disableDecrement = false,
  orientation = 'horizontal'
}) => {
  const handleIncrement = () => {
    if (maxValue === count) {
      return;
    }

    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count === 0) {
      return;
    }
    setCount(count - 1);
  };

  const increment = () => {
    return (
      <Button
        id={
          index !== null
            ? `counter-${index}-increment-button`
            : `counter-increment-${kebabCase(title)}-button`
        }
        data-testid={
          index !== null
            ? `counter-${index}-increment-button`
            : `counter-increment-${kebabCase(title)}-button`
        }
        disabled={disableIncrement}
        sx={{
          width: '4rem',
          height: '4rem'
        }}
        onClick={handleIncrement}
      >
        <ArrowUpwardIcon />
      </Button>
    );
  };

  const decrement = () => {
    return (
      <Button
        id={
          index !== null
            ? `counter-${index}-decrement-button`
            : `counter-decrement-${kebabCase(title)}-button`
        }
        data-testid={
          index !== null
            ? `counter-${index}-decrement-button`
            : `counter-decrement-${kebabCase(title)}-button`
        }
        sx={{
          width: '4rem',
          height: '4rem'
        }}
        disabled={disableDecrement}
        onClick={handleDecrement}
      >
        <ArrowDownwardIcon />
      </Button>
    );
  };

  return (
    <>
      <Typography
        textAlign={'center'}
        sx={{
          marginBottom: orientation === 'vertical' ? '1rem' : '0.5rem'
        }}
        variant="h6"
      >
        {title}
      </Typography>
      <ButtonGroup
        orientation={orientation}
        size="medium"
        aria-label="Increment Decrement Group"
      >
        {orientation === 'vertical' ? increment() : decrement()}
        <Button
          sx={{
            pointerEvents: 'none',
            width: '4rem',
            lineHeight: '16px'
          }}
        >
          {count}
        </Button>
        {orientation === 'vertical' ? decrement() : increment()}
      </ButtonGroup>
    </>
  );
};
