import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  styled,
  useTheme
} from '@mui/material';

import OverflowTextfield from '../../../../../components/Shared/TextFields/Overflow/index';
import React from 'react';
import StatsView from '../../../../../components/Rankings/StatsView';
import { get } from 'lodash';

// eslint-disable-next-line no-unused-vars
const OpponentHistoryContainer = styled(Grid)(({ theme }) => ({
  maxHeight: '25vh',
  overflowY: 'scroll',
  marginTop: '30px'
}));

const UserRankMatchupHistory = ({ rankingData = {} }) => {
  const gamesStats = get(rankingData, 'gameplayHistory.games');
  const matchesStats = get(rankingData, 'gameplayHistory.matches');
  const opponents = get(rankingData, 'opponentHistory', []);
  const theme = useTheme();

  const createOpponentView = opponent => {
    return (
      <Grid item xs={12} key={opponent.id}>
        <Accordion
          id={`ranking-details-opponent-${opponent.id}`}
          data-testid={`ranking-details-opponent-${opponent.id}`}
        >
          <AccordionSummary>
            <Grid container alignItems={'center'} spacing={2} wrap={'nowrap'}>
              <Grid item>
                <Avatar src={opponent.imageUrl} />
              </Grid>
              <Grid item>
                <OverflowTextfield
                  title={`${opponent.firstName} ${opponent.lastName}`}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <StatsView
                  title="Games"
                  stats={get(opponent, 'stats.games', {})}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatsView
                  title="Matches"
                  stats={get(opponent, 'stats.matches', {})}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            borderRight={{
              xs: 'none',
              sm: `1px solid ${theme.palette.neutral[400]}`
            }}
          >
            <StatsView title="Overall Games" stats={gamesStats} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StatsView title="Overall Matches" stats={matchesStats} />
          </Grid>
        </Grid>
      </Grid>
      {opponents.length > 0 && (
        <Grid item xs={12}>
          <OpponentHistoryContainer container spacing={1}>
            {opponents.map(opponent => createOpponentView(opponent))}
          </OpponentHistoryContainer>
        </Grid>
      )}
    </Grid>
  );
};
export default UserRankMatchupHistory;
