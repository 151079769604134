import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import SingleEventHeader from '../../../components/Events/SingleEventHeader';
import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Loader from '../../../components/Loading/Loader';
import { useGetSingleEventQuery } from '../../../services/apis/organizePlayApi';
import { singleEventInfo } from '../../../utils/EventUtils';
import StatusPage, { statusTypes } from '../../Status';

const EventSinglePage = () => {
  const { eventId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { game } = useSelector(({ app }) => app);
  const { token } = useSelector(({ auth }) => auth);

  // eslint-disable-next-line no-unused-vars
  const {
    data: activeEvent,
    isLoading,
    error,
    refetch
  } = useGetSingleEventQuery(eventId);

  const { gameId } = singleEventInfo(activeEvent);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  if (!isLoading) {
    if (gameId !== game.id || error) {
      return <StatusPage type={statusTypes.eventNotFound} />;
    }
    return (
      <MaxWidthContainer container gap={6} className={'p-percent'}>
        <Grid xs={12} item>
          <SingleEventHeader />
        </Grid>
        <Grid xs={12} item>
          <Outlet />
        </Grid>
      </MaxWidthContainer>
    );
  } else {
    return <Loader />;
  }
};

export default EventSinglePage;
