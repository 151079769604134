import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { has } from 'lodash';
import { GooglePlaceTextField } from '../../Shared/GooglePlaceTextField';
import { createAddress } from '../../../utils/address';
import { userAddress } from '../../../values/location';

const FormAddressInfo = ({
  formMethods,
  name = 'location',
  addressFormat = userAddress
}) => {
  const { clearErrors, control, getValues } = formMethods;
  const clearLocation = () => clearErrors(name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => (
        <>
          <GooglePlaceTextField
            data-testid="address-input"
            error={has(formState, `errors.${name}`)}
            label={'Shipping Address'}
            {...field}
            onChange={e => {
              clearLocation();
              field.onChange({ ...createAddress(e, addressFormat) });
            }}
          />

          {/* Address 2*/}
          <TextField
            data-testid="address-2-input"
            label="Address 2"
            {...field}
            value={getValues(`${name}.address2`)}
            onChange={e =>
              field.onChange({
                ...createAddress(field.value, addressFormat),
                address2: e.target.value
              })
            }
          />
        </>
      )}
    />
  );
};

export default FormAddressInfo;
