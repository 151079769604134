import { Box, Grid, Typography } from '@mui/material';
import { DeckCardMainContainer, DeckValidityStatusContainer } from './styles';
import { capitalize, get, lowerCase } from 'lodash';
import {
  createFormatOptions,
  getDeckCardCount,
  getFirstFormatOption
} from '../../../../utils/deck';
import { useNavigate, useParams } from 'react-router-dom';

import BasicUserInfo from '../../../Shared/Info/BasicUserInfo';
import DeckCardCountTube from '../../../Shared/Tube/DeckCardCountTube';
import DeckDropDown from '../../DecksDropDown';
import DeckEnergyTube from '../../../Shared/Tube/DeckEnergyTube';
import DeckFormatTube from '../../../Shared/Tube/DeckFormatTube';
import DeckLastUpdatedStatus from '../../DeckLastUpdatedStatus';
import DeckStackOfCards from '../DeckStackOfCards';
import DeckTagsTube from '../../../Shared/Tube/DeckTagsTube';
import { DeckValidationStatus } from '../../DeckValidationStatus';
import GeneralContainer from '../../../Shared/Cards/GeneralCard';
import React from 'react';
import SocialTube from '../../../Shared/Tube/SocialTube';
import TrendTube from '../../../Shared/Tube/TrendTube';
import { buildRoutePath } from '../../../../utils/routingUtils';
import { useDeck } from '../../../../pages/Decks/hooks/useDeck';
import { useSelector } from 'react-redux';

const DeckCard = ({ info: deckInfo, isMyDecks = false }) => {
  const { user } = useSelector(({ app }) => app);
  const info = deckInfo?.deck;
  const userInfo = deckInfo?.user;
  const gameUserInfo = deckInfo?.gameUser;
  // eslint-disable-next-line no-unused-vars
  const { deckFormatsById } = useDeck();

  const userAvatar = get(
    gameUserInfo,
    'imageUrl',
    get(userInfo, 'profile.image', get(user, 'profile.image'))
  );

  const username =
    get(deckInfo, 'user.profile.nickname') ||
    `${get(deckInfo, 'user.profile.firstName', '')} ${
      get(deckInfo, 'user.profile.lastName', ' ')[0]
    }.`;

  const deckSections = get(
    deckFormatsById,
    `${deckInfo?.deck?.formatId}.deckType.deckSections`,
    []
  );

  const formatOptions = createFormatOptions(
    info?.formatOptions,
    deckFormatsById[info?.formatId]?.formatOptions
  );

  // eslint-disable-next-line no-unused-vars
  const [formatOptionName] = getFirstFormatOption(formatOptions);

  const sections = info?.sections;
  const cardInfo = sections?.reduce(
    (prev, current) => {
      const updatedInfo = { ...prev };
      const section = deckSections?.find(
        section => section?._id === current?.deckSectionId
      );
      if (section && section?.type === 'mainboard') {
        updatedInfo.count =
          updatedInfo.count + getDeckCardCount(current?.cards);
      }
      return updatedInfo;
    },
    { count: 0, images: [] }
  );

  const { gameSlug } = useParams();
  const navigate = useNavigate();

  const getUsername = () => {
    if (isMyDecks) {
      return get(
        user,
        'details.info.nickname',
        `${get(user, 'details.info.firstName')} ${
          get(user, 'details.info.lastName', ' ')[0]
        }`
      );
    }
    return username;
  };

  return (
    <GeneralContainer
      id={`decks-deck-${deckInfo.deck._id}-card-button`}
      paperStyle={{ minHeight: '200px', height: '100%', overflow: 'hidden' }}
      buttonStyle={{ padding: 3 }}
      onClick={() => navigate(buildRoutePath(gameSlug, `/decks/${info?._id}`))}
    >
      <DeckCardMainContainer cards={deckInfo?.cards?.length}>
        {/* Deck Info*/}
        <Box
          className={'deck-info'}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            flexWrap: 'nowrap'
          }}
        >
          <Box
            sx={{
              height: { xs: '150px', md: '225px' },
              width: { xs: '100px', md: '150px' }
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${get(
                  deckInfo,
                  'deck.deckImageUrl',
                  ''
                )})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '16px',
                height: '100%',
                width: '100%',
                position: 'relative'
              }}
            >
              {isMyDecks && (
                <DeckValidityStatusContainer>
                  <DeckValidationStatus
                    iconOnly
                    deck={get(deckInfo, 'deck', {})}
                  />
                </DeckValidityStatusContainer>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}
          >
            {info?.trend && <TrendTube />}
            <DeckCardCountTube count={cardInfo?.count} />
            {info?.energy && <DeckEnergyTube />}
          </Box>
        </Box>

        <DeckStackOfCards cards={deckInfo?.cards} />

        {/* Deck Name */}
        <Grid
          item
          xs={12}
          className={'deck-name-info'}
          style={{ overflow: 'hidden' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems={'space-between'}
                flexWrap={'nowrap'}
              >
                <Grid item xs={12} sm={8} md={'auto'}>
                  {/* Deck Name and UserName*/}
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className={'deck-name'} variant={'h4'}>
                          {info?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <BasicUserInfo
                          className={'deck-username'}
                          avatar={userAvatar}
                          name={getUsername()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Deck Type and Deck Stats */}
        <Grid item xs={12} className={'deck-stats'}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <DeckFormatTube
                    format={
                      (formatOptionName
                        ? `${capitalize(lowerCase(formatOptionName))} `
                        : '') + get(info, 'format.name')
                    }
                  />
                </Grid>
                <Grid item pr={{ xs: 0, sm: 4 }}>
                  <DeckTagsTube tags={get(info, 'tags', [])} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DeckLastUpdatedStatus deck={info} />
            </Grid>
            {info?.social && (
              <Grid item xs={12} sm={6}>
                <SocialTube
                  tubeStyle={{
                    minHeight: 'unset',
                    padding: 1.5,
                    height: '100%'
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DeckCardMainContainer>
      <DeckDropDown deck={info} />
    </GeneralContainer>
  );
};

export default React.memo(DeckCard);
