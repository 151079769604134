import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { LoadScript } from '@react-google-maps/api';
import Loading from '../../Loading/Loader';
import React from 'react';
import { setGmapLoaded } from '../../../store/app/actions';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE;
const libraries = ['places'];

const MapLoaderError = () => {
  return (
    <Grid>
      <Grid>
        <Typography>Unable to load map!</Typography>
      </Grid>
    </Grid>
  );
};

const MapLoader = props => {
  const dispatch = useDispatch();
  const { isGmapLoaded } = useSelector(({ app }) => app);
  if (!GOOGLE_KEY) {
    return <MapLoaderError />;
  }
  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_KEY}
      libraries={libraries}
      onLoad={() => dispatch(setGmapLoaded(true))}
      id="google-maps-v3"
    >
      {isGmapLoaded ? <>{props.children}</> : <Loading />}
    </LoadScript>
  );
};

export default React.memo(MapLoader);
