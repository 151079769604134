import { Box, Grid, Typography } from '@mui/material';

import React from 'react';
import { get } from 'lodash';

const StatsView = ({ title, stats = {} }) => {
  const getStats = () => {
    return {
      played: get(stats, 'played', 0),
      won: get(stats, 'won', 0),
      lost: get(stats, 'lost', 0),
      drawn: get(stats, 'drawn', 0)
    };
  };
  const { played, won, lost, drawn } = getStats();

  return (
    <Grid
      id="rankings-user-details-container"
      data-testid="rankings-user-details-container"
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography color={'primary'}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={'flex-start'} spacing={4}>
          <Grid item>
            <Grid
              container
              alignItems={'center'}
              flexDirection={'column'}
              width={'fit-content'}
            >
              <Grid item>
                <Typography variant={'body2'}>Total</Typography>
              </Grid>
              <Grid item>
                <Typography>{played}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems={'center'}
              flexDirection={'column'}
              width={'fit-content'}
            >
              <Grid item>
                <Typography variant={'body2'}>Record</Typography>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <Typography color={'success.main'}>{won}</Typography>
                  <Typography whiteSpace={'pre-wrap'}>-</Typography>
                  <Typography color={'error.main'}>{lost}</Typography>
                  <Typography whiteSpace={'pre-wrap'}>-</Typography>
                  <Typography color={'info.main'}>{drawn}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatsView;
