import {
  APP_INIT_FAILURE,
  APP_INIT_REQUEST,
  APP_INIT_SUCCESS,
  AUTH_COMPLETE,
  AUTH_FAILURE,
  AUTH_START,
  GET_ENTITY_APPLICATIONS_FAILURE,
  GET_ENTITY_APPLICATIONS_REQUEST,
  GET_ENTITY_APPLICATIONS_SUCCESS,
  GET_GAME_PROFILE_PICS,
  SET_GMAP_LOADED,
  SET_STORE_CURRENCY,
  SET_USER,
  UPDATE_GAME_USER_FAILURE,
  UPDATE_GAME_USER_REQUEST,
  UPDATE_GAME_USER_SUCCESS,
  UPDATE_LOCAL_USER_IMAGE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PROFILE_PIC,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_WALLET_FAILURE,
  UPDATE_USER_WALLET_REQUEST,
  UPDATE_USER_WALLET_SUCCESS
} from './types';

import { cloneDeep, set, get } from 'lodash';

const INITIAL_STATE = {
  isAuthorizing: false,
  isInitializingApp: false,
  isUpdatingUser: false,
  isUpdatingEntityApplication: false,
  isGmapLoaded: false,
  game: '',
  user: {},
  selectedStoreCurrency: '',
  isUpdatingUserWallet: false
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_INIT_REQUEST:
      return {
        ...state,
        isInitializingApp: true,
        selectedStoreCurrency: null
      };

    case APP_INIT_SUCCESS:
      return {
        ...state,
        isInitializingApp: false,
        game: action.game,
        selectedStoreCurrency: get(
          action,
          `game.pgGame.currencySymbolIdToCurrency.${get(
            action,
            'game.defaultCurrency'
          )}.id`,
          ''
        )
      };

    case APP_INIT_FAILURE:
      return {
        ...state,
        isInitializingApp: false
      };

    case AUTH_START:
      return {
        ...state,
        isAuthorizing: true
      };

    case AUTH_COMPLETE:
      return {
        ...state,
        isAuthorizing: false
      };

    case AUTH_FAILURE:
      return {
        ...state,
        isAuthorizing: false
      };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            imageUrl: state.user.details.gameUser.imageUrl,
            location: action.newUser.location,
            info: {
              ...state.user.details.info,
              ...action.newUser.profile
            }
          }
        }
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdatingUser: false
      };

    case SET_USER:
      return {
        ...state,
        user: action.user
      };

    case UPDATE_GAME_USER_REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      };

    case UPDATE_GAME_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            imageUrl: action.newGameUser.imageUrl,
            gameUser: {
              ...state.user.details.gameUser,
              imageUrl: action.newGameUser.imageUrl,
              teamName: action.newGameUser.teamName
            }
          }
        }
      };

    case UPDATE_GAME_USER_FAILURE:
      return {
        ...state,
        isUpdatingUser: false
      };

    case UPDATE_USER_PROFILE_PIC:
      return set(
        cloneDeep(state),
        'user.details.gameUser.imageUrl',
        action.imageUrl
      );

    case UPDATE_LOCAL_USER_IMAGE:
      return {
        ...state,
        isUpdatingUser: false,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            imageUrl: action.localUserImage,
            gameUser: {
              ...state.user.details.gameUser,
              imageUrl: action.localUserImage
            }
          }
        }
      };

    case GET_GAME_PROFILE_PICS:
      return {
        ...state,
        game: {
          ...state.game,
          images: {
            ...state.game.images,
            profilePics: action.profilePics
          }
        }
      };

    //  Entity Applications Reducers
    case GET_ENTITY_APPLICATIONS_REQUEST:
      return {
        ...state,
        isUpdatingEntityApplication: true
      };

    case GET_ENTITY_APPLICATIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            gameUser: {
              ...state.user.gameUser,
              lgsApplications: action.lgsApplications
            }
          }
        },
        isUpdatingEntityApplication: false
      };

    case GET_ENTITY_APPLICATIONS_FAILURE:
      return {
        ...state,
        isUpdatingEntityApplication: false
      };
    case SET_GMAP_LOADED:
      return {
        ...state,
        isGmapLoaded: true
      };
    case SET_STORE_CURRENCY:
      return {
        ...state,
        selectedStoreCurrency: action.currency
      };
    case UPDATE_USER_WALLET_REQUEST:
      return {
        ...state,
        isUpdatingUserWallet: true
      };
    case UPDATE_USER_WALLET_SUCCESS:
      return {
        ...state,
        isUpdatingUserWallet: false,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            wallets: action.wallets
          }
        }
      };
    case UPDATE_USER_WALLET_FAILURE:
      return {
        ...state,
        isUpdatingUserWallet: false,
        user: {
          ...state.user,
          details: {
            ...state.user.details,
            wallets: []
          }
        }
      };
    default:
      return state;
  }
};

export default appReducer;
