import { Box, styled } from '@mui/material';

export const CategoryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0),
  width: '100%',
  padding: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    padding: 0,
    marginTop: theme.spacing(1)
  }
}));

export const gridContainerStyle = {
  margin: 0,
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(2, 1fr)',
    sm: 'repeat(3, 1fr)',
    md: 'repeat(4, 1fr)',
    lg: 'repeat(5, 1fr)'
  },
  gap: 3,
  paddingX: { xs: 0, sm: 1 },
  paddingY: 2
};
