import 'swiper/css';
import 'swiper/css/pagination';
import '../../index';

import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

import GameStats from '../../components/GameInfo/Stats';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import { NA } from '../../assets/strings';
import React from 'react';
import { get } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LandingHeader = () => {
  const { game } = useSelector(({ app }) => app);
  const title = get(game, 'layout.homepage.header.title', NA);
  const subtitle = get(game, 'layout.homepage.header.subtitle', NA);
  const events = get(game, 'stats.events', 0);
  const players = get(game, 'stats.players', 0);
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const mobileQuery = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Grid
      id="landing-page-header-container"
      data-testid="landing-page-header-container"
      container
    >
      <Grid item xs={12}>
        <MaxWidthContainer
          className={`p-percent ${!mobileQuery && 'landing-header'}`}
          container
          justifyContent="space-between"
        >
          {/* Left Section */}
          <Grid item sx={{ paddingY: 12 }} xs={12} md={5}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography sx={{ mb: 4 }} variant="h1">
                  {title}
                </Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
              </Grid>
              <Grid item xs={12}>
                <GameStats totalEvents={events} totalPlayers={players} />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  gap={2}
                  alignItems={{ xs: 'center', sm: 'flex-start' }}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  mt={{ xs: 4, md: 0 }}
                >
                  <Grid item sx={{ width: '100%' }}>
                    <Button
                      id="explore-events-button"
                      data-testid="explore-events-button"
                      onClick={() => {
                        navigate(
                          get(
                            game,
                            'layout.homepage.header.customLink.url',
                            'events'
                          )
                        );
                      }}
                      sx={{
                        width: {
                          xs: '100%',
                          md: 'initial'
                        }
                      }}
                      variant="contained"
                    >
                      {get(
                        game,
                        'layout.homepage.header.customLink.title',
                        'Explore Events'
                      )}
                    </Button>
                  </Grid>
                  {!isAuthenticated && mobileQuery && (
                    <Grid item sx={{ width: '100%' }}>
                      <Button
                        id="login-button"
                        data-testid="login-button"
                        onClick={loginWithRedirect}
                        sx={{ width: '100%' }}
                        variant="outlined"
                      >
                        Login
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Box
              sx={{
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundImage: `url(${get(
                  game,
                  'layout.homepage.header.heroImage'
                )})`,
                height: '100%',
                width: '100%'
              }}
            ></Box>
          </Grid>
        </MaxWidthContainer>
      </Grid>
    </Grid>
  );
};

export default LandingHeader;
