import { Box, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const minHeight = '64px';
export const StyledQuantityButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& .MuiButtonBase-root': {
    padding: '12px 18px'
  },
  '& .cart-quantity-minus': {
    borderTopLeftRadius: `${theme.border.borderRadius}px`,
    borderBottomLeftRadius: `${theme.border.borderRadius}px`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minHeight,
    [theme.breakpoints.down('sm')]: {}
  },
  '& .cart-quantity-plus': {
    borderTopRightRadius: `${theme.border.borderRadius}px`,
    borderBottomRightRadius: `${theme.border.borderRadius}px`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minHeight
  }
}));

export const StyledQuantity = styled(Box)(({ theme }) => ({
  minWidth: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.primary.main}`,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  minHeight,
  ['&.disabled']: {
    opacity: 0.5
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '60px'
  }
}));

export const AddItemButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: `${theme.border.borderRadius}px`,
  minHeight
}));
