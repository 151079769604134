/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  useLazyGetPaymentIntentDetailsQuery,
  usePostVerifyPaymentAndJoinEventMutation,
  usePostVerifyPaymentAndOrderMutation
} from '../../../services/apis/organizePlayApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import EmptyPageLoader from '../../../components/Shared/EmptyPageLoader/index';
import { buildRoutePath } from '../../../utils/routingUtils';
import { get } from 'lodash';
import { toast } from 'react-toastify';

const PaymentCallbackPage = () => {
  const location = useLocation();
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const [joinEventId, setJoinEventId] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const paymentIntentId = queryParams.get('payment_intent');
  const paymentIntentClientSecret = queryParams.get(
    'payment_intent_client_secret'
  );
  const redirectStatus = queryParams.get('redirect_status');
  const [
    getPaymentIntentDetails,
    getPaymentIntentDetailsResult
  ] = useLazyGetPaymentIntentDetailsQuery();
  const [
    verifyAndOrder,
    verifyAndOrderRes
  ] = usePostVerifyPaymentAndOrderMutation();
  const [
    verifyAndJoinEvent,
    verifyAndJoinEventRes
  ] = usePostVerifyPaymentAndJoinEventMutation();
  useEffect(() => {
    if (!redirectStatus || !paymentIntentId || !paymentIntentClientSecret) {
      return navigate(buildRoutePath(gameSlug, '/'));
    }
    if (redirectStatus !== 'succeeded') {
      toast.error('Payment failed, please try again.');
      return navigate(buildRoutePath(gameSlug, '/cart'));
    }
    if (paymentIntentId) {
      getPaymentIntentDetails(paymentIntentId);
    }
  }, [paymentIntentId, paymentIntentClientSecret, redirectStatus]);

  useEffect(() => {
    if (getPaymentIntentDetailsResult.isSuccess) {
      const isSuccess =
        get(getPaymentIntentDetailsResult, 'data.data.status', '') ===
        'succeeded';
      const metaData = get(
        getPaymentIntentDetailsResult,
        'data.data.metadata',
        null
      );
      if (!isSuccess || !metaData) {
        toast.error('Payment failed, please try again.');
        return navigate(buildRoutePath(gameSlug, '/'));
      }
      if (metaData.type === 'rewards-order') {
        verifyAndOrder({
          paymentIntentId
        });
      } else if (metaData.type === 'event-ticket') {
        const eventId = get(metaData, 'eventMongoId', null);
        const userId = get(metaData, 'userMongoId', null);
        setJoinEventId(eventId);
        verifyAndJoinEvent({
          body: {
            eventId,
            userId
          }
        });
      }
    }
  }, [getPaymentIntentDetailsResult]);

  useEffect(() => {
    if (verifyAndOrderRes.isSuccess) {
      toast.success('Order successful!');
      return navigate(buildRoutePath(gameSlug, '/user/profile/my-rewards'));
    }
  }, [verifyAndOrderRes]);

  useEffect(() => {
    if (verifyAndJoinEventRes.isSuccess) {
      toast.success('Successfully joined event!');
      return navigate(buildRoutePath(gameSlug, `/events/${joinEventId}`));
    }
  }, [verifyAndJoinEventRes]);

  return (
    <EmptyPageLoader
      mainText="Please wait while we verify payment details"
      subtitle="Please do not refresh of press back!"
    />
  );
};

export default PaymentCallbackPage;
