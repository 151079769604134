import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { forEach, get, isEmpty } from 'lodash';
import {
  getSelectedVariantInventoryId,
  getVariantMapping
} from '../../../../../utils/cart';

import StoreCurrency from '../../../../../components/Shared/StoreCurrency';
import { useSelector } from 'react-redux';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  marginTop: '8px',
  '& .MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
    padding: '4px 12px',
    marginRight: theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    minWidth: '60px'
  }
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

const VariantSelector = ({
  cartDetails,
  isCartFetching = false,
  isCartLoading = false,
  currencies = [],
  currencyId,
  product,
  selectedInventoryId = null,
  onChange = () => {},
  onInvalidSelection = () => {}
}) => {
  const [variantsMap, setVariantsMap] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});
  const { selectedStoreCurrency } = useSelector(({ app }) => app);
  const usedCurrencyId = currencyId || selectedStoreCurrency;
  const currency = currencies.find(cr => cr.id === usedCurrencyId);

  const theme = useTheme();
  useEffect(() => {
    if (variantsMap && selectedVariant) {
      const newInvId = getSelectedVariantInventoryId(
        variantsMap.availabilityMap,
        selectedVariant
      );

      if (selectedInventoryId !== newInvId) {
        onChange(newInvId);
      }
    }
  }, [variantsMap, selectedVariant, selectedInventoryId]);
  useEffect(() => {
    if (product) {
      const vMap = getVariantMapping(product, usedCurrencyId);
      setVariantsMap(vMap);
      if (isEmpty(selectedVariant)) {
        const sVar = {};
        const selVar = selectedInventoryId
          ? get(vMap, `availabilityMap.${selectedInventoryId}`, null)
          : null;
        forEach(vMap.variants, variant => {
          sVar[variant.variant] = selVar
            ? selVar[variant.variant]
            : variant.values[0];
        });
        setSelectedVariant(sVar);
      }
    }
  }, [product, selectedInventoryId, selectedVariant]);
  const onVariantChange = (variantType, variantValue, variant) => {
    setSelectedVariant({
      ...selectedVariant,
      [variantType]: variantValue
    });
  };
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        padding: theme.spacing(2),
        borderRadius: '12px'
      }}
    >
      {variantsMap &&
        variantsMap.variants &&
        variantsMap.variants.map(availableVariant => (
          <Grid item xs={12} key={availableVariant.variant}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2Bold" textTransform="capitalize">
                  {availableVariant.variant}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledToggleButtonGroup
                  color="primary"
                  value={selectedVariant[availableVariant.variant]}
                  exclusive
                  onChange={(event, newValue) =>
                    onVariantChange(
                      availableVariant.variant,
                      newValue,
                      availableVariant
                    )
                  }
                >
                  {availableVariant.values.map(
                    ({ variant, price, fiatPrice }) => (
                      <StyledToggleButton key={variant} value={variant}>
                        <Typography variant="h4">{variant}</Typography>
                        <StoreCurrency
                          amount={price}
                          symbol={currency.symbol || 'points'}
                          color="primary"
                          fiatPrice={fiatPrice}
                          showInSingleLine={false}
                        />
                      </StyledToggleButton>
                    )
                  )}
                </StyledToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default VariantSelector;
