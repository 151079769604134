import { Button, Collapse, Drawer, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OptionsContainer } from './styles';
import CloseIcon from '@mui/icons-material/Close';

import DeckName from './DeckName';
import DeckTags from './DeckTags';
import DeckCards from './DeckCards';
import DeckFormat from './DeckFormat';
import { debounce } from 'lodash';

const DeckFilterOptions = ({
  showFilterOptions,
  offsetTop,
  isMobile,
  openDrawer,
  setOpenDrawer,
  setDeckQuery,
  INITIAL_QUERY
}) => {
  const [tags, setTags] = useState([]);
  const [name, setName] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState('');

  useEffect(() => {
    const updateDeckQuery = debounce(() => {
      setDeckQuery({
        name,
        deckTagIds: tags.map(tag => tag._id),
        cardIds: selectedCards.map(card => card.id),
        formatId: selectedFormat
      });
    }, 250);

    updateDeckQuery();

    return () => updateDeckQuery.cancel();
  }, [tags, name, selectedCards, selectedFormat]);

  const resetFilters = () => {
    setTags([]);
    setName('');
    setSelectedCards([]);
    setSelectedFormat('');
    setDeckQuery(INITIAL_QUERY);
  };

  return (
    <Collapse in={showFilterOptions}>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        sx={{
          position: 'fixed',
          zIndex: 1000,
          ...(!isMobile && {
            position: 'relative',
            width: '100%'
          }),
          '& .MuiDrawer-paper': {
            top: { ...offsetTop, sm: offsetTop.xs, md: 0 },
            position: isMobile ? 'fixed' : 'relative',
            width: { xs: '85vw', md: '100%' },
            maxWidth: '100%',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            paddingBottom: { ...offsetTop, sm: offsetTop.xs, md: 0 }
          }
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
      >
        <OptionsContainer>
          <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2}>
            {isMobile && (
              <Grid
                display="flex"
                item
                xs
                alignItems="center"
                justifyContent="flex-end"
                gap={3}
                order={{ xs: -2, md: 0 }}
              >
                <IconButton onClick={() => setOpenDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item xs>
              <DeckName name={name} setName={setName} />
            </Grid>
            <Grid item xs>
              <DeckTags tags={tags} setTags={setTags} />
            </Grid>
            <Grid item xs>
              <DeckCards
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
              />
            </Grid>
            <Grid item xs>
              <DeckFormat
                selectedFormat={selectedFormat}
                setSelectedFormat={setSelectedFormat}
              />
            </Grid>

            <Grid
              order={{ xs: -1, md: 0 }}
              display="flex"
              justifyContent="flex-end"
              item
              xs={12}
            >
              <Button
                onClick={resetFilters}
                variant="text"
                data-testid="decks-reset-filters-button"
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </OptionsContainer>
      </Drawer>
    </Collapse>
  );
};

export default DeckFilterOptions;
