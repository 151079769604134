import { get } from 'lodash';

export function darkenColor(hexColor, darkenFactor = 5) {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Darken the color by subtracting 10 from each component, but make sure each component stays within the range 0-255
  const darkerR = Math.max(r - darkenFactor, 0);
  const darkerG = Math.max(g - darkenFactor, 0);
  const darkerB = Math.max(b - darkenFactor, 0);

  return `#${darkerR.toString(16).padStart(2, '0')}${darkerG
    .toString(16)
    .padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
}

export function getMetric(deck, metricKey, allMetrics = false) {
  if (allMetrics) {
    return get(deck, 'data.deck.metrics', {});
  }
  return get(deck, `data.deck.metrics.${metricKey}`, []);
}

export function getRandomColors(startColor, endColor, length, step = 1) {
  // Convert hex colors to RGB format
  const startRGB = hexToRGB(startColor);
  const endRGB = hexToRGB(endColor);

  // Calculate the color increment for each step
  const rIncrement = (endRGB.r - startRGB.r) / (length - 1);
  const gIncrement = (endRGB.g - startRGB.g) / (length - 1);
  const bIncrement = (endRGB.b - startRGB.b) / (length - 1);

  // Generate gradient array
  const gradient = [startColor];
  for (let i = 0; i < length; i += step) {
    const r = Math.round(startRGB.r + rIncrement * i);
    const g = Math.round(startRGB.g + gIncrement * i);
    const b = Math.round(startRGB.b + bIncrement * i);
    gradient.push(rgbToHex(r, g, b));
  }

  return gradient;
}

function hexToRGB(hex) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  const componentToHex = c => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
