import React from 'react';
import { TubeCard } from '../../Cards/styles';
import { Typography } from '@mui/material';
import { deckCountTubeStyle } from './styles';

const DeckCardCountTube = ({ count = 0 }) => {
  return (
    <TubeCard className={'deck-count-tube'} sx={deckCountTubeStyle}>
      <Typography className={'deck-count-typo'}>{count}</Typography>
      <Typography className={'deck-cards-typo'}>Cards</Typography>
    </TubeCard>
  );
};

export default DeckCardCountTube;
