import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import DeckAccordion from '../../../../../../components/Decks/DeckAccordion';
import {
  createDeckNamePath,
  getDeckCardCount,
  groupByCardType
} from '../../../../../../utils/deck';
import { Box, Grid, List, Typography } from '@mui/material';
import { CategoryContainer, gridContainerStyle } from './styles';
import DeckCardsPreview from './DeckCardsPreview';
import ConditionalWrapper from '../../../../../../components/Shared/ConditionalWrapper';

const DeckSection = ({ index, section, view, data }) => {
  const isTextView = view === 'text';
  const formContext = useFormContext();
  const { control, getValues } = formContext || {};
  const cards = useWatch({
    control,
    name: `sections.${index}.cards`
  });

  const cardInfo = getValues('cardInfo') || {};
  const cardsByGroup = groupByCardType(cards, cardInfo, index);
  return (
    <DeckAccordion
      type="create-deck-preview"
      name={getValues(createDeckNamePath(section?.deckSectionId))}
      count={getDeckCardCount(cards)}
      key={section?.deckSectionId}
      mainTextStyle={{
        typography: { xs: 'h3', sm: 'h2' }
      }}
    >
      <Grid container spacing={1}>
        {Object.keys(cardsByGroup).map(key => {
          return (
            <Grid key={key} item xs minWidth={isTextView ? '230px' : '100%'}>
              <CategoryContainer>
                <Typography variant="h3">{data[key]?.name}</Typography>
                <Typography color="primary.main" variant="h4">
                  ({getDeckCardCount(cardsByGroup[key])})
                </Typography>
              </CategoryContainer>

              <ConditionalWrapper
                condition
                wrapper={children => {
                  if (isTextView) {
                    return <List>{children}</List>;
                  }
                  return <Box sx={gridContainerStyle}>{children}</Box>;
                }}
              >
                <DeckCardsPreview
                  section={section}
                  cards={cardsByGroup[key]}
                  isTextView={isTextView}
                />
              </ConditionalWrapper>
            </Grid>
          );
        })}
      </Grid>
    </DeckAccordion>
  );
};

export default DeckSection;
