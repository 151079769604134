import { Grid, Slider, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../Rewards';

const PointSlider = () => {
  const { updateFilter, filter } = useContext(FilterContext);
  const minDistance = 10;
  const maxPoints = filter.maxValue;
  const [point, setPoint] = useState([filter.min, maxPoints]);

  useEffect(() => {
    updateFilter({ min: +point[0], max: +point[1] });
  }, [point]);

  const handleChange = (e, side) => {
    const newValue = +e.target.value;
    switch (side) {
      case 'right':
        setPoint([point[0], newValue]);
        break;
      case 'left':
        setPoint([newValue, point[1]]);
        break;
      default:
        break;
    }
  };

  const handleBlur = side => {
    const value = +point[side === 'left' ? 0 : 1];

    switch (side) {
      case 'left':
        if (value < 0) {
          setPoint([0, point[1]]);
        }
        if (value > point[1] - minDistance) {
          setPoint([point[1] - minDistance, point[1]]);
        }
        break;
      case 'right':
        if (value > maxPoints) {
          setPoint([point[0], maxPoints]);
        }
        if (value < point[0] + minDistance) {
          setPoint([point[0], point[0] + minDistance]);
        }
        break;
      default:
        break;
    }
  };

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (activeThumb === 0) {
      setPoint([Math.min(newValue[0], point[1] - minDistance), point[1]]);
    } else {
      setPoint([point[0], Math.max(newValue[1], point[0] + minDistance)]);
    }
  };

  const marks = [
    {
      value: 0,
      label: 0
    },
    {
      value: maxPoints / 2,
      label: maxPoints / 2
    },
    {
      value: maxPoints,
      label: maxPoints
    }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={3} sm={2}>
        <TextField
          label="Min"
          data-testid="currency-min-value"
          sx={{ backgroundColor: 'transparent' }}
          value={point[0]}
          onChange={e => handleChange(e, 'left')}
          onBlur={() => handleBlur('left')}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'center'
            }
          }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={8}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            position: 'relative',
            top: '-20px'
          }}
          variant="body3"
        >
          Point Amount
        </Typography>
        <Slider
          valueLabelDisplay="auto"
          data-testid="currency-value-slider"
          max={maxPoints}
          disableSwap
          value={point}
          marks={marks}
          onChange={handleSliderChange}
          sx={{
            position: 'relative',
            '& .MuiSlider-markLabel': {
              top: '-15px'
            }
          }}
        />
      </Grid>
      <Grid item xs={3} sm={2}>
        <TextField
          label="Max"
          data-testid="currency-max-value"
          sx={{ backgroundColor: 'transparent' }}
          value={point[1]}
          onChange={e => handleChange(e, 'right')}
          onBlur={() => handleBlur('right')}
          inputProps={{
            type: 'number',
            style: {
              textAlign: 'center'
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PointSlider;
