import { Box, styled, Table } from '@mui/material';

export const WalletBalanceContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  flexDirection: 'column'
}));

export const StyledTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    padding: '8px'
  }
}));
