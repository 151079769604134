import { Button } from '@mui/material';
import { PaperContainer } from '../../Paper';
import React from 'react';
import { isFunction } from 'lodash';

const GeneralContainer = ({
  disabled = false,
  id = null,
  active = true,
  hover = true,
  buttonStyle,
  paperStyle,
  onClick,
  onTouchStart,
  onMouseLeave,
  ...restProps
}) => {
  const paperStyles = theme => ({
    backgroundColor: theme.events.cardPrimaryColor,
    ['WebkitTapHighlightColor']: 'transparent',
    pointerEvents: active ? 'unset' : 'none',
    position: 'relative',
    ...(isFunction(paperStyle) ? paperStyle(theme) : paperStyle)
  });

  const buttonStyles = {
    height: '100%',
    width: '100%',
    padding: 0,
    borderRadius: 'inherit',
    color: 'inherit',
    cursor: onClick ? 'pointer' : 'default',
    ...buttonStyle
  };
  return (
    <PaperContainer
      id={id}
      data-testid={id}
      className={hover && !disabled && 'hover'}
      sx={paperStyles}
    >
      <Button
        disabled={disabled}
        component={'div'}
        onClick={onClick && onClick}
        onTouchStart={onTouchStart && onTouchStart}
        onMouseLeave={onMouseLeave && onMouseLeave}
        disableRipple={true}
        sx={buttonStyles}
      >
        {restProps.children}
      </Button>
    </PaperContainer>
  );
};

export default GeneralContainer;
