import { Navigate, Route } from 'react-router-dom';

import PaymentCallbackPage from './callback/index';
import PaymentsPage from './index';
import React from 'react';

const PaymentsRoutes = () => {
  return (
    <Route path={'payments/*'} element={<PaymentsPage />}>
      <Route index element={<Navigate to="callback" replace />} />
      <Route exact path="callback" element={<PaymentCallbackPage />} />
    </Route>
  );
};

export default PaymentsRoutes;
