import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../../components/Loading/Loader';
import { useSearchParams } from 'react-router-dom';
import { useCustomNavigation } from '../../hooks/navigation/useCustomNavigation';

export const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const { buildUrlPath } = useCustomNavigation();
  const error = searchParams.get('error');

  useEffect(() => {
    (async () => {
      await loginWithRedirect({
        ...(error ? { flashMessageType: 'info', flashMessageText: error } : {}),
        redirectUri: buildUrlPath()
      });
    })();
  }, []);

  return <Loader />;
};

export default Login;
