import React from 'react';
import FirstPlaceCrown from '../../../assets/firstPlaceCrown.png';
import SecondPlaceCrown from '../../../assets/secondPlaceCrown.png';
import ThirdPlaceCrown from '../../../assets/thirdPlaceCrown.png';

export const createCrown = (hash = false, position, overrideStyle = {}) => {
  let url = null;
  if (hash) {
    switch (hash) {
      case 1:
        url = FirstPlaceCrown;
        break;
      case 2:
        url = SecondPlaceCrown;
        break;
      case 3:
        url = ThirdPlaceCrown;
        break;
      default:
        return null;
    }
  } else {
    switch (position) {
      case 1:
        url = FirstPlaceCrown;
        break;
      case 2:
        url = SecondPlaceCrown;
        break;
      case 3:
        url = ThirdPlaceCrown;
        break;
      default:
        return null;
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: -22,
        left: -16,
        width: '45px',
        ...overrideStyle
      }}
    >
      <img src={url} width={'100%'} height={'auto'} />
    </div>
  );
};
