import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { find, get, isEmpty } from 'lodash';

import Loading from '../../../../../components/Loading/Loader';
import { LocationIcon } from '../../../../../components/Icon/vectors';
import { getStoreAddress } from '../../../../../utils/StringUtil';

const StyledMapContainer = styled(Box)(({ theme }) => ({
  height: '75vh',
  minHeight: '680px',
  width: '100%',
  '& > div': {
    height: '100%',
    width: '100%',
    borderRadius: '8px'
  },
  [theme.breakpoints.down('sm')]: {
    height: '50vh',
    minHeight: '400px'
  }
}));

// eslint-disable-next-line no-unused-vars
const StyledStoreSingleInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg': {
    width: '20px'
  }
}));

const mapControls = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

const StoreMapViewer = ({
  mapCenter,
  data = [],
  gotoStoreId = null,
  zoom = 11,
  setZoomExplicitly = false
}) => {
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const theme = useTheme();

  const getStorePosition = store => {
    return {
      lat: get(store, 'location.geo.coordinates[1]'),
      lng: get(store, 'location.geo.coordinates[0]')
    };
  };

  const handleSelectStore = storeId => {
    if (storeId === selectedStoreId) {
      return;
    }
    setSelectedStoreId(storeId);
  };

  const setMapBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    data.forEach(store => bounds.extend(getStorePosition(store)));
    mapInstance.fitBounds(bounds);
    if (setZoomExplicitly) {
      setTimeout(() => {
        mapInstance.setZoom(zoom);
      }, 1000);
    }
  };

  useEffect(() => {
    if (data.length && mapInstance) {
      setMapBounds();
    }
  }, [data, mapInstance]);

  useEffect(() => {
    if (data.length && mapInstance && gotoStoreId) {
      const findStore = find(data, store => store._id === gotoStoreId);
      if (findStore) {
        const location = getStorePosition(findStore);
        mapInstance.panTo(location);
        mapInstance.setZoom(15);
      }
    } else if (data.length && mapInstance) {
      setMapBounds();
    }
  }, [gotoStoreId, mapInstance, data]);

  if (isEmpty(mapCenter)) {
    return <Loading />;
  }
  return (
    <StyledMapContainer>
      <GoogleMap
        mapContainerStyle={{
          height: '100%',
          width: '100%'
        }}
        center={mapCenter}
        zoom={zoom}
        onLoad={map => setMapInstance(map)}
        options={{
          ...mapControls
        }}
      >
        {data.map(store => {
          return (
            <Marker
              key={store._id}
              position={getStorePosition(store)}
              onClick={() => handleSelectStore(store._id)}
            >
              {selectedStoreId === store._id ? (
                <InfoWindow
                  onCloseClick={() => handleSelectStore(null)}
                  options={{ maxWidth: 400 }}
                >
                  <Grid container spacing={1} padding={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color={theme.palette.neutral[700]}
                      >
                        {store.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <StyledStoreSingleInfoContainer>
                        <LocationIcon color={theme.palette.neutral[700]} />
                        <Typography
                          variant="body3Light"
                          ml={1}
                          color={theme.palette.neutral[700]}
                        >
                          {getStoreAddress(store.location)}
                        </Typography>
                      </StyledStoreSingleInfoContainer>
                    </Grid>
                  </Grid>
                </InfoWindow>
              ) : null}
            </Marker>
          );
        })}
      </GoogleMap>
    </StyledMapContainer>
  );
};

export default StoreMapViewer;
