import { Box, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const StyledEditIcon = styled(EditIcon)`
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  cursor: pointer;
`;

export const LinkWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;

  & a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const LinkEditor = styled(Box)`
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;

  & .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }
`;
