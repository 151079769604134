import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useContext } from 'react';

import { LoadingButton } from '@mui/lab';
import { ModalContext } from '../../../components/Modal/Context/ModalContext';
import ShippingOptionsModal from '../ShippingOptionsModal/index';
import { getFormattedCurrency } from '../../../utils/number';
import { isEmpty } from 'lodash';

const ShippingMethodContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  padding: '16px',
  marginTop: '16px',
  maxWidth: '400px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    maxWidth: '90vw'
  }
}));

const CheckoutButtonWithShipping = ({
  loading,
  disabled,
  isShippingAddressValid,
  hasPhysicalProduct,
  shippingMethod,
  openShippingAddressModal,
  onShippingMethodSelect
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { addModal, closeModal } = useContext(ModalContext);
  const getCheckoutButtonText = () => {
    if (!isShippingAddressValid) {
      return 'Checkout';
    } else if (hasPhysicalProduct && !shippingMethod) {
      return 'Select Shipping Method';
    } else if (hasPhysicalProduct && shippingMethod) {
      return 'Change shipping method';
    } else {
      return 'Checkout';
    }
  };
  const onClickHandler = () => {
    if (!isShippingAddressValid) {
      openShippingAddressModal();
    } else if (hasPhysicalProduct) {
      addModal({
        children: (
          <ShippingOptionsModal
            onCloseModal={() => closeModal()}
            onSelectShippingMethod={onShippingMethodSelect}
            shippingMethod={shippingMethod}
          />
        )
      });
    }
  };
  return (
    <>
      <LoadingButton
        sx={{
          padding: isXs ? '12px 16px' : '22px 75px',
          minWidth: isXs ? '250px' : 'auto'
        }}
        fullWidth={isXs}
        variant="contained"
        loading={loading}
        onClick={onClickHandler}
        disabled={disabled}
        data-testid="update-shipping-method-button"
      >
        {getCheckoutButtonText()}
      </LoadingButton>
      {!isEmpty(shippingMethod) && (
        <ShippingMethodContainer>
          <Typography data-testid="shipping-carrier-name">
            {shippingMethod.carrierName} ({shippingMethod.serviceName})
          </Typography>
          <Typography data-testid="shipping-method-cost">
            {getFormattedCurrency(shippingMethod.amount)}
          </Typography>
        </ShippingMethodContainer>
      )}
    </>
  );
};

export default CheckoutButtonWithShipping;
