import { Grid, styled } from '@mui/material';

export const StickyComponent = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  padding: 1,
  boxShadow: `0px 0px 0px 1px ${theme.palette.background.paper}`,
  background: theme.palette.background.paper,
  width: '100%',
  zIndex: 10,
  ['&.header']: {
    top: 0,
    paddingBottom: theme.spacing(2)
  },
  ['&.footer']: {
    bottom: 0,
    paddingTop: theme.spacing(2)
  }
}));
