import React, { useContext } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie';

import { Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { getRoundDetails, singleEventInfo } from '../../../../utils/EventUtils';
import {
  useGetSingleEventPairingsQuery,
  useGetSingleEventQuery
} from '../../../../services/apis/organizePlayApi';

import { EventPlayersScoreCard } from '../../../../components/Shared/Cards/styles';
import GeneralContainer from '../../../../components/Shared/Cards/GeneralCard';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { ModalContext } from '../../../../components/Modal/Context/ModalContext';
import ReportScoreModal from './ModalPages/ReportScoreModal';
import { RosterStatus } from '../../../../components/Events/RosterStatus';
import { eventStatuses } from '../../../../values/event-values';

import { PlayerResult } from './style';

import * as akoraBattleAnimation from '../../../../assets/animations/battle/akora.json';
import * as akoraByeAnimation from '../../../../assets/animations/bye/akora.json';
import * as defaultBattleAnimation from '../../../../assets/animations/battle/cardeio.json';
import * as defaultByeAnimation from '../../../../assets/animations/bye/cardeio.json';
import * as elestralsBattleAnimation from '../../../../assets/animations/battle/elestrals.json';
import * as elestralsByeAnimation from '../../../../assets/animations/bye/elestrals.json';
import * as interstellarBattleAnimation from '../../../../assets/animations/battle/interstellar.json';
import * as interstellarByeAnimation from '../../../../assets/animations/bye/interstellar.json';
import * as kryptikBattleAnimation from '../../../../assets/animations/battle/kryptik.json';
import * as kryptikByeAnimation from '../../../../assets/animations/bye/kryptik.json';
import * as metazooBattleAnimation from '../../../../assets/animations/battle/metazoo.json';
import * as metazooByeAnimation from '../../../../assets/animations/bye/metazoo.json';
import * as solforgeBattleAnimation from '../../../../assets/animations/battle/solforge.json';
import * as solforgeByeAnimation from '../../../../assets/animations/bye/solforge.json';
import defaultBattleIcon from '../../../../assets/images/battle/default.png';
import metazooBattleIcon from '../../../../assets/images/battle/metazoo.png';

const PairingCard = ({ pairing, goToRound, totalPairings }) => {
  // React Router Hooks
  const { eventId } = useParams();
  const theme = useTheme();

  // Context Hooks
  const { addModal } = useContext(ModalContext);

  // Redux Hooks
  const { data: activeEvent } = useGetSingleEventQuery(eventId);
  const { data: pairingsData } = useGetSingleEventPairingsQuery({
    eventId,
    roundNumber: goToRound
  });
  const { game, user } = useSelector(({ app }) => app);

  const battleIcons = {
    metazoo: metazooBattleIcon
  };

  const battleAnimation = {
    akora: akoraBattleAnimation,
    elestrals: elestralsBattleAnimation,
    interstellar: interstellarBattleAnimation,
    kryptik: kryptikBattleAnimation,
    metazoo: metazooBattleAnimation,
    solforge: solforgeBattleAnimation
  };

  const battleOptions = {
    loop: true,
    autoplay: true,
    animationData: battleAnimation[game.slug] || defaultBattleAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const byeAnimation = {
    akora: akoraByeAnimation,
    elestrals: elestralsByeAnimation,
    interstellar: interstellarByeAnimation,
    kryptik: kryptikByeAnimation,
    metazoo: metazooByeAnimation,
    solforge: solforgeByeAnimation
  };

  const byeOptions = {
    loop: true,
    autoplay: true,
    speed: 0.5,
    animationData: byeAnimation[game.slug] || defaultByeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    isOrganizer,
    status,
    currentRound,
    regularRounds,
    tournament,
    isJudge
  } = singleEventInfo(activeEvent);
  const { roundNumber } = getRoundDetails(
    get(pairingsData, 'roundDetails', {})
  );

  const bestOf = get(tournament, 'bestOf');

  const eventNotComplete = status !== eventStatuses.complete.key;
  const isCurrentRound = currentRound === roundNumber;
  const canPlayerReportScore =
    (get(pairing, 'playerOne.userId') === get(user, 'details.id') ||
      get(pairing, 'playerTwo.userId') === get(user, 'details.id')) &&
    get(pairing, 'active', false) &&
    eventNotComplete &&
    isCurrentRound;

  const isBye = !get(pairing, 'playerTwo', false);

  const canReportResults =
    (eventNotComplete &&
      (isOrganizer || isJudge) &&
      isCurrentRound &&
      !isBye) ||
    (canPlayerReportScore && !isBye);

  const playerOneWins = get(pairing, 'results.playerOneWins', 0);
  const playerTwoWins = get(pairing, 'results.playerTwoWins', 0);

  const playerOneStatus =
    playerOneWins === playerTwoWins
      ? 'Draw'
      : playerOneWins > playerTwoWins
      ? 'Win'
      : 'Loss';

  const playerTwoStatus =
    playerOneWins === playerTwoWins
      ? 'Draw'
      : playerOneWins < playerTwoWins
      ? 'Win'
      : 'Loss';

  const createModal = () => {
    if (canReportResults) {
      addModal({
        children: (
          <ReportScoreModal
            eventId={eventId}
            bestOf={bestOf}
            sx={{ maxWidth: '300px' }}
            activeScoreCard={pairing}
            isPlayoffs={
              goToRound > regularRounds ||
              tournament.format === 'single-elimination' ||
              tournament.status === 'stage-two'
            }
          />
        )
      });
    }
  };

  const loggedUserId = get(user, 'details.id', '');
  const isLoggedInUser = () => {
    if (
      loggedUserId &&
      (loggedUserId === get(pairing, 'playerOne.userId') ||
        loggedUserId === get(pairing, 'playerTwo.userId'))
    ) {
      return true;
    }

    return false;
  };

  return (
    <GeneralContainer
      onClick={canReportResults ? createModal : undefined}
      buttonStyle={{
        padding: '16px 24px',
        boxShadow: `${
          isLoggedInUser() ? theme.palette.primary.main : 'transparent'
        } 0px 0px 16px 4px`
      }}
    >
      <Grid
        container
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        spacing={4}
        wrap={'nowrap'}
      >
        {pairing.playerTwo ? (
          <>
            <Grid item>
              <Grid
                container
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                <RosterStatus
                  textAlign={'md'}
                  alignBottomItems={{ xs: 'center', md: 'flex-start' }}
                  result={{
                    isActive: pairing.active,
                    gamesWon: playerOneWins,
                    status: playerOneStatus
                  }}
                  score
                  user={pairing.playerOne}
                  shouldCenterTeamName={true}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container flexDirection="column" alignItems="center">
                <EventPlayersScoreCard
                  container
                  item
                  wrap={'nowrap'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {pairing.active ? (
                    <Grid item>
                      <PlayerResult
                        className={
                          pairing.active ? '' : playerTwoStatus.toLowerCase()
                        }
                      >
                        {totalPairings < 24 ? (
                          <Lottie
                            options={battleOptions}
                            height={56}
                            width={56}
                          />
                        ) : (
                          <img
                            src={battleIcons[game.slug] || defaultBattleIcon}
                            height={40}
                            width={40}
                          />
                        )}
                      </PlayerResult>
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <PlayerResult
                          className={
                            pairing.active ? '' : playerOneStatus.toLowerCase()
                          }
                        >
                          {!pairing.active && playerOneStatus === 'Win' && (
                            <CheckIcon />
                          )}
                          {!pairing.active && playerOneStatus === 'Loss' && (
                            <ClearIcon />
                          )}
                          {!pairing.active && playerOneStatus === 'Draw' && (
                            <HandshakeIcon />
                          )}
                        </PlayerResult>
                      </Grid>
                      <Grid item marginLeft={-1}>
                        <PlayerResult
                          className={
                            pairing.active ? '' : playerTwoStatus.toLowerCase()
                          }
                        >
                          {!pairing.active && playerTwoStatus === 'Win' && (
                            <CheckIcon />
                          )}
                          {!pairing.active && playerTwoStatus === 'Loss' && (
                            <ClearIcon />
                          )}
                          {!pairing.active && playerTwoStatus === 'Draw' && (
                            <HandshakeIcon />
                          )}
                        </PlayerResult>
                      </Grid>
                    </>
                  )}
                </EventPlayersScoreCard>
                <Typography
                  variant="body2"
                  sx={{
                    letterSpacing: '2px',
                    marginTop: 1,
                    textTransform: 'uppercase',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Table {pairing.tableNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justifyContent={{ xs: 'center', md: 'flex-end' }}>
                <RosterStatus
                  flip
                  textAlign={'md'}
                  alignBottomItems={{ xs: 'center', md: 'flex-end' }}
                  result={{
                    isActive: pairing.active,
                    gamesWon: playerTwoWins,
                    status: playerTwoStatus
                  }}
                  score
                  user={pairing.playerTwo}
                  shouldCenterTeamName={true}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          // Show bye
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              wrap={'nowrap'}
              flexDirection={{ xs: 'column', md: 'row' }}
              gap={4}
            >
              <Grid item xs={6}>
                <Grid container justifyContent={{ xs: 'center', md: 'unset' }}>
                  <RosterStatus
                    textAlign={'md'}
                    result={{
                      isActive: true,
                      gamesWon: 0,
                      status: 'Bye'
                    }}
                    alignBottomItems={{ xs: 'center', md: 'flex-start' }}
                    score
                    user={pairing.playerOne}
                    shouldCenterTeamName={true}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={1}>
                <PlayerResult className="bye">
                  <Lottie options={byeOptions} height={96} width={96} />
                </PlayerResult>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </GeneralContainer>
  );
};

export default PairingCard;
