import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useContext } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import { FilterContext } from '../Rewards';

const RewardSearch = ({ setShowFilter, showFilter }) => {
  const { updateFilter } = useContext(FilterContext);
  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between" spacing={3}>
        <Grid item xs={12} sm={8}>
          <TextField
            id="outlined-start-adornment"
            data-testid="rewards-search-item"
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent'
            }}
            placeholder="Search"
            name="name"
            onChange={e => updateFilter({ name: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{
                      transform: 'scale(1.3)'
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            className="toggleButton"
            data-testid="rewards-filter-button"
            sx={theme => ({
              border: `${theme.border.borderWidth}px solid ${theme.border.borderColor}`,
              '&.MuiButton-root.toggleButton:hover': {
                backgroundColor: 'transparent'
              }
            })}
            onClick={() => setShowFilter(!showFilter)}
          >
            Filters
            <TuneIcon />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RewardSearch;
