import { useMediaQuery, useTheme } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import React from 'react';

const CheckoutButtonWithPayment = ({ onClickPayment, loading, disabled }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <LoadingButton
      sx={{
        padding: isXs ? '12px 16px' : '22px 75px',
        minWidth: isXs ? '250px' : 'auto'
      }}
      fullWidth={isXs}
      variant="contained"
      loading={loading}
      onClick={onClickPayment}
      disabled={disabled}
    >
      Continue to Payment
    </LoadingButton>
  );
};

export default CheckoutButtonWithPayment;
