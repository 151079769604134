import { Box, Typography, styled } from '@mui/material';
import {
  getFormattedCurrency,
  getUserReadableCurrencyAmount
} from '../../../utils/number';

import React from 'react';
import Tooltip from '../Tooltip/index';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const StyledCurrencyContainer = styled(Box)(() => ({
  ['.MuiIconButton-root']: {
    padding: 0
  }
}));

const StyledCurrencyWithFiatContainer = styled(Box)(({ showInSingleLine }) => ({
  display: 'flex',
  flexDirection: showInSingleLine ? 'row' : 'column',
  alignItems: showInSingleLine ? 'baseline' : 'flex-end',
  justifyContent: 'flex-start'
}));

const StyledFiatCurrencyContainer = styled(Box)(
  ({ showInSingleLine, amount }) => ({
    marginLeft: showInSingleLine && amount ? '16px' : '0',
    marginTop: '5px'
  })
);

const StoreCurrency = ({
  amount,
  symbol,
  currencyId,
  containerProps = {},
  imageSize = '24px',
  fiatPrice = null,
  showInSingleLine = false,
  ...restProps
}) => {
  const { game } = useSelector(({ app }) => app);
  const symbolToCurrencyMap = get(
    game,
    'pgGame.currencySymbolIdToCurrency',
    {}
  );
  const idToCurrencyMap = get(game, 'pgGame.currencyIdToCurrency', {});
  const currencyIcon = symbol
    ? get(
        symbolToCurrencyMap,
        `${symbol.toUpperCase()}.resourceImages.0.image.url`,
        null
      )
    : currencyId
    ? get(idToCurrencyMap, `${currencyId}.resourceImages.0.image.url`)
    : null;

  const renderCoinCurrency = () => {
    if (!amount) {
      return null;
    }
    return (
      <StyledCurrencyContainer
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        imageSize={imageSize}
        {...containerProps}
      >
        {(amount !== null || amount !== undefined) && (
          <Typography
            {...restProps}
            mr={1}
            paddingTop="5px"
            data-testid="currency-amount-coins"
          >
            {amount}
          </Typography>
        )}
        {currencyIcon ? (
          <Tooltip
            disableInteractive
            buttonSize={imageSize}
            icon={
              <img
                width={imageSize}
                height={imageSize}
                src={currencyIcon}
                alt={symbol || 'points'}
              />
            }
            info={symbol}
          />
        ) : (
          <Typography {...restProps}>{symbol || 'points'}</Typography>
        )}
      </StyledCurrencyContainer>
    );
  };

  if (!fiatPrice || !fiatPrice.amount || !fiatPrice.symbol) {
    return renderCoinCurrency();
  }

  return (
    <StyledCurrencyWithFiatContainer showInSingleLine={showInSingleLine}>
      {renderCoinCurrency()}
      <StyledFiatCurrencyContainer
        showInSingleLine={showInSingleLine}
        amount={amount}
      >
        <Typography color="primary" {...restProps}>
          {getFormattedCurrency(
            getUserReadableCurrencyAmount(fiatPrice.amount, fiatPrice.currency),
            fiatPrice.currency
          )}
        </Typography>
      </StyledFiatCurrencyContainer>
    </StyledCurrencyWithFiatContainer>
  );
};

export default StoreCurrency;
