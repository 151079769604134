import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import InfiniteScroll from '../../../components/InfiniteScroll/index';
import Loading from '../../../components/Loading/Loader';
import OrderCard from './OrderCard';
import { PaperContainer } from '../../../components/Shared/Paper';
import { orderGridStyles } from './styles';
import { useGetOrdersQuery } from '../../../services/apis/organizePlayApi';
import { usePagination } from '../../../hooks/pagination/usePagination';

const RewardsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isFetching } = useGetOrdersQuery({
    page: currentPage,
    limit: 12
  });
  const { loadAction, treatedData, nextPage, totalResults } = usePagination({
    data,
    dataKey: 'data',
    setCurrentPage,
    isLoading: isLoading,
    isFetching: isFetching
  });
  return (
    <Grid item xs={12}>
      <PaperContainer className="user-profile">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={'h3'} sx={{ mb: 4 }}>
              Order History
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InfiniteScroll
              isLoading={isLoading}
              done={nextPage === 0 || totalResults === treatedData?.length}
              loaderAction={loadAction}
              loader={<Loading />}
            >
              {!isLoading && treatedData && treatedData.length ? (
                <Box sx={orderGridStyles}>
                  {treatedData.map(order => (
                    <OrderCard order={order} key={order.id} />
                  ))}
                </Box>
              ) : null}
            </InfiniteScroll>
            {!isLoading &&
            !isFetching &&
            (!treatedData || !treatedData.length) ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography variant="h3" color="primary">
                  No orders found!
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </PaperContainer>
    </Grid>
  );
};

export default RewardsPage;
