import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { displayableDate } from '../../../../utils/date';

const HowToGetSeasonPointsModal = ({ seasonId }) => {
  const [activeSeason, setActiveSeason] = useState(null);
  const { game } = useSelector(({ app }) => app);
  const seasonPointTerm = get(
    game,
    'terminology.points.seasonTotal',
    'Season Points'
  );

  useEffect(() => {
    const selectedSeason = game.seasons.filter(
      season => season._id === seasonId
    );

    setActiveSeason(selectedSeason[0]);
  }, []);

  return (
    <Grid container alignItems={'flex-start'} justifyContent={'flex-start'}>
      <Typography variant="h4">{`How do I receive ${seasonPointTerm}?`}</Typography>
      <Typography
        mt={2}
        variant="body2"
      >{`In order to receive ${seasonPointTerm}, you must participate and play in local and online events! See below for a breakdown on ${seasonPointTerm}.`}</Typography>

      <Grid mt={4}>
        {get(activeSeason, 'seasonPointValues.participation', 0) > 0 && (
          <Grid>
            <Grid container alignItems="center">
              <Typography variant="body3bold">Event Participation:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.participation',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.playMatch', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">Match Played:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.playMatch',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.winMatch', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">Match Won:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.winMatch',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.topCut', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">Top Cut in Event:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.topCut',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.third', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">3rd Place in Event*:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.third',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.second', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">2nd Place in Event*:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.second',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}

        {get(activeSeason, 'seasonPointValues.first', 0) > 0 && (
          <Grid mt={2}>
            <Grid container alignItems="center">
              <Typography variant="body3bold">1st Place in Event*:</Typography>
              <Typography ml={1} mb={-0.25} variant="body3">
                {`${get(
                  activeSeason,
                  'seasonPointValues.first',
                  0
                )} ${seasonPointTerm}`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid sx={{ mt: 4, width: '100%' }}>
        <Typography variant="body4Light">
          *In order for an event to receive placement points it must have 5 or
          more participants and 3 or more total rounds.
        </Typography>
      </Grid>

      <Grid sx={{ mt: 2, width: '100%' }}>
        <Typography
          mt={4}
          variant="h4"
        >{`When do ${seasonPointTerm} stop accruing?`}</Typography>
        <Typography
          mt={2}
          variant="body2"
        >{`${seasonPointTerm} for this season stops accruing on ${displayableDate(
          get(activeSeason, 'endDate')
        )}. After this time, a Champion will be determined for the season based on the total ${seasonPointTerm} and season rewards will be fulfilled shortly after!`}</Typography>
      </Grid>
    </Grid>
  );
};

export default HowToGetSeasonPointsModal;
