import { Chip, styled } from '@mui/material';

export const EventStatusChipStyled = styled(Chip, {
  shouldForwardProp: prop => prop !== 'bgColor' && prop !== 'textColor'
})(({ bgColor, textColor }) => ({
  backgroundColor: bgColor,
  position: 'absolute',
  bottom: '8px',
  left: '8px',
  color: textColor,
  boxShadow: '0 0 10px 1px #1a1a1a',
  fontSize: '1rem'
}));
