import React from 'react';
// import { get } from 'lodash';

import { Grid, Typography, styled } from '@mui/material';
export const GeneralEventCard = styled(Grid)`
  background: ${({ theme }) => theme.layout.backgrounds.block};
`;

const RewardCard = ({ reward }) => {
  return (
    <GeneralEventCard container>
      <Grid container justifyContent="center">
        <Typography
          sx={{ mb: 2, textAlign: 'center', width: '100%' }}
          variant="h4"
        >
          {reward.name}
        </Typography>
        <img src={reward.prize} height={'80px'} width={'70%'} />

        <Typography
          sx={{ mb: 2, mt: 2, textAlign: 'center', width: '100%' }}
          variant="body2"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing.
        </Typography>
      </Grid>
    </GeneralEventCard>
  );
};

export default RewardCard;
