// eslint-disable-next-line no-unused-vars
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  useGetSingleEventQuery,
  usePostAddParticipantToEventMutation
} from '../../../../../services/apis/organizePlayApi';

import AddByUserEmail from './AddByUserEmail';
import AddByUserName from './AddByUserName';
import { ModalContext } from '../../../../Modal/Context/ModalContext';
import SimpleModalCard from '../../../../Modal/Cards/SimpleModalCard';
import { addTypes } from './utils';
import { singleEventInfo } from '../../../../../utils/EventUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

const AddParticipantsSection = () => {
  // Context Hooks
  const { closeModal, setIsLoading, isLoading } = useContext(ModalContext);
  // Router Dom Hooks
  const { eventId } = useParams();
  const { ticketing } = useFlags();

  // Redux Hooks
  const { data: activeEvent } = useGetSingleEventQuery(eventId);
  const [addPlayer, addPlayerResult] = usePostAddParticipantToEventMutation();
  const { capacity, participants: participantCount } = singleEventInfo(
    activeEvent
  );

  const initialParticipantsState = [{ firstName: '', lastName: '' }];

  const [foundUserByEmail, setFoundUserByEmail] = useState(false);
  const [addType, setAddType] = useState(addTypes.email);
  const [participants, setParticipants] = useState(initialParticipantsState);
  const [hideEmailJoin, setHideEmailJoin] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const handleAddParticipant = () => {
    const values = [...participants];
    values.push({
      firstName: '',
      lastName: ''
    });
    setParticipants(values);
  };
  const addPlayerAction = () => {
    addPlayer({ eventId, body: participants[0] });
  };

  useEffect(() => {
    setParticipants(initialParticipantsState);
    setFoundUserByEmail(false);
    addPlayerResult.data = {};
  }, [addType]);

  useEffect(() => {
    setIsLoading(addPlayerResult.isLoading);
    if (addPlayerResult.isSuccess && !addPlayerResult.data.errorMessage) {
      closeModal();
    }
  }, [addPlayerResult]);

  useEffect(() => {
    if (activeEvent) {
      const { payoutEnabled } = activeEvent;
      if (ticketing && payoutEnabled) {
        setAddType(addTypes.guest);
        setHideEmailJoin(true);
      }
    }
  }, [activeEvent]);

  const checkDisabledMode = () => {
    if (participantCount >= capacity) return true;
    if (participants.length === 0) return true;
    if (addType === addTypes.guest) {
      return !participants[0].firstName;
    }

    return !foundUserByEmail;
  };

  const handleChangeType = e => {
    setAddType(e.target.value);
  };
  return (
    <SimpleModalCard
      title={'Add Player'}
      cancelButton={{ id: 'add-participant-cancel-button' }}
      confirmButton={{
        id: 'add-participant-confirm-button',
        onClick: addPlayerAction,
        title: 'Add',
        disabled: checkDisabledMode()
      }}
    >
      <Grid>
        {!hideEmailJoin && (
          <RadioGroup
            id="add-participant-select-type-radio-buttons-group"
            data-testid="add-participant-select-type-radio-buttons-group"
            aria-labelledby="select-type-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={addType}
            onChange={handleChangeType}
            row
          >
            <FormControlLabel
              value={addTypes.email}
              control={
                <Radio
                  id="add-participant-email-type-radio"
                  data-testid="add-participant-email-type-radio"
                />
              }
              label="Add by Email"
              disabled={isLoading}
            />
            <FormControlLabel
              value={addTypes.guest}
              control={
                <Radio
                  id="add-participant-guest-type-radio"
                  data-testid="add-participant-guest-type-radio"
                />
              }
              label="Add Guest"
              disabled={isLoading}
            />
          </RadioGroup>
        )}
        <Grid
          container
          sx={theme => ({
            backgroundColor: `${theme.layout.backgrounds.primary}5A`,
            borderRadius: '8px',
            mb: 2,
            p: { xs: 1, sm: 4 }
          })}
        >
          {participants.length > 0 && addType === addTypes.guest && (
            <AddByUserName
              participants={participants}
              setParticipants={setParticipants}
            />
          )}
          {addType === addTypes.email && (
            <AddByUserEmail
              addPlayerResult={addPlayerResult}
              setFoundUserByEmail={setFoundUserByEmail}
              setParticipants={setParticipants}
              participants={participants}
              setIsLoading={setIsLoading}
            />
          )}
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};

export default AddParticipantsSection;
