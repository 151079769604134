import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';

import DropDownMenuOptions from '../../DropDownMenuOptions';
import { buildRoutePath } from '../../../utils/routingUtils';
import { useDeck } from '../../../pages/Decks/hooks/useDeck';
import { dropDownContainerStyle } from './styles';

/** TODO: Add clone functionality **/
const DeckDropDown = ({ deck, disableHover, redirectToDecks }) => {
  const { user } = useSelector(({ app }) => app);
  const { gameSlug } = useParams();
  const { deleteModalAction } = useDeck();

  const navigate = useNavigate();
  const publicOptions = [
    {
      title: 'Copy TTS Code',
      key: 'tts',
      icon: <ShareIcon />,
      action: () => {
        navigator.clipboard.writeText(deck.hash);
        toast.success('TTS Code Successfully Copied to Clipboard!');
      }
    }
  ];
  const options = [
    {
      title: 'Edit',
      key: 'edit',
      icon: <EditIcon />,
      action: () => {
        deck?._id &&
          navigate(buildRoutePath(gameSlug, `/decks/${deck._id}/edit`));
      }
    },
    {
      title: 'Delete',
      key: 'delete',
      icon: <DeleteIcon />,
      action: () => {
        user?.details?.id === deck?.userId &&
          deleteModalAction(deck?._id, redirectToDecks);
      }
    }
    /** TODO: Add Sharing later **/
    // { title: 'Share', key: 'share', icon: <ShareIcon /> }
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      className={`${isOpen ? 'open' : ''} ${
        disableHover ? 'disable-hover' : ''
      }`}
      sx={dropDownContainerStyle}
    >
      <Box
        id={`deck-card-${deck?._id}-more-options-select-button`}
        data-testid={`deck-card-${deck?._id}-more-options-select-button`}
        className={'more-options'}
      >
        <DropDownMenuOptions
          id={`deck-card-more-options-${options.key}`}
          data-testid={`deck-card-more-options-${options.key}`}
          options={
            user?.details?.id === deck?.userId
              ? [...options, ...publicOptions]
              : publicOptions
          }
          menuAction={open => setIsOpen(open)}
        />
      </Box>
    </Box>
  );
};

export default DeckDropDown;
