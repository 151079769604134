import { Box, Grid, Typography, alpha, styled } from '@mui/material';
import { get } from 'lodash';

import { LocationIcon } from '../../../../../components/Icon/vectors';
import React from 'react';
import SocialLinks from './SocialLinks';
import StoreHours from './StoreHours';
import StoreMapViewer from '../../../Locator/components/StoreMapView';
import { getStoreAddress } from '../../../../../utils/StringUtil';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const StyledStoreSingleInfoContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg': {
    width: '20px'
  }
}));

const StoreInformation = ({ store }) => {
  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const dummyHeader = `https://storage.googleapis.com/cardeio-images/defaults/${game?.slug}/events/1.webp`;

  const textColor = alpha(
    theme.palette.getContrastText(theme.palette.background.paper),
    0.7
  );

  const storeIsClosed = get(store, 'info.openingHours', []).every(
    item => !item.isOpen
  );

  return (
    <Grid container justifyContent="space-between" mt={8}>
      <Grid
        sx={{
          backgroundColor: get(theme, 'palette.background.paper'),
          borderRadius: 2,
          p: { xs: 2, sm: 4 },
          width: { xs: '100%', md: '40%' },
          mb: { xs: 4, md: 0 }
        }}
      >
        <Grid
          mb={4}
          sx={{
            backgroundImage: `url(${dummyHeader})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '200px',
            borderRadius: theme.spacing(2),
            opacity: 0.7,
            width: '100%'
          }}
        />
        <Typography mb={4} variant="h3" sx={{ wordBreak: 'break-all' }}>
          {get(store, 'name')}
        </Typography>
        <Box display="flex" flexDirection="column" gap={4}>
          <StyledStoreSingleInfoContainer>
            <LocationIcon color={theme.palette.text.primary} />
            <Typography variant="body2Light" ml={1} color={textColor}>
              {getStoreAddress(store.location)}
            </Typography>
          </StyledStoreSingleInfoContainer>
          {!storeIsClosed && <StoreHours store={store} />}
          <SocialLinks store={store} />
        </Box>
      </Grid>
      <Grid sx={{ width: { xs: '100%', md: '55%' } }}>
        <StoreMapViewer
          data={[store]}
          mapCenter={get(store, 'location.geo.coordinates')}
          zoom={14}
          setZoomExplicitly={true}
        />
      </Grid>
    </Grid>
  );
};

export default StoreInformation;
