import React, { useContext } from 'react';
import { capitalize, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';

import { ModalContext } from '../../Modal/Context/ModalContext';
import { PlayerDeckDetailsModal } from '../PlayerDeckDetailsModal';
import { PlayerScoreCard } from '../../../pages/Events/Single/EventPairingsPage/style';
import { PlayerTeamName } from '../PlayerTeamName';

export const RosterStatus = ({
  user = { name: 'test' },
  showAvatar = true,
  showUserInfo = true,
  flip = false,
  result = {},
  score = false,
  overlay = null,
  textAlign = 'sm',
  constantTextAlign = '',
  alignBottomItems = null,
  shouldCenterTeamName = false,
  centerBreakpointTeamName = 'md',
  showDeckDetails = false
}) => {
  const alignItems = flip ? 'flex-end' : 'flex-start';
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(textAlign));
  const showDeckDetailsButton = showDeckDetails && !isEmpty(get(user, 'deck'));
  const { addModal } = useContext(ModalContext);
  const { game } = useSelector(({ app }) => app);

  const alignText = () => {
    if (constantTextAlign) {
      return constantTextAlign;
    }
    return isSmallScreen ? 'center' : flip ? 'end' : 'unset';
  };

  const onClickViewDeck = event => {
    event.stopPropagation();
    event.preventDefault();
    addModal({
      children: <PlayerDeckDetailsModal deckDetails={get(user, 'deck')} />
    });
  };

  return (
    <>
      {/* Avatar Picture */}
      {showAvatar && (
        <Grid item style={{ position: 'relative' }}>
          <Avatar
            src={get(user, 'imageUrl')}
            sx={{ height: '64px', width: '64px' }}
          />
          {overlay && overlay}
        </Grid>
      )}
      {showUserInfo && (
        <Grid item>
          <Grid
            container
            item
            direction={'column'}
            alignItems={alignBottomItems || alignItems}
            justifyContent={{ xs: 'center', sm: 'unset' }}
            gap={0.5}
          >
            {/* Name */}
            <Grid item>
              <Box
                sx={{
                  display: 'table',
                  tableLayout: 'fixed',
                  width: '100%',
                  borderCollapse: 'collapse'
                }}
              >
                <Typography
                  variant={'h3'}
                  sx={{
                    display: 'table-cell',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textAlign: alignText()
                  }}
                >
                  {`${capitalize(get(user, 'firstName', ''))} ${capitalize(
                    get(user, 'lastName', '')
                  )}`}
                </Typography>
              </Box>
              <PlayerTeamName
                teamName={get(user, 'teamName')}
                flip={flip}
                shouldCenter={shouldCenterTeamName}
                centerBreakpoint={centerBreakpointTeamName}
              />
            </Grid>

            {score && (
              <PlayerScoreCard
                className={!result.isActive ? result.status.toLowerCase() : ''}
                item
                style={{ color: !result.isActive ? 'white' : '' }}
              >
                <Typography style={{ textAlign: 'end' }} variant={'body2'}>
                  {result.isActive
                    ? result.status === 'Bye'
                      ? 'Bye'
                      : 'In Progress'
                    : `${result.status}: ${result.gamesWon}`}
                </Typography>
              </PlayerScoreCard>
            )}
          </Grid>
        </Grid>
      )}
      {showDeckDetailsButton && (
        <Grid item>
          <Button
            sx={{
              minWidth: '180px',
              padding: '2px 6px',
              textTransform: 'uppercase'
            }}
            variant="contained"
            color="primary"
            onClick={event => onClickViewDeck(event)}
          >
            View {get(game, 'terminology.deck', 'Deck')}
          </Button>
        </Grid>
      )}
    </>
  );
};
