import { Box, Button, styled } from '@mui/material';

// eslint-disable-next-line no-unused-vars
const borderWidthValue = '2px';
export const SingleCardMainStyle = styled(Box)(
  ({ open, ismedium, error, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    border: `2px solid ${theme?.border?.borderColor}`,
    borderRadius: '12px',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    gap: 1,
    ['.single-card-selections']: {
      visibility: 'hidden',
      pointerEvents: `${open || ismedium !== 'true' ? 'unset' : 'none'}`,
      opacity: 0,
      ...(open && {
        opacity: 1
      })
    },
    [':hover']: {
      ['.single-card-selections']: {
        background: `${theme.palette.background.paper}66`,
        opacity: 1,
        visibility: open || ismedium !== 'true' ? 'visible' : 'invisible',
        pointerEvents: 'unset'
      }
    },
    ...(error && { borderColor: theme.palette.error.main })
  })
);

export const CardButtonSelector = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.light + 'CC',
  color: '#FFF',
  height: '35px',
  minWidth: '0px',
  padding: 8,
  borderRadius: '12px',
  border: `2px solid ${theme.palette.primary.main}`,
  [':hover']: {
    background: theme.palette.primary.main,
    border: '2px solid #FFF5'
  }
}));

export const SingleCardInfoContainer = styled(Box)(
  ({ error, image, theme }) => ({
    display: 'grid',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${image})`,
    backgroundPositionX: 'center',
    backgroundPositionY: '35%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150%',
    boxShadow: `0px 0px 0px 2px ${theme?.border?.borderColor}`,
    borderRadius: '10px',
    overflow: 'hidden',
    ['*']: {
      gridRow: 1,
      gridColumn: 1
    },
    ...(error && {
      boxShadow: `0px 0px 0px 2px ${theme.palette.error.main}`
    })
  })
);

export const SingleCardNameContainer = styled(Box)`
  background-color: #0008;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 8px;

  * {
    text-shadow: 0 0 4px black;
    text-transform: uppercase;
  }
`;

export const singleCardSelectionStyle = () => ({
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  paddingRight: 1,
  gridRow: 1,
  gridColumn: 1,
  [':hover']: {},
  zIndex: 1,
  gap: 0.25,
  transition: 'opacity 0.3s',
  ['.add-sub-buttons-container']: {
    display: 'inherit',
    gap: 0.5,
    flexDirection: 'inherit'
  }
});
