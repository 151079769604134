import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import React from 'react';
import { buildRoutePath } from '../../utils/routingUtils';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

// Used for the StatusPage type prop.
export const statusTypes = {
  error: 'error',
  eventNotFound: 'eventNotFound',
  deckNotFound: 'deckNotFound',
  notFound: 'notFound',
  successful: 'successful'
};

const StatusPage = ({
  type = statusTypes.error,
  customTitle = undefined,
  body = undefined
}) => {
  // router dom hooks
  const navigate = useNavigate();
  const { gameSlug } = useParams();

  // Theme hooks
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  // Constants
  const iconSize = smallDevice ? '75px' : '100px';

  const iconTheme = {
    width: iconSize,
    height: iconSize,
    fill:
      type === statusTypes.successful
        ? get(theme, 'palette.success.main', 'green')
        : get(theme, 'palette.error.main', 'red'),
    background: 'white',
    borderRadius: 20
  };

  // Functions
  const getTitle = type => {
    switch (type) {
      case statusTypes.error:
        return 'Oops Something is Wrong';
      case statusTypes.deckNotFound:
        return 'Deck Not Found';
      case statusTypes.eventNotFound:
        return 'Event Not Found';
      case statusTypes.notFound:
        return 'Page Not Found';
      case statusTypes.successful:
        return 'Success!';
    }
  };

  const getButtonTitle = type => {
    switch (type) {
      case statusTypes.eventNotFound:
        return 'Back To Events';
      case statusTypes.deckNotFound:
        return 'Back To Decks';
      default:
        return 'Back To Home';
    }
  };

  const getIcon = type => {
    switch (type) {
      case statusTypes.successful:
        return <CheckCircleIcon sx={iconTheme} />;
      default:
        return <ErrorIcon sx={iconTheme} />;
    }
  };

  const navigateHome = () => {
    let path = '';
    switch (type) {
      case statusTypes.eventNotFound:
        path = '/events';
        break;
      case statusTypes.deckNotFound:
        path = '/decks';
        break;
      default:
        break;
    }
    navigate(buildRoutePath(gameSlug, path));
  };

  return (
    <MaxWidthContainer container className={'p-percent p-top'}>
      <Grid
        container
        flexDirection={'column'}
        alignItems={'center'}
        spacing={4}
      >
        {/* Icon */}
        <Grid item>{getIcon(type)}</Grid>

        {/* Title */}
        <Grid item>
          <Typography variant={'h1'} textAlign={'center'}>
            {customTitle || getTitle(type)}
          </Typography>
        </Grid>

        {/* Additional Description */}
        {body && (
          <Grid item maxWidth={'600px'}>
            <Typography textAlign={'center'}>{body}</Typography>
          </Grid>
        )}

        {/* Only show return to home button if status type is successful */}
        {type !== statusTypes.error && (
          <Grid item>
            <Button
              id="home-page-link-button"
              data-testid="home-page-link-button"
              onClick={navigateHome}
              variant={'contained'}
            >
              {getButtonTitle(type)}
            </Button>
          </Grid>
        )}
      </Grid>
    </MaxWidthContainer>
  );
};

export default StatusPage;
