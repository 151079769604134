export const deckNameStyle = {
  typography: { xs: 'h3', sm: 'h1' }
};

export const userInfoStyle = {
  ['.avatar-icon']: {
    height: '50px',
    width: '50px'
  }
};

export const deckDetailsHeaderStyle = ({ backgroundImageUrl }) => ({
  position: 'relative',
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundPositionX: 'center',
  backgroundPositionY: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  paddingTop: { xs: '100px', sm: '45%', md: '40%', lg: '35%' },
  minHeight: { xs: '400px', sm: '300px' },
  borderRadius: '16px',
  border: '1px solid #FFF3',
  overflow: 'hidden'
});

export const deckDetailsMoreOptionsStyle = {
  position: 'absolute',
  top: '4px',
  right: '4px',
  zIndex: 2
};
