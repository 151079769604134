import { Checkbox, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';
import {
  useGetSingleEventQuery,
  usePostUpdateEventParticipantMutation
} from '../../../../../services/apis/organizePlayApi';

import { ModalContext } from '../../../../../components/Modal/Context/ModalContext';
import { RosterStatus } from '../../../../../components/Events/RosterStatus';
import SimpleModalCard from '../../../../../components/Modal/Cards/SimpleModalCard';
import { eventStatuses } from '../../../../../values/event-values';
import { get } from 'lodash';
import { singleEventInfo } from '../../../../../utils/EventUtils';
import { useParams } from 'react-router-dom';

const UpdateRegisteredPlayer = ({
  // eslint-disable-next-line no-unused-vars
  editable,
  user,
  canEditSelf = false,
  type = 'default',
  title = 'Edit Status'
}) => {
  const combineTypes = (end = '&&', ...types) => {
    let expression;

    types.forEach((item, i) => {
      if (i === 0) {
        expression = item;
        return expression;
      } else if (i === types.length - 1) {
        expression = `${expression} || ${item} ${end}`;
        return expression;
      }
      return (expression = `${expression} || ${item} `);
    });
    return expression;
  };

  // React Router Hooks
  const { eventId } = useParams();

  // Context Hooks
  const { closeModal, setIsLoading } = useContext(ModalContext);

  // Redux Hooks
  const { data: activeEvent } = useGetSingleEventQuery(eventId);
  const [
    updateParticipant,
    updateParticipantResult
  ] = usePostUpdateEventParticipantMutation();

  const { status } = singleEventInfo(activeEvent);

  const startedAndNotComplete =
    status === 'active' || status === 'started' || status === 'playoffs';

  // React Hook Form Hooks
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { currentStatus: get(user, 'status', '') }
  });

  // Setup values
  const statusTypes =
    status === eventStatuses.started.key
      ? [
          { title: 'Dropped', key: 'dropped' },
          { title: 'Checked In', key: 'active' }
        ]
      : type === 'unregister'
      ? [
          { title: 'Registered', key: 'registered' },
          { title: 'Unregistered', key: 'unregistered' }
        ]
      : [
          { title: 'Registered', key: 'registered' },
          { title: 'Unregistered', key: 'unregistered' },
          { title: 'Checked In', key: 'active' }
        ];

  // Close if Successful
  useEffect(() => {
    setIsLoading(updateParticipantResult.isLoading);
    if (updateParticipantResult.isSuccess) {
      closeModal();
    }
  }, [updateParticipantResult.isLoading]);

  // Functions
  const onSubmit = data => {
    const body =
      type === 'selfDrop'
        ? { status: 'unregistered' }
        : combineTypes('', 'default', 'unregister') && {
            status: data.currentStatus
          };

    const userId = type === 'selfDrop' ? get(user, 'details.id') : user.userId;

    updateParticipant({ body, userId: userId, eventId });
  };

  const [checked, setChecked] = useState(false);

  const handleChange = e => {
    setChecked(e.target.checked);
  };

  const disabledSubmitConditions = () => {
    if (type === 'default' && canEditSelf === false) {
      return watch('currentStatus') === get(user, 'status', '');
    } else if (type === 'unregister' && canEditSelf) {
      return watch('currentStatus') === get(user, 'status', '');
    } else if (type === 'default' && canEditSelf) {
      return !checked || watch('currentStatus') === get(user, 'status', '');
    } else {
      return null;
    }
  };

  return (
    <form
      id={`update-registered-player-${type}-modal-container`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <SimpleModalCard
        title={type === 'selfDrop' ? undefined : title}
        image={get(user, 'details.imageUrl', '')}
        confirmButton={{
          id: `update-registered-player-${type}-confirm-button`,
          dataTestId: `update-registered-player-${type}-confirm-button`,
          disabled: disabledSubmitConditions(),
          title: type === 'selfDrop' ? 'Submit' : 'Update',
          type: 'submit'
        }}
      >
        {type === 'selfDrop' && (
          <Grid justifyContent={'center'} container>
            <Typography variant={'h4'} item>
              {title}
            </Typography>
          </Grid>
        )}
        {type !== 'selfDrop' && (
          <Grid container spacing={3} justifyContent={'center'}>
            {/* User Info */}
            {!canEditSelf && (
              <RosterStatus
                user={user}
                textAlign={'center'}
                constantTextAlign={'center'}
              />
            )}
            {canEditSelf && startedAndNotComplete && (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                item
              >
                <Typography>Check to confirm</Typography>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
            )}
            {/* Select User Status */}
            <Grid item xs={12}>
              <Controller
                name={'currentStatus'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id={`update-registered-player-${type}-select`}
                    data-testid={`update-registered-player-${type}-select`}
                    select
                    fullWidth
                    labelid="current-status-simple-select-label"
                    label="Status"
                  >
                    {statusTypes.map(item => (
                      <MenuItem
                        id={`update-registered-player-select-${item.key}-option`}
                        data-testid={`update-registered-player-select-${item.key}-option`}
                        key={item.key}
                        value={item.key}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              ></Controller>
            </Grid>
          </Grid>
        )}
      </SimpleModalCard>
    </form>
  );
};

export default UpdateRegisteredPlayer;
