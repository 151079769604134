import { Box, styled } from '@mui/material';

export const headerImageStyle = theme => ({
  paddingTop: { xs: '50%', sm: '35%', md: '30%', lg: '25%' },
  backgroundPosition: 'top',
  borderRadius: theme.border.borderRadius + 'px',
  marginBottom: '50px'
});

export const BannerProfileContainer = styled(Box)(() => ({
  display: 'grid',
  ['& > *']: { gridColumn: 1, gridRow: 1 },
  ['.profile-pic']: {
    alignSelf: 'flex-end',
    justifySelf: 'center',
    position: 'relative'
  }
}));
