import { Button, Grid, styled } from '@mui/material';
import { get } from 'lodash';
// import appBackground from '../../assets/appSectionBackground.svg';

export const MaxWidthContainer = styled(Grid)`
  max-width: 1500px;
  margin: 0 auto;
  z-index: 100;
`;

export const DownloadMobileAppContainer = styled(Grid)`
  background-size: cover;
  position: relative;
  overflow: hidden;
`;

export const DownloadMobileAppBackground = styled('div')`
  background: ${({ theme }) => theme.layout.backgrounds.block}66;
  height: 100%;
  position: absolute;
  top: 70px;
  transform: rotate(-2deg) scaleX(1.03);
  border-radius: 100px 100px 0 0;
  width: 100%;
  z-index: -1;
`;

export const QRCodeBorder = styled('div')`
  max-width: 278px;
  max-height: 346px;
  border: 5px solid ${({ theme }) => theme.palette.text.primary};
  border-radius: 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
    border-width: 4px;
  }
`;

export const AppRatingWhiteBackground = styled(Grid)`
  max-width: 140px;
  height: 170px;
  background: #ffffff;
  box-shadow: 20px 14px 60px rgba(0, 0, 0, 0.05);
  border-radius: 80px 80px 0 0;
`;

export const CopyrightSectionContainer = styled(Grid)`
  background: ${({ theme }) => get(theme, 'layout.backgrounds.footer')};
  color: ${({ theme }) => get(theme, 'colors.footer.textColor')};
  padding: 16px;
`;

export const LinksMainContainer = styled(Grid)``;

export const LinksContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const SocialIconButton = styled(Button)`
  padding: 16px;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  &:hover {
    transform: scale(1.08);
    transition: transform 0.25s ease-in-out;
  }
`;

export const SocialLinksContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
  gap: 12px;

  ${props => props.theme.breakpoints.down('sm')} {
    gap: 8px;
  }
`;

export const AppUsersContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;
