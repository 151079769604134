import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { get, unset } from 'lodash';
import { useSelector } from 'react-redux';
import {
  usePutUpdateUserInfoByGameMutation,
  usePutUpdateUserInfoMutation
} from '../../../services/apis/organizePlayApi';

import { MaxWidthContainer } from '../../../components/Shared/Grid/styles';
import { Outlet } from 'react-router-dom';
import ProfileTabs from '../../../components/User/ProfileTabs';
import UserHeader from '../../../components/User/UserHeader';
import { defaultUserInfo } from '../../../values/user-values';
import { joiResolver } from '@hookform/resolvers/joi';
import { useFormError } from '../../../hooks/form/useFormError';
import { updateUserSchema } from '../../../schema/user/updateUserSchema';

const UserProfilePage = () => {
  const { user, game } = useSelector(({ app }) => app);
  const editUseState = useState(false);

  const userId = get(user, 'details.id', null);
  const gameId = get(game, 'id', null);

  const userInfo = {
    profile: {
      firstName: get(user, 'details.info.firstName', ''),
      lastName: get(user, 'details.info.lastName', ''),
      nickname: get(user, 'details.info.nickname', ''),
      email: get(user, 'details.info.email', '')
    },
    location: {
      ...get(user, 'details.location', defaultUserInfo.location),
      name: ''
    },
    teamName: get(user, 'details.gameUser.teamName', '')
  };

  const [updateUser] = usePutUpdateUserInfoMutation();
  const [updateGameUser] = usePutUpdateUserInfoByGameMutation();

  const handleSave = data => {
    const localData = {
      location: { ...data.location },
      profile: { ...data.profile }
    };
    unset(localData, 'profile.email');
    unset(localData, 'teamName');
    if (userId) {
      localData.location.isOptedOut =
        localData.location.address1 === '' && localData.location.city === '';
      unset(localData, 'location.geo');
      unset(localData, 'location.name');
      unset(localData, 'location.isOnline');
      updateUser({ id: userId, body: localData });
      updateGameUser({
        id: userId,
        gameId,
        body: {
          imageUrl: get(user, 'details.gameUser.imageUrl', ''),
          teamName: get(data, 'teamName', '')
        }
      });
    }
  };

  const methods = useForm({
    defaultValues: { ...userInfo },
    resolver: joiResolver(updateUserSchema)
  });

  useFormError(methods.formState);

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({ ...userInfo });
    editUseState[1](false);
  }, [user]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <MaxWidthContainer
          container
          className={'p-percent p-top-5'}
          alignItems={'center'}
          gap={10}
        >
          {/* User Profile Section */}
          <Grid p={0} item xs={12}>
            <Grid container spacing={2}>
              {/* Page Title */}
              <Grid item xs={12}>
                <Typography
                  textAlign={'center'}
                  variant={'h1'}
                  data-testid="profile-title"
                >
                  My Profile
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <UserHeader />
              </Grid>

              <Grid item xs={12}>
                <ProfileTabs />
              </Grid>

              <Outlet context={{ userInfo, editUseState }} />
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </form>
    </FormProvider>
  );
};

export default UserProfilePage;
