import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { get, kebabCase } from 'lodash';

import React from 'react';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { buildRoutePath } from '../../../../../../utils/routingUtils';
import dayjs from 'dayjs';
import { getOrdinal } from '../../../../../../utils/number';
import { useParams } from 'react-router-dom';

dayjs.extend(advancedFormat);

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: `1px solid ${theme.palette.neutral[500]}`
  }
}));

const EventSingleStat = ({ eventInfo = {} }) => {
  const eloChange = Math.round(get(eventInfo, 'elo.change'));
  const eloScore = Math.round(get(eventInfo, 'elo.currentRank'));
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const eventStanding = get(eventInfo, 'eventStanding');
  const params = useParams();

  const dataToShow = [
    {
      title: 'Event Placing',
      value: `${eventStanding}${getOrdinal(eventStanding)}`
    },
    {
      title: 'Total Participants',
      value: get(eventInfo, 'eventTotalParticipants')
    },
    {
      title: 'Starting ELO',
      value: Math.round(
        get(eventInfo, 'elo.currentRank') - get(eventInfo, 'elo.change')
      )
    },
    {
      title: 'Ending ELO',
      value: Math.round(get(eventInfo, 'elo.currentRank')),
      colorAccordingToValue:
        get(eventInfo, 'elo.change') > 0
          ? theme.palette.success.main
          : theme.palette.error.main
    }
  ];

  return (
    <Accordion
      id={`ranking-details-event-${eventInfo.id}`}
      data-testid={`ranking-details-event-${eventInfo.id}`}
    >
      <StyledAccordionSummary>
        <Grid container>
          <Grid item xs={6} pr={2}>
            <Typography variant={isSmall ? 'body2Bold' : 'body1Bold'}>
              {get(eventInfo, 'eventName')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body3Bold">
              {dayjs(new Date(get(eventInfo, 'timestamp'))).format(
                'MMM Do YYYY'
              )}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="end">
            {eloChange ? (
              <Typography
                color={
                  eloChange > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
                variant={isSmall ? 'body2Bold' : 'body1Bold'}
              >
                {eloChange > 0 ? `+${eloChange}` : eloChange}
              </Typography>
            ) : (
              <Typography variant={isSmall ? 'body2Bold' : 'body1Bold'}>
                {eloScore}
              </Typography>
            )}
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {dataToShow.map(data => {
            return (
              <Grid item xs={6} key={`event_stats_${kebabCase(data.title)}`}>
                <Grid container spacing={1}>
                  <Grid item xs={12} textAlign="center">
                    <Typography variant={isSmall ? 'body2Bold' : 'body1Bold'}>
                      {data.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography
                      variant={isSmall ? 'body2Bold' : 'body1Bold'}
                      color={data.colorAccordingToValue}
                    >
                      {data.value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid item mb={1} mt={1} xs={12} textAlign="end">
            <Link
              href={`${window.location.origin}${buildRoutePath(
                params.gameSlug,
                `/events/${get(eventInfo, 'eventId', '')}`
              )}`}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              View Event
            </Link>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EventSingleStat;
