import { get } from 'lodash';
import { Button, Drawer, Grid, styled } from '@mui/material';

export const HeaderGridContainer = styled(Grid)`
  align-items: center;
  flex-wrap: nowrap;

  &.logo {
    height: 100%;
  }
`;

export const HeaderMainContainer = styled(HeaderGridContainer)`
  width: 100%;
  height: 80px;
  background-color: ${props => props.theme.secondaryColor};
  position: fixed;
  z-index: 10;
`;

export const HeaderNavButton = styled(Button)`
  border-radius: 0;
  border-bottom: 4px solid transparent;

  &.active {
    color: ${({ theme }) => get(theme, 'colors.header.textActive')};

    &:not(.drawer) {
      border-color: ${({ theme }) => get(theme, 'colors.header.textActive')};
    }
  }
  &.user-info {
    gap: 8px;
    padding: 4px;
    :hover {
      background: unset;
    }
  }
`;

export const HeaderLogoButton = styled(Button)`
  width: 220px;
  height: 100%;
  padding: 0;

  img {
    max-height: 100%;
    width: 100%;
  }

  :hover {
    background: unset;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: unset;
    max-width: 200px;
  }
`;

export const HeaderDrawer = styled(Drawer)``;

export const LogoContainer = styled('div')`
  width: 400px;
`;
