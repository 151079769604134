import React from 'react';
import { Box, useTheme } from '@mui/material';

export const NumberCircle = ({ number, color, gameSlug }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '1rem',
        fontFamily: theme.typography.body2.fontFamily,
        paddingTop:
          gameSlug === 'nostalgix' ? { xs: '0.1rem', sm: '0.3rem' } : 0,
        borderRadius: '12px',
        fontSize: { xs: '10px', sm: '12px' },
        width: { xs: '14px', sm: '24px' },
        height: { xs: '14px', sm: '24px' },
        backgroundColor: color
      }}
    >
      {number}
    </Box>
  );
};

export const CardIcon1 = ({ iconImage }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${iconImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: { xs: '9px', sm: '14px' },
        height: { xs: '14px', sm: '22px' }
      }}
    ></Box>
  );
};

export const CardIcon2 = ({ iconImage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundImage: `url(${iconImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginBottom: '1px',
        width: { xs: '18px', sm: '30px' },
        height: { xs: '13px', sm: '22px' }
      }}
    ></Box>
  );
};
