import React from 'react';

function CustomerBehaviorIcon({ fill = 'currentColor' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <g fill={fill} clipPath="url(#clip0_4656_15202)">
        <path d="M36.208 5.474H14.743a7.513 7.513 0 100 4.098h20.782c.754 0 1.366.612 1.366 1.366v6.147c0 .755-.612 1.366-1.366 1.366h-1.383a1.598 1.598 0 00-2.579-1.012l-2.259 1.802a1.61 1.61 0 000 2.517l2.258 1.801a1.598 1.598 0 002.58-1.01h2.066a4.787 4.787 0 004.781-4.78v-7.514a4.787 4.787 0 00-4.781-4.781zM4.141 12.652a2.05 2.05 0 012.014-1.714h2.732c1 .004 1.85.728 2.014 1.714a6.119 6.119 0 01-6.761 0h.001zm3.38-3.08a2.732 2.732 0 110-5.464 2.732 2.732 0 010 5.464zm4.516 2.107a3.437 3.437 0 00-1.768-1.807 4.098 4.098 0 10-5.495 0 3.436 3.436 0 00-1.767 1.808 6.147 6.147 0 119.03 0zm27.586 6.09a3.42 3.42 0 01-3.415 3.414h-2.732a.683.683 0 00-.683.683v.444a.227.227 0 01-.137.215.214.214 0 01-.246-.032l-2.262-1.804a.242.242 0 010-.378l2.264-1.806a.216.216 0 01.243-.03c.085.037.14.122.137.215v.444c0 .378.305.683.683.683h2.049a2.732 2.732 0 002.732-2.732v-6.147a2.732 2.732 0 00-2.732-2.732H15c.02-.225.034-.452.034-.683 0-.23-.014-.458-.034-.683h21.208a3.42 3.42 0 013.415 3.415v7.513zM4.79 35.527h2.065a1.595 1.595 0 002.579 1.012l2.26-1.803a1.609 1.609 0 000-2.517l-2.258-1.8a1.597 1.597 0 00-2.58 1.01H5.471a1.366 1.366 0 01-1.366-1.366v-6.148c0-.754.612-1.366 1.366-1.366h4.781a2.05 2.05 0 000-4.098H4.79a4.787 4.787 0 00-4.78 4.781v7.514a4.787 4.787 0 004.78 4.78zM1.373 23.232a3.42 3.42 0 013.415-3.415h5.465a.683.683 0 110 1.366H5.472a2.732 2.732 0 00-2.732 2.732v6.147a2.732 2.732 0 002.732 2.732h2.05a.683.683 0 00.682-.683v-.444a.227.227 0 01.137-.215.22.22 0 01.246.032l2.261 1.804a.24.24 0 010 .379l-2.263 1.806a.22.22 0 01-.243.03.227.227 0 01-.137-.216v-.444a.683.683 0 00-.683-.683H4.789a3.42 3.42 0 01-3.415-3.415v-7.513z"></path>
        <path d="M16.532 23.56a1.598 1.598 0 002.583-1.01h6.165a2.05 2.05 0 000-4.099h-6.165a1.597 1.597 0 00-2.578-1.011l-2.26 1.802a1.61 1.61 0 000 2.517l2.255 1.8zm-1.405-3.25l2.263-1.806a.226.226 0 01.14-.053c.036 0 .07.01.103.025.085.037.14.122.136.216v.442c0 .377.306.683.683.683h6.828a.683.683 0 110 1.366h-6.83a.683.683 0 00-.683.683v.444a.227.227 0 01-.137.215.214.214 0 01-.246-.032l-2.26-1.803a.242.242 0 010-.38h.003zM33.476 25.964a7.521 7.521 0 00-7.308 5.81l-1.7-1.356a1.598 1.598 0 00-2.586 1.01h-6.165a2.05 2.05 0 000 4.099h6.165a1.595 1.595 0 002.578 1.012l1.708-1.358a7.513 7.513 0 107.308-9.217zm-7.605 7.703l-2.263 1.806a.22.22 0 01-.243.03.227.227 0 01-.137-.215v-.444a.683.683 0 00-.683-.683h-6.828a.683.683 0 010-1.366h6.83a.683.683 0 00.684-.683v-.444a.227.227 0 01.136-.215.22.22 0 01.246.032l2.262 1.803c.057.046.09.116.088.19a.233.233 0 01-.092.189zm7.605 5.958a6.154 6.154 0 01-6.147-6.143v-.008a6.147 6.147 0 116.147 6.15z"></path>
        <path d="M35.725 30.945l-3.615 3.616-.883-.883a.683.683 0 00-.966.965l1.366 1.366c.267.267.7.267.966 0l4.098-4.098a.683.683 0 00-.966-.966z"></path>
      </g>
      <defs>
        <clipPath id="clip0_4656_15202">
          <path fill={fill} d="M0 0H41V41H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CustomerBehaviorIcon;
