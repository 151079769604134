/* eslint-disable no-unused-vars */
import { Autocomplete, Box, Select, TextField, styled } from '@mui/material';

export const OptionsContainer = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  background: `${theme.palette.background.paper}`,
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    overflow: 'auto'
  }
}));
