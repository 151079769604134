import { Button, styled } from '@mui/material';

export const MoreOptions = styled(Button)`
  background: ${({ theme }) => theme.palette.neutral['200']}1A;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 10px;
  border-radius: 50%;
  min-width: 100%;
  min-height: 100%;

  &:hover {
    background: ${({ theme }) => theme.palette.neutral['200']}1A;
  }
`;
