import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { displayableDate } from '../../../utils/date';
const SeasonBanner = ({ season }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ position: 'relative', height: { xs: 310, sm: 450 } }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: -1
          }}
        >
          <img
            src={season.bannerImageUrl}
            width={'100%'}
            height={'100%'}
            style={{
              objectFit: 'cover',
              borderRadius: '20px',
              filter: 'brightness(0.3)'
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Typography textAlign="center" variant="h1">
            {season.name}
          </Typography>
          <Box
            display="flex"
            bgcolor="red"
            padding="6.5px 16px"
            borderRadius="20px"
            gap={1}
            sx={{
              background: 'rgba(255, 255, 255, 0.1)',
              backdropFilter: 'blur(1.2px)',
              border: '1px solid rgba(255, 255, 255, 0.19)'
            }}
          >
            <AccessTimeIcon color="primary" />
            <Typography color="primary.main" variant="body1">
              {displayableDate(season.startDate)}
              {'  '}to{'  '}
              {displayableDate(season.endDate)}
            </Typography>
          </Box>
          <Typography variant="h5">xxx total players</Typography>
        </Box>
      </Grid>
    </>
  );
};

export default SeasonBanner;
