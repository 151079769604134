import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import Lottie from 'react-lottie';

import { Box } from '@mui/material';

import * as akoraLoader from '../../../assets/animations/loading/akora.json';
import * as alphaClashLoader from '../../../assets/animations/loading/alpha-clash.json';
import * as cardeioLoader from '../../../assets/animations/loading/cardeio.json';
import * as elestralsLoader from '../../../assets/animations/loading/elestrals.json';
import * as fabledSagasLoader from '../../../assets/animations/loading/fabled-sagas.json';
import * as grandArchiveLoader from '../../../assets/animations/loading/grand-archive.json';
import * as interstellarLoader from '../../../assets/animations/loading/interstellar.json';
import * as kryptikLoader from '../../../assets/animations/loading/kryptik.json';
import * as lorcanaLoader from '../../../assets/animations/loading/lorcana.json';
import * as metazooLoader from '../../../assets/animations/loading/metazoo.json';
import * as neopetsLoader from '../../../assets/animations/loading/neopets-battledome.json';
import * as nostalgixLoader from '../../../assets/animations/loading/nostalgix.json';
import * as onepieceLoader from '../../../assets/animations/loading/onepiece.json';
import * as riseTcgLoader from '../../../assets/animations/loading/rise-tcg.json';
import * as shadowverseLoader from '../../../assets/animations/loading/shadowverse-evolve.json';
import * as solforgeLoader from '../../../assets/animations/loading/solforge.json';
import * as sorceryLoader from '../../../assets/animations/loading/sorcery.json';
import * as starRealmsLoader from '../../../assets/animations/loading/star-realms.json';
import * as starWarsLoader from '../../../assets/animations/loading/starwars-unlimited.json';
import * as synsoulsLoader from '../../../assets/animations/loading/synsouls.json';
import * as systemGateLoader from '../../../assets/animations/loading/system-gate.json';
import * as universusLoader from '../../../assets/animations/loading/universus.json';
import * as vampireLoader from '../../../assets/animations/loading/vampire.json';

const Loading = ({ entireScreen = false }) => {
  const { game } = useSelector(({ app }) => app);

  const animationData = {
    akora: akoraLoader,
    'alpha-clash': alphaClashLoader,
    elestrals: elestralsLoader,
    'fabled-sagas': fabledSagasLoader,
    'grand-archive': grandArchiveLoader,
    interstellar: interstellarLoader,
    kryptik: kryptikLoader,
    lorcana: lorcanaLoader,
    metazoo: metazooLoader,
    'neopets-battledome': neopetsLoader,
    nostalgix: nostalgixLoader,
    onepiece: onepieceLoader,
    'rise-tcg': riseTcgLoader,
    'shadowverse-evolve': shadowverseLoader,
    solforge: solforgeLoader,
    sorcery: sorceryLoader,
    'star-realms': starRealmsLoader,
    'starwars-unlimited': starWarsLoader,
    synsouls: synsoulsLoader,
    'system-gate': systemGateLoader,
    universus: universusLoader,
    vampire: vampireLoader
  };

  const customSize = {
    interstellar: {
      height: 100,
      width: 225
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData[game.slug] || cardeioLoader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (entireScreen) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          minHeight: '400px',
          alignItems: 'center'
        }}
      >
        <Lottie
          options={defaultOptions}
          height={get(customSize[game.slug], 'height', 200)}
          width={get(customSize[game.slug], 'width', 200)}
        />
      </Box>
    );
  }

  return (
    <Lottie
      options={defaultOptions}
      height={get(customSize[game.slug], 'height', 200)}
      width={get(customSize[game.slug], 'width', 200)}
    />
  );
};

export default Loading;
