import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { find, get, isEmpty } from 'lodash';

import Loading from '../../Loading/Loader';
import { ModalContext } from '../../Modal/Context/ModalContext';
import SimpleModalCard from '../../Modal/Cards/SimpleModalCard';
import { useSelector } from 'react-redux';
import Tooltip from '../../Shared/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { getDeckErrors } from '../../../utils/deck';

const DeckImageContainer = styled(Box)(({ imageUrl }) => ({
  position: 'relative',
  minHeight: '200px',
  width: '100%',
  backgroundImage: `url(${imageUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '12px'
}));

export const PlayerDeckDetailsModal = ({ deckDetails }) => {
  const { closeModal } = useContext(ModalContext);
  const { game } = useSelector(({ app }) => app);
  const theme = useTheme();
  const defaultDeckTypeData = game?.defaultDeckType;

  const getSectionName = deckSectionId => {
    return get(
      find(
        defaultDeckTypeData.deckSections,
        section => section._id === deckSectionId
      ),
      'name'
    );
  };

  const getCardName = cardId => {
    return get(
      find(deckDetails.cards, card => card._id === cardId),
      'name',
      'card'
    );
  };

  return (
    <SimpleModalCard
      title={`${get(game, 'terminology.deck', 'Deck')} Details`}
      confirmButton={{
        title: 'Close',
        onClick: () => closeModal()
      }}
      hideCancelButton
      showClose
      onClose={closeModal}
    >
      <Grid container>
        <Grid item xs={12}>
          <DeckImageContainer imageUrl={get(deckDetails, 'deck.deckImageUrl')}>
            {!isEmpty(deckDetails?.deck?.errors) && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <Tooltip
                  icon={
                    <ErrorIcon
                      className={'error-icon'}
                      color={'error'}
                      sx={{ fontSize: '4rem' }}
                    />
                  }
                  info={getDeckErrors({
                    errors: deckDetails?.deck?.errors,
                    id: '$'
                  })}
                />
              </Box>
            )}
          </DeckImageContainer>
        </Grid>
        <Grid item xs={12} mt="16px">
          <Typography variant="h3">{get(deckDetails, 'deck.name')}</Typography>
        </Grid>
        {get(deckDetails, 'deck.notes', null) ? (
          <Grid item xs={12} mt="8px">
            <Typography variant="subtitle2">
              {get(deckDetails, 'deck.notes')}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} mt="16px">
          <Grid container spacing={2}>
            {get(deckDetails, 'deck.sections', []).map(deckSection => {
              const sectionError = getDeckErrors({
                errors: deckDetails.deck?.errors,
                id: deckSection?.deckSectionId
              });
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={deckSection.deckSectionId}
                  sx={{ margin: { xs: '8px', sm: '0' } }}
                >
                  <Grid
                    container
                    spacing={1}
                    sx={{ background: theme.palette.neutral[400] }}
                    padding="8px 4px"
                  >
                    <Grid item xs={12}>
                      <Grid container alignItems={'center'}>
                        <Grid item>
                          <Typography variant="h6">
                            {getSectionName(deckSection.deckSectionId)}
                          </Typography>
                        </Grid>
                        {sectionError && (
                          <Grid item>
                            <Tooltip
                              icon={
                                <ErrorIcon
                                  className={'error-icon'}
                                  color={'error'}
                                  fontSize={'small'}
                                />
                              }
                              info={sectionError}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {get(deckSection, 'cards', []).map(deckCard => {
                      const cardError = getDeckErrors({
                        errors: deckDetails?.deck?.errors,
                        id: deckCard.cardId
                      });

                      return (
                        <Grid
                          key={deckCard.cardId}
                          container
                          alignItems={'center'}
                          spacing={1}
                          pl={0.5}
                        >
                          <Grid item>
                            {`${get(deckCard, 'count', 1)}x ${getCardName(
                              get(deckCard, 'cardId')
                            )}`}
                          </Grid>
                          {cardError && (
                            <Grid item>
                              <Tooltip
                                icon={
                                  <ErrorIcon
                                    className={'error-icon'}
                                    color={'error'}
                                    fontSize={'small'}
                                  />
                                }
                                info={cardError}
                              />
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </SimpleModalCard>
  );
};
