import React from 'react';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const PlayerTeamName = ({
  teamName = null,
  flip = false,
  shouldCenter = false,
  centerBreakpoint = 'md'
}) => {
  const theme = useTheme();
  const smMatches = useMediaQuery(theme.breakpoints.down(centerBreakpoint));
  if (!teamName) {
    return null;
  }
  return (
    <Box
      sx={{
        textAlign: smMatches && shouldCenter ? 'center' : flip ? 'end' : 'start'
      }}
    >
      <Typography variant="subtitle3">{teamName}</Typography>
    </Box>
  );
};
