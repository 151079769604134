import { Grid, TextField } from '@mui/material';

import React from 'react';
import { inputTextSxMobile } from '../styles';

const AddByUserName = ({ participants, setParticipants }) => {
  const handleInputChange = (index, event, fieldName) => {
    const values = [...participants];
    values[index][fieldName] = event.target.value;
    setParticipants(values);
  };

  return (
    <>
      {participants.length > 0 && (
        <>
          {participants.map((field, index) => (
            <Grid key={index} item xs={12}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="add-participant-type-firstname-search-input"
                    data-testid="add-participant-type-firstname-search-input"
                    label="First Name"
                    onChange={event =>
                      handleInputChange(index, event, 'firstName')
                    }
                    value={field.firstName}
                    inputProps={{
                      sx: inputTextSxMobile
                    }}
                    InputLabelProps={{
                      sx: inputTextSxMobile
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="add-participant-type-lastname-search-input"
                    data-testid="add-participant-type-lastname-search-input"
                    label="Last Name"
                    onChange={event =>
                      handleInputChange(index, event, 'lastName')
                    }
                    value={field.lastName}
                    inputProps={{
                      sx: inputTextSxMobile
                    }}
                    InputLabelProps={{
                      sx: inputTextSxMobile
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

export default AddByUserName;
