import {
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { eventStatuses, playerStatus } from '../../../../values/event-values';

import ClearIcon from '@mui/icons-material/Clear';
import Currency from '../../../Shared/TextFields/Currency';
import { get } from 'lodash';
import { useGetSingleEventRosterQuery } from '../../../../services/apis/organizePlayApi';

// eslint-disable-next-line react/display-name
const TournamentOverwrites = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ value, onChange, activeEvent }, ref) => {
    // eslint-disable-next-line no-unused-vars
    const { data, isLoading } = useGetSingleEventRosterQuery({
      eventId: activeEvent?._id || ''
    });
    const roster = get(data, 'data.participants', []);
    const activePlayers = roster.filter(
      player => player.status === playerStatus.active.key
    ).length;
    const [override, setOverride] = useState(
      Object.keys(get(value, 'tournamentFormatOverwrites', {})).length > 0 ||
        Object.keys(get(value, 'tournamentFormatPlayOffsOverwrites', {}))
          .length > 0
    );
    const hasStarted =
      get(activeEvent, 'status', '') === eventStatuses.started.key;

    const changeValue = newValue => {
      // const newValues = { ...value };
      // if (key === 'tournamentFormatId') {
      //   const isEditMode = true;
      //
      //   newValues['tournamentFormatOverwrites'] = {
      //     ...(isEditMode && activeEvent.tournament.tournamentFormatOverwrites)
      //   };
      //   newValues['tournamentFormatPlayOffsOverwrites'] = {
      //     ...(isEditMode &&
      //       activeEvent.tournament.tournamentFormatPlayOffsOverwrites)
      //   };
      //   setOverride(false);
      // }
      // newValues[key] = newValue;
      onChange(newValue);
    };

    const overwrites = [
      {
        key: 'tournamentFormatOverwrites',
        title: '',
        values: ['rounds']
      }
    ].filter(overwrite => overwrite.values.length);

    const labels = {
      bestOf: { title: 'Best Of', value: [1, 3, 5, 7], disabled: hasStarted },
      rounds: { title: 'Total Rounds' },
      playOffPlayerCount: {
        title: 'Total Players',
        value: [0, 2, 4, 8, 16, 32, 64].filter(num => {
          if (!hasStarted) {
            return true;
          }

          return num < activePlayers;
        }),
        disabled:
          !hasStarted ||
          (activeEvent &&
            (activeEvent.currentRound < activeEvent.tournament.rounds ||
              activeEvent.tournament.playOffRounds === 0))
      },
      enabled: { title: 'Enable Top Cut?' }
    };

    const getLabel = type => get(labels, `${type}.title`, '');

    const createOverwrite = (type, overwrite) => {
      const singleValue = get(value, `${type}`, '');
      const createOnChange = newValue => {
        changeValue({
          ...value,
          [type]: newValue
        });
      };
      switch (type) {
        case 'bestOf':
        case 'playOffPlayerCount':
          return (
            <TextField
              id="play-off-player-input"
              data-testid="play-off-player-input"
              disabled={!override || get(labels, `${type}.disabled`, false)}
              label={getLabel(type)}
              value={singleValue}
              InputProps={{
                endAdornment: singleValue !== '' && !activeEvent && (
                  <IconButton
                    sx={{ marginRight: 1 }}
                    disabled={!override || labels[type]?.disabled}
                    onClick={() => createOnChange('')}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
              onChange={e => createOnChange(e.target.value)}
              select
            >
              {labels[type].value.map(num => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </TextField>
          );
        case 'rounds':
          return (
            <>
              <Currency
                id="play-off-rounds-input"
                data-testid="play-off-rounds-input"
                disabled={!override || !hasStarted}
                hideAdornment
                label={getLabel(type)}
                value={singleValue}
                onChange={e => {
                  const newValue = e ? Number(e) : '';
                  if (
                    (Number.isInteger(newValue) &&
                      activeEvent &&
                      newValue < activeEvent?.currentRound) ||
                    newValue > get(activeEvent, 'tournament.maxNumOfRounds')
                  ) {
                    return;
                  }
                  createOnChange(e ? Number(e) : '');
                }}
              />
              {overwrite === 'tournamentFormatOverwrites' &&
                activeEvent &&
                hasStarted && (
                  <Typography variant="body3" sx={{ paddingTop: 0.5 }}>
                    {`Minimum: ${activeEvent?.currentRound} Round${
                      activeEvent?.currentRound === 1 ? '' : 's'
                    }`}
                    {'  -  '}
                    {`Maximum: ${get(
                      activeEvent,
                      'tournament.maxNumOfRounds'
                    )} Rounds`}
                  </Typography>
                )}
            </>
          );
      }

      return <></>;
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid item>
            <FormControlLabel
              onChange={e => setOverride(e.target.checked)}
              control={
                <Switch
                  id="tournament-overwrite-switch"
                  data-testid="tournament-overwrite-switch"
                  checked={override}
                  color={'primary'}
                  disabled={!hasStarted}
                />
              }
              label={
                <Typography variant={'h3'} lineHeight={'3rem'}>
                  Customize
                </Typography>
              }
              labelPlacement={'start'}
              sx={{ alignItems: 'flex-end', margin: 0 }}
            />
          </Grid>

          {/* Overwrites */}
          <Grid item sx={{ padding: 2 }}>
            <Grid container spacing={3}>
              {overwrites.map(overwrite => (
                <Grid item key={overwrite.key} xs={12} sm={6}>
                  <Typography variant={'h4'} gutterBottom>
                    {overwrite.title}
                  </Typography>
                  <Grid container spacing={2}>
                    {overwrite.values.map(overwritable => (
                      <Grid item xs={12} key={overwritable}>
                        {createOverwrite(overwritable, overwrite.key)}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {!hasStarted && (
            <Grid item>
              <Typography variant={'body2'}>
                * Some inputs are disabled until the event starts.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default TournamentOverwrites;
