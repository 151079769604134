import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { setStoreCurrency } from '../../../store/app/actions';
import { useGetUserWalletsQuery } from '../../../services/apis/organizePlayApi';
import { useIsLoggedIn } from '../../../hooks/auth0/useLoggedIn';

const RewardsHeader = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data: userWalletsData } = useGetUserWalletsQuery('', {
    skip: !isLoggedIn
  });

  const dispatch = useDispatch();
  const { selectedStoreCurrency, game } = useSelector(({ app }) => app);
  const gameCurrencies = get(game, 'pgGame.currencies', []);
  const defaultCurrency = get(game, 'defaultCurrency');
  const [currencies, setCurrencies] = useState(gameCurrencies);
  const onSelectCurrency = currency => {
    if (currency !== selectedStoreCurrency) {
      dispatch(setStoreCurrency(currency));
    }
  };
  useEffect(() => {
    if (isLoggedIn && !isEmpty(gameCurrencies) && !isEmpty(userWalletsData)) {
      setCurrencies(
        gameCurrencies.filter(gameCurrency =>
          get(userWalletsData, `currencyIdToWallet.${gameCurrency.id}`)
        )
      );
    } else if (!isLoggedIn && !isEmpty(gameCurrencies)) {
      setCurrencies(
        gameCurrencies.filter(
          gameCurrency => gameCurrency.symbol === defaultCurrency
        )
      );
    }
  }, [gameCurrencies, userWalletsData, defaultCurrency]);

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          alignItems={'end'}
          justifyContent={'space-between'}
          spacing={1}
        >
          <Grid item>
            <Typography variant="h1" data-testid="rewards-title">
              Reward Store
            </Typography>
          </Grid>
          <Grid item>
            <FormControl variant="standard" size="small">
              <Select
                className={'select-primitive'}
                value={selectedStoreCurrency}
                label=""
                data-testid="selected-currency-type"
                onChange={event => {
                  onSelectCurrency(event.target.value);
                }}
                disableUnderline
              >
                {currencies.map(currency => (
                  <MenuItem value={currency.id} key={currency.id}>
                    <Typography>{currency.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RewardsHeader;
