import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Auth from '../Auth';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../Icon';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

const NavDrawer = ({ logo, navs }) => {
  const showDrawer = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorOpen, setAnchorOpen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isBetweenMdLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const shouldShowMenu = isBetweenMdLg && navs.length > 3;

  const openDrawer = () => setAnchorOpen(true);
  const closeDrawer = () => setAnchorOpen(false);

  const navAction = path => {
    closeDrawer();
    navigate(path);
  };

  useEffect(() => {
    !showDrawer && anchorOpen && closeDrawer();
  }, [showDrawer]);

  return (
    <>
      {/*Menu Button*/}
      <Grid
        id="header-nav-drawer-section"
        data-testid="header-nav-drawer-section"
        item
        display={{
          xs: 'block',
          md: shouldShowMenu ? 'block' : 'none',
          lg: 'none'
        }}
      >
        <IconButton
          id="header-nav-drawer-button"
          data-testid="header-nav-drawer-button"
          color={'primary'}
          onClick={() => openDrawer()}
        >
          <Icon
            color={get(theme, 'colors.header.textColor', '#FFFFFF')}
            name="hamburger"
            width={32}
          />
        </IconButton>
      </Grid>

      <SwipeableDrawer
        disableSwipeToOpen={true}
        anchor={'left'}
        open={anchorOpen}
        onClose={() => {
          setAnchorOpen(false);
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: isSmall ? '85vw' : '300px'
          }
        }}
        onOpen={() => {}}
      >
        <Grid
          container
          sx={{
            padding: 2,
            height: '100%'
          }}
          justifyContent={'flex-start'}
          alignContent={'space-between'}
        >
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={'center'}
              mb={2}
              sx={{ position: 'relative' }}
            >
              {logo(closeDrawer)}
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <IconButton
                  id="header-nav-drawer-close-button"
                  data-testid="header-nav-drawer-close-button"
                  onClick={closeDrawer}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>

            {/* Sections of the app shown in the drawer */}
            <Grid item xs={12}>
              <List>
                {navs.map(obj => (
                  <ListItemButton
                    id={`header-${obj.path}-nav-drawer-button`}
                    data-testid={`header-${obj.path}-nav-drawer-button`}
                    key={obj.path}
                    selected={pathname.includes(`/${obj.path}`)}
                    onClick={() => navAction(obj.path)}
                  >
                    <ListItemText>{obj.title}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Grid>
          </Grid>

          {/* Login/User button */}
          <Grid xs={12} item>
            <Auth id="header-login-button" drawer close={closeDrawer} />
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};

export default NavDrawer;
