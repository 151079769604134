/* eslint-disable no-unused-vars */
import { Box, Button, Typography, styled } from '@mui/material';
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { buildRoutePath } from '../../../../utils/routingUtils';
import { useParams } from 'react-router-dom';

const StyledStripeContainer = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  marginTop: '16px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90vw'
  }
}));

const StyledStripePaymentButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '16px',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center'
  }
}));

const StyledStripeErrorMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '16px',
  alignItems: 'center',
  border: `1px solid ${theme.palette.error.main}`,
  padding: '8px'
}));

const StripePaymentForm = ({
  clientSecret,
  clientEmail = '',
  showCancel = false,
  paymentBreakdown = null,
  onCancel = () => {}
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { gameSlug } = useParams();
  const [email, setEmail] = useState(clientEmail);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const getReturnUrl = () => {
    const { origin } = window.location;
    return `${origin}${buildRoutePath(gameSlug, '/payments/callback')}`;
  };
  useEffect(() => {
    if (!stripe) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret]);
  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: getReturnUrl()
      }
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }
    setIsSubmitted(true);
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };
  return (
    <StyledStripeContainer>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          options={{ defaultValues: { email: email || '' } }}
          onChange={e => setEmail(e?.target?.value)}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {!isLoading && stripe && elements && paymentBreakdown
          ? paymentBreakdown
          : null}
        <StyledStripePaymentButtonContainer>
          {showCancel && (
            <Button
              onClick={onCancel}
              variant="text"
              sx={{ marginRight: '16px' }}
              color="primary"
              id="cancel"
              type="button"
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            disabled={isLoading || !stripe || !elements}
            id="submit"
            data-testid="pay-now-button"
            loading={isLoading}
            sx={{
              padding: '12px 24px'
            }}
            variant="contained"
            type="submit"
          >
            Pay now
          </LoadingButton>
        </StyledStripePaymentButtonContainer>
        {/* Show any error or success messages */}
        {message && isSubmitted && (
          <StyledStripeErrorMessageContainer>
            <Typography id="payment-message" variant="body2Light">
              {message}
            </Typography>
          </StyledStripeErrorMessageContainer>
        )}
      </form>
    </StyledStripeContainer>
  );
};

export default StripePaymentForm;
