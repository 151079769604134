import { Grid } from '@mui/material';
import React from 'react';
import { MaxWidthContainer } from '../../components/Shared/Grid/styles';
import {
  SeasonBanner,
  SeasonHeader,
  SeasonHelp,
  SeasonRank,
  SeasonRankProfile,
  SeasonRewards
} from './components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { get } from 'lodash';

const SeasonsMainPage = () => {
  const { game, token, user } = useSelector(({ app, auth }) => {
    return { ...app, ...auth };
  });

  const { seasons } = game;

  const [seasonId, setSeasonId] = useState(get(seasons, '[0]._id', null));
  const selectedSeason = game.seasons.find(season => season._id === seasonId);
  return (
    <MaxWidthContainer
      container
      className={'p-percent p-top-5'}
      sx={{ alignItems: 'center', display: 'flex' }}
    >
      <Grid container gap={{ xs: 3, sm: 8 }}>
        <SeasonHeader
          game={game}
          seasonId={seasonId}
          setSeasonId={setSeasonId}
        />
        <SeasonBanner season={selectedSeason} />
        {token && <SeasonRankProfile user={user} />}
        <SeasonRewards />
        <SeasonRank seasonId={seasonId} />
        <SeasonHelp />
      </Grid>
    </MaxWidthContainer>
  );
};

export default SeasonsMainPage;
