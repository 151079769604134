import { Box, styled } from '@mui/system';

export const PrizesBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.border.borderRadius}px`,
  padding: '120px 100px',
  [theme.breakpoints.down('md')]: {
    padding: '8px 15px',
    paddingBottom: '20px'
  }
}));
