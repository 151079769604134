import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { get, snakeCase } from 'lodash';

import Loading from '../../../components/Loading/Loader';
import ShippingMethodOption from '../ShippingMethodOption';
import SimpleModalCard from '../../../components/Modal/Cards/SimpleModalCard';
import { useLazyGetShippingRatesQuery } from '../../../services/apis/organizePlayApi';

const ShippingOptionsContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  padding: '16px',
  maxHeight: '400px',
  overflowY: 'auto'
}));

const ShippingOptionsModal = ({
  onSelectShippingMethod = () => {},
  onCloseModal = () => {},
  shippingMethod = {}
}) => {
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [shippingMethodOptions, setShippingMethodOptions] = useState([]);
  const [getShippingRates, shippingRateResult] = useLazyGetShippingRatesQuery();
  const isShippingRatesLoading =
    shippingRateResult?.isLoading || shippingRateResult?.isFetching;

  useEffect(() => {
    getShippingRates();
  }, [getShippingRates]);
  useEffect(() => {
    const availableShippingOptions = get(
      shippingRateResult,
      'data.data.availableShippingOptions',
      []
    );

    if (availableShippingOptions.length > 0) {
      const currentCarrierOptions = availableShippingOptions.map(carrier => ({
        label: carrier.carrierName,
        value: carrier.carrierCode,
        options: carrier.options
      }));
      let selectedCarrierCode = currentCarrierOptions[0].value;
      if (shippingMethod?.carrierCode) {
        const isAvailable = currentCarrierOptions.find(
          carrier => carrier.value === shippingMethod.carrierCode
        );
        if (isAvailable) {
          selectedCarrierCode = shippingMethod.carrierCode;
        }
      }
      setCarrierOptions(currentCarrierOptions);
      setSelectedCarrier(selectedCarrierCode);
    }
  }, [shippingRateResult]);

  useEffect(() => {
    if (selectedCarrier) {
      const serviceCost = get(shippingRateResult, 'data.data.platformCost', 0);
      const currentCarrierOptions = carrierOptions.find(
        carrier => carrier.value === selectedCarrier
      );
      const currentShippingMethodOptions = currentCarrierOptions.options.map(
        sm => ({
          label: sm.serviceName,
          value: snakeCase(sm.serviceName),
          serviceCode: sm.serviceCode,
          amount: sm.shipmentCost + sm.otherCost + serviceCost
        })
      );
      let selectedShippingMethodCode = currentShippingMethodOptions[0].value;
      if (shippingMethod?.serviceCode) {
        const isAvailable = currentShippingMethodOptions.find(
          sm => sm.value === snakeCase(shippingMethod.serviceName)
        );
        if (isAvailable) {
          selectedShippingMethodCode = snakeCase(shippingMethod.serviceName);
        }
      }
      setShippingMethodOptions(currentShippingMethodOptions);
      setSelectedShippingMethod(selectedShippingMethodCode);
    }
  }, [selectedCarrier]);

  const onSelectShippingMethodOption = () => {
    const selectedShippingMethodOption = shippingMethodOptions.find(
      sm => sm.value === selectedShippingMethod
    );
    const selectedCarrierOption = carrierOptions.find(
      carrier => carrier.value === selectedCarrier
    );
    onSelectShippingMethod({
      carrierName: selectedCarrierOption.label,
      carrierCode: selectedCarrierOption.value,
      serviceName: selectedShippingMethodOption.label,
      serviceCode: selectedShippingMethodOption.serviceCode,
      amount: selectedShippingMethodOption.amount
    });
  };

  return (
    <SimpleModalCard
      cancelButton={{
        'data-testid': 'shipping-method-cancel-button',
        title: 'Cancel'
      }}
      confirmButton={{
        'data-testid': 'shipping-method-select-button',
        title: 'Select',
        disabled: !selectedShippingMethod,
        onClick: () => {
          onSelectShippingMethodOption();
        }
      }}
      title="Select Shipping Option"
      showClose
      onClose={onCloseModal}
    >
      {isShippingRatesLoading ? (
        <Loading />
      ) : (
        <Grid container>
          {carrierOptions && carrierOptions.length === 0 ? (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2Light" textAlign="center">
                Unable to fetch latest shipping rates
              </Typography>
              <Button onClick={() => getShippingRates()} variant="text">
                Try again
              </Button>
            </Grid>
          ) : null}
          {carrierOptions && carrierOptions.length > 1 ? (
            <Grid item xs={12}>
              <FormControl>
                <FormLabel
                  id="shipping-carrier-select-radio-label"
                  data-testid="shipping-carrier-select-radio-label"
                >
                  Carrier
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="shipping-carrier-select-radio-label"
                  name="shipping-carrier-select-radio-buttons-group"
                  value={selectedCarrier}
                  onChange={(event, value) => {
                    setSelectedCarrier(value);
                  }}
                >
                  {carrierOptions.map(carrier => (
                    <FormControlLabel
                      key={carrier.value}
                      value={carrier.value}
                      control={<Radio />}
                      label={carrier.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} mt={2}>
            <ShippingOptionsContainer container>
              {shippingMethodOptions && shippingMethodOptions.length > 0
                ? shippingMethodOptions.map(shippingMethod => (
                    <Grid item xs={12} key={shippingMethod.value}>
                      <ShippingMethodOption
                        isSelected={
                          selectedShippingMethod === shippingMethod.value
                        }
                        onSelect={setSelectedShippingMethod}
                        shippingMethod={shippingMethod}
                      />
                    </Grid>
                  ))
                : null}
            </ShippingOptionsContainer>
          </Grid>
        </Grid>
      )}
    </SimpleModalCard>
  );
};

export default ShippingOptionsModal;
