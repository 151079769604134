import React from 'react';
import { CardList } from './styles';
import { ListItemText, Typography } from '@mui/material';

const TextView = ({ card, cardInfo }) => {
  return (
    <CardList key={card.cardId}>
      <ListItemText>
        <Typography className={'card-name'} variant="body2">{`${card.count} ${
          cardInfo[card.cardId]?.name
        }`}</Typography>
      </ListItemText>
    </CardList>
  );
};

export default TextView;
