import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { debounce, get, isEmpty } from 'lodash';

import { EventRecordCard } from '../../../../components/Shared/Cards/styles';
import GeneralContainer from '../../../../components/Shared/Cards/GeneralCard';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import Loader from '../../../../components/Loading/Loader';
import { MaxWidthContainer } from '../../../../components/Shared/Grid/styles';
import RankTypography from '../../../../components/Shared/Typography/RankTypography';
import { RosterStatus } from '../../../../components/Events/RosterStatus';
import { StyledSearchTextField } from '../../../../components/Shared/TextFields';
import { createCrown } from '../../../../components/Shared/Overlay';
import { useGetSingleEventStandingsQuery } from '../../../../services/apis/organizePlayApi';
import { useParams } from 'react-router-dom';
import { usePrevious } from '../../../../utils/usePrevious';
import { useTheme } from '@mui/material/styles';

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  limit: 25
};

const EventPlacingsPage = () => {
  const { eventId } = useParams();
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [placings, setPlacings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState({});
  const [currentPaginationData, setCurrentPaginationData] = useState(
    DEFAULT_PAGINATION_OPTIONS
  );
  const previousSearchInput = usePrevious(searchInput);

  const resetPagination = () => {
    setCurrentPaginationData(DEFAULT_PAGINATION_OPTIONS);
  };

  useEffect(() => {
    setSearchQuery({
      ...currentPaginationData,
      searchText,
      eventId
    });
  }, [currentPaginationData, searchText, eventId]);

  // Redux Hooks
  const { data, isLoading, isFetching } = useGetSingleEventStandingsQuery(
    searchQuery,
    {
      refetchOnMountOrArgChange: true,
      skip: isEmpty(searchQuery)
    }
  );

  // eslint-disable-next-line no-unused-vars
  const { activePage, totalPages, totalResults } = get(data, 'pagination', {});

  const searchByText = searchTextValue => {
    resetPagination();
    setSearchText(searchTextValue);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => searchByText(searchInput), 500);
    if (searchInput || (!searchInput && previousSearchInput)) {
      debouncedSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, previousSearchInput]);

  useEffect(() => {
    const { activePage } = get(data, 'pagination', 1);
    const placingData = get(data, 'data', []);
    if (data) {
      if (activePage === 1) {
        setPlacings(placingData);
      } else {
        setPlacings(prevState => [...prevState, ...placingData]);
      }
    }
  }, [data]);

  const handleSearch = e => {
    const eventTarget = e.target.value;
    setSearchInput(eventTarget);
  };

  const loadAction = () => {
    const { activePage, nextPage, totalPages } = get(data, 'pagination', {
      activePage: 0,
      nextPage: 0,
      totalPages: -1
    });
    if (!isLoading && !isFetching && activePage < totalPages) {
      setCurrentPaginationData(prevState => {
        return { ...prevState, page: nextPage };
      });
    }
  };

  const commonFlexDirection = { xs: 'column', md: 'row' };

  return (
    <MaxWidthContainer
      id="event-placings-page-container"
      data-testid="event-placings-page-container"
    >
      <StyledSearchTextField
        id="event-placings-page-search-input"
        onChange={handleSearch}
        value={searchInput}
      />
      <InfiniteScroll
        done={activePage >= totalPages || !data}
        emptyMessage={
          searchText
            ? 'No Standings found for the search term'
            : 'No Standings Found'
        }
        isLoading={isLoading || isFetching}
        loader={isLoading || isFetching ? <Loader /> : null}
        loaderAction={loadAction}
        shouldScrollToTop={false}
        emptyResultContainerStyle={{
          position: 'relative',
          minHeight: '50vh'
        }}
      >
        {placings && placings.length
          ? placings.map(user => {
              return (
                <Box key={user.userId} mt={3}>
                  <GeneralContainer buttonStyle={{ padding: '16px 24px' }}>
                    <Grid
                      container
                      justifyContent={{ xs: 'center', sm: 'space-between' }}
                      alignItems={'center'}
                      flexDirection={commonFlexDirection}
                      wrap={'nowrap'}
                      gap={4}
                    >
                      <Grid item>
                        <Grid
                          container
                          item
                          alignItems={'center'}
                          direction={commonFlexDirection}
                          wrap={'nowrap'}
                          gap={2}
                        >
                          <Grid item>
                            <RankTypography
                              variant={'h3'}
                              hash={user.place}
                              position={user.standing}
                            />
                          </Grid>
                          <RosterStatus
                            user={user}
                            textAlign={'md'}
                            overlay={createCrown(user.place, user.standing)}
                            shouldCenterTeamName={true}
                            centerBreakpointTeamName="md"
                          />
                        </Grid>
                      </Grid>
                      {/* Player Record */}
                      <Grid item>
                        <Grid
                          container
                          flexDirection={{ xs: 'column', sm: 'row' }}
                          justifyContent={{ xs: 'center', md: 'flex-end' }}
                          wrap={'nowrap'}
                          width={{ xs: '100%', md: '400px' }}
                          gap={2}
                        >
                          <EventRecordCard item>
                            <Grid
                              container
                              direction={'column'}
                              alignItems={'flex-end'}
                              gap={0.5}
                            >
                              <Grid item>
                                <Grid container gap={1} alignItems="center">
                                  <Typography>Match Points:</Typography>
                                  <Typography variant="h5">
                                    {user.points}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                alignItems="center"
                                container
                                justifyContent="flex-end"
                                gap={0.75}
                              >
                                <Typography
                                  color={theme.palette.success.main}
                                  variant="h4"
                                >
                                  {user.wins}
                                </Typography>
                                <Typography>-</Typography>
                                <Typography
                                  color={theme.palette.error.main}
                                  sx={{ fontSize: '22px', fontWeight: 500 }}
                                  variant="h4"
                                >
                                  {user.losses}
                                </Typography>
                                <Typography>-</Typography>
                                <Typography
                                  color={theme.palette.info.main}
                                  variant="h4"
                                  sx={{ fontSize: '22px', fontWeight: 500 }}
                                >
                                  {user.draws}
                                </Typography>
                              </Grid>
                            </Grid>
                          </EventRecordCard>
                          <EventRecordCard item>
                            <Grid
                              container
                              justifyContent={'space-between'}
                              alignItems={'flex-end'}
                              direction={'column'}
                              gap={0.5}
                            >
                              <Grid item>
                                <Typography>Opp. Match Win %</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h4">
                                  {`${(
                                    get(user, 'tieBreakers.oppMatchWinPct', 0) *
                                    100
                                  ).toFixed(2)}%`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </EventRecordCard>
                        </Grid>
                      </Grid>
                    </Grid>
                  </GeneralContainer>
                </Box>
              );
            })
          : null}
      </InfiniteScroll>
    </MaxWidthContainer>
  );
};

export default EventPlacingsPage;
